import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_tgi = () => {
	const [isHovered, setIsHovered] = useState(false);
	
var photostocklist = {
        TGI_GRISSIK: {
            "1":"15d3rl-2_owKThAfIgjcIVUI0tT0xJN-w",
			"2":"1P_K04CrwkYkchBev7TCpqIoAt4ILNjMQ",
			"3":"1TY64kePs5wESUIAQivb_MGXlRjR2PJuP",
			"4":"1qQ7YQl8FEZerrWM68TE4dX06X2IPL67p",
			"5":"1dvkt8dSiqP7uYA8CgX6Et2nACoRWFRJK",
			"6":"1eIGd4xx1CfOI2KrhXTJ98im123TGvzZT",
			"7":"1yfNdrLh-mRpGvP8kiQnt4khv0pw0ygAx",
			"8":"1034i8FKD3TqbwsGQY0fwSS9wXMsqepIh",
			"9":"1QUIYsuY7V-hZvCz_40vNKhpVy6ZXpXiv",
			"10":"1Ozk-As9Zr7hDDGJTHzRL67L0zKzu5bP0",
			"11":"1dGPFeupc2hBmYHzK9pwshKWN19LQHL4w",
			"12":"186zw_WOELMBuTzlGEWbpbNmuqKjbZ6_M",
			"13":"1PB1A-v9fELLqNUoeP3xPOWaTtqK8jvYn",
			"14":"124mhGBYDcHQgCzpc1ozUjI6hESnw8bek",
			"15":"1azPU2Ax4SUPbpfvakwrzXwbuU2rkRaxr",
			"16":"1VrTqirZjjU4Zj7O1oPnvGS-hJ639l5_j",
			"17":"1Uq8g3UAhscG7pKNzsL9noEalnAxgR63e",
			"18":"1XQitebE_iFMt-fVJ3ZsKjDzowQs41XMU",
			"19":"1cVsAGFxQAffUe6C-djSse_nSzRCCxZPi",
			"20":"1hjWXal9LVY2uTaGBJyhdPfDbsC9oWYGv",
			"21":"112-RKvCNfz82H00M7iX3JD4Tzu28uY16",
			"22":"1JhWAATIMPHx_GzSFasuEsc8Qcd6Vuk7y",
			"23":"18ld-jlEEdeOtHxbdaeabs3fONpfBHFh3",
			"24":"1Rdrg2NuSILBzrOU171sNMUalu2J8MKt-",
			"25":"11j42rMO7ZWqtVibs85osqNJbeUFRIO06",
			"26":"1CF9nB0C3egZBdWNcjsowdFWstvKXZDh3",
			"27":"148TVPBm2O6DKrqDz6NKxQFri0ZSurgKw",
			"28":"1K4Ua5gvU0uKk41Uyz5-Z9Ssvtcwas9tk",
			"29":"1S3Lq-0drSdgv6J8p49rXewIUZglw8VJa",
			"30":"1uDgy4aCgb0UiYOYN8xSv-DkKoqkTFLjK",
			"31":"1x4cSI3nuHepxtwoCUFanZPmwE5mA-_t9",
			"32":"1uRo4bIscY9G_8t-PFBI1vMyHnz8OW7Oo",
			"33":"1ONUJXdajWkcD-vPMYqFSXCCiPqVUHwVW",
			"34":"1FjI147anetmbxFq-12y8WkvQTJzEwvIh",
			"35":"1XegLRhJ72p7Ik3CvFDwlZYy8YnZCzgAA",
			"36":"1BeEztVpP9Uuba5J01WBQqc0TgVwY3XGW",
			"37":"18SgfjJlwKFqcDIZp5RWFooaTuf9TsTXq",
			"38":"1c6wfWxSiSEWPZbYOBd7Lns0I7Onrrncj",
			"39":"1h_1Dot-W5QfD2bvFnPwsRdqdBY7gSXKw",
			"40":"1jxwHrQBhzW9_UvwzaNFBPjDUv_nWKc0j",
			"41":"11d0G863O7t_ydTdN1k1AQCi86kJPTUeF",
			"42":"1Fenx_jsDmCmo_1BWnSNrhW0edGvnzUdK",
			"43":"1lf57MZM-b20wd1Aftrh2nr5IumEKCG8W",
			"44":"1B8daK2N2ecr4Mv6_nKpHdtYisvXqkBN2",
			"45":"1rc2J558awwCYPDBUucbbHb-9j0xPPYHq",
			"46":"1u1BRUvVDhvqQ1zQ0XhEQbyr4M7GkbyGk",
			"47":"1w5S2i5IhJL9M2S0CzeurOAd8OiTuroer",
			"48":"1S1GQHTeNva1t6jWwP23uHeMlDmnmDw_L",
			"49":"1W74Y4xiN4decoeH78cOkUxCE-BoMFnc0",
			"50":"1ZUzrmW8J8StRsQuO0m8Dt0q1hZPUnTeh",
			"51":"1LNgJTaoZd_mbIyQTPKC0waEOod37VpCB",
			"52":"1rWDFpZ6kIS2uEN_nTGmzbO6i37Xw7-Dd",
			"53":"1haowz2OJkX4dT_YtUVh3yQHif9DdFDuf",
			"54":"1Dl4zvwn-a6v0fQ1aKcDOXThfvLRuNhf0",
			"55":"12Z-t9AV_aMguTcTYJit0ruB7gzneKBkv",
			"56":"1ZbsWowhz-B97CwvOKC0cTs83r74i3vfP",
			"57":"1V2scMPnOOjA2f_fFdaPjeQMI02c-dD36",
			"58":"1U1-dyc3Ne71hoC19QEn9nGKsUQ54hUBV",
			"59":"1A71XyN6AMOw_0Kl5BsrUf1Ss43voRoOx",
			"60":"1cVwFDyndeq3UiZlkeg3ZaJTSueGbieGy",
			"61":"15PNvPkK78oz9F7dnIsh_Xo-3qCNgYJaX",
			"62":"1_9t_wUYlARmYs9-s9kIpRrsWFB6UOVaq",
			"63":"1i1kgYyGtLaSyDFdk3Yq81xz7cVEXcoTQ",
			"64":"1mKBOs9lmsKg0VDDjdZP7AdEv7emKQjTy",
			"65":"1FyTqFdToeD-RnjlymIO2Ge3iCTJ4AF0V",
			"66":"1orHV1T-cq9OhfkOuFylhjEerVvosNv7a",
			"67":"1_8fWxb_VGOLDmWI3ggaugVxPLzUmB8vV",
			"68":"14nuzF-FIu-KS0gtLcIx5H1c66T318Z5C",
			"69":"1-fo9UAApdTN4Jk-AOYUpjAi4Yegyf8Sq",
			"70":"1c54xwa7kTui74z4x_IbD8I1pyY-jaV4q",
            "71":"11uvOKKzR-1kuidXOHNTquDTtzJaG16uz",
            "72":"1Vf6jACW5tfPc1ZWMmB-LmiOwdhhn54OU",
            "73":"1_TLQ_ZXU3_8Rzo4DcGbMpo8CwDP1FHjr",
            "74":"1AtvaSocuSSMIo-mBs68yPTYGFY32AiEU",
            "75":"1dJeVLFhWYjSbztfNXrY7EkgnkpJSfw5O",
            "76":"1S0_Sk0Bcw31vad5oxk5Trnh-7F7SG7eD",
            "77":"1UhkwCdoWe-nkofgTNJnXpLcmweUgJQGv",
            "78":"1d8X6DkKTs88nbD4t2FCIvywawsevS7YY",
            "79":"1RPmKv6lsy7GG_xpo8eyADIYSU5E1VfHa",
            "80":"1MC1JO19JK7S7HjKkbDEXsD3rKC989NTD",
            "81":"13puC1aqQIMF8ELUy0y99h3JHiy2lxFPR",
            "82":"15fju2bYprMyWZVbg60mVu4HaD2YCHulP",
            "83":"1LXv77NYfEk0Tf9l4CbPN-qm2W5z2OHVe",
            "84":"1VI8xbup5K7Z5IODZSc3Hpojgg5hZII0n",
            "85":"1jHgbIV2jfobgK9z4IF2uuh-vxf8qijPQ",
            "86":"1mJc67yQ2u-svoCa0E3k2acAFhbx_YizI",
            "87":"1QbfGqZfm69ChsfWWaMzUznhKqHoo2nhm",
            "88":"1wCftYqh_M5H4X-bMhOHIyLzCTSE6Qwmk",
            "89":"1IHA2a-NNEtiRz3uqNu1qPQ2B9s52drRi",
            "90":"1cfdyP8eLBi9bZD-PKC1vX9SNV7m8J_9B",
            "91":"1iIzR8o2bLvgIJ-pgKB5yIbquPpqF3v3E",
            "92":"1SU5XGpyXYqgXhQy2w4nOq-HXuZ9nzHMm",
            "93":"1zgp4NoR2AoZpjCqMW3gMFc91o33i3_z3",
            "94":"1Zd8ZYaXt4v44PXvjZOsMT7xFf_MSXbRl",
            "95":"1JFTA6e81VoS6drUoildsViiFsfx1L0Ag",
            "96":"1gekuwQeVkt3BCfwyb1vvfonbuEeJYl1_",
            "97":"1svdurqi7SLNRqrzlkKfpVZOBDwDILd-V",
            "98":"1MHizQvec87p-YLiBZouTwFdRc7Hn1nKw",
            "99":"1-nAJemJ5zRDtniYI3Z6_I9EoH3hr-XwG",
            "100":"1f2ZFO551hWfBF8qC6AVp9jD0EA6tqmmo",
            "101":"1D51IvVWTJVmuzZlHlr6iEQbCDRD7ih3m",
            "102":"1Pb75IBmG8ETQgQ9VxENBpKVtmH_L8l0a",
            "103":"17ByjJWGbz_iWsw2pIjIhJaOuvd_bW8jO",
            "104":"1Jf-hHAF774-EzXtHFPDWXygVJE5STxUg",
            "105":"1UZEkFOcarjnH0_m6nvjh88VF0E29l07u",
            "106":"1iTdTMBicXbGwt_t0pKZkUYbgrNHWcHg4",
            "107":"1GZ7OCUjUarWZGlid027VVDUYqcNXxuPO",
            "108":"1HWN1P7MLCbStvFsybFM9ut8nOa_KN-Lz",
            "109":"1dZGELjmoMakUH3HCE5GCHOgQPo31vJuA",
            "110":"14lj09wk2U5vuicUaD2dhZ8CY2s9b5mcb",
            "111":"1YTFPK6z5w0rR_p6N1rqRme13_Ydg_wHS",
            "112":"1793HD2O2XHRLqgqL79Gtyk0woviklzBg",
            "113":"14dHnEJWHZeuFBf_SvI_4n9SIHTwnhcBC",
            "114":"1ohU5SwtBxD1Ik6N7kaDd9kM28x2uJEii",
            "115":"1grLUEvByIHadsupq9LHiWn4Ky0q4N6mP",
            "116":"1ZQmh0DTUUHGBdlI01aKetg4IYFD3QTAD",
            "117":"1MII_gQ-b-pghyUfDoYlTi2FZ902T9taS",
            "118":"1tHbmPGuJm1uOr6IIiDJx7pvdMRGUwTqF",
            "119":"16mJO6sNmEoaRVfon-xy6l1XqDVqo_Yuh",
            "120":"17_XD9BO9taZwaFj55ue8s_-HBYWpu4p6",
            "121":"17qTsFaq5li2TRjzDCD1_ENZI5QrnLSb8",
            "122":"1FFcOTbPwySMJPcwzpDkhHSzcowYogDA8",
            "123":"1pA29hs2sHgFM-xfwodVCkJ4Lt2Qfmc53",
            "124":"1nXssVyjlQOGFQYZF2Al7dWpVv4n-ogzo",
            "125":"1BT7zJioksi7-ZDa7N5njazRsC1GfZNbC",
            "126":"1N1tarouTVi6Rf5fDUzmRbLgooi_qYMlV",
            "127":"1_qucHNCBOUyJH6JvAyjcg4lt_t_Uj4SJ",
            "128":"1DVzjK4vAGDKxIKYOqUk3_cSKczLpDj7q",
            "129":"1YHcE6l_JeDWqjNBK3EQBVWs-fWuaI0tO",
            "130":"1Pf0SXybEf4toJUpqvAmE15NDAltZUOia",
            "131":"1DUa3RFrQe4pnU6DaEbT3_PTbbPGL6xzo",
            "132":"1vlGhg10krLp8_HxAmxpfxC6LgF6O-FQd",
            "133":"1aDLUqg_dO3mvhUkGtia4vV359MZeom_I",
            "134":"16CBIQMfa8SKgEGi-NdW5UEfG28nibfOJ",
            "135":"1-ChPWR2zNUVtXp8w__bg1IZ9-3uRgaWI",
            "136":"18eb885CVNwKwvKOfGV605xasJggZTW6o",
            "137":"1HHvyQize2PdmFplDV-lpr2XP1aZjaJ_5",
            "138":"1B3p6jioed6oisppfd6OeCN1mGsAd72fh",
            "139":"1UXx3PCrH9o139nIlb90Xdbj2EwxWtj0T",
            "140":"15kMmVsZJTakSNX6IqGsiAhcFgpjsR9wT",
            "141":"1osJPvgSOcicdYTJOLL8dP4C73fUP-7kU",
            "142":"1S_NXjh8l65s265u80FmEqT6PNs_1Yc_C",
            "143":"1_kKBteUP8CpS6QKc75rKu1Kwa5XhLBHe",
            "144":"15b3EUJE0gscC-zQpx_pyYsJUGEMUGGgU",
            "145":"127jkLe7UtDh91T4lTcII7-qSfk0nuzJC",
            "146":"15wfhe-0RZHfdZoD7sifddSYVS3xgepEo",
            "147":"1aAeJl4UHGyz5SiuyAjW0PDjDgCSu2uGh",
            "148":"1_N2s_M3ezFCL_ExkrYBzf7jSBm-vwkQU",
            "149":"1H4-cfK98nsJ7by-ygAKFRl4KHN3qdt-4",
            "150":"11h-xIvc6hBXCrYXrmtrhmvctJrwHkibp",
            "151":"1e4ArulHK5vI7oMf2zXINXne8SVNcYPjM",
            "152":"13-jTL6zHfQ2GrAiklzBYmasOCR4qpF_-",
            "153":"1LQD541iMSLx5gh9pqOq73tr0wZMA8vm8",
            "154":"1gO9scxYHRlJzUL46byuwuUWhhHs6epdL",
            "155":"1vHy88C1pQdggrs4ugt3fHU1PVbT0Hp7I",
            "156":"1dB3yJukSBirnm3BH4pzPJDn6v63Ttx-q",
            "157":"1fAY086umajsmWUVs-LhG0o11nLNOkv2v",
            "158":"1fRQxplE6QnXalHVDwaOPXLp0STWjrv9V",
            "159":"1rlk3IiMiouirPfFIc3bEQ0cu1Fg64FB7",
            "160":"1WENaLrgbJg_W7sV3ZBdp3iMuOfuAexzC",
            "161":"1xGqI_xuSz6sqj2JOA73y3KtkDJb0buoO",
            "162":"13rA6hCZQeMvOV5dEjDY9ZhrSsmX3sjcU",
            "163":"1LSpskKqYPaS2Sa0dQSg9bb3LlbGTe4gq",
            "164":"1NkP1xVWihL-7VY0Idu2xVO8CmDr7gwmn",
            "165":"1NOKqfMAhTSfyTLKbE6EREx8Jz0SsJTcu",
            "166":"1XgFJSXraROPZ6No0iaoPUO4c60dpgF4d",
            "167":"1X_DMF_bHcoTZyls8lg98spOHR7HaK_bu",
            "168":"1c2hQ7ONqGhPviuxCVVT2VWzf4L7GX11C",
            "169":"1IjGJuiVNioPnHZID1tTWwA0VvBKNm-IT",
            "170":"1UHrNMI4kxSKFCJIA_zQQqklChGHFe2oo",
            "171":"1OJOLodsGkzSbOVl3DsIMk_tu1XQn7Lgb",
            "172":"1C17exOGwqTkPHKj7_NLDI6Jj1cpcqYEj",
            "173":"1RHgs9VsgqMmtnzCoClD82Z5UrQM3HDKL",
            "174":"16anfEoLt_yKCJCx5VqeqPl9lsf4YDw3D",
            "175":"1m-VSKqxeUaaswQbZc8N-L1zNAsq89C6I",
            "176":"1ujGsDLV-tFI70cJJ0ujqazQNg13X8h_8",
            "177":"1lc-rH1Uy43wPOfMUGO0n4WeKQUHPT1JZ",
            "178":"15bzUEjpRa1TTajjdqVLOfZhlgS9yR0Zh",
            "179":"18r8_uM6u_FgVAnQKGvn9ZDLfWK8XA_eb",
            "180":"1TPTQM5U96wckx2fi2NMYw8jcNJEqosA7",
            "181":"10qQQAaQ5bYDX491PZuSxxfG9g_o7pOFf",
            "182":"1Hn7gfTtA_h-9ChK1htdI-lbPBcjCG3li",
            "183":"1vkeiX9RPcKcztL5JV2MLNcJJV3dbjpGa",
            "184":"1OK_ta85oXD031g6AbhgOhRakfih1Dgs2",
            "185":"13BLpD2H9fNGiD2wE3peKBl9keOdGD52i",
            "186":"1FwcWklKXCJQBn_B0FIWYg49NeMnAuGNe",
            "187":"182FS62Clv54QGDgwLQy1mLh-ucpmvhlj",
            "188":"1BGCzeDBVWbN_s7qZh99O5TCESujN7Quq",
            "189":"18WemwyN742nazMThsTQm2Y5ZIbjJk3hu",
            "190":"1MsEISUdRWlgKpn-KAlFvAXLlvp_Mu62l",
            "191":"1y9IOQKHLTDHpkAMqFPgOIsmTJj5k08Ip",
            "192":"1zWhHepVqpsdRLKjYkFnHQWwpOwvy3uHO",
            "193":"16bS-QwPbiQDk1H6n0ouonl_Ew4tBRcpo",
            "194":"1mPzeeYjhg0J3MAZSaIckYqMNRCfIztk2",
            "195":"1e9CTXnn_2bbvzvuhpkZotR4FeVyselsf",
            "196":"1icFanEbCYpUbBKIInYE4ucAbQNV7IRem",
            "197":"1djEh70LoL1PoNqqZ3N_f1ShDO7OtM87H",
            "198":"1XWoXmbtTcg70PY63hD2-VhqitUTJ3YFR",
            "199":"1IBPnAgGQ_JdNdm9RX7YAXYDd6CFTSn6O",
            "200":"1FLQzpLoKrG86Fw2bhOF4hwmTd_OQIflG",
            "201":"1DFUQWffog7TasVa7BG-h9a-82HwUieEs",
            "202":"1xAjz0FSJ_JQ-rgIQzRoP4ZLHNNZZ7LsA",
            "203":"193b8WaExdx9oe5y-E7wBlJ4PSwvT0mCg",
            "204":"1p8BD3_uzyIwweCUK-Haz9Rk3ZkmgVo6m",
            "205":"10ZeKGtDUrZmsAW_biTEqVJxtlZfbd4kH",
            "206":"1KDBdTzqbFFOcA67ZNvUxG7pubkHAEcxL",
            "207":"1hzNTFUWbWbAnAqZfZItqBpJOZZetsKxe",
            "208":"1bmBYi_EG_-PWGvAnwToRofXxOUFXxVqm",
            "209":"1RSW8nO_VJeVthyT4bXfwbBtwneBe7hDl",
            "210":"1ZogkK9Ej2V5lV-bJcoaI4S5Jg54fX3P0",
            "211":"1VhlM1CgPb7IGzChfWM3w6cLxDUd6A5sP",
            "212":"17S7Y6UpVXl9ivqIq4Rz7z4XVEzLhEuZH",
            "213":"1B8y8wbMbOF4N5JEUMDi4ODYkcm284UaG",
            "214":"1XeKbj6imYUNC9GnlJYJkKksB1g3wl8SX",
            "215":"1vcxXTOa4FVgWXadzRP5sJ0SfBSMZLP0V",
            "216":"1REwEEtMubs_bhOGjy53fvUV2RluG8qLh",
            "217":"1e_2EZH7phDOhvqemiBEej1EJd-b6I-sj",
            "218":"1IquQopMaETyayb9AyDfCNXtusqiiREON",
            "219":"11nK1iWjxN29wOLfmcNeVho7McpCC-UzR",
            "220":"1x2pLiaamNTMP51zXb3DyR95H79PIFs6C",
            "221":"1XzSTpj3CLqhnZKLVO0w5COz0BzeIMHij",
            "222":"16LtsY6P86M2tvvknBhKidwdqHSOct9Is",
            "223":"1RsY0YLur1zcLNfQvv6hxKS5sFYWdUa-v",
            "224":"1JmVF-Ss3NzOTwoA4XUcbDEmEDKdzQTqx",
            "225":"1D848OKN7YEOXKvSWh17AUbbvSkcwJFme",
            "226":"16Ce0mrL-ZfQwaIep7cNaeTn3h3Iu61l0",
            "227":"1Xzm0NQVs6MEwl8gkze6GP1CR5OFH5mia",
            "228":"1NvOq8e5HKdq3FXpfwyKNa20tSeErHagO",
            "229":"1pVE6R1YzNx1pAgGvsCclOaZFG_fycaBI",
            "230":"1BqZ9Yql7_ovsDNU7HLioRnIKLQcwWxx6",
            "231":"1S2vroqsiXbsGLFgh6hUst8GlzYpzVE7E",
            "232":"1dwdeG_nUetmB8ELc4ncu0Xk_DATL1QIZ",
            "233":"1pZCBxK8So1pGUiZKktesoaJ8wBXNg5Ly",
            "234":"12buQfYJi7IgrfyGP0obvuowsLCLxCuJn",
            "235":"19gkd64L2ovGg6rZ6xKciwbcyPdn3uvMf",
            "236":"14-PPLYb8rvtUTuRjAaG5pR6eMzv2mJSw",
            "237":"1thSQ_PdXNG4-0bwT-mSYAxNd9HwL_DMf",
            "238":"1DpSqKAKQZL2r7Rt0bvWj_TCgNlMz3DK9",
            "239":"1R-sZwoUGadpKcPoLslXbNm4neXt-zV9E",
            "240":"1ekw9rS8se5n0hIlDeU8nrQflmtpK9lvI",
            "241":"1YWEr1aw699NDlQxe6BjvVbIWvtJMRIIY",
            "242":"1ft0mjvDdrnInar1SoLSL_Xa825HT2Xfr",
            "243":"1CQSOgR4Dpra1fXbJnw8K_CMu0qvdjkUt",
            "244":"1EJS4Zvbmowh8c3I8j7dSEx_F0zxzrp0L",
            "245":"1AjW9jBo8qZj4ekysCaucJNUY5GnqfU2c",
            "246":"1AkpWTUYFIsjaJoeMSCEQLE6uDTOcDbpm",
            "247":"1Tjy7DzW8UNEYUK20f3087SjI1wtbPymR",
            "248":"1-uO9MmXFMUDE6mLV-Rn_XrZNdhNYalOB",
            "249":"1eK2GlqPOEFTLz_djwcnir-pvh2I1vKie",
            "250":"1KP6Hpz5qEbJhh29_SdWfZbwG_eQZ2kbO",
            "251":"1WPWqT2sfHXGD76dh8SwlX42WyMfZw1Co",
            "252":"1hWjog9qlnPOZVZxSjcb7vxqfGKCZlxna",
            "253":"1jmH_9IAdu5vRyZei6_2HEL-FoHVQ_I7D",
            "254":"1tQH8OIvJdrFXU2Zsn0VaIJZrl0JeKYzh",
            "255":"1v7o5LvEJbzwWUkAr4BlZAgvBmV9kdaia",
            "256":"1ezqKNz3JTWI6khH0E_ml2NhoyXTcmb6C",
            "257":"1Hn_YhEQYCd3qkRPi1vCVt0jNn3XUeSiF",
            "258":"1SjXPXkCYbeyPMcCik0fZ5LdmAd_J-d-B",
            "259":"1Sa3Fv4NCgeXPjx-t9qADlxcG3STwPpvg",
            "260":"11PeMGAlcjdJGVBivLYpjDWPGYK-mVjuC",
            "261":"1L2I2rveJYFeERnGg-XXQ3RK2v-izo7it",
            "262":"1Kmr3NHbpeuvkU9c3aHNPIV-XrGG8uurM",
            "263":"1ucFg5Ecp-I4Yr2i_qP6PJVD1YZ8fI_g4",
            "264":"1mlUk7aowTwrOKIr-TMGYJGg56q3pIpUA",
            "265":"1Q7STsd45jXltl9g7hCcIE3RS8ftwmPKt",
            "266":"1LP8u-iaDA1soIvxopeXZqtq8qTqPQF05",
            "267":"1D-vcWdKeHeaTPu749zZvHlTwiDhVD1_7",
            "268":"1YoQ4fU7jQi8u0lO7YRk2n19RV8U4RVUY",
            "269":"1_UGucE4VgoR4YJRBnDhY5F5h7U9QGFNX",
            "270":"1y5w9Ubu0d0KB-Gq3pyHl6TC0NHGnPlCD",
            "271":"1Rr9ecdapPa0KLwN6BoR1T1-GlgyEDmWm",
            "272":"1o9o-kfG0UlCgvdRj2ghxNdI2LBiRKkFW",
            "273":"1Nm7MoH3DjuFWBjceyZnFyF0izloW5lC9",
            "274":"1Gd893zVwHlrxF3G6AtxT2_Wu5ehBJaOe",
            "275":"1CkJIqcat1CFco5qYM_YQfMTfP5E6eWvf",
            "276":"1xZst0ZkOIBL75S4f2aQqS-ruE1_ahGQT",
            "277":"13N3XMTupVy2MC68--xE0-Fp9TFOgW4z4",
            "278":"1cTUWkFZ6yxiFzL-vFiMSvOuXKEzac14L",
            "279":"1_Dw-aSnmALiZ58wYf8AiMEfw2erHRGZ6",
            "280":"1d9CNcP_AUNdXm662uGoWJ-hNIdpRhJxB",
            "281":"1lGhPXZMbpaEhqewueGPZh_VjK42ynlYC",
            "282":"1w9Gqk42JZjQBNBVhs4TcRmGs2emmX7mu",
            "283":"15to7pou0c23HaWplb5N__bGMfgIaFtP0",
            "284":"1ejunVqzWPK1rxQzTayp5i0cVYT6etsjN",
            "285":"1EjIcSjAy7zZ3LrPSww-g3325xhIjeTTU",
            "286":"1ozL96SJfInZ_9YrzddOkWyYt6fJE_mcY",
            "287":"11pXOa1JYh7BawHbG-Wu6BhaGnvQZkA-I",
            "288":"1dlHR1dqQYQTE6eiCpzkGIojWwmmwljmW",
            "289":"1FCu8LqC0SusncoOZoOXsf43PhL-454MD",
            "290":"1Vpihdpm9uCc5oJA9rO2b3WARgcgl3CNV",
            "291":"1FNdVZnTLCm57noI-BTUQEuXrgTU6mhwG",
            "292":"1k_KNw2PHATRU37Ied07y4mAV-D2h4Im8",
            "293":"1FWML741FPb12lqBYW21hE-wCA_LkBUky",
            "294":"1LvYsGP_rPnIoCH19KWd4p8s32AcnhfsD",
            "295":"1pOItPQ79HkgH8KZhMKYycoxByneIAKz-",
            "296":"153MCHJfNI7GMwIIQ5M7tcpM7d8Lkwy_9",
            "297":"1RuNfMkZvr5PjP_SNDYUQytz98Qd5Qiul",
            "298":"1IXxKZVCQVGnCwc-i_3YqErOzXL3-5GDC",
            "299":"1yuLaUxAzRPDJw3esCt-5gAH3AyJKdKs4",
            "300":"1QCHl2Rj4xAqQshHNSFu9z7UM_CYp7UkF",
            "301":"1toWCVLVUO5eUo9ee8Rts162HgVWepvgA",
            "302":"1uQYRgoYCBuBNF8I5ZoG8RGiWfGAXX-cE",
            "303":"1UnDOGU2rlaeHFHs25hZumdwEMxkLFak-",
        },
		
		TGI_Tempino_metering: {
		
			"1":"1NlmGH4O_gmwctcjxbDV92LJdcirJQfI5",
			"2":"1ylFxdGduDj498ZRXUsssTNqz156UFXza",
			"3":"14LbPfPHDfrI1XWrEBskGoE44z3yMvYDP",
			"4":"15ko68hFxQkl-gyTAokzGZiuzedxM7Nwb",
			"5":"1FqPMDVLRO2vHYwL8_CLQPTjbQrpxs61h",
			"6":"1xGCEdskaAyAzIB0hKJN4HRKJ3KuFvdOo",
			"7":"1wxpI6fA9VPeOebBTjNKuEi_G8Ys38Rkn",
			"8":"1LT4LyWh7plZ1GCi7J8yHFD_386PsLf2N",
			"9":"1RiEiznKyaI0U6N2rTeMhi4SutpNmYxNK",
			"10":"1Ui3oPENnVclgGmZwtMzRaHYP1TR0JtUu",
			"11":"1daCiFTGSbUsEK7XePw8FKU2mJnlFABAo",
			"12":"1JPWXzQCeZXKxRRxUqmulqVsdrX_r5xCF",
			"13":"1mrWhSPnm58hYFIt60XsgHZZNiPpvgV0u",
			"14":"14bVgwrKN1RtLxI_pVg9YmQkW02OaO2QE",
			"15":"1cBl9tKj24SZfWEw4GnPmBVWdtElDVg01",
			"16":"1D-R-taDoOppnnHLeJfIHrlgTz0p6U_ib",
			"17":"1FAk5CDgGVw5EsFFoQUxFZYf1xa6TxWUE",
			"18":"1_odD5z2BW6yFxjtXWPSmIjWfZEIiBVc1",
			"19":"1NHaR9_MQpOYTZozklJw3-ZU8f_nb4teE",
			"20":"1vIVLb5Yu6m70cesPduRZEWIqMKUqYlgh",
			"21":"1hvLt7jtzGDYW-hwTzdNjvSo0N18IoYm7",
			"22":"1slLhHV_feq-puEGkOC_g_cE7SSb9WFx2",
			"23":"1JPkx3G4BPYsKX2ksvWyqYievSXxuN1P4",
			"24":"1erVxjzM_UDSTJTxLSydfRawSatOL6Brg",
			"25":"1ixdB1vh9iAF8P8TrDR_QzJNqYeJtxkE1",
			"26":"1ufObR7Lo0iSDNn0pEso3Vp8nFffLY6O-",
			"27":"1MZQN-3dGHhfATrqGcb05iopO15ms1Yw_",
			"28":"1BpQU1duKIIAhEx8n_c2hScmVm41meNSG",
			"29":"1NoecKSok0b6T0opCj_qJ2afgMYu7dcIE",
			"30":"1U4ziRIssXEET1Bbtb7tXXZa8SYqOTZmv",
			"31":"1S1L9SWxLoDoqjSzLZ0Z0JMuacJflA6ET",
			"32":"1xIsLTeyGxaIskI72pHLJLFFHNf3YEhW2",
			"33":"1CSZeMkBvNySiwYOlvjNUgP97nkPV82Qn",
			"34":"11uEt40F0XRKhqRsm_P97rfPliyHrZoOg",
			"35":"1KEJuZi1yp_nqKo4vXcyZZTKFeqQvFxMk",
			"36":"1lFyHoyBaq8l7OoFGX42-wSm-bYCVo6zy",
			"37":"1AmqYgvaih1ySC6xxotR_OoBwQC-4snrj",
			"38":"1g5zUPhrtDFfrhtYRXWw3Q61PTSBEVVgF",
			"39":"1W-tOD1g7-63wzHAXozkUZRyCHePmTnpH",
			"40":"1JqWSnazqm9rE4HUWWdN6nBCgrtxiUgrF",
			"41":"1uX6GwieYq7fOTIJH2yPkTEitVfBrCBxe",
			"42":"1xu8j7CQ2bqEneqEvvjCvmH7uWuK2kTeU",
			"43":"1j4_Ot1wPnm_ZLePFW6hrXMmN6ch36bYG",
			"44":"1e-phit1Hw6C3iaeQ6lyACJiNfXMiBuCs",
			"45":"1z_hK_DqlMTfMEkF_-Mt-IXOwck9Ktk3K",
			"46":"1F-QAvhxqX4sGWmxZKnFUQna1cd6NRex9",
			"47":"1IaLzmva8THA7E_HdGbOFiq0rrcssVm4a",
			"48":"18hUq-b8oADj7XnGyUWS-jXk-kEosY_FZ",
			"49":"1HhB2borjg4ynd-iHNp-40CI1wARzRPJu",
			"50":"1Wi8f5vxlzMNuzsXSTrDd_piE41XOmDSB",
			"51":"1P3mFrcUdV-s3gQDyZu3RrCpnu7fsUdfz",
			"52":"11aka46zsN7ZH-YN3yYm2iElKcWniqjg4",
			"53":"1RgU7AmjcD5BjzeEcxvqvLTSqJFiWt6ky",
			"54":"11UW0ujXFqR6bKn-L6YWOdBA2IRVzEM-Q",
			"55":"1nt33bPT6Cazy2i35R84VlPFYlw07utC1",
			"56":"1Qw3XDIbOafcVdE3AGVMQhsybrdJDwgib",
			"57":"1lOcx05e9Rp4t9Y3kzS81WPI1JVx5DSc4",
			"58":"1ciInMQWcPKZ30EblCEWHjmKKwoULuyV8",
			"59":"1mW0CN7dAFfFSohjtYJ69R6bn7Kzx_VDy",
			"60":"1T4iMnppOTndwupExtcg2nwtGUor30EV3",
			"61":"1WDYONYoDjRfScqxHG4TZnTMxnaRT1HLM",
			"62":"17npaCxPZGb9c44hE5jYK4DjMkZYZjDSW",
			"63":"1gyFB9UN0GHg1NnUZt2wtPDvLTVW2EdBF",
			"64":"1ZtpS2MmgnJhPi33B38ICBajtagAS4Fch",
			"65":"1S1G6y2Tdp8mNnFF9zAKYy9r47tDQbKYO",
			"66":"1b8ho12Sl06fS8fCN5MzkFQPBDTzErXYr",
			"67":"17FbUmkMX7SDpYkiSfVhj6ejpZsAZ3sOc",
			"68":"1Uh7XVGOj_Rm5KDEucXIx8Hlp8ZRueD8F",
			"69":"1WARUzqqk7q6oIWveq9TyF2K-ZTTByXSs",
			"70":"1O3xksB9jk0M6-8pTiYzkXeJ3folOJ7XY",
			"71":"1JNwc2lh_kIw3ROS5b5Mjao8TOar_WU2A",
			"72":"1HKg_gRNdeJumVpdBtYnQIf2qygS2cY-R",
			"73":"1ctKfkCu8YiV1ACb18ddRhQiZ6Wdd9btI",
			"74":"1jApRNWg-gC8lqqIwEFYC2NlLiXhhYC5p",
			"75":"1z_O94i2WfokYbKU8lESierAf3GAY2AHw",
			"76":"1oUZi9HMCEXMTOwnvPVau6HW0b4z6k-Uj",
			"77":"1DACbeMDVJzQCrxhknoX6XWnujG6FV6fU",
			"78":"110NqkrnGqk_O-D4yIh4ME8Kj5fIU1QCJ",
			"79":"1fU2D2OfBr59Y2woN7kMvSZE7WzROP8qp",
			"80":"1BktYLYh5hfDoFwD9KVTUlVqly43WABY4",
			"81":"1h0xKpBHT70lF78pileYpvvrCQweo2r3G",
			"82":"1vePMCSkrjwzXDkQttlSNRSKm5oJdDLxj",
			"83":"19vGpQz0LWkaAtDRJwnXoqyBQDaH90S6t",
			"84":"1I_yw2Txha4EQ5E2arCUmU3AUFfxHRy5X",
			"85":"1w1m-cKdzSjkikYhygA9gsYbkF3jViBQm",
			"86":"1OC3qrkgCYjXZ5CGOEYcvC07vFqgD5M8b",
			"87":"1ZjhSrfZk19eSTHCkfEyppM3Pa5wMYlbc",
			"88":"1sus5Pe-jhNBvNl6kTo9c_MKXsi_RUyp9",
			"89":"1NkTDnF4WwuZNMoivMDG3zHQHya9UmlsG",
			"90":"1rDkug2AQfG4BAtxQ-BGRgOuso5KQLH2R",
			"91":"12pQlFfSweK3v23nhWZ2tloa3VikhVLbj",
			"92":"1eLGvx5riZpuT_6f38YqiEA2x2qwKvMtf",
			"93":"17JbtI9nO-wWAO3skNBUknLAyP2OfS98i",
			"94":"10Co20342XxXjXaFsM0EotlMpomM1rJge",
			"95":"17nFi29IEpcJisPrXfLq0QRtFQOmh-dZh",
			"96":"1MmfY70cGofVAMIg0zIfoPAD4YjXNCxER",
			"97":"1rd05OJ3xJBc_QnVjygg3o--ctsWVq3cP",
			"98":"1v1ebRa_3t1-wFo13JimSWkduOrsqZ_jw",
			"99":"1zDpVuTRidWMmoW-6pwCc5KCGo0DKIo0u",
			"100":"1cFVcTzU3Rs-0lp4f_BkjMLhn8hP73ftv",
			"101":"1DH2RARliUG2OqlCOMYTgrw7-wrA4aU4O",
			"102":"1PGlfWdOEbgKn_Xmflq02_tbbNG2Bf5yK",
			"103":"131iopswmmlN6UIBMaxJOOjI-hkoYq6Cd",
			"104":"1zPcumeD5suHtkBtpTkZeLZaC8KyFm-aP",
			"105":"1FeSCYBykLs48DBf3YzuLuR46kv4_-OwW",
			"106":"1sNpAEVF_msRZhV6Io4cRXFxJ89VrdI1y",
			"107":"1pvRaEaaEHtJUWA8HZFWn7yDL7QutHL8h",
			"108":"1_lN8NbbZYfJZe1conhakk-aEHhDT0HkH",
			"109":"1HVWsZcVjEOogbnLSYHk8CUjsJ-8V1q8F",
			"110":"1_JZYMGxL5kJMAjGOfQI4jV0eea99E1vl",
			"111":"1zQK8ctTWRJaKld4AN8Zb7uwW6iAF9gSg",
			"112":"163OnmK1lq7KEuTQftTPB5HzQkMbIRq_4",
			"113":"1APOHETtkBk7Scc9OmwE9Dw9VBYbCFKeY",
			"114":"1Lfg3f_bPDyDanUY4PTi8Vk9_lAvZNvvF",
			"115":"1lv-6iJD9wJdlofRHX5HonAJKPAUuh0na",
			"116":"1EB929dRci1VqTfLGh8AQbvAVLxHCKfno",
			"117":"1NrTXeo2FeNbHemVQaEjymFwKocbHuzSW",
			"118":"1sx1HfNGFJfwKF4GFkk0YJs3Ef6jxgCu2",
			"119":"1N1fDSnSRy0Zaat2BNuaH7Xk6DWslOqMn",
			"120":"1TC1xG_bWbu2EIOusHdAIQlZ83aa0CH_s",
			"121":"1qEWFZ45JJ_RHZOuchkY0Rs0vY9vM3jls",
			"122":"1yT_DAUuLaQAtlOQ0mNaoJRQqu3qbytGw",
			"123":"1n6T5bw-8QUG-IJBuOUOZsGZHF9S_Y2_9",
			"124":"1MjcaDRAgrGhh6b61pGjDgObEVLAcfJTE",
			"125":"1rX6mmGigNdZQe6uJ0oQgfMVSICkiJWTh",
			"126":"18wP_pdcoS4grs6CRk6w2QW3ljdZOqgbX",
			"127":"1ZKjtTmjdU8Pqasue_LiQM09Ui3dRntwk",
			"128":"1lOV2wShGX35PNYyyUucU_nHygA9HgmFU",
			"129":"1bgOawyAhYLZ2zm00JuCrhNC7MQn3aKbE",
			"130":"1zAUb0BHzDSGrzD-TDYovsDc39PiI_vLu",
			"131":"1a0Ck5s6AM5H_s1ZOoyuitLdz0By1nJ7m",
			"132":"1BjKMXfRXjRl6GfgR3t4LgZvuQx7Omwh9",
			"133":"1dXxNNKnBnNIgwGkZpQYcalxqJ5qFdfKS",
			"134":"1HxxdHqGNnasMnFpgmcjX3E4Pzl8qGR93",
			"135":"1fj3SZQj50awhLYNOgSR9h1eCqErAyPbu",
			"136":"14t6s5aRi0q6RTTOtb-r0JwtEvykJS6YX",
			"137":"1ot3IRcWmkLeBJue1ID6ul0EAyAwhXjtK",
			"138":"1GNOIsN0GJGRS1X7GHLO2M5AIYfMFCr89",
			"139":"1WNUaAXtnshAZZBC0IMt3dOxUfVKYO-Us",
			"140":"1IdEVVQb1XCSv8GMUxS57tO3Tjkk-C0K5",
			"141":"12ZNAmi8SK58xKuJLGEC47EJ1O0v7rF0l",
			"142":"1t2xDX8YBVSs4hfDYW7xQ48tq0qHg1Y0s",
			"143":"1Wb0lPP4_QfQod7UCOAyFtg0N3YgT-2k4",
			"144":"1U1fgO8-7e29bXbVq5_7G6sjs2AbL7lYH",
			"145":"1blxtwtQKhJZGdizuLq01gTH6U43x51yT",
			"146":"1roDDLdRnnR3ZTJGvQysMga0lO7Qoyp_N",
			"147":"14JK7B5V-rCkyuK6gz-5ZtkzSjecy6XKV",
			"148":"1oymtVAe3qFuzqqwO7cdvNnpFuu-yhi3O",
			"149":"12id5DEgTLZtdcMfd9s3kLav8-uZWXIR5",
			"150":"1yVLcfAo1wFxO2_IZ3OIUJJZA9b_zCDO2",
			"151":"1N36H_Qy14-c-zzTyW43tr9S8_OKh_lzv",
			"152":"1ywmGVhgp3MzBtMIAE-QW3LsqYqEFMjwF",
			"153":"1CTZjMYpWvsLkK4jYFgoAFyz1197otQvb",
			"154":"1VLuKni5VDcPtD4qOVyta-4XvlrVPTIJe",
			"155":"1R9jGJvdsIriBYfQTvuZuNjp4k-xe8iSB",
			"156":"1EnIz7oxbNqBeQhgkCoWuZV-Q6wBVzExp",
			"157":"1AXh0_ns8Vk_EQckmih8YO-vSmxIRoMcf",
			"158":"1byev4T38NXzfReTBojd-5nf8Wdtdd0Lr",
			"159":"1Q-nPSpB3LHQK1SdEUM8bhQMOx3AoXhJb",
			"160":"1EWOeGKk01TLehk4KhfRhhOiwg3ncJgt9",
			"161":"1wXePfB3UaWB60jdbbZXJ_3F8g_elNzkH",
			"162":"1Q3NVal8SRNYpIV0dUQwX9GvoiiYcgKDG",
			"163":"1XYEkl-7T0DxgYX8cuUNaXySzqmscvcMJ",
			"164":"1bjSQH1MS4RBXCrVcHu5o4MegClnx7zX1",
			"165":"1tAoOX13JT8niHGiaUZsD1rptnFIBvkW5",
			"166":"1N1R3cmrS_3tUv1xFwEyZH4fEYyif-Xkv",
			"167":"1RCo4gz2oTJxbYw08O5roVGlqoboe-AIY",
			"168":"1zoJ4pZsDrFiRoTvd27jZNv7jZra4Bl4w",
			"169":"1ffLKp-_Lz_qrhDgE1bDLUX4B3WhFA06k",
			"170":"10XNdDabOzpGIltnTTvvmeyaaLfMwv4Tb",
			"171":"1oFiINykiq1Smm6EHAd6XvprMxcvwvTiz",
			"172":"1-dIfZFJMX2lOcxnyNek1W915s3VioiDH",
			"173":"1l7ZvGj6tA6zPaJB4XfCwq7lT33F8GOIl",
			"174":"1UhRmFyPJWnMS9XroZNFpZbwR0938alBt",
			"175":"18G6UqQ68Qme7n4WnKcOeqvWq4o0XNlQr",
			"176":"1N_0zSzym7lDURMHEDtKEcqz1JxKhLOKv",
			"177":"1pvYAvVtrK09dHO_Z1hctHU_hWpv86h-B",
			"178":"1ZUXd-6QHeY19sz6kGuAs-vgfCGAvKINH",
			"179":"13kP_UhH34O96t1wuXP8_WaARwfvQpKqh",
			"180":"1H0ICrn2zh03fFgi2ZK8FLSXjPmux0JPf",
			"181":"12ayuI6-g_iZVP3XMgWV2QWRtyEJOmqtV",
			"182":"1ziuM2skiArMqD2VYsq_d45KdjffRN7xA",
			"183":"1jq9iAsLKhklwDQrLrXNkIuVHzHyEpvxe",
			"184":"1vzqaLJY7ZylCSCxVIOvVtdvbTzR1aJRo",
			"185":"1qtsoPN0bvKRU-eT6pc89wyOanMcd33FA",
			"186":"1ehuBLQhhf2Un-G3Tt1r3qOeuh5Z69c13",
			"187":"1Rxz4KAGii_srKkYr3V4zTSdEvPO484H9",
			"188":"1I3U1GQcGhI5Z03Aq3juaNLRwYIVx7HTy",
			"189":"1b_pek6Ma4UWqk_K3CZseX--e0QLGGm7W",
			"190":"1KJUlXfk3rcDf5Xw3wUv9-BtkOE-ijDtE",
			"191":"1l0Tm1xCx4Lm-kxZRPfm6nESx4D6bwz9V",
			"192":"13VDry87L81fW0v39EcTWey2rhm2hS-yj",
			"193":"1zDpLItVOTJ8qrvVfN7xoHm9z3h5GTYBd",
			"194":"1FCw9Kg2sip1vMsh8n2Lj0gYPAlQJa6KI",
			"195":"1awi8iwHclLssu0p4gtcAWW4CrWq7hnYc",
			"196":"1s0Ipz-Oy9njZKifHoPLpTm4yfXFlk4gu",
			"197":"1eEWSzvd49jHpcIvV8AdtiitnohGKqVYL",
			"198":"1GTnE5fyNeT5jGR_5Kyl_B_0dleIOJ8p2",
			"199":"160fhFxDjF15tN6UUUSLboNzc5Z-XAVRV",
			"200":"1fCe1EVd281cUElnLRxDa2R7UoqmSw_4s",
			"201":"1z6ileOk8yEWcOGc-at8FATwTyCHcXE5m",
			"202":"1scmr88AJrIfj7X0bwv_i1hzoO3VhRMxR",
			"203":"1ST5bAV4uzNhxQiyjK2cpOSQXwNy6f-YV",
			"204":"1hA2moYCow09cvwxw5pBdKp_Sy-Euk-wI",
			"205":"1_w3VgW1p48DXrAreen31mht58vwgmvZl",
			"206":"1NBg5cBSXflzuLPPNqHTirYnGDEF4PfvU",
			"207":"1y3Y6P8DSFcQ0tQOGSwYb9pb2yFDkt1rw",
			"208":"16SltY89JJhAYG51adydtxQ6BXcpXu1SY",
			"209":"1W1UYXBg_OkIuW_2CVkBEVOXwbluGipaw",
			"210":"1LXfaqEOzUh-RJIKj7XDYPq86i2_ZGiJU",
			"211":"1ArPqXkdgs67bGi71AIr_P7W76QhaG4zV",
			"212":"1psUbgUbAqcGROHEYkK2JKYCdibBLoYN_",
			"213":"1cQOORg2pDaYYDq85i_8xFR8vFytP5nuP",
			"214":"1oGOmpM_sjSpXTPzf96y__a9RbuBWMACY",
			"215":"1CUyepOaw02RIZiUOvUmJVKUm7f3qfriy",
			"216":"1XA42qlcfs-vQDvbtt2Zf0iyCscYaVeKg",
			"217":"1JtN4gArY0eXaCKUxZ8PNwU7GSLNjwEzg",
			"218":"1v2L0oA0XzzVrcE_0mfDwC2LGVHX8Yvwm",
			"219":"1VHT8VYmRlcH7crARndytxu6d7itehXzW",
			"220":"1rGbi9r2TqrO7jet3IYmJ7tdRca43qxyb",
			"221":"12b4p6gDKsm7QYUjO5vld1hJwuK13uzj1",
			"222":"1uqqibBJHUOnUsO8j_c2rF9y8qb1NNimA",
			"223":"1Yhr2jfbGUv5qP48yR5PmILyaA_yd8Qpa",
			"224":"1FiLJXB-GwlLeYRlzhfsEVvgbXSOdutJF",
			"225":"1qvVTJqgCSoVaiDiaWkol4zgQpBQzLwdX",
			"226":"1GGWB14D3fPOI8iXM0sYnhdwPEdwsJJ79",
			"227":"1VOf_Es7V5V-MMOrNOLE2UYNPsJkmKJJC",
			"228":"18oUXoyLP-l8N9v7w0ZkpEN9CQmPoi6XQ",
			"229":"1e_mFYej7QmvG8PxywoXjO8Za32-S4NGS",
			"230":"1PMU1XEczrmK5c5L0AjjgwM1lVBDi9shf",
			"231":"1-O3838ljC9PV23AnmqcCIIueVljHSUaY",
			"232":"1sI1bBBwfQDINsqnSgUQUqjeSsxVLhnRB",
			"233":"1QKu0VlTb3aSYFJbFRBrZPxV9O5txoqfx",
			"234":"1zaxyds-C6YhQ3uSU_lojhBzj_oKZZLRZ",
			"235":"1Jq40N3um4J_feZWXXl2neMh4Oy6SrMQZ",
			"236":"1Adai_AN1rUhifmXy0lAWrkrEKBH0gTgX",
			"237":"1exm9Nyi-_IqZDbQkUDdK-ux74wlz3K2J",
			"238":"1WZ8vooyX98cNFtrcjPja0eZVfxuCym6x",
			"239":"19UZXIlzORuPLMgUayP-C8Wtpg25i68mE",
			"240":"1tptgzP95pLUi3aN0FvCayq9mCsE5utgB",
			"241":"1PyQQ7664t28cHqovaSJ3O_WU2eLnaYnd",
			"242":"1OUR2JB4b4cEy8iODSQimq2AGx1RwyUv-",
			"243":"1FsSaMcKnxn3PdwVaoTck9AX0ybnv7gS4",
			"244":"1ByIHXwHVK4VGQJX8PtNCusfurUabPITj",
			"245":"1JYa7h2-i8T2rXGnlNBw8iVw3GmR87P3R",
			"246":"1L_83pUt8PSXPhXYjuqG2ohpAUpeLtAZ0",
			"247":"1djMJCFg5t37th0evOmglWTYhUk0guSyK",
			"248":"1bGJnUJ6fIY0njj4AB4FCGby_BQvIjoVP",
			"249":"11PuZz0UvoT6PNbT4chdBw6duOtlhehUP",
			"250":"1amOf9qtHydVA2vulTu_wplHo-VIij3td",
			"251":"1FyTpjuQabPbYYwqSt01HK4I0zaemdPVv",
			"252":"11F5KTmx4qkME6IBmh3fFjHVhC2GyYgfq",
			"253":"1Snk_Ipdxivfn6W9-BYnGpOGnfHoKGfEe",
			"254":"1LUevITpT65FS_jOfs9SeFFSJG-P2tzSx",
			"255":"1X2m_tQo79NmvBdwFdT-7lQIVHWwNE4ej",
			"256":"1lPbHRsIKgInL3vHLlOC-OMLgi0kUvM-Y",
			"257":"16aOlonlkujq4ODX2b1GzzHjUEWElYXDl",
			"258":"1elcbirf6F3tcz98DP73QjnQf-sI17dor",
			"259":"12Y6-Re4AZGQA2PWALaP1ny6x3tiltTMN",
			"260":"1fC3rEq4F9xIfqdEgQSWOXskO37tNSpkr",
			"261":"1FWW7KSUvRvYjnoupZ4rcPhpT6yZyPWkZ",
			"262":"1OaU985Z0ju0nG7B6YQeUwoqqt3jSnYgA",
			"263":"1CA5ZUtNzI0HhYfDM89UzXuh_QsQTyPzq",
			"264":"1L6OYVb_5eu-yOcOEQSqZTZp6VSoB313p",
			"265":"1FxGDndknhPUgHf5C6NW2fJVGPfkvCR-0",
			"266":"1aXY-ywZonzT9SD2AAQYGp1dAlpBFb1b9",
			"267":"1Ni0aox0nwmnDz1m8nNze-0X4GP4SH66E",
			"268":"1JvrP0jF_wL3INsxxcOWo4z8hzOel82_r",
			"269":"17AQY3Y_IpLeOUY5lu01zbnWhos_oYulV",
			"270":"1dpPhZibd4kNqpsovkhHpCzsv3M7b8S3V",
			"271":"1QPEmeZXF_9o4DIDMV-RgqO8zuY7Pj_7N",
			"272":"1ZrV5oSNkNLRGpWjnxvkfwJm9XRkIBzPg",
			"273":"1xPoIZz0Hj388xFKKEFNpmEKlpkELPNA-",
			"274":"173g1tjiXWKPcSVnq5MZ4-0c2Up0FQLff",
			"275":"1whFIu_rkrxWU1XRZN-8WrV6zU6hZcE4X",
			"276":"1HZXBwinFD6rV3ZGkH1cYFA8FzbAqq26l",
			"277":"1K02wXXgLUeFTMlM6_fwaWVQODaZjmnNH",
			"278":"10_-41EGZ3VmCk2qgE-WuVhHFMzrMn6Cs",
			"279":"1dnU8BxpbVUO9gfxVikVhnB_gqPmooOxf",
			"280":"1BfwXNVVdS8mhe2DYgvFhWNNSF_D9vZDi",
			"281":"1XquGshHm-oIWnvoSpFjkF9YAmU-Mv3gY",
			"282":"1TQ-oFD4BeNGgNcduVy4HQRKQLZB2QHAy",
			"283":"1qi08sRAG8o7qIoz5Nv4bsyNgcyhJ4CMx",
			"284":"1EPyase6WzFOBuWvk0BCAD80fC-HULo4M",
			"285":"1V3h4F4kw4bmvnexGw0xfPsWID6IpqqPL",
			"286":"1Ja_0lawP0ULbkgX-MoweQ8JUaGJsmu3S",
			"287":"1nul_O8_b4a_1-l-i4d4_IFG7HOdyAFuZ",
			"288":"11dEEJCn4cyc5xMqnE1IS5-0R4hRdQf3n",
			"289":"1csNJc3QUtovc0tUJjK3LCLwOf5A-h74t",
			"290":"1OfkYeNWxqCNTB3QWad_BU68CPOlexhye",
			"291":"1_5dCiKc998g0eshUiQrvTEpL-aiHBl4i",
			"292":"1-CfHItWhya1fdbZRCku2TdzFPjkKmQgf",
			"293":"1KOYPDFgBCKJeoGgmw-QUDyTZyMIOtdwI",
			"294":"1cX5B4NKZDjz4UkOG_dLK4Ntsb6FVxHNZ",
			"295":"1Gw-xgw0IIY29jPgPeX4_YXP2gv5cuXco",
			"296":"10cD_kvjb68Dn0UgpOe7FfGn4gZ5h7rOM",
			"297":"12LEV0QE4NP0YccLYl6Vh7FeF7e3LFynQ",
			"298":"1ylMPuZNXlbGn_iLV1mAS-cGQALimLTyJ",
			"299":"1mi-IRow4EITidKcsxN8XaCfb4gl4INyW",
			"300":"1HaTGZ-u_yuO_setE_qEeP_jtWdexrPhj",
			"301":"17hhP4hkENsox_GQCa66Awb84cmwofe2E",
			"302":"1sDwLG_R2UXNboB_pbfmKNd1A48xZaEzV",
			"303":"1d_KuNtxELf0KsiUWU7zZC54PhiBL3zWi",
			"304":"1VGCJYjLmCzkRXjE4BhtFUxm7saqHJWxi",
			"305":"1VvKM4e30uB5_77a5JLcZiZzIrH7cN_Uv",
			"306":"1F3lv0FjiARULNLjfk-HOPgY1avoE9uSk",
			"307":"1QfOIObSsLRm4x3_wW1LIvTZiNovfzDmI",
			"308":"1F6F1yIpu-YSaNzwIPT9pE25BSrTZw1cf",
			"309":"1PP3nqRSO7YyEdQ9EniCfGeURV4A7dAhu",
			"310":"1EL1L8H9eyBNWNKmmbBJ_EhxVDgJQectX",
			"311":"1JtBRX5qX1kcrGV4breci7aUkZLAvTo_y",
			"312":"1zr51spVZIbmwYgg5xLYRUNatTUydFvSv",
			"313":"1XrjKgXhk_TX9Zd5UpDv_tu7SyWJgXPkK",
			"314":"173aEfZjFrW62rbXBpvxUK9fEPTw0sv9I",
			"315":"1VxrnxEieKM7z6vIiuhJpATsmImBgvqPE",
			"316":"1WTGrzgtzVf_gGZLmjAnvIdAsebAsOqMu",
			"317":"19wBQHB4cDeJy7QhX_Maeezy_ogptqBmt",
			"318":"1te-vQmvf3B_v_dsmaJSoHNvJyh2EfhOu",
			"319":"1sYl0-WF1DbOUoADBzlkIb_2tScKEwqYR",
			"320":"16BUqJBCVdMCeAu0XJ8pivYND20DyW41r",
		
		},
		
		TGI_Tempino_regulating: {
		
			"1":"1w4H5DP0ruvMB1UYkmeZ8yfuRdGPbTGrk",
			"2":"1IY1A_yyYbw_W5dk0ijSj3SZsk1cpDv_w",
			"3":"1j74wv-HRqX-mJvFWtilv4CVctRT8Jum0",
			"4":"1oi0on3Ta3YQCRLS9sNWDDPw1PzLOiiQ8",
			"5":"1XshsOqy1TYL3bjAUlQwCXlMMtudwsMdn",
			"6":"1oLE0SmfFa-BuJOepbrClgGY45bnaTE16",
			"7":"1DIQKe7A9QZWcRe_sQpuPJofKJpHlwZ6g",
			"8":"1uqPL01h3ubAslZ8zK4pUrPELU2873nuN",
			"9":"1O-aBuSoJ0OIWLRwepDW3Z2mdPxE620U0",
			"10":"1H3hf5R0vah9bxvSJJvV4ZsN3PD8A1rfB",
			"11":"1RmVjZOnnvMVqANuY2Vue2E6Rx_UCgeZj",
			"12":"1WDFRsMUiTkvbL0pgRBlRVFVWurENp0Pn",
			"13":"1Ngh-d9vY8wut5Qa8KwH8x4fOy4bHBCOY",
			"14":"1e8q-mG9PeZmu4t1_ATYjzFy_YZwm75g5",
			"15":"1uh-D3AVSU6aeuIghyIduDHnbFUyLQLl6",
			"16":"19U4ST5-Uvmo-naCt3QRFvu3RPLGUmc9a",
			"17":"1VbWbmBanjZfzZHW-YAMGgNwlZEdECyQV",
			"18":"1wfzfimkI7Q9waSd37hQdkDEHJsltJPsk",
			"19":"1Lm7QR4H0OUb93g7x-6wliVmBJ-QJXZ8t",
			"20":"15PISFyRNlXTBIW6woxxqOuJoQEY0vJtR",
			"21":"10dOXOY1R0bGTHjE_WI24ez5TLcITvLEb",
			"22":"1dKtEyjPnu8THcW1YqnsLxmGiNm8qGLcP",
			"23":"1ldVZgIGYZ5oiDEejaE_7qX556IUwXqHJ",
			"24":"11qExI38FRmyV6HujOrGFFqnj1hErZ1SS",
			"25":"1Cn1ea6hwJoAR7RnKm1-sIEUvExrtVjQ1",
			"26":"1bN2qv1qI8tAG_TGiyiuWGid6emXUOYwR",
			"27":"1o3JB0Q8KoRXU2n1_kUglrY09srvMLSMf",
			"28":"1kV0Cs4hxcSbrSNCei6WJy1KrgIpVq8qC",
		
		},
		
		TGI_SAM_Simpang_Abadi_Metering: {
		
			"1":"1ZTqgifpGlZy1A4ciXWmkSJgPVOVYeapz",
			"2":"1ixJAwkmeIjIpuQh_3md8DRUgL-etL_lL",
			"3":"1WatoV2JXXxQ4I0QdXtRv56JZ-peOV5e3",
			"4":"1hXgqpwZ54wX9gXdX12nUCRzSA16Iq1Bn",
			"5":"1_kbex2GCwYxwy_ntlvVzqjXUUNBO9L1m",
			"6":"1vbje4yrm_qiXP59FdT2VxMgDc-UkfCf6",
			"7":"1Fy8Zh6fWk1TFIOXYXZ4T0uWo2UQO0XVt",
			"8":"1arEa-oUzm0-cM772RiNdbrzZn-SM1Uxu",
			"9":"18uA6bdQww-QSeaGFyRcHipCfNxxPCveB",
			"10":"17FOmEDVNcAHw2PNrVDTDtLDCUCpNN4Kg",
			"11":"1sImdfbVq4EFhdRIK9tggP2k0CC504XOT",
			"12":"1GZp5twsfpKS_pTpSeqttcWFj31X_S16T",
			"13":"1-ZxAbrNyO3RPoypSIM9GubxSZHjHjfaH",
			"14":"1oVG-_PvHCx1kwTNUmDOSp8aNwUtNwjFo",
			"15":"1nK8W6P4-G2KOeCh13ihPQVJbZOJC6Ejx",
			"16":"1TJqiKHOlLxp3AZ6ZmqXip0iLs_CIpHo0",
			"17":"1tKE6TRdrdpEN066Xjs12x4MwuBM6k9mJ",
			"18":"1KaZ88Wvzf8boCjXZTHrnbAWfPZqM2cFk",
			"19":"1_7yQu8iG9ezqqwW7hHn5-LkBAjPt3lik",
			"20":"1jbqAQe_NjicElbfEh4Sy2MRhJmG0vGXP",
			"21":"1q6Dk7GQoDazBIINrhH9wW8yO_rRmN5EJ",
			"22":"1QudmP0uobOOHIqwPn1dp12Q-zpwI5R3c",
			"23":"1LqqKOkCD5R5Y6a2nX4yThK1mVojTrGhI",
			"24":"1BA04Cm4uvKtNIv1ktd_VQXlW_8517IVY",
			"25":"12adSRUk_pQNYy8EtHZ97dyUci_FMePqZ",
			"26":"1_gVOxMuCRCXENKW-UZqoNCTZnb_yno6L",
			"27":"1XdbWwXXDNUPFL1AjdlxQ8-eQpiIPG5YZ",
			"28":"1Xm9bdSfj-8403Ht9YAlSR0-w9XwHMDM8",
			"29":"1A6cpPFEWKzE01n0jI4MRLYNvVd4SqZCk",
			"30":"1hZ7jdDkDJxju3fBOdBj3P5VDt-svyQBT",
			"31":"1_Xk2hfk6gHuzKR31DnVznmu6LnqQWHvz",
			"32":"17bpKqa5fqjkGyCdC7xnyhrjJT8Zo49sq",
			"33":"12mRIp5FCpWTCYut_4DqF_VHWNtbsNU6Q",
			"34":"185N3np3vwLYFqQlDcItqW6g-iewiSV7M",
			"35":"1-SkVCoNk2wK9bBefcQFpFphdTUqk5lwr",
			"36":"10dR_zkss9sHvOtEXO6Zi1g1aQaCnhOG1",
			"37":"1Z-Cfz7xZ8D-uJLtFKUp4jidDdEGiV07-",
			"38":"1n7jS5kHB_amhaUXjPErLr5yObUiS2zhK",
			"39":"1HU-qX3YKVCBqHCc3xv56mmRuMu0WI8rk",
			"40":"1lzVkKmq8OJKowPB9U6PZH8QIi-otNxFL",
			"41":"17HjBHmewsTflQ9dnzuACDWuJGNbxzkLy",
			"42":"1AVwp-Qml6TEx9lwxXXW8RXwGN6yhJBDi",
			"43":"112s9FhWYTmcKRSRgw78IaLINtdsZR50G",
			"44":"17gGV0BNZ2tt5iqLutf8L_jBZJCLBJXSr",
			"45":"1P5_xQE_Qt3sQdmkEKi7mPOTfIm4Vzsby",
			"46":"1_XzumTrXf5ka9j-xl8mo0OptFsqEopfC",
			"47":"1ylB9KuM31xOadCTCjhY13cWexjbYnVIa",
			"48":"1sbsevEmkwQbF5gfMxYcCeHGSRjXR0LEc",
			"49":"1u7ygWzpIS3U0Th2SRLd69GUvjbydDdJl",
			"50":"1Tl0DMPHCInYjaL_RiC9k63AEKRaM4Q_z",
			"51":"1j25SPx4cmkS68BrE0e1yquMeAU-NHHEu",
			"52":"1R6NY2uQt7COcXp11GjXO_S1lNIhv1NwW",
			"53":"1MpWLv66n8vHyP_CMO96ImmzIXhuP9kS6",
			"54":"10b-_bkSo3PeRyjLF1I-oCUOhgQ12RTJi",
			"55":"1MBGUFdL6689c6A7M6U69Eiec6Yzhia-U",
			"56":"1lmNC5xlbOgrrKJVviilYcgSsgoDYOwSz",
			"57":"1ejsAx8vpH7TZ02kBgG3q6gZPy7_qF1hg",
			"58":"1GYxv7E5fCku8oGl8gx6GFBOKlWaMlXaU",
			"59":"12PMAy48JWEG7-__YAW10qCE99NnnJ1EL",
			"60":"1_r10zU1fnH9cBtU06ty21xSM2_CLDVKF",
			"61":"1By_on8yvclfcmWxJnrIDd9NxS8xTJgTT",
			"62":"1xydEvTqao73TT-U2yYJ46ln-HH1x1hB9",
			"63":"1D-2HU5kjXwrIAK54XSbKcxruSFwenYNj",
			"64":"13lk-8spDm1hCLUZETsT1c3wg7rs0dQ7R",
			"65":"101pwjaISLYuEEG16OIvuI7Z8I-4-dGKC",
			"66":"1vlvGsKO4KOi41k2u-nd2AsAYd0VqXRw8",
			"67":"1OiCY1YSPMpdPIajHcl3Y7GE82_KHgOMh",
			"68":"1fqstjKd5MN6IVIasCYnJ7Tpt5htG4jM8",
			"69":"1FmQmcaDbG9Sm3bqYtm9GyHRQOhA9eQiw",
			"70":"1Om_Ozm8h7smo-9weYv_SgxB8-l9ST8Id",
			"71":"1NYoTlOYVLotVhIKin8aK--cFCZtPUJrb",
			"72":"1M6ezhK0XZk7Pj0v52vVcolXqAHdZRXia",
			"73":"1pQLL60MjSyuTBDSToxo6cgio1fUwOsX1",
			"74":"1o_cR0zZaON9tiNMltFA3dA-ueE9ymyNn",
			"75":"1ip4pMD86DjOtIKvglNU2P2xz9m4TB3PD",
			"76":"1iMjwt3tUPJlvKxmwneRTgc1pKk8Gj0Do",
			"77":"1xQ4OGBIwXt4G6rDqz4C_4wZPMjOzpqKc",
			"78":"1Rme9WgdR7WcEbn54R8biVZEq_h2SLtTR",
			"79":"1RrQTIurYyC3flG6536pnuRf_sZeKgyPS",
			"80":"1m9LE1SJxi1ImQVD8QrPst1YFqV24iPJr",
			"81":"1xItPAaHdk2vhbvJfBH-PZ6i3Iw6XgXm4",
			"82":"1nWJ04aFJjGdFeIyb5jeqL9q-T6HkFk1D",
			"83":"1cIs2JN8DtKN8lH5yb1Zywu3eJoBLZPjk",
			"84":"12raImM9vfVXI7v3PsPyOB9d--485qvEY",
			"85":"1M-ztW_Zn26t4rSWUhQ6HAofaG7fw_c9L",
			"86":"19AD_O5AjjDz3rzzd1T7pClYVGPbMrfMW",
			"87":"1YpXC-zle_MEYGAuTOkN4HJpV4IQwrNFa",
			"88":"1661HGz7Wrl75Mu20umIfrcOM_mFsLFqM",
			"89":"1OI5SGtBtbDTWFSBob5_QXszCOYGy0sph",
			"90":"1WU0xgjaOYfEFmP__PRaLJq30x_eui9-h",
			"91":"1SD3fZIbVIvK8mBh5GqHlC2ARWICkVXBu",
			"92":"1iHHxYUc-oAtELGrow1-bOEwDjSHVd2oY",
			"93":"1g9sSBWL4NcsanHw05NKmGcE9Xxlw9oV2",
			"94":"1LZ2trVdhBULnrSafe7enN5S06Vbjip8T",
			"95":"1NP_lewRIisi0jtriXBzrNz-v_gSR33yK",
			"96":"10DlEo2b7mMaRQmEknoeD8D-eZaPQdsOD",
			"97":"1O0vlRkjhEDHVTCjxbdWFUkou-vH0n6mO",
			"98":"117fX1NJVTUmfUryLLuJOtkP-5n6vbhsJ",
			"99":"1KP5uzguPzG_KoWcfDhVOf-zvxVofkfjm",
			"100":"1SI_QDjzaCAANc790HEGkTrVuIo8K5qp2",
			"101":"1YB0Hqu0KrGnF0-iPmC5r_Z8Dh1SVQj5k",
			"102":"1GlRiC61HzDydCiMas9phvyveUmRx7-h5",
			"103":"18S7lTUJBHSVYmGgOFI7hqazyFSBGl-Tm",
			"104":"1NcKAlOSKHwuGYCAn9g5cI3_cSIqslBeD",
			"105":"1ebXrrzVDejrX3Cvq6HtkLnsHMsYdDBr9",
			"106":"1sIotMqbBtpf90cOUCbykRlY7-epo-NKV",
			"107":"16rbiN1Wq2v5mzXHoI432dQ1WWx6YdEKP",
			"108":"10MUW4ziDdY4mAraJbUFxRwwIhOOUm7ss",
			"109":"1Al0MLEy6RBlSSrJF5f5FMl4eGfymCrTl",
			"110":"1kIE5v-ArOmD26ZebMsnXJsNl7aQTptkR",
			"111":"1g30Z5sEJVXXXNBn1L6aouoWVcsGVYoxk",
			"112":"1V5O7Mo6-xkGSfSGqUV8--ldg8RJXThN3",
			"113":"19VqNCSHAOBVBMmfecR2znLdV65G6WIx8",
			"114":"1rdua5Hrg7MHfIRxxplK-djRr6s_q6eKU",
			"115":"1DYvwCo6XQdNV8EkGvA2xRNvRduquPQ6_",
			"116":"1UmRKYAl1n7nBRRoe2d9fr2gcPE6F3BmI",
			"117":"17CplTW24RYxxIh45C6aaJVnf6XSErOOV",
			"118":"1TtiTQVVHjgUqBjKtKOszgxHBAq2MNsWb",
			"119":"1AYcgvzNwuuMs8en_lsQcmtGTxhuFnR5f",
			"120":"1c70quMq9hm9y3xKTVpoDvCQzwHJVG8Su",
			"121":"1TbJCDhlilMCuTWVaZf4lkpOptpgdsyi9",
			"122":"1Q9Y1UA2x-rra9wNW-bBfBJQumh9so2mI",
			"123":"1WDMpGFCyMt4mCcwsf5avOCIQlIqXAHC1",
			"124":"1Mk_LQT3cqRmF7g-F95qIoxV7nMawF4uL",
			"125":"1ouBypzDlGLtcFPaDECk_C5W93qEbtMet",
			"126":"1SyJWc0ixDYvXjWZYqtrNFz9aGAQZXYe9",
			"127":"17VFBbObt2jHj4T3UexOHbR4tiVDWRdYw",
			"128":"1Zqaf72-GIf5ncCBvEmFjMbohBn7ts5Rv",
			"129":"1_IbhuilBWXavP4Z-xwVVMCA0I4_iJgRz",
			"130":"1oRqvGFFHGW-EJeQOnp0P05y6MWgZrdtl",
			"131":"1gLv-dFAFfsQcXluae7HKDSumkIzt-4qb",
			"132":"1ovNrZBNh4n76fnTx63KwjzxJGbGPRig0",
			"133":"1K-2gphZfOqK_oURdXadaH_ZPFTQ62JGi",
			"134":"1FQJD_GKzSNyUEGaSVjbghEivRX8t2beu",
			"135":"1lpirj8OPEFA6tUD7XAZOy-T-ZF-2RSks",
			"136":"1WopdkdP3qBH4ILqm2K_4ZNDpAQAkaxlz",
			"137":"1rXreplncWcpta8FrRNIfnCeegN48lnMY",
			"138":"1IU_CoPGD1RCmIrb0d9yYCpb6-zqwha-x",
			"139":"1yK1tepFbNHpDV8ZP29gguhVwRCxiHTMY",
			"140":"1zTcnEcaA22_Ov7YlGRqC36HkbfjEHNSr",
			"141":"1L2c6Loa5o_c7TL24deNBLH6AQ-Jbfzjn",
			"142":"1w70igHem5K2MrPvVxU04fe5IAi6_UF6T",
			"143":"1U1quKwKTgUSNbcmK067AZDTdJgwxqJVR",
			"144":"1Rbu_oGvhCOkkMCPhNLiQdhdBBWnDtkWK",
			"145":"1IXtF6gGsiyIo1fIlxzbGnpVCS5sneykn",
			"146":"1heAxE_4KunvxUcK9VHXHq4Eee85QCnoQ",
			"147":"1jkw7n3VyDZapnGQtK0VMTwiPySuhcKSv",
			"148":"1lH0V8IsSdAHl1GqMiqLlJVXE46QR5e5L",
			"149":"1iPQ6X9djiw2GLiqJgDP64LkoAckgV1XN",
			"150":"1wTHsfirayTVfqcGJSPbRDaazNKvOEfej",
			"151":"10jgQ_zRZyj4OBLiy2FnjDXBQjnHBS3su",
			"152":"1T5-3yPfbKcRwO57DN31lXhNU5NUB_Cgu",
			"153":"16pl_MTsYz0ofxRXPABbsLVXPf_o7s9s2",
			"154":"1kEH9gtdAvg50YZU0pRA0d6p41ymVk25X",
			"155":"1n69rl7Ac88gQHsS-CRKzekDo791pi6q9",
			"156":"1YGL1AYyxtBhGfBIqMYukX_gk598o4kjz",
			"157":"1J0lDA-Gq7TOp_WIFb9bMvHOsiPzpfujr",
			"158":"1u1ZccNEU2O8BeoROBbBJ3O_2aaV1OgFw",
			"159":"1ozC4ZKrixJUD2NWKr4vJ_PBd9oGAICQB",
			"160":"1HlLWY9g3rqnPZPK4N2CESxXkCzn7zKOz",
			"161":"1emlWT23exrPBWcHdDIZlm62fLKcZpNbk",
			"162":"1sk5j0IRsQGmkei9t_MorzaFEozMzmKbv",
			"163":"1yZ864UYZ4mXlHOU4bumMvhTH2AIZ_7UE",
			"164":"1dI16gjKzQoBVKM1b90XCfJSaB02cQtfF",
			"165":"1uAlGTsuiI1RZQQGRKsBK1aC63kJEJL10",
			"166":"100Sr0leCg8YTG5mnP-bT7xBOxZ5jN1J7",
			"167":"1MHBeWGaM1qj0MDb8hVnsOI7grZ3U3iiz",
			"168":"1M-XPbkMPwH9UdXJkgfaDAWbjz8U3msIb",
			"169":"1G7LD1ItsBnTg_HJQBHrrYW9ODSFt_a2l",
			"170":"1Ove8vKMlPuifLgyQJX3tlFv6ei-iAL-N",
			"171":"1XO03yWzGfLbnQmNuiITO2jUePFNsmHWw",
			"172":"1GhuBK-8t5aHL5-X3CQmqjZytX6hknyEb",
			"173":"1d9L_WCgfBGke5cvI3DX-gct6zJ707bze",
			"174":"1NexVbtrkM9EOO0A93WVOnOohnP43vIVz",
			"175":"1jrvQVZ9YC3FCB7oLkCZ55psa3M1FRyR6",
			"176":"1Png1Bj6DWCJxT_Fah-_pFynJQRcn8f7X",
			"177":"1sEnu2zPSLwnrbcCYvwITrVUNsqwNxlRd",
			"178":"1LT9hawlOafWR8-yoT9rnBalbA3UpaWJl",
			"179":"1DAoxK86ZEDd8kCO_cT3tIlaBDH8rb_eY",
			"180":"1kr3YMCcg9ijGEedfMGfCOIrXCGF_Az9O",
			"181":"1UbdHtdQpwO26_3fgn9Q73QwEtuteI9aP",
			"182":"1MD9EcZskX_OPr4CupI_9vTqJSST95dES",
			"183":"1ZJmtU2vAvNAXwtRWe_VXd7qh-JezK3ja",
			"184":"1nScR0rl3rnbmyzX5LQOYvM0RKzSznWJC",
			"185":"1d--9x4fZ57PZ0K9c2eKWHyVLLCJtdnvt",
			"186":"17UOS9ZOV7H4sHdXZkNa0DsAwqdozRHnM",
			"187":"1lbXfcuptNb6TBxQPNyCQXbOGCccD5Kp5",
			"188":"15BjOhwLjdP8H9NVufvhtG7kMZbwEmc9M",
			"189":"1jFuWZUWuw7U4oJTaP_iy9njiOOjWyCzf",
            "190":"1VBDbwRJGrARLHuhcVA448wSa-V2KFxuf",
            "191":"1zzK3yMwXHAyuZpXnoWtMYtm7OHxHtiph",
            "192":"1nKAINqsZDtGYffdbif0sGkN7XSjC3pqS",
            "193":"1H20HbFQZl3xZVB9envs4vQthtmIW6K0t",
            "194":"1SgCbnOr92HKho1bWi8b9IZIFuI13vAo7",
            "195":"1rxeOoaXFrsIsCWZcqVkqVJVmxYrJK-r2",
            "196":"1fUL3W8ulLYXHCc1H72-Hk1It_HIF6tmX",
            "197":"18biHsmgkrcXZ6LL6yDUGkXa5_okcKcDk",
            "198":"1H8PZON0zThXKQuHyyiWpHgSGIwz6DwnJ",
            "199":"1n1U7uNYmX0fiqh1tLoAOZ_TsTDpfmiFp",
            "200":"1Spa20DDHSZcj7ZayJuZc3S2nWwVoG493",
            "201":"1dip9KcIn73z4HyAFToMV9fKgTtxfaYOz",
            "202":"1iyFp_PuMC8_r-dkcqvHZc83J2-druHGa",
            "203":"1Ks91I9ixAu3fAPuvySSnDeADxVVoH-9b",
            "204":"1eUkSRVvy_bIiCzNUvvP4Uj50sfW4uPf3",
            "205":"1ROKxHHh7kZZbj1945d7U3Gd-NhP8-E6Q",
            "206":"12bi4n091EZDwlwIo3UdrFiCw-aZj4oRw",
            "207":"1EVUJL5CRd9TmyNQf07OC5dN__wGRl5O0",
            "208":"1-q8VhleWtGTitPk4OdwmGWa9HPMT8ceE",
            "209":"1D-LapQlWxlb7HP-ZKVU5TfIL5DB69qdK",
            "210":"1oWhT1lNL84pSZxp3oRLTYogmrq27fc25",
            "211":"1tcRtGhBzQ5DtIDKUbmIJoPEZJ0JwhLBS",
            "212":"1dWfSX8iyRu5KVBGUEMEKdlf8QP7yEPeZ",
            "213":"1CSmXmrE48lUcjNTvn5RZiIjB7MAfOXOP",
            "214":"11Y3lQ7URs4xpufTuEir1c75g3L64LDkU",
            "215":"1tDOlhz15WybME6QetGA0-8vJfXIUY-7V",
            "216":"1A1U0cGKzVcxwFSREcrMEe5IzC_rMZtao",
            "217":"1pO-w7YfFCQejN9tSl8eYEqbBnRIJ5VUw",
            "218":"1o68IfxxoVikP4q0O-T6yiTKRoLy9rg-L",
            "219":"14-TcILnE5-byy8PJCehCa298tIjgKXtF",
            "220":"1fH-B7Fq7g-EDT6M6jFWpMXYXdftOvhCw",
            "221":"1bDPT0fnrjLbwQ41V53Nzk2hKg3Aw-UZo",
            "222":"16030v1T1CRmzEY46ugcNkxo42GzVcY05",
            "223":"1NWoUzeNjb2loZlbboVQ9Ij375UHzJNxV",
            "224":"1tqhWrkhs5uG6qro8TfU8_Jhcns30utCu",
            "225":"1_4FPIqMFrhwynzvGTriaLNgaFtY8u9KV",
            "226":"14gkgwSN4J2AiFkUuZUcogH25HHInLNpF",
            "227":"1yjiua1z6htQK8ZjEOsd1QWr8ZjETyUz0",
            "228":"1ULulS0zjmIn9RxhjxE9pSWDXT32hFBaN",
            "229":"1HvcAMN5JUG-3ywoDUZQhTJh-1vBJncni",
            "230":"1DV2HN0f5IAAJqs1ngtcvcZ-jdkt8LsWs",
            "231":"1tXwxMFj7I8y0GKRI0t6yJM7jHQIeK23D",
            "232":"1Uk39wa2_fhLgHisiZCaJCHgpKvDvwxjU",
            "233":"1mvZZfKGB5VaCYQtQU15b11dnDPweKmgo",
            "234":"1eH5SlGqq3Zn5r5UbFC4q6q_2_ejIqNNL",
            "235":"1sAiYngfuNehG5iFel1JiDA_XyO987UIJ",
            "236":"1l6TmYhmUTBCLp7gJyZxOZ9JkgX_WcuF2",
            "237":"1O8UaObCmB2-ipge2R4DLIfrhbtFgjwfb",
            "238":"1nRm6tyTD4-Cd3NT7pKgocqJJYgNMRAeF",
            "239":"1piLql9PjjPCZT2gSRZT2lK0VeG1oZaRz",
            "240":"1Tu7KpTAhQUGIQjQ68UpMFBd15baegRiR",
            "241":"1ohhCW7QSRDrGRESK0PW6jHLzyowQp0ut",
            "242":"1WUmTLjEvTihUpeYgTsqPa8StvbqnEYGY",
            "243":"1BNjjfUgm9kG09ZF7ixLyPxlW4CP7tqm5",
            "244":"1hBSd_9Iaz8ZUL0PmQVkcyHGq-9MurG9e",
            "245":"12ZuIERdF0Rw_HarbfoGLzJANJKu7cEDt",
            "246":"1whQdEb1vUBR_1NuZfVzQxmpdvAm9iPcp",
            "247":"1wA2VYxnrMWf5CMOxI7YqJ8zX6HSi5YqF",
            "248":"1t1rA7glGRPTCBGLTZteDjULlAMOQzZof",
            "249":"1cS4b7X4LUhVJf-T4o7QmrL88-X2PooLg",
            "250":"1Mbj1pXMyOkXmwgdnLuI3U5GEOgFnwo8R",
            "251":"1qnICclSR97aevxI0kVrAi7y1XOrS3le1",
            "252":"1cchMZ14rToUhCfJjvyMSYrIvvXNtDDBD",
            "253":"1Wvz2m8WWZ4ZIeO_ZwJg16amv1qLw0Bnb",
            "254":"1jeqcsrcTuQLMe4kTJKS8RbzfCBvUgIll",
            "255":"1ZRMk06ph-a-fCFSMtZ1IKmejfIPjs5yo",
            "256":"1QADbYeIhWkWCdCv-9PiLdJYfmag8ORXC",
            "257":"1CaYDM69I1_kup-X6wAJrobW24fgBZmjr",
            "258":"1ekiv6rKaIeP6LEgEavu7vsLWPdJLDTFD",
            "259":"1LigA6ymtmd_enHhi45gK_KzJmanTAQT6",
            "260":"1NtXvqK09EtpjZ1HQBv-W8l77_YhX_RY6",
            "261":"1StVE2gs_DDlDk8tYNuTlMAScLuTwMTE7",
            "262":"1eWmzM1vZRnIp8LstkpOb4EJwH33bY0-i",
            "263":"1BJtUgt7Qlotxt8ZXrUZEqNri8X_9fks8",
            "264":"1xSYJJaSFUcMXFIao9yKoo7vrQlhJrFDV",
            "265":"1vIGKVTrYmGVcQFNsfkk-m80NuYDOc1ee",
            "266":"1E_iLafAautBBSL3WRImvC7dFg6-DqrIJ",
            "267":"1p28R7Hyups_c7UtKILafds8ve8U-A6wa",
            "268":"1JhyoiNF7s2MSqeIHt6Dyw8m76HSd71q6",
            "269":"1iROjLyOHEhdfTcwqGtMTZBrSM_VnEF9N",
            "270":"1vaLhp02iZvlrwLHYA95DgMqzlCvXDwh9",
            "271":"1QoYG36ljQUtayTI_wyhnucWGt-Ff1ggY",
            "272":"1HLlsdR2tpYbrR_GPnpw0xDRo8f6yYpkw",
            "273":"1EeYGPkig17MI3-4ETur2RPi0VcKuOZod",
            "274":"1eyliLAJzq2kMPYfV0Rd2c1aFMTira__3",
            "275":"19u3rTKt39dAovo0N6yUUNQS9m4JjyjS9",
            "276":"1S03gbw5s6oR7mbJT0sgm5fjDlCfCJCAH",
            "277":"19vUo1EEqRXI8ykeHCML64KAtJGKFcA1t",
            "278":"1YzlTdO1Bqcq1sTfOF3-Kj1rwy9w1kE2_",
            "279":"1F7b_Zw74z0j-AH711oprWYXF9BcK0yLr",
            "280":"16FU-pEhw9NWsza9B--wdnjwvooyDTnQc",
            "281":"1IPBwuyRNi32MnLVeLZNgID-bVcVo2CGX",
            "282":"1udaL5pRqsT-usodQr-XrIX4RbmKw1XKW",
            "283":"1BKdRSVvF0WjsvQraExzXvCeAgQj1dnUp",
            "284":"1oHy_obZnh8PH1SK3qlMpHcH8JiUOg6wX",
            "285":"1-bTFDwq3ZY36NJc699h0Ml2OeksMVzhO",
            "286":"1dUZwSUtnmZhXNpSv_tzl3PTRoeS1CCaS",
            "287":"1kuuAEM7lnY8KlizpUNHytdHufmlcu8tf",
            "288":"1bUSP7J8zoyoUyIZlWRUm03BehyMB3rsx",
            "289":"1wikMj8zfTg91eSFBKbEq_o1tRRKWyhas",
            "290":"1kpJ2zwyBghAat_0rxTIZW04koIqBMR5y",
            "291":"1_Yrp1aMp8wvo9r093EPUhFXMHL0FAmyO",
            "292":"1nKV2e4CVdcR4fzmDQTYtngQcr8UNzRSd",
            "293":"1LARETx2-Gru5KxWvrXTjZ7TZWAAPzhYX",
            "294":"1u-OV_qcwGh252ayti9eVAMiTkLlLdNIV",
            "295":"1ZsYxglgOCZaHU_igKHqKe5NnsS631uST",
            "296":"1ctUQEmN4W-fucQWB6uw0pOUI8fgHA1Vw",
            "297":"18vGyLW9WZSXwVw-UdAjA1phoCbV4-x8y",
            "298":"1eYtkq7aICt8dUgJz9WqPiBhI_AKRNlXY",
		
		},
		
		TGI_Sakernan_GBS: {
		
			"1":"1Hrj7SV-wAtOxYHjEYfF5c973pEz4UfPx",
			"2":"1ufWZVLIzUPqUwvhwzyeFlZh4GHZ4HKXT",
			"3":"1nB5bkiATRV8zdKeU1j58laO2DRmaga5O",
			"4":"1_JNAr5Fje8fK9zjz45Zm9NZ3m27XEyh8",
			"5":"1hmxtxBMAZTmSMQybq9eFpJJHl67CyR7s",
			"6":"169Xi4yUqydWYDiuq0H-E2RjLvE3HlSlp",
			"7":"1H9Yna9hRDXkC_KATaLjVxn-WRMl_3KsX",
			"8":"1GQgXDrLOeVhSCgaDSllZasIYs9fAS2zV",
			"9":"1aSbqHcISEhHZLwik2pbSdT0sh0OF0wY-",
			"10":"1eCrLR4VUtitXDT3fTbkviNJRc5r7s_Oz",
			"11":"1dPzjQe8ZTEIiegbXY2ZdlgP3c0AP6bQ3",
			"12":"1gf0k31l9S3sIje_shCQQUB1WJmZCAzFF",
			"13":"1_YSAzA9o-mtS40FRyM2S45l1-mA6dVsE",
			"14":"1n7VGx98M8v7UVw2S-faL76vP-Y4L6RJ4",
			"15":"1DIdbVUEdcfwAR50xSO3qN5c6i0O5fPDF",
			"16":"1Di0OUib2rYc95T6EI88ieIJMeScYgixn",
			"17":"1PVDSk1fA0Sk-dkhf_QAY7q-I1JuB17tN",
			"18":"19EHFQ_dY_OiyC8Y-spro1TMp7Fp9g3Vn",
			"19":"1iLR4iy89RJgZAzOU4w2XMZJ-lFEuPOJ3",
			"20":"1DyYs-hXuf2qiKnxzvAiOWLHqXsBoyj-F",
			"21":"1FkShYHvDZlS3FcIj42Q0Cdc2TqhOsIDr",
			"22":"14Ee5E2aOZ5wQ713sNdAjZUcq3udwRsVU",
			"23":"1sPfZh-Ns11EW_UwlwmWEtZpPM9ltcWXZ",
			"24":"1BGCE7h_yt59kGFNIo57U3wvNkJamQSHM",
			"25":"1MjI55kL2EC-CNWSGt3-SWqhKk5-XGCRg",
			"26":"1A-xXFmnwo6kwAR9r9sqNWH6OOqHvH6pn",
			"27":"1lw1_H6RRSip_rLvmEqrqK5mVjbdwNoqo",
			"28":"1suRBcRpexU5VrwhpVraIaabdW0ON_x-X",
			"29":"1UNoNLXeaHAAF9uGUowDGTvGgVlA5eKqr",
			"30":"14yZsA31GxFG4Hqp7LOrx6IpP22yvN89L",
			"31":"1Xh0_qvuVmtjMH3iZ14sY0TFxrdlC5h1b",
			"32":"1e6nIjhGDPdXHqAN9esTCdPwxezcp40z2",
			"33":"17s89EGdKA4902GTM0QHjubjEo1e-bbay",
			"34":"1D-U4H3TtIIEypQoPBDD6QcONII3sTEzn",
			"35":"1TyejgEccBgLjxA9Cr7jIXGtEl50gibOG",
			"36":"1ooLL5U9C0VupuZCrOjuILj5yEcm7FsQi",
			"37":"1XdNmfE1fxcUDOkvGB5M2m0otaLm3Qafy",
			"38":"1BdJQwM6aqwPPGW_uADRt7Pc_CAuXiS7K",
			"39":"15kOzvNTGie5mUXR5TI3fYOYBaZsnUvkb",
			"40":"1_BuHWHzHten-GZanBo3SNw3YvDSp7L1Z",
			"41":"14HZElmv3Uk6XYfRMD-_y7b4AcOYs-LNF",
			"42":"1mZMvPN66Dg5AZJyNsZVAmIghuTDAbm5b",
			"43":"1Txv7pBcyi4lHFh3ffUiIL7hTRDKbTOpx",
			"44":"1At4zbGPrYjKN2vxf3J1u9FivBzFiimHH",
			"45":"1LbvUb_eT7J4TdTnZQarVN0lShzYVgx1v",
			"46":"1_y0-zRw7jjnIkkCnNlQ9ilRSZj-72bvk",
			"47":"1qk68MN1sDg5Gy7pVSfvA6tC1GfL_KpGY",
			"48":"1TNpvPdIA5QsBkUP-tQ4D3X30woLTH1lm",
			"49":"1efwdExZvZUmlsVVBwZZp8c03OiCAaCER",
			"50":"1gk7ltCHwAdextZQFWRK9h79smx389TIU",
			"51":"1jNgcMfgsjwLniDt-1lzCEQmftGjv3aAJ",
			"52":"1JMidF5l_qN1y16BkcluKFbnNuLB3kA2c",
			"53":"1j2WlUUuR9fMw5sTUvDHkLEVXlu2OyWt6",
			"54":"1NTyk589JfCATHxynZnGdlrX42kez967Z",
			"55":"1XDSCALGy4co9zFi4QMoKqFBngGq-wBj_",
			"56":"1OnO-9un0XL9GPtm9vpkLfRYTuiZTkKbl",
			"57":"1UiwKAPKkQ9mJA4T-dzKCT5DLDrZxEI_r",
			"58":"1BXJ1RsDtHMXBuVGeBxfvjXY1VErm_ydw",
			"59":"1Xz8FzufVzpaWBSjy0gJ-GUTh7XQwU8Rk",
			"60":"140flCHqh4-pkzqmCgXm_dIU9xKM3EgAF",
			"61":"1wtHpnD8w-GlPGb5U0ZJ_Rn3js2_aNFHe",
			"62":"1vOifUuMCzeP9bD4V6rhWlYgTYIJ5TuYS",
			"63":"1aeHaJd4ITHHUHAZy1Br6j7qdbC6E5DaL",
			"64":"117dx6yhospQb5z6iH6cO73esPdxWTCl6",
			"65":"1wLAnE499O89GwTMB8ICpvdbYfq7IqBO9",
			"66":"1b3L6RNcq5zlOcGw_SBLg0tMlkiSCDyTS",
			"67":"1CSXnX23mPfVroRUo_akM6bnrk3vP7Hds",
			"68":"10ZRfAhyN1OQ5pKQcFN0eeIryZWG-xt8v",
			"69":"1h0ze2o9F8dr-nrEvvHZ-2bh4vWEa1fYC",
			"70":"10unBv1fxU8fKybQn4Y3MQygb68gTcVz9",
			"71":"1XjBEcG8f29WvwDRqiSYqYeJZzRCHtMsH",
			"72":"1GKSh8BpnYUfuzITGkdEGoYjOxtkJSqOW",
			"73":"1ptWFcPmwCHRNGrBOwg36wJSjkhEQ8_hE",
			"74":"1ps62rTkRqq-oG7tV00LP1t_srnv80p7M",
			"75":"1qdrNUgPhwV6Ad9-duZ3VDCIjO0J1ulFw",
			"76":"1iiLy2U2GDDMMpvnclMJrRE8VHG633J6R",
			"77":"1i-8jyXeEOIj3pavkFUJSs0c20v0FikW8",
			"78":"1PemQx3PDnGCvXhHpFvB3LyoeWxKk-dXV",
			"79":"1sUKxJ39eAw-Rrp2IRvHAwkzl3OZjp9Dz",
			"80":"1KWAzUQbddXnZ2v2ewfrdjdzREFyfiQjy",
			"81":"1C3FSr3zXFiF2dKesQAn9FB7T4O--ercm",
			"82":"1q1vxK-Mv_y-PZJxxM6ZYC4mxcjZUc2Hx",
			"83":"1c_3ahWuEU4OWWaJuAWuDe6GGQYohMAed",
			"84":"1e4RWS9gbjhI20FqCt8oGD66Y73eaq455",
			"85":"1dNb3N4mywJI1Q8AgWkjFaCilXy55aWbV",
			"86":"13N9fkH8Omct-f_ztS9b-DNeNo16NQqne",
			"87":"11Jh1DS4tj78a8JAKgSYy329HwRDiU8LR",
			"88":"15bdKqrggzMgkoCPnbSdn2lvA7jX4g2XI",
			"89":"10ftV5PmO0WbOxgaPub-mfr9DqQqISdfW",
			"90":"1P8udwY-mnUBmS3Rkivz1irm9ZcNbcTxD",
			"91":"1p5DXWJ7coJokP0Cgj-viyPmTzsxMiIx4",
			"92":"1IH42cdXTnvGtujMz9wJn_QsTnVo1gUQD",
			"93":"1EIaqvo5pn7-sYOJWu6xZ3QGCbyHVLoyh",
			"94":"1X4y7fgwWKBb5j9RJSLgXUgFuCCBHj_gS",
			"95":"1TMotaBUyg_lwSOI51OY1FpMxD5Yr-aBO",
			"96":"1DZn0p4LJzf4MZrkpYceCZFu4UopcMVr6",
			"97":"1NBSiByDE2DteuM0SLfErwTKHuuVpG3v7",
			"98":"1ZSkEQYD0i3lZJjH-CIMMOWXiZXc53pqJ",
			"99":"1xj6KCO4QKdLGYomZMNERwGQNtEct5Q5N",
			"100":"1KV9bTZuXpsGvZCPgzMC2k2p7gs7tDD6P",
			"101":"1atLtNBa3efW1nwG1j0N1kbpMZciUjAag",
			"102":"1LXGOm_4LZjVs08e0qUqZec4-d6ZQs3Jj",
			"103":"1oC35N2Uq8eVy591zLY0jIcEaUFRp695f",
			"104":"1aQWDIl_qZfkg7eYSfFppw_2sijx_S8hl",
			"105":"1ns5sJDO-2PKmxT0zDm1dyMb7bkg9taLZ",
			"106":"1J3jrwrSf0Rx9p2AaKe5s__9xKeOWjnu-",
			"107":"1JgAQ276ygHf0FSW2r01QLOuhbXIbnowi",
			"108":"12AgYAneQ-5KXAKLb1817okpftXyK3lme",
			"109":"1Y7BcxyfO7t4WjX8N66lCeV0e5oVo6Bub",
			"110":"1vJ8BEgq7frTMTJXRm3GdnbhyJw6878s6",
			"111":"1wFuq2Bp0METvbIxnWmcngMG5eSn6pnSd",
			"112":"1M-E_f_K0BqL24KpdK3n-ZUx-P9oUN_Am",
			"113":"16pZ35Py4wSWWoe0lJWLY8LLq6UO9oTQJ",
			"114":"1UPrVVxiVDEjmVmwlspyyDV6_LKcNOmHa",
			"115":"1RnZsI6N6csdvEEvxFB9KBfr-nsHc6mM8",
			"116":"17GXQt045LVeqw0vUxeuPRpKqOktV5Tpz",
			"117":"12qSbCuIyfaKVZVMCQQ5p7Vi_Uk0dNq9x",
			"118":"1ilUvYR9DcpnVUQKZ0vAxYzDBfMP7jASg",
			"119":"1Q3kO5VVQn3jSQQGT-vSiGtrJp3bL72q0",
			"120":"1pvbDhWxUP4SJ79wj77ZyeKzEgQBzVI1Z",
			"121":"1nYFkxynqkJe1iOBY8h8QnCG2nTJnm7p-",
			"122":"1_dVnQPhPRRZ-46gQNyg7L3KDriaNtFwS",
			"123":"1SmqeRpkxdhtk0ncQGXn-X61bqgxcCMVL",
			"124":"1ldihEYI2nhHB0mOdeYSxU_HXzn24g2Tp",
			"125":"1qHOEckp1HCFMp1ue4TX9M5HO5wCx83br",
			"126":"1GYH_ZL3ubgDk5dANmbhWB9cx1ltUBgna",
			"127":"1Bv3yV87FVIT8bK1K4ZSvl2Vny0bXAgH8",
			"128":"1hKTPjkB-VSPRjn8djQlL36Eh0-xjcmF7",
			"129":"1W-nEYTJbx0BiZ0v2bw0ZrAcRjy9QNKrl",
			"130":"1onphunueBhSKlp8fUJWXcwNamRIGA37L",
			"131":"1NEhvoMYfTcPtnrYDbsOAsKSW9Vh9Me9O",
			"132":"1bSF1BEy3qn0I40MKS-dc1gnPcSKFrejG",
			"133":"1lUfwndXxilN6EdLbMBSy1eJJ_kSds2Le",
			"134":"1_LXak28Cd3OCgImwG-njLZo68MK5X2Ws",
			"135":"1vCfBnkvERXxiltSf8OMj2ivxmloJzRoz",
			"136":"1bQobSzbWhF2s8Tg0e1RBlRHn3QFYw_29",
			"137":"1XgoSLBRxkr6OeV-LksDMSBOwEq1A4D7Q",
			"138":"1-F-PZ0LW9dQDeSkGqipT1E_y_2aQMWOA",
			"139":"1G04Bd07_SmfpedYC7bZiSpfZ1iXiUgE9",
			"140":"1HtYOQhA1ffYoNe-fjejKLUlRpPuhlQUY",
			"141":"1YxHOX6AqKeh8PxX3zECkpxaXVfbVv3nJ",
			"142":"1GUj9PuXTmwl65rkl54wPU-3Yk_N9CB_h",
			"143":"1DJIZm1C3XjYei7XYFii7390-knuIMD7H",
			"144":"16yy-WvG5a_ZA5YmW1n0KKl2JqvNOFs6T",
			"145":"1hD0EP2fckNz0HTUXIxjE6nHavnjzTDBh",
			"146":"1CFWBdI0Xte9VllcLqeP3_eAdIAWokvzK",
			"147":"1v5-t5T6Zhtsj4BQZERcktfUdmw45CAjq",
			"148":"1g2LgEiOSRgVnbErUulOIfXxIylw4_Vw1",
			"149":"1to5dYLkELo2fvvlYYyMoTuS9SRY7d2Pi",
			"150":"1pd0wIKNPqnjnR7Ya6D---KIHcd2e-A8i",
			"151":"1-1P7h0xX97EmpC5j7uwiu8FlIJS0VAna",
			"152":"1Yo3SWB7Qgip5dtS6JW6B_IhtRGN6u5-G",
			"153":"17E3L_BI7L_alU5YqRMFPgFggg14pTUdx",
			"154":"1zyHWcHnaqdqY-vuwaaZWB7YOIJmSdinY",
			"155":"14CxctbS0daVaIGbg2DyEJYp2hgHDesU2",
			"156":"10T_XyDGLqROo3to65_Gugi3E7sW5nVI6",
			"157":"1Fq5XjcHtT6N2mTv3sSU85I5Bi5gueqNg",
			"158":"1POLnNOelbKEehLu_FCw0ED4_7BhMbfvy",
			"159":"1H7scslZtQvVqREXtqrMnpsGXbEnkb-u7",
			"160":"1JQX2dqvFAEH_6W5zCloTy7pd1TH4YP0g",
			"161":"1m7TBbf7fvxK4e1gChGv38FTpTx5RuLYh",
			"162":"1-rRTme-JxHDImFA3HuUTqKORJmo-znf2",
			"163":"13CyPnovwByMnvKK0pIl7rfbFinU21PjY",
			"164":"1a-ptRFMfSMkFCYuYhzGhOkfmFTDpbZJN",
			"165":"1Wlj3ltyegpB2GgNDr3IJ4AFGTQt84EqX",
			"166":"1slEH0EPIaufFMZ_oifRvnjgpikhrcanx",
			"167":"1ho6jMJ1tKUEZCEXCzgOam6BBbiZbsv1m",
			"168":"1YLsQCT6_1--UmgLkzQ5tPfEGWBOUvstM",
			"169":"1ahXcYFnbLR9VGTC9PZRwGfaWGyoMPoYs",
			"170":"1uPo8ieQD-4eKpB33SjCVEYMTcVc-Vr_G",
			"171":"17vAOt5YUn5yfI87gdtsGi2HlTrH7PioA",
			"172":"1xJyb5ZKWgSf02a0RUmvJoPmtIeahT2-j",
			"173":"1krdBO6OVSLQ15q7TrpWbU3qdg4n6Kzzs",
			"174":"1xtzHKMSCf179nhZetxDl8J1Uqt5rm0fN",
			"175":"1UgakGThASlQk4Svi7BpnwbP3Oq8VFKHg",
			"176":"1JAx8BUlUpqCrZk9tKvhfZMuL_LkKGay9",
			"177":"1_c-Y2Fw7uQpG9b_3FEjAw7wHp0o9JadW",
			"178":"1uNyVUtd62LlJaWEtfdRHsBB2yVZe_F5r",
			"179":"1uxCsk2q_8N6FwGUAPKFvprHfcaS1r67g",
			"180":"1LUqeuDD1crzwcTaZ0MgQSUpUJI7GZTRp",
			"181":"1Zc32-GCIeHTE084__vwN4dG1vvNOROqq",
			"182":"1cvwRQnIK34NbrJZTc5AtuEfl186DQUlh",
			"183":"14KPm5-Q5VQNdWQRCO4d0tCWleote22ZO",
			"184":"1NoEzHEwF6eRzlRMN3YROtkMXuemjqHj6",
			"185":"1_vXy6tCyW7df-Vj1dAppqiLWKsyOBbhf",
			"186":"1frN-UPpCmJisOXP9eUgU5oE1j1PsjiE-",
			"187":"1rxNBn0YyOANMnLx__M7RkWhj3jcjjBsk",
			"188":"1emohWw7DAZNk2LNKy0PVVqxZV0fvCTug",
			"189":"1Jkm-Cf-PTnLu1pMhmg9huw7MwmE3EIOv",
			"190":"14ItLbmw1N2ES8HoeKKrlussfQ9Atchtc",
			"191":"1E4SMqtL8Z4q3H4mrx2p2gRRd3wWlexiw",
			"192":"1H0qbLTyzZbGe2q770daUNYqGuQgIl72I",
			"193":"1DunDhL5qr8eFT2clpdzakiHr-MxYk16q",
			"194":"1l9cO9h_WS0xjA2sZbwknHxk6lU-YC7yi",
			"195":"1E2Uq0vLbP_pdRrjZC2En9z0WfHlEhc9n",
			"196":"1zudULrucBlh6ypzSCno7RQGHotEw-7kt",
			"197":"1DDZdf2RgkX43W973du4D0eHD9SIPbbpT",
			"198":"1vWNQ4bBiTzXALU-d5gAoXjrL5WHq8AeJ",
			"199":"1UrHS1rLUMCCpDn85L9nWiFzmkjqLstQa",
			"200":"1DCIm2zAjghY-YHOZ44bAOm76tHqOGpCP",
			"201":"1-nqQFHgPnYblDGTTq72cZeTmAkA3BoCt",
			"202":"1-cCuJ02YMv20gdM8BpMlWPkO31h3wssM",
			"203":"1kkUgxug5owGyhNEow-jO5YWFTj91hA2Q",
			"204":"1jhJFbG_QktzfDawUysh7AtU8CkwOfuNn",
			"205":"1ehrPBfVnn0H4_k0YMW4PXOwynUHKISxD",
			"206":"10pruiHok8Zk4r29Oy-xILMzbNG5AcHcH",
			"207":"1SBglu6S70m7nuJ_M1eChIz_KPnMnbp6k",
			"208":"1MpK2RzvjejWFZh0TomlvSEFXdUqu1gcO",
			"209":"1rI36nEjSA12gvpf-dCwr0mWNZvyTa1mG",
			"210":"1aBAbh7ILLtfyx27j4MpKFRiRWr7n6u6T",
			"211":"1durrZDfNZu5CdljhA2rgP3QvAD9BKTWA",
			"212":"1pj51cKnPmes2F-lbSPSpoAZx-MwR3NSx",
			"213":"1bkPOlpOqUXggb4WzLJGQDJpJtVkv6ZOT",
			"214":"1EZuA8vglyfaMEMRCde8CWkOW9UslwVl1",
			"215":"1faWUYCmujZbInSX0Bs_GU-xDjcdJtBV2",
			"216":"1hkV6ASpQlDWOvPIdPW-Yw8mmW7mruGnU",
			"217":"1mAWfaBve23bltwuO9AEdmG71yNHUWu4j",
			"218":"1licNn2UHSMoMom1IXUtEzys9Nu_X37K4",
			"219":"1-pDOu_zqucU66y5SRS0Siu_qURORKd0P",
			"220":"1l3grHX_POFKXNp4ZDSrmGk8wPCxCMukp",
			"221":"1sYk6b_UkRmAsSSsdEP4luuFv4LkHvrEe",
			"222":"1ZVaX5TUIafep8eycE0MzxLZSdKY9P1pW",
			"223":"1CTsYUigWqc6Wfce-QOmz4W6X9QcvSdVK",
			"224":"1htL8G6mS-euuAS5SXdDV7SkpmkImqF98",
			"225":"1zCxPd777iYsVT6OhilPmypCmueoPDniN",
			"226":"1DUlMYMRpD2qdfTIXrHA7dsnqaQGl51K2",
			"227":"156bIw-Z8hlTcs2ziEoPZCwgu6SZJ97ab",
			"228":"1T2YUaj9kMCEFFRtzewC46mOrPiuzl7g_",
			"229":"161vp1_jSHX8nSiKS6SkdVb1AVNvbdhKa",
			"230":"1KL_ZukMoWugo5ofuOaQi5XX836DTXXMw",
			"231":"1_mjRNPftVZqZCsH-EOCN3Bhp5yjKzG0x",
			"232":"1kD36a4XZ0yiWCBaF3_iIfYrTjMyCJb9h",
			"233":"15hCGQH6g3O4WsAWYSky6pbdSJHMQ7J44",
			"234":"1i9Iv1sXeZlY-eEw_ik8vC8JozwTEhSyw",
			"235":"1st2Vvw9zS7gb5TeESl97rza4axRke20D",
			"236":"1VXhjbDAUtszDFGiczqtnypMByxJJmm1b",
			"237":"1cQIS7BR8zIsbAxR9GrXEIujPLB9CQFPk",
			"238":"1taGAHKTFxAOeYjZPlc4ynWkp-XCQ451q",
			"239":"1teFg1xVGKFNMC1Ms0YUXTvmHl_bRQ9O4",
			"240":"1KXx41UeggkBh8Qvp_1DN8o5J-LApt0u-",
			"241":"13JwLjE8XY9a5Ikdydi_FuRxB7LDQVWi_",
			"242":"1L3C85Sa8Uk2v2b0GKxETaU7iIfiJNPe4",
			"243":"1sz0CIh1vPO_bs7zMJB0xAlvS_i5ebCL5",
			"244":"1EsVDK0YtLQNyPcOQkUjhvjsZ2fYyX_9u",
			"245":"1iSP3NHElEpMdpWX9Us1g9iDvshAjxLyx",
			"246":"1Pvt2fYyEuhm7c8x1826akrtItN66rnOB",
			"247":"1V3k8wBXiyRa7ogafI587KpsxvlYBrbQi",
			"248":"1uxkhDvL4RZiUoIrGicGf5Q1KoNc3NDtu",
			"249":"19fZVJPQr3MiM5kbGZbT1p1rcWonxu3Vb",
			"250":"1Srj8tcjqyzGGbanxNM_NAcb28yKE-pz3",
			"251":"1Zwo3U6OkBxoPYa6J9WZ3jcYIzjhsOXPD",
			"252":"1c4sI1EstbNzNtv-cG3baWnP-HaoDBgOJ",
			"253":"1PQDZVBJjTeDf0N7u8G6o1L6tq3Ms-Ihp",
			"254":"1ptC_JZVDB6JS21nN35k4KSvCQSQq_HL9",
		
		},
		
		TGI_Sakernan: {
		
			"1":"1f4B32XVBSVzY1-oKsb8WCLqk-HWQ8Mle",
			"2":"12ttI3LXBksxPlOrsyUkFjiKZ3FtwvTMk",
			"3":"1-kSlA8-FbP_lt9HKPAC8XIVYXSMvpada",
			"4":"1j3EDZnLD6nLpvXolvl4BqqIJpQF0Amae",
			"5":"1gfQIbhwSK4VwCKQjSyj1i-uAeS1s63RW",
			"6":"1xLe_OHrPsVSccT5H6dJuGSQV7R8NPmTs",
			"7":"1mO_PSMWyQtn3iIH5YOLB7unyNAgzu0HK",
			"8":"1CFmlKex6z6JCp5DHWIDaEeiUN89pNLBA",
			"9":"1ZVh_dI_SQVslzjbhrCWNHEgGdg5ehlND",
			"10":"1X8WEbLziCn9Y9TcS1HiV5BXcz5Z5j5T_",
			"11":"1Hhw7mPLq1JDOIWTkN6MvLqtG1lCGQZtz",
			"12":"1Z4VNNacNzWC9ce28Ks0S_DGsrvsFOGgv",
			"13":"1CJsA-5b-fla-C6-wH0yMoCZYTf_G_FBh",
			"14":"15KQtiPVaIBS27QHYEz6XuabAS7rH-ee_",
			"15":"12mOwAYqmB3-RbrRgE19o_Qb0mpYefGdS",
			"16":"1PYkfa6VkCikbxfR3z_lxi37QboT0w6L7",
			"17":"1OejXJS4G_nBdoCFAcU0YICiMbEKw9DHA",
			"18":"1ft4PGm6i7ll4dN7iu8jt8dumw3HljhvO",
			"19":"1zDLeyzNGNFX3pUsVQUzxo4nVQmGmx07z",
			"20":"1dxkkz041g_1fr-qkSCiDqlNGVxsiZC_g",
			"21":"1Jol8bFBFuqPfI9qwqOM0kHiUsAtjCnWe",
			"22":"1UwllvdFAEL4UuEGclgIG5O6ULYVvdvE1",
			"23":"10FPC_DNfovE8f-EJfMIcQtX831SL7YRv",
			"24":"1wTUA-kUYZg9Nap4Q1aNk0pXDjL1zb156",
			"25":"1TCQ_ZUe3fnqWxemRfwhPfap_eAnAJW6N",
			"26":"1VOQCcgt0m-aBcopkG9aktK6MUQdbZO1D",
			"27":"1jiWLQOAjXKDe9GALIsuhG0UJHgcdKWTX",
			"28":"1XFwlYDq_ZUx4uqzOw45bQaM4CjqIJ9l2",
			"29":"11p-7y-8jOevI2RcOmHqGFBu6v7sBuj2d",
			"30":"1UF75YEQ7r1rPrpU3asdtkCfCyKpJu-PV",
			"31":"19jtbYKwa35xXT7R1WbOHraOBwpB_c0Ja",
			"32":"1JWI7s02Gv-ad4wyPCmP2hiUMRajxVt48",
			"33":"1lMFB-JLmf8QTOFg9_vkh-CRGqTGYoIbl",
			"34":"1Nbk3WPEIoc8xKbesaEdwBXhBfzFojYSa",
			"35":"1ZcOiM3HbYAZ7-3Ht1QVoUV_XlEcqTD9M",
			"36":"1QIv8g-yClq29huQv1osjVr40WcWwi89P",
			"37":"1m4fulsMiwvEOhy64Lpv7MAPWdil3BXZm",
			"38":"1liM-iEKekt8wa3vhv0PGM5hU4WQRRo9C",
			"39":"1iP3nACUcNLQdaY0x7-40IOh6jcdHN0vY",
			"40":"1c_payyVWzdya_cL3x1CZeHK5kiiBWPEp",
			"41":"1FdPqx0oTQPrtXIWAQOyEAeKPrFFpsQqp",
			"42":"1TQsa4146fi4WxwipV068ni51r7veys4w",
			"43":"1iNp5TE9XFtlxdRfU2aC6kaKuTmdMy81-",
			"44":"1tqwm15miq5HHqqjFO1MotD21PRh1Y47H",
			"45":"19f7N8ekzWTmEXofjAaST1j7QU76eCD-D",
			"46":"14iErtfBOZd2l3lck_1UleUaA3V-Uz1RO",
			"47":"1hMrCNRCzzrpcJATkrXW0YQTk_n3QVk8i",
			"48":"1NzTC8k9LtU9_fF944CauYdxNNhyg2riu",
			"49":"1hxSFawLOr9GCcNyT1lD8f5foHOS6e5TN",
			"50":"1MVOZG8eC6M8LsVkVwWyihjnA46PPPBlq",
			"51":"1StNK_q9lUs7QZhl9clMbrSrndDo03LDu",
			"52":"1oNPxkEqxPB-e-3ogjQhPc9DoWIRe5c-S",
			"53":"1nIv9WQWJfSRBYjBWyji5hCltjUz_pDwY",
			"54":"1dl23o9w_y7AofwsLqT9RYDdS5rj5XfHS",
			"55":"1jGFn5Krc2nZdMlrVbc4QCFgiX8rLwF5y",
			"56":"1iNG9MgnW3IxbZb9sslxP-M7RpwoJqE6z",
			"57":"11EGA0fHPZ_L9QPAbp1nNYszApWadI-Sn",
			"58":"1L5rJu89GtVoYQLb8sSjwmgzKVrMODbdY",
			"59":"1Nd9mC-ZU-AxYJL0HCVUPvHTfmBgsSGzs",
			"60":"15DXl-I7BuP5F_ftQXmnPHPOuodD2UUzj",
			"61":"1nAq2CEwOlLg7jfr-9PqdbV7S6yFvKTR0",
			"62":"13QPnwB5w79aONzGeSuY01w_X1mJQ2sZ3",
			"63":"1C1KXL3UvLCFnRjePC_1otEHfr_sSL40C",
			"64":"1Z7oDzKteFJajVpYiqoEcfvG3VgzRtwbd",
			"65":"1CVRiQJUBj-8wjt2SMaNZleh9ek3y17YE",
			"66":"11r61Fn_pMndbNGQqNHILniW7AaQ_sgzw",
			"67":"1qn7Z4tbHWmrSwFyufXqTyUhFGpSM99RV",
			"68":"10Ph1YaBKJqjr5nFY5rL777RFamJLcdy-",
			"69":"1kgRGsQRDuwIJ9sdhi7iqVXSsr1-tna8k",
			"70":"1XKWbGayFh6lXvJFSrLYxyh1tc3ldESU7",
			"71":"1AeZAub1zymd-tEwXQv1qxB9bVbDpwMae",
			"72":"1y1M5pN1agEtzxysC6dWe86MV3UDJe0gJ",
			"73":"1KOYhIB--dxlRbfFA72DhW4Giu6wFMqWQ",
			"74":"16YDBL3F80O7VzYTv3VryoveJC7uFwzf1",
			"75":"1S3DnBpKc4me0Du4bAiFdUZKLQXqfm_QM",
			"76":"1ZcnW_jV_voI-PA2L73sw7ht2Fo7T3ALB",
			"77":"1kwp3YGOaa5X2lTlL99UGZBW0FmvsHDOp",
			"78":"1sW-rFcOoWzz5mRE0GcDQN2PIFQn2TZA8",
			"79":"1GhxYpzG3zfBshN3MllfmeKsAjRhfYWng",
			"80":"1EBwrxdXPFlKLQ1fdlXc89Vie3dLY8lnA",
			"81":"1gmNG9wjkLbDOz9e90aY8252TBw2tShgQ",
			"82":"1kTNT8rF5bGPyVXM01warje7dmJE2Vrao",
			"83":"1qHEDb1jDlX68JjeSkUfJC3yfgAdsRAbN",
			"84":"1MuhIJfgsUvT0knj3WMZrGvYergM4osxz",
			"85":"1xM-wWwGLcetNIFmMNpvU3SzKjF_r47z_",
			"86":"1LdKT1rgMtY9FScC77H-QVIVWdTvyvZ-F",
			"87":"1U5Xu0Q_vZlvp1jdw4AAbpKVjzVsaByze",
			"88":"1vgOzCZHtJNKNjQZDYtwdPD2VegVelldr",
			"89":"1mkrnbRSRPEAd_662MSnkF7C6Eht72fQF",
			"90":"1Ig_Xi37uK_YugD7bpRVHwENIhkM10fEJ",
			"91":"177MrjGZN9DUkxnIAdHZPvGCWT3Ew2s9q",
			"92":"1ssE_7Ua1hHhG4qkj-6vBJRxWrHklQhiu",
			"93":"1ylCOPiQ_ZASut5ynN2rtaVDHvIDSTVCC",
			"94":"15_5drIOSPS0VOTBO24ms6oYCN9R1o-lw",
			"95":"1KoA1vlwDldvmKZIEaqxufd1skhc7F2-g",
			"96":"1_D7cCfrG9FJV03lhlEcR-uH1dULwNwWT",
			"97":"11-cYe3O-p67KQ5O2KOM8XrcEXWyNakpo",
			"98":"1-r6BMxYgCanB36YpVmvPQXjo84TvStoU",
			"99":"1ZUgIz3MYpdHCDNEVUMtVjlH4_FzPCanL",
			"100":"1M_ezIvBlmZ5I87YlGbQ-EmdKsWIBJpO8",
			"101":"1TvMrXnRtCiyLU9DIeh_KUdff688rPLuE",
			"102":"1yUfEvYzIe88BiSCU7ERLWbFnGTxcFsVF",
			"103":"1fTai3NuOMGW3p4G_-fNp02Qi7yCcjgaM",
			"104":"122C-TdO9KpDPdkM1kKlDa9KHJ7jSWt4s",
			"105":"13Ns_akCHAb8Yzcj0czCXA3wMrhOmEMaO",
			"106":"1uJORdYTlY005W1Oq6NbyDghMLOClsS_S",
			"107":"125p8OCbv3pbWc-Docjz-H31UfakAtkM-",
			"108":"10amuG1hsgbaU_LfOypXAZ0KrDIyK2snu",
			"109":"1skUhnNv942VJBGwo70lpT35iCWu0y7Kd",
			"110":"1YpjZ9ostwhK-Lb-p-TRO9AYHeRFiZcud",
			"111":"1u86rd1CEMR41irm9IYiysASSqtkWMJZn",
			"112":"1OUSjbKygSKP9vel_t6cVAwR8XZh9yBc9",
			"113":"1K3BplDnu5na1JJwvmGlD2JK8mzsZvwUH",
			"114":"114_nvuR76mXATcxeCXlgz66KPjzYFSEg",
			"115":"1d2y-FbUcA2_1JQxgSjRKAVWmVUgfuB_A",
			"116":"1kdns9Mj-kOQakCNByFXKujxy6JX5szdA",
			"117":"1gHJ0jOYeUakaG4ZY353d1mMncgHM0l6c",
			"118":"1FQhH904BOCuHKX8_Kkf5lLLrBc1KMf5h",
			"119":"1Aj9gOLTENwvfTOdUgsAEUqN6KFwCD9a3",
			"120":"11MF5LWjhemBb5upHisX7XlhSAtnmnB8r",
			"121":"1rIyl8esk7uWXvhcnX8pFcpkoWEqV5-nm",
			"122":"12VfkMpg6ABcwgRtOa1rlJE_zKgCM1oks",
			"123":"1AKyn8wgmjl0TJP_fKt1CANvQdG0S8lDF",
			"124":"1tg8iUneIV_1wbQnRw3jx2CjFxf2qcVD-",
			"125":"1S9vD4-35fTcIpwKeczdkD0eAKTS3kArR",
			"126":"1wGKv44w6fzovwRr44uFT1fa-wZpTMsBu",
			"127":"1N2zW51rrJIHMLUvH3Cr_7Kuc_KJQH3RN",
			"128":"1Jz_jovdAo8BWvUhh5aWvJaIGK2pBzayF",
			"129":"1z5sV9st6AzAKlgXafg83yTNV1LudDgkV",
			"130":"1z69JrgxDKpQPvAmFf7fR3kipxK8SvRcN",
			"131":"1ykVvsi3VX0dFS6jIZhghLE-o0XpgyzbD",
			"132":"1xxrzQpzlh9OAujCX8S6Dkk0XO3DUD8Mr",
			"133":"1s3I_fGiDz92u75Laf6iqgEfC7njKhOAN",
			"134":"15n0Jt-reFDnbZgoP2kbRaJMFtO-rkCH8",
			"135":"1YWqDWjGLohLRX553R-6ZDYneOrqdX-52",
			"136":"1utKOgxYFitu-las1oSDY08Y7CfmoOHhI",
			"137":"14y5yxgrzc5QYA6FftRo9X0Zz3irGYQIA",
			"138":"1fEzn-El5Gx3_KHNy0Ary5xU3oWE8iNxc",
			"139":"1rLG6hWH--6VHtv5A6iBbPg8GFGI5knEY",
			"140":"1HMz7HB-SaKSkEEkL72Nhw_VE6scDuhl8",
			"141":"18kZTcJMQdC0V40Lhkk9CbI2LnfcONEMk",
			"142":"1E2gDyJwtGACcBrjEJNZluvjRSU81at8M",
			"143":"1V6MqO6PmXghcOuWTyKy3nmcOoxtLzmtj",
			"144":"18DSaXePItWCLXQQoZpz3v8JJnX6jtWb1",
			"145":"1Lg73M6PZGoBC-egNa3mXFVwRoqLhbbkJ",
			"146":"1LD8s8ztCGslBKg-5AVPkOjpBOxgzqp5W",
			"147":"1yQgfWBx8MvgRuKCnOSZGQPUpG9yoKVdt",
			"148":"1vYzrEpu_6asy9tsozQbYUZ1_qkHg8mYF",
			"149":"1mkouf5gSAlSDcnESeu6mN93JWzxQqseW",
			"150":"1_R_my_Lwz7jBfzmdjFeux_Ed-BRHmPfW",
			"151":"1ZJp6ZZJDWZ7VHlbdW7lxDNj7zTawM8Gh",
			"152":"1MQD2Tbf08vLEjTT4-PF1Th6WX92NskYy",
			"153":"1XwBzzLVz-Z7wLIjKNFErWJaDMJuJE4p3",
			"154":"1nvexOeYafx_FINUHGlltQNCUdNVFWsBY",
			"155":"12nWR2QXDvauQSotifWi0M1D_wGQz5Ge3",
			"156":"1YYktysbEeOo4ZrXJKZ-ZsTHTVgWBqPum",
			"157":"1LYDBaEo7Spj0jqizde2b3Jbre12DJQSP",
			"158":"1VNm2c7W2G7U2k5xcXmbGKRC8nplR_8GG",
			"159":"159WU-dp6sQ5pzuw5fUp9QtHiNNjqmWPH",
			"160":"1eW7Ha-MF_dlBqviFhmqg1Y1X3G6Gb-4F",
			"161":"1WBXh2dK_YYBha02mhZQcOOuqeiVvsJZ3",
			"162":"1yiVmddjD5-wh9mHYomR3ldCLX-ZBxbqc",
			"163":"1V-7itMb2iaZA0CjoiVny4OFfjTDhgnse",
			"164":"1IsUCcTVN-u-30d1W-fOe1bXc18XPPG0v",
			"165":"1yzVm8xADNzqPFnyIeK23H6RIqxd_WJRy",
			"166":"1NBb9xoD52epjhTu8uLmgkz6Cq5FRC_um",
			"167":"15dWWVJEom8WnlugcE2Wc0UmL5DDWkrG8",
			"168":"1XWE1e3E9goUvZw_DciLNA6onSzqMOkik",
			"169":"1cnqSxfKfew7w7nC5tLFljdc8YItubGBu",
			"170":"16sGXg2u1JF8wwYaS0A2d5WlZeHrhcDki",
			"171":"19-N2UtwMD34x0dEMf3g0rV4qKyd0uE34",
			"172":"1mA3NwHAJaIIE3vwaNjz2xOPuOLWqRPxM",
			"173":"16eYhReCY0CIRWmsXmcNo4c9oLmaneuj3",
			"174":"1dycDnO5ArcLtACzDGmGkPmWdPhC5mCi4",
			"175":"1wr7zq0T8Le8kzCO6BIAcPGN7eMbmHkP8",
			"176":"1UpWdnPwO_Kxs9pqyUDz2nHB9nS5_d9k-",
			"177":"1CWug61T20__iiM4UmxJuVjwc369gLALC",
			"178":"1IkhDUw9rnvr53wkBZ-VwuvXQ3hMPn-PA",
			"179":"18n0pjPyOsaij4_eHusZFNPNA23Eu0s84",
			"180":"1-daooaMOWDJFCSy06aZf1Rw_l4LUkjgF",
			"181":"1cVRVDeTjAxLTxGlEpFxxd_ZDrRUuQZ0T",
			"182":"16NgsRXdtovlZOU0nYMmFvzIVkre-2Qp3",
			"183":"1OZVVkw7Q10YhC5_VrxU_L7G49c9DaCac",
			"184":"1Mi3JK2m5q09IBUvH-q-Cb2AqZ685CYnW",
			"185":"1EAuRm2Zy3XOZDbHKDMZGBsJ1Ebgh7hgQ",
			"186":"1C2bEp99eqSaUUVVGUS5wcOHJAoeeQbjm",
			"187":"1ZzO790GB8gNgptXGSnY1L0BIGwHwkYpY",
			"188":"1dfxCH0HxBOUlR8GZN1vbWifbWC3Eeoqd",
			"189":"10VWQDzvCpU5cvxMnHYCXBRSZSvLvuuHB",
			"190":"1txQkr2lwC_QcVeF8w6W6CyOJIpv69KZe",
			"191":"10QhbSINhC5zL_ct_wJGYjNZOvAhOzLTY",
			"192":"1rRjo7Ju-iVA-hA5phrGYnPr5-a3H6dmB",
			"193":"1t55w7WWkk3pYkfiWqN4Er_U2F_z-c7nt",
			"194":"16gDa0XVxMWtybPWLTFdNkunhS4E2EXGu",
			"195":"1X1H8x9677O-hc9vQ99Ga__ffV5xMlotn",
			"196":"1KfsYn0UbQegAGgKxIA9pEHE4fro7jpb0",
			"197":"1SVLgQh4_f0oQu4AaYRJwJJTaoIsBwV--",
			"198":"1teXyZHg-tFDq7fLZ0vjFld2ZupjRbboD",
			"199":"1QPKqQPVW36_OBfFsZziicSzt8Dq4qN-O",
			"200":"1M-2qduRct3J5luqWHnEOxlR01S6P2Iq3",
			"201":"1BYkqX5DSnLGkk8gfbk8OSTASYubYeo_L",
			"202":"1rKggabmqertJjM3xqgGvFD3-cZk07uSY",
			"203":"18rmLCgMf21pdq8wiULX7_Oe9YJeecZVR",
			"204":"1OqoZ4S_40sOnA3FnARWaUUaQHF89Z4_8",
			"205":"1ohWrId9M3LLuWeMpDO7br0EXNskeYo3m",
			"206":"1Op2CSgp7wNz_qotSG-FaVNqElarV8zYj",
			"207":"1-GzVKBCaG2j5h7z4T7yJcOJUwlu7MqrB",
			"208":"1BIpCxTAhmkylLHoOgm6nWns1bLNvVNPD",
			"209":"1AII6NSCpIcP9ezJucVfukmc29ODS5Wfy",
			"210":"1glq24zECkrNOvXkXd-qBcb5v4zXABYJ9",
			"211":"1rXdJRl-eprk5sB5IJpS78K_0UnSlg3Dh",
			"212":"1ok3A6TcrgFRxvrxh1b4gDmt-QO-G2vNs",
			"213":"1hhwh-7Nv8R7DPNfKfsIbjDAgVPUAtnvQ",
			"214":"1PC00Ydtbm0tQHCfFHOKjdubAoIDFQuZd",
			"215":"1O4lEjhsZgO3W0JSij-D0pxspifOyifVV",
			"216":"1pr51bSPava77v1N1x02lzLegSn0uLk-y",
			"217":"1-v3OC71QNOH1vZNNExGq4SYYgIB3IpwV",
			"218":"1N91Ly5Slahi78ULZL1gH5A9XKQ-slr-C",
			"219":"1lI2PfCo1UoH8eVtAuHMbFPn6w8X3lpmS",
			"220":"1zZtZIJMSb-Gf1rJ_G8kLiNT1Gy2dlqWN",
			"221":"198lUS6M_aSKhfleIzXK-iz38wZRghfsw",
			"222":"1jLbtpYYfyMNnbhya-EvC0dIlmmae9k0L",
			"223":"1wyg8l9KKfhxQUQJbcKoySRVkJbnjKUfE",
			"224":"1rHeUSzTQvmwf6EcgTEcTYp9mC7UCP6LT",
			"225":"1cWFcOaCOyA2UpfgBQakVM1ZtzSSSaq1Y",
			"226":"1QBbo_-JPbEvrcubJOrqANG-aQjj3GLO-",
			"227":"1tOX3zNidtN5qnun41hrp0pAOomwjB8tX",
			"228":"1AYQV2AakjgLY2ZMUpZTs2BaqiN_CYPSS",
			"229":"12XC3M_XYnXD92aQRCTmXweKCo5UYTb8A",
			"230":"1g_lUNaCb6CLWcMSmS-MlW33kIhuRwW_2",
			"231":"1__F36Mp0PsoQ03AWVaKCW8zYP_MQfEbT",
			"232":"1zwOQoc9KXEA1RnmTJjYWuw6kUAJKtfnz",
			"233":"14Yx7HAR44mfAI_zCW_OSkUpuD_c2k00t",
			"234":"16Wlz9MPLGtuZjSWApH2ZbGzOTWr-liXZ",
			"235":"1Y7-GGPzKzx8rNv5qLKbZALnuDFl82yKy",
			"236":"1SsXFH_hTgCp5y7NqGJ_mMmygPGd5GHAC",
			"237":"1D3-owzKS8B6CS-eEK22AOMymH6BgMFPh",
			"238":"1DMPPxsuqoU-WNNDlGnM_J9OOk7lB4kjJ",
			"239":"1_GSJQTQKDR7AnlLYIjIz4UP9qvdqpbSn",
			"240":"1_H29j-3NHVaaBx7lihU44PE49VcPwnp7",
			"241":"1k6Vj5PlqfoL7CqdkZUubF5Hxm6ie8PhV",
			"242":"1HBpim6PKytQ4sAyVFkCgHscQ_O85aEtl",
			"243":"1S6SW1lQpyoZJOz5w4bbmxWrIrupTtfGY",
			"244":"12tg_r1org_JNOTHsQ2mImsYtAVcofmri",
			"245":"188rea2w7MHKuQlK0TKseFt-vH0xJhwlz",
			"246":"1UyIsM5lzZLSv2qsWicPFaDoCdv1Phman",
			"247":"1Luu9TzVwaomNNTkomhEN_0y8YEtiVV0H",
			"248":"16bcMO7uE4ekl7_PyacAoXvqUCYtDh8nt",
			"249":"1ggA4o33zMqvEQTLgBiX3VxfeKZui7k9O",
			"250":"1eNnrNBIn2T-Oa-dBMFoKrSU85zigqAha",
			"251":"1y1U-waPji5wk65Z-Jvr-re2GTFgKRccj",
			"252":"1l4CzzZxrzQ4xnyeKdpxk5DYDG9a2JcJN",
			"253":"1R35Cv57uj80u_ECOjxveyPiqtYlR_-fy",
			"254":"1uh95rVepBPInkFUb7WyizC8Ocg91_c10",
		    "255":"1j8kNL4hjbmq78AwXOmgU82WJ15cu_VUw",
            "256":"19Iz3RboG3lhO3WSGjL2_tSG-3P-STaMx",
            "257":"11GpQwO7r_9pYydiFZDlYvmo0CwK8K-GR",
            "258":"15LdHxPiq-wlHg8AUwA7Rm4ySomfCscWZ",
            "259":"1KT1iStaQbttjmD_jY5L0qKulc0HXWuaY",
            "260":"1AuAmMg2dKBa58icdeNX2UhpUMjnB9_zn",
            "261":"1osKUUK_nTmpW50q4wF7IY3HKXQYBkzJw",
            "262":"1dkUfAi0YH47H28ivsEOIMVUCOJKJ8cHD",
            "263":"1WRt7Nc49GHU-0csrq58LFVpHmXEZ_bgf",
            "264":"19y_9z-DhDjnmud94eiqozP1qc2nzEbyi",
            "265":"1-kH9X406YkcxM3IFmxqevIU680737CiC",
            "266":"1ce2OHcIGx_ITbG_GP6YlL-ULo3FLzb6T",
            "267":"1PB4biHk5uTdmP5bFidtAv4Fye7lxzGli",
            "268":"12_5SDf1P5cBUw7KLbpC0xQTBrWVTC7YV",
            "269":"15u1JCzf3EPPBcG1shwy9oRJO6-NyU7rQ",
            "270":"18ZoN2_Y_d47thIzS9RB4CCL_XdX0sLn-",
            "271":"1xmAjVvs1T2mgiRji0dhR5k3icnTYltlB",
            "272":"1bV-ADg0Zoj9cb7Zj4ej6-dMn6qXJ-fTm",
            "273":"1YKemiKR_yias_lAumnIvOFQV3W3Rj18k",
            "274":"1bNYgCAFo63gZAIV2acLezV0T054UBDD0",
            "275":"1aAHx14KTA95kMXqzTp354tzYpZHULvRe",
            "276":"10f9Dzv3eVKVfr_Dng6epZfwQIpvVkDe4",
            "277":"1M7OjYTf_OVO7MwB-M_obembwWe605Hj4",
            "278":"1V0EnasrONaXO_UHTwHcNBImBBhr86zwM",
            "279":"130FlKwSgQ2ADgL_N5JNO5VuA-MoMQRJf",
            "280":"180E34YyjY7mmKeSAaRuK8bJQHglROza1",
            "281":"13mQYUcRBMaLJmS8PDaMPAY1F8uNTZJwH",
            "282":"1fjzVaBDa_MKShIUaklMZA--r0VC9qZsc",
            "283":"10gPr7KmLeXSCBZYNC2aIA41TBfhWRKdd",
            "284":"1DfSOCLjiyYPLyF1AVilVnKFc3fISLvmp",
            "285":"1Eo8D7P33qP7PdIjLPvGhz-w_UEAmlCGq",
            "286":"1nL54CyO_qmSNAhNcp-v1tffBLFDiSbtt",
            "287":"1_MHPbBuAqFJFwPCaQ94nassHm55hi1CQ",
            "288":"1qmqoAuyzcD_aiISrez6UqwQBENQDaq0X",
            "289":"1CiwZajj2PNaylaqGewLhVtiMKORaK5k2",
            "290":"1j6xZGH25w1E0TBBh0gMhb-5n7U9HB4n9",
            "291":"1RjhO1QUCDaJplnix0xLuVivx5LylBWC1",
            "292":"1aTDZDfOv9qTkQMMuBsCqxyzrJLwCgIXq",
            "293":"1SHSQE6cGQyqlncsftpTCpRmdBr2qvu_L",
            "294":"1PaB29XAWa_uMxgmEogHS-stQekmHsF5w",
            "295":"1IzOLg1yxU1MOGvW8HTzJLYNLfPyqpg7N",
            "296":"1p3PuQxaPawBNdH3eXTmaj-K7dY85nTHv",
            "297":"1kTQiLHiKerlUmgkF6iAfiXtgYl8UErAs",
            "298":"1VLgg-jEIwoN8DPqMYKczCcQBljNWfPXO",
            "299":"10ZE8ubNG30csZV3UId15xBAofARicJj1",
            "300":"1j3DH4kU2Q_aX8jkFkejURFLF80Q1OGYw",
            "301":"1y4skeTNolhWaVK9ksrTElpTDhvFFOwy3",
            "302":"1-bJybYFM8ybEw8SW6K-PQ1SJd0ud-1T8",
            "303":"1ZM3uv9Y9IVT9ipBI2vFfrGKeRf_adQQx",
            "304":"1wlwwfk-3s1iMM84zGYtkk8poFMw-w6TX",
            "305":"1vZmBcd0oz9_idaDJgq8EHLGdXPhhZc5k",
            "306":"10KBkibMH-9CzkYcJmOxfGGizeJ8zDzol",
            "307":"1ogOxpWdPC-qynRTOdbWEl3UndL6Gt56S",
            "308":"1bpV66Bex5vN7pGXWAoRJDrRN2Qji21mo",
            "309":"1897mzN3-EmGET_biepQXfSdX4HqRp9Ao",
            "310":"1HnSsQNkUW1W2cYMNO6eBB35yISSMY9jD",
            "311":"17xxAZ97aYaC0fUtqoG4RlzQ3zlAd3y2I",
            "312":"13BHjuxPjXzZsfyi7nDq7wbIzHyoMMW7s",
            "313":"19mzOO5U7tZhbrOJg8U_xtz_4Op8b_FPx",
            "314":"1b5iTsPrEbt1dRiGNJuxKlucKvFTTm_oL",
            "315":"1eXgVk_raXjAuSiXYHqO9Wijf1sPhMcrc",
            "316":"1kWP2bQRc8VakksobOD8CJdhLgopYXtMx",
            "317":"1qlaHhDu1ogpkaefeus--xACTMR36rB3U",
            "318":"1eBK6Z4unLls0jY3yB9clweofF4HYqzxO",
            "319":"1MTfsx7f5-RSGqWkfZV4HX_9RqO3Hhats",
            "320":"1inMb-ITd5qjuKgM3CyzLOr_Yx8Yvu7CX",
            "321":"1S0t3eWi49DG5ejKXEfz7-bRnXY1581XC",
            "322":"18H4dRxQOl8NZXyI6Ua46_eZtBeVPBE6-",
            "323":"1Rx7ZV74IpVpo84uZDXGQ-3FBy1nuPbTc",
            "324":"1cibD1p1hYf-XyzStUnJcKR9hm2cu1m0k",
            "325":"1O_rLGiTMnmgCMTJRfqdUJ0BES0nRG-Zu",
            "326":"11EOUXCR_COmbuUUiIw0ms1Zzm_ZdWuV6",
            "327":"1HdYpLEBvy8_Y9OniXUDDkyYubO3kgumk",
            "328":"17Gwcjlcz4-4QxuW_lV_8vxDBDgb_yD33",
            "329":"1diEf2i8AAswN715ppTzXtYyPxUQaSv_l",
            "330":"1pNsPSG_ZnKdM91P_D-ml9i0HfP6eBykH",
            "331":"1qXADArhWHohKvQis2wh6cp3Bnenkwl1W",
            "332":"1MyWXdZiOjUrrarEt4TWqqu_Sp2lr4HPZ",
            "333":"1EElWT6ZSr6zhl2bk68SvhcO7NoweAsAB",
            "334":"1rK2QGcJhqoVdc6rNvWBxgGorqDadGrYA",
            "335":"1EOlYMCPur5ndfdR3zRgbp8LM0Bnpp3Iy",
            "336":"16FdpKpVeLyZEsLztb3gIw3yZBixaDCQ0",
            "337":"1tdnFlJ6dJ9UCN5_Q9zO16kuM8LzwW405",
            "338":"1mi3Pcp80EJBc9d4AqhIdeTd6maAg4VME",
            "339":"1_iY89RS_cnCmxRe5NkschfDukEetYDqY",
            "340":"1WbNhmeSFpUUITmiYtU-4QcDI82V-n6J4",
            "341":"1e1p3W72nXzxlrBxmGNhTf-p0TfaV7Xc3",
            "342":"1SUzH9fgAXweGw9KBF5am5c9aguQeWFZ-",
            "343":"1iqjx5HPBY0f1exJn9R7nN5hepYYJRwCz",
            "344":"1G6e5GQNBytiPg8IVVS2zFXVUa6z08cON",
            "345":"15FSGSM6SAItAs5VFtJgm-Vn4_e731WYP",
            "346":"1dQKxjcLxAlQWO0PA3yWzlsZQEOhMc9ap",
            "347":"14csGeGMR28wmcnQx4D5Uxy59V3pUiYT7",
            "348":"1uLi5NZ5Ip_0EvqcQLbwxZusvclWCVEUw",
            "349":"1nGzekLxTQ71s3izn3p3M0ZaWzniW0EPy",
            "350":"1JeBGSQSG4HKOPQDPZWmVVHV7JaXqkseN",
            "351":"1lvsjEoOY9NT077uAq7YmzElZW8lE_uG-",
            "352":"1TxP5kIwPBJnIZe7VVtI8JwLunVhcZFmq",
            "353":"1Kdxv-rNex1hNS-6-zlB_lN0SL99qFflN",
            "354":"1D_7EbvDbW0XlTcuw2l-ZK76PXEo8pB4x",
            "355":"1A1L5yu7cQHSZ3F5l9gCOyjN5_UNdYlRM",
            "356":"1wq3QMPVgqSYQ8wamvJaD_anl4dDVhoag",
            "357":"1XK0Lyktfj7TVpWM8jsxRDCdYB1GHh0Rh",
            "358":"1E1Y0wmqhQvKGPOwLTnzEVLTNgiVs3VeO",
            "359":"1BpE7R98lzoRic6PFMvMnp-mpKRqb3W0e",
            "360":"1-0QtZmZNWt4x7WPOeoV1AN37AiOaAdd_",
            "361":"1xHy3NxkLQi-YQWTTe3syDEplXJsSw2gC",
            "362":"1QgNH8eArTDnT9VHmxxylOXBSi9GtGM1d",
            "363":"1urzpQegFByhTAtd_TjQ_UHR7jryZkUjj",
            "364":"1RnK91G3EUSQpbiYYFUC_syrSW5Diw4Yi",
            "365":"1QYjJLeINKaQQFdBNBX5qCZD5iCJ_fl2l",
            "366":"105NNGJLl-iv_YklbO7ra4F_2IbSG2WF-",
            "367":"1RlB74BwFkB5DqzKqYP_QyJ5oF_wRcYnP",
            "368":"1hHPkNnWCZ5MsPmNsdgus-MvTluXs_Pdp",
            "369":"1ELBNM5XAKLQcxwv4DLkrkQkh4H4pOEGC",
            "370":"11NVbQGwydO1fu9TmxvzzfelcTIJxEWdG",
            "371":"1347xCA4Vex8zkxZnoJoRDTb2pDuZ5Ir_",
            "372":"1slVlaIwGVtqIIVA8Ja-oKZNKvyS6EUDl",
            "373":"1hgYE1wxffbE4P3a_leC0666qPejRyNUL",
            "374":"1yNiQTgrBi0vLFiPXDmM3ilrr1fJ0gXKz",
            "375":"1cwevJukG4jvLpNyaJ-WL4FAL18Ym6JOR",
            "376":"12KGJSTCcjQBKoDGO83o4Bjkhwsm9GHDM",
            "377":"1JW4x5K2PaIsdU3CaFsKg-ZmaQu9JEdJ5",
            "378":"1hgYlRTAeKFjMI13nTMVCOtX77KADFumz",
            "379":"1FHnhO_dIIGpVd8v_H-mLZIY7U-j7u__Y",
            "380":"1GzqsVDvqBZwuAlc8wxqcHK3Dzwf4ip6t",
            "381":"1CBHos1iIg277TWwrx0-sXdRtc3XCAe36",
            "382":"1wePrMnYEcXnt65CVp2fzREAcF0az5AB5",
            "383":"1-RdgoEyQSY6n4qw0b6rU0Gxm3Qz1xrIS",
            "384":"1I50RbYXoec79Pe464L5UHQiP341qeCRe",
            "385":"1D55uKsWXSDIMHve5A_9u4VXiaSvYR9HN",
            "386":"1Wo2CcUPSrpixespYauq0qEm7HyaJq21x",
            "387":"1Eaug3T05tomMbiALd0RJf4e0BwEoCVKV",
            "388":"1Vp7xZpnICSPowr48EUoO48tsMUQ8RFBo",
            "389":"1UuHv90KKMpALZ3_J9yhxcuW57XdbfnFW",
            "390":"14fnSAuyw2MzDiHGYhcyFLL3PgcM-SCXH",
            "391":"1NrZWznrY9_4k2N-qZ5IvmW4fv0ms_-na",
            "392":"1WKfs-jKWH-9PqSb-8BU6kYUz3RIiXXea",
            "393":"10z1NiCznP4aygTfwIlcd0VmH4qfUYNTF",
            "394":"1VQgX6S9JyY5341sB02KBCnUmuFVt6Qos",
            "395":"1ms-ZVQR5Av5Mpt9e8Xl0YeNYSZLVtEmb",
            "396":"1ULyq6FuIFAyyX_D0yFydlMotfvJXdKn0",
            "397":"13saMQlRDXK0Mbkz0_zI8uLejgsv4yVYh",
            "398":"17tUieN3SK-fF8htP_Y0Mys65ym3sUKSr",
            "399":"1ZHr7bAioh5f9bitUjVGJLJeORJG3RNPr",
            "400":"1WwgW4K-7lu08unLqFSDISz7rvjSaCww4",
            "401":"1YjHn0BNvEn38N5aLhTcFHZaL7qozbv1E",
            "402":"1NeeX6Yvrk-aZolrqHCOAypxNMTXUGPYM",
            "403":"1Va43414Ui0YE9CUW653ccShdhdL5MVeC",
            "404":"1O2f3SSaI3YI85rujrmoNzXCHiHdPPiCL",
            "405":"1L3BnvYQKNc-yVtTSkdHDwGxjXHcPcuwB",
            "406":"11mtgGB8O1_1dFwOfEutwRyAgVT-uepHr",
            "407":"1XDcS9xh6VmaL05z4q5abuuA2PgJpe6IY",
            "408":"1cRR1d0-FqY07S4nlrrZwdxMXBbjvQKsg",
            "409":"1-ew9ZU9KkThG03qiO7XqDEUdh_O7Oo3I",
            "410":"1rjEXfXuIN1_5bQ5CJtCojbJcZ85fL8Iu",
            "411":"1kZRNO4tXcw5-uVYBTF6wr2S2oV4GfB0P",
            "412":"1X-MQW1exFN7sPg8NbWSQaL-VsQA7fg3K",
            "413":"165miqRhj0Onyb0WmVdMOzgtNe629wLrY",
            "414":"1drI2IWnjJFPjKbTulb66tNyj2v6ENHH4",
            "415":"1kldu10WceQvkEFcrom446ljXJF6oV2pT",
            "416":"15KaASgU3RlwBK_k1LMuYkzUErxKKqgUf",
            "417":"1GcS69B3eukQzA-GpfWXZ8LbLz6X4GTnl",
            "418":"1wBVrnZvusX42vg9LwSHEfpivdltB6T74",
            "419":"1YNG1I8Jmof8ScW7O3oCV2HNp9AxCB1J7",
            "420":"1d9dSjmpaVGgW9SGkK3p-ITPevrczYnsM",
            "421":"15lsU_hWVjvbLUFT1UiTdoarkZPnbJRyI",
            "422":"1wczsKIi7Z7S6vw4O1hXMGc3pzcFLFpiT",
            "423":"1YAwQUJ4Cqk5lGL7K5rkdPOzrSDU-qF9C",
		
		},
		
		TGI_SV1306_Pangkalan_kerinci: {
		
			"1":"1kcq3BuITB4Nc4P6pLDxyjrUhvEcMbq2N",
			"2":"1vcAlmNDMKh6OikcsHR5efNRVDOvBtYj7",
			"3":"1QJx8KjmnNHmSuBE8EocyyvC6H7pG7LcS",
			"4":"1YHUtlNT9UFSFBLv9sedsBgfWnLi63NzS",
			"5":"1QHopfkl7DsOngdsyr0SEzifjTrSV0h09",
			"6":"1Hw3wXG0pVFFy0XEQFfU7upUsaXDiwPvU",
			"7":"1ApUxQhwcQzrOMsDQNMQQgkATVSwp1BnM",
			"8":"1teqZiyctMv6LVDE1h4U3GNu6ByF74UQc",
			"9":"1T1VS38Gw8CwqNlKtOlHrkxbZeczEJDzN",
			"10":"173_2bzEB4ViytDk105c_9RYZEMaz6lYF",
			"11":"1SgdWcugSRjsIu4z5lke_ZZN3__IyTG2c",
			"12":"1gGkVTVikLl38-NQOWuoVvCSrcQdR0XVv",
			"13":"1nwsyqNMnHKTEm3KtIOIqY3oKL2GH3lmy",
			"14":"1qKQc-Sm-0dyU0EibumA3tmFRMR51-9BP",
			"15":"1EkcOwYeJT5hknA4flzzDZ4ufww_MSmgP",
			"16":"1aI0p_tgaM4QB_aHe01IR6nsLDggnNIux",
			"17":"11KmrNZqFu05CS1DiGdVsxRnOfpsOBh5G",
			"18":"1JUjjQwEvqeLAcwjLGysI4eePNqAMZIh6",
			"19":"17oVaujGEZyL5gQnKPzx_Iu3lGctngcE-",
			"20":"1OqfNAZMvQ5ZSjk4EE8FJcC4yFcDsRvLN",
			"21":"1WFPEHc8KNhkxddr1XR5gLzsmOfqfJLeO",
			"22":"1zZ3Cj4C8RO-qT724GsuE2mz_KhVhM7FC",
			"23":"16N1yR95LowZa9FKGlprhs20j5xxGefmr",
			"24":"16XqsNBLNUvCAu8MsHRpC7SYCCxC_vCEr",
			"25":"1ACJo_AcAgkmcgne6zyuyO6sLdJVthQ9m",
			"26":"1BtuCPivu5E5-NqwV7FEpGtAeSeaDvkHn",
			"27":"1DunBeDvRIesXGHaYkpd0GxSquJnDOiLz",
			"28":"19GoTdYmMbQlpReOR22B4UHT-st3AKI1E",
			"29":"1IY-jI2zl87mXK8U5RSs_KDgjjRjaWbhd",
			"30":"1V3bDBbjFbSHyI3gvI0AUQdOQQpXfhfVf",
			"31":"1iyBU63j4hGxVa-lnUTuNRY2b1aCUWltu",
			"32":"1BjWzbGot6lVUVCI40-G0HMG1MMRR1vQd",
			"33":"1DK1NFNfmDuANTnV7hKToZhauEZXS2Cjd",
			"34":"1HKpiP5RAsb7SQRRpSp0VOKvnnL0Z5W97",
			"35":"1PQR9qACPbWlojyvqAmrQIjA96PftI23R",
			"36":"1J6j_ADqJyLNIA7mV8ouerVUakkOWWJpU",
			"37":"1WNjHhUMJUhjPEmRXwEh0KAiSYmCzK4J8",
			"38":"1l5g2pQl5z59qC_nACg8HWr0RVaWSlI28",
			"39":"1Av7Fasy2AD08l9LEhaPh1_uw93HCDWTv",
			"40":"1_mN8A2KRLxY-s4C1adwesuvJb9iSS0d-",
			"41":"1G4EOnicxzjz7-GHYdnezSc2fBvtkW_RW",
			"42":"1GA7qSXD5ZuDYhDN5QO92uTb9K3etzcm9",
			"43":"15fUd5cYzBBrkujy4bgX1XuJwz9LRSiCM",
			"44":"1nxFIShh3Vyqxmx9ktXDxcvfaBZqxZy6c",
			"45":"1pex_MAM6pI_rl89i-Q7pD0Jy7eZUIBJx",
			"46":"1LYDLzUaS4AdAb-6eU_qFmDvZbSPRwefe",
			"47":"1qmz89Myz-g0eqZ-WtoYZIxBp5p_DG5_-",
			"48":"1xsxMTgvr3-9B5K-kCPZGFcb7_9tUfH8o",
			"49":"17xOyqSQPJvbmtcrDZ6y9i75A0rEwu3cj",
			"50":"1-_nUWThG-dwqGb87JUFhEg4jBiVzZal9",
			"51":"1_EsTuZHwMtp5M4taZ7XFsWfjQSoVPcI6",
			"52":"1l6RZWcleSpt89bNw8lQXI3lZ7xjqsP7Q",
			"53":"1Neoh3L9VQEiHDoKF9URSSoVBbKESoQAH",
			"54":"173Bc4laH6PnKqna8ZROEFbz4JELksUxU",
			"55":"1_osskFD4uF9V5qYxVBGHO3YvNqhUIuQP",
			"56":"1dNvTmIac8okj7cOgJUyGV_BbVDPVnNAY",
			"57":"1EXlXcBmxE2euOz1074f3lYSYcQfWoscP",
			"58":"1YA4wRKDRAsidWxGOyf8VsTLwQEh3Vher",
			"59":"1_KOq0JShGLmWF7RHn-3cGLfSS9UcLFvj",
			"60":"1_XgUcc8pi_mIL76e-PjdU6GHpuh2ixbW",
			"61":"1U4mBL3y7XwomxoElkW7NZYMkAWdwfWnf",
			"62":"1QtdyRZUvbdCaQRekDPwHbpDhH3W5B7du",
			"63":"1m0UqMkxu_VgM-5RHys4YxBK_2mxeJBNS",
			"64":"1MCVMaCK-J4W0VxQp920XlNHTfU8pOLMf",
			"65":"1ReuP_tSqWwYSYM3FYWQL4jNI1cSvFx-X",
			"66":"1YhItX5gMg2UHgJBi4EdYQkOclALvzdPJ",
			"67":"1YqyiMedQHzY87DZ3-dP5LE6t4bUtk6SA",
			"68":"1zfQGfhtXY1vt2uRMNOAqIloIw2af7gs1",
			"69":"10HrRAc9JiULqHRObWBCnVJQnsIysEDNP",
			"70":"1Gbl0TszC6EIkJnCn5POfXAaHTcfQvD89",
			"71":"1Sv1xWacZRBjOR-uMZ-fph5TK7RPl2ccz",
			"72":"1UxFvwvfPerSgEyfwxPFjO91b9UiyakHP",
			"73":"1zJYdT6Hc2hjL-iYR8XEzbp_dIF0lpW-A",
			"74":"1EdTVUY_OoKmhVgBzjPqQFDtLGI-9IafA",
			"75":"1PkbOQcxyMIYu032zqY8GWk0a7YwJngwb",
			"76":"1W2cLhxkzsoPOfkGtf8KSLDfJpVEeplNM",
			"77":"1WEFBIazOBkpoUk1LZnRMxzDQzFMBMf8X",
			"78":"1v0FSSQelYmrTc6et4QhGTQdVqxwqFMiy",
			"79":"1aRGNnpbWc7iq-0G4KmOugnCbDDHbpsPh",
			"80":"1f4IvW3HByc1u-r8LZfuR1umRiGfuZF85",
			"81":"1nRY6z1OYr_STsLL_fgfRppMcCjFRXxuc",
			"82":"1qY-U8JCEVxJHc2Kkxkb-s4WvQ4Ajheem",
			"83":"1e6XiClaa4MemqdT5uT_L8JFjK8mZ-Rlg",
			"84":"1C-9mMv2Uia_61sUC5rv_SMaz5nW7zIlz",
			"85":"1ZDiPE2lRa5nco3X8woM8WeD6XCO4uNpQ",
			"86":"1C8jQ7DWZD-Tv1ilA34nXv5f8b-0Tu6vv",
			"87":"1xLmLIkt3J2Zc_OfaaeHDJiNHE7f8Ja8l",
			"88":"1bE6WdunpXgiFGvXXJq7DtPZLcoCd2C0H",
			"89":"1PBOk5jViSlzRMvn2BIk6PPk-fB9juXeR",
			"90":"1Bz1pM1mhdfVHikSJ6gYLgjk63SbSWwEV",
			"91":"1OjPwf8mJflXjZl_0h_1M_BxXnWpLlaa4",
			"92":"14Z53x8YnWcLanXl-AbLpqHYiT9BA7687",
			"93":"1wnByQ8yK3qFpz9dUWoDv9-gtGh5-T9BS",
			"94":"1-nwIjokZ30sginn__mdcoWMKL_ScwnNm",
			"95":"1h1CMRqVOzxE5vfEkRzRIBqCrx0qXAgvg",
			"96":"1SUHPWPm3p414rmSVHrjUV5G2Yj3OOkLS",
			"97":"13KZpfMxIS6iDLbSskGJt4EGxnZbOftVj",
			"98":"1yXYRtpgNzXOO9dgtpWTwc6omD6b1iEEb",
			"99":"1ykl8mfSyUKTqBN2tPfoUZyE507Pk5YcF",
			"100":"1AHByR4RXpdq2_DxunGQLH3bB07e_nehv",
			"101":"1nhoJ36nQSupPoLD3xT0qrt6tvgkzFBLb",
			"102":"17ToQ8kXSURlIrqgrT6-JawZ56KWyGaHv",
			"103":"1W_vU1uXaPNAh-xWNvB7ZN_ADbsg1brbe",
			"104":"1e8AtnF1ebjUOYJl1E28F6BqPs10JrCX6",
			"105":"11c_V590RSP45nOppJHvm51R_3w6_1hfB",
			"106":"1CmxSvblboRhJyeuoTKERI8I1_ZPLpvo3",
			"107":"1I6DIlBoTAzQO8DoAtPZV3od_buuHjIRS",
			"108":"1MAPXdWuSX4N-mGVZGzSn8GII-Cv4Uv7P",
			"109":"1Tzyb3REvFoyOkpvBHi1PjDR0LV1sqhHK",
			"110":"1W8pd3uFe5LHpLtXEFi2joymK-gXFNF7t",
			"111":"1uvpTdn_KpoK6h4SV8XAxw2aXr6WNSR9E",
			"112":"1K3B8qDinWJfk7Or2N03mqZuWdEDkw3IU",
			"113":"1P9sa9mrle07KlpvlgqtIe0mmDVGaweBW",
			"114":"1gFzIdv9kTq_iR3GIKrl0bcYbqUfql74f",
			"115":"1nKPKyxhcUM2iP3jk-E9k1ky0fxCkEtVs",
			"116":"1iDVWHtFzx4w_8nOzF7Et027fbX0Rm8cF",
			"117":"1tUFRDAUM8y1MT5VzGoJxZa7wJDuUtpQj",
			"118":"14WEA65r9Ws0Q-aMm1A-b7YFOhSkv-lei",
			"119":"1AbA0ocNuD57qledHJqEheH4ZegKs58Oc",
			"120":"1PgSgghsCNwK2NX_RgAGA-k6uyoE8XPW8",
			"121":"1W9lTTZkNtnDeOPo8faCc8u14yZbhMcK6",
			"122":"1ZYYfAz9kXPrG-5SK2VFITFLPupYOQ4m2",
			"123":"1Ea3LLlelfxgJCbS19flUST_ap31P-5LP",
			"124":"1F-CVS5VYdL6Q-wMw4WaEqazUKsAJHLsO",
			"125":"1iANRuA0TT-NCpCJZ18uNLVFdsDy6t_2r",
			"126":"1ef1z7pwYI_7iWWxa1CE1xPMAhlJu59Pt",
			"127":"1LDmAMDE3e-n5Rhl8JeRSlA7v05a9wjyF",
			"128":"1RwFMl9eDq10V4z0Xh3asSJrunbygSkaT",
			"129":"1SvrL7aHGFtl531phNypPnvXQoZfKFrcC",
			"130":"1f1-AkuDuTTizJQJwgzjOUAmlLDDJgykQ",
			"131":"1W_gMyfnxR4IgKgopxYr64ypbRmNGxgkm",
			"132":"1YijzbJ-plz5_xyCwsjT0XP09V5h043Js",
			"133":"1eeufr6jAtbOFIW-L6EWQZepxZd-ViBf7",
			"134":"1-H6_ZOY2YcHpHnAP9x4SJdu5pU85L2BB",
			"135":"1BBnRP5N4JDyx4D08jy7nEYHjmeZCrI2v",
			"136":"1CN0JgkTRGk6ntK8o2BF9s1ivVLS3WkgB",
			"137":"1PZUZKslahtzCtNkj_YrYrlpnBv1NVWZg",
			"138":"1ZMokv7toRqWKGBH607YFnWbzV1ey9Cp0",
			"139":"1rLzfs29Q9kvl_b5CHUgzjkgRU7R9iqJz",
			"140":"1dXPLVucfZiQfABK71UJSWDjEd-CJAD6q",
			"141":"1fw33xD9PMEmTtG2dZVjrWDV6TfDxTA2b",
			"142":"1y9C8anQl-_Y3J63pk4Va4LGM0aL8th6u",
			"143":"1W-6vVnjI5l59VDZ0sRfcdYF7HSDul-jY",
			"144":"1dflCNnUpEGoG0nj3WZO9hk5WClsHXxq6",
			"145":"1HgZ0kr_TqolsrW_0wQeofw-cEpvZEFsI",
			"146":"1rAAJwR7NTE2Aj-fq9JrkGsHTKRDnKsn7",
			"147":"1NVyRpCr0O4_X9TwA3aXUCYzV748KI2ft",
			"148":"1T-mSLX83pXH-rG3OsKHqPxrDKADgegny",
			"149":"1Z9S5fJ6_5_loP9rc-g9vax7U10Cvwkeh",
			"150":"1hsAkctAEfYhG1hG8-6Z7EVhbI4XqunGp",
			"151":"1q1b1CcdhapmgeWhMGjYa1gSbNnFacSrH",
			"152":"190Ft3JBwkmAks6BaZSJ32BOZGoTp-ueP",
			"153":"1DOaNg0tIxIvX6xStt9HVewkUi_kzLehq",
			"154":"1UsDMCdNYKLqNvLzFoF1FbNIWU1Zc1HMH",
			"155":"1ZpfZ4EWVkuaRcKwahhM5XoP5p3x2I3Xn",
			"156":"1i5hamfijc5wjh3qsJ4jc_EcpFJwhc9_F",
			"157":"1YbRNjeEdG-Qy2zdpNBpRUqTrIE8V2oCl",
			"158":"1dFRaBXf_az_vQCQZPKE8CkN6aXfiIa7X",
			"159":"1oiNuebchhiPmF4M_0IyJSAL8ru99b0Jl",
			"160":"1wtBJc0Z9BINgG-eaDyY8U1Gpf7g4c0od",
			"161":"19VCeYvsV3S-wA18PbjudET3uFxXlwhBS",
			"162":"12iv8ZShCdzTZ3bDMLtyla1TQI9aHlT3G",
			"163":"1Aawd53UgQ2u4oLCdDrTmjXz9rhNt9I4A",
			"164":"1bybT4qrVKI-btohvmbrMVi7-WI5zWgUI",
			"165":"1gPlQ7rySYSnA-tr5JcmQb_6Vl-q0xGW4",
			"166":"1-ooaVXZwt2oYDXprRC2Y9kUg0kKy478f",
			"167":"1v_UWl4cAC4BujKnnS6Skqz-lP2dbZexA",
			"168":"1mo9wAaV3OgephOlDcptuoyK3iAPESjy7",
			"169":"1-mZ8mls9K7HgzDUQL6RB0ZyvlpoBUphw",
			"170":"1iZYFHp2c4m-uT74G1DmrDhUAoRwNek9T",
			"171":"1YzChXsOfkT5laPMO3YR9W-StuFmPFi8z",
			"172":"1nzsEgKOR8eEdlLfwZXPXGqPnaj9G1yTY",
			"173":"1ukSKaN60VTaFEyyIdIECRa3NZ5lFe-kJ",
			"174":"1_eK9kTQug_4nEqkSJpje6Iy6nPAtJOi_",
			"175":"1WZcdAoIWec3f8aZ3f2DBhB_m666mKd_U",
			"176":"1dGeTSh8h0n1zoMuXCMvgvNCfH-sXzLOh",
			"177":"1obJvSB6ArdH8Kf1tojx-3syJFshqsZy6",
			"178":"1izPvUFvMQTYgZXNUbeYvAiKrODoUFzQd",
			"179":"1S86IimvM1UfvMSol_kMraxUYZ5z08zik",
			"180":"1ivBnuqgWXVjXafCqdsQ9U84pTr8oNv96",
			"181":"1nRmeI5-r-M_DQ1LnxNIcIvPICah-ATrR",
			"182":"1yllVbsFsScjIH0rzK9F6ZCq8O8sYuOET",
			"183":"1doYd8PPy8zYXyRY7jD2-dVkTVey_thIL",
			"184":"1iJXoOzi9vaNqxFjK8v7dOcW2lRJG0zAt",
			"185":"14umR3ba-be8KnEgjZejZJwbUFF_OtQw4",
			"186":"16XZ7aIBKCxMYZtktF1oGEwSr-PBZQiee",
			"187":"19XAZwQkOXsy6V3sTxi9LDqqolB_YWmcc",
			"188":"1F2TkKG01hFFVm8s2rtsE_QNWpMmPQCld",
			"189":"1dkG3U8HfoyCtRT6SmqZptLkNgQTRK0Ib",
			"190":"1eJRVfaxLXWrYlPHWBQRPpV8p7vJWKTnz",
			"191":"1G-We4j93msPLDM3o0EDTpVQVue17vhLg",
			"192":"1lrTplzyFVjwNVRbZ1A8d5P0SGWVUImec",
			"193":"1ufEhEqtLRLWhZd_J5NqfTxaHFfKkHj5d",
			"194":"1CQInwmguLBMZeI-8gVQxOM2l-eLRxLiK",
			"195":"1Kisgr2q6Q-CXM_FN5NEWRpJhakZk7WOq",
			"196":"1f3u7dXFoVDiyng_7xf6meC4eJrC1KXz6",
			"197":"1Cb2xUnM33gnYX60c6OtBvy9WbK6cjdlI",
			"198":"1g3RJLeYlRvx6sX7RBt3VOLfMAN7uKIQm",
			"199":"1KjiuKDSbiXf6N9kJQCEs7xqsPtQEgofQ",
			"200":"1gRxdNOS8uUOAHn7NeF6rnqWfWtcjVDhb",
			"201":"1B0I9GmL3eABtkIDt76w6D_rey1AVpTOU",
			"202":"1BedshHjD7jjtOAkFf013jAdVadtalxuB",
			"203":"1JIm_DzUlwuXwwcrvHZyB2PvMAVU8gjAx",
			"204":"1w9yZLVTjaCzkCk_9tQCFqz4T4gprM03m",
			"205":"1U4FId8kjUCDhwBiCfpEwjcnB3OqSSSin",
			"206":"1dzG59AXFL1xgr9dhgPkgZ50zT_2XVoC1",
			"207":"1gOp-QGbmjUGb-4_3vxvCez3C5knbEH67",
			"208":"1kWHDA22GjPnMWjfTZHuTSjahLfln8wG4",
			"209":"1Cz9I6779GrorJv86_R9cWi5xy2CCJ0pk",
			"210":"1LHsPx3_xDGaFjomXYqEQ3lCnr22YCM_f",
			"211":"1xHq5oQPUFvpV7KV2xUPQvPsCuhPBRO4y",
			"212":"1hHg2YJ4kpSV2Tcl7n7_Yr8JDZnUFmrRk",
			"213":"14EDH1bmqOfbXys6ocOx0doAI1LEwwHGD",
			"214":"18BgT36Sf6tP057O8GP2Jrm0hMjMFTG6l",
			"215":"1Ow0Pj9VwxVBDvFxryws8v2KHT1eH0Afw",
			"216":"1R8VgdG7l3nbWRTQYFsYtGJy46knOfRYf",
			"217":"1TTJybZCVpYBcKDna7gDHAZvhj80IzOFk",
			"218":"1YGkrSkUrnxMbUdw75tnAJULs8kwoneOo",
			"219":"1XurO666BqsHcNrLM4fizbz2eaF8mpJ1Z",
			"220":"1qkwGJpnEAV0BRLmnL07X1k9rV48qxacb",
			"221":"1YOYmLzwGzWOZDr9QhYOLhCEW-GTMfqMH",
			"222":"1YvgZEptezmFordRcOxBAm7-t7OaCpPUg",
			"223":"18760y2qMZhSYKe8E3YqtOF8BPhEVvXFR",
			"224":"1IYOXgGEDtJgKOW3O4JbR8H11psz8FNKo",
			"225":"1rCjNcEpdXwTi7d4slguvRVWyVrhOPDkK",
			"226":"12n6oVYTKu45XTuksnUomjl0w0C0FCFZ2",
			"227":"19aoaE2wDs1u-UgC1Ij9IBzDG3HhUhZbV",
			"228":"1Db45IsAZd-g83y5XBafjFT1JhBApvR2F",
			"229":"1OKPgx00Y-og-2x0xu0IsPm0Wh2aNlDN4",
			"230":"1RlJCAvPe1ANTQMm6nwtacPXrGMYau21b",
			"231":"1_a_RoxcE8zsGW_ceyir3aDs1aJmDx6RP",
			"232":"17EMNBHmyHTn2AENMskykyGWuiqqvWz81",
			"233":"17rWKPGoVwr_GcGaaSYt9LEJx1JiWLZpI",
			"234":"1SYQq9o6ztIqei-1YClwITasOQUZZKHT8",
			"235":"1TUA2BCWNrzO5xk9ZuQ25sX8tSY9mT8x1",
			"236":"1ZTkjfdh0Ya9Spt8d_S5mGvH-eMuypsLT",
			"237":"1WkkBzMkjQwD5SUl9LxJjPkPmTXx6zHf3",
			"238":"1hbeKV6y8gKftFhdsqcHUk18L3Zgf_lYx",
			"239":"1sFo6wKrK2gn4MFpjyF9BSwQUVRLn8SGi",
			"240":"1UnCc4alxnYVKFRB3SEV-tfQmSD4xsxfX",
			"241":"1AmI70LAK6aofCTqd9xjtNAtAqyvXsZ2_",
			"242":"14itPAtEiq1kkZHTOy3_toP_-1gJU8cPx",
			"243":"17Z7c204b1EF6kw54qGmTCKxs5N0H5VY5",
			"244":"1HpXoYbavqAuTavdWcP9ZBKvkn-2br6BR",
			"245":"1jfKnECJSZgQ9VmACuGb_oJfyQAKK4XM5",
			"246":"191DF4IAz1tR0ststLm-Nn9TDnZh5fVpV",
			"247":"1ADRo5wUMDI6WpK1b0Cxt03u8_MZsk9SR",
			"248":"1_DVHBL876pCH4zUoU5sorQsxt0uboxF_",
			"249":"1xbBHq1_9IRrJ5c7kF1arwj4II1VPvzQu",
			"250":"1CzZvwNqHREILe1Dwj-kkBc08dlfbI-z3",
			"251":"1SyZ8_B-1YB6W3djp3XYfKptSNs8ncxvr",
			"252":"1jgRDoIZxmwPzptNR_cA4OW9YQketvHVu",
			"253":"1pAl2fJOiGWO2Zp8QcOwaBy8xeApP28Bf",
			"254":"121lmoJK9_wdUvSCt2D_AOUAsRQyn1Ofg",
			"255":"16_CXAgutNgvfNA8rfbVYVhgmQhcWum5W",
			"256":"1cKX3o1gHeBiFjVjasqc4WwdyNly6QOGe",
			"257":"1duL62xJan6HRZ-klpiFV5g7q9y9dz3GT",
			"258":"1jgTqsAm_1iJrJdFQ56polJtTo9R0zZCF",
			"259":"1oTWVms99idyT9AJ-ToDIHHKEtjPxhN2g",
			"260":"1oVmszBoUb8cE4KCkLQPjf3qem2shd54i",
			"261":"10D3xl1dnDqcLUEVA-wtYuivXeFMJ-Meu",
			"262":"1VLGCz4AFfVWzC1q1oWS9ZUEEqwTY8AU6",
			"263":"1iVgNk-j-XCxxuGFoYnvKYw93VdfkU9YG",
			"264":"1C23OYXZk_JPjTw4Jf76NPoMQ1tsEW8DH",
			"265":"1LWYUUvVVCFYKWvUCwAD8JxotPf9eE-qu",
			"266":"11QXdcP0Skcp2i232CcRXAnRbnFBz1Zau",
			"267":"1_j5nLZRSESluu28_6gUDOn8MdfBybrX2",
			"268":"1C33zvYXcBQ3t7seTcmUtzKGhHyL0891E",
			"269":"1XKK_ONcyNDC4lfTcLOQVGaHMGC_oM-yB",
			"270":"12PJja6YQDH5dl7fhBU85kd2pmF4m5-xj",
			"271":"1HLilhOL5Vc7ozdRfYgx8p7-HQRJTLEzg",
			"272":"15YxMOoGiT1X2H_0Nzgdcl-B42V5JKGjn",
			"273":"1Sm1rFGQm2pDniBDOgZjCNaUtl4RjG99d",
			"274":"1FPK96UyYoByAlzCKmYV-R60838gDlmmC",
			"275":"1Z68SY9bI3ImfURaC1oWBsQzcFY2F5Wtm",
			"276":"1dBCMqzC0WwRSDVEdgx1LNUPlBfbpFp7L",
			"277":"1lmR6rUwSAbeIea4sDXnubHwVReGUg7RX",
			"278":"1ubqEb8pLzdDDrRlwJcopFWyL4AYqLLcb",
			"279":"1zFES7e6lZh2tfpcB2-kTzF9GBuFARxlc",
			"280":"19mufDEHjKFVsiuVFaHdKx1fNV3EeU3BP",
			"281":"1LK1Bbt5xhwjGXU7pBW4Bz7Lggu1_uvba",
			"282":"1iaK-a_jRvLxt3MrY1qBYJE0kuxbWHCk5",
			"283":"1nC4tKkmetUF28vhAA9pqgWzj8sliact9",
			"284":"1dFebFSYMF9JSEne9dEsBnG5ifmolWSdc",
			"285":"1fCe3xtQyZsvSCUqx5qu_I6DKXIwhA2L2",
			"286":"1h26RMyb7hQyNHKX6CY2wXbtX0nVwyJqc",
			"287":"1pJcxlbn2Kggun5KOJrZaZ9gL9Y0JJNnr",
			"288":"1tOwLScC_l9MmuZYt7GSC1uJrA9cTF9Y7",
			"289":"15Mh9pCMTvFjAhIPGLqVj_EaLAeQm62v1",
			"290":"17OVEvXk-VKi0uNibtQxvx8rPNgMrmkD-",
			"291":"1AZP73hMvOXuvsxkoa92jhZNjT79pusEy",
			"292":"1DoW0cmPfYYSKWe7Kn-R8qVg2zYhaf4lb",
			"293":"1PTwDhQtp80djARR2HOHwy98ljInXc2bv",
			"294":"1h0B1_Iu3gjDgnGLk2PkIDUR44i2qvW-o",
			"295":"14IqAAlJR7sQWTW7fRZWaxOzyAj1ie1KG",
			"296":"18gIJ9iatrNtl4Rmws1Bzqe6bFih2k__H",
			"297":"1FqvoUFCxD-YoBkLA06YmfTrk9YPFF6I2",
			"298":"1TInzdHGvoqdQODsXKLXlfnKZ18AE5-lI",
			"299":"1jFDMhjw-QskgUmmcCT9zoKhWxid91yPM",
			"300":"15iw31_OFOQJ5NYAMZkGhu83n_NaqNuDx",
			"301":"1SyovhXTG_58oOul3o0MraPPkEiprU4Az",
			"302":"1tGf5IoV1Im0ff0KpTBNESa5ntJV9Kd4T",
			"303":"1wO8Z1RHARuoNrg9rORb5C45ZVIPnN6u6",
			"304":"1XleQcrSH2R3AVB4FgOB2ahUkCXyVMmaO",
			"305":"1Sp5s1UqoFKHdYM0QHi1ZSByrGWV1Mp8O",
			"306":"1lBYhSHVzHjsfZjpXi8uZ68uHhpogIZJl",
			"307":"12IlxReYgR-rBqLiPsRc6ZB_I3UIsGGKa",
			"308":"1dfxsCpNboJggyW_1Yvy4OW6CTzX4XiaQ",
			"309":"1NET1_DIV0LWxIdbsM7SQnw2g8blqSntt",
			"310":"1P1rW-bjGulCefgy38gglhCdDaIE0EB-9",
			"311":"1RbWwc34QK4Wb_KQiJEJXCrBMfpRinhZg",
			"312":"1_uug9Sx7GWpKAB0LirtU17Rsn4Me8bRK",
			"313":"1gZnF_Y_Xp8ln8g1coKeCV9TLqdMNJXIa",
			"314":"1tgbY-GUgfcq4RIz5RHpcTsvowX0T4eho",
			"315":"17H3kLAgPjmcdlpxo0l0ljxfamKRYqJqZ",
			"316":"1IuaeeWxeaMrrnvg59dE2SDrflEsOhomq",
			"317":"1LIL8sw-eF1lPuCzbkwaKB7NJkl5vbY-a",
			"318":"1nn45MdSDf7Z9zshRL5f_fNdS3aUlJ214",
			"319":"13BsPFSWQEzXOGcTO1mo7biPejq4lUTIp",
			"320":"1R138aN43l8oMD_paR5EmoqQV3cPNPv1d",
			"321":"1sf3Pd0tFYLu3qzfxXN6waN1M3k4G8IhD",
			"322":"1z5Q17vxV7GmkWefBvdaARmVOKxKvzJ62",
			"323":"17cXYSmIC_62rE97iVtq-SwjHO6gFkSP-",
			"324":"1j9DAPTZIm0TCSf3aLk3BZjv0nesAEF5s",
			"325":"10b3lfJCSe7xB-wysniVI07uqqtQ0Dh6B",
			"326":"1bcwvIXT9xPUTW-T2ikYjzXcIsZ_xvNIp",
			"327":"1GragPahC9Ra6qWy88dphHfSLzM7GXdSR",
			"328":"1vpcIXT-fnwZ3XsKH-BMiliWxQO1wIL4h",
			"329":"1VrhNbjZW4zwx6ehfATBbkLQ48ntXC4I8",
			"330":"1gFtcNKfdrvhx3bMCMWlvOzoAAF-0jZfV",
			"331":"13A_o-aQjfQiyRwfidO1910H3MjjGizIY",
			"332":"1SJbSUrbc2gWrg_wLrc081PXyy7SOrxPU",
			"333":"1mW7b5gsTY0HX2pOY-aR1psM5D0tOwq2_",
			"334":"193ihhHjCPjOqC2tmWXNXbQPfdPBvEQ_V",
			"335":"1PF-wQtiUmZrAHNTDMgU0DcVmdrRunCMp",
			"336":"1JG0vRnKjDWaeH0aZ7Pu4awPXiklgQIiJ",
		
		},
		
		TGI_DURI_1: {
		
			"1":"1SAtML_eAtuCKmyWkmoCiqzAGCJV4hofr",
			"2":"12WKec4_eTLxFJjsnYgMr-M1Ueimfyc7k",
			"3":"1n5NF9VRpdn3JItT1D588FJ7hNZr5v07H",
			"4":"1GVSEGAI6A8evg_EMYwjpQygC9Hr3aYY5",
			"5":"1xJHjib6MqdquB5yo8N8guNbEmJ7CaCFK",
			"6":"1iziuU0X6-hxlcEyLWbM3aepDvikcQSY2",
			"7":"1a7EvQicjn5Ar_q8hKQp_hwmVIC3s7eLR",
			"8":"1h6hgvXqsuZe3jkQoI_Lh9Wj6b6fmygOy",
			"9":"1XAebI6Nfaqbh6oYSeHALAjOFVdWFFQ0j",
			"10":"1OmlWoXawWxa66PnJqpeEn1Kz9Ldz8Eld",
			"11":"1T1Gyl5ciiu81o7OBeJn1qLoRj7ySV7rG",
			"12":"14tsKbjfIGnrLY_Og1hx6TKCM0dYpSSUS",
			"13":"1idQF91b3_laOivgFoiiqnL4XOj5PBaUQ",
			"14":"1tN27FyubmRkuiVwC6XfLXTUUk0IFn-06",
			"15":"1VgzjAF1r_D69HzSjiVuuJ0COov5nMv_l",
			"16":"1dKCp3CvJSsDkLKdgtWApWicKfsdHEfSa",
			"17":"1JkbRSN5F3f1Fhu01ilq1FP8ZduCs1Iz9",
			"18":"1ZR5hbku6SMqY7PCRTr8XsN1MeAK9-NWC",
			"19":"1PyxgPYpCvDkb1fIC8KW6oIPciZ3L9wQh",
			"20":"1TzzAqej50yQE7VVYzyFpaG69GAHw5G0O",
			"21":"1rKN-HWtJJOiUn8-9xNCDYH5kGxJA_5AU",
			"22":"1IbQLg4uJ06w4u5Udk5dTvaAEMk8f7Tzl",
			"23":"159Y3u60cP8gB3o5tVkLOvO06sZxElSPC",
			"24":"1F7w75fXyQVVbY-Xr0O_-YmdeNnKsHZU3",
			"25":"1NtTsVvnWzhCHoVqE3yhBN9kmCoO7BPp8",
			"26":"1hhs0qSHM05lWWv_JJrnDxuZ7iMV-T_tt",
			"27":"1mRCDyPJaXUUzNO1jFnK3sWLCK0V6P_RK",
			"28":"1u6RNwSASzufLmDsHADNo_irCZ9YsqFm1",
			"29":"17uIMQSxaVR8gDDyBiMEnntKwmFD3NGB6",
			"30":"19orTs9iJC4kkscF7MhsPGuK1znk3P_5s",
			"31":"1D0f7urXbpEm24DEB5oauzrvzrt4WWkHp",
			"32":"17vexKMt-oNOW64bi4EGE5oMqqbEk147E",
			"33":"1QLy-pvZIEQOOejL2kroQ4t52n5SILq5u",
			"34":"1NQjqX6ZCQiAnkXufVavQZQC1Lw2S3fbX",
			"35":"1k-Hp6PKRhMqceKs4uuEVa82zVZj4FSsW",
			"36":"1yYW3bkTyUha7PS-APa7NNMataaPHEMwy",
			"37":"187KZYM06Rl3aqqrk60-LvoBE5MOUM11F",
			"38":"1OfL5T0AXzJPYGYWxeki7Cdr_mwafYL2M",
			"39":"1XcD7dg8wi1tMNC0yvkB-RJ-ghxznxyP9",
			"40":"1oj6_bRJ60IpKCJa7zt5cbFcciw0zjoVX",
			"41":"1Ir6evwyzkiwH-x-f9TSz6iBhEVMW7kSv",
			"42":"1e-L72316Ngm3a9-0M7Bsyv4VNRTpcY2G",
			"43":"1h7CDDuXPx7ayBxgbvN6XUMjxMIJgBDOp",
			"44":"1um2YZws_-YyudxSjFBfS1zBMMLOJUXXG",
			"45":"1D4qK3IttV77jo85Spt2q5EU7GduwhyBY",
			"46":"1IfZUMzo4ZDmpUk1RejQdVF0FEIdcGhYW",
			"47":"1P_k3GCkulvv83nO2Bwo-c0gnd4oeLbdh",
			"48":"1RLMbYvFRMKLFZoRJDd4Nvz2gcqHbpWit",
			"49":"1S3rm0wamsJ9BxOIdSyFeMPOSpNM1-8aN",
			"50":"1W-ZUqAqruLBkcjUdlc4xoj0_mTEsze2b",
			"51":"1dimc2n8eb0xobEaaAHVHnVdKJMZhRyXF",
			"52":"1B6MyaLIYeO3QNLPv7SNTmY_ae_RzpXuN",
			"53":"1SQJMgK0aJ1mDwgzZrd5Z7shx0o4k96QF",
			"54":"1U4q_IzZqxG6Ery4aYS7PX_aFRNcjE_e-",
			"55":"1WU-0yPgdoziQtdI0eDHsuD1a2zLtlJ6J",
			"56":"1jHyuCoVUISJfqHSCxfqR655MDkwYiAlz",
			"57":"1ku9bndJSGerz7VcADq45O-i34_QgrJ69",
			"58":"1T2eogRYpQyPoyEc9rGIFC3Bv3NLm6vX_",
			"59":"1oTESu8qx2lSDD9DpXRyyAzIn25q4vha9",
			"60":"10ZwxmVoCOZPtF9ejFZehaMNoeRgGl-vi",
			"61":"1Bawguo3wely05RybXYuDnY94GyGBoeDi",
			"62":"14mRDippYWd_vERht1-v45k8Rsex1hDNl",
			"63":"1PHbbxEzKXkRFSo-ZmRgbsOpmyioGida_",
			"64":"13WPtE6aufMhG954yWOcLvFmspzE_cLKs",
			"65":"1hTWDOeY6LTAKQjmXpU2y7hzDc6vvWlXZ",
			"66":"1ZDcYOn2WarlQRGG0ZJ8UxYpkyKQW5Mvm",
			"67":"1g7cviPxar2r1w_o1qYieGOwId23JjKm8",
			"68":"1vB1xae5Aroxq47uHxrck9ThY8a_VzZ9E",
			"69":"1jWKc2ib0CYD1ZrzaLtWZS8JrfTTd1Wa1",
			"70":"1wxFrkr17wyb6qrCj91UniJEtvEpjuxmR",
			"71":"1P-20NyyAuRJFL2W9OhEWf5KoRklZytVK",
			"72":"1vynNFcv9B8TDplfRTJEevTP_JwpbWpBI",
			"73":"17wjtqEVFH3FyICo1NWLhvjd0TImunhAI",
			"74":"12l7yse8UDTX3lfiQNI6uV5uekDH_dgaW",
			"75":"1XsgAlnMYJ_Q7xuS5ML9_hw9bAQ9_M_QY",
			"76":"1uF_hILPsa0fzXadRlP6Fvr1pveR0ACkw",
			"77":"1tfkfhqxPW7GvoVPRiNpaxr62zxgr8kIj",
			"78":"1i7CVKJiFsYfxsGGw3uP3vChz8KBhEIlJ",
			"79":"13Nqb1LEI3_oKuKnZcAasZPaJ_BWsv3b8",
			"80":"1yxn0SYyBZjuL1h-BtDFvdc4sGPM-8oV-",
			"81":"1YvRmmzH7mETjlp7s0Vm4j2eZEkzHTD8H",
			"82":"1IBJDSJHgN-nOx6b184vpEgr27zHdC-8G",
			"83":"1powQRmevWMrkJKvccEbh672xwnDUe0e_",
			"84":"1b_F4F2WhhtL6K5d6UsOCGnYpSDLNPY76",
			"85":"1ofuGh88AkI3MX--SyZXGQa4E9-5tjt6s",
			"86":"1ZFlchWk1GNyWjkuXjASiMqz5bc7-lwlc",
			"87":"1ncM9z4nntrFp9BzvSXfEM1zg1WWHJnpe",
			"88":"1URMIY4Ed_6fIA8MYZ3o9EzJoqvNdhK51",
			"89":"1OCgJAUY06eWrURiqzk-rsFh9wEdZM9aj",
			"90":"1-IhpPzZYUhoaFanBRrbsCx7KgK5fd4oJ",
			"91":"10TC8fXOCI8S96Rx3M1TqIS2x6oubfIx4",
			"92":"1qeqPYNHgjuJZ15gjpgbMdk-CulZKJDO1",
			"93":"10JSXjKjGuZZDMnCMaWe2hyreZHN3AVgo",
			"94":"1C2vMQb3f72Ke2PF2-Ubv7FeiO-JybA67",
			"95":"1PsqnD9zImiO1_UMqLJUhYwnoF97j9-j9",
			"96":"1W7SsZwWMQbMLR1IHVgEyGrUXolZ_FVE7",
			"97":"1jw9Fheu6bsSnfscVMzVc91_hJsevOJ_n",
			"98":"1qjUtnawvxC5KxYAs46257vb-GdNfl8d5",
			"99":"1vyN1TMaT6Tmx2fNDCSN3p1jOmu0UpQMD",
			"100":"1s1vT1bzev_dwEwOhh-yaVq52htNT9Jky",
			"101":"1H-avKNnAf58dLzftAs52nZX8y4-zNuJY",
			"102":"1ZhSVORaan0DscTU2K79llYzpM_Vx_ZVW",
			"103":"1LFheq0GPMz6l7R_9Zs3cjPe2K_zSE9Xy",
			"104":"1TDzmNQer02Wyvu5Hg2N8OarXXNpYvZra",
			"105":"12u-7-55Rq3sSVqXazWY71zf2qg7kyjug",
			"106":"1AlREbmyOd8fEeGH3kfict90ObXjKy5Gy",
			"107":"1WMXUcHpP45WBf-d5kqj9AagymRdIkVM8",
			"108":"1bmAmuzspU3qXxG6pVaLqD5kV_angVrhO",
			"109":"1H3ndiexuOWht8KLc3-kqitmMmZVJ7N6X",
			"110":"1XfQ9l0CvtSqa-5Y25mTwZYngl3IugUyu",
			"111":"1yL2FD2jUTQQ3SqXXNHMN6DCkr1qHue9w",
			"112":"1zPNO4kDmjPj20jw8e1EcMr0VO_BbVM5v",
			"113":"17_buLwH4kyAFzFErxQFjmvbHSo7xyQFH",
			"114":"18Sl8a_YRLECT3-lQe-DtMWMlmPPuqkmn",
			"115":"1B8LOfkBWit6Sf53a4C5T8ZOfDU9OSZNq",
			"116":"1BdsQaz95mZkta43YI6PCPOx0FvwXT2MF",
			"117":"1XFo4Rb9-V45PtpeqyW_L3K_0saaN9njp",
			"118":"168jg0gInmOfmRcHiUDdkPwKc_0lCXmb4",
			"119":"1FGNCHPzGZHzdjLy2v3yZ_3NNFWC-7iS1",
			"120":"1MVibVSr82HuXIEeo86dfAfwY6P9dejqA",
			"121":"1jKSuMMFscIMpnu9VfcdjM56mlVRV8I_F",
			"122":"1Timnceg3ff8vtg94DCc_DDm_VCc1x7_G",
			"123":"1VKpHSze4DgRNMTt35Wn73IhsH2bK-Yuo",
			"124":"1vNd5ZpdE_pAuW5NllRlHIxtwz6B_oMy1",
			"125":"1AGixa1CSo2Mit1P8kfj6IxeuwTxmNbvz",
			"126":"1SWRqjAf58qFRrFlxaQ-_1XM7Ro8d06LA",
			"127":"1wYQF6dcsHBNZCqyaSD2ulGyT9xRJM7v9",
			"128":"1GOnUFASt6vWeH6C8dBpCUwqDHHV1DnZO",
			"129":"1sa3l2DWtl6Y43Fnjh424M8shuvMMNBS6",
			"130":"1S6pyTxsYk0-y2qsL2Z05qb1UYiTOhyxA",
			"131":"1IvaQHFhyPZ5a7cY0dqtv_S6aSJHT7ZyJ",
			"132":"1LQR9rqRDKAKAxnaDdbeyd05X7Ts_6d_W",
			"133":"1GfcgLLu0sn87_w2oOO0z98rK24btDQkY",
			"134":"164Q-s6UUICJG4HUkHFzkqTecHH6iXWLM",
			"135":"1uRIfzOGlvpTjQ263S2Z2m6r2wMm7txQi",
			"136":"1QNhxc7lxbzciVj1ycpOGrmcPmTcuig5Q",
			"137":"1bc8-dKOvXDZLlE_P6kAab7SFKSiU6N6S",
			"138":"1lndhTHIoIHrBVsuFl5QjWaBRAEDbFYao",
			"139":"1fKzuvpRCfRN6hHflzOwrEny1qeb2BgfG",
			"140":"1mmquuvcyUZx7XxpYFJKwq9nrfLbyTcfm",
			"141":"1tvkcZeoszLWwPqEIiAG2ulCNGi1UHoGK",
			"142":"18RYjk9apn2gOGc3YWCIF37Q7QXG27vtj",
			"143":"1TQJZU5IUtfe_5fYXyEMchM8GEJ_Z7-8P",
			"144":"1dif--fJAUukkt_8M3EGfvEFnE4nVg2Et",
			"145":"1x1h2jIy6ZCtk8oxfveAPzCJPZHcA7iHM",
			"146":"107k_Lq_0Rsl1n57qZWNVuPGgNS7g0OPb",
			"147":"14xY-4ELW19QuF2ukc7uD64vr0rvrcIti",
			"148":"1Dikb1pMl5sZe1-LOfFGjqPlRKpsdlCpG",
			"149":"1VqrBdRd70ZO4J02BDYmVQVEn18Dmz21h",
			"150":"1ZhbuBHnVU2kBtEemtTbGHJ-FD6dsHc_K",
			"151":"1kx70K6XX40izVWIASPawE-4mFhfEEpdV",
			"152":"1nPu_hr8Pa2hBkoTa-7O3PS1GNrbCwN-D",
			"153":"13-y_nzjuITE1KUVKQ0kQnN0jvxRIBpRY",
			"154":"17Mfctt3MFVuqqsxVKrSNv22lwmQVcjCn",
			"155":"1AE0Insa57zrHa2_LYlK4V6bPhqBV2L2h",
			"156":"17kSuRiA0F_ns5toNvlAc5NUYfkbkO16n",
			"157":"1IsvZh2uQ5QsPl24bcP6CMAwk3tTSOO7o",
			"158":"1Rm1pmWFqAsy99tUdT-7OXI6v9OUcYkLy",
			"159":"1XuBnhORvfVZ4YFx37zklDschobT6A_uY",
			"160":"1c8BYDBhJvnfOjdCHtwJhH8coYz-CexSC",
			"161":"1qzN_klMgfPDTguqX98xd9IzzkotwAewP",
			"162":"1jPWVz29lgeyihTOxJH_-0odmKUxg1ChX",
			"163":"1p88U8tfqVQsXISbfDurY0pzQ4gK1x7SI",
			"164":"1y5ceAixQqfXOzhrur4AlbbPq-u1u88R2",
			"165":"1UwWuIA4jZbsmp-1p1dC0ywWcPK4jMpx0",
			"166":"1QWpz-TZBhIhhsUj3O-JsoClOAC9vQ3SQ",
			"167":"1U99irUlXH-GnULrGAokA-EaHO3375Zm1",
			"168":"1fpZseOGVFU0JUvPPyhDx3my38sHIHmGg",
			"169":"1oCXSWsQ4lArTKSbS52gBDQlM0hCXUurW",
			"170":"14Hli9R2SlCRetbo82Qqud1UXDdwXrSP-",
			"171":"14buwRHQaPM9UxFIe0OVQVJ3yTrS6fvYI",
			"172":"1Y5u4Gsx9H6YPN2uKiztkRSzwktxJy6_6",
			"173":"1hULh843rZ_CBEXslApFT4qZx2qFHjvbm",
			"174":"1lR6JHi1UbOgRspNTscAZgU7oHFVjjLut",
			"175":"15L_ijGiZywp5Zkv0GOwf1tLadjxpnhiB",
			"176":"1PiNn93pEVneI4y7Apq-M7akTVqLY-bX_",
			"177":"1YSq_zLIRVf61FZVGrn3fx4RR6jJoYy6r",
			"178":"1nIB0LC1wyREnSbPco2x5Gl7hQlD06NhL",
			"179":"1olucwc5yD0UQtrbGTcAq2u9La6jeRZHs",
			"180":"12sbGBRFKL9NxwVIXIInCCLs81_ElllGO",
			"181":"1auuBwvYsoyPTIbX-GNPBSGUIlOF5LDP8",
			"182":"1oWBqR2z00dbbEyGI60bD2i6iM46FNQJT",
			"183":"1sJMqiyxwRIT2ftj9070LQFFTu4zhbZ79",
			"184":"1wJsXq6CfDsm9qzYIscqcC1hK787f9OOT",
			"185":"1hrVZEt28WtdrFdYwSfa-5ZY7bcvqWRjG",
			"186":"1AwvO6DrqsipAkC8xwDXvSPNSorsz2jp5",
			"187":"1nuXGa_7L41ww_5Qw86Jae1W9Ees4h5SD",
			"188":"1rl9oS4Q_tQ_D2uqKC2XzyKpt-HAIVIvd",
			"189":"1DSIZXjEZclqFdz9pWvg9v9Qd1-J3tlKa",
			"190":"1IkV_eAd2pbCG4a1x_R5vsGX7GAFqFFVe",
			"191":"1o3xALR17r85b3wSoINorQN35lsCWUVEX",
			"192":"1zXCHV14Ls3zInus2TV8jENNsoZRO2_U3",
			"193":"1ORhWPCvYob7InndfidNF_9Hm2nHCjhTN",
			"194":"1efaIqWDDYPlTAuXPzgU1q-NFdRNnJn7T",
			"195":"1fRQZ7_6kqyo_6CpeTG48nTAykVNL7_vL",
			"196":"1gYCt5_jjJHAELHsEpowAZHHNFiZTlFOO",
			"197":"1Jvb7ubjzf48iLNagI8y7UY4TBHNlLJp-",
			"198":"1ZZUfP4X2pI_sfLsnbLzutdu57LcSHQgq",
			"199":"1FEyHvySV4XBte9OQAeh_bbeuX3hriPme",
			"200":"1FmX0OJnTtq3_rfN2er4kDVUW5tCqiV8P",
			"201":"1GVhswxUmmipcoOE-u68oipZDi4QRurcU",
			"202":"1KLVPADMktujyfnKQWo-InyIa5sLVv0_-",
			"203":"1eg9wkNEHInfmqAj04bGP14ehm9ujdAqP",
			"204":"1jUHEw0RajDEHmKKubW6atI_nY3ffECsJ",
			"205":"1pcqGs3OaAVX3CqAPSYJgcQYy0n9J9oeK",
			"206":"1tpnS31lkGWvsGxwy37dkyhzjITzH-iXz",
			"207":"1fyYY56nIHmvTLdgTXTeR51sPRVu_-g1f",
			"208":"1lifPsR0pfeXHF6dhWddFevcifF6LkAjr",
			"209":"17CTP6yeUAQNjTpel5DZaIXjNEbCBh5SU",
			"210":"1RpVOfqMhz464wB6YhloX-Efedqwf_xqs",
			"211":"150oG8IMNLodJHNxEhn-LS8eaNGMJG6iZ",
			"212":"1e8LXc49zjaeWmgZX1M4Q9jskRIDqQF8S",
			"213":"1wP1MiMzKuxyN2jkDpMdyQdU6axAzZO4l",
			"214":"1zh5GxjzLfQa2Iki23-j532BEcLbrAngO",
			"215":"14UJesETkqfooTG2ee34RhrgewrL3nS_P",
			"216":"1k0detppJlw30ZFbNOBSPVS5nP_iXHDWm",
			"217":"1t2MrL_rYZf2xYkqoCyrJeYbq3EkuDVO8",
			"218":"11l0LTiab6rvUR36nt_4nXfozHZG81B-2",
			"219":"17xtVKwtxwZ5Xntx1Ns-nHKyyuM7XcYph",
			"220":"1Kb_3Z32-SnsSKKrPd1l6cbLrQUPzNA_O",
			"221":"1QaN6MrFF8a1PQVNbWrI3fYG11u5LYaOn",
			"222":"1puVQWgWM6CxNDhErBRD9T0RBwZkA3DrT",
			"223":"1Tpt9yWm2u5XswJztBGH3hVLM9-Z8vgD3",
			"224":"13fSTMGs_eHfTG-ZxTH-grI2KQlXTWA0o",
			"225":"1Wvg--juoyUK-MyswMDc0Y7_XQiEZcBI-",
			"226":"1eA0XXoGw4i1m0ep9pmz-uoJKri4UFwYq",
			"227":"10O2ZQMnNKNCe0stvyZo3ewDVqlxSiktn",
			"228":"11kX9wV3mXhVJ0JAMNWLmpiGECmV1TiQP",
			"229":"1YyIHGgWEjiiZDWdsTj4lxcKjZh-_CHv5",
			"230":"1I05kOYx1CHVABKMzD3WLBlI-w3QcnNeH",
			"231":"1Lb5s5xCStX8mYAxVEC-rHNbReEKrdDDA",
			"232":"1e7XiSj712kLJw0XrM3bSxfrkfAHmA0DF",
			"233":"1rrfWSs8-YAmcc4d2WN0Z_lFDOA7A9lDz",
			"234":"1uUSrNudRDlsXJMkgbdpdDN260qo11wJV",
			"235":"12CcjD6YVcPjk7ANgsKy9PUcuOT3XGzJN",
			"236":"1NMeBJan-JkvU_mItpvepRDmMuHiXSMYk",
			"237":"1R4-eK0OvYJaPr1AvM21qlR-kYtBe6uqg",
			"238":"1asw7cCdKbxiJWpJVmaHTHdQx9XNn70K8",
			"239":"1AWWZ5Fl4q1lbKnS3gfW0tZ0J1b8Rg5Ht",
			"240":"1LjKtmtpBP0Lpej0NopbRGF9RfqjcNYda",
			"241":"1nm_abFKJ7lzm59fcBTEnNA4XnCJ7OKSv",
			"242":"1oOvAoRNkXfJmqisbzyR8Qh4pWmTSWQrz",
			"243":"1Cbd-4mZLKcYHbhmVvX34A5-vupe0Y93R",
			"244":"1ilLcXeqx0UW2k-O2liTLfRELylupMsTq",
			"245":"1oYGKMMq6rEqUrnvLSnblVDlHjzgSajXR",
			"246":"1tA9R5Iyhwi8faaWsjK1Nw4Bj5RBskIFc",
			"247":"146M2D7aO3fYH-UMUwhUxAlL85ATF2Z1N",
			"248":"18KrbV2N-0iTlpAfVd9-mVUFv1zgmM84p",
			"249":"1TkPLC2loziAWxnvyfhVVZCfUh1VyX7WP",
			"250":"1wWzsns0elMPykOfnjKTDvC-0fw4nXy51",
			"251":"1Kjv8jp0iYgt7d79be6AoLQPOQ1sfEvjH",
			"252":"1j7sxhsKwsQjKmeoFWHxslLf6I3qGUEIp",
			"253":"1laIJak-CHNbGyxq5XJBexn2RauHFbYl8",
			"254":"1onywR7J0Zb3lgJfnbSOjjA7Ev7rW3-1T",
			"255":"121RPGY7XjZ8ZAhxKh-hSdeKSlsWMkbop",
			"256":"16B0_OBLznn-pAp4AbpZnp6Sf3mUc32HN",
			"257":"16FDGOk-ORplx-IzOnUNmqVf6vVN140vp",
			"258":"1V26dq2I4vBZUf2mUhHniElJmQJT77Wic",
			"259":"1bBF8qyXodPRReNAevdGCUCPSAMP1mHlT",
			"260":"16MA8UxyxXWkOxZCWuS_BpZ_vDQx0Xe8n",
			"261":"1RfxSkapbJn0A_52XeV_d7Sd183kymC74",
			"262":"1cXCGZaJbct5Zh4vD3wZBW3tXj6T-LX5X",
			"263":"1hMrHHy11as6jsLzxAizcIXf3AGnQ47BK",
			"264":"1tx19Umk0fxCRuF-2Cj-DO_-SnWUol1OO",
			"265":"124CDkPF2b55_oWSCg3sCZQ9sKTEX-84A",
			"266":"1kfsV7d9agcBV8H5yQ8KpOlf5mCXoBDOI",
			"267":"1nwkvMtLU_eO7LRDyPG3UATTWtnd_IgJh",
			"268":"1x2eeKABOth7nJHwRa9cd9WOXZWAQBtsS",
			"269":"1OW4d4Hsm05LGa2Q100RvraTj8heQCvRc",
			"270":"1PavTkYIoDfmKXKOtdMO4ebEObUoyuBmI",
			"271":"1xObHV6MfEbbmeP2egB7uMd6iCFLd4ncP",
			"272":"1zHxKvJ_ZlzjJfjvEkqRQZLfIN5lE2oH5",
			"273":"1_zmXmYUbungbu_IC2bQqYgvIDa4GTxJj",
			"274":"1bap6odFXSWJ9_X5tVHPxkGwtdZ6kyTvo",
			"275":"1vWdvddEtfiCfbN3PVLdeXPPeh8RwwFb7",
			"276":"1CSFi-hOmoTJD56DRgQVji-n5lkscVpU-",
			"277":"1W-wSXNIYHnVawO5rdbeV5hJn8774WDok",
			"278":"1g8LM9Wf6svTAHqP8NV9VpRgnHskx23rE",
			"279":"1qbIpqkKVh0o0oQfbib5CzYOq34iYcz1k",
			"280":"1s8GY1QFEkRgkPREKCrsy3rNm5NpzE4j4",
			"281":"11ZxQAR17fUlg5Fomqg79rRnQYtKgBcZU",
			"282":"1Qx3k0STCvJH2tVLHP30SNyyhqiHJ0Far",
			"283":"1SalzoyScoZgaoPsdozRcDgldzBFbHDHx",
			"284":"1bVl-XA_PpvH2K6GUNbydp7YF45Zkvs1K",
			"285":"1daeigfJIOcCQ4PNJGA_k6YO--rPwm3uK",
			"286":"1iZ_WieSzhxIWAuZgOffni-BTZMOZ8z8m",
			"287":"1iaw4bTlW1FSlJPe9AtPuCPw1ZhmNoiV5",
			"288":"10Fmrqj2VDvTcwCCR_iOB37EUTmgJm8Wc",
			"289":"1EBUnXZ2MXaLq0EJk5O4HUQF62WQNKUQb",
			"290":"1PTQnQzGtsxBGmFK7QW3YhkTL_xJvRxeO",
			"291":"1dWdu1wR6Vy_tGdwJhy014t3yJiEdHaxr",
			"292":"1-vPgl8_4qNQTbjh9VhDl1040MxUysZhQ",
			"293":"1aaOmBDAK4VrEhCBl_mukWgHx6XpWZFA-",
			"294":"1zbt2pQ9GxggrsvPNylGRV5NMWj35KIT_",
			"295":"1Q_uZkRc-8xVkGi1n0uopoUpfoSPETUr6",
			"296":"1ihsLGyRrc1OnQz1C098uHn47Legi76Ek",
			"297":"1oU3Rvw0SR4GUT3CiQqe-6OC-HveTeh9v",
			"298":"1quJq-dmblwaGadentglCya9p-Tkcp62b",
			"299":"1wLLPyMd4nPMfafoQFIRGIc-Z3mj8lPLN",
			"300":"11493Vq7Bs1YodE5YpIyclRrJAClO8lsX",
			"301":"1Dr0MSQ5TeAFOYfX3ZIN-D925dNpOdjyF",
			"302":"19n_-DvuovnqnAspkZ8OgFYjephkfv7ZQ",
			"303":"1aak3U5meQi_uiNi78nUcVqPhQhVCQh9I",
			"304":"1sol3h-LcVUhtRbKRxiF_iEJMsf0Mu4kg",
			"305":"1FsRrNSztdndNgoLOx-mlz6M5EtaonPQU",
			"306":"1aSndUOkZXNbLfYv-YS0PIgBHHGWCkldS",
			"307":"1Wp9Mq37FuU01jANIMUmHpfSeG9rR5ZXi",
			"308":"1mp30Kd96pEXkS9d_xY1b0NHD7IgtZ6wR",
			"309":"1se4qLJcAzEr1aC4lWUa5XZKPaD1dEvuf",
			"310":"1EZVwLLIQOT5DyM6rGrl-TX4WnLc70XDI",
			"311":"1g4FJUYVNomsxIOsqbKVZwTCYL5HH7Uv8",
			"312":"1ddkGagnIROb_qCkBF9U15Asis1PvpZa0",
			"313":"1s7pNsqVByAeYYMLsnDErgHpjlx_tsjN-",
			"314":"1g1oUp0HOFIm2p2VZaZeulakxCu1F4KXO",
			"315":"1ueWOC7960fMujmBe-ndocUm8VYZ-JcDc",
			"316":"1w2yxBYoc2gH0cWmBhcSBw4Uo9L7MVGlV",
			"317":"1Jx8I-Q0BuaW59Dr918kZJfUjOJo77hvY",
			"318":"1AlQU2ayGcf3xkGgMa32mWb-K1B6mLIv-",
			"319":"1BYx0HJWk_FQ6PJcAZFYnUVgb5MzEimvE",
			"320":"1n7zxGRYBF0T5QYSan4hm2geuWu4HoeLM",
			"321":"1us5lfINtfbO2fd1r3auk58VVbew1P3QV",
			"322":"10q63S-Vh0XS9xdfgXmilYJrcuItlW8_i",
			"323":"15Iq8wobPwzwdbGiJ5VMrhksO6ZF-0vKw",
			"324":"1F8gmi-qcGH1Yf-IBHU-QoEE8XWqHFUf4",
			"325":"1NqB5RFBgdM0k_-nRO_FIErMRE2Pc0gDR",
			"326":"1uy9HjYah8fEOmHz73SW3pCUhDpttroh8",
			"327":"1v8sNPSVf55T1rMTeEsvNfC1JGvohOOn7",
			"328":"1wxMK8wBxDkgoTU3Jbp3ayFZjZi1DRYPw",
			"329":"1SCcPB269Vh0VKGHDPPmwoMaiyx-uOkEe",
			"330":"1TJsBcUhUbY9RNJVBuhuzg4gPMEbVuVjS",
			"331":"1aLgt6DAfbV2gbjI8TQ5IVy3GV_fN5wRz",
			"332":"1xxtWtabMNrTNPxZn4o9DTZNPl5rCpihO",
			"333":"1ZipJHlZNMebPcxlnBoeOK5c4vAc0K9ln",
			"334":"1_q9S7L3TBa0OEPiMi4na0Ha56EQX2Wlt",
			"335":"105CuWfr-9DeeWVGLjo5K5BECT4b8BAwV",
			"336":"1PcqdBE2no1tyDbfdYo5Yivbl7XMaKelM",
			"337":"1u0pBCsdyM-d17QWdoHN08cBeWWjum7ib",
			"338":"1-eUYB64g9FA8COulFADTl3Z4kCjaqPav",
			"339":"17EtKyLxfxedGq--__0hEC5Ky_tka8fDc",
			"340":"1l-k008bHL7E49UxO7CTp4Sqo8zdFU9Qk",
			"341":"1rgmWrSoJpRGSMzOj-1sElIbpmhlNBBS7",
			"342":"19aJRd8B1YtcqFRliqBsIdQBwhZ_DEbRb",
			"343":"1GS63HO7_g1Vc9ExXNTbXJ8C5cLyq_7CB",
			"344":"1b13q_x2CmXcueTtFvBU7L8JrJ35Lhqqi",
			"345":"1uCQvxbS67fOP9B_tckX9BQ0ki0HvjeDK",
			"346":"16lprd2qSGgzoASEtfcSryF5ePxe48Zrh",
			"347":"1hki2mmETkDE8m9razOZ3RjX-yun_BIM6",
			"348":"1utvi4N9aLO-3XVrL4B6z0a1kxu2bAs2W",
			"349":"1wuNox6_YzzwOEq9aNhFS_oNrannrgsei",
			"350":"146OFTju5pDH8FQ7RvdaP5UE1KL_MIgwq",
			"351":"1Wy0h3z9TY47qu-eoKjuNOu_7ixhqgO_n",
			"352":"1ZkeLQ65rd-We5zq2AFnZLuBSmCBkM7nv",
			"353":"1sLwClcmfoFBnDKrMSg604_9eRINh_los",
			"354":"1JpYsfIUHAIxV6octW00ZHTER3MtBIh0V",
			"355":"1y0v6ay5pOYkLJDOlLD9-nRAzozyCAlRa",
			"356":"10cd2K9s8giZWrxFakIjcpxZXHGZqC-MX",
			"357":"1xQQY_ntpHv8i7tmY9Q3F4dYVvt5oUQQB",
			"358":"1HWcM-nDIis3NmL5lObDR4t3YjazKwvZU",
			"359":"1xVHKnQJZdMewkrEsx1hD8VTiHV4rTqVU",
			"360":"11dVCaBpQfGmh7G5Taew-JsbS4CCnupuN",
			"361":"1FqVFc8cClRp8j4K1wVytqHFNW3g-6LA1",
			"362":"1Gjl4ntn7KmGOmYmYMWXweHdNYNWiwZ-u",
			"363":"1T39tacnJ7dug4pVrY0TEvMzLBMCEvTSu",
			"364":"1ljkd8dBHJi0qiYWaXcvqh8gP-nznZfBM",
			"365":"1spORDRpM5y5uRcyhz4PriIvEnfboGQgI",
			"366":"1CrFCVwC2sMwyhhTbRh8dI4LqVe4_D428",
			"367":"1Ek_qvWE3N3_nc66lE2WjOFCMnRJCC2Yc",
			"368":"1Q_rmb07ZjyIELYpTzRHMifV7tFdm0SPA",
			"369":"1Xz3bReeqjl1Z1D_vwKC0JuElE2VTifLc",
			"370":"1br0r38wdEiKBdYvHuJW4w_3Fq-ItGFDN",
			"371":"1D1HHNsFogO6LFnrFsfbwwJJgn5EihiH4",
			"372":"1RbWrnfdQRQ1zhFz8nAxu9qAQ0ZYmbXwl",
			"373":"1iKfMQqbnSFq8i_kwjKlNpAnLl0pB-GTv",
			"374":"1saWAWLQNowjAznEraEO0OQGbh1QVE3_V",
			"375":"1zPKGrbKKHsM7Y6vaqYIE0j35R6Z33Mbx",
			"376":"16ukWg3CK5eAQ_47TMWAYKHVOPKjNO07g",
			"377":"1h-wcYOHIV9N7m5n57z0qHSr9x0arZYbr",
			"378":"1mNjmM_wJUJfY5HYoRy1iit5fgxyt_pra",
			"379":"1yKg17DjhFHQmPRRq5aejG-4FJaZAuS-0",
			"380":"117qOqvBmYIxg25CqlI8A_UFTxysxk2kX",
			"381":"1h_DkiXkuWMDumGYJuOD1at9cI0WW1it7",
			"382":"1SXPtWPPjuO70R5hjZbIlAh5NKYxWyrXN",
			"383":"1yBco4hpTs_QX6UyrNwOk7UmxMM-68xif",
			"384":"1dYM6D_JBQtMlDRtzdUmxhF5OSXZL-Ooi",
			"385":"16p0M2wEn7PsAFOR04lO8wt7DCO8FNGmo",
			"386":"1RcGyagl8slhcYET85jDSnuLBJdiFYQag",
			"387":"1IuNdOfgW0mbat5HHsYay1CyPjme-Ce5S",
			"388":"1d0ToxhXUzysivkjKLw2NvwQxlCfbu8rO",
			"389":"14tS_Q2IPu0B_1qKCX8avrt1Vw8InCbiz",
			"390":"1jaB7_T_ITxetXinsaPTqpGlBMGLXVSdn",
			"391":"1k2tBUQ2CURBNxkxQM14MosvYsvyQw1Ql",
			"392":"1JduB1UIfsRALZiSCGDSwACByws7b9Jk9",
			"393":"1uSistOpPiPxUoH9tufPLWG54fO7Iimq_",
			"394":"1Hab9a0gYVDqmTf2PsJT8mMAyndQq63jN",
			"395":"10ygkjK6wsAB98TDoAFYjHjX1Cxq3wvjH",
			"396":"1VjwsYxtJHNJ424U616HvPRua2jVsX2Ws",
			"397":"1X1eHczF32q3GtEmyU5RUIOmh0vnuGJjK",
			"398":"1ZaHO5pPr3eYdYShsx_kG4ybfYut2IaWu",
			"399":"1qq0hdAH2KBMg7UDYM3hurjCfLIYgN4vH",
			"400":"1z0X3nKOydYYb2vs5HjICHwhlztHUUgSD",
			"401":"1ZjVXEVc9EF305KdXhbmc1rr5I7KCq6o7",
			"402":"1Hkm3IGNJ1yfMJNmqcjfqbJTashLhSMrg",
			"403":"1hzf2IVNZEv4THyX4nsmy9oCIGd95X4jX",
			"404":"1wQNDn2arh_j2z5RGNnK-2jE7fy-QH8wW",
			"405":"1RC7XY4Mh90T1GGTVvF0JMOVmNIDwr7yi",
			"406":"1uRj2pLWC1zy0B8IYN7yneDfDhtQVkLWo",
			"407":"1J5QD7QSNvHSC2pPd_aE_hsomoUOihLh-",
			"408":"1Qa5ZcFz2tu95liQvNaPTXCCu-ecbjvMt",
			"409":"1X8scWUY0glCiXWYt5mxNk6RXjOi9FxP1",
			"410":"1yMotCIAGXz3SuZwEYiUXqyPm3FzvznZr",
			"411":"14jcOdk6fqx8wi_RZFQavXWeLBnAY-_qI",
			"412":"17iONUysosVi5y2-3ontR5jxieIzE72eX",
			"413":"1UMm5zHsgjBslOqFH9O97nl0mJJmedcqJ",
			"414":"1r7pWJ9xqExASQJEUFAHqzZ5T3gZ-sxVF",
			"415":"16pb6k9682iF8agFYbBFVznZ856UYAzV1",
			"416":"1CSHGYPh2dlDOdHEY6nbw4wb9FLfzZ8F1",
			"417":"1KUjhJDKSnllESgzUmjtPdPpVP94k_RUJ",
			"418":"1UwPrfNS9q_jsQxn_fjPry66Z_y6CTu5l",
			"419":"1CvjtK97yWWDNPGgtZDEkojqEyLaJ3u40",
			"420":"1HXtFXxg6xlYZ9hoaTFU6W8H4P2EFzgV4",
			"421":"1QNTB8kIuNTo4m7B3qpuBTNS7zfu9vqRw",
			"422":"1W85KHUSqF_irPx14_Gg3jTTfd9voMH6U",
			"423":"1486YlMRXvGjUBgHPOXdhSjDlnjlxvBkQ",
			"424":"1df-pU_i1R7AUp7zqXJJ9vkNsIXSZQdWe",
			"425":"1xYaoMp-AECN_1yaq5Yz9ibJ-ip3OO-zn",
			"426":"15xCyJVx9VbDkgLSlkz-5_H2Snfn42gP0",
			"427":"1RCgCKEJ5bR10ndWqdU8XZhy2aE1tEjPn",
			"428":"1baKPLbG9R-2xh1i7igOPzP1R6qX_rD2G",
			"429":"1piArJY5fhUrjtyl3XPZURgS2rVH8gGum",
			"430":"1x9_1B6qsCn__bZQ8iBJkI-GQrP57h1uO",
			"431":"1HsVX3wkanAph_jBQvyBhK79uIJamEist",
			"432":"1jEryRFGvfbutn6ZFaVfObTMmu4zYggLs",
			"433":"1Xg4OflH-dIrQBepKOrW8ULEEGROyEeJT",
			"434":"19nlpv9mJKq4tRUPNxnpSI_MKmOCza-dq",
			"435":"1JW-RhGHFgMCnrq739Av1m9RHDEaUyT5s",
			"436":"1c7XHpYIKqRUb6g4N70aAMMel559C0m4c",
			"437":"1gscBbLwk8s08g2qR3Dkqe6FpD4Co9qPD",
			"438":"12K4Csb5LzBMJYRw8eIwDHbkMjg0n-dE9",
			"439":"1Z30h5TckwNyDn5g_bRv5VhKuQPG6p6S7",
			"440":"1hLrJIVnb34stsOiIdjp5tsYY2_qxkseX",
			"441":"1kx2Imx_M5ctRqV1TKwBOBx_HZ0rsTk4E",
			"442":"17jlKjt0PJR34KF-qfxmjSqxhoTF2ctnS",
			"443":"1B1jiXk6f7kwmpRx4FOLIk1qGVDLcWErb",
			"444":"1NStQjhyL2RgVAVMFHwYL-EIPp8zvLA1P",
			"445":"1ugvMGzt0b1YfCHijGeyRCfZGWX3L3zY2",
			"446":"1AtM5nssar2oDtLXDbzlGDHyDIIqJiysN",
			"447":"1FPOTE5QT5ZTuiNTml3j2N07kKFXA6j9f",
			"448":"1Fzw9oZ2GBjw0y8jHw2ZLjZkpYPiBloRy",
			"449":"1ZyP97umLYx8I1FXL72iGC4kP7_DAuhQb",
			"450":"1p1om68ujYNfKajfM0sObYk_CmReYd9g7",
			"451":"1phF6MIvL_6rBc27QghWX2DwyP9EwrPKh",
			"452":"1DcIppUsdZlo_yG2528RLoKXl26Qj1C_M",
			"453":"1LLF1L6XPtGPz3rSU0rL8hOM5vf4xX4pp",
			"454":"1LQ7eF5zh5L7OkmDMoNSaknCxnllP30rY",
			"455":"1i69FuD20TxxV6g2IjcbTOS6z-dLw21N0",
			"456":"1oWuCXvZC22959Cje0Ykc4th76HRuik6z",
			"457":"1Tnl7hEcuEFQ1jaxMpnoxjp36Uouua1nu",
			"458":"1vZhrkzc1MgIGEsWLDvuc41UcdnDFPIXW",
			"459":"1cRSsMok-lYy9pf384suTi5B2lpWejR43",
			"460":"1cp0FbqoOg7L-0-D_LTKz-A_VUcSOi74N",
			"461":"1verj1Nz1vBzykkwpqpNYFqdYPdpcM3NS",
			"462":"1iCSCPTaP2sbOIAFHAciTRifIYzdvxhpt",
			"463":"1yhg3QmwQ_4-JuWRpSHyuW9dW8AnTqa6A",
			"464":"1J0q4JYStGlUlGFk1YJZ8qwXXFaT2uEtP",
			"465":"1c60J2IratD7USHhtjPZbdFcZidrI3XhR",
			"466":"1nec3v92v0jz2Gn_ScQWuNmW2OLkQF9DH",
			"467":"16dQ6nqwbB9GJfIt-angJR1qgz5H6T062",
			"468":"1N3IJX7shHPgu3qAXOHH9OEhV2Z14KFdo",
			"469":"1_p7ETlqVO1VP_nuGT0HVFBBbmsIEEM43",
			"470":"1qWVA_nH8gC89U4Saer73GpD_RhKGfbVb",
			"471":"1-hYAfcXvs7pajIRnadDWHj6IfO39DIbh",
			"472":"15YJOLoVrneNRJ_xjlaLq_g-p9u5u98kf",
			"473":"1FRgyyxTqGIpd8FhweyVzoTS82VJI9BrA",
			"474":"1X1ji-8Na3nlFWjKEN5cVSqG2CQxZWi3Q",
			"475":"1uoKb4Cv1LPFoPShGMJutA43XX9HeDKja",
			"476":"105EWteRvwBdBqXrunQuc92Zpw_o0cgfU",
			"477":"1Fu_PBaxR6749kZCXWuyqiDixCrtC6trK",
			"478":"1HTIl0Luvuf0FGMRyym4ZsEgJG98QWduk",
			"479":"1gAqT50ktkNPZ3HAWoQQaqREBCH9ByDGp",
			"480":"1t72ExEYpEXRaWRDrxOtkKgZ_qaUt69eV",
			"481":"10EPLSTYD9Fr0rAGGlDzbPIiIUHO7rcJj",
			"482":"19d0Nqe_2lalzjp8X0QP7SsFZQpvyGdR7",
			"483":"1EeSJWPm4VFkmuTVBRppAF-mUcnFoYg9k",
			"484":"1RLJWVTvjxc68YOkPv9yDWRk2VqFhndZc",
			"485":"1v3SJH79Pvw1tLDxbmQ_dMFEy7eZMaIAe",
			"486":"1tXFgSqFO3GpCa0slMQLlLZCF6nryK-kf",
			"487":"1oUxtZGiS0rLTuof_AnFS9Y9VDelkFQqs",
			"488":"1t7sXCqtvaGCAHbZ8pTJNxKel0PDI30t4",
			"489":"1o4TBTrS65bV32uSQY2hS_VEYOffNvwnk",
			"490":"137A1fIDS9MhdJQcmeEWMWEzHDpVGDu-9",
			"491":"17cc8TnrVEa09fTF-HB_mn-GTjC08Dxqa",
			"492":"1kW7yXxfvLF4yXPTZMoVVG5G4d7sb2_Kd",
			"493":"15jEdLl_ZivmFZlJsdnBRKh1DNsGK7Z-s",
			"494":"1g91OxpohVRAtSqxqEBU54kSAJtDAUgGR",
			"495":"1OPpUlcZ2r9PUF-D2buPGp1FVEWr1_pWZ",
			"496":"1TVs_X_IwKDbs3OLKlZtBuenaVPk77Dfh",
			"497":"1i9E9a1MR2VR5-CqzzuKc7PlIoSz_yJn4",
			"498":"1CHWw4_WEvAzcIZcBFHIWY03Lc6k7hyBJ",
			"499":"18o__WV_82uRe2_JdX-dJaq6N6NL2buPI",
			"500":"19Jj7rxn3zcCnLD9Zq0M6azz06_W8pPth",
			"501":"1agOHMzo3sOBijjzH6LnSWe9EHuJ1jjGz",
			"502":"12whrok8vw8fKF1ChfESBkXRGgMW-rWMT",
			"503":"1MsB9PvzsYElmfZXrr4KVD901wUmxoxVa",
			"504":"1CHMNXrwmHhC6h2sO4hfCA6EKW5z7qelC",
			"505":"1iljeDZzsZ7e_yvMZeH9s3psAo-_VYBLW",
			"506":"1vcG-j-CgFV6l0pxugGTsICDn-9j4Lr9l",
			"507":"1X0XN7YR6pobGvoVKuszJyfB_dWW9ByQm",
			"508":"1MuStJfjYvn_wPtlyGfHcwpRwLTYCS49L",
			"509":"1PsUlkoTXw4RfiSqOE4m5fH-Fh07uGT2a",
			"510":"1qt9OQatHQzr6-8lkHXNCKmjMvpixoPAe",
			"511":"1uNHqkMjXSkfgSEfFraLvvf8OvTZzQaX1",
			"512":"18qqRjLp160xSoqCBy_VvZoQjyZ02HwD1",
			"513":"1AlJJrL1-gwQkn1j_kEomi9O7beLpwLZw",
			"514":"1CB9n2MwxticNW6u0GFHSYbOv-BXJp-vg",
			"515":"1NvmzuukTnTF4NZax2vsEgwFfSxLJ8XT_",
			"516":"1AauoJer7Gqs0hiJAPZbS2WphFid_tdYL",
			"517":"1Ikohja4WGNUjs07H3zd62zHl7sk0fikj",
			"518":"1X1Ws7bztmhD1RjU_1cIXe1AR_GTanfyb",
			"519":"1XcnTxoVTUpBiEIS96JaEOXABiQL2kyBn",
			"520":"1f8ejn9qk51WOin30zuU8a69b9RNgy2Wt",
			"521":"1q-O3LHEgf2O9IU49x8rBm78bzFUA_HSH",
			"522":"1KzQbOM3ysd4sPApJNYng-xb5IY-2_aMy",
			"523":"1Tnslw6bmIEFh-vaZBZIu0Pneu4NvT3De",
			"524":"1iZUX7ZsJtwABkUB4plUwsjw1PYsodNQl",
			"525":"1z1gw9Afn6qlYNjKkqlPx0sGySscDZ0bG",
			"526":"19RyjYGrYLp9kJBEw0W4LuN-xaGMI1IoB",
			"527":"1bcfwNqilLaaH9eHKMyjrBrigpu8_jLZo",
			"528":"1NbNb_K8M2cOnn-6INnHgr2NRAfJsdfR0",
			"529":"1hpmRUY6whCwGfB0PqIppyA6YzcNtDe44",
			"530":"1CfFDbijWig7ZOCP2YRH_3pbB-GuckXXr",
			"531":"1QGwZV8yMw0LPL-ZNNtFrLCF-1AupABw1",
			"532":"1gfh2JSeLUZltc2qqaITPAiN83fNSUiQH",
			"533":"1mTt-c0Z4A1RCsE_kiXbZRU6YLvo7Taky",
			"534":"151bUTY5ZcYhWMiuoslv2HY_XDFtMknfM",
			"535":"1EINinCKZ335ZLOCBN7DESvHcpohOyDOc",
			"536":"1uJVeIpUnxziEHWIOK8pbulEXvSKHO62Z",
			"537":"1yh6jjXFyKRyj7LMKOURjtd9khS1qnf73",
			"538":"16eO8QuWmtCaW7Af3mb4RCOlwTWwhgMQo",
			"539":"1HsAtAH0QhMKSOjsO-e-vXLHUho7NkKZq",
			"540":"1I7G8cQSl4yydA1iWBLFIYOv37Z9p5IJe",
			"541":"1Wafg00iGOhyvrBqBh7DptdoxNSRj0sIo",
			"542":"1crLBh_ZT3IM_T84PSdBJrpWjDnzskUkU",
			"543":"1dZQkt5dPsRukN79FVe_97c1QZJ4weJuh",
			"544":"1-uBkXl51k7agnxcDR-MndFBmQnggX3h9",
			"545":"1962i6emxUoUloI7LXwvaOUkkH9sCMFFG",
			"546":"1T1UEq7vVDd9IGbIEzg8AzmPmGDCGya3F",
			"547":"1bggSE3Bzft-k_jCWDZXY89aIET8njR_y",
			"548":"1xdP5yrvKMlNzSMHIIO_XqBDnI1j6tjcf",
			"549":"1stKcX0CIzuCafD9CN54guwzsGsGqh9sL",
			"550":"1PZGsF_mTofGPY6SJZSvmc1BBRtxAHq3w",
			"551":"1qF2xVhOSBdEnahzRH94g1sqj-gxa7QAv",
			"552":"1QJLfKWZs4WRx-RNgP0_cB8-if3mv1rh8",
			"553":"1S1kO9Nm5S2H9vBzMzqu58o01w0PdB69K",
			"554":"1Ndj6LPHhYXUnM6lM21GUVrhw45RKCUeo",
			"555":"1-WMUBlsSW9WcLtAWJCoYEV7nnaWLzieQ",
			"556":"1ABVozH3HFVjeh-C2Ia8CbiwbeQtV78uN",
			"557":"1Br2FDNVHds564xjZb4ldUKbhlNNCervO",
			"558":"1ExDCQogrdGi3ZtCsQ3_ECm_7F3LR9Aec",
			"559":"10Hv1CZL98BFna50aoqoBDPuJXkoPcYGm",
			"560":"1A120-1bJQtiUMViH5wRbbyz-_9QP6dt0",
			"561":"1AZUuK8lzrueNhomwdVPZACew7jZf_2Am",
			"562":"1sLXSzj3B5txCY7MONDfNUzeiEdG_-d3t",
			"563":"1ACE4XjHXELurMMNguhR4W9RTss5ES3G_",
			"564":"1BeWvBTS_7I_hDUjQ6zgxkhiVp75VNGda",
			"565":"1QCUnkinNckwPde9twAHGr1fSj9UMrIlZ",
			"566":"1cJcZMFLGx2acA064j6KZB7aMVAQQQ7at",
			"567":"1uTIJzbgPQSXARKtDAT5ot2eg-rTTZbRP",
			"568":"1DpfkiegvapePamomtpLUb_HxNNakZ95U",
			"569":"1NuGngtWF1eUfotl3siJ0W0J7dtB67QEW",
			"570":"1VnMpZ_TZf-7YaF9_ePC58YErLQRdmOPK",
			"571":"1Z-QrjdpQcA_UpEiYj0iQ2SAOAf-Fk1YD",
			"572":"1mrJgyuU4sgx3TRF1R9HyxM7WPH3F6Br0",
			"573":"1Drg6zfIAovd8omgmuCEFyG_OFyqKBHRn",
			"574":"1SnW5JNfTdWPmyyiZHleVT3EpDjZViChL",
			"575":"1Dm3QtCvjcCM8eZq5ms_SJ9I9GhI8die9",
			"576":"1JsdXoSzA3_4d_kK7ic12CfzXGIfLONfx",
			"577":"12bRPFJN5UiAgmq5M90QHluqYDF7Y_NpA",
			"578":"1Q1QApTAae_3eZ4PsKc9MK6SM4_Vp47Zl",
			"579":"16oFn_NvjDmlWiq4hqD2RzhC5CbRg4lPC",
			"580":"1WxWEq5vnSxP4kbcW8MV5_pq8ehCiMo_E",
			"581":"1RlfcPNaUMsvWE3YwwTU-s73xzSmSdZDy",
			"582":"1SAHF2uA8nVwPV4RfFGNaLQrEA7DR7UEN",
			"583":"1o4crP-fGN0GbNpOgQDm9y7SwuXOSSqr2",
			"584":"1J1ve01qyoof9hMesVfNOA1uNPVTL2YTH",
			"585":"17kK_z-J5t6Fg4Gmz3tv4C1xpud6G78cE",
			"586":"1kcbjHxroBXh2gL9fsbi_Xesq-Ads1Oah",
			"587":"1fb6AaNG3Hgn717vwHF6F0K4zJlwxqXff",
			"588":"1wP8uBsq2lVeIdbu4kzIf6YyCVY9nRxUp",
			"589":"16rqdbwbRQFBPL7AMSluJXpKAnTbLawPQ",
			"590":"1AQhfHN_IeOEkbJtAMKa7yqo_HhbCa1ZB",
			"591":"1kkH9GOazN533XR3ABy6BsGdgtHuWi3oL",
			"592":"1EujsJJeB1Lr5slgA6c2TVj1g6gZ493IQ",
			"593":"1RYHXcKLdk1_F7Xz1qv34PuQ9O33gpihr",
			"594":"1IY_NnACWOEgPkLN-PMlI_DDMEDfpj84-",
			"595":"1OUvkYlhqnz2Y7MC6oUW3hcgTj9LJE6sZ",
			"596":"1OU5CPdN9sy7glSl_n0x_zdsc8oi2GWqo",
			"597":"1BBhrj5z0xZUP_oCDY8KUgEyHTi3owmoO",
			"598":"1Fq0eDqxP-nsbWxeAShK_WHjj-KXQh_wj",
			"599":"1y7kSY2wXisFit7T3w4a_oXxVtd-1o2KT",
			"600":"166CnjUGoTONUMDZbZWfXRZFvgwDIGzVp",
			"601":"1WUYrFBDvuXbjb_ypG1GJG34aKfvuOWqG",
			"602":"1_aappYXpoj62i60HGTby8tjPoDbrvJhl",
			"603":"12wU-t8flctWG8srOoTBogJ6kwg32tYQc",
			"604":"1IcVmp3ZqSLYfp28xm4jCCHf7-IQ7Zkk1",
			"605":"1Y8ILG4b2tgbZlwriXYRFdZikpFl6BcRL",
			"606":"1coB1-M6CDci_ge-rdBT7VWxoWO0G_s-B",
			"607":"15-GnEJRMcm7JXKqoMLOrN04e5XLKSxmr",
			"608":"1AqoftIROYknwpe8_8JYT_hakSzynB2yb",
			"609":"1kvPB22PxBGkGsYe3QPIwdJSNJVWFWAsI",
			"610":"1qBwQQbjqyj0rCRpSchTSCm0doDcSqL5f",
			"611":"1MT0gKX8ONN5Kf9lC3S_25UUukARhCYCw",
			"612":"1W5jtpHq6hRWYbE_U6SXiNDScGLzLnPHN",
			"613":"1WHReACo_VwYzc0-HMD_TB1cYo8lzhcGP",
			"614":"1eLnsDBZV-Pa56n8yz4OSOZa_4qZjuxsY",
			"615":"1gPL3pm1z4r-kw2d2ZFyji9d-WVKrcw0F",
			"616":"1m5FVLkNTQlqWaJQpaerCsDlP-DElRxAK",
			"617":"1Dx1mhCL8N-adEpvMP33bqKKcW_qQ4F-D",
			"618":"1FTgB-TuLdyk2s5AKqG0Uk85MIcwQPHNk",
			"619":"1PYP2lUhq_6ehRK6A9mjp5DwAJWowPD3x",
			"620":"1guIhCMiFVhtQO2Rzz6oIpB9bCLnvhu4f",
			"621":"14MFE5wyGz-9qlPJdwjCixepSwVhnHHvJ",
			"622":"1b9TWQyyUrBPvp-lT6tJmAIeG4-_6j0iV",
			"623":"1jv1r-57IuvzwhOlPuDuZBhOukkguXRN9",
			"624":"15zCPPXtIsymhKPIXsjkeHxYT9lFefhAI",
			"625":"1JUr0k0HkujhwHgAiPJvVJz4U-kl7oDxQ",
			"626":"1Wzj5IOjL6nGGq7_VCUQE2M8lxu22dJ4d",
			"627":"1IDY9YDyLhDCyJnNY-92EUWtVNbR3g-UI",
			"628":"1QOVGDXF_6BNkcuuTb-6C7_5kcpcQ_bbp",
			"629":"1Xsj-biWhudOraDN6Dtny0kZaxWgQ0jqD",
			"630":"1kJcXzjSpf6mGNWGiEk-UJ79mc5nf5xSp",
			"631":"1bXskRrjW7DvCnoxlwTYwemVe5_L9L9KC",
			"632":"1eMwwBv00wsIchu5PMA8FJMrAqCNoeokf",
			"633":"1scoNVb99xrD5OJf6Amje6obQnxRTM77I",
			"634":"1vKYnOCgeAXpakheH2-LNDviNxR8hIoL4",
			"635":"1Fcmc4BICU3C0Zig35kdSiJ7enMWwKckD",
			"636":"1MYYbOkckZZgMp-HMB9OZS1TOQQ01DlgV",
			"637":"1QoVHtW7OcHGSXbjl8hrbv4jySnVSj6kV",
			"638":"1WqTWuX3gp6zGlUieWGOtsU8t4Y-HdJFy",
			"639":"1-1e4TXDqlPVXMyKqfTEYJNJbJom8Y4VW",
			"640":"16g0cewguVJxnFxxdjbUOXLj1EOFWVVZf",
			"641":"1MPnVVtWSBTy-0a70Oj1XZUdbqO9sof3P",
			"642":"1diTJAYVxJu-ferWCxOQurbHE4lK_85Tu",
			"643":"1lJBJWYY7VTeewR_Kngcbik_DtCBzW0qX",
			"644":"1nF_f_mL83Zz-VLeZpHGb3CfaKjJRDFf-",
			"645":"1uqdWbBpd_CVXm0QGk5PGPzDPJ-zni99N",
			"646":"16sqWnsgBeDJTAM5uAIa7SIAMG5VZ-Kvt",
			"647":"1WUz9FoOQ_qDb58g9Qw1rgyGyW-ZyClVN",
			"648":"1gqR1he4HK5FSSaco_P9-P_y9meNrdgi8",
			"649":"1G5r8_NyRTbYWUzLy2OfuY8qUoE7Z3B_K",
			"650":"13dyLMzIQnRrPRurClWU2vCa-H6CsIo_J",
			"651":"1Bz7o2ZOL2rQURqQcpbMhuCXZQ9cqgGcA",
			"652":"1Kp9kamncNZymR2j0sJ7bUWWjGRdpgtlz",
			"653":"1Zuesaf_IJGs7RfJ5GjOZYAcPaEfi0GqR",
			"654":"15vJPmNchnzZzwFaWBf_UXRy085VLmbun",
			"655":"16Kvy4NYV6bwQQYJGhEgeVHNkEm0iv1Oh",
			"656":"1OIYn50rgVFjzMgq-8rbymVECnIs9PUW6",
			"657":"1SQqSFulmESrTxVGmaNB4l9NCshMRpu5g",
			"658":"1qFazeok4LTaNaz25RtbcK2kJIXo0BEok",
			"659":"1trWuv7dLUUOufdNpmy96Mn6UK5Mv1bep",
			"660":"1xUoj3h_kmZn7nYuHqGZwfsKvBdtMRnVg",
			"661":"14ZgAk4dYlpjrYfrA2jPFsOvbUDFmO3OV",
			"662":"1I5YGLXeioJufgLPa5_rgOoxGZwdsb1Rl",
			"663":"1IfDwyZm4MlRob4XLFkLw1iHOOkSKWlUU",
			"664":"1XdGtCBroRHOfJygIPNZu2fRJK2Ce1XmK",
			"665":"1oiiISaMxtXSEVpzHHfeUhSFKTJN3BM3D",
			"666":"1Acjubw3xW8jn-mVnzeA-GzB0gqPbkyv8",
			"667":"1fGehFLqWrwLtNIPbfSKcUBMkDLTFRxAC",
			"668":"1o41nU_wnfPjE_VFGmynMr4q1BaXKFmsk",
			"669":"1DlOC8DmSkgq7F3klnN6zndcndPFkTIcR",
			"670":"1V5_SmoNfZCuffR4fmOwxvGzqsMbxWlIL",
			"671":"1bAYsZ0ErN1Rs0Nh8Ry7OI2ZAVgQtW2Zv",
			"672":"1nO1c5usiUq2BegEIlb37zXCX5maWCQj7",
			"673":"1HMIcNG2K-cUIcqx2fmAm5sCCNy2xYrrC",
			"674":"1bTudmiAAF8xA0LuA5Zs6572rV99nfO25",
			"675":"12H7aXbxvlWVdWgEK9pTkjfuY_rqsasPh",
			"676":"1Jx4P3JowUvEuMHPsUPnpQCExn21XPuyd",
			"677":"1Tb5r0H9kCpAqqAbOitMhkxawRnA2LvAB",
			"678":"11--WofGXG8VkzSnxyJxXzvnvtoK1OITx",
			"679":"17gvtTGF-6_l_LRd9vHudP1gXoVHlEGgy",
			"680":"1Q71nZ3RoG5MlFRVSquP8A-Ewf7cW4H8k",
			"681":"19gWMv50i5ea2J53XK8nB1XGCMaWQFx7M",
			"682":"1n8X4vfQ38hNubjNeotgidLFjgAIGbNRs",
			"683":"1sKk0DVZKc1BtSUt0HGZn_vEpcGC674-d",
			"684":"13FfEkBuJFhe6zVPNmXfqgfLftQo4KNwo",
			"685":"16Dva1vX-U-nDwgo7GnNFIZHOIudUnIWz",
			"686":"1WEB2hT373BZ69KeuROCJNJzmSoPHObg6",
			"687":"1TZbf9iueP2x28hPE8jm8sp7AjNfNMPc0",
			"688":"1Xd7G8tIsaBfvIBEraUmy3xDU71xYZHPf",
			"689":"1YhLUTspRfB-odlOBt74rHTGJnd_7sySD",
			"690":"1k-m4_0Miz-6i0qumDylRsNzRL7wLjfga",
			"691":"1yaptlG83TK1nZGaT2JGTRb0Q2KzL02nn",
			"692":"1AQc49-2QQrFBENqaT8I_ReFplmkbwi-d",
			"693":"1MGEeaRirJvNBTVTpoQsZquZhjuC3Gn0k",
			"694":"1VVu0PKOcBpdxgVmCTbvtFh3PBbl6_xC2",
			"695":"1VdzXtmDKZCL0TpZ5aUmr3ghq9wErbXmx",
			"696":"1byeOQoUUBI2rlk9KhoccytHIGLjYXiuf",
			"697":"1fSH7wbUzlcIFwRVrqtnco2f76jZ-wR3Y",
			"698":"1goIYxtx1DMWNWBFckCGcow0tVyf9oruD",
			"699":"1GztBAt5BVDWJJzJgTnteDom9ro3mEQKk",
			"700":"1AwPXoWzajFUS76uEh34yTIiWt7fHzdZH",
			"701":"1DXTzfsN5tylGxaA4g_keUde-tEbgJcgz",
			"702":"1NGMR1g26z82zTkPZTDX1AxkyVe4rrvR_",
			"703":"1-QYkv5MtY2D4Q7hpet_lTv48SwNWa6S7",
			"704":"1RRnmSpSq99r21djZXo8aMEOf03FN9TXE",
			"705":"1XQXv44UcYaHdY8UQQg6EXYcBw2YpV9w5",
			"706":"1sU3QJsRvje4NIlB1q1eR4x-_4IrRuCtX",
			"707":"19EQn9GKLcSdKmM-w58pZn--zQziIdsDp",
			"708":"1LB-9MgQ5QvgfkDLSoCEnp0-Z30OSiExc",
			"709":"1gmTIlkx1cdiMZ5rw9WlfTZB30nxM_zC1",
			"710":"1j62IxGWBYVqm2XD1GtfjDYKpMPEEb2rA",
			"711":"1NYZJt4976hVXZeUnzz7Dpy4SVdIYeNZ4",
			"712":"11ve8d8Qsr4LMWh4q69S0UzGKb159H-gT",
			"713":"1LV2nGFiWEEe7Apt1lYPLQ4v1egwLHSer",
			"714":"1Zdvbx04l9rdaQRAOaEeZ3HzC6939B6rH",
		
		},
		
		TGI_DURI_2: {
		
			"1":"1JdsnbwStKtq9bbveDZfhxcfT0feK2PA2",
			"2":"1mYTSBGAdXCOa_tFNzN5XgHEyk7zdTOef",
			"3":"1ZzxZcZ0-Tu77A6j6bDArUMfEL1qO15He",
			"4":"1ru4Z9DoAYTmumix3pooOL_XQsVld7ko_",
			"5":"1BI0zPSoXfu5uv035KQ1B8GmUiLzEPb-F",
			"6":"16HAwY_oovIXQYTQ7IR2zQq6M-4ckQMOK",
			"7":"1QtOrveo1e9PMayIGGeKiZ2Gpi0ZGxcfk",
			"8":"1XDjHugFd0u-JzPL88YGJtS6oarlyOs6l",
			"9":"1xsb0c4GPXCOdDbrHkrG8_ayAZLqz2g2F",
			"10":"15g59esOM3rwRcuSjyQNbTtxm5gZb3Ou6",
			"11":"1CqR6fCAspS9lYp8qp-FVhmcL6tJc2dmL",
			"12":"1fK_wOUiet7yENfOFIYJtgtmX2-z4mjXP",
			"13":"1gBEiaY5TPx6d00Mkbtzg6zH3JlkcX2qq",
			"14":"1rZJwc-wOPOBR325zuH5eJObt3jrf7gU0",
			"15":"1Oglwvy4Ymc_azw1H01as8zPLbWWQij3q",
			"16":"1UTkVrOJqGr7PQ3iRU4Ti7M6XvuuZnO_o",
			"17":"1rNPnaNFwZRqMZ5D0EpFUMas2UC9jmG1s",
			"18":"1to4oQ89sIEj6rR2QZeouYtLrXRBEsdKa",
			"19":"1z4_Ys5eoEgeaQ93bkZvc6qxaWY3lFIrl",
			"20":"1OT7Z-A0huAb_-0EaOXhY4KZPiPev7Xtq",
			"21":"1mUVx8jqPwzmG7W8rq57lfr8XszvE8Tzq",
			"22":"1w-y-R-E1-1_Ec07e00C2C0t4GwiBINCh",
			"23":"1zYKTKTrv7YD4-0AgqMZQsKj7zaxFYHlh",
			"24":"14HGYb7_gq6h98bDVU008O-FxTmGrWZOG",
			"25":"1lUZl_vJ4oJSwhSRUOqSf2g9wXm1DmpBB",
			"26":"1ukB4wY29YZEEnAhef7nieVSU1NK7V_g_",
			"27":"1SvVYZraR6dtnIHo5lpGZEEFdAIptijtJ",
			"28":"10bqCFkIOTBEwkvniotKk49H_0XS484Su",
			"29":"14VjD0Ijy7_gU897ZNGT-y6PoXIBR8aT9",
			"30":"1C4AdEaowG8TIQieWIBBfkpMhz-5N4Z3A",
			"31":"1ZLIdmxiDPO_9pERTsJRV69YiKVR_xmoz",
			"32":"1eTd4-dGM5ynSwjWPoS6fFuHqFDeRm88r",
			"33":"1t_BzDI5BsLDUQvPRmHJVH-rTDonRHZtb",
			"34":"1OhRTvCUtbO6QHue7lhgFjiOYTmvek9af",
			"35":"1EoBXqfGNfJxTz-F7qV2iMoi6TeeYuiGr",
			"36":"1bXEztfkiygcYUx5myNPChEFCEkEiCgRJ",
			"37":"147_O3uuqZuuRn38n3a1JJjpfIiGaGNZD",
			"38":"1C5NkwvCjn_TwEo_5WsALD9ySLx0X2AYt",
			"39":"1N5oNTWEtw-UAezlCtnA3dnueQIk28s_6",
			"40":"1ZxMJcODWm6GQIi2pKFoUzUxiuriSGbuP",
			"41":"1goEVzSWxTstrBTeaChoHDw23XsEDejgj",
			"42":"1kILNeVGBPdrD1ARbbWk1YAY4KbrLZdL-",
			"43":"1uo6AlQERV9RwW2QjlafV2YXDOzYYrUW4",
			"44":"1CmbSrs2T-G2CGPoe14sVwi5e_9TDhhnh",
			"45":"1o7uF1dhMrw8QDyxci6OuUXdg6uoaFx86",
			"46":"1uPz8i_aunhFX29Z309oX1PGHNfogbRkG",
			"47":"1AK34oq5J5t4CJ2FNgcBz19FwAL-4G6ok",
			"48":"1xcbL-orj_BjsBA9zA0pDU-GGeRIVlQyS",
			"49":"1568SbZfH3ElXKlI3N1c_O4LEuJa557Zy",
			"50":"1AAfJkxOfLm90bsbnKDG5UYsTtKtRbye9",
			"51":"1Jq1B_U3EvQHq50g0XahAEWkog8o3nw0U",
			"52":"1h-IC5afOh2RM5ggLO_w0oJSEw-CKT3S0",
			"53":"12wzNGMlU7Hap7u6A8ONEntH230yG_TJm",
			"54":"1NcPgjqf1rjzAIiHfZ_9tjZWU3cbVlGPK",
			"55":"1sLMIJkXfQrHwxr41WFIQgVLMynj6Te_V",
			"56":"1ywJ7mNiF4--ysMXyBaYRPUICzbcsEK8O",
			"57":"1OYDL8UqwCbVDwEgfLT-hDEjFgYPj15FJ",
			"58":"1wxCyKnitqA5BpWQqWKyHJeLTy5SHwoaw",
			"59":"1yfABbB2yvCq2bzCjDcg-emdLvhpPoAy0",
			"60":"1zLelLXhnVYQJ1hIaDs1OdpsRV2MHIZng",
			"61":"18kznpDI3J_Ur01CbmO_DkB6e-Xo89q_j",
			"62":"1vUButUM_Uc5w2svjgaqavQAUyAzDr1o8",
			"63":"1z5IRvcvZzj8Fz9DhqnbAKBsv_A-V55Iu",
			"64":"1aCtvGAKnxm1Qjy3ZLZyNr7FE1ewzUH1f",
			"65":"1eEFu0YCbSv3vlpMz8OS2uwm5SYAclrvI",
			"66":"1mDwmQ7g3Q1f5kDmr9P8p_R4JyRe9A_Xj",
			"67":"1BOkfDcO3hnameFViJk0B12v7UT-hKwxa",
			"68":"1VWZCTfkQh2t358ufyBXquu-p2XqokH91",
			"69":"1zZi7lnJUJ2e2xSM7NO6ytQOCMYtMX0de",
			"70":"1zxTU_DlBxwW6zCR_oU7esCary_dS5OO2",
			"71":"13WfmAZyhbKgrhVGKgA3p_0yoQeS-J5GS",
			"72":"1dHdbmyhmri1Siw4vtxfubhUDBo8Prd8P",
			"73":"1U2obSgAiWVXJg2-sxU8SnzOb4K2JmEDu",
			"74":"1-S8vF4NqcQq8M1YofeS85sciVo6U7FbF",
			"75":"15Qp5v0v8yaGb0wHVT8deLYYGQTrLKtWE",
			"76":"1yQJ1dFGLenEZEdaGOHiv-lI3teQqAo2b",
			"77":"12FuD4qrHfMz7Ld_32DmBOxfSqR1Thb5A",
			"78":"1UweQtmS_yvoYtqmhfg9hlLOkcDk1srvj",
			"79":"1c_GaX2O1j8xnOMRKjD0o0sJ-IdWOSWag",
			"80":"1tD-R3zE05b3IASP0EXT_-GoaHS1tIt76",
			"81":"1PXclR4G-UjkUyYJkHYzGQJ3-1fSs5AVl",
			"82":"1VEvDJW1H5xzbI62J9FKWgCt4SRohdShD",
			"83":"1bcUGJbz12wsLZlopMNBXjE1QwVJXogvj",
			"84":"1WTH3ueHZQzYNaWNzVEvcaXq6LDVR3Rde",
			"85":"1swyHiSg-zXpq3jFngMrXMA8RE8HMZdMN",
			"86":"14ChS8MqfR8Qestbp40hSM8g0DgHVgEFo",
			"87":"1DlGCpEvEHJ6KNx47Hc9QaL1mQWkncfxv",
			"88":"1-K4c0optRnKcAj9862a_54120st7m3Ik",
			"89":"1pZVCeO9aFSQ0s0fifz4xjKgUaeYDzZvM",
			"90":"1r4PzetMKLOSuGOXUbMtlMHNxCWkEFcVX",
			"91":"1-diORNICU1UFbFIcTnv35jfTNDic-EVK",
			"92":"181wtZyUNHvsR3psNMbNz6rhKcK1cbVHj",
			"93":"19JgUSsLEEhbR7ewfK8qOBmNsCTrNOdWe",
			"94":"1Q6c4QUglecfkQN4os3udP_7Arl4Yn9mn",
			"95":"1J48wc4gXWL6IS48WDxbArstvIO7Ji4fS",
			"96":"1ZK_ECW98AY8ftaGM8zAx0zh6M9UarlJ6",
			"97":"1nm9xnhh4nLodyayXoX3FT7L3Nh2ki_P9",
			"98":"1Ed39ib9zdB1ZfqE2iEhnyaLIKjB_N1QT",
			"99":"1DMwvSKTxDDItSfUXi_WRgF4HZEyrqyIw",
			"100":"1NG5S4ljTJkDO6bBpubXYABOxE1s3dXwl",
			"101":"10-apUnep942-2HgN54Ezartq74O90psS",
			"102":"1IsZoBYUNQulXmssCOgMOT1zZniUbEGtT",
			"103":"1PFrNcHEOX07szd6CttHh_y3uq7vA2-CJ",
			"104":"1a3etb7IilF9CR9GZqcl6BqPQQR4e2JWI",
			"105":"1mdF4o46F_bqubSYulkspQpRgts_XDKn2",
			"106":"115LybKOJ9VkngBshWhf0S_ltEN-K-2pe",
			"107":"1Ag2qmOWFsF236k53rzXPka3iW-DD50st",
			"108":"1bBEgpPCBRZ8XzhyU16sxnkvs5fjAA011",
			"109":"1cJxHU1SGOOjuIxReU7yM_eGJrJ63yLCm",
			"110":"1GerpmnNlOtjCnXOAYj18YJJ6xBBUfIVO",
			"111":"1FVPV9d8jmVs2Eh6N4OhwKeEgxhAMrWUU",
			"112":"1oVQVweP0xN2Ah5RxG8_8dB5Fe0ePXI6N",
			"113":"1y_dZ8V-T1NEDTeHdSaqy3H5e1TfLztWF",
			"114":"1MwoLBpv7zTEPERF0q_QoT8u8ZsRY1DSc",
			"115":"1nRc6wdPRC4kKLRIxt11r2ltfwkTgWhVB",
			"116":"1w3GdUoRrgKxGaFT7JmAPXSXml12CpvLM",
			"117":"1xQOn1sgZzzS9vg4U3HeJAl4gi0KtsEhf",
			"118":"1zd3jY8QgRhdMk2Jd-pZ5vrHqab1xiGf6",
			"119":"1Q_ontI7Bt3v9SwNdYnDl-mTHYhqgsp2y",
			"120":"1tedFlDKory2Vt3BQ8k4c_vSMJFX1WePv",
			"121":"1D6ztbJUEnJtOj_VXN7Kv9Sesil2dYqu9",
			"122":"1gVwM6TXsRLDSOaf5NDUWQ5lJdgRDw0t8",
			"123":"1d3uRisMHNiyKKPrdLaOEMfVotPvTi3I1",
			"124":"1kSyFwDnL-hf3cuD-qmytByvBSC6u3jb9",
			"125":"1bcQtbrxYHaVKqy6-HNgSyqDOtJje6X5X",
			"126":"1_IjElr7etbtsOg91UoWBS-MlBAnk05iX",
			"127":"1YMHgGA3kxsDQrVftRaIGIQwxRZrCNR4X",
			"128":"13qJDToTnoqLtAR1l5tWFvhBX6oEoDigx",
			"129":"1K_lSjTR5aBFmYhL6P2rIOroUuO-1W0CT",
			"130":"1oDJlX5Vh_VwE68aQz_ZQJE80L_fjTtO4",
			"131":"1NCVZSDqwYfdqBobylhKXJwpZwlyq5dtW",
			"132":"1MENLBObs-wwLfKv-i4mBi3F1WeFxUrMk",
			"133":"1ZKp2QG8o2DKwJl8pNM8dQycefX8yGegr",
			"134":"1nwhwXdWH-NLUaDOXIChQxzVPcG9d1Lcy",
			"135":"1iWJA2s3eui0e_Uwsk6316MLMqc8vsi02",
			"136":"1tPt9wocX-nPcbvKhPWgW02up13xCNS12",
			"137":"1biFhnWJ83k8aJIQeRtAk_idwSVZ5OKMs",
			"138":"1Aixcm_EbDOYcJ6s2zSTBC-3VyhR1Va5k",
			"139":"1KD69zWonYbzRBXZjzK8pdN19M2KZebYH",
			"140":"1gbA1PC_ACT0APti0PlXeGFEhx39FLn3D",
			"141":"1arFWfZ2-M8CkFaO2Z1QZ8KkAABR9rhsM",
			"142":"1blCHWljAeG45K9Md1qbhpVP703t3MOsK",
			"143":"1Kae3CHjAtDNB41taQAusi2NA_BErEWgt",
			"144":"1oQEC_xKoSGozknEJmqP0d5gYzMwbuQhj",
			"145":"1meTbCJrQCtzLbCdzJxexI7Wsq52v4aIa",
			"146":"1qkuaKJS310ycd7JnysVOl-BBDl5HUJoc",
			"147":"1NtZ9Acu47tMK6ZIxvtpzu21YjPgh1vmW",
			"148":"1_W4XCDLuWehKiOYsa-rntZAPCHFH2_Sa",
			"149":"1pd0__ezgflIxT7KXQIwTMhXB9r0GrZzP",
			"150":"1l80sIEoMy998PLc3zUA8unSufYmJfnw5",
			"151":"1-Or0RVWdgtgWuJ6b-Z1xnqR_YwD_0YxO",
			"152":"1BPdmrWkJ2KY_bdtdF-FmxMOVvSbQopEh",
			"153":"1M0WPknuclX9H9rZ_Psh0tIuHjR3q9MLJ",
			"154":"1VsExLTGpF6wXj_ks5Me_ue5da6G5nsN6",
			"155":"1sFWFC291TZ6WEB_1b2vU3fKvzgD4eWsM",
			"156":"1FvXl76PnWvcap-TMW24MNIWohdYD8CXR",
			"157":"1Mk7Pjvi_eeAlQuzpUdQkpvjhjNWS6Co9",
			"158":"1fEjdVNCrY6FJd-ZdvJWkMmvqMzrtVLyy",
			"159":"1-XaOtnRWoe7s5XUR33vy7EHgTNJ6ARhB",
			"160":"1pQOsvEAAgdMiUSCOikL_KMQmCsDf17k9",
			"161":"1A4KiykZeKYKDxOV6IWhh4YhEUhJkzTEU",
			"162":"1FKhQ3YsqL4Wzubzmxhjt39JZRkTs7yCX",
			"163":"1-oGP7poiZNUrWmGx7fzvQW3FA9qH58bh",
			"164":"1BPPMu3ayER88kZNTaVHP2a6aDsR9W6uj",
			"165":"1QwkIO8HF7PFR3TtkWwdfJl7DAeEY8rG6",
			"166":"1VQSFDUdkSVHZyBcx-tDl7Qadlud1O-Ff",
			"167":"1WTAyDINdpP2-zHNX06aDpihEp6WJyWbj",
			"168":"1m8J1qmHLdz_oop7wJ39rJtuC8_H8cg8A",
			"169":"1H6UACvLeHWOlOBBsvenmZKQEMex6dbD1",
			"170":"1Oc4UmDuW5Gdwj18dQVdOmDIVavt5iNCG",
			"171":"1h8h-licCZa3dG_xR9YMofG5YLggYAvVt",
			"172":"1rG_CjlFTTVEDGRnYYarEjdDZQHauc4dr",
			"173":"1neuSdN8f7AmX_weLeKQkABSlWy1uYMcJ",
			"174":"1pcTh4vA_gLu8p7a8fQ9J4zAN15_MHpEf",
			"175":"1y4Wy8Kee-BbUp4nMLzCG6n85RLe70vQI",
			"176":"1-HlY7KHGqoRk46XoUgNlXd57yOxUQmKS",
			"177":"1AN__gff7XJ8cT0k3PWguvZXx4e9pIVsH",
			"178":"1Xy1FKhZqpqpJp04eVLll9Xz75bqrdWRj",
			"179":"13ioulWQud4cYvDrfSHeg_R51ysBBJjRq",
			"180":"15P1dcPxMTbMiSJVht2yhcjuZxcQRXsOD",
			"181":"1PD5NnkBy2HX4CCleghkltsLqG4Iwcei8",
			"182":"1YnOClyj9ySDg6zaqiP1_jxYSmIOLw-l0",
			"183":"1LRZg7KgyayXY_PBU_RwMWKAZjqJb2HQv",
			"184":"1Yv_I0HQU4magGhf-AiepvZwx612LkvUg",
			"185":"1f30JvAQHxM9lRQKZ_fKfKxZrVSm1ExlD",
			"186":"1y3DHgDewmG77XzTKhkQLam6-YkJtgkG8",
			"187":"1-JZCUzyz_OxLgQwL2sXKv--9RONUGnAA",
			"188":"16vpul_E_XDV9u9s4vRV5NxbTsh8krhAY",
			"189":"1CyAFPULGhvkqAjNi7njWe76FVpXWzJsK",
			"190":"1Ef60AHGM73jZfJGmEi9PdyPJsVWIIc1C",
			"191":"1dYTQYsjGcs5zTU4DLt5ft0CGRMg53BxC",
			"192":"1lfaJPt0GQHr52x5lZugBJ85IlSudADj6",
			"193":"1rN4bIssQP_ddwaoyPFzhuehOZHs8xb0r",
			"194":"1XdHRVJPiU6QPdYhpCcRXNMFMqEhqf-i5",
			"195":"1j6KXKsryhYaQ5KZReU2T27ZMAjYPaV-7",
			"196":"13jv9TUHuOphI28Tby8Le0pfTYWTj_vjQ",
			"197":"1RGuZMyDS9ijXUDRkczjAn0uzjsNwoanh",
			"198":"1g8mQHbHHduYRe2Xvc2h60Z1HguRM0wDM",
			"199":"14W8XBIeECB2n-svw8rTCkzZVfyNpT5Sk",
			"200":"1kenAgMx2wkfCQwC6CPLFE34Neak6CLIv",
			"201":"1Qd7W3TRsP-T99b7yhrDeENBrtYJVryal",
			"202":"1p1RjJDVWULZrqKQQhnnLbt90O-tvK5PM",
			"203":"1l8xKwVp-2kLZeNGOm0jQtu3iPa5e0p8E",
			"204":"1JdpbgCW3v9xKkbXtIizf9AJn4c0Vix4a",
			"205":"1bwHXUPcrJcqdd6FJ1o38c05NAbaXCeuh",
			"206":"1_v8rM3wDEQX_D8l8f3SCFIPnFqngm5OO",
			"207":"19YFeTvQENv6p2fWAjCHD1prSkKysgxfI",
			"208":"1YVK9N5byM37Yi5YUrYMcQFzlBBAX6f5n",
			"209":"1XM3NMCytS6fNxMel7GfqJoJXzRWAzKG5",
			"210":"1HYdBI9eIOphwlyy5u7jKDwF7eewld4ue",
			"211":"1hoKvVbtOp9RIc8cQA7TLARZFAVfrj4Mq",
			"212":"1CDG24ROHwT7YOVKpnS_MYclFHe6seEk-",
			"213":"12dXotx4JMOX84Wulgd04PO3hqoL8Gd4A",
			"214":"15AuUhH68zRzQeAm3EZx6PEyLeg4lhZ8T",
			"215":"1VcSsGhj7zQLQow0qNA1PMskTreQ3v-uD",
			"216":"1elpEO4I8eLv7HmsN9h2atZt94aexdQa6",
			"217":"1OGXsPJHIhfOHJ2xStTRZBj0OkeeLynjj",
			"218":"1bIkpMiFoT6xt74UEJVvgupm32Q1KAoGi",
			"219":"1WL0O4rMnxu9kE8j40yrML8N1pSaiagkT",
			"220":"1g7y9GxeV8_2LHQjzD9zPEDPfZKzDnY3I",
			"221":"1tfhwAvqzkW0WDSHYXMosUGt99NuMtHNB",
			"222":"1O3CWVeclv1nMxqxuuQpEXKQ-9ICr35bA",
			"223":"1sMuPd2Q8ZwvfezjAIbXL0Gu3eiqYo9qZ",
			"224":"1_4oJnM06FmHToOAk3AoRp6P1eVb06_C3",
			"225":"1NkI9Xn-iLlL-_ENf6z4eFcM8DS84s-Q2",
		
		},
		
		TGI_JABUNG_GBS: {
		
			"1":"1csKqXjWfRFdsdxiPr4i7n5htxjLxtN5H",
			"2":"1L8HHBpc5OJgvrvGMGfJdzpk2bchujJhT",
			"3":"142RwKPHz0FN6CCSObxsavYw2rbRGgFpi",
			"4":"14xxGvvn4F9pUJCmLRalIEasWktOedUdU",
			"5":"1HZNdpu41OdIQOJl7iZSZxcSAhJzk6xy6",
			"6":"1WKdUWPZkbdqAtwhgF9sb5q6qpzbL9Oie",
			"7":"1YoaeALH62olcL5NOogFHtKUXUuwfjeim",
			"8":"1ay4ZZLWCJZb-rAjPH18Otag4bZV9lET0",
			"9":"1X__XTTny5U99QqicVOILzA5P7ijstlk_",
			"10":"1fmTzROEtBJuGEFfp6U0M90RBc98Lz0S4",
			"11":"1FjOTmaeNkfVUel_g01qR8JBaVH_jWfVd",
			"12":"1He0Fb0VDqweqEcroWInEq3llPpiVE2uM",
			"13":"15QKEb_DHIhF9xtuRuSZj8eMgMebKWgVl",
			"14":"1Ky5dAZEjLxmVjZrZZw8aUw5wkAaS051i",
			"15":"1AcKb0G7zZobddo-oswqdQdnUVBtd88UZ",
			"16":"1peKK9jty7vi5eLlOn0aFFg7bLEdPeF6r",
			"17":"1xxCYyUdXBgEtjKT28lwZ9tXaTekY6J9h",
			"18":"1yjTWPLgizSgxXm3hlFrvxJrcG1KkOEoI",
			"19":"1vhmRDXf3ZbxznE_LzaD2tKkTV1nrdOaB",
			"20":"1SpjD_h5riRTxoCiGmqHmcshhNxiRg4xM",
			"21":"18qVq9dCj4MW46O2WiZE1ZKox1oJlbDBy",
			"22":"1zh_Y3bkT7Kptp57QdSs8YQG5A0EmaSuj",
			"23":"1cL8t3m4lMQcDnZRNNLHdy9ee1lPuxTzx",
			"24":"1yWNrq7RhA34fhyjQHYw8haIfgdKqMiNk",
			"25":"1BrNTIBk1LZvgRBi_8J9RAEvZKf5_iSer",
			"26":"1hNj3-Pb_sRVjCXLT7DCTO789JxrGBfiX",
			"27":"19YkEpQkwC8Qe11frPcvoy1HKK_Sb6GFx",
			"28":"16QFiiGw1NhoYKr7L-oc-QvZKGeGSEDu5",
			"29":"1ZDAv-ULnRIofQI_FROmhB_GBs3A-FKJI",
			"30":"1Y5wrqNZ5pV_0ko_J5uGNUmOPzBtmku8V",
			"31":"1gldv02qqughWWcX5Q7Xlq-yLdic0UZiv",
			"32":"16AYPGIQ7NNeVOUS3ghL8AnUJtVz-qCLN",
			"33":"1Qgnszs6qpsOizIi0GWrJJb2nluBzicvz",
			"34":"1xrol8g01vyNt0iE5qzbSBRX3FtU6v0LU",
			"35":"1dJ-c1NmNQVB_qTyYEc_1HrtsjGwPDTaB",
			"36":"1F3jGqb10lnLj1CRblGPpHB4fd9zuXfGu",
			"37":"1NtxOXLd4UTk_VsOL9q_BV3NPMDrdgH3R",
			"38":"1won5yqA75J-9If3Ff8LGfjcu_Wv4kQqa",
			"39":"1SKu-rogB7i9AFNBL1-qBbM91qujP7MPo",
			"40":"1TKIZlMS3anN_QNJAzZEsUO6BWWeXYEeJ",
			"41":"1KJoLYJRTH8tjIt1SDLwewlIdqf9wjWbp",
			"42":"16rSJtoK6BZrTvn3UNEVH3MuRt4NZEWmU",
			"43":"12_RqvzZrh8hHMSRzMCQfu5Im6CFtOrG1",
			"44":"1q7SdJxWfarzHtYDdsYNi9yMrKSrjBYSw",
			"45":"1CvK8AuRHwgubs2846aIU72xxP5kWggSB",
			"46":"1y7F3AeArYB22XGZQmJOHd8y_oc4aDzDN",
			"47":"16DoOEVzpHsrzKPaw76U57VPXBb613WGI",
			"48":"1OTtyt9kdyJkTnRRbybxTSDC2bgGggVAK",
			"49":"1WBLPamOtXlK14ucQLmACoyq_Dxw9E6ck",
			"50":"1hBaMnqwtL2prcKxqSaaZjoHUW0V1zp2s",
			"51":"1q3YU7goidRwmgVgx79RhrtVQfY8Hw7oh",
			"52":"1s9pKGkv1nrE_f8nt8ASaeEhCMJJj5ooY",
			"53":"13Mbmc-UYYsBuJhXk2sUsNhChRw1DrvAk",
			"54":"19LsZflrxec7Pmm36LScVpK4sWlUzfrCf",
			"55":"1R79wDw11zdJXQwiylmy7ssAqq3K4xIlI",
			"56":"1GqalhPdKftawFQN3NiiZMuFEAldNvJB3",
			"57":"1murs7ZpzXEgVjvs_J-FU07HBwBpdk8fm",
			"58":"1Lp0NeYxN091AdCMolKWcOV52OUn2Tq1G",
			"59":"1va-EwN5ON6merigstPfF81C6DGbwq1IQ",
			"60":"1mUshcLuG1heN2Jf8SvqggcmjyGVQhvAn",
			"61":"1Bf5HTUmSxUeBZmqcNTcF2cbQ0be0XeFj",
			"62":"1ejnm6R09Cy1rb2VOSypmAY6mo6kYjtkH",
			"63":"1f3PpFQfXWMFCbeK3Bu0ivXZa4iZhR3Sm",
			"64":"1g4mMF3YWn82XJGj1wqmBBubrHXcnjvJq",
			"65":"1UJMowrNKbFu85X8Gd_Jy0vHsaCXPYsVK",
			"66":"13vNg_-VMvxoivW4JvRiPP5r6UiQUoD_K",
			"67":"1vwv4Go98TfDmkpOFtPnzZ6cWE6htqU7y",
			"68":"1K8Czzaoo56u1niSA2RtTheUOdBBXGfg-",
			"69":"1kchaPU8LyyGRdLfffJmR23J0eSyOw9sw",
			"70":"1P1uwRAfbq35TXnxFqdEM2Z3mi2Ia40Xr",
			"71":"136t1v2vFHrmWhRI2ir1l23tWS3ISXaEm",
			"72":"1OTzhltTgs7uhicNJcCJ1ldRz1YVM7Xsl",
			"73":"1aQ-E14sCMsQ5PEGGZwy5P4Jk69oKQLfz",
			"74":"1w-KY1W8fWD64dJnIQ1w1rwohVWAhh7PT",
			"75":"1DH8h5Z8pK01HjCEfHYVFnsML3UAbRySM",
			"76":"1T8Ww4fbyrLBisq6c2hX8-UdToO-Hpjyd",
			"77":"1Gq9BViIAQ7QNmjXenpMDOONGXa05ET9o",
			"78":"1htJCNL1Q6pAqlb2hsWZezMZPHOJ9GNSm",
			"79":"1XtdsHUGGF32dBg-cTW9wsdhJuohBpkON",
			"80":"1z5-x5ryL87tF1QCWJSKowMrtZOBEt2C-",
			"81":"1SD4yKSTArxbtv_ydWsu1R-zQPuAUjzQ9",
			"82":"1T1US3dMXd0ViOA9uKtk-dVuTl1vYGqwF",
			"83":"1EgpiVCsT9laidg_OB2Yj-MeNUppPSK4v",
			"84":"1olnkzRqyb9xKf3bmVJhOoff055BLbg2M",
			"85":"16jydWYxDX99WLODriWG5cOXByncTFqXi",
			"86":"10Q7VIi3QFAFn9a6msoKP45KpEAJ5eqJp",
			"87":"1cahsPsish-dOuPeIvjAOOtp_rNvIGKkc",
			"88":"1JHl37KDoBZJz7wqiQ5g6W7T2aCTnSgcP",
			"89":"18cBGFAOpyR6CsFV9AWsfUVG_HdaaHBat",
			"90":"1Vw8lS93ofrfBaAhouFK2YFI0RytqHBOg",
			"91":"17ShUIpxfhO-Wr5TMb3P40Oe-EOcdF13i",
			"92":"1LFfs0PRPAbMaW6mMKM_md1PMrwMjtH_7",
			"93":"1wBwG8WaYMXcfqUWXvUqpBPGPVdCIFf2i",
			"94":"1NoFkMWC6JAQFj9LEyUaW4jSNyxgtBGH1",
			"95":"1w5WAsh6PqJfXA7vAqMZr9qhrLyh1Sqtp",
			"96":"1zhOZE-rjR5lNSqB2npEHTVKECuY69YhD",
			"97":"1ZBvbed73YtQbBoX8sJ1MEqTSPalmXpPM",
			"98":"1I_V97oiWH6i0P6b-N3Ejrc5ERqk4HhuZ",
			"99":"1Frvc6IW6fzU2i34zGqniqKWw_ecTnPlt",
			"100":"1zluu5uMw7vUjPT11FDbfLOthZHo1n8jH",
			"101":"1S82LQ3q_mbtDYrrSPwXTbn4ljMFbbhaG",
			"102":"1olHkjuouJ12N-Vtg4MKX7UGaVv-SiMeg",
			"103":"1KaYAtnKmEAcGTYE6LVkSr89ID1usLsSG",
			"104":"1RpT1r1-FEIOOoUT89njwokTV08EvrXs0",
			"105":"1_8C3yxwI1T_QwEVKze9nAMx5N6HClEw_",
			"106":"1DpaWhUTnj8qgMdM7xZRYoNVoQLQwVVJ8",
			"107":"1fM0ECsSbaxprkkWEJufkFoBaSeQb888h",
			"108":"1IgdbfMreVSt5O_genzAGDCabSTEXDxM-",
			"109":"1I2tLkVXjtmoVkfK3rBsljcywoaKi8kzq",
			"110":"16-K-Dg4VDvE_nMxGzyE-0kOU3w5wZ9zH",
			"111":"10vnn9Jlp822OFFeI4qbKtsDXVkLPe1mh",
			"112":"1hGxvwAAi5njCFmiP6AnQRK7GxQPaZAy6",
			"113":"1P7iyN6SPDTzY9-19fIZqb52U4UKJs-im",
			"114":"1jwwc7lCpP42lkLZTl2rpyJRZCGhufiuq",
			"115":"1_BCDRTRRBtblhDROS3xS_LY8SKqqb1bB",
			"116":"1Y6ocmuqSgA0xlRoLEOB3eZJK7IbwB-FR",
			"117":"1SIDM_FNjB5upDSVrdRU1O_6Z1nE4wppB",
			"118":"16LnfVjL_OX1_bBtjufyhj3_uZKmxX_0_",
			"119":"1EpFdxZ6W-E-GnPgQ6eITxrrgbtoKHHpG",
			"120":"1-lnK6me5M2aZ_nGlP1SPLBN0DDCAm9_S",
			"121":"1vsBGVHHo1jsYtqqGMS4sqboZXhrh6-6h",
			"122":"1zp6Kqb5WwsXfHE2-ydRdIcMj-7TrWhR9",
			"123":"1oUX2izRWf_TmCP6Qg5DLgSx7ehFdg19G",
			"124":"1TSi8JRh5p_Ll6HC1syVe654JJBuKTCO1",
			"125":"12K_77RAi6ZQPmCZESrn25AlvMUNSCb9a",
			"126":"1Y4ke5OURo8-h1vTv0-l7yeeDv-CaxdzQ",
			"127":"1meaGnbr3ZvMG7pAysCiYe8PczmTbUFjI",
			"128":"1EA5LH3UIv4zqIvMlyhpSceS_cVeb3iMh",
			"129":"1o5HTEe_w9WDcKSg6TB9GAWRgsAemBBjT",
			"130":"1FNb1QISnp3oje_RcTQFQiqk__uifwaRS",
			"131":"1UZKJQrVYsBvIANKCi0iXjeomgpPe2Anp",
			"132":"1Gp8UD6boXawIKCaG-bepuK3vbi2uzVAN",
			"133":"12NQEzYrfLE007JiUsGXJzWAywx3Orzr2",
			"134":"1ve1NwJRFAdTDcGJonWPJOQIw1TqKsy3d",
			"135":"1_w4zR7e1i1WfdZjp-jfADPBjtkjsQHbB",
			"136":"1_NZ4E1bp3pvsNF3HA2rrXaBx57uV_RkF",
			"137":"1yR0Gqg-b0Dv8S_bRzIbIVKK8_ou2kqt2",
			"138":"115Tmi8QISMK89xNbypU7mp3vI53xs_en",
			"139":"1rPx9AqBsodmDqPMCRAtcaJUHGtfypgCN",
			"140":"1c3jZvJlFmh3eaw2ig10_hiQx5ZQ_77tY",
			"141":"1t83P2UNfDbfubnEdzx0aW89qADpexdcU",
			"142":"1nkypxNUPUu43oTjm__mhA2n3j6HTNzI2",
			"143":"1lHO4oNV_K5kE60Ze2eKkwWmBT_irIFSP",
			"144":"1lIcEe8tYSOhiVZU3zVoRATeQy9-L0Yh7",
			"145":"1-szdqOUnWXAiWghEwGw9bkPnn1wwbpVH",
			"146":"1a9a_GIw_DATmGRdO1FTeI625ggyOCwuR",
			"147":"1J94jS0FWiEa0ozGoqIYaSsGNC_QQlsGq",
			"148":"1y8yiqh5K7pvcLW8qY7a4fZdj8NF12X_N",
			"149":"1vcDEUIKcYpTn0JsjG30A4j5PDCYaUUON",
			"150":"1NJ8dslDi9TZXBEKrjKJYq7ckFY10IlG3",
			"151":"1vhpiJqzJz-yWGMUQSCcnpApj5ZtCzmcO",
			"152":"14UokmkRROYrTEZQUzwDPUyi66METghpK",
			"153":"1CQiDilhHY2mKdQgYh1aOaB0232AFMZVv",
			"154":"1M0xXjnZKY8ga8eEjB5QFDnfS0dAeMpzr",
			"155":"1ObFKHWOEY_8wVkluSm9IItF0sY2UMQY7",
			"156":"1zis9AhXLVdeZrqOK7aJkqbQOicRMQv8i",
			"157":"1dtzB1BKSXNQ-o0GdMytqmirEC88mVg5m",
			"158":"1MmUkoCJ-tQKPsniOSPilg6HgOarBQ3KU",
			"159":"1sL0UWevzfOh52QflrLkqtGmxDpz98Af2",
			"160":"1PEuGmgA2qGZxAwZjiK62sNq7-ICeeBFi",
			"161":"1jYInapi8G4oRTVkeGH_lXqNIUjPOVUT4",
			"162":"1TykNsp6SYQMP9k_hoaBumrSZtjBkXW0_",
			"163":"1UI1EPZseOe_oDtGg9AA9LTMFHaoV744u",
			"164":"1LZUX0Xo2tCH2A6iffMkPm5FOxY0oEhcx",
			"165":"1xD5zv7z_1uCWMzy2NSYc1W-jKZ51loXJ",
			"166":"13OHwlWewkR-eLzTuqQGZpKe_M3XLh7D_",
			"167":"1t1KAAUovRVZxReEnLpC8QMomZc7XJ5fh",
			"168":"1x5i7KXkJepKtolOifK5u78MQ7_CShcjX",
			"169":"158Xk5WjaFL31cOekYtO2xrGuM4VfS90Z",
			"170":"1CtOX2QE94pvK5dSsf5BgqNt0PTFj-klD",
			"171":"1Yh3dlfU-rBW3xXBjr4FGwtH_8fxaM6SF",
			"172":"1BvAltaKQzz0KCaO9kQY4-LWip5X5OMgo",
			"173":"1W1NIPo_dOngpzDqs_9MnYQluWVyy8SHS",
			"174":"1rjUn-Qx9BypR7BOUD8BOFyPmtrPqmE4n",
			"175":"1Qm3r8UK4emTcjwa7CITimuNte-9xUBnt",
			"176":"1TZmKrft3bnZIYpaowqAgKXUjciAoiGW1",
			"177":"1B2ABRunFEQ5kj5mCI-2NAKcjUWWMdkKz",
			"178":"10pcaBMlgxBLl9T_TAcF1q2v87_OsZwLJ",
			"179":"1MsyzBhXk7DoKkVEtmRuSnS90oWUOYqHs",
			"180":"13GQb_EzguayP0WYGKN4S5MQj3KkLCUjQ",
			"181":"18CwfrdFhcosm4-ej1HS3jMOtw-e_l62u",
			"182":"1OuebtC4s6cpxn2lGoPHdZLTtM16mGxVK",
			"183":"14cMQCtl6lu2Y-ssiLoHkWWHNc4S6xV4H",
			"184":"1PNb3eOe8oWcnYDmewSn07MD_o2JA8jV_",
			"185":"1EyI7hO4cW7813pckl5WfFRuAp83uqIwl",
			"186":"12GlcgE_XM4XpP7SrWrGCm2JUHiaoCr6t",
			"187":"1gX7TlNptHqXI-wRjj4V-a9gqq1sf4hQz",
			"188":"1l7YLYB2GFJcIvzToQBKcHgF3RIt-abiD",
			"189":"1GIEOX7hkfRMYiuPhguP8O8y00BMUR7GD",
			"190":"1ZvSaxbl4QbizUindWAwD4R2MAHfynjAI",
			"191":"1Hms2Dhs-fxctzVV_Lfg0kLCrpEolYWEm",
			"192":"1t2JbKpOo_dACDhFCpJtuSfeoyUNWAUSi",
			"193":"1wPKiUxqxogNbUtrXfXoZ246hVqa-PeyU",
			"194":"1PTinIQAAITrUI42f4lrlMiywmiZLMKtj",
			"195":"1ckg32s3Yz6aAZqZUbksjwhe4L2pBtPQu",
			"196":"1K2CM330PvVErPVsRGJ4LITsiEcDz0QXH",
			"197":"12K-x5Kj57Fzb69DRa5OyARHPaCkJpkM9",
			"198":"1cSwPMLGhZ2tQq6-wt7cq5N9v-WL7w8-L",
			"199":"1ySGoaVwQ8I2Z2x1C4EHmoMHQwcv14JMh",
			"200":"1CJQ6rZQfzyswh2-Ve9jFEhfYoKMScjPT",
			"201":"1aOkg0VTXch8MYoe5IoAagLhJCoGYv0u-",
			"202":"13FBicssJvWWXW8XM6sflFYzMVwA6OviW",
			"203":"16-tixT7oDWns0eAC2QC_lxnid6Q_ILJ7",
			"204":"15a7J1pxZ3UEKfEsxuBLPlPJrnZfao6ZW",
			"205":"1QSD7SmLS9khV43D7d_XgDIIImQ88l8nU",
			"206":"1nUjzb7pIXQx55mM8nZVmzbyP_Ocu47FM",
			"207":"1RWQR3vD_-x5IDeF1Fw4CGE4wCwqRs_4v",
			"208":"1eXE7E2jkrcjMmikNKOscEWtIN5TC3Xao",
			"209":"1KI9PjDx_pWRZcCYiiiOhG4hQKaKsta50",
			"210":"1Jv39KJu8A5EzZHx8tmUB9YMaBzG3T9Uk",
			"211":"1gWSfmJgHpvamvUe5F6QYCdO4FpUEvCSL",
			"212":"13yQEzFBPTVnOh5q0KottWWN79aul5lR1",
			"213":"1LZ1HAXGxrQyu9UjEXex9Dv3sDI-2wIe8",
			"214":"1fidAXRMYcpngFHIbNLwHzFQbXbqaQuZE",
			"215":"19m0oneU3yR-3j06vADZHMn-1KMoPMsgw",
			"216":"1rA7JbB0DYNRHWXmXlQ7cw_RXjouEm1Ll",
			"217":"1ev-sVeNJ1JIYZiatBkU72hcktdg-EusF",
			"218":"1S8ELcvU9RSNiuosdxK4Y3CoxdHLdEINI",
			"219":"1x4BNU5w6lXWPBM5m3Ab6G9IyAn36Lx4F",
			"220":"1RPGS56c59kvlUlyAo1EAU5VcZ0hmM3t8",
			"221":"1ns3qkIc7hKOGCuUC_frLlc0VI5PC2mpA",
			"222":"1PxR3MRyRQbVM1ygTAKctZEHJDvQHC06Z",
			"223":"1Kp3RgYDdotwsYUYjfD28gsttNn5xOPHq",
			"224":"1pVuZzv4jRsgze8jdVrXXdjV_qeI1F4q4",
			"225":"13zvwIZRkuAoZb7hQM6Q_SFcsXUpjAVLD",
			"226":"1y-KAiCwyRFeDj-xmmcIubYSGKnnN3reY",
			"227":"1p8a8FcV6ywYvsU7PG40DTQlS4wehjgfr",
			"228":"11j0cAIggUcKpwsKdYrSSAwSnYKK7iWKa",
			"229":"1fkG1EYLgViZtr3iEBpnQPDl3MTa7BaDU",
			"230":"13SNwt063htiGTysanuPFMTuV3_NBmm_8",
			"231":"19cnpj2hEzFxkT2nfLsuw0_GXnTRozal8",
			"232":"1VYbrL3j44QWETAmugEYHaXEl_4ccQ1fE",
			"233":"1Gra3hekCE_SqXPXVdtF76r8Z0D-W4nJb",
			"234":"1o9BTEWvIMrtg8cISn7WOxlSQxpDJkYwY",
			"235":"1yhbBeJ19Sulm6uFaMA4BykVECXTLg5E3",
			"236":"11lT8R7IJHWlj5XeeU1B4qK0VS7IYjP6y",
			"237":"1ez5P85VD5sQ8CbbNOXLQ6GB5vy63niUx",
			"238":"1H89Vs_QG0g-GMHr7VUffVed_x9hbbig0",
			"239":"1WUxNfuXI5FjsTwxG3zE3GKgFbqiBZpJe",
			"240":"1gHfikq_UAYBl7ijbU2Wrf5Tz7FjtCbTQ",
			"241":"1wJAmEmQsRSpi9m6-4sPTEidDsb2tnaBH",
			"242":"1SqlzuEARgK2__DYrWcB9M79P8gdni7ZI",
			"243":"1d4ANbEFWsEh2dOsmZ0KsZVa8_nERxUIs",
			"244":"1vdTmSK1xo3YcWSRWSOfS9fntfUNCBE_D",
			"245":"1JKGpnjCpPpeY5VPa8quvf9cvUdnbyy0L",
			"246":"1d_QcOyjyha4i1iVVlZO4ztfOrqQLRxZ3",
			"247":"1sNdfEQVGZFOJrcxXblHZErZiW9GwgX10",
			"248":"1jO_UbZ-Bw6Gtx1-ho6IAwv8QAOsPokVU",
			"249":"16AmX_jC2URZzVA_9wqgtxBxic7XdJHJI",
			"250":"1cWVKX4GBImZonS2-zdRbu7A4evPc7vjg",
			"251":"1H7QZlgAaogTefx1GOkr0pRS9llMqHRpo",
			"252":"1qKRQgETR3BZRQUUc8DjcZbNerxEU64sB",
			"253":"1wgDElYKjHcl20JFhQx_QZNytb0-Niu95",
			"254":"1MyZ8qWw76iH6EKSMx-9A6uDUT6KA5eQ-",
			"255":"1yj7BZ_QmG1K_iQuL2xfCzJXSwz2yQ7Pf",
			"256":"1PW3BlyC76tZ_7IXQ_kF2WzS1f_w1zYBS",
			"257":"10Jw3j-TjsASJumtEv3ZGLgnLYR5lN-0r",
			"258":"1HMgvDCXShMHqU4Aj_XcvnPF1Idg8h8Dc",
			"259":"1MnoO0oYZJTEixo0U1FmvCtYZFBLCM013",
			"260":"1NMqplV0gLcN5SBDCmo3wrtD3Ec0bRSBI",
			"261":"1MiI_fI9Aji7O_xbBfnnJnS-idfFeAZ8J",
			"262":"1O5m5p61LwLvML8EQJwCODpQM9jmyCGq_",
			"263":"1Z5va2321SmWgkmTGIOIcUW9BcJNT-yVd",
			"264":"100gVXUy4-OFhhfKOIF_K2Hoz-xQ0nJAv",
			"265":"12mrFDg8MN0wXpZDjI2Fb-VK0XjhQLExA",
			"266":"1BFBARFrokgN0uy-e19ScMk-KZfe1TcKC",
			"267":"1yuDDeJ7JB93amJ7szPO0XVRU4trn5z3z",
			"268":"15e7fJCIgzHnpuZKgfLGzeW0CghpxcakG",
			"269":"1M_3h5HBFn4lnw4flvLsy-6faYH-yJGP_",
			"270":"1X9LqVifuizLdmFmaxTor2xYPJJW-_Egh",
			"271":"1KMTFIVo92M4G_QjZpfzBmcqar_miXAJL",
			"272":"1ImyuthSR94zvjQI_R-KS-S_jd3XrARx7",
			"273":"125Y1b6YBWHCDW_xEKw_1-R0IJ-6S4XPx",
			"274":"1lKuMFQuiDJwJk4qShW2NAnRc_3oHAU-n",
			"275":"1EXOyhnjo6KG_G7vB58nzsnaspspRRrd3",
			"276":"1tulsvcGnEnDEiWrqU0X9fDI8PR_Ox4Dr",
			"277":"1P68kVxr04oW85_AH82U-DFj3ANUi8chi",
			"278":"16KQ_zMa08rsc4K9-yCCx0b-B0thwFx3r",
			"279":"191Kff-aRpv7blP3zr_tzIJdtTfkGY9X8",
			"280":"1HhyFb1HfCMKwmU2k-ErNqOMGz0KboguG",
			"281":"1YS0BJKtnZ_o0FDVsE6qv1eqvB77vMqQC",
			"282":"1VL1OekmYLkLJJriEnHMHZFykAK8RQ4pF",
			"283":"1Facy8O8BJ7gIcFLnX_NRTknCefa669wK",
			"284":"1MISVn3-TsBPnbhtYL41OeOoPD8USmKVi",
			"285":"1Q7bEWWNW1yZY52RTf1ybEF5UYzzyAIvD",
			"286":"1PmPTTU99DpgLsdQEmPQSk5UxS9GsgErC",
			"287":"1lIkg-tc_YW8bDd9AcaFUhuyxLS-sHKvV",
			"288":"18wLT7_XJ9HDgwIbqulLoISMm9nVZ3Jyn",
			"289":"1eQp97wBlu_wjg6IDpb_jEIoZsI7eBHhD",
			"290":"1Ht4iWllIxD4oZkjtbUo55WpG1nsx6A7l",
			"291":"1oemxmTLdU0g7lKIFGMPVNyls6eX9I12A",
			"292":"1J-ZkwX73suTy_wXGLhHyW036UCe1OE5F",
			"293":"1QHesyxdwHhE1CEni3W8r0qwq5L-5pILc",
			"294":"1qEb2zjx7mUt1y5cg9YuFtP8lolNdtvJV",
			"295":"1GqnJvP8d1izxAAyBcv9wdh465k6CKN7C",
			"296":"1KMCDhxgDc1HcakcRbhyBrKw0_k2YV1Mw",
			"297":"1GDZRQ746AXs7-kk4cnWGiCzIW1YZEVZR",
			"298":"1AKb-raZK_qtqLAqu7BYNYtEUM5MBUzMX",
			"299":"1gxyJkGaHad1kKoqsYjXpVnkVhFzPiP1I",
			"300":"1MjfTBlSX5ylsBVG-yEIA-jJfTBPfF2D4",
			"301":"10EKTO9JGjZd3HcTM9tfiT9I-1NrCE5jH",
			"302":"1TEC9PWtlX7YEmng_vl_RnKfoNwhkuuLH",
			"303":"1hfnrqLByCKVvfwRQNEzqbrUEjNj-gHh7",
			"304":"1UHQLqPe4E86vzZGLA_EVkUrr5CHGYYJP",
			"305":"14jeUapUOAmQVWm3YOe3BrZ2K4rtIYLjz",
			"306":"1RsXeV-MujNRmwoT-LFF7eubLai57D22v",
			"307":"1MRYj4HqxC560pPKZqoLXeqKjdmq7xXB0",
			"308":"1bTi5mIB1dYKg_ICoBaH8FsF1lnpIVnxh",
			"309":"1KODcp9RqFd7jCbmXtgK2Q0t60i_vap8I",
					
		},
		
		TGI_JABUNG: {
		
			"1":"1OADy3LABxxJ_4IAH9qTpA4iMSXa49oHG",
			"2":"1a3pXOXhFloOyD27FJVWTdJYBCESra-y_",
			"3":"1sL-XTVI8KfCNK9ulZARk1LjlUTm-e7AM",
			"4":"1MkNPz8oIgi4RY5TCMvP6mEo97xttRb6r",
			"5":"1TwItJRPmPGxmgxWxqHp3M7VvdiqbcR4g",
			"6":"1vtpGKUIyYIt-Dh-tHGqJSl9ABsVPkHFW",
			"7":"1ySk7FNVGmPja2EgSQZqrjCQMXPq3JNJX",
			"8":"11cLfsC7LMJ2lya1tPxB4xgALRugxYkxl",
			"9":"1oorfGgVpboxnN_Y5tiQIdxpHbvQh5Xs5",
			"10":"1ra2c6mGU8P_iKJee7iwdRNa-mqXS35kz",
			"11":"1v9G9OFHJwHrN7UzxIZ9iSkzT2vKT_sDm",
			"12":"1Ww4yoVQSMl3iNgH7wymrsYXiv2H3y5Zk",
			"13":"1P6zdoP_-bXS-myfBSgsvDE7mmzYXCJ42",
			"14":"18nyy4dznJZ8jNhKUjkNerotRzJjj2ozL",
			"15":"1MFHDWK3K7Y2ZMuUpsSwa3ugiWTCjMGk5",
			"16":"1OEmkJk8soUXK7R7V2AlL7oIWUfeu8SZO",
			"17":"1m5-tUTOEToXjr70Nf8Ebf6a1bUsQ8smB",
			"18":"1nof2w1ZEXVaJQwG3RUdRC3Eb5N3-ZA8B",
			"19":"11xGyyWmVcrwJhJREUGW14N6eSadkQ0Z3",
			"20":"16UeulckYX5iZw5NeKGNUKJqghhspJ2LH",
			"21":"16rSRURTQDbQoke7cr4F3SXhQGB0Ug6UG",
			"22":"1n34V6ZegdURbCwUMNzzQYcKyrcThg3G5",
			"23":"1HBmORg7qTWc7ryWvoeFyLxiQjoXzqMJu",
			"24":"13Hb4s1kWG6d8vSs9RMb97bYkXJcCnhPE",
			"25":"1PuhvhCAocAsUME1wW_uhHMzSDH7NljdD",
			"26":"1mnXFZicy3rskj8lsIOG50QR3lTjU5c1W",
			"27":"1YdKM3aVeFnfXYCtopLTPlUnKbj-UGfuX",
			"28":"1dgEWOf9M6zSqohSUGSDHy8uyXhvWMiBO",
			"29":"1IU6yd2DB7q0Kd1BC0q0RjSoh7ooWUpbe",
			"30":"1UUdWQlxtgnxwM5ygdpZ25aV5SQWVEQyc",
			"31":"1JK8qI_EuRgzJMNvqHkc-a8HO6XSVJT3n",
			"32":"1tkwqPzo_Q4bAcSsiaXlWsJNH6ovbibrO",
			"33":"13pUUv6687TQgASjU4P6PX-evMgFrclUZ",
			"34":"109H46-OV0k5oN-MZmEQW0L6KnCde1KJk",
			"35":"1Ok4ENBsoEr5BxdEELFmLw3FiDyjDLxkd",
			"36":"1MGvWYFaPFcRMQ4A8LwxJrTOzlvGb_xqj",
			"37":"1gJd7gb9EKl_yOv4oRxypX1xdCkzEwCt3",
			"38":"1R_r15oo6LTt5XawzrLMIk8bOY9axgEMA",
			"39":"1AZfs4s07brLXRZednAq29uEpkQO2z_Hc",
			"40":"1bXv7GVt2v-nCdNUo8VHV3rDQjjVZWvd_",
			"41":"1y5z92rhNVCrXjmQAlHeq0lwNRbx0iVfX",
			"42":"1MRrFeQ_59iUjhe32BkxVUMrvfPSIBbaD",
			"43":"1HrT4cqZlOgkrTImtrwbNUOUyZV6Wfeld",
			"44":"1ncAeeAi19jxY74_WMM-GI-CbfuijNhiD",
			"45":"11RuOBi6djTW_HdZQP43ZYW_mOYTuq2FM",
			"46":"1bmpufBX8uCSRZD8KdNyBJL-O6_k1A1_y",
			"47":"1CyDnTCitGDBoehDB7jnl2Hg3bTVRvV_L",
			"48":"1eqq8nCD_fcWjPeHs3HSMkKxlWuaOkiL2",
			"49":"1Tih9RoFHhN8--O_A5rkiMBGolG9OIm95",
			"50":"1XvzJSZF_mVtxzYaaoRU0Anjxh3K4DVRH",
			"51":"1be9MBb6FSil8-uN-1XLU2e5uc088qzeX",
			"52":"1EtyYCN5d_DMYuMV-vgki1JHILZoeEPm0",
			"53":"1F7FaKh5t_NCc_Jm_riKGNkLRJEAS3xW1",
			"54":"1FfdKOEyyXH0GzjVTt3q-9BiglZhz7vmr",
			"55":"1R-pUgwUmWO04hYnDxIBDe9Cv831AAS5h",
			"56":"1FCO8ELM9Xvl0A0vMW4R1XhRxQv3xzgJN",
			"57":"1LffRpHrY-L1qMH0TeYsUR4boAAvaksEc",
			"58":"1o7jyLUeUAflahmK3vVkbH4i7qft4fbHs",
			"59":"1HxQMkf0CqCQAInlrrKZkWxPmGzRqsh9U",
			"60":"1aPR5J_SGRVLdxdLxF9EdSFa5Vqb4S4QN",
			"61":"1Oh4JBx1dhUoc1aYgPfrHYwp-5OdIFQNh",
			"62":"18l46d6NjAMWGiSU-uVzPId1XjGExBHRr",
			"63":"1IpTrorTs2-VhetvhwWstta8Wi-DKdTYp",
			"64":"1Ljhe3x9Et7yc1T5jKTXcf-BfrHmJYe2v",
			"65":"1MjT374FtPE0DcbcGBbGn8BW2x_HXHzx_",
			"66":"1MZ61VUtePHbXpKLMTy62Td5L4w_NA9un",
			"67":"1Q3JvgCRtQfVjV3CYPcYl9W03n7tpjksh",
			"68":"1SyZOM7DbLPFDxIXGEfYFl3IosdEiN8It",
			"69":"123SBcNKFXzQwg0jDcnb8vLJ--dFxHH8u",
			"70":"1_0YdTr9r0V8q5F6DAfNykSWawSDzExME",
			"71":"17Qc74KVCznYE9oSi2FIQv3NY5yHQxEc7",
			"72":"1E6hQHKexWJG8kfKq6EYvYog3lDQtnz_Y",
			"73":"18fmRet4NboRO86hqArkS3s-MD5NwSnH1",
			"74":"1PM_oUu4oVEbRo9M1-bMI8SdoylNsu28i",
			"75":"1gu4AKM9lOe-sgpwu1cUlVZGGBisjgwkU",
			"76":"1k9eyJeJMv8goEEfE24aA_cAECVsemjZX",
			"77":"1Gsy-7tvm7LNWWDHmUcauHjImQlnuN6VO",
			"78":"1RgD1dZvQbDVhYkC3i-1jjBmlnqhNKi10",
			"79":"1Uvb_O8GhCfWzNvDz8PH8CQpEBjTs2LX8",
			"80":"1fYEJWTQV-5t1H350d1WE7l4wV8uxlWvZ",
			"81":"1ji25qzOSezf3puQE4SBA4Oq1jPssWvsG",
			"82":"1nN4tUiCJiGicabmEs91U5IFRSvA17NT4",
			"83":"1No22YGFqmhKT_cGLUEQmYOa-9bPaeBrd",
			"84":"1oZDjya2xhTHihXfCw0V6rxWAlGOipNS4",
			"85":"1toYLLe447nERrZ5jwx5Qp2WU-fEIEO7b",
			"86":"1w4a6tpYEPL32UVaK3eRX0INSnjm-relz",
			"87":"15Rl8nx_jaeBChcajWJ4m9FtB91h_OaCl",
			"88":"1CiTXyPvrpUGgdG_Al2S40jiYrwC2k0ZL",
			"89":"1KWVwJdMDEPJPUOxIKH63_L8vzz8lFLvq",
			"90":"1_HTZRuGpn7kwwJVBRdjWXv6Qbxj0-f0Y",
			"91":"1TvxtZKl9SVpPOx2RRkr0qSoaOYCBxG0C",
			"92":"1YuDlFHM75WC3soZaYkGTilWU0Y2GURYR",
			"93":"13EXsTljuM-e2etOv9ioaYHxRN1s_NSWm",
			"94":"1WYIBssQ5P7uwkuOKjuk0qx7ZnKSmXDoN",
			"95":"13mQOUOXd6z2D5Gp7WveZxeOYLaLT-cM_",
			"96":"1JoOeuy2ir7QNWYMAm3U0LuiiOt5qGvd5",
			"97":"1LFg63sGNHaj-BM8y0Wr55uIjynOpYZ_R",
			"98":"1Sve_zfwvRBwFrz0lpPvN3k0Od82QgIAF",
			"99":"1xlPDhNQUI08Lgns5pzW4_aqZtMDFUt01",
			"100":"1Gm4YZGXhHrHnAdWIYu8j3oLjJ5-DM4UT",
			"101":"1HKUJWjvwM3w4EGqep2ahrkWK5kVxrr2T",
			"102":"1BQuTqy-62P5cK7f9yALiidnPaXurXLtP",
			"103":"1PlDoJskdV2eJMRmHkMDUEgVXsT5a2fVh",
			"104":"18-tlcdQ12_254dACpH6ETv1k6Nlo9vfJ",
			"105":"1ZyhUBq4UB7CCrgLegCfPIgjDgbuJkQVj",
			"106":"1skbY2_GOcMaqPrRdX-ilcxN7LpkaXRCZ",
			"107":"1C7WAKpY7F80x1nrPoBx8l2xqV1R9UFx0",
			"108":"1Oqnw7lfov9PHhktkSuFGn1XLIvjJGScP",
			"109":"1CeQ-A9mx5NZqRm516zafFJEugJam-uSL",
			"110":"1DKm5H5vdNiSHl2BbjGHyvQnguPnTBtUq",
			"111":"1I-HxxgFopOHhfIXpdeu1-Pd-qWcrleZ8",
			"112":"1u1-OrwOEPXBlf2XsapCkFaYHyHIGvELV",
			"113":"10Iys0ij4FE2hWZLKdv7y6kNxWYj6wsJ8",
			"114":"17B7lPoGgxbDcE0__ht4fJxnSSLecWN0l",
			"115":"1KaIF1brsS-RfhPvXqxolBzDkV_WreAgl",
			"116":"1ijczsMI_E1eM0VrgWZGxu2avWWSY7ngk",
			"117":"1yVBUesFX10KblFy6-Z8_XBGWh3q1oAIz",
			"118":"12jH_MGla-KZfEuoqGpYBLYVipLHCX1mK",
			"119":"1UM909h4eNy3D3VEgcLn6DwMCGknr8H45",
			"120":"1tHh0eenewfHukfpQCNCKTqdKwMYZ5kFn",
			"121":"1Oom7JJ9cXnClne5h2p9YDsZxDGiGopo7",
			"122":"1p2jx9TqNv04_io4-TZ170WKgAaWeOdFb",
			"123":"1_L2X6DX7n-SIr4clPtaAUmIGS9SzgGuN",
			"124":"1fBHs7zJLqlMXPOeivQiAJJrdOeEDvmjZ",
			"125":"1ikGAzxyxbolnzB34e2Q7wTgRDM0zK9UR",
			"126":"1guoUJHXb3c0JgazsQHUInLDUxqK2tdMo",
			"127":"1rwjP-KFKdzzDDwjH62aBXMttydDtpGBT",
			"128":"1BwT80MF3FfCwk1Va0tvcCWSE3ml3O8fo",
			"129":"1PQl86AWR46WwWdevA6WbGahWcKIa6DWl",
			"130":"1lXBMyCYyvIkjVb37qx6vKUdW-MA_Or5n",
			"131":"1wsW5TNIu9jBw9OFgjoDd-wQ8lxr-YkZK",
			"132":"1gu1jsU595QzBPabU2PRFzn8WwUJn2Aee",
			"133":"12PM9lgC9UP8qpa6IZrz0Rz6WOpcMSETY",
			"134":"1D5oJf8yZrsWH8mZKZHZGJ8BkqBtmbU5Q",
			"135":"1Ba-dn1vRSp0PHxLViflS4uImq7ABjqyn",
			"136":"1r3PeCTvKfJPusd9cbQ1qQW12lC18Yf0e",
			"137":"1w6-EfUPZAUwHIbxl3OnNecz4cMJMkkuH",
			"138":"1fnOPN60yO5fGMC2l_62mEJ4ENYIN383o",
			"139":"1U7HMt8CTMMabDFH5cRSE1_0bl8c69vw6",
			"140":"1vYStYyfbMH_rgTA3uIM7oIxquV-Kzu_7",
			"141":"1aH5Inubiyi8kz-U2GFIhn1niSLrmxEe8",
			"142":"1mi-z1uCdil5U2KAbHyZbYuOsr8Xc1FAF",
			"143":"1CCXtkcVQHYpNWBxAdvOhBpHBkHpd1LEC",
			"144":"1Z9DjLwS03ITSPimNPwFqD8_f3uGw7b5M",
			"145":"1daLT7GxH1p5w1ADkrb3q0x_2Kv_8l2oV",
			"146":"12Eukzcxwk9aSoJa4oOJmyHhbYhDzvh66",
			"147":"1DrZkH4bnBpi8S-TskFXTeOaKQfX_J6ZN",
			"148":"1829VG8bpx4pFHLJSl_VDgvIwDDgXRxPF",
			"149":"1-6-Uh1SSE-o2XCj0R_hY5c32DaOrCsL-",
			"150":"1M6lIeGjF6A-2f5IpP0hCHtOeb-7ZhqCo",
			"151":"1dwjAErQyvFHIAnSB_ycOYYo455Cy6D3w",
			"152":"1g1g44MVY873W2Uhfe_DzUd1o2qs88jhS",
			"153":"1uqF-jV9BgADZLsZQgaXlutmuMimQwMFB",
			"154":"1CLarEpXcuU_2U59_k0yyGK6jVHV1g9In",
			"155":"1FfXD-cP7aOUYnwITapI9CRDwMtnSgS_N",
			"156":"1Uii44N9mDeW5sHZu2WGZfXdddLoK4jOl",
			"157":"1BqQuBOQlXC0QK2e-mnI1V6nWMfdyXRkX",
			"158":"1bIZbQOTrKLIN_RxDQhHqiyyxwkqMWX4i",
			"159":"1GMgW6FomJvXNfL4wJWBEZyAzBf_6ETgw",
			"160":"11MiccqK9mNURo7UjdMi9B6jmIMhTNGtJ",
			"161":"1V15-l0TyX_o8r7XOQXIMwkzN7xiK1YGQ",
			"162":"1Y-I1uCu808Dbsx0eP7fsqMGRWC_6QNmm",
			"163":"1yw1kzEM6z_Wp537ELjtLIlWfBMHOVS00",
			"164":"1yrkI6QR7PTKI69oNqDeb7Zh25LWlNQGc",
			"165":"1DjEG2x_ofwQX4KkD31PvNj0Pe20SjLPj",
			"166":"1Hl4WBKetnT4qMawB2ezmnxbSHHWy9VKL",
			"167":"1XALJTdFY2D3paowJDONxyMbnEM4hsTWu",
			"168":"1dOgHi-aFXqrgVNv-njwc7gKmeZ7R0oCT",
			"169":"1ZFlPVki8iEXGUvLnV95pj2miWuF4zXjp",
			"170":"17Pq0s_4HqKak_VDTjIhFygyf_da6kqmV",
			"171":"1BcEA0xVt8sb7XS8nT1GGlH7dJwO_9z_L",
			"172":"1vQykuJslmWnFFKMxQULqu2WOnJaVKo_e",
			"173":"1-drSPNUzg4Z-yo9uCVyCcp29f-qLdRa8",
			"174":"11mvQTZd1eDiT3FJ0tWMTTl6f1wskpSFI",
			"175":"1eAjHeyBJSjTzBGbahKHmD-xabQaxCyiQ",
			"176":"1SKvauBru-Ll_Agn_NVy9gpjOJrLnsXok",
			"177":"1YoWMmUzhZbweRvCc_lfCiapAjBe6P51p",
			"178":"1mLntZVpJ9Q-p4zcFj9tm0DWsMpBmktft",
			"179":"1CmjzZYcGnUSK7WR8MxTWAq8KjlRhQ1-A",
			"180":"1OmNSAzjOjTO6Sr4aHSvJXCqYknNqNtQw",
			"181":"1IdnkuwrUa-dDi0Y7JvZhq4_aWNhY4rH_",
			"182":"1FoZAQ4LEWwVWosLZC4Az-DVTpzdIy9E5",
			"183":"1QdZaIYgCSRtk2_KGtQzsXXBIcDqWfOWB",
			"184":"1iVgzm5oWgIVkKF3f0vD2DFEX471cOzjm",
			"185":"1i3DH4JRnzS3ShRM60i90eqwdqB4l521K",
			"186":"18ip2QVhXZbNSRWlAmZEdcIA-cIp-Vzgf",
			"187":"1mdnXZcfkuVPLAKV3mBiE536uaA-lovEM",
			"188":"1hrB3t_Uuf863DgaqPzdhDmbarxKwGBCN",
			"189":"152eHG9SryckfCPsVwMaUkCQ24BpE70Kb",
			"190":"1csAyfbODGTYTBiFQsxazXGJmWXxxxYBp",
			"191":"1IkqMxMJFXvTzRkn0bDBSYtkDNuYdFgWo",
			"192":"1XA9NJbFWIUT4rpZY_7ADxU_nfLnlHqcO",
			"193":"1T-JamNHc_iwIUF3Xlko74spRrc3s6Ydc",
			"194":"16X34NIPUnYXdQeRE0TOekbjhJWFKHIvj",
			"195":"1or5MsuReOpTT9SQCaHeKKiXqm8uxaeCf",
			"196":"1ySjSBxBk7Pa9d2l6hFR1YEgHvbSUotI_",
			"197":"1gFvW_LcvTqPOhKwx-U1I3JlW0MPTTxym",
			"198":"1zfK6q59Z9F1eBSxPkprbNuvG-8WLc9v0",
			"199":"1o-kymYYWIRd65WZXV27ajnLa6KaZi_r6",
			"200":"1B_b8bm00H0praehgpDwOD4iKxnvZfoXn",
			"201":"100axOHIC4WZaJPUuJQntRAN9egiV4hn2",
			"202":"1YCw4q9fwVuZUYeAF80FNi3WQ7QBPzOCO",
			"203":"1SL9HaGNLAfCgpE0CmFdehpkzZ-bGe_eg",
			"204":"1DXoxrC8Xo9uMOOEiBxkai22v_DIkaXsy",
			"205":"1Y9QnD7Ub24fdMKGoHNf0pD9uN_eh-MEw",
			"206":"1E2SDjNOgxqm1BVivmUK_vuLK1tsbY6nk",
			"207":"1MYzg08En-vHXd6jaWu4GLjxNcjMNPU7X",
			"208":"1qxsP_jIai4-ZI3V0lqRtg8kjNWHkxi-L",
			"209":"1VAE0W0REkImhADU2O0tbFSHKP6gRlIkn",
			"210":"1xRPe5PxQjN8sU_dVYL7XZJwci-Xmv1hQ",
			"211":"1SgtivqgQFey0GYVSMqfdhFVH2Sgsy9DI",
			"212":"1Pfd8k9wG8kHxeB36pYSFWzT-a9KUpPNN",
			"213":"1vkbVLUHLRsLOrEr8yjFJWvhGRyqAgUp3",
			"214":"1IFNrTkjSW3hHS-JhvCydrzQOCx3BadPd",
			"215":"18_pX6Q0-NBPl3Yuj7ta5yRqf-9ZJ74dS",
			"216":"10BID1uGOCH5eCr4YPfRTEsmGas86K26r",
			"217":"1SSQaIb5Rwg__8feNA04030H6ZeSrocfW",
			"218":"1PfogXdxGinBc8TWu5Oy2UHMlxZuWMS8b",
			"219":"142uD6l_PLooLX1XbBGzYspqBEhYVO5iK",
			"220":"1uDK-C6AzgsWp8M1Gnmxrt3DhznlqAOZ3",
			"221":"17Gsmc4XwoCC_0TM-BV31RDTXBxn5ZLdJ",
			"222":"1v3VvrkmKBxkumZQfygXWDR8zA-GewMUE",
			"223":"1LCZHSsvCoqntjxjIr8jPDmrVq3qCEKYX",
			"224":"1mgYilXgqkl4oJ6VcQBXVq094pJ62cTvs",
			"225":"1m33q_gKQPp2gDIbac5QfMdib3ydgvo8p",
			"226":"1lbstJ1VK2S80pE46Vcq12BP-5DOgCMqr",
			"227":"1E4ZeujaSFwgBgaXOdD5FMx4t6761ub2d",
			"228":"1C6bk61AbrRXTqFVLP-658RLwgB07gXd-",
			"229":"1kXR5SpJ46_AoeYcEASUKqP4NOJ8qvitc",
			"230":"1_gZ5uExVDEY0WuTyfw1npiDlOs0vFSN1",
			"231":"1Ti3JygVBVHR68vgnBTcUqOAvlzXOjwPN",
			"232":"1D-HaN483xeoL7yABtxRU_ekLwfHALRde",
			"233":"1jALQ5hk0Z1ppYBu1_nCGNvbi9mqDLd3Q",
			"234":"1LlOGA87U-v1mThf9GLi0384TU5_n5eSK",
			"235":"1ZxGjHqHSf584dAWpTVewYwjgDTdw51Wo",
			"236":"1q0ZlIHrIM1TMIiDgSHTgIcw8dV_Lnbj4",
			"237":"19RDXIW7a27rwjW8vbtCYzWarjFSJFSIT",
			"238":"1PJUS-wTpxqJAohLzs-6hPFD7zKkhJhec",
			"239":"1AUITA0QLr7RzYDiH5Syy8AjaeOQc9yfH",
			"240":"17NUJZPQPWGkjItWjV6N5n_l5Nwft9j8L",
			"241":"19cD-2_wVOl88oNQqfe9IQsXhDTt4VO1V",
			"242":"1DYkoxtIW57-XzLRePCWiw1KYH7xG_pHJ",
			"243":"1FPZ5sH2nv_gUkhCVaqiajZ8Fc3blqBpP",
			"244":"1O1xD1-mTaYwWuRoT0XCbqTsMGguvBIo7",
			"245":"1nj4DprYX11FuuYa9pYYXYzEKcKpM82mB",
			"246":"1u9qbcz8aVuYWjGz38xTX4o0QhYf02Rig",
			"247":"1twVlD_J_Mwm9OApT3QaspRdCu6zrmIM2",
			"248":"1dQW7BTFxIJpyxFQ5YhByLdOyqTQV5CAF",
			"249":"1AyTHnyYvrCA7syzDpQjThV20Z5J4Taxn",
			"250":"1FN-r6sPW9tkeZ-k9jqrYV3UGexAG4Zkk",
            "251":"1fXknLyjqvBv7rVYX-sFQmEhUem7ETGau",
            "252":"1X3JcZyOlAVakpX4_t6rFcZb6oyP6GmQG",
            "253":"10OLI_10BDppNBYkXoF7Y-UENht1QcR0g",
            "254":"12XpAuCdzGSAsMU149Zmw6B-0y_BlVyzf",
            "255":"1QM_qCLhpQCOI7nveON7fv1zNa7D81oZw",
            "256":"1i4x6UQawF_4qtBC_0VYchk89eIeHdj5n",
            "257":"1U7euOj0-r0QiJpA8zKxsZlzGXletlONv",
            "258":"1fGy7joDgFIODeoLjEAcrClAfeRK4uYGr",
            "259":"1o0JG7rLfQyFSJ3LaO5XQh7D3bM9A3frL",
            "260":"1vzovdqMdecrJQ0ifPUKC8FCoBsyiK7bs",
            "261":"1EveUb5Di2SROTQ8jLwQj4aZC_LtCio0i",
            "262":"1gYRu6F2NsUe826HGODBztL47dLPTL75g",
            "263":"1FXeJN0Xl74B3bbQU3EWmtSZyFP6vBd4c",
            "264":"159N5okRZCoP9Btk2rgYtSJIBrk1q0Esz",
            "265":"12OCEA9-r89eJhP4Xwqhf-t3f_dZDNvI8",
            "266":"1jCFbZvi2b65475H77Jk9-Raaz1krb2Jt",
            "267":"1y8SgmyK7UnVYny69tP-6T_8-9w6gBotm",
            "268":"11nSId91E6dt9jl227np5ye2QeCiuAuCl",
            "269":"1s9cKc6kUhrMrg7o8KiRIdRHAr1o0p39_",
            "270":"1ezjI7PyKgB5PY-G2iv6_1U4prlsQ28AO",
            "271":"1Pq89DNoVuiP223fWG7lvMxjurV8JenFy",
            "272":"1KvRvJN4pQKFSHJgCW9CwO6DHiU9qTCU0",
            "273":"1xbGhvgxBygP4GV1K5QmcJBz1soFU15Pi",
            "274":"1UhAuF08t33Cb7X-yKyKdZpe-Eua2aLHo",
            "275":"1oVUUfzAzNMy9ABc1y2PvKhJvyYRk0yyT",
            "276":"1AusZeUH_boHjNkBFK_AhDZiAAFwwd_Ur",
            "277":"10WItsmCeXiy0c6v2EJgs-q8x9hS-MsQS",
            "278":"1WkwjQ2r8Lk7MpnsKyBy5fVx2C1-_Wt42",
            "279":"1VffZqUO9s_7rtrQe2VYc3prKa8KWIyOj",
            "280":"1hZjVmE0w_4QW6A9vEuFlgXialujO-xD1",
            "281":"1XuMhYXs6mskVU1S4CjyVeoJL3l5pWYsG",
            "282":"1tiLPMvyZEIXm5fVgUHXnCPN-XAknAbcJ",
            "283":"1uAL1S7ml1kJWZo2mZNxTsYoTqIKEoYzP",
            "284":"1xqjzVvWG6-wE2zfJsQhJJlLJnX1Y7VCh",
            "285":"1m-G4HgyiKfc338fCpDGcrIoeqjEDp2HT",
            "286":"1QvJTnjpvyTjVWzh15eiw5MPoDUwPvSFT",
            "287":"1kQn1opM0bJhfBPZsnW-mF_wr0SdR0L4g",
            "288":"1prgUU0BAhS9IbQXg-qIi0dwBg2wBL6_J",
            "289":"1oWEjJhZCXBnYogvylDd2a8kt5yY8c0ph",
            "290":"1r1TIq-_M71f8zRc0IZNBkXPW-Vor7DMo",
            "291":"18C1iUH5J6zJVShhHdeNrJUd7qh0twbAC",
            "292":"1rzofFvx6st_MumYYuWiWOxdPdIEJkk7q",
            "293":"1PytiIS9Z6vFZEWEFIBGIAdsze1lmdwJs",
            "294":"1sJRagZd_CBIh8sozBJH7NLH9pewJxCi7",
            "295":"11MMzc0tWF0TFM_Bt8cV0YXZk_Qwg60ee",
            "296":"1j-G_PO4IuBQ9vX2ostL0HJOM5GCO91xs",
            "297":"1OziXIU6NpEx9ex0_-jCArLWxKqam9opi",
            "298":"1NFE8Rv4CbGQdHiEzoJSFF2DyVtD9EbEe",
            "299":"19OWHS9l3JvpRbhDzlKevAHzQOLlmUNec",
            "300":"1ouJULTf8Ocpnt8gmClS-lo87xDWk4zWV",
            "301":"1EqHeUpoLL8PD6O7bFAmXmGZf9LYcDdCP",
            "302":"1ShOXyTu-EkYYusYOUG7w2hN3jutap1jE",
            "303":"1Bt5hN6QQ72eH7DOe60ODSMM9XBfJ9ARD",
            "304":"1hGZC9VLAgrkZncGvCHy9pVJg5Ta9PrhH",
            "305":"1Hlj2YCjiSBVBejjo6m-HlK9j1tvcxo7q",
            "306":"1QdJVFo925cVaLEPhpnd1ltEUjqU3JWyD",
            "307":"1QJpX0qHe4Z7vxiVCp9SjaPcdN9XuVv7n",
            "308":"1OrNPhNprOQDshHZ4ZWyWEkmG-mpMAXGE",
            "309":"1HVXJL-jgeEZYkjKqDJzRo3CNkn3Roleq",
            "310":"1LNKZVqKpUMHPLP5j6H5xupmchF0-UVzT",
            "311":"1x5B3sB7QpYRraOCB6OMqSzoQnTQ-3yuF",
            "312":"1Xh0fVRY3K5KYXz1s3vpozUpo33AHvVNf",
            "313":"1T16fVp_pWfF4BTapZDJQqaeZZjWFTnVU",
            "314":"1vF65WRcBDKu5NqbKK4_1CCW_y_OtM-p3",
            "315":"10k34rMp4SVXWslcslXVIKfvEF6v8LoiI",
            "316":"1Iiv_1D04LwXa6VXp5-mNSdeZYBDsVBW2",
            "317":"19G338kFWk5NWKj1lwX6uQUnLmPIQIuOg",
            "318":"16YEFKkxP2D4jN2foiTutmcYV24LQePjk",
            "319":"1Vps2EK-PCWakzdmpEiJoNfsb8_kZzYH5",
            "320":"1-7QGVAd-oQFSZvKKAwPnPJv_FqbT7JUk",
            "321":"1QLnsAFymdjvXwbfJ_bfLLWb0RajQJGPx",
            "322":"1qRT1DZurQ0yZjtRMIlXvcnLti_O4EA3Q",
            "323":"1LpCI8LjhAJ1MBjR-7B7Z9ItSQMlKA8eO",
            "324":"17XgYYmBprU8shnupX2w7sIFolAgPTTS0",
            "325":"1-05jQLSToksBRnKtnipz3zURAQdwEwkQ",
            "326":"1zNW3c1qqrRcoK5443ahKD-wXgGYDHcEX",
            "327":"1troEYnZntCgFX4DUFte4eOMN3Oz4DH_M",
            "328":"1q6RO9ftcV86ZaQHsegwLZsfzD3DOKB_v",
            "329":"1y0QE0rWfZca25sxydejwEVFZ6PU5JSR6",
            "330":"1xCNctCphRwKmN-fAmXFqIVmxfvrK9Te3",
            "331":"10SnKoEJZzYf_7tXIQ0QqKY6Z_rsPfrL6",
            "332":"1bC2yjONe0Bj55_4rEQPhlbo6xjIvW3km",
            "333":"1NJ5a-aBhBOdenq-UTSo3TRQq3Qoxd8Gr",
            "334":"1oyUk1CJgwHJhf34sTLPpucr3qYAQ39Up",
            "335":"1QL4sENZ7eDwbo48B0vF23_D7syvnUS-D",
            "336":"1TVaAsdEzz4trOD6b5Q86YnAFnoQl87jd",
            "337":"1Z0MUiBT3w8G3Iha0UUckMpRhvvLoLNuN",
            "338":"1-PcAuvsh-q1yO1zwBP7avmcBx7l6PEjc",
            "339":"1M58Db9fxUmrnYl_0tGuvDZihxKgVs4qw",
            "340":"1my8JF89LmLVqzF3sYASTRW7ckXLmBIm3",
            "341":"1BbbPoJg8QNqfx-dAImxZn8KfopyHWZ5a",
            "342":"1GBohYCRZPx8oEc66ZAO_oTWc3dAd8E3B",
            "343":"1iw89j0TiMOcmycfNyZEqwOnrO-O958Fb",
            "344":"1KHR3Es1pVAxYjFO6_iYlttXCamaRIwsP",
            "345":"1aiTSpQXsDb3KR95OLwUglO8OxiecY_Y5",
            "346":"1lb2FSUIbVY02fnX66t029bkI1QWXfbxm",
            "347":"1LBIvwX8aTx-eGHBtQTuqyoVtHOidlTtg",
            "348":"1g6Smjnqym36nVTsLTvOmRPk4WXy0m1Mv",
            "349":"1TLnLq_JelnhWRSxzjWejULGbfxmuQKTo",
            "350":"1GeOna2osRVClAaqZ-ZuTDjZ58bUYx46H",
            "351":"1JeqIomWw_IY8DcFmAF035nO9GR755oAW",
            "352":"12wcja8MC9ypqPmu1RxJVlWXSHb4Itcbm",
            "353":"1hpWlY8_xzNNPKmUhLVu7zwIWZgw6VZyt",
            "354":"1iFWAVx8XuuS40ImQIT_p6Q4Txp-9QUih",
            "355":"1LU8cfG8bKtDuCeRDqMZNPChAEjFVKmj1",
            "356":"1RpF8m-P_Ub77fEE98aoyxZJtiLcOLTI9",
            "357":"18q5FkzBCimESQF2CJmXJ3y5y3jr2pakE",
            "358":"1JiIEv2dQCNVEVEZdz4-uXT0yFEyhEoPO",
            "359":"1Y926b5dmbFc-V5lovfgpHtkj-lT06yaY",
            "360":"18YutuY_ZLs0mqTr8G-M_pE5HXKyyjmcg",
            "361":"1iXmecCbU7Ugv-3ZxtWlnGPOQKLSsiQWG",
            "362":"1O-9hbhIb8uGbmPa1AnPVPK3Lgx5Xu02X",
            "363":"18XsM63TMfIzmEVQAuZLL3fUbFf25qWOp",
            "364":"1fvhSPgArPJZdY_zDfbfdo9AZDxt3fcLd",
            "365":"1ZLinetSsZGSsnUmSqqPWKW2qW7zQfnHO",
            "366":"17r6H13snOsmTwdZ4dLvT2Jwj9G85_EOp",
            "367":"1aXvzP61EAu4qa2WAIY4OhX2Io9VZMbTp",
            "368":"1fO0byGn-9eEhOycEf6hYqStVwT-yNsp3",
            "369":"1vWmW2zgbGmIOBGT4-AoWKVduaTzlhZcf",
            "370":"1QIpiwoDPQGC0cSj7CBVmOj61sNlBFgwD",
            "371":"1QOk7EPaR4dw29D7Yt5pHkhQbRG-l4XLw",
            "372":"15gO2SI48NgM8G0xae722NkvtF6dpbIHd",
            "373":"1Kfxz6iA3VhyVMsBsGYaez5rUN49jdyhk",
            "374":"1_bE3gAiGsSKFmO-I5qZYzVlhkTAMUdb4",
            "375":"18S4T8l1JqFIvnktOT_lGYfzeqKlucW4f",
            "376":"12Ijmxi4641CmTC75Cn7JJ2RRcquKXhnA",
            "377":"1ExSEPUkARD1Ajqj39iCpHW83w4IFxFB5",
            "378":"17qCPvkclnGgxMy97NfawP8ZPASB7fZAd",
            "379":"1oOFwwsVMOoRAQ9yYoaUfgxMPysHsPzL6",
            "380":"1tENKh3YRNkU4Da_U2wPHBmSKuYutE8Lk",
            "381":"1fWv95iJIX3I-wTOtMaxaObe80hxjR17S",
            "382":"1O84_RtB8fib3tbxU0oU0yxVBVHGL786E",
            "383":"1ZlMGu_QiPlsjEpC0nyToDpLNKgIy3iO8",
            "384":"1O4JQzD_wypb8mJFAmHmOcdMF6uH1Fd_P",
            "385":"1dX-KoJVzftgWU1LjDNzPwxQsVhUZdo96",
            "386":"1wczJ0G_7YX5U68MG8z-7Fw_RcVy9ogKZ",
            "387":"12m2NsHrMO_YD-I5LewlQDJKyTmynlRV1",
            "388":"1PcsKlGVDY3yEgQiXXgvT4ACPztxuRoA8",
            "389":"1yA94S3jicxDXy1x-370fSBQu_3PcSol5",
            "390":"1n9fz3oJVGdP33oSPLMJ6UQWbH1FoL3P3",
            "391":"15rguQzD-uLzxf4aknocd44SWMjTRGq5K",
            "392":"1lgo7KP2ItDrFKFwEmYHvKaY2ELyQgGPc",
            "393":"1JbJTSSgJ2ZBreHQkG8u0rF2m0mwlL4xG",
            "394":"1rLKKTFpZGU7gSB7IhP6yVIyIJZ1fWpOo",
            "395":"14UlIkQNToxDg1Yb9Yec1TBanxsxoD26K",
            "396":"1dFC7I2H4vbx_J_XMVSif9Rsgjl-BJymV",
            "397":"1L4sA8Vrine4fyBkgi_iAXd57nPP8zZxg",
            "398":"1-UJBvifBPxdLGJ8sjjrK8v1pOCYxBsqm",
            "399":"14N7wjU_xCP5O2RVj9osS3XnvL-C2Ko8r",
            "400":"1vcQDWbVc66qigAc75MH3B-lyQronCPDQ",
            "401":"1IVQ6Dw_DTIX-n6nF6rr7HC0wXvo5iDoj",
            "402":"1zwd4SjAK-rhCKZvBZ960bPJl4VipLOab",
            "403":"1TM7CTbDqZW8fpyxmjCXGOkNvVjL_FS1W",
            "404":"1O77iddsMPuBevb3Fq8jbd2L35PKr_p5D",
            "405":"1oBy8MlCL_Z0GNbBkhSl5NBZLSV3nb-lf",
            "406":"1y1qmTGX5s3WZqtYDM18TuG3PZ3UCeAjQ",
            "407":"10DI1nVV0rELF0UukYbLoz4Iry1Ad9Tl6",
            "408":"1J9Jc1cMBw5-NiYBsNoakVjiJuM9CHVKf",
            "409":"1Y3YH3xxPRlYvPzwC8peVmAJsIGU8DGlV",
            "410":"1Jem3ZFOEPyCduLYJKL3aA72mgJGczBhL",
            "411":"1BvGoaTKFIGChE1dQnvCZ98EPeiSWTTyx",
            "412":"1fXkcIgxRAz5Tt2vA1PaFgXy0LtduNCpW",
            "413":"1Nr4gp8Kz8SF46u6eyOrusccb2ckZnT5f",
            "414":"1pHc-jyXz2yK0ee_25WSRC4nrq0a_YaRp",
            "415":"1MwhBB2y8K4_uHY5KN2I9TEM5_X33C3aw",
            "416":"1X6yKsMrnC23Z6N4yG7HqCyWkHDo6ar_J",
            "417":"1CC22zbjiPPpwp3XfaxZ_Ug9qkbl2YuvD",
            "418":"1c_FzMufBhnZVeodTKK-pPgSGc10zvyu6",
            "419":"122noSx8StDJZsVp5jOO2FLJnjRXzdfOG",
            "420":"1yk3CMOUr2eVfKn0ZD8nys0Jj8gug2EPb",
            "421":"1PH7Jxg44IMETUIoThh0ZM-y6PW-_fCwQ",
            "422":"1aQzWYhFNgRtG8Q2LV8cNbaXzYpA7Xkte",
            "423":"1UqZiJAJXMGUjlayYJWfktHqaamBEgxfm",
            "424":"1yDmpxipPSLpLQi-bMr8_8WOkGaqhnBE_",
            "425":"1YkA7WwNDrhI_I6ahhLrd9jSj5WguxayG",
            "426":"14z-017o2Ks7-XS8FE8IkpfHhXz7JBrUv",
            "427":"1aMgWFV2I2myzXUotljDTvFoMbHphtwpO",
            "428":"1m5e0jx9EKTVTEmdhlIf4I6k_s8fIC6rn",
            "429":"1F8m62a8Lba7kxyfVvy41M_RZP60trTjD",
            "430":"1Fd0lQIaKL_Bku1ZPjqBxAF6c3UmLrgf7",
            "431":"1Pqn98aeNGWjbJSlYPfssRyVY4K1bpc8p",
            "432":"17XlTVZv3mIlbLrgb4_NFAEiR32VeAZvG",
            "433":"1uOvCh7dZbjxq5AHa2nobiyq-19-Ywzh5",
            "434":"13cXSJnUDn7POPAOhtArHIpAS-2Kjxd6m",
            "435":"1hEhcbIBaXJZ-skTmDeoVCSMInPUb6iLS",
            "436":"15AlEnev2XKlTeMJwCP9JNNmCmyJh2UDg",
            "437":"1Li8b2zwgC_YNkW0b8FZisn2ByG405_4Z",
            "438":"1600cBK682FofR7zaeFwpwqeMYelmZQqm",
            "439":"1oqVfOBtbGSkIgVM6iNgd5KIJEc9JNhWv",
            "440":"1nhtF8VN6RkHhxWWb-B9bDrCK0NjHQvNB",
            "441":"1O6RI_IqSHInXzIV5rfGP8DJIWWwebKLm",
            "442":"1B5NJl46r-hQMK4R2sFpW9lCWER25JQ7A",
            "443":"1hxZJXc-Nv93L6dUrRuQLO3fpHppw2OYl",
            "444":"17FX-GxIGsoi15QQ2Tth0vUbFulPqRgJI",
            "445":"1nchsmyAXjgYRy5nXptxhsoBnW95t2Jov",
            "446":"1iYGlCqHecNI8IjlsYIujIb3WQX_5qGIe",
            "447":"1zXStt1S-bAyHC65KFeIZCVDqCNEeRr8x",
            "448":"1V5Y1nTQ96ZgOFfJr4HTj9WYT0OtvwhVm",
            "449":"1EOqZT_lTcBF9Ijbisu6xpeDYfFK1jbX9",
            "450":"1YZVPK5qjZRfWDaJouCyo0mVeBUf_F1kN",
            "451":"1BP_2p9mm8PBXjJvj76XgJQ7IF9DyOl5h",
            "452":"1v9uFTra_sA49NcFSR5BB7psw0YgPK-jB",
            "453":"1vuPn5gT_B_Pr8UPfJC7Pag0eQMx5LaEa",
            "454":"1w-I0EL3RsiChSlpCSwAzBG9rUX9-QHW5",
            "455":"1ARrFq3BZgRKMiWESF07K0Loxj9didV6g",
            "456":"1YpK9WkYnGIx8I2jivp74ImpRNQeRO89I",
            "457":"1ruHVhQ3LclqF1v_LGklP5cyS3XuAK7rS",
            "458":"1VEpWoa4oXEVa0VtZrwAliEDUIHK3WRab",
            "459":"1cAM3zea1oTgjkAxJA_lVWEBKhTe0TpXX",
            "460":"1M9ZUv_wAktSOUROcziViWoRYQT0iaQSN",
            "461":"16AQOaC8H2VPiw666pZFuQj72mJARW7cH",
            "462":"1JePg4ed1JnW-G9MQCbHJyuALidGlFI2u",
            "463":"1MVIckG8ZjTAUTMIA-VQAS_19caEnNZqQ",
            "464":"1P-_HC0_CoTh8LV5COSWYlp66Xr1X4BcM",
            "465":"18ruF2pF9iS9yS0k0d8hDrtOK8MnnwtUZ",
            "466":"1I1efRCl9zN9bXDuW1yFKrLQWEPGS4Ne3",
            "467":"1aADrTAcJYJlIqR8Ku3b6BLybm44dCg7y",
		
		},
		
		TGI_BELILAS_GBS: {
		
			"1":"1R4U6Yq7NNO74cGiG1WF1lfkj8j-EyUML",
            "2":"1bwc8tNBHMjYZGCfEjK1GtHiaxqgABXMt",
            "3":"1YzUXCsmoTu5ZhKr500pThGedf0sE37bA",
            "4":"1gp8zF5dqNBUcmn2zrslnPBCuVmWTLVIT",
            "5":"1ktUFD0D4EsshnyH_wwQ7W5nRgQjRERMK",
            "6":"1ABL84M13vSFE6aPeB4zvlPKUAtVe3Gvh",
            "7":"1I5VIJnfcAXxMeR8XKhkmZAEgfNoSPQOc",
            "8":"1XxpnbE7I2tYy5rftUzbo4qA259yU9BkM",
            "9":"1uPFZNUIaSkBIkKgUImBtV5d1g2luUdgT",
            "10":"1wf2udhAgVAcpelpfAo-NV8PG8Pot0HJt",
            "11":"1A8NmB8iRg5tutTtXA4bAbs3W0tAvhLrG",
            "12":"1_VpoeU1hgseJ-oM4pIYrW-3lj9rDxWU8",
            "13":"1vaMekXkxo23ODLuUF2DKK-T_ujXsOSbC",
            "14":"1GqAAIdHulYt3qDxaV0EevGjDQfkEumoH",
            "15":"1LAlhcjcb70ah86UKeQkpUdB8ZxsRcS-e",
            "16":"1azn2d_39-_SwpjYuEt6-sROwAnAeoGNB",
            "17":"1u39XpyX1NO2BSP35390DD9b7LQs0agMu",
            "18":"1WC29NuoS8wZJ38avsXx2czKsCE9vvFBk",
            "19":"1YK0i1eSP-lOmAV1fNzuEEmCIRNCrCEOb",
            "20":"1pqvI69oK0oXM1QmlQbLupeVTEdLt2kat",
            "21":"1vxVsfO7DbWdPbevKvQtouvPCudpAzAgC",
            "22":"1DAJJNLAzenrl--ST9QwS063qAmGb-jkF",
            "23":"1JeBJGeML4PunbtVU5pavjNODuBdwxA3X",
            "24":"1TesHbbDv_mUWDHnZi7m14GiwqkhI0z0F",
            "25":"1pR36M6XNpeC0CHVZvacNkXl_Ad17qj5v",
            "26":"1r52_JNPNVeqU-C4Np0IbJfTQM6B1R4Pk",
            "27":"1sdpsFc8O-LbML6Tnw6FChaqxPUw-dF1f",
            "28":"1voIup0FJGFwsJgUltURXIsiJCPjOzroj",
            "29":"1wJBEl_PYOuMUOcfOZ9CeCAMIAcTKa6wm",
            "30":"1ziNl78GFcw79MbbEV_geWjka_0qyty9V",
            "31":"1NopB6k-T5WjH7qfdeJWdE4zDofRos0rA",
            "32":"1ef2IA8H6rq5sr2SS-0WqbxMjbsbqo-n9",
            "33":"1hECP56ehm2S4I1dx3Iz1a1CrzK83RlUH",
            "34":"1mEEk8hZoSOgqnWz-O0zH3Z15re1y1V1T",
            "35":"12XkpFp1uoDXaNOzmzhLbDraPDaq1kP9q",
            "36":"1D8ooogxxCKK0BiYRcZV6AEjaFuzz_bV7",
            "37":"1K5YWDe9dJu0INGF0x2ImGCGINS1sJFUy",
            "38":"1aX1Y2Pv4LJmfphh6FGinF09v-2W5nd8U",
            "39":"11eosDCdhOI8770XkqhgrYHVSax8_xfJr",
            "40":"1VxHRjp2CwAiO9mAka33AZHIxz617M8Oe",
            "41":"19GYlRvTvGg31d7J7d22DQ4PvIzRS5Smt",
            "42":"1PlGir3TPKrji6TTmyyzomU2aYZFTIeQk",
            "43":"1tL6aBZ0dy8dFw5fmiZKVdKX0mj6mtuFt",
            "44":"1nwz9TV57Qhz5jDy8ym6LZybKUWk7BT05",
            "45":"1-fmPexlu10K34RLgqiHMACCkNH5FwVgR",
            "46":"1pR6GteAqqHqxGliYuomRePfbKH1EOdks",
            "47":"1VBoZuRWEtlicJpxglo2IZUx6pxI9vqdw",
            "48":"1kU91GGggg37C8b-N6utP7xOMKdC39Vxe",
            "49":"1imtUeVgQgPF0ROlbGWDw1cNkBg3Aau7i",
            "50":"146g9FDrHBnazrJhVDLZFbKltkkUgf957",
            "51":"1uKKEV1SXE_7h4-x-6nSiYd_ad5bBq0ff",
            "52":"1NoVETWsHFekRRTRAzEui0PpgAimaqyfY",
            "53":"1c2m6MAoYyuB9p8wohd62b2nup7Kzx9xt",
            "54":"1FZaHCE4-CjfttJMwlJFM2gdw3VdQ0y1o",
            "55":"1SDY6vTsRl0jsQ7KjHBHxzPWrZovvg2-v",
            "56":"1lKp50AhfHIf9HiPN7w0BBiAM_noNvZX0",
            "57":"1WVTray9LhgLPhPhaTbcsEgDByqj5u25x",
            "58":"1pJEpwVMmqroxY5K1ncgH5xVo_FwFMK3H",
            "59":"1Kp36wQaYNnUpJOOOWutZ2rgjJ5QgKmXx",
            "60":"11SwANZAaImYtSvm5I72VuuvOueCAlUD4",
            "61":"19Sol4TbBw5btrc8QfI4nKs8RqfA1W-Uj",
            "62":"1xel1-c9HYvBOfNOB3pcqHorYgP1rOHUD",
            "63":"1eemMs-wj8OL-_3ucjMa8uEeK-fmJou9i",
            "64":"1DOtImYiWl0LPqhGdoZs00KI8gGQW-ygm",
            "65":"1JdcH8vHU5qE1JW3YAcR2QbpS5pEFnH4E",
            "66":"1b7G5x7F45IJeQ5mxkrFvL2Od76zFIIFx",
            "67":"1cTsk8ofkE2EBbOaYZXEsy6Am2G0CGcwi",
            "68":"19QtQ1NIbWry74pKHmH4LidOJaPhqLXrF",
            "69":"1_0CX3NaDVChYRiOB-nPrqi0WMRvCE2_C",
            "70":"15DG77gqtu05mehmT8YZKU1bCnq_Gs6iK",
            "71":"1CSId8k37Np0QClCN_0A6ghVxmEo91Yc0",
            "72":"1q9pa5wSIh6hdRluSLaq0LvJOXSaxA2SN",
            "73":"1EVUa3o3WOuRRb52uP9-uDpVvOVq-LZSQ",
            "74":"1MdHmUq7TCV2tTXEP9qz0DbpJFilmKzda",
            "75":"1kGKJPDxtKTyP_AP5mKFa8B-724M19s_D",
            "76":"1stDwhC1EBFZIQn4QgtAgez7P9bZihLVf",
            "77":"12mM8Kg6IsoEiBoTtfyB8nKcTsr07dFWG",
            "78":"1E1WNusY7FVh78sCxIUnaL7KycpWziEtX",
            "79":"1q1dgJiLm-2ix-tzToXCSvpNadjBQYSZS",
            "80":"1u8uwLE_Kaa1Txi_UCcDOHI7N0aA5BfnI",
            "81":"1JIa1-Y9CGvBSMJ4o9jwEblU_cFVjumD-",
            "82":"1mw9KfspxZkRT5uKFMjdMpmmK8adTqXHH",
            "83":"1RoC3A6DaJ_JWRhfpcYfgBcTUZTajbGYV",
            "84":"1B60SOPesyJ_LHyctQuqTagARR9qTY8Hs",
            "85":"1iV0ZO6TLmW4jGS0PgzxZhMQlSakaSZuD",
            "86":"1cV8mMVMMuqVHonHQYnlOcOjpak3iATLN",
            "87":"1nANm6OTDlh4C4uIa3vcEz5D9KGF3gKLO",
            "88":"1mphaKiU_Yv9YCFT6BCyFZSCtI4ELqt1G",
            "89":"11wYLe5878tRey-3KFFRhhqU6SVrUL0J5",
            "90":"1L5G9HrEmIfXN6AjlV1ZuoWz-EaKkb_LS",
            "91":"1O8dVwtTrBYkcSwV4p5SuIrpMyGSFlZTz",
            "92":"1637IqOa4v5gSErCkuZWC1hMpq2WZu3E8",
            "93":"1fQw3e0AgDbk65GkbfQI3YnKMQNDO1Gsi",
            "94":"1aVjDk670sSQ7PqboXLL7DL8v_v4poN49",
            "95":"1bSPe017dywT90DN9_KCs4qwoLhhe5D6G",
            "96":"1n-D7-GCGPM8R5-WexbTS2jcJY9Kzs82Y",
            "97":"1ySlCBkm5pgRt4rH_vdI4Gvpo6spqmsvT",
            "98":"1Nh02JHT9zasWw6IiMqc18wIEXmLWMFlU",
            "99":"1uon7C9DHmTwrnFm1o2cdmOBnYmQOl1bX",
            "100":"15xPH8_aSBU5g_RdypW1KHP9DRCAQcPgN",
            "101":"1Tgwi9iNjRFmO-CUFtHP9_J3cNw1v69w-",
            "102":"18l_YJ8xawG_qRE7Qlo8KMfyIQrlUQ6IY",
            "103":"1DiY-U27SSPXkgtdirQI_QkmtT6zjbJ8Z",
            "104":"1UTNzQWXW-wRA6i6SbVghHsb-ZwpaJwGe",
            "105":"1oTAnJMsPO_xQdIZco6mftsLHVDFQFip6",
            "106":"14Mh62Lq90SSdj9mShvnC_qfwM_gvNhby",
            "107":"1g_HLniq-aAvKG0eLucIYVQ66HzzJwbh2",
            "108":"1id0HmeUD-i-IwJwp3QWlMBgQn070Y8uG",
            "109":"1xk3ulRu5ofRp_AI2NBeNappVRWZD1UO_",
            "110":"19RknjnZDO_Uc_4DWrXqhBnz8KaoVESte",
            "111":"1MohEMf7Xrut8zLviJ612evOsDBBGDg2Z",
            "112":"1mta0U3uDdOW6YMnJAA4YpLHpnAFZpRcC",
            "113":"1xxMN0fbsFzmVF6j7pGV-n17NXGw5wdZP",
            "114":"1Bwa1vq6rk0q19dwqjsf4u5n9RjuXir1L",
            "115":"1IPyf6aLSSXAQblzVKb2HujE1HDFDbHKd",
            "116":"1v4k63cUPK2bVRt-XJXyP1F-yA_jjbLQj",
            "117":"1HaynjtUGcj4KWXAC-n48kuc4YQYebpZx",
            "118":"1DOVjsnQ3ITADeweQFvdf_dgJE6ovWJAo",
            "119":"1Lgd39OKXtRP-MKPhjCpoBQ4Mhy99pL-9",
            "120":"1Tu3zCxWcxk8b7dX0xPDw5rUTViG7MB9n",
            "121":"13W2qNpIfuunDcuF8yvz37Ttzxq-ndAYK",
            "122":"1R60gkE1am41QNkFoPvEIbtdjmHXFgShy",
            "123":"1zVlP5ZzLuDKBdp1Ftln70c3O_dlayA_7",
            "124":"1FX0oCl-mbR4Re2f5T2yJiveOM-r9n6iD",
            "125":"1M1HlSKhndVcs4wLJhfnqqYWooMQVVst7",
            "126":"1mAvyNrfqrWnHzuY05b50iU3cKrQCjTCl",
            "127":"1GpqfguldWD9XmBPgI8SjzO350xFJuBGn",
            "128":"1NWxEpGS6pk_vdHU3t2XlcX9bM2XJfdIN",
            "129":"1vLVXSn9aaLnDsJvPyLsgA3hG5xhZ9s-S",
            "130":"1wf3eU0vZgPVe81oNUZnyUr3gjBhKqR_9",
            "131":"1HgLl8moyO3e9N43C9SgDAEPnGPreEtPJ",
            "132":"1c8PfsDhuZmRJfhM0Wz2iyptodwmiEpIc",
            "133":"1iNXTU8lzA4kEdxvvcJmAEWhPQjUqr2UW",
            "134":"1K9SE2lqBv6RinHTSVFJhtSQdUko5DbV4",
            "135":"1MhY5mTxvk4q19idiweJ4EFOueZKp7BkM",
            "136":"1VlXxwKaB3piKYiMIBXBnZImZZulJg7Vx",
            "137":"1Y2PmxZzfXU6QGed5H7t1AuDp8gd8_CmF",
            "138":"1srVK9ZwskYCp9gZq6FsPnhkj40JIxadR",
            "139":"10FqH01IsxyMx0PaqCpmkfYo6nzDlxbrO",
            "140":"1B63ggnjwUAMOdSbk5JKc2ATZeclQMV64",
            "141":"1Ea0e8ws5mbB8vxrHeb7EwUXFVuFvqT30",
            "142":"1yMduYw-asBEBF-BCgOI_Rg-dOq8jhAHh",
            "143":"1FZCfbWlsdnS8bXlwDNNjljas0G-u68o6",
            "144":"1RruIkrX3xuEt1VBaL9Oy-wChJMPPclw8",
            "145":"1UzKjoN267VaUSVxpOjYIRSfwwOroPmjs",
            "146":"1iq-h8GAh1QPqBIFamuuSgTbjdM3wPk24",
            "147":"1n0mRkhDtsrltyso-3Co1iObLoOWbOKW-",
            "148":"1tXHzA_HGW_X_uGMKxy9oIyrqX1S6fA6F",
            "149":"12gH3_OqGi4B-nzETmAALImzDZYUjQXYl",
            "150":"1c9nClOBXHjocU1I_OARze6csWFmGjsra",
            "151":"1C2-rmQZxYFog5CnIsu5CYf9sT0eI_8s0",
            "152":"1_VX07JWpXm0XKqtFR8m4bgiGFbgkHD0h",
            "153":"1iHb7bhsVbL4QJbovIeyBj5jYGKuaKQfC",
            "154":"1IVz6c3XxrkwA4rlDoX6IR4hmTNS3iMJi",
            "155":"1Oo8DnNFDcpi8-DsxF6jx5OmQ8eIqPYVl",
            "156":"1QrUUVJnX7GVYjGGMvMRjRriAFjbolh4R",
            "157":"1tIZNXFFKVewkp_OeCdjSZmWvgsOPU8ie",
            "158":"1TKjlcuP0zm8XPT94SozEPxf0fo7x8QWO",
            "159":"1b8wjPsF2C1qYbX9csIaWs536W4qMF_dO",
            "160":"1c2E8--Q0WRtpLINOUY2YKqzLZlzLWTYG",
            "161":"1e-0-1ryFReCW9Wn0oPbJyO3fPvukrZq_",
            "162":"1nZsioWceq0k8JwjDavnyVFiRmP5Nk5ls",
            "163":"1ViJpChYjTWrX9tnZQitNNyxxBIG-wzJy",
            "164":"1UCzvjkHTJmiWRsU88mwtaMHoRf-4R1ou",
            "165":"14z6rNThoPMQvJbJC2gWuYtMYugOmgWD-",
            "166":"1kVgcZ6J59qalWdyC8N6jzDp-lbBFTvtE",
            "167":"1PWt0GfKUSlNeXke2rLK-hrimq6aoXEqY",
            "168":"11T1eg2d_nl6kuvIkN1nH43JIywhvrzcU",
            "169":"1P7osn27vcY3cqR-UoY4gWJCpFuZWV1-u",
            "170":"1qcSMZHZYkqWmdJpOQmMz3h6xHAS9La3H",
            "171":"126-N9Wb3B1L7_HDVQjBWzlB_ceDvio-o",
            "172":"1GgHiTuJHacpxF36RRGincXdo926OkQyu",
            "173":"1TEnw1X4UXBLXv4BLC4MWC2RXRusDRs6s",
            "174":"1Zhz3oj9Da2cRGRFKzCJHKH9GWHgKEIHN",
            "175":"1nFcFpGx3rOJxt1l-n-b6FF50ZXsK9bMQ",
            "176":"1-AxQEJz558nE1UphhFYJ-we0e8riznf9",
            "177":"114y8wVWhJNhNFHecRPb1tDfGMx2TgYQp",
            "178":"19CTn0YeKAUzC6H03-QrGtw_iCSIdXYMe",
            "179":"1cMyPowES95MQSQ6fKnGJJG4JhtqXR6Qw",
            "180":"1fZmbuK1tKG3jA8dEJDPxJLKWtSmJw699",
            "181":"1riL36j3vYnet3qzOcaiT5tzCFoGkjpkS",
            "182":"1-RED0JlBQvxNjtNNR3jAyfaDSFqr9Cwx",
            "183":"1OJ09h5jSL7HP86RpiY_Wugz-JLE6a0ZC",
            "184":"1g5XwiHY9N23bdb6vUufiEboHudX1TqZu",
            "185":"1SzHguKwbHv45IZyh0xDmi_8nnWzkd46g",
            "186":"1iS9mvJs7s4XxYC3OuW4Wua3SBpzWP4gP",
            "187":"1KJehG5Ib2o_8cGmpvliTtlXk-ph4We9d",
            "188":"1NeQ-aq36QtqEEai-Qr_Xh-reiLXdhkBL",
            "189":"1WosvWMCNfCO3kH76WIOXUhGNktipij_A",
            "190":"18F_TRHUYF5SpnNExXweQsC9mH4lht8al",
            "191":"1zfd_Kbkqi7a1HzMvm6Yn_ECRRNIQgX6l",
            "192":"1CIsh5F0QOY9FCvkJrCVfQYbVoPGjLXxs",
            "193":"1Frw9vfJmeQ8c6_V-tW-1akvAWkacANQA",
            "194":"1RRvv_03fb72i95UXcj6o_GFevORxShV6",
            "195":"1ZHqhtGUmCqy349hFhvsStXEWBTD-y52B",
            "196":"1naLKk8RRL5SZULzKf_KSY1-LEtwNP8CN",
            "197":"1y77O-8jq9DS4og4IVPo_O3ckBptYHH-u",
            "198":"12CkZbugIxOE7ywTcPLGFSDhBHBazIE_c",
            "199":"1ZNn26rbH0pVQL0ml36B-6_zEBr2DBia6",
            "200":"14CCgfphE59Y6n-HbiMOLr4FO8U-VzQ70",
            "201":"1-Vj2fFGPgwXDKMmirVAZBOACNOBZtMtA",
            "202":"13qXyVWeTc1WKAVctQ1Ihhw2aMZOwS_HZ",
            "203":"13y0e-T7a3--bNu0YE133ofrbGq9i0npV",
            "204":"18aHz85DErwr5ffqYp5F-JQjZF8zxM5o4",
            "205":"1AVXbZW1EBw-sYN_-UcPyMDbkMCc6SKx-",
            "206":"1H2rjDhHjoIHkwf4bgUTQKBMY1fxdcilq",
            "207":"1_biRXOfme2LfuIwPJUz4USfBhQbsW11t",
            "208":"1HAFXDbpGB2hGLweSWasMb11RznFOz7jX",
            "209":"1drOrF4McVQizmp5rXoby5NkPqGI6nje_",
            "210":"1oaEHPXcrBImB_dlCysEKrkOqXY-eBmOc",
            "211":"1VIpAA5I3UKDbxghrIL2KiCTsP-i2XNOQ",
            "212":"1xNUrIuhMYOm7CQQfSPOkSV25ZoiZMGWs",
            "213":"1Q-RWhfwoIRVxOiGCH14JCnSFcTCXwmJC",
            "214":"1vyvUSq4kGXMfNzbqBiP00-HWKowIVLnQ",
            "215":"1Yd4kjq6l6gIMxW35sCozaFaRZ4maay_L",
            "216":"1Ekfd8aXPLnMcunvYK3Y8eEm2KhZrlYC8",
            "217":"10_pQcLg_Ax5BcdpF29NMrN80VPiCGed8",
            "218":"1B11ax0pfNZwD85iDyE1KBmmnyatP8el8",
            "219":"1AbIdSB65Vq_PalYrtnKbz9Ty7yT-pf8e",
            "220":"1AL2tfW4p5Zkrgbw6OcnGoTtAIh59i0ip",
            "221":"11akGtlmUYfRk1UCJyIPC4iyBfSbXmPlQ",
            "222":"1Tb4q0cZSXKbZkeDksYrql4YnQkJhd6aZ",
            "223":"1uc7kjvS7Z-dcU2g04mM-qbfZQjrjEMuO",
            "224":"1XZwoFukXIiF8WCKHhONFxyd_drb_YXkW",
            "225":"1y4hQ-zStMOEEsrtHkMeBGMx-STlURN_L",
            "226":"1Sv-6LywXO3-Y0KDo9DX0roO6AsaYgVB-",
            "227":"1siz7Enr6vm5iasgW6Y9iZ9SmviextO0p",
            "228":"1uD6QsZmzNS8GxdmRWxEfgPZHQxFzn-Fo",
            "229":"1tp8Pie_0qnFuRI4Ow9y54Nv6hy2WvBTC",
            "230":"1zhhZ4Ef3-sbdnVvtBvLkxxRn6ewjlWiX",
            "231":"12HXj92OWkJVtXQHWigd-_vW-t6wZ3_KT",
            "232":"1InBBPZ2mvAuWDLBuTamcbkZjjc9VNAk4",
            "233":"1zec49UNe-6fQcimU7BAVkoxnJSwtTVJO",
            "234":"11m-MbR2j4lKM7ZOSB2Jc-aNVdYENWRf3",
            "235":"1VRqm5RPqaBIviWs1daVIAtdho-smOBi3",
            "236":"1jp8_-dvpkCT7UTDkGFYFdmFAJ6kErZVY",
            "237":"1xmVShCOCFu3soeR_IAjsas5TpDwr2Ja8",
            "238":"1FfXFQfmS0Dwot1mePRbUUQuQGGqUELTA",
            "239":"1RGOD6uPBUuUzffhOsl5tX5y8EDzNylT5",
            "240":"1m5ds_JVI6NtPPRLSDscE59wBS7tpZwh-",
            "241":"1w4e8CfjuQaI2ivV0CENGZA76H204aVEw",
            "242":"1Hzdc3tAcJVG3CPiVY2khKuWB4WOqJ2SS",
            "243":"1SVTCZmr0gIfwOkmhLfiazV9SjxVdgaSa",
            "244":"1TP5cl6oz-guL1YVUYS_X_HI8I7INNWXv",
            "245":"1pW2oAym0TN1l3WeSNC5rNTAujS0_K5nZ",
            "246":"1lQPvDZDtjpHNRfeI7UXm5mKGXo-ROqt5",
            "247":"10S9iFJv9BduqZI1v8f-0wIjrmshIp7VQ",
            "248":"1GUmoCmGELgYWb99GOuf5F02JYgu_Sx9R",
            "249":"1YCilg_a6DdLub0yOPmHX72KMAXh-JbjK",
            "250":"1z3jHHraVtKwW9V-2qw7gDSPGEvBdIkBT",
            "251":"1_6DDWUaBQE3CXeGkf-Zk6v_uyyS13CSt",
            "252":"1qWJIlDiqyZUi5TYmm2E8q7D7A1r50ygH",
            "253":"1wrx_RXBMesjFppjIWQHc7cddAE_kOYyA",
            "254":"1Jddu0PdTLMgm7MOIStAcMVqPNm9LGzps",
            "255":"1ZmHaVZJwr_qa2BbkNTcQF-Br_HeoLFPy",
            "256":"1ppwC5PJfAts5xfnIiQRDtG41t89W2oqy",
            "257":"1vQqvr-oVoO1rg7xi_FB-ya0huNexrZLg",
            "258":"1KFEaS_DfHAjiJzfDXDADPAxxBC4PSI7l",
            "259":"19hbsbNaZPcs4bOxrz-nhYtFKmxTs4f0C",
            "260":"1NaRYwDdWgTVcvoB5HXU1-5uqtaIs87XT",
            "261":"1x2pMGiYqI8vKzevskqsM8TedOpqIaRkg",
            "262":"1Vnl5WYt6ZLzaQxAj6Yt8Nb21ITM7Uyh6",
            "263":"1Xfd3KgX_25d4DeCpCvgA6dOLSPYHJGmA",
            "264":"12w4J-Cf56vJgnjZ1tqn7gTvo922OkPAd",
            "265":"1ftZCDLLdb--c7JL3fzXVDfXuV_GIFkOM",
            "266":"1yaXUygAW38WZhrYY9mhw6lW8TPBCecJB",
            "267":"1DD9PWBDlcDsnrQZDnXWeKXsW579I8JVu",
            "268":"1fj6spizsCUI-fLQmzdjNpSL6UFQI9EVE",
            "269":"1JX1VvhAojLdj03rwtRRLn-3hYfZOBWmC",
            "270":"1GwZaG3vFixSl8FE23Xca_Jz3-vgh7dMO",
            "271":"1SsHU5cgbu2qgWb3XL8EyVOGnN85FS17A",
            "272":"18dFelfp-5oYxsi67Q2YV4283_3bgGbok",
            "273":"1mmrj8_Nb39NwBt641BKFGtpvVudOZnER",
            "274":"1jAV_hvwF5XxxBGo_YtXOlxKrGKCTCy5C",
            "275":"1yn14ldzPfHcggmJ1gVjthrIyjRdM09kZ",
            "276":"10693S-dOggV08Lsaolt88hF67qFJLHaP",
            "277":"1A-9-Ccu6C1fV5UHuk6CYxB2S6uChVOYW",
            "278":"1fhDI88eUsqt47yYKDjsJdJlQXnFYC5zn",
		
		},
		
		TGI_BELILAS: {
		    "1":"1xnlvazdyma3opWe94VHisDdbbOnFnbXI",
            "2":"1y6oN_jDzGUQFJTWkRcSk0fG_N0umzDL8",
            "3":"1Rlo9PBMRiIgV3MbClkxeR9-4sgrtzjfu",
            "4":"1tSanzcuWCMdd_5g1whE_U8Pt2zSiynTI",
            "5":"17mgQLkWc9IVFfIOzYiAkzUXYlObPhbh9",
            "6":"1aT2k1z1VA9eSoGiWtKMkYT6P9WErYwi3",
            "7":"1VMtzG0tX0LKOvFsPhgIBNj_bGlANELKQ",
            "8":"1flB1brt7U8fqsHNlSnuI52yj6hX0_xCC",
            "9":"1nt9foX0UxTxFPGxuGDbrFEHbkqK0dJuP",
            "10":"1zqDhq3wc9gv3pmbuDdiKsUkwRd0ITw_p",
            "11":"1P8br5D7AtqunNGfEt-n3Uru_7o9pxdSU",
            "12":"1xykFzeNtY59uz0ooN_rRdk6PfxL1ghTs",
            "13":"17nWUHhvkaSdwLHSQ7FHH0gqMAkswaTeQ",
            "14":"1hAXrzReXRYOZYXbrHYbyJ-bmd3QwR5us",
            "15":"19ctnrMBYTNArbtdbQf0A8AAHaCmPt935",
            "16":"105_PrPnWKMeVm1cqwCo3xwKC5Gi9AFqq",
            "17":"16NpsNisuz2QoSmyHQsArZrelYX798LgO",
            "18":"11hH7X7sJpCxbwFzfIkqTFaqB2D21fPvd",
            "19":"1tjVuWevoB2tlyi1zW052849_eeHXKjQl",
            "20":"1AyiWu8RYo01oZhuzsJVApux-_qxunwSO",
            "21":"1B7QtiWT-1KOe92NVqZG6nDXqCH_9JFov",
            "22":"1gHktnXxhH9fdvr18C6oo6AkSw43QnDyn",
            "23":"1m6756G9m7LJwOP2zSPIEx9LSHETXCiU6",
            "24":"1fUqof_S9OqPUH07Oc9O4pwWHeFe5LnG4",
            "25":"1ljedxKoolpkYLgylOEd6E8ORL3LzvrYI",
            "26":"1-jk7-8Xm8T8_O2ydon2Ap7OZ-KwxoSvr",
            "27":"1792QLhj3qr-pgGVFS9ynH2AuV3UuzVpL",
            "28":"1N21vuNAcyu3tRZevFAhi_bo-gDaRqYVD",
            "29":"12lI_3YvNocAdlkxEY64ks7yReeHaMsi-",
            "30":"17Ql1pdR4b2_5zCh8ONvOmMuZFysukMHA",
            "31":"1ULoFIx--Y0rEp3Ym3OqeC4WT9kT9Tkox",
            "32":"1kUaYyYEgMUOeR3IMb8Oce7nLccS8qlef",
            "33":"1EX7a2nrrJHR79-02ZD8LJ_GKBlZZX0Pa",
            "34":"1Rzu8gtUPRyeEjkiTS8G9doMfpUYD1WYR",
            "35":"1LztQ_TcidxjUjUcWqJnpCBFKiqgJVuZY",
            "36":"1bWci2H8otMQE5B_iVBJB7gMX7GhnhyCt",
            "37":"1i50JqJh9qTCXDckLrqgYGm8WNiq1qxy-",
            "38":"1wJXOw-IYyJKShGyNygC-1e1giZNxt8NZ",
            "39":"19_MZuCDVmj_Q7sB-lxbb5f16Jr7W63jH",
            "40":"1vs4cmpr1OXU8Bq6wUi85TkG5FeuyETxO",
            "41":"14hqCmazwsZULbo8ZqMjxvWgxY9FLlPUU",
            "42":"1aknG6VQm8KWlCSAR5vqmS6FMHtTeHcmb",
            "43":"16E8_UV2ucC7sd90PzXddt6aiqP0F0qWf",
            "44":"1KNY4HepiiF53WNXoh6cWSGf7KAtOYKGp",
            "45":"1i3KWS6HHRkv232V69cLyQUgv2h0qebwv",
            "46":"1GEeWaJFeNxd1DVei9A6EX9m34R04Eis6",
            "47":"1_IqpxBdLqjM4zkV1_VAyqwF36hEOH4qY",
            "48":"1fJH8GTOa30kOPEh22aJ-ep4R5CJNjiUI",
            "49":"1yeJHbOqnpAp4r_U_MHETEk6Rj7Ax3zX5",
            "50":"1u2wDdMyvmoALGMNULwen3qGdanllR7L2",
            "51":"1SzlxLt1ohx84YxRUEaWruDN4uZjY1ebO",
            "52":"1wvxdEp3fzkU8UhBKOwcZOehVPr188O1k",
            "53":"1BbfLq6hQ6HkOPSwahl9l0poNQ8BFyUtI",
            "54":"1Eyaib5247FQltJwTzLW46vwn95gRynfS",
            "55":"1ZULszK5pu_-lNoyISPOVKZsWMFYCzPBX",
            "56":"1HyqxgNAqVdEosfi3EEOmmwUaGBAcNK0h",
            "57":"1pdsFi0KV3teXSd5nWdKjL6hTe3pFGhXE",
            "58":"1jNFKFFwehaxj90dphaqGNjAqQBx1A2cw",
            "59":"1obB48QyOjxGfFTQtZ2ulPdfVB8Lx009K",
            "60":"1nZuPYGSVl3YYZKkO1HoPbKGNldQHOz5E",
            "61":"1vsd2PZPlZyExpXo9hV6gmZxiBszKhfGQ",
            "62":"1AqliZPZlqyD5Wk_--TwyvOmKP4Bip63F",
            "63":"1NfjtIn_1aexq5awmNbOjsx290lWT7jrt",
            "64":"1felwJ7G-NBNwo086VvWl9eYuKFxIRZ5e",
            "65":"1XlYWS0VN-f6X6VtM3D1rvNRUMk5ZyqoM",
            "66":"1bF8ktPjtVbxB2dX-p8YtWo8qBSjp4L1S",
            "67":"1k1w1jndW8_nHO0BbWb2c2R9FLuZ7htqv",
            "68":"1HwXGsCJ1gOC4O7fbndI32acMBlDQDMcS",
            "69":"16enAHyySATi2ixHOCVGkGWvJbmsDagSe",
            "70":"1mgicgZZnrCFHog7bnK-0F7PY3JIs7nW9",
            "71":"1S5GyRjtReiv4jTOQerNDF6tdftOE3guI",
            "72":"1ozXLQ2mFUlwpk46J3n0YL6V9tbNuPaf5",
            "73":"1VkSHJQyFOxl7ts2jNJ9V1_kal8gaJD_B",
            "74":"1IilHlW4TcbpIdu97qMK-N70PZTeRLKMq",
            "75":"1GW9x8gaT2qGro_CBJ5ohW_k4W8LbvllK",
            "76":"1jTSNj4uY49VkqDj_CWYp-icDijclOgsd",
            "77":"1X3QOLTVJUhq8MTx05pRKvs9ekAqYVwce",
            "78":"1fh_TyE0K22895pcMuenPngcTYF1gvEFQ",
            "79":"1PuhoPh1OqFmHrGJplJJ7gczXrpWIwB_3",
            "80":"1OySKXL8av1MEHMXivaEdqoQw4piBW5sl",
            "81":"1aMclUHh30OB_FAcNR5qtSLeKDTCxAb1s",
            "82":"1mbif1pLBkUw1wi72m_VtXNhLIrzA2yoN",
            "83":"1FgJh-bKUTpWluPP8rd0xokol7CDkFSg0",
            "84":"155lZH609B7mQx8qOJ1DEwhi2IUvePRkW",
            "85":"1V3-y7-_Xl_elNq9iBBq0DHfzhXDS7d70",
            "86":"1XPM_3ZenjyY8pVyKQySGBOCt75FMl6ZI",
            "87":"15gVNasehjRqmUDSAH_WPSolWzoNT9uN1",
            "88":"18tZs-tdK_ad7GOCJDgWUqJHa_O2UcWqP",
            "89":"11N6L12W5wzr8liMfojXNNhRUcV8LdljO",
            "90":"1GG3CFTuhGgBPkzq8vuDlPJpS6Szj2hDX",
            "91":"1EL1d8yFfN4fUh-e0XidHwJVzlEqEiBam",
            "92":"1LwSLMeWfc9ZV9JDPyj0kiv1J2g0_b7Q8",
            "93":"1C7OxJ3l7BJo6ANeeJCC5l7zXFrkFn122",
            "94":"1HmyHOBU6c1UmdKdswUmHv4SrOqF3tatp",
            "95":"1wbHPPnT_k5F38KpHKDbXw_N_HoLvSwe3",
            "96":"187D0Q0G5mT-HMzCh37bGxnQ0CXOgQOwG",
            "97":"1Y-iiCet7NWnUWVwS2WCPhdL19KRdV2Mn",
            "98":"1KrVIWfx13zqq7TNw6oFT0kWORB81jMMZ",
            "99":"1giYT7APbaSqP22oL78Fs372z0w8SI3rE",
            "100":"1rkIXCNm55uudArt3Hin5FOT5y8L81dhZ",
            "101":"1eJ5ctwNVIkZ9lxg77yA1Rn1oVSplCUZh",
            "102":"1wrlffec3jVe4fLd2P860H8ZsG9BcQkQt",
            "103":"1e1JWDGQlPpH98NOFlTD1AzYeaYW8yYkn",
            "104":"1GOsbg2qDGg1lKudhYd3pS6yOetCtaWkw",
            "105":"1jcKvbo6UFvm4abtbGRk-zhJRud5YYU_o",
            "106":"1MmBAkGMaWPOcF5sh_iiqd17b9euzuT0h",
            "107":"1J7k4JWMwdVE-g2l_YUAvnF1AowRIz73I",
            "108":"1_FclsMys9ss3b9odmcja5paIiXOnwDWM",
            "109":"11n0Z_Vzja0m__7vKqIPcbI3i6qdua1qY",
            "110":"1RvxC_LaeaT_teY7W6VpgxCCz7taZLxlR",
            "111":"1bYX4QkzwszC--E_XtbhRLknT3IyPLkH4",
            "112":"1z61arEd6qA452NZKxYvZldVnZTcAuDOw",
            "113":"1LWNLWacvWmJzpMZg-s-rwnkt538p_DF2",
            "114":"1mkMRcT4oENAUn5CrToQQHwSSeIeyKeJC",
            "115":"122qI7L4QWCczXJQAZzte3jgwYDzEj40j",
            "116":"1X3Glk9m0b-uTIalY9QGyxGkHXdoZELg0",
            "117":"191lHzs5icpXPGOPst0bJ5qaj0uUM7QgY",
            "118":"1fgaVYfWTGcwAEUtXDKWe0Z0dU-XtGYXV",
            "119":"1sobNuIkfZoQVsLeg5IYLJ5-g5R8aig_9",
            "120":"17w9INY2OWEhH-KPbIhWwlUefvurfM3jH",
            "121":"1l6qX1Sa4SPQOtpbp5Iu8hxY129iLE4XA",
            "122":"1fujh7Yebd35s8w7sMd0A9xtY-eONufjZ",
            "123":"1tkebzphEwN4A4k_F1kQOhtqVVoYheHxS",
            "124":"1Y1jQbMZDVkh6TZPSXCScfY59pAvz89MU",
            "125":"1YF7ri3ywqtSwWsNZzgsv2Gw2J1-Rm5ld",
            "126":"1Fn_VZVL3csDMAh1FP7RR0stv60_zPpLm",
            "127":"1jHOQpGlFbor8HAb8oBMwS24JtVkeH_9d",
            "128":"1B_Bd2br50rMezxdABUEPNRsPPBr3WUIw",
            "129":"1VqUoPSnWHCF_Jjy0q4i4lxZEna-MKmJF",
            "130":"18U1uwUQV5MRipCDW5r6f6RRR8ND2lmuv",
            "131":"1idPfjUXqOwNgXEJrI8OIguij5NwMa5_K",
            "132":"1GK3JxegtkRk5Nle0F-cbuOdXyX9QzLKW",
            "133":"1uGitWh11u_nNrblTDVAs8Q3FfKcFKo5h",
            "134":"1zmgc0K_DVQ_mL-qezdx77jyv-_zWyFrK",
            "135":"10_pVS72EvsEpX7RvnQlX0FtkaLYDEB9i",
            "136":"11e6OharV8X3dh4FRvUZKWnmafegXHGJW",
            "137":"1TyUTDDDRUBkVNm_UOMeTigYbPq_hnuWx",
            "138":"1_L9cV6HJ40puExdNtZP9fb2HF6q5PKnL",
            "139":"189jM204gMUk4G58U8ZWdL6skWaqGdlAd",
            "140":"1ERE2XJ1pu4K2thzz4CtbTBSdPh4xIc_L",
            "141":"1ezUFFzVv1QVf8cepYh73oQziOZxQe4lX",
            "142":"1_qQJKg2oh_cympgz1b-CPr99oWza6JX0",
            "143":"1KeMQxOHz3gOS7X_j7CUlLUP7Gz3sUXA-",
            "144":"1yvDZznKSPH5YTtMHu6YjLnthMB7nH8cg",
            "145":"1XM4PT51q6tNpMJpXoPjLbPYzef86qISz",
            "146":"1Q6r4eWkT0hThS5Xak3rSQ_VAMRmNgLAe",
            "147":"1ztI3-33I33E09uSz8xzCjhOTNzNs3TaK",
            "148":"1MCZPV_o8IF2jqJa75QdkYnU0d4dZ6jzU",
            "149":"1Q6QfDr_gd_NF4Vpg3mwSCHtlYFbkZDXj",
            "150":"1-_PZDBCRSwNoGPa9pHMzZ-RYIK0hTONm",
            "151":"1Dv71PazaF6oUpX6x1hwhM2zIdmx1yT4H",
            "152":"1ERvk1elE8ZOQHu3qqI2Lwqu9sw2JQAR9",
            "153":"19dXAW28uNcYx7K5M7FPvLKq3vPqadrWH",
            "154":"1-JJGq3RoX622qKJz-LW_ZFAIY1fYRv18",
            "155":"1Ykr1zhYjlWp43xkZHXZYF8s7YI93Yl1E",
            "156":"1SG3Iw6mq-mYMxtPDMJNl3IkojOMRm7hs",
            "157":"1swpg7cny_AlF2G4hTk6WHoTDpi50Brls",
            "158":"1C_zwqZ2cHdpYv_K8LPGF7NMV2eUStyNr",
            "159":"1XKXKiU0iIL5no0VIlmjDUcHYPxfPED4H",
            "160":"1q5UvaBHgxrD5Ix-uhljvkyNxCvqQ_Ow_",
            "161":"1d34OB68pgg6OWTEOqKJtFZ7Vnb3PfjQD",
            "162":"1AUZLnRuiMUropD75HPw861gSlvXPBZp-",
            "163":"1JBXjg7WXKp_aZaWHdxIiRQf29GaU3dvF",
            "164":"1j5_zXYfWDNA4EaXkr7dWyX04vjD4o517",
            "165":"1KUA7pcIjkpcIDdsNcpZED8gdkX8nhO7J",
            "166":"1nDlKEh9uAJhAldSTaYzXpo3aGGByF347",
            "167":"1-fzuEQww81bkU6afA_tIewCUagD35HZt",
            "168":"1qjSJYt2yiofPzRlKIQdQsfN0q0l4GKOS",
            "169":"1Qz60cDUcUPexUdp9xzGIlNbxpwf879Mu",
            "170":"19rqnie24MgdC7QIsedau4ZM5q37cCV5v",
            "171":"1dIVcyn83kWkpr5wyRfPh3u8XBJRnG-ik",
            "172":"1C3CePDK-5j6X0MG7EH_3tSlm8Jh4_6Ya",
            "173":"11_OzXxtVipNS7KgOoRNQQj4Uv2AYCKau",
            "174":"1ng2XXsP6sS_-4uSxgp6OjuFwBohFTosy",
            "175":"1OPW9aZfCeJLwtMCWaw4Pit5fWOqOUnRj",
            "176":"1rYVAcOLEeRSqBm1TKLGxmd5Iy9h1WmEe",
            "177":"1C_XxvGMoq8UPtlbh0TwHEyNAZ8tVsRsD",
            "178":"1q_cZA-NzAhfwVLA2kVmF446Adw1G1eZg",
            "179":"1ilzWoag7IFHHgOEy8h7wMXwcjakwFAFa",
            "180":"10KIFzD7oSJ4Zso6kD2zephwTv7xQ1Hzk",
            "181":"1XKPNqgNc8KlD-eNpnjjqlbbgPxLTYuMY",
            "182":"1BaP1IHfahwMlyEdh5KKJSuQFXklcwhD_",
            "183":"1aFIotb5L8jrLSLDgkWOma47hYYnu4AQu",
            "184":"1ZF6enr6_80iYbSBOjWWEhCWCjYTLF4r_",
            "185":"1g3okRrJWuc0c4zMqLk2cILiPZV2RaBC3",
            "186":"1EeAnNqAReHIvYZyNkwUiRuf2kq2TrsUk",
            "187":"1K03i0kWS2WfpyZg2WBse_HcHiu-TiPtu",
            "188":"1STiKjbIxGkSUxGcnrS_dSAS-h02ZK9cj",
            "189":"1BdFXX8iF5O5tKz0PuXP7MRqpHV0igwK-",
            "190":"1ETw-2gQot2ETBWgPK3cbf6jyEFxoiYKB",
            "191":"1Wo_pdMAFlewJM8d6JZSoiYgp7XUpzkbu",
            "192":"1WrPGwvJZjh2q6C9AbLQpc4mN-NGHRj3V",
            "193":"1SDgYb-rXhWEPx75I6WFqTDNwHa3UhLnB",
            "194":"1u9UMaoc3Jd77Da2o-G5SHIiRGRTx2DL6",
            "195":"1E7pNTvOGH7KJvrxM041FHD4xnMu6PQZd",
            "196":"12pWtCQv7dsqCzzZccRYzl86UQ3dkE_1h",
            "197":"1UwiHsubK7y2pfRWzZP2GWOHOGKFYFuxg",
            "198":"1Q-mlXxQkaqwfHTXrmLmp06BR_0O3Rro8",
            "199":"1eM1rOjRYv6WO9i6LyiZu08XUqKGbOhOv",
            "200":"19HZQR6HVHV-fQl5kDjh16gNZGlwwWVTX",
            "201":"1IqfhVIlDhRUQTVp1aBZHLS4Wxfahs360",
            "202":"1G65m7z4mEQ3soFh-NWfA0CH47xmreAIv",
            "203":"1K65aqEYO-Zqt86BdJB3Wsp3iuPA0is4X",
            "204":"1rPJ8O6CXD2erxEcQ_6UrHnS0X0-kQEfQ",
            "205":"1ZZH0cutMUs5ybWRqMK0Mx441vDBW2HId",
		},
		
    };
   
   const photostockData = [
  { id: 'TGI_GRISSIK', label: 'GRISSIK' },
  { id: 'TGI_Tempino_metering', label: 'TEMPINO METERING' },
  { id: 'TGI_Tempino_regulating', label: 'TEMPINO REGULATING' },
  { id: 'TGI_Sakernan_GBS', label: 'SAKERNAN GBS' },
  { id: 'TGI_Sakernan', label: 'SAKERNAN' },
  { id: 'TGI_SAM_Simpang_Abadi_Metering', label: 'SAM - SIMPANG ABADI METRING' },
  { id: 'TGI_JABUNG_GBS', label: 'JABUNG GBS' },
  { id: 'TGI_JABUNG', label: 'JABUNG' },
  { id: 'TGI_BELILAS_GBS', label: 'BELILAS GBS' },
  { id: 'TGI_BELILAS', label: 'BELILAS' },
  { id: 'TGI_SV1306_Pangkalan_kerinci', label: 'SV1306 PANGKALAN KERINCI' },
  { id: 'TGI_DURI_1', label: 'DURI 1' },
  { id: 'TGI_DURI_2', label: 'DURI 2' },
  { id: 'TGI_PANARAN', label: 'PANARAN' },
  { id: 'TGI_PEMPING', label: 'PEMPING' },
];

   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src="https://drive.google.com/thumbnail?id=1_zjS0qPhgXO7lNKz5Xnwc6H7UN1CONtW&sz=w1000" 
						alt="Home Alive Photostock" 
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		  ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_tgi;
