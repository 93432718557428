import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'chosen-js/chosen.css';
import $ from 'jquery';

export const subsidiaryOptions = [
  { value: 'PGN', label: '1. Perusahaan Gas Negara' },
  { value: 'PTPertaminaGas', label: '2. Pertamina Gas' },
  { value: 'PTNusantaraRegas', label: '3. Nusantara Regas' },
  { value: 'PLI', label: '4. PLI' },
  { value: 'PTTransportasiGasIndonesia', label: '5. Transportasi Gas Indonesia' },
  { value: 'PTKalimantanJawaGas', label: '6. Kalimantan Jawa Gas' },
  { value: 'PTPertaSamtanGas', label: '7. Perta Samtan Gas' },
  { value: 'PTPGNLNGIndonesia', label: '8. PGN LNG Indonesia' },
  { value: 'PTWidarMandripaNusantara', label: '9. Widar Mandripa Nusantara' },
  { value: 'PTSakaEnergiIndonesia', label: '10. Saka Energi Indonesia' },
  { value: 'GAGAS', label: '11. Gagas Energi Indonesia' },
  { value: 'PTPertagasNiaga', label: '12. Pertagas Niaga' },
  { value: 'PTPertaArunGas', label: '13. Perta Arun Gas' },
  { value: 'PTPertaDayaGas', label: '14. Perta Daya Gas' },
  { value: 'PGNSSWJ', label: '15. PGN-SSWJ' },
  { value: 'PGNSOR1', label: '16. PGN-SOR1' },
  { value: 'PGNSOR2', label: '17. PGN-SOR2' },
  { value: 'PGNSOR3', label: '18. PGN-SOR3' },
];

const Rimoxfunc = () => {
  const [subsidiary, setSubsidiary] = useState(null);
  const [stasiunData, setStasiunData] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [stasiunDisabled, setStasiunDisabled] = useState(true);
  const [selectedStasiun, setSelectedStasiun] = useState(null);
  const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
  
	const updatematrix = (stasiun) => {
    if (!stasiun) {
        console.error('Stasiun is not selected.');
        return;
    }

    setLoading(true);
	
	const rimox_prm = document.getElementById('prm_rimox').innerHTML;
	const prm_entitas = document.getElementById('prm_entitas').innerHTML;
	if(rimox_prm == "rbi"){
		
	}else if(rimox_prm == "fmeca"){
		
	}

    fetch(`https://script.google.com/macros/s/AKfycbzAom_qz_T_3beNWLXpxtbVXQLS2EddWDbLeHrx9pNyc7-liXJlpQYf4ozuvVChcvj7/exec?sn=${rimox_prm.toUpperCase()}${prm_entitas}&rj=yes&stasiun=${stasiun}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            setData(data[0]);

            const elementIds = ["5A", "5B", "5C", "5D", "5E", "4A", "4B", "4C", "4D", "4E", "3A", "3B", "3C", "3D", "3E", "2A", "2B", "2C", "2D", "2E", "1A", "1B", "1C", "1D", "1E"];

            let samping1 = 0;
            let samping2 = 0;
            let samping3 = 0;
            let samping4 = 0;
            let samping5 = 0;

            elementIds.forEach((id) => {
                const element = document.getElementById(id);
                if (element) {
                    const cekdata = parseFloat(data[0][id]);
                    if (cekdata > 0) {
                        element.innerHTML = cekdata;
                    } else {
                        element.innerHTML = "";
                    }

                    if (["5E", "5D", "5C", "4E", "4D", "3E"].includes(id)) {
                        samping1 += cekdata;
                    } else if (["5B", "4C", "3D", "2E"].includes(id)) {
                        samping2 += cekdata;
                    } else if (["5A", "4B", "3B", "3C", "2C", "2D", "1E"].includes(id)) {
                        samping3 += cekdata;
                    } else if (["2B", "1D"].includes(id)) {
                        samping4 += cekdata;
                    } else if (["3A", "2A", "1A", "1B", "1C"].includes(id)) {
                        samping5 += cekdata;
                    }
                }
            });

            if (document.getElementById("samping1")) {
                document.getElementById("samping1").innerHTML = samping1 > 0 ? samping1 : " ";
            }
            if (document.getElementById("samping2")) {
                document.getElementById("samping2").innerHTML = samping2 > 0 ? samping2 : " ";
            }
            if (document.getElementById("samping3")) {
                document.getElementById("samping3").innerHTML = samping3 > 0 ? samping3 : " ";
            }
            if (document.getElementById("samping4")) {
                document.getElementById("samping4").innerHTML = samping4 > 0 ? samping4 : " ";
            }
            if (document.getElementById("samping5")) {
                document.getElementById("samping5").innerHTML = samping5 > 0 ? samping5 : " ";
            }

            setLoading(false);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            setError(error);
            setLoading(false);
        });
		
		var xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function() {
		  if (xhr.readyState === 4 && xhr.status === 200) {
			var jsonData = JSON.parse(xhr.responseText);
			var tbody = document.querySelector('#tbl_detail tbody');
			tbody.innerHTML = '<div></div>';

			for (var i = 0; i < jsonData.length; i++) {
			  var row = tbody.insertRow();
			  var cellNo = row.insertCell(0);
			  var cellTagNumber = row.insertCell(1);
			  var cellEquipmentName = row.insertCell(2);
			  var cellRiskCategory = row.insertCell(3);
			  var cellRiskLevel = row.insertCell(4);
			  var cellRemainingLife = row.insertCell(5);
			  var cellNextInspection = row.insertCell(6);

			  cellNo.innerHTML = i + 1;
			  cellTagNumber.innerHTML = jsonData[i].tag_number;
			  cellEquipmentName.innerHTML = jsonData[i].equipment_name;
			  
			  cellRiskCategory.innerHTML = jsonData[i].risk_category;
			  cellRiskLevel.innerHTML = jsonData[i].risk_level;
			  cellRemainingLife.innerHTML = jsonData[i].remaining_life;
			  cellNextInspection.innerHTML = jsonData[i].next_inspection;
			}
		  }
		};
		xhr.open('GET', `https://script.google.com/macros/s/AKfycbzAom_qz_T_3beNWLXpxtbVXQLS2EddWDbLeHrx9pNyc7-liXJlpQYf4ozuvVChcvj7/exec?stasiun=${stasiun}&sn=${rimox_prm.toUpperCase()}${prm_entitas}`, true);
		xhr.send();
		
};


  const handleSubsidiaryChange = async (selectedOption) => {
    const selectedSubsidiary = selectedOption ? selectedOption.value : null;
    setSubsidiary(selectedSubsidiary);

    if (selectedSubsidiary) {
	  const rimox_prm = document.getElementById('prm_rimox').innerHTML;
	  if(selectedSubsidiary == "PGN" || selectedSubsidiary == "PGNSSWJ" || selectedSubsidiary == "PGNSOR1" || selectedSubsidiary == "PGNSOR2" || selectedSubsidiary == "PGNSOR3"){
		document.getElementById('prm_entitas').innerHTML="";
	  }else if(selectedSubsidiary == "PTTransportasiGasIndonesia"){
		document.getElementById('prm_entitas').innerHTML="_"+selectedSubsidiary;
	  }else{
		  document.getElementById('prm_entitas').innerHTML="test";
	  }
      setStasiunDisabled(true); // Set disabled to true while loading data
      const url = `https://script.google.com/macros/s/AKfycbzAom_qz_T_3beNWLXpxtbVXQLS2EddWDbLeHrx9pNyc7-liXJlpQYf4ozuvVChcvj7/exec?subsidiary=${selectedSubsidiary}&sn=link_rbi&dtp=${rimox_prm}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStasiunData(data);
        setStasiunDisabled(false); // Enable dropdown after data is loaded

        // Automatically select the first stasiun option
        if (data.length > 0) {
          const firstStasiun = {
            value: JSON.stringify([data[0].link, data[0].link2, data[0].link3]),
            label: data[0].stasiun
          };
		  
		  
		  document.getElementById('judul_stasiun_matrix').innerHTML = firstStasiun.label;
		  document.getElementById('valcad').innerHTML=data[0].link2;
		  
          setSelectedStasiun(firstStasiun);
          setSelectedLinks([data[0].link, data[0].link2, data[0].link3]);
		  
		  updatematrix(firstStasiun.label);
		  
		  if(data[0].link2){
			document.getElementById('iframecad2').style.display="block";
			document.getElementById('imgkosong2').style.display="none";
			document.getElementById('iframecad2').src = data[0].link2;
			document.getElementById('iframecad3').src = data[0].link2;
		  }else{
			  document.getElementById('iframecad2').style.display="none";
			  document.getElementById('imgkosong2').style.display="block";
		  }
		  
        } else {
          setSelectedStasiun(null);
        }
      } catch (error) {
        console.error('Error:', error);
        setStasiunDisabled(true); // Keep it disabled if there's an error
      }
    } else {
      setStasiunData([]);
      setStasiunDisabled(true); // Disable dropdown if no subsidiary is selected
    }
  };

  const handleStasiunChange = (selectedOption) => {
    const links = selectedOption ? JSON.parse(selectedOption.value) : [];
    //setSelectedLinks(links);
	setSelectedStasiun(selectedOption);
	document.getElementById('iframecad2').src = links[1];
	document.getElementById('iframecad3').src = links[1];
	document.getElementById('valcad').innerHTML=links[1];
	document.getElementById('judul_stasiun_matrix').innerHTML = selectedOption.label;
	updatematrix(selectedOption.label);
	
  };
  
  const stasiunOptions = stasiunData.map(stasiunObj => {
    const linkArray = [stasiunObj.link, stasiunObj.link2, stasiunObj.link3];
    return {
      value: JSON.stringify(linkArray),
      label: stasiunObj.stasiun
    };
  });

  return {
    data,
    setData,
    setLoading,
    setError,
    loading,
    error,
    subsidiaryOptions,
    handleSubsidiaryChange,
    handleStasiunChange,
    stasiunOptions,
    stasiunDisabled,
    selectedStasiun,
	updatematrix,
	setSubsidiary,
	subsidiary
  };
};

export default Rimoxfunc;
