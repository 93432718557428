import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logout from './Logout';

const DataTable = () => {
  // Fungsi untuk mendapatkan tanggal saat ini dalam format yyyy-mm-dd
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit

    return `${year}-${month}-${day}`;
  };

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5); // Jumlah item per halaman
  const [selectedDate, setSelectedDate] = useState(getCurrentDateTime()); // Menyimpan tanggal yang dipilih

  useEffect(() => {
    fetchData();
  }, [selectedDate]); // Fetch data setiap kali selectedDate berubah

  const fetchData = async () => {
    const token = localStorage.getItem('token');
	
	try {
	  const response = await axios.post(
		'https://alive.my.id/restapialive3/api/userscek',
		{}, // Body request jika diperlukan, kosongkan jika tidak
		{
		  headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		  },
		}
	  );

	  // Pastikan response.data yang digunakan sesuai dengan struktur response yang dikembalikan API
	  if (response.data !== 1 && response.data !== 28) {
		window.location.href = "/";
	  }
	} catch (error) {
	  window.location.href = "/";
	}


    try {
      const response = await axios.post(
        'https://alive.my.id/restapialive3/api/data_tampil',
        { tgl: selectedDate },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Gunakan token dari localStorage
            'Content-Type': 'application/json',
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Filter data berdasarkan search query
  const filteredData = data.filter(item =>
    item.user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentData = filteredData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Membuat array nomor halaman berdasarkan jumlah data yang difilter
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const styles = {
    navigation: {
      width: '190px',
      height: 'calc(100vh - 200px)',
      float: 'left',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)',
      marginRight: '10px',
      display: 'none'
    },
    formtambah: {
      width: '38%',
      height: 'calc(100vh - 200px)',
      padding: '10px',
      float: 'left',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)',
    },
    formedit: {
      width: '38%',
      height: 'calc(100vh - 200px)',
      padding: '10px',
      margin: '0 auto',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)',
    },
    list: {
      width: '60%',
      height: 'calc(100vh - 200px)',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginRight: '10px',
      padding: '10px',
      float: 'left',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)',
      scrollbarWidth: 'thin',
      scrollbarColor: '#888 #f5f5f5',
    }
  };

  const aliases = {
    tableClass: "table table-bordered",
    buttonPrimary: "btn btn-primary",
    buttonDanger: "btn btn-danger",
    editButtonWidth: { width: '60px', textAlign: 'center', padding: '0', margin: '5px' },
   
  };

  return (
    <div style={{ marginTop: '30px', padding: '10px' }}>
      <a href="./">
        <img
          src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
          alt="Alive Logo"
          style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }}
        />
      </a>

      <img
        style={{ float: 'right', marginTop: '10px', marginRight: '15px' }}
        src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`}
        width="200"
      />
      <div style={{ clear: 'both' }}></div>

      <h3 style={styles.judullayout} align="center">History Login</h3>

      <input
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        style={{ marginBottom: '10px', marginRight: '10px' }}
      />

      <input
        type="text"
        placeholder="Search by name or email..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: '10px', width: '250px' }}
      />

      <table className={aliases.tableClass}>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Entity</th>
            <th>Grand Access</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr key={item.id}>
              <td>{indexOfFirstUser + index + 1}</td>
              <td>{item.user.name}</td>
              <td>{item.user.email}</td>
              <td>{item.user.entitas}</td>
              <td>{item.user.hak_akses}</td>
              <td>{item.tgl}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
	  
	   <div style={{
        position: 'fixed', 
        bottom: '0', 
        width: '100%', 
        backgroundColor: '#ffff', 
        padding: '5px', 
        fontSize: '12px', 
        textAlign: 'center'
      }}>
        ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
        <div style={{
          position: 'absolute', 
          bottom: '5px', 
          right: '50px'
        }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
