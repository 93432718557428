import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Logout = () => {
  const navigate = useNavigate();
  const [tampilhistory, setTampilhistory] = useState(0);
  
  const token = localStorage.getItem('token');
  
  useEffect(() => {
	  
		const userscekalive = async () => {
    try {
      const response = await axios.post(
        'https://alive.my.id/restapialive3/api/userscek',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data === 1 || response.data === 28) {
        setTampilhistory(1);
      } else {
        setTampilhistory(0);
      }
    } catch (error) {
      setTampilhistory(0);
      console.error('Error checking user status:', error); // Logging error untuk debugging
    }
  };

  // Memanggil fungsi asinkron
  userscekalive();
		
	}, []);
  
  const handleSignOut = async () => {
	  
    try {
      // Ambil token sebelum dihapus
      const token = localStorage.getItem('token');

      // Jika token tidak ada, redirect langsung ke login
      if (!token) {
        navigate('/login');
        return;
      }

      // Logout dari Firebase terlebih dahulu
      await signOut(auth);
      console.log('User signed out from Firebase');

      // Jika ada endpoint REST API untuk logout, hapus token di server
      await axios.post('https://alive.my.id/restapialive3/api/logout', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('Token revoked on the server');

      // Setelah logout berhasil di server, hapus token dari localStorage
      localStorage.removeItem('token');

      // Redirect ke halaman login setelah logout
      navigate('/login');

    } catch (error) {
      console.error('Error during logout:', error);

      // Jika terjadi error, hapus token dari localStorage untuk memastikan user logout
      localStorage.removeItem('token');

      // Redirect ke halaman login meskipun terjadi error saat logout
      navigate('/login');
    }
  };
  
  const handleHistorylogin = async () => {
    
	navigate('/history_login');
	
  };
  
  const handleSettinguser = async () => {
    
	navigate('/createprofile');
	
  };
  
  const handleSettinghome = async () => {
    
	navigate('/');
	
  };
	
  const location = useLocation();

  return (
  <div>
	{(location.pathname !== '/history_login' && tampilhistory === 1) && (
        <i style={{ marginRight: '10px' }} onClick={handleHistorylogin} className="fa fa-history"></i>
      )}
	  
	  {location.pathname !== '/createprofile' && (
        <i style={{ marginRight: '10px' }} onClick={handleSettinguser} className="fa fa-user-circle"></i>
      )}
	  
	  {(location.pathname === '/createprofile' || location.pathname === '/history_login') && (
        <i style={{ marginRight: '10px' }} onClick={handleSettinghome} className="fa fa-home"></i>
      )}
    <i onClick={handleSignOut} className="fa fa-sign-out"></i>
    
</div>
  );
};

export default Logout;
