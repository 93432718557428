import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Nipoc = () => {
	const [isHovered, setIsHovered] = useState(false);
  
   const [userData, setUserData] = useState(null);
   const [token, setToken] = useState(null);
   const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);
   const [selectedVisualrov, setSelectedVisualrov] = useState(null);
   const [selectedCompany, setSelectedCompany] = useState('');
   const [selectedArea, setSelectedArea] = useState('');
   const [selectedJalur, setSelectedJalur] = useState('');
   const [areas, setAreas] = useState([]);
   const [jalur, setJalur] = useState([]);
   const [jalurData, setJalurData] = useState({});
   const [jalurDataheight, setJalurDataheight] = useState({});
   const navigate = useNavigate();
   
   const [scrollPosition, setScrollPosition] = useState(0); // Untuk menyimpan posisi scroll saat ini
  const scrollRef = useRef(null); // Membuat referensi untuk elemen scroll

  const handleScroll = () => {
    // Memperbarui posisi scroll dengan nilai scrollTop elemen
    setScrollPosition(scrollRef.current.scrollTop);
	//alert(`Posisi Scroll: ${scrollPosition} px`); 
  };
	
	const [hoveredItem, setHoveredItem] = useState(null);

	const handleMouseEnter = (item) => {
		
		setHoveredItem(item);
	};

	const handleMouseLeave = () => {
		
		setHoveredItem(null);
		  
	};
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	
	var ifr1 = {
		"ffs": {
        "preview": "https://integritycalculator.blogspot.com",
		},
		"pipetally": {
			"preview": "https://public.tableau.com/views/ip_baru_17146088122110/Depth?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
		},
		"cpmonitoring": {
			"preview": "https://public.tableau.com/views/Cp_Point_AOL2Fase/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
		},
		"aligmentsheet": {
			"preview": "https://a360.co/4bcisxW",
		},
		"asgcpmonitoring": {
			"preview": "https://ario93.github.io/asgpotentialpattern.html",
		},
	};
	
	var ifr2 = {
	"refcpmonitoring": {
		"preview":"https://drive.google.com/file/d/1u7Yu1E0fnAEzRC_LX-YcxLvc8VBN43gL/preview",
		"view":"https://drive.google.com/file/d/1u7Yu1E0fnAEzRC_LX-YcxLvc8VBN43gL/view",
		"download":"https://drive.google.com/uc?export=download&id=1u7Yu1E0fnAEzRC_LX-YcxLvc8VBN43gL",
	},
	"api579": {
		"preview":"https://drive.google.com/file/d/1dDhk01413umHYGHZH2vp6W_SluGvj503/preview",
		"view":"'https://drive.google.com/file/d/1dDhk01413umHYGHZH2vp6W_SluGvj503/view",
		"download":"https://drive.google.com/uc?export=download&id=1dDhk01413umHYGHZH2vp6W_SluGvj503",
	},
	"DNVRPF101":{
		"preview":"https://drive.google.com/file/d/1tg2ar4tmjbySsq1HWwl5iB5y1onuRppG/preview",
		"view":"https://drive.google.com/file/d/1tg2ar4tmjbySsq1HWwl5iB5y1onuRppG/view",
		"download":"https://drive.google.com/uc?export=download&id=1tg2ar4tmjbySsq1HWwl5iB5y1onuRppG",
	},
	"KEPDIRTENGLING_MIGAS":{
		"preview":"https://drive.google.com/file/d/1-qg0VVoYepIssVhjd2uAKyAG7ddjRfwG/preview",
		"view":"https://drive.google.com/file/d/1-qg0VVoYepIssVhjd2uAKyAG7ddjRfwG/view",
		"download":"https://drive.google.com/uc?export=download&id=1dDhk01413umHYGHZH2vp6W_SluGvj503",
	},
	"asmeb318":{
		"preview":"https://drive.google.com/file/d/1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n/preview",
		"view":"https://drive.google.com/file/d/1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n/view",
		"download":"https://drive.google.com/uc?export=download&id=1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n",
	},
	"ftpa":{
		"preview":"https://drive.google.com/file/d/1szDYuvBBiWOxqGDdVL7vp7Nk0GptB3sL/preview",
		"view":"https://drive.google.com/file/d/1szDYuvBBiWOxqGDdVL7vp7Nk0GptB3sL/view",
		"download":"https://drive.google.com/uc?export=download&id=1szDYuvBBiWOxqGDdVL7vp7Nk0GptB3sL",
	},
	"muhlbauer":{
		"preview":"https://drive.google.com/file/d/1eD7hfIjjjSc1IV3b-XlMSY4IyYjFLVJW/preview",
		"view":"https://drive.google.com/file/d/1eD7hfIjjjSc1IV3b-XlMSY4IyYjFLVJW/view",
		"download":"https://drive.google.com/uc?export=download&id=1eD7hfIjjjSc1IV3b-XlMSY4IyYjFLVJW",
	},
	"repot_1p_2024":{
		"preview":"https://drive.google.com/file/d/1w9aTr3Mv8VBTXh_vPaeWmnFJfI5fmigM/preview",
		"view":"https://drive.google.com/file/d/1w9aTr3Mv8VBTXh_vPaeWmnFJfI5fmigM/view",
		"download":"https://drive.google.com/uc?export=download&id=1w9aTr3Mv8VBTXh_vPaeWmnFJfI5fmigM",
	},
};

var [visualrovOptions, setVisualrovOptions] = useState([
	
	{label: '20211016 092822 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/9856RA0cut0?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=9856RA0cut0'},
	{label: '20211016 085802 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/0oNB0kSzL-w?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=0oNB0kSzL-w'},
	{label: '20211019 042834 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/iyjdDVpouZI?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=iyjdDVpouZI'},
	{label: '20211019 030655 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/TsH_A_OQ_Uo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=TsH_A_OQ_Uo'},
	{label: '20211020 061513 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/hNvqJ8lrHHc?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=hNvqJ8lrHHc'},
	{label: '20211020 101858 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/rbgHoe7FdJA?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=rbgHoe7FdJA'},
	{label: '20211020 141900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/BqeHewFgPkA?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=BqeHewFgPkA'},
	{label: '20211020 121859 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/wTAvGgIAcNQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=wTAvGgIAcNQ'},
	{label: '20211020 181900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/iPudQbUr3XM?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=iPudQbUr3XM'},
	{label: '20211020 161900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/3NHbb5Z-UTg?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=3NHbb5Z-UTg'},
	{label: '20211020 201900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/hiPn16Qh580?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=hiPn16Qh580'},
	{label: '20211020 221900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/zaFgw9bi8Q8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=zaFgw9bi8Q8'},
	{label: '20211021 041900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/BVbs1taItMw?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=BVbs1taItMw'},
	{label: '20211021 061900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/RksgdHHEIxg?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=RksgdHHEIxg'},
	{label: '20211021 094109 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/bPtBq5a3FPQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=bPtBq5a3FPQ'},
	{label: '20211021 001901 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/JJGH6F4O-Mw?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=JJGH6F4O-Mw'},
	{ label: '20211021 021900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/-mI36gK-dzQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=-mI36gK-dzQ' },
    { label: '20211021 145900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/_wx9wD6vYyo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=_wx9wD6vYyo' },
    { label: '20211021 165900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/LGiXfhzso9k?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=LGiXfhzso9k' },
    { label: '20211021 185901 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/PEviWe5JQsY?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=PEviWe5JQsY' },
    { label: '20211021 114110 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ZKK5mcame3U?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ZKK5mcame3U' },
    { label: '20211021 125859 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/T7ViWbcpIQQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=T7ViWbcpIQQ' },
    { label: '20211021 193304 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/QuFuKSUsJf4?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=QuFuKSUsJf4' },
    { label: '20211022 114127 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/MBPB0w9IFLU?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=MBPB0w9IFLU' },
    { label: '20211021 213306 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/zkB5SK8Oiko?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=zkB5SK8Oiko' },
    { label: '20211022 013306 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/8Ywx2P6_3xc?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=8Ywx2P6_3xc' },
    { label: '20211022 033307 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/6XBygcfNN4E?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=6XBygcfNN4E' },
    { label: '20211021 233306 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ibiZgNMEWg8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ibiZgNMEWg8' },
    { label: '20211022 084359 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/WbZFavc-sSo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=WbZFavc-sSo' },
    { label: '20211022 100031 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/Ar-xr0ttkgQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=Ar-xr0ttkgQ' },
    { label: '20211022 053308 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/hBzgyelcYdM?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=hBzgyelcYdM' },
    { label: '20211022 195841 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/_UIfk0mwYB8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=_UIfk0mwYB8' },
    { label: '20211022 134128 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/BkDJuukn_Ew?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=BkDJuukn_Ew' },
    { label: '20211023 051226 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/TOZEf0n-HBw?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=TOZEf0n-HBw' },
    { label: '20211023 071228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/A_GmayjTsBY?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=A_GmayjTsBY' },
    { label: '20211023 091227 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/p5tsOWQlMmE?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=p5tsOWQlMmE' },
    { label: '20211023 111228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/rbhBQG53M1k?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=rbhBQG53M1k' },
    { label: '20211022 154128 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ahEEfdxEQKo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ahEEfdxEQKo' },
    { label: '20211022 215843 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/UzTjuWlwq84?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=aUzTjuWlwq84' },
    { label: '20211023 011508 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/rGu4ZmcC5UI?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=rGu4ZmcC5UI' },
    { label: '20211023 031511 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ry-um2nG_sY?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ry-um2nG_sY' },
    { label: '20211023 171228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/4wd52nmiIqM?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=4wd52nmiIqM' },
    { label: '20211023 141228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/vZTr1zyC0P8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=vZTr1zyC0P8' },
    { label: '20211023 121227 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/y24N6fCzZ5U?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=y24N6fCzZ5U' },
    { label: '20211023 091226 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/nrOpd11ibY8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=nrOpd11ibY8' }

]);

var stasiunData = {

	PGNSSWJ: {
        "AOL": "",
        "AOSS": "",
        "AOJBB": "",
    },
    
    PGNSOR2:{
        "Bekasi":"https://public.tableau.com/views/Sor2_Bekasi_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Bogor":"https://public.tableau.com/views/Sor2_Bogor_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Cilegon":"https://public.tableau.com/views/Sor2_Cilegon_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Cirebon":"https://public.tableau.com/views/Sor2_Cirebon_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Jakarta":"https://public.tableau.com/views/Sor2_Jakarta_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Karawang":"https://public.tableau.com/views/Sor2_Karawang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Tangerang":"https://public.tableau.com/views/Sor2_Tangerang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
    },
    
    PGNSOR1:{
        "Batam":"https://public.tableau.com/views/Sor1_Batam_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Dumai":"https://public.tableau.com/views/Sor1_Dumai_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Lampung":"https://public.tableau.com/views/Sor1_Lampung_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Medan":"https://public.tableau.com/views/Sor1_Medan_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Palembang":"https://public.tableau.com/views/Sor1_Palembang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Pekanbaru":"https://public.tableau.com/views/Sor1_Pekanbaru_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
    },
    
    PGNSOR3:{
        "Pasuruan":"https://public.tableau.com/views/Sor3_Pasuruan_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Semarang":"https://public.tableau.com/views/Sor3_Semarang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Sidoarjo":"https://public.tableau.com/views/Sor3_Sidoarjo_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Surabaya":"https://public.tableau.com/views/Sor3_Surabaya_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
    },
	
};

var jalurDataasg = {

	AOL: {
        "Belitang-LBM PH1": "",
        "Belitang-LBM PH2": "",
    },
	AOSS: {
        "GRS - PGD": "",
        "PGD - BELITANG (PH 1)": "",
        "PGD - BELITANG (PH 2)": "",
    },
	AOJBB: {
		"LBM - BJN":"https://ario93.github.io/asgpotentialpattern.html",
		"LBM - MBK":"https://alive.my.id/asgpotentialpattern_lbm_mbk.html",
    },
	
};

var data_kp_point = {
    
    AOL: {
       "": "",
    },
    AOSS: {
        "": "",
    },
    AOJBB: {
        "Pig Launcher - KP 0": "https://a360.co/4bcisxW",
		"KP 0 - KP 2.5": "https://a360.co/3Wzpzfn",
		"KP 2.5 - KP 5.0": "https://a360.co/3wnNkfN",
		"KP 5.0 - KP 7.5": "https://a360.co/4amzGHs",
		"KP 7.5 - KP 10.0": "https://a360.co/3WEPq5w",
		"KP 10.0 - KP 12.5": "https://a360.co/3JWgSEn",
		"KP 12.5 - KP 15.0": "https://a360.co/3WWh6Dh",
		"KP 15.0 - KP 17.5": "https://a360.co/3K1xxGB",
		"KP 17.5 - KP 20.0": "https://a360.co/3Wx0uBI",
		"KP 20.0 - KP 22.5": "https://a360.co/4bAOuU4",
		"KP 22.5 - KP 25.0": "https://a360.co/4bqx10u",
		"KP 25.0 - KP 27.5": "https://a360.co/3yiEBMr",
		"KP 27.5 - KP 30.0": "https://a360.co/4adxge9",
		"KP 30.0 - KP 32.5": "https://a360.co/3yf0mMZ",
		"KP 32.5 - KP 35.0": "https://a360.co/4ahbZ3i",
		"KP 35.0 - KP 37.5": "https://a360.co/4dEfzYo",
		"KP 37.5 - KP 40.0": "https://a360.co/3QCOlqT",
		"KP 40.0 - KP 42.5": "https://a360.co/3QILeOf",
		"KP 42.5 - KP 45.0": "https://a360.co/4ajkAm0",
		"KP 45.0 - KP 47.5": "https://a360.co/3QJgQn7",
		"KP 47.5 - KP 50.0": "https://a360.co/4bAOS4Y",
		"KP 50.0 - KP 52.5": "https://a360.co/4dAp5f0",
		"KP 52.5 - KP 55.0": "https://a360.co/3wwS96p",
		"KP 55.0 - KP 57.5": "https://a360.co/3QCOx9B",
		"KP 57.5 - KP 60.0": "https://a360.co/3K1xU3X",
		"KP 60.0 - KP 62.5": "https://a360.co/4bzhVpv",
		"KP 62.5 - KP 65.0": "https://a360.co/4bAavlP",
		"KP 65.0 - KP 67.5": "https://a360.co/3JZzeUO",
		"KP 67.5 - KP 70.0": "https://a360.co/4bwYxJI",
		"KP 70.0 - KP 72.5": "https://a360.co/3K05Pdq",
		"KP 72.5 - KP 75.0": "https://a360.co/3yiF29x",
		"KP 75.0 - KP 77.5": "https://a360.co/3JYjWQf",
		"KP 77.5 - KP 80.0": "https://a360.co/4bwYzRQ",
		"KP 80.0 - KP 82.5": "https://a360.co/4bwYzRQ",
		"KP 82.5 - KP 85.0": "https://a360.co/44BBawr",
		"KP 85.0 - KP 87.5": "https://a360.co/3K3kSmg",
		"KP 87.5 - KP 90.0": "https://a360.co/44HdF5e",
		"KP 90.0 - KP 92.5": "https://a360.co/3QFwP5m",
		"KP 92.5 - KP 95.0": "https://a360.co/3wHGNMR",
		"KP 95.0 - KP 97.5": "https://a360.co/3QFwX4Q",
		"KP 97.5 - KP 99.907": "https://a360.co/44GQHeb",
		"KP 99.907 - Pig Receiver": "https://a360.co/3UGxhBO"
    }
	
};

const handleCompanyChange = (e) => {
    const company = e.target.value;
    setSelectedCompany(company);
    setSelectedArea('');
    setJalur([]);
	
	if(company == "PGNSOR1"){
					document.getElementById('div_jalur').style.display="none";
				}else if(company == "PGNSOR2"){
					document.getElementById('div_jalur').style.display="none";
				}else if(company == "PGNSOR3"){
					document.getElementById('div_jalur').style.display="none";
				}else{
					document.getElementById('div_jalur').style.display="block";
				}
	
    if (company && stasiunData[company]) {
      setAreas(Object.keys(stasiunData[company]));
    } else {
      setAreas([]);
	  
    }
	
	
	
  };

  const handleAreaChange = (e) => {
	  
    const area = e.target.value;
    setSelectedArea(area);
	const ambildata = document.getElementById('prm_ifr_nipoc').innerHTML;
		if(ambildata == "asgcpmonitoring"){
			
			if (area && jalurDataasg[area]) {
			  setJalur(Object.keys(jalurDataasg[area]));
			} else {
			  setJalur([]);
			}
			
		}else if(ambildata == "cpmonitoring"){
			
			if(selectedCompany === "PGNSOR1" || selectedCompany === "PGNSOR2" || selectedCompany === "PGNSOR3"){
				
				const height1 = (jalurDataheight[selectedCompany][area]-40)+"px";
				const height2 = jalurDataheight[selectedCompany][area]+"px";
				
				document.getElementById('placeholder_tbl3').style.height = height1;
				document.getElementById('iframetbl3').style.height = height2;
				
				document.getElementById('iframetbl3').src = jalurData[selectedCompany][area];
				document.getElementById('ifr3').style.display="block";
				
			}else{
				if (area && jalurData[area]) {
				  setJalur(Object.keys(jalurData[area]));
				} else {
				  setJalur([]);
				}
			}
			
		}else if(ambildata == "aligmentsheet"){
			if (area && data_kp_point[area]) {
			  setJalur(Object.keys(data_kp_point[area]));
			} else {
			  setJalur([]);
			}
		}
		
  };
  
  const handlerovChange = (selectedOption) => {
	  if (selectedOption) {
		setSelectedVisualrov(selectedOption);
		document.getElementById('iframetbl4').src = selectedOption.value;
		document.getElementById('ifr4').style.display="block";
	  } else {
		setSelectedVisualrov(null);
		document.getElementById('ifr3').style.display="none";
	  }
	};

  
  const handleJalurChange = (e) => {
    const jalur = e.target.value;
    setSelectedJalur(jalur);
	const selectedArea = document.getElementById('stasiun').value;
	const ambildata = document.getElementById('prm_ifr_nipoc').innerHTML;
	if(ambildata == "asgcpmonitoring"){
		document.getElementById('iframetbl1').src = jalurDataasg[selectedArea][jalur];
		document.getElementById('ifr1').style.display="block";
	}else if(ambildata == "cpmonitoring"){
		
		const height1 = (jalurDataheight[selectedArea][jalur]-40)+"px";
		const height2 = jalurDataheight[selectedArea][jalur]+"px";
		
		document.getElementById('placeholder_tbl3').style.height = height1;
		document.getElementById('iframetbl3').style.height = height2;
		
		document.getElementById('iframetbl3').src = jalurData[selectedArea][jalur];
		document.getElementById('ifr3').style.display="block";
		
	}else if(ambildata == "aligmentsheet"){
		document.getElementById('iframetbl3').src = data_kp_point[selectedArea][jalur];
		document.getElementById('ifr3').style.display="block";
	}
	
  };
	
	const tampilIfr = (ifr,idfile,ida,ida2) => {
		
		//document.getElementById('logoalive').style.height="500px";
		setAreas([]);
		setJalur([]);
		allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
		
		if(ifr == 1){
			
			if(idfile =="asgcpmonitoring"){
				document.getElementById('prm_ifr_nipoc').innerHTML="asgcpmonitoring";
				document.getElementById('div_subsidiary').style.display="block";
				document.getElementById('div_jalur').style.display="block";
				document.getElementById('div_stasiun').style.display="block";
			}
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				document.getElementById('div_subsidiary').style.display="none";
				document.getElementById('div_jalur').style.display="none";
				document.getElementById('div_stasiun').style.display="none";
				document.getElementById('prm_ifr_nipoc').innerHTML="";
			}
			
		}else if(ifr == 2){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr1').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				if(idfile == "pipetally"){
					document.getElementById('iframetbl1').src = ifr1[idfile].preview;
				}else{
					document.getElementById('iframetbl1').src = ifr2[idfile].preview;
				}
			}else{
				document.getElementById('ifr1').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 3){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('prm_ifr_nipoc').innerHTML="cpmonitoring";
				document.getElementById('div_subsidiary').style.display="block";
				document.getElementById('div_jalur').style.display="block";
				document.getElementById('div_stasiun').style.display="block";
				document.getElementById('ifr3').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				//document.getElementById('iframetbl3').src = ifr1[idfile].preview;
				
				const firstKey1 = Object.keys(jalurDataheight)[0];

				const firstSubKey1 = Object.keys(jalurDataheight[firstKey1])[0];

				const firstValue1 = jalurDataheight[firstKey1][firstSubKey1];
				
				const firstKey2 = Object.keys(jalurData)[0];

				const firstSubKey2 = Object.keys(jalurData[firstKey2])[0];

				const firstValue2 = jalurData[firstKey2][firstSubKey2];
				
				const height1 = (firstValue1-40)+"px";
				const height2 = firstValue1+"px";
				
				document.getElementById('placeholder_tbl3').style.height = height1;
				document.getElementById('iframetbl3').style.height = height2;
				
				document.getElementById('iframetbl3').src = firstValue2;
				
			}else{
				document.getElementById('prm_ifr_nipoc').innerHTML="";
				document.getElementById('div_subsidiary').style.display="none";
				document.getElementById('div_jalur').style.display="none";
				document.getElementById('div_stasiun').style.display="none";
				document.getElementById('ifr3').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 4){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('prm_ifr_nipoc').innerHTML="aligmentsheet";
				document.getElementById('div_subsidiary').style.display="block";
				document.getElementById('div_stasiun').style.display="block";
				document.getElementById('div_jalur').style.display="block";
				document.getElementById('ifr3').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl3').src = ifr1[idfile].preview;
			}else{
				document.getElementById('prm_ifr_nipoc').innerHTML="";
				document.getElementById('div_subsidiary').style.display="none";
				document.getElementById('div_stasiun').style.display="none";
				document.getElementById('div_jalur').style.display="none";
				document.getElementById('ifr3').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 5){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('iframetbl4').src = 'https://www.youtube.com/embed/9856RA0cut0?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=9856RA0cut0';
				document.getElementById('ifr4').style.display="block";
				document.getElementById('div_rov').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
			}else{
				document.getElementById('div_rov').style.display="none";
				document.getElementById('ifr4').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}
		
	};
	
	const [openSections, setOpenSections] = useState({});

	const toggleCollapse = (section) => {
		setOpenSections((prevState) => ({
		  ...prevState,
		  [section]: !prevState[section],
		}));
	};

	const handlePofChange = async (selectedOption) => {

	

	};
	
  const [pointOfConcernOptions, setPointOfConcernOptions] = useState([
    { value: '', label: 'Point of Concern' },
    { value: 'sfpoc', label: 'Station Facilities' },
    { value: 'nipoc', label: 'Network Infrastructure' },
    { value: 'repoc', label: 'Rotating Equipment' },
    { value: 'lngrpoc', label: 'LNG Regasification' },
    { value: 'lpgppoc', label: 'LPG Plant' },
    { value: 'cngrpoc', label: 'CNG Refueling' },
    { value: 'pgpoc', label: 'Power Generation' }
  ]);
  
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		ifr2css: {
			margin: '50px',
			width: 'calc(100vw - 270px)',
			height: '700px'
		},

		placeholder_tbl2css: {
			marginTop: '0px',
			marginLeft: '-0px',
			maxHeight: '700px',
			overflow: 'hidden',
		},

		iframetbl2css:{
			float: 'left',
			width: '1610px',
			height: '820px',
			left: 0,
			transform: 'scale(0.78)',
			overflow: 'hidden',
			top: '10%',
			transformOrigin: '0 0',
			msTransformOrigin: '0 0',
			MozTransformOrigin: '0 0',
			OTransformOrigin: '0 0',
			WebkitTransformOrigin: '0 0',
		},
		
		selectcompany:{
			margin: '0px 10px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		selectstasiun:{
			margin: '0px 0px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		iframetbl3css: {
			width: 'calc(100vw - 200px)',
			height: '590px',
		  },

		  ifr3css: {
			marginTop: '10px',
			width: 'calc(100vw - 200px)',
			height: '550px',
			overflowX: 'hidden',
			overflowY: 'auto',
			scrollbarWidth: 'none',
		  },
		  
		  placeholdertbl3css: {
			marginTop: '-0px',
			marginLeft: '-1px',
			height: '550px',
			overflow: 'hidden',
		  },
		  
		divsides:{
			//position: 'relative', 
			overflow: 'visible',
		},
		
		divsides2:{
			float: 'left',
			marginLeft: '160px',
			position : 'absolute',
			width : '100px',
			background: 'transparance',
			borderRadius: '5px',
			textAlign: 'justify',
			padding: '5px',
			height: '100px',
			overflow: 'auto', 
			scrollbarWidth: 'none',
			color: 'white',
		},
		
		divsides3:{
			float: 'left',
			marginLeft: '250px',
			position : 'absolute',
			maxWidth : '400px',
			background: 'grey',
			borderRadius: '5px',
			textAlign: 'justify',
			padding: '5px',
			height: '100px',
			overflow: 'auto', 
			scrollbarWidth: 'none',
			color: 'white',
		},
		
		dividesdalam:{
			fontSize: '0px', 
			height: '50px', 
			width:'50px',
		},
		
	};

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

	useEffect(() => {
    
    const url = 'https://script.google.com/macros/s/AKfycbyiB_FX-cJNo8frFs-vVWc5W2QxqG8LLzJ2bCSxs4V8qO5m4PsSVk1_3VJCJwL__fF1/exec?prm_potential=link_potential';

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setJalurData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
	  
	const url2 = 'https://script.google.com/macros/s/AKfycbyiB_FX-cJNo8frFs-vVWc5W2QxqG8LLzJ2bCSxs4V8qO5m4PsSVk1_3VJCJwL__fF1/exec?prm_potential=height_potential';

    fetch(url2)
      .then(response => response.json())
      .then(data => {
        setJalurDataheight(data);
      })
      .catch(error => console.error('Error fetching data:', error));
	  
  }, []);
	
  useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);
  
  useEffect(() => {
    if (selectedPointOfConcern) {
      navigate("/"+selectedPointOfConcern.value);
	  
    }
  }, [selectedPointOfConcern, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
	
		<div id="prm_ifr_nipoc" style={{display: 'none'}}></div>
		<div id="prm_entitas" style={{display: 'none'}}>PGN</div>
		<div id="prm_rimox" style={{display: 'none'}}></div>
		
		<a href="./">
			<img 
				id="logoalive"
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '0px', marginRight: '15px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{ clear: 'both' }}></div>
		
	<div style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
			<Select
			  styles={customStyles}
			  options={pointOfConcernOptions}
			  onChange={setSelectedPointOfConcern}
			  placeholder="Point of Concern"
			  value={selectedPointOfConcern}
			/>
		</div>
		
		<div ref={(el) => (allIframesRef.current[4] = el)} id="div_subsidiary" style={{ display: 'none', margin: '0px 10px -20px 0px', width: '250px', float: 'left' }}>
		  <select 
			style={{
			  height: '25px', 
			  width: '100%', 
			  backgroundColor: '#fff', 
			  border: '1px solid #ccc', 
			  borderRadius: '4px', 
			  padding: '0 10px',
			  fontSize: '14px',
			  color: '#333',
			  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
			  appearance: 'none',
			}}
			id="subsidiary" 
			className="form-control"
			value={selectedCompany}
			onChange={handleCompanyChange}
		>
			<option value="">Company</option>
			<option value="PGNSSWJ">1.PGN-SSWJ</option>
			<option value="PTPertaminaGas">2.Pertamina Gas</option>
			<option value="PTNusantaraRegas">3.Nusantara Regas</option>
			<option value="PTTransportasiGasIndonesia">4.Transportasi Gas Indonesia</option>
			<option value="PTKalimantanJawaGas">5.Kalimantan Jawa Gas</option>
			<option value="PTPertaSamtanGas">6.Perta Samtan Gas</option>
			<option value="PTPGNLNGIndonesia">7.PGN LNG Indonesia</option>
			<option value="PTWidarMandripaNusantara">8.Widar Mandripa Nusantara</option>
			<option value="PTSakaEnergiIndonesia">9.Saka Energi Indonesia</option>
			<option value="PTGagasEnergiIndonesia">10.Gagas Energi Indonesia</option>
			<option value="PTPertagasNiaga">11.Pertagas Niaga</option>
			<option value="PTPertaArunGas">12.Perta Arun Gas</option>
			<option value="PTPertaDayaGas">13.Perta Daya Gas</option>
			<option value="PGNSOR1">14.PGN-SOR1</option>
			<option value="PGNSOR2">15.PGN-SOR2</option>
			<option value="PGNSOR3">16.PGN-SOR3</option>
		</select>

		</div>


      <div ref={(el) => (allIframesRef.current[5] = el)} id="div_stasiun" style={{ display: 'none', margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
        <select 
          style={{
			  height: '25px', 
			  width: '100%', 
			  backgroundColor: '#fff', 
			  border: '1px solid #ccc', 
			  borderRadius: '4px', 
			  padding: '0 10px',
			  fontSize: '14px',
			  color: '#333',
			  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
			  appearance: 'none',
			}}
          id="stasiun" 
          className="form-control"
          value={selectedArea}
          onChange={handleAreaChange}
          disabled={!areas.length}
        >
          <option value="">Area</option>
          {areas.map(area => (
            <option key={area} value={area}>{area}</option>
          ))}
        </select>
      </div>

      <div ref={(el) => (allIframesRef.current[6] = el)} id="div_jalur" style={{ display: 'none', margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
        <select 
          style={{
			  height: '25px', 
			  width: '100%', 
			  backgroundColor: '#fff', 
			  border: '1px solid #ccc', 
			  borderRadius: '4px', 
			  padding: '0 10px',
			  fontSize: '14px',
			  color: '#333',
			  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
			  appearance: 'none', // Remove default dropdown arrow for custom styling
			}}
          id="jalur" 
          className="form-control all_ifr"
		  onChange={handleJalurChange}
          disabled={!jalur.length}
        >
          <option value="">Jalur</option>
          {jalur.map(j => (
            <option key={j} value={j}>{j}</option>
          ))}
        </select>
      </div>
	  
	  <div ref={(el) => (allIframesRef.current[26] = el)} id="div_rov" style={{ display: 'none', margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
			<Select
			  styles={customStyles}
			  options={visualrovOptions}
			  onChange={handlerovChange}
			  placeholder="ROV"
			  value={selectedVisualrov}
			/>
		</div>
		
		
		<div style={{clear: 'both'}}></div>
	  
		<table style={{marginRight: '0px'}}>
		
			<tbody>
			<tr>
				<td align="left" valign="top" style={{width: '200px', fontWeight: '500'}}>
					
					<div
					ref={scrollRef}
					style={{
					  width: '100%',
					  maxHeight: '560px',
					  overflowY: 'auto',
					  scrollbarWidth: 'none',
					}}
					onScroll={handleScroll}
					className={`custom-scroll ${isHovered ? 'hover' : ''}`}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				  >
				  {/*Risk Profile Update*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('risk_profile_update')}
						  onMouseEnter={() => handleMouseEnter('risk_profile_updatehover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'risk_profile_updatehover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Integrity Management System (PIMS) adalah suatu sistem yang dirancang untuk memastikan bahwa jaringan pipa gas tetap beroperasi dengan aman, handal, dan efisien sepanjang masa pakainya. PIMS adalah bagian dari manajemen keselamatan pipa yang komprehensif yang mencakup berbagai prosedur, teknologi, dan metode untuk mengelola risiko yang terkait dengan operasi pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											PIMS Pipeline Integrity Management System; Risk Profile Update
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['risk_profile_update'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[13] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asmeb318" onClick={() => tampilIfr(2,'asmeb318','bk_asmeb318','ttp_asmeb318')}>ASME B-318</a>
														<a ref={(el) => (allIframesRef.current[13] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asmeb318" onClick={() => tampilIfr(2,'asmeb318','ttp_asmeb318','bk_asmeb318')}><b>ASME B-318</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_calc']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[14] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbauer" onClick={() => tampilIfr(2,'muhlbauer','bk_muhlbauer','ttp_muhlbauer')}>RA Muhlbauer 2015</a>
														<a ref={(el) => (allIframesRef.current[14] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbauer" onClick={() => tampilIfr(2,'muhlbauer','ttp_muhlbauer','bk_muhlbauer')}><b>RA Muhlbauer 2015</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Risk Profile Update*/}
				  
					{/*Intelligent Pigging*/}
					<div style={{  }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('intelligent_pigging')}
						  onMouseEnter={() => handleMouseEnter('intelligent_pigginghover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'intelligent_pigginghover' && (
							 <>
							  <div 
							  style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
								
							  }}
							  >
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div 
								style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
									}}
								>

									“Intelligent Pigging” yang juga disebut sebagai “Smart Pigging” adalah teknik robotik canggih yang menggerakkan unit pengukur integritas pipa (PIG) ​​yang dibuat khusus yang dilengkapi dengan berbagai probe dan sensor melalui pipa untuk mendeteksi dan mengukur korosi, kehilangan logam, retakan, penyok, deformasi, dll. Laporan ILI akan merinci semua masalah utama terkait kondisi dan integritas pipa dengan penanda lokasi yang tepat.
									
								</div>
								
								<div style={{clear: 'both'}}></div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Intelligent Pigging
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['intelligent_pigging'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ftpa" onClick={() => tampilIfr(2,'ftpa','bk_ftpa','ttp_ftpa')}>Field Tool Performance Assessment</a>
														<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ftpa" onClick={() => tampilIfr(2,'ftpa','ttp_ftpa','bk_ftpa')}><b>Field Tool Performance Assessment</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_calc'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[0] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ffs" onClick={() => tampilIfr(1,'ffs','bk_ffs','ttp_ffs')}>Fitness For Service</a>
														<a ref={(el) => (allIframesRef.current[0] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ffs" onClick={() => tampilIfr(1,'ffs','ttp_ffs','bk_ffs')}><b>Fitness For Service</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_assessment12')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[1] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_pipetally" onClick={() => tampilIfr(1,'pipetally','bk_pipetally','ttp_pipetally')}>Pipetally</a>
														<a ref={(el) => (allIframesRef.current[1] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_pipetally" onClick={() => tampilIfr(1,'pipetally','ttp_pipetally','bk_pipetally')}><b>Pipetally</b></a>
													</td>
												</tr>
											</table>
										</li>
									
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','bk_repot_1p_2024','ttp_repot_1p_2024')}>Repot IP 2024</a>
														<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','ttp_repot_1p_2024','bk_repot_1p_2024')}><b>Repot IP 2024</b></a>
													</td>
												</tr>
											</table>
										</li>
										
									</ul>
								 
								 </div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Intelligent Pigging*/}
					
					{/*CP Monitoring*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('cp_monitoring')}
						  onMouseEnter={() => handleMouseEnter('cp_monitoringhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'cp_monitoringhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									CP Monitoring (Cathodic Protection Monitoring) pada pipa gas adalah proses pengawasan dan pengukuran perlindungan katodik (Cathodic Protection/CP) untuk mencegah korosi eksternal pada pipa bawah tanah atau pipa yang terpapar lingkungan korosif. CP merupakan metode yang paling umum digunakan untuk melindungi pipa baja dari korosi dengan mengubahnya menjadi katode dalam suatu sel elektrokimia.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											CP Monitoring
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['cp_monitoring'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['cp_monitoring_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[2] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refcpmonitoring" onClick={() => tampilIfr(2,'refcpmonitoring','bk_refcpmonitoring','ttp_refcpmonitoring')}>NACE RP0102</a>
														<a ref={(el) => (allIframesRef.current[2] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refcpmonitoring" onClick={() => tampilIfr(2,'refcpmonitoring','ttp_refcpmonitoring','bk_refcpmonitoring')}><b>NACE RP0102</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['cp_monitoring_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[3] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_cpmonitoring" onClick={() => tampilIfr(3,'cpmonitoring','bk_cpmonitoring','ttp_cpmonitoring')}>Potential Pattern</a>
														<a ref={(el) => (allIframesRef.current[3] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_cpmonitoring" onClick={() => tampilIfr(3,'cpmonitoring','ttp_cpmonitoring','bk_cpmonitoring')}><b>Potential Pattern</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 0px 0px'}}>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[7] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asgcpmonitoring" onClick={() => tampilIfr(1,'asgcpmonitoring','bk_asgcpmonitoring','ttp_asgcpmonitoring')}>ASG Potential Pattern</a>
														<a ref={(el) => (allIframesRef.current[7] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asgcpmonitoring" onClick={() => tampilIfr(1,'asgcpmonitoring','ttp_asgcpmonitoring','bk_asgcpmonitoring')}><b>ASG Potential Pattern</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end CP Monitoring*/}
					
					{/*ROW Management*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('row_management')}
						  onMouseEnter={() => handleMouseEnter('row_managementhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'row_managementhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									ROW Management (Right-of-Way Management) adalah proses pengelolaan area yang diperlukan untuk instalasi, pemeliharaan, dan operasi pipa. Right-of-Way (ROW) adalah hak atau izin untuk menggunakan sepotong tanah untuk menempatkan pipa dan infrastruktur terkait lainnya. ROW Management mencakup berbagai aktivitas untuk memastikan bahwa penggunaan area ini aman, efisien, dan sesuai dengan regulasi.
									
								</div>
							 </>
						)}

							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											ROW Management
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['row_management'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['row_management_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[8] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_aligmentsheet" onClick={() => tampilIfr(4,'aligmentsheet','bk_aligmentsheet','ttp_aligmentsheet')}>Aligment Sheet</a>
														<a ref={(el) => (allIframesRef.current[8] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_aligmentsheet" onClick={() => tampilIfr(4,'aligmentsheet','ttp_aligmentsheet','bk_aligmentsheet')}><b>Aligment Sheet</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['row_management_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['row_management_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['row_management_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end ROW Management*/}
					
					{/*SideScan Sonar*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('sidescan_sonar')}
						  onMouseEnter={() => handleMouseEnter('sidescan_sonarhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'sidescan_sonarhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									SideScan Sonar adalah teknologi penginderaan bawah air yang digunakan untuk survei dan pemantauan pipa gas yang terendam atau berada di bawah permukaan air. Teknologi ini memberikan gambaran dua dimensi dari dasar perairan, termasuk pipa, struktur bawah air, dan fitur lainnya, dengan memanfaatkan gelombang suara untuk mendeteksi objek dan menganalisis kondisi sekitarnya.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											SideScan Sonar
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['sidescan_sonar'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end sidescan_sonar*/}
					
					{/*Survey Accoustic Survey*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('survey_accoustic_survey')}
						  onMouseEnter={() => handleMouseEnter('survey_accoustichover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'survey_accoustichover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Acoustic Survey atau Survei Akustik pada pipa gas adalah metode non-destruktif yang digunakan untuk memeriksa dan menganalisis kondisi pipa, terutama pipa bawah tanah atau pipa yang terendam di dalam air. Teknik ini menggunakan gelombang suara untuk mendeteksi dan mengukur kondisi pipa dan lingkungan sekitarnya.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Survey Accoustic Survey
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['survey_accoustic_survey'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Survey Accoustic Survey*/}
					
					{/*CIPS DCVG*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('cipsdcvg')}
						  onMouseEnter={() => handleMouseEnter('cipsdcvghover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'cipsdcvghover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									CIPS (Current Injection Pipe Survey) dan DCVG (Direct Current Voltage Gradient) adalah metode pengujian yang digunakan untuk memantau dan mengevaluasi sistem perlindungan katodik (Cathodic Protection/CP) pada pipa gas untuk memastikan bahwa pipa dilindungi dari korosi.
								
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											CIPS DCVG
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['cipsdcvg'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end CIPS DCVG*/}
					
					{/*Freespan Rectification*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('freespan_rectification')}
						  onMouseEnter={() => handleMouseEnter('freespan_rectificationhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'freespan_rectificationhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									Freespan Rectification adalah metode yang digunakan dalam pengelolaan dan pemeliharaan pipa gas untuk menangani dan memperbaiki masalah yang terkait dengan freespan — bagian pipa yang terjuntai tanpa dukungan dari struktur penopang. Freespan dapat menjadi area yang rawan terhadap stres dan kegagalan jika tidak ditangani dengan benar.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Freespan Rectification
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['freespan_rectification'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Freespan Rectification*/}
					
					
					{/*Reinforcement*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('reinforcement')}
						  onMouseEnter={() => handleMouseEnter('reinforcementhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'reinforcementhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Reinforcement pada pipa gas merujuk pada proses atau teknik yang digunakan untuk memperkuat pipa agar dapat menahan tekanan, beban, dan kondisi lingkungan yang mungkin mengancam integritasnya. Proses ini penting untuk memastikan bahwa pipa gas tetap aman, efektif, dan berfungsi dengan baik sepanjang masa pakainya.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Reinforcement
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['reinforcement'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['reinforcement_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['reinforcement_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['reinforcement_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['reinforcement_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Reinforcement*/}
					
					
					{/*Visual Inspection ROV*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('visual_inspection_rov')}
						  onMouseEnter={() => handleMouseEnter('visual_inspection_rovhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'visual_inspection_rovhover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Visual Inspection ROV (Remotely Operated Vehicle) pada pipa gas adalah metode inspeksi bawah air menggunakan kendaraan yang dikendalikan dari jarak jauh untuk memeriksa dan menilai kondisi pipa yang terendam atau berada di bawah permukaan air. Metode ini memungkinkan pemeriksaan visual secara langsung tanpa perlu melakukan penggalian atau pemindahan pipa, yang sangat berguna untuk pipa yang terletak di lokasi yang sulit dijangkau.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Visual Inspection Rov
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['visual_inspection_rov'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[9] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_visual_rov_2021" onClick={() => tampilIfr(5,'visual_rov_2021','bk_visual_rov_2021','ttp_visual_rov_2021')}>2021</a>
														<a ref={(el) => (allIframesRef.current[9] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_visual_rov_2021" onClick={() => tampilIfr(5,'visual_rov_2021','ttp_visual_rov_2021','bk_visual_rov_2021')}><b>2021</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Visual Inspection ROV*/}
					
					{/*Inline Inspection*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('inline_inspection')}
						  onMouseEnter={() => handleMouseEnter('inline_inspectionhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'inline_inspectionhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Inline Inspection (ILI) pada pipa gas adalah metode pengujian yang dilakukan untuk mengevaluasi kondisi internal pipa menggunakan perangkat yang dimasukkan langsung ke dalam pipa. Teknik ini penting untuk mendeteksi potensi masalah seperti korosi, retakan, dan penumpukan material yang dapat mempengaruhi integritas dan keamanan pipa.
									
								</div>
							 </>
						)}
						
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Inline Inspection
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['inline_inspection'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Inline Inspection*/}
					
					{/*Long Range UT Inspection*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('long_range_ut_inspection')}
						  onMouseEnter={() => handleMouseEnter('long_range_ut_inspectionhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'long_range_ut_inspectionhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Long Range Ultrasonic Testing (LRUT) adalah metode inspeksi non-destruktif yang digunakan untuk memeriksa pipa gas dan struktur logam lainnya dengan menggunakan gelombang ultrasonik untuk mendeteksi kerusakan, korosi, atau degradasi material dari jarak jauh. Metode ini sangat berguna untuk melakukan survei pipa panjang tanpa perlu membuka atau memindahkan pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Long Range Ultrasonic Testing (LRUT)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['long_range_ut_inspection'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Long Range UT Inspection*/}
					
					{/*Pipeline Patrol & Leak Survey*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pipeline_patrol_leak_survey')}
						  onMouseEnter={() => handleMouseEnter('pipeline_patrol_leak_surveyhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'pipeline_patrol_leak_surveyhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Patrol & Leak Survey adalah metode pengawasan dan pemeriksaan yang digunakan untuk memantau pipa gas dan mendeteksi kebocoran atau masalah lain yang mungkin mempengaruhi operasional dan keselamatan sistem pipa. Metode ini penting untuk memastikan bahwa pipa gas berfungsi dengan aman dan efisien serta untuk mengidentifikasi masalah sebelum menjadi lebih serius.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Pipeline Patrol & Leak Survey
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pipeline_patrol_leak_survey'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_lasreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Pipeline Patrol & Leak Survey*/}
					
					{/*Barrier Activities*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('barrier_activities')}
						  onMouseEnter={() => handleMouseEnter('barrier_activitieshover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'barrier_activitieshover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
								  ...styles.divsides3,
								  marginTop: `-${scrollPosition}px`,
								}}>

									Barrier Activities pada pipa gas adalah tindakan yang dilakukan untuk menetapkan dan menjaga batasan atau penghalang yang melindungi pipa dari potensi risiko dan bahaya yang dapat mempengaruhi integritas dan keamanan operasionalnya. Konsep ini mencakup berbagai strategi dan tindakan yang bertujuan untuk mencegah atau memitigasi masalah yang dapat menyebabkan kerusakan atau kecelakaan pada sistem pipa gas.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Barrier Activities
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['barrier_activities'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_lasreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Barrier Activities*/}
					
					{/*Permanen Repair*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('permanen_repair')}
						  onMouseEnter={() => handleMouseEnter('permanen_repairhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'permanen_repairhover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Permanen Repair pada pipa gas adalah tindakan perbaikan jangka panjang yang dilakukan untuk memperbaiki kerusakan atau masalah pada pipa secara permanen, memastikan bahwa pipa berfungsi kembali dengan aman dan dapat diandalkan. Tindakan ini berbeda dari perbaikan sementara yang hanya mengatasi masalah untuk jangka waktu pendek. Repair permanen dirancang untuk memulihkan integritas struktural pipa dan memastikan bahwa pipa dapat beroperasi tanpa risiko kegagalan di masa depan.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Permanen Repair
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['permanen_repair'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('permanen_repair_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('permanen_repair_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('permanen_repair_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_lastreport'] && (
								
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Aligment Sheet GC
										</li>
									</ul>
								
								)}
							</div>
						 
						 </div>
						)}
					</div>
					{/*Permanen Repair*/}
					
					{/*Geohazard Assessment*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('geohazard_assessment')}
						  onMouseEnter={() => handleMouseEnter('geohazard_assessmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'geohazard_assessmenthover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Geohazard Assessment pada pipa gas adalah proses evaluasi dan identifikasi potensi bahaya geologi yang dapat mempengaruhi integritas pipa. Tujuan dari penilaian ini adalah untuk memahami risiko yang terkait dengan kondisi geologis di sekitar jalur pipa dan mengambil langkah-langkah pencegahan untuk melindungi pipa dari kerusakan atau kegagalan yang disebabkan oleh faktor-faktor geologi.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Geohazard Assessment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['geohazard_assessment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Geohazard Assessment*/}
					
					{/*Field Joint Assessment*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('field_joint_assessment')}
						  onMouseEnter={() => handleMouseEnter('field_joint_assessmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'field_joint_assessmenthover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Field Joint Assessment pada pipa gas adalah proses evaluasi dan pemeriksaan sambungan pipa yang dilakukan di lapangan. Field joints adalah sambungan atau penyambungan antara potongan-potongan pipa yang dilakukan di lokasi pemasangan. Proses ini penting untuk memastikan bahwa sambungan pipa di lapangan memenuhi standar kualitas dan keselamatan yang diperlukan.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Field Joint Assessment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['field_joint_assessment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Field Joint Assessment*/}
					
					{/*Survey Foto Udara (Fotogrametri) & LIDAR*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar')}
						  onMouseEnter={() => handleMouseEnter('survey_foto_udara_fotogrametri_lidarhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'survey_foto_udara_fotogrametri_lidarhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Survey Foto Udara (Fotogrametri) dan LIDAR adalah teknik pemantauan dan pemetaan yang digunakan dalam industri pipa gas untuk mengumpulkan data akurat mengenai jalur pipa dan area sekitarnya dari udara. Kedua metode ini memberikan informasi yang sangat berguna untuk perencanaan, pemeliharaan, dan manajemen pipa gas.

								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Survey Foto Udara (Fotogrametri) & LIDAR
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['survey_foto_udara_fotogrametri_lidar'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Survey Foto Udara (Fotogrametri) & LIDAR*/}
					
					{/*Coating Defect Assessment and Repair*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('coating_defect_sssessment_repair')}
						  onMouseEnter={() => handleMouseEnter('coating_defect_sssessment_repairhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'coating_defect_sssessment_repairhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Coating Defect Assessment and Repair pada pipa gas adalah proses untuk menilai dan memperbaiki kerusakan pada lapisan pelindung (coating) yang melindungi pipa dari korosi dan faktor lingkungan lainnya. Coating pada pipa gas memainkan peran penting dalam melindungi pipa dari kerusakan eksternal yang dapat mempengaruhi integritas dan umur pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Coating Defect Assessment and Repair
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['coating_defect_sssessment_repair'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coating_defect_sssessment_repair_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('scoating_defect_sssessment_repair_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coating_defect_sssessment_repair_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Coating Defect Assessment and Repair*/}
					
					{/*Pipeline Defect Assessment and Reinforcement*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement')}
						  onMouseEnter={() => handleMouseEnter('pipeline_defect_assessment_reinforcementhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'pipeline_defect_assessment_reinforcementhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Defect Assessment and Reinforcement pada pipa gas adalah proses yang melibatkan identifikasi, evaluasi, dan perbaikan kerusakan atau kekurangan pada pipa untuk memastikan integritas dan keamanan sistem pipa gas. Proses ini meliputi penilaian terhadap defect atau cacat yang ada, serta penerapan langkah-langkah penguatan (reinforcement) untuk memperbaiki dan memperkuat pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Pipeline Defect Assessment and Reinforcement
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pipeline_defect_assessment_reinforcement'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coating_defect_sssessment_repair_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Pipeline Defect Assessment and Reinforcement*/}
					
					{/*Pipeline Risk Assessment*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pipeline_risk_assessment')}
						  onMouseEnter={() => handleMouseEnter('pipeline_risk_assessmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'pipeline_risk_assessmenthover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Risk Assessment pada pipa gas adalah proses sistematis untuk mengidentifikasi, menganalisis, dan menilai risiko yang dapat mempengaruhi keselamatan, keandalan, dan operasional sistem pipa gas. Tujuan dari penilaian risiko ini adalah untuk memahami potensi ancaman dan dampaknya serta mengembangkan strategi mitigasi untuk mengurangi risiko tersebut.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Pipeline Risk Assessment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pipeline_risk_assessment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Pipeline Risk Assessment*/}
					
					{/*Corrosion Underwater*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('corrosion_underwater')}
						  onMouseEnter={() => handleMouseEnter('corrosion_underwaterhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'corrosion_underwaterhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Corrosion Underwater pada pipa gas adalah proses korosi yang terjadi pada bagian pipa yang terendam di bawah air, seperti pipa yang melewati sungai, danau, atau laut. Korosi bawah air ini dapat menjadi masalah serius karena dapat mengakibatkan penurunan integritas struktural pipa, yang berpotensi menyebabkan kebocoran atau kegagalan pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Corrosion Underwater
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['corrosion_underwater'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[10] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_api579" onClick={() => tampilIfr(2,'api579','bk_api579','ttp_api579')}>API 579-1-2021</a>
														<a ref={(el) => (allIframesRef.current[10] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_api579" onClick={() => tampilIfr(2,'api579','ttp_api579','bk_api579')}><b>API 579-1-2021</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
									
									<ul style={{ margin: '-15px 0px 10px 0px', fontSize: '13px' }}>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[11] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF101" onClick={() => tampilIfr(2,'DNVRPF101','bk_DNVRPF101','ttp_DNVRPF101')}>DNV-RP-F101</a>
														<a ref={(el) => (allIframesRef.current[11] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF101" onClick={() => tampilIfr(2,'DNVRPF101','ttp_DNVRPF101','bk_DNVRPF101')}><b>DNV-RP-F101</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Corrosion Underwater*/}
					
					{/*Certification of Inspection(COI/PLO)*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('coiplo')}
						  onMouseEnter={() => handleMouseEnter('coiplohover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'coiplohover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Certification of Inspection (COI) / Pipeline Location and Orientation (PLO) adalah dokumen penting dalam industri pipa gas yang mengonfirmasi bahwa pipa telah diperiksa dan memenuhi standar kualitas serta regulasi yang berlaku.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Certification of Inspection(COI/PLO)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['coiplo'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['coiplo_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_KEPDIRTENGLING_MIGAS" onClick={() => tampilIfr(2,'KEPDIRTENGLING_MIGAS','bk_KEPDIRTENGLING_MIGAS','ttp_KEPDIRTENGLING_MIGAS')}>KEPDIRTENGLING MIGAS</a>
														<a ref={(el) => (allIframesRef.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_KEPDIRTENGLING_MIGAS" onClick={() => tampilIfr(2,'KEPDIRTENGLING_MIGAS','ttp_KEPDIRTENGLING_MIGAS','bk_KEPDIRTENGLING_MIGAS')}><b>KEPDIRTENGLING MIGAS</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['coiplo_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['coiplo_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['coiplo_lastreport'] }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Certification of Inspection(COI/PLO)*/}
				</div>
				</td>
				<td valign="top" style={{width:'calc(100vw - 200px)'}}>
					<div style={{width: '100%', height: '100%', overflow: 'auto'}}>
						<img 
							id="homealive_samping_sidemenu"
							src={`${process.env.PUBLIC_URL}/gambar/alive_poc.jpeg`} 
							alt="Alive home" 
							style={{width: '98%', height: '73vh', margin: '10px'}}
						/>
						
						<div ref={(el) => (allIframesRef.current[20] = el)} id="ifr1" style={{ width: '100%', height: '75vh', margin: '10px', display: 'none'}}>
							<iframe id="iframetbl1" src="" style={{width: '100%', height: '100%'}}></iframe>
						</div>
						
						<div ref={(el) => (allIframesRef.current[21] = el)} id="ifr2" style={{display: 'none', maxWidth: 'calc(100vw - 270px)'}} >
							<iframe id="iframetbl2" style={styles.iframetbl2css} src="" frameBorder="0" scrolling="no"></iframe>
						</div>
						
						<div ref={(el) => (allIframesRef.current[22] = el)} id="ifr3" style={{ display: 'none', ...styles.ifr3css }}>
							<div id="placeholder_tbl3" style={styles.placeholdertbl3css}>
								<iframe id="iframetbl3" src="" style={styles.iframetbl3css}></iframe>
							</div>
						</div>
				
						<div ref={(el) => (allIframesRef.current[23] = el)} id="ifr4" style={{display: 'none', marginTop: '80px', width: 'calc(100vw - 270px)', height: '700px'}}>
							<div id="placeholder_tbl4" style={{marginTop: '-65px', marginLeft: '-0px', maxHeight: '610px', overflow: 'hidden'}}>
								<iframe id="iframetbl4"style={{border: '0px none', height: '700px', marginTop: '-60px', width: 'calc(100vw - 270px)'}}  src="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</div>
						
					</div>
				</td>
			</tr>
			</tbody>
			
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		  ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nipoc;
