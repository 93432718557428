import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Nativestock_tgi = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	 var photostocklist = {
        
        isometric:{
            "1":"11LhBnHtG4OwPy7uTtHl0yLgzeyeW085Z",
            "2":"11uaMk3nv-OOKXrhcueaQhx3NUaRI2o3R",
            "3":"12m6rHlUdrA01gKeYghK2dPFtmyPUHXsD",
            "4":"13LietwBUkBaI__w6BCt6Z39aC0FaNq7p",
            "5":"14v5D7j5DPQgajfZuebW1ErN2vj1I0cbL",
            "6":"17M6I7Xxh6_-CC22cFhRY5ezboeBVw0Ct",
            "7":"17ohYFsA8G12MwQk0u1YObvS3wM2kVyPD",
            "8":"18WOHdK7G9s502YfbTXxvqunHIPCNbXcw",
            "9":"19-3saEoMYyrIOWEAciD31fi0oARBKhNK",
            "10":"19sPZpbljhhRleuey5tFEzV4F4E2hQ4r-",
            "11":"1D-CnfkUyvs9FVDtLAQo_LueYzVVfpWFB",
            "12":"1D3XcqnwARKn6okE29wJAfePDaqr2HmNc",
            "13":"1FpmIdISKf295DUHaviZ1TMWmuq3nHfMr",
            "14":"1FzY6hqVFGX69mfOoIfgvwALl5kzWtvZ5",
            "15":"1GOxmRe3QIXlYWnJuu9l6xZOhdCFWuBjo",
            "16":"1GZjSfib_XmOGa8Oxwf9JYbkzCJ0Wj08U",
            "17":"1GzatCPihgBkHDxcn9x86fT7OnMgsx020",
            "18":"1HR19W4120n45RdY8BU6v5AeJPm4w_MxN",
            "19":"1Ksy7pgA-vYGjrrRxcuvCiFqRl8TvXYs-",
            "20":"1Ky2aylSnFgT0-aKa86MJcXHE9PAyPgsr",
            "21":"1N-9pPvM8YMpS3XPRo4mSzuaKo2oyzwX4",
            "22":"1NtaUhFW3F8C4u5lh6gI_1V20sRB7_SjR",
            "23":"1PAhhxrF_484vQzuLiQP54ZehrVqMwhRi",
            "24":"1PXgwmnn5EpmB9fDly5rVbpzqZyAAgMMW",
            "25":"1PcHSqD-jdtv2JyIrB7Q9OUloLkeX4EIT",
            "26":"1Q6KC-HeOep-AFqUZ4lfRZiRB2Hip6e_Y",
            "27":"1Q_PqDRSTlX0GYKBWBhiQ-E6B2FNQ9etJ",
            "28":"1RRcyM2yIbT7wO12tGjoyNXNf-C2bkkAf",
            "29":"1TtbxfWpaHihTbEbZU4dxr-Qlbqe2mL1h",
            "30":"1UQCUaFk-TTN28qmDxr5l-hOevZJ_Ar1A",
            "31":"1VZGMViIm__u0Z6Oo-FdEZknnavmfRalj",
            "32":"1b2hUI_S3d31rne4cyQ3MYwukEuRdmoqS",
            "33":"1cKjJ6udJCt0_wC6K9jBWjV_I9WZUi-i4",
            "34":"1eOufDUcrulchiJX9r5LRM85zxz8eVsOm",
            "35":"1fatKyGvbUxqdnIIx1-dTNGEKdEgykZpj",
            "36":"1gGZs7uINUlBfOIMTYDVvwHIffqw1GUps",
            "37":"1gLMourjPvUibgbsAZ77Nb1yxnt5166gB",
            "38":"1hF73Q3M3NERry7NqMzrP1dL412Vow5ji",
            "39":"1hGwIHDOgBDTqJWHSNK8wdEU8B8u2oEbq",
            "40":"1hMbPKQ0WYyDHNB_xelMCXWpfFPIKsa1K",
            "41":"1hNS5W84Jbqi1MMcONPz8h3u8jzTzZ1Ox",
            "42":"1haCyB0_B1nG34-LRJk-ap2w1WMdBUe8n",
            "43":"1iQSoxtCQ2x62HNOZC7E0F2Qq05kYa1RT",
            "44":"1iungdhPXfopQV1hIw6wFqM2tccqfeAif",
            "45":"1jf-aM3MSnHOY4tEElJVU7MESxR3Dq9JH",
            "46":"1jiXrlaucOFflBKP5-tcMmiTt7naRw1uI",
            "47":"1lw7fJkN1NkD3KE6_xTqKExm00UKg1tTn",
            "48":"1m-J0Gcsbq14YZ8vi2k4iJRWecEhInvmV",
            "49":"1mjrGyEnJ2TkJ4-gM73pQL6or81aWHURf",
            "50":"1mrVTMflGvVTUbMa-cr3_e5FTrI3Xfd-T",
            "51":"1n5_X3rHDFnR9nb_fOjnYy5bzA9SAthk6",
            "52":"1nOkhG9n6G-KV9Iz2Edg3AIRs1SxuangF",
            "53":"1nVrUWAROPfkK3tK-APFGWJKR8Z1QmW8B",
            "54":"1p7t91aZycDo0DwiLQ5HXOuyOEdAU7KYO",
            "55":"1pKoW5uGTLEl-jRdgMF_sKOOTpUQdXyyX",
            "56":"1qIJcGkAe-HyUWCAB3Zz9-WBThbOj3WHQ",
            "57":"1qVWJtOodfD2Ri8p5CEKVgmbjS-1Q9BGM",
            "58":"1sXzXU2sJ_VwTgg_FjwGyqVLasmRAALhw",
            "59":"1scbFGnu4kekLr6D-1IfRemyvsPPCGXCm",
            "60":"1tQbCKCj8NQ8S8XkosOQc2j8G4qxaKtmx",
            "61":"1ul0PENsU4fJ1fJAiAIVgxQVdMXxuRLoN",
            "62":"1uuBeLKJBuLzOdYHXAhk1zRmptnlv511k",
            "63":"1vW2SiWxqPCYrOTB5fK76bkxKACsWaS0l",
            "64":"1wM-hM7mX5guIyBuCzaxWReYJJ150KOXP",
            "65":"1wRJSS86PqmDCq3g4b0Zw2ZRnWfU6cU4G",
            "66":"1wWWyIAQoNb7B9YTlGauLiZviQ8wjKG4Q",
            "67":"1x7h5wZupjLIreOTFZGNiac_kdtSe2HQH",
            "68":"1ym0_JSzYp--pwA9fUS7oOFeVePbiLk6L",
            "69":"1zTP1eCQiiEl5WunwciJum4FCurJHUc8s",
            "70":"1zmdLc5wAZZgS_HHcr52KpNabAZjviDRy",
            "71":"1zpqyYTdkfylN_XwFxdqnSBoNyFPc3J2r",
            "72":"1-llEPRslyZOlJ1QAsZBIPk8IOANLytWr",
            "73":"11fLFaQcmFFWw0JGeTw7577CY8LQaTwk-",
            "74":"11hA1EgKaep0BIRmmRaUCc3i3rxw0G2-h",
            "75":"12DArhkDQ4UTM1PR8fKWymbP34_DCZYo_",
            "76":"189Vag5SdbGZnGVm-iEjeQdTsH_Xk6Hq1",
            "77":"189j0q6SaLju_VXZ6LAn57kGeVwex-j5v",
            "78":"19KQT4SxbLlaxbsIOQOFA44K5vy22BthA",
            "79":"1H15eyrMr88uq3c1E4NLhh1gSLagTzkKB",
            "80":"1HYKUEfEeGQoYV_b92CVNa49vatxeW1NI",
            "81":"1HzjmHY2cuhAFyhaZzFGrgspJbj6nCX2W",
            "82":"1JvD2rw-6T3oU10BvviqpqZfPj3iAKTbk",
            "83":"1RhtmTPzsfmlK08W5LcHK_vCP6mlaL2Ou",
            "84":"1RxIePWWfXbAsLQXiYccdcBPIXqVlzwa1",
            "85":"1XoX4sP3r5mlFTL0t1uHZbw-Mo2O0B6A9",
            "86":"1Z3y4wiaXJRgqtg5XNYyCKxHtfa8Of_Lj",
            "87":"1_gzHAS-McaEn6CberFNSpHtGIu_ntI_n",
            "88":"1aV4IRyDrUFjaFn8srciL_Al6UEiQVMDp",
            "89":"1fZs5cXK4MZOLuOMGVE68qlzYQ5EgEWrG",
            "90":"1grQkJOqbOY3qyOh8YgBgufIQJcVCxXdf",
            "91":"1hDkWNM1MpDhIMXyVUAbFjfr0kKeAgaCQ",
            "92":"1kDHtCOxowqx6nXnHJCAfzbgy4hMH81fJ",
            "93":"1okT_sXfDAU15pMNTmtxpRsYioiXhA3iI",
            "94":"1px-OgGINmD6S6M6q0E9hiHbEnsMWDOKz",
            "95":"1tWxyePj636035hOPNaIc4BIzf4OecCm5",
            "96":"1uEvfEOo6QWzE7lA6hN8_EFm3lyPAdJq2",
            "97":"1uxGgQ1iLCGbCgKtLyyJtYwxNnLSdGTXg",
            "98":"1wy0LOxp8QSGt5z-kHBgeVvR8f2AxM7df",
            "99":"1z6XhSdMqM78jN9dzYEJzBaFRNsa1xqAW",
            "100":"1zQSLBhBcYwSMj8uAPXCCHKL0u0pGToea",
            "101":"1-Dbd8vXlUy6ArHSt0CrzHwIWkOqR6CN0",
            "102":"1-P3me1gTWmwV5wCS3LhNxkmfHK5KLDe2",
            "103":"10PADCRO5oQRYHnsuuK4kh3XUeXG0x1ra",
            "104":"10vzdFtVOJ3I86Knu-HSXrDCjA00DPNBD",
            "105":"11cfJ6q1cqHKXdpEN3jxGKWutH4Ec9zjW",
            "106":"11frjngaVZciUtawb0lELpR3T8JMTKIoe",
            "107":"12Dzhp8USpxIikQY0rT4LuJ371HNCmepx",
            "108":"12QXZoMKf_H-BzmPy3d4i5Oj5flYr6Aew",
            "109":"12UB_8ylC8CrftzlaMCLX_SFldmZRFkXm",
            "110":"13ABynJHEmCLTPeaF2orjLmXnw-0guGLj",
            "111":"147DAXTC0H_bwEOu7Wk68n6UWOEg7-WcR",
            "112":"15_YtViK9lLM_ao2z1x9egZAtyVifFjQO",
            "113":"15aZB-tB7oxkzAB9J8DTC-6eIzRmPMzU0",
            "114":"15h47Gfxycb7Qk4P7GP14aUzh4kwh2ywW",
            "115":"17H7x_7WE2qfA-WhX8xLv1--Q9Xn0LDQy",
            "116":"18ctu4PL-JqiLlT4AikMrRin4Oi9S2h4m",
            "117":"1929d8jHFjQv3Op92TT2wL98CGA3wjxpH",
            "118":"19DWo0ks8XDXLStodFtyFxuUSsR3khBMc",
            "119":"19tCDLBcqTTlPkq3zrJRGWQWqo2eid6F4",
            "120":"1CSuHYOZQZ7vHAXqGDyUXqRqAfmfUA1vQ",
            "121":"1D_pEYWQfPwRt4U1sJ3N7skoHWraKKtEL",
            "122":"1EMBXxqMOdYtxvOk_wf_IRZy88-a9qpnR",
            "123":"1EVNB3PYAWfMEcON6TAANrDZww03iNmXy",
            "124":"1GSIJNDRc_G7RjL2B2PJX6mS0yUlSjgrd",
            "125":"1GUi5zurmcrYB1VqhS4d_uQxl_v-AO_4h",
            "126":"1GlTL-9IFeAjNAGxdpnROpNxBegi8elaq",
            "127":"1HwcpbNfZP04Bm5sxhpPDTxNJ-v_fN4Wj",
            "128":"1I-Y4ovaeLMegkVArUg7VreAPCJxX95Jh",
            "129":"1I1sOYq_AK4WUUiqm_W3-JQ8KtiPZbame",
            "130":"1IBupuS2CaAs6Hwlw-Ym9RCIfiYkVru-x",
            "131":"1Io9AKqJrfT_eJm1ZXnDQxDMKailS_YCg",
            "132":"1Iy63f4p3FZsyk7Dc8y4JycKZjiF8qweU",
            "133":"1K8nlEex06TJcyycvF9VUcdalxfXvGoaQ",
            "134":"1KibByefoNybhz7MVwhXAMKP4J_mDBUvr",
            "135":"1L3VqEAYcc5_5cpFabeX9paQhUFZPGOdS",
            "136":"1LBgB8wtbud4YACFCGJHyoucHubS7FQcG",
            "137":"1NkIqOv8y9I_c_W7I0c7d0PjoUJAZ6yWA",
            "138":"1NvQfPc_UJBaUXly5t8nSRUlcFGAMr5pG",
            "139":"1O80W6XZYTy2X9ySVMvrVD15mOzm7ce44",
            "140":"1OHdOX6715fduqDHNCcE6vgKAxEsON0NN",
            "141":"1Oj8Um6SF2L0QrXe9CuD0xJHwQEuXqCMU",
            "142":"1RAVduvyzMO5euqoCiMZqoMJPG9_VNXV_",
            "143":"1SP27YT7qnmM7R3e7z5iUCMe-hwXkIiJ_",
            "144":"1T3ofWUaGRMaC6BAKKwEPlETE2kCJtrh5",
            "145":"1U0WdSUrXQYm-EuGSG7MxvCJwDZts1b4N",
            "146":"1UZgLrfsbnvRcQTenAsyfqi7AESY1efhs",
            "147":"1UkLlrd4dd-i_qCuB6ch_MzBXUU3fklIM",
            "148":"1VDMjPvSkslj0nuQ04zlgQC-HoM6iBXkh",
            "149":"1VN3xrAPG5kKWcwKS4N3RdcYoITm1KOtI",
            "150":"1Vp1oHYmpTqbRXdv3Nq0KEx9KaLlEDpxs",
            "151":"1Vw9RraNZmTicjsg9c-mlsts8yI4oP3Z1",
            "152":"1WDUXO0Yh6e_0NwwIMIj7BxbhB73dxkKm",
            "153":"1X2Y_knLEsPC_uPv8z9EATYetIZkSYeWr",
            "154":"1XMzCL7HIbIu7KaXkWI8_rNcj09enlSLn",
            "155":"1XQ0LY86htyY597jAgiDb11_lw-uSUFds",
            "156":"1ZMj7gXUUwrMbPYd5xH4o8TlliJ1P5L9G",
            "157":"1ZbwPC3dgtRc2HECPTXxcKMlJEKCQ3b2Z",
            "158":"1aQNH53X1BmVl4-tAXE0zXiZ4-2Twyost",
            "159":"1b-yAZS-b3fky8p5ZdbhUcYX4YE50fbEo",
            "160":"1cNApmiZOF7Mrd-EzA_-zsKZoHTiSHRLG",
            "161":"1dN1OteM6hmlcfbID3VO4v-VZbs0ol10a",
            "162":"1eaoTMUiYvHJeqqvKIGiVUx8aGUwgE-CJ",
            "163":"1f-O47KcetMs_hvNp11S6_amcX_Zmlu1B",
            "164":"1fmBKnnEAHYckzQ9WP-f7Hfc5DTRMpwHH",
            "165":"1gOy0o9ktdh_-b50qrS-HbKgnHri3h2qU",
            "166":"1h-lCKrxL-tYGH454agGZm9DImXo5jKzc",
            "167":"1iHVlhMWyf_fGzf3UYQJVneAC9sO3r-1I",
            "168":"1ig4a3t_fv6l4sSSKHfYwRO2M5xKcjRMc",
            "169":"1ijaPaWPBGM5_jp6VcF6mJ3TMPtdTI6z3",
            "170":"1j-i6gkrvdwS8xJTZZwZUV2k0WUvDh1UD",
            "171":"1jBG4IN_rqDXExJQrWj56rkOaltYbwcm2",
            "172":"1jLPu0t2cLRTEOB5pgn9emKQ3lP6DZr9s",
            "173":"1jXQEh1ZZUe3edARLGCO9OvGsdOqUSpwb",
            "174":"1kIsKw4SXCWvo9Zar2-PfOlJfJcDqEO31",
            "175":"1lDPCeF2v7lh46oHuPq8NVvIkbi-C5W8k",
            "176":"1lS8CU5cME3IYbUV1TlXtIgM3dqhxYOPg",
            "177":"1lyYef-Y50EhhSj1WOP85vs8mopJmMbDp",
            "178":"1mbHc6UqOXKxxLbc3Xv21PUlgIX47GNz_",
            "179":"1mzVBHBrJ3aKAf7loA8gQ9fKNxSbSXYng",
            "180":"1ny_RmYcnuTV-4ijaCgwcExPO88xNzJYZ",
            "181":"1ogjX8yTdAQSP9R9dE4wOEVPRMgInwmvw",
            "182":"1p5UYHts-fs8HZosffRbYUi2rgnU6sRdd",
            "183":"1pWRAOREDk-QMCtwc4SSZJvwIkG_xt44I",
            "184":"1ph4wFxXYs59_CKYGhmsjaoGBVElRcwlA",
            "185":"1rZOthTkr9dqMvMYfSpwJrz4jLQQhcTnQ",
            "186":"1rfQjnK_4EYFb7DaWEUeT_Rb_b-1Ch13M",
            "187":"1rqtji_jk7DBGmDZziLPC8Rx4wyEP40b9",
            "188":"1s2TIcOuI5JDb79kbGpf0QTifuKsA27Nb",
            "189":"1sACHJKmWe6VnMkd0eRZ2ZwYiZA2YffKZ",
            "190":"1sHAescaNi5C8w-bOi6_M8_M6dhBgoKZ2",
            "191":"1t-GmDLiI3Ts0DykHwjESJeBJyTCaIoZ5",
            "192":"1t91v8tEl6VA3nUWkk4do2Tg0j-H6qwPA",
            "193":"1tbs0sg0A63f2eBgjdRPg1CipjSFYq_NX",
            "194":"1uCEtQkmxmN5iotQu0A-2tf0r8ahVifMa",
            "195":"1ugiVFvqK5PFI40vMAwwmHOsFYHmzSNxQ",
            "196":"1uxO0bqHLEqjQ3DbEcPX7vzTQg6w0KPBb",
            "197":"1wHD4sRwO0V2ojoKJIAKJI7YohxrmBKba",
            "198":"1zCXDjTzrSDvQenObAVNY87mx0mx0XVMV",
            "199":"1zcSEZi7aUOg2_oR_L8MpgD88ZKUQAspk",
            "200":"1zft5-zoIIRk2CGTuG3xbQQfOWF-zTbYO",
            "201":"1-YaKXoagLK3LRkPxhJ0ZPw9O4jFkRbx3",
            "202":"10we_weLioYirdVgb-Fsh_wCCJXU-O2wU",
            "203":"11xVaO62BijTrRlcD1hvhcp_tCrqOvKpT",
            "204":"12ghaaFqgf9ghIVs7jkL2w9rSSOPAWr6t",
            "205":"133p47_C9Y0LXuQJl0PIDGjR2sUTaX5m8",
            "206":"13KtpccEuZ0GnFDF9gGKNVzOA0kXKz5jk",
            "207":"15t3gKWm9AesI-YErwDZ_CIfaiMsN6Qjw",
            "208":"17g-5iO71yEZZ9mRcfOmK6Q18mkO8-Xde",
            "209":"19NjYr6py_xZK0vIEaG_WLQIiyJ-mvD4K",
            "210":"1AtswlxaWMkkRzLLWXyn7WFdCSGqMEizo",
            "211":"1CZG1yL5R6m1knKIhTco6TzApW7tGMgW2",
            "212":"1Dh8kgz0S79mHyNc9Gs9macKFMJsXq0tS",
            "213":"1Drs0KooPlF2rGsmoa3Nwz4YejY0ugUrb",
            "214":"1E_Ou9bg7pS7lwR0F31Mks769_J1iGihS",
            "215":"1G-9zK7raYB9ysn7P1bmeD55bjONbOLQd",
            "216":"1G7wgwbfnBFtXJK5AyC3k-yzE1Ob_XyEW",
            "217":"1GB1FJRtA27VvYxDPNvnlquQboQbN3hPQ",
            "218":"1GYONmkdudtGrPfAgFzXhN37PVUQmtY0v",
            "219":"1H2i9RLQGFWoOr86b6WgStHHa70qF5R91",
            "220":"1HjN4rx9_k-OuNLq7GNxQBI8ogbZXuRCb",
            "221":"1LLVGRdng_ju8ZDz5uA5c_BSvXmmbnkce",
            "222":"1LRcddDj5s93Zn6beeV4tAW4Zyh0e4zoT",
            "223":"1LVXF3GBrdK98C_CmTK75hfs3p4IRsRl6",
            "224":"1LvZl7-oHiBmyPwfERuNd-JITDj_PEcI_",
            "225":"1M5VWjxEOnwDguH1Hn5Ck7l1lsT2x45yi",
            "226":"1NJQfzyg3T6oMuM3HfFA8cLQd28Y2EWas",
            "227":"1QRV9JVxi0tLtzDo4NoC7br44TwexlTP3",
            "228":"1RbOgPpa-wixxR6RY3Uu0uoY1CgUTF3l9",
            "229":"1Rs60bzDz6IrcQtWB7lFqVP7IeLWk7pBQ",
            "230":"1RwElP0UhBoRYnHZ6uOOC_41lhPqmm7sv",
            "231":"1S6Afjo8-dcJ15lZeII6BVi-fBtgd19Vk",
            "232":"1SCX5n0gd51o-OYrQ7hhJg1HuQ3WsIpx9",
            "233":"1Sg6JWL0KJsFxdTxBBc_BXbypEDzEXB_w",
            "234":"1U2GMXd0qrRgOsAg4Xs6biDmgq2vLSzlX",
            "235":"1UUSA4GzsjenFMIDa2WSLxgo2ZbF6dhUZ",
            "236":"1VMbTiEguPKko0mr6rQGoHXUvXszx-tQa",
            "237":"1WKO3yL3z3UidUeABi8yGYj_er79a5RBm",
            "238":"1Yl0GqmWDrDcGOSCC_Z5UCkAg2W8pDoWY",
            "239":"1ZlTCgHWKvV-pHCWub5DI2SziVULL3C6D",
            "240":"1ZxxPQw6Tuy5YTdUin8jP2yGkbrXtMl7A",
            "241":"1ZzuX1v-PdaQy4kNyTnroGQU0nuT8IM0E",
            "242":"1_D384u8wcDtaVdn_Cd_IARKmGoi2r1-y",
            "243":"1_kH_hZEzobMEnwyEt6xH2SjsQESD5DZJ",
            "244":"1aOCcoik6jHUZuzIQw10kBxkRibuJt3nw",
            "245":"1ajJ2TVXlzjXSF_cuTRVvTidMuJtWubn6",
            "246":"1cE2Y1sm6MNQ9uyAUg1EmZayMBrjZsFzj",
            "247":"1dHTEx3gESUo3nkUen_MeNuiBwpwHXWZX",
            "248":"1dIyNbHFxb7H_YpUW-DrIti1q3_Qq_1si",
            "249":"1dvD1kcotlQritT098fenadOyu0m9DGPe",
            "250":"1eZ4oZWDABe5K7Gib0chOjmqNzltKuD0f",
            "251":"1etiEMAYfBSj2RywRSiVKtRu0CmI_aa0F",
            "252":"1gAHK8Q8s-sy6CRcqkjhZh7E_XS9TG-vq",
            "253":"1gEwLumdwBbxulFwFz7JR4Sxk6T7-5p8_",
            "254":"1h9p52ZxfK1kLcTnfxh998zB0zrVpNRrA",
            "255":"1hVsOw8fTNLpT1o_TbSttj5GQeI6k5RvF",
            "256":"1hZx6EehOAGN-A7SGitHT6qfutrwTSsnZ",
            "257":"1huy4Nldr93Xgnxa-mzCtmaylWpEbvQ_Z",
            "258":"1kn6imRwavVZEyxp14W4zGAdGbSMz2Tr-",
            "259":"1kzh7o9U7vWnOLaXAl1ss2OzwBk7L2uoF",
            "260":"1nKqy6idFU8nQyDxLkCXiWWTNQVfSNvu5",
            "261":"1nZ0UVFoEF5YEFQ9r3pFRN5nZBR2D72WB",
            "262":"1q-z6hVOluMmh74TVD_vgmq3DYKzpJLSv",
            "263":"1q5HhMbJWYAoqpmvw58q3hN5K91hHNw3e",
            "264":"1qq6KkoF0guTMjhPHacSihe0MY-6xLdOH",
            "265":"1rrg-QA9YqUPLdXzRnWGslRmSL_c_3RUQ",
            "266":"1sQfdp7qLf3zefAJjZzzRMPERDfvZ2X1w",
            "267":"1sSeUpdcmHIYB15izhSKiqUGTHGL9zYW4",
            "268":"1tHdlMXsFa4WfPT1dXb3Ps_qGOahiySF6",
            "269":"1th4UPrXB9NwLAoCkTLV3Q3jyoLe9KHbS",
            "270":"1uod1xYPjp9mhwtvAdEiMWFnflbQ-rh3c",
            "271":"1vAyKIsxP3v4PaRzkHWaoCAnhEpf9J1l9",
            "272":"1vFujymmED0ERLG_rsGVbT0uF9AzS-1gl",
            "273":"1w5EZj9ofJNcBpF5qzRoCApcH1AXUEWd2",
            "274":"1w8d2IDd7PNVblUKVEAJjh7mrgAm24xHX",
            "275":"1wXCak4-kvrQYjtMCQmPSgjfl78TtFG2I",
            "276":"1x1_OotwfNoNRuAZTPaw_lyCsvRZk5Hcn",
            "277":"1xSRWQE8L57csnW4iJnvYuYy0w-Zm9PpK",
            "278":"1zhkClRYVab5yOqHELL6KdqM80KXbndOf",
            "279":"1-wR0sjuAd3sVLsETEgSzd0KR8q3myId_",
            "280":"10GIsi8XhRM069WWfCpiGATXMlT8j_yc5",
            "281":"1AofHhFE9qwchveasIKPsasbcCVZCc9Fc",
            "282":"1D_gu5OdkxjmoSvrgxLDl08YhzC7_Wosc",
            "283":"1ECBqacWtnsRvYGOBZcz3lgnKW7NjnMdn",
            "284":"1Fa1YH8UjPjE2dyhDWLqB9IiPthT3QDK7",
            "285":"1I_BKukUYdCs96Ar3fIoK7clJCmxXrDTx",
            "286":"1I_vDJnD54DfDhuofEL_OM3OBpmVAy2wJ",
            "287":"1O_XJ7pREL8KgRVcCgorcNfHzOXEssgz9",
            "288":"1TRMdwB_Kn0YlMiAWmic0E7FFmuf-ZoCP",
            "289":"1UWakhhieaNLy81Ps5PLcJs2r_ur00T_r",
            "290":"1_c6izBur5BGPHdRJiQevL9yGDEbrz-pS",
            "291":"1ijux9otXsR6WgwVEktho2PZc9its1l8_",
            "292":"1nh6UE4sJrJvCKWZ2nRo8GtX3TGkhgGL8",
            "293":"1qgER1FPYA6M-nrk3UvTWYIbM7upvDNNo",
            "294":"1t02FbtzQ2zrN-7XWf9JxRWRahBCga88e",
            "295":"1uYbyj6Ovy7yZw_tjHFN0A8qmUrRqcZGW",
            "296":"1vTCEZDi6wCA9GS7CmtcUoq4RPVT5dO6c",
            "297":"1vlrGT1hATG3KCawIzFeILActpmti-BAV",
            "298":"1x-HLmeIVTRKCGu-4sjBg6QUVkS5UQ1i-",
            "299":"1xGzYUOg8szzqr73FshqdkFKahoyOwfVg",
            "300":"1zX5_cuBxN2CLQz-rXI_Ec7wkeMrCcyNV",
            "301":"119FEzAzsRd0N_exaoY-gID-9NLGCzJiF",
            "302":"12KfLepbVgBjjPlVnNepmyYQSntw5LFaB",
            "303":"13l9mwemKEZNHlFuNtKzu2JW4G32MzEZG",
            "304":"141hM_UzQpUG7Hl80yWM3UrkcCj3ylPrn",
            "305":"14aVN_GiQWZd-ZBR1mxZZuC3g0uFUgzg3",
            "306":"15B74kx6vKLfISc6bVkkyC4Ml5zVW7Wo9",
            "307":"15icSCi7xEyTsVLGtne5Pmo8EaXeV3sC6",
            "308":"166Gvr4PXyyIvEw1H0MrZYUG2Clhq9Z6_",
            "309":"169gC-aYN_Kl2taAWgQ2G7035ou-K1X9b",
            "310":"16GJL4JZxIR7Og4Pvf1dsAIKewrbctryF",
            "311":"16MAzoor7I3CCVnsVbW2sTwj-y3gLu99T",
            "312":"18IiFLv1NcXenTciSq6vFBdF87_tsOWRW",
            "313":"18eVzVbYZpySI8eTeTleUGeR68EAt-d_o",
            "314":"1CvtCL12rjbjlgJaU08aH3cWZ_UPTydEP",
            "315":"1F53fcKa-zddTvTEGgOBasURdmvkBkHVz",
            "316":"1GVHX220eMYO_FoJ2onP1dos2ci2jQJen",
            "317":"1H7ftBuJKOLIxdJVx04WUkqo90hawGJFj",
            "318":"1HG0j-61tNSqbk-YDOPV2oPe5JBTJzOtO",
            "319":"1HGGoQVon11XHKOwl0MJ0bmxA2RgDHOnb",
            "320":"1HGURF6rU-ZDmXRuS8vAcZyvQFOXFQCgU",
            "321":"1HU0VsA4V82mOD9xQxlAxfKZaEVDCsC5M",
            "322":"1IsffkaYPtajYjBcLdseZtRxsq65di8ru",
            "323":"1JoHCAAhdh9LfwICt87dINqrARID_c7Lm",
            "324":"1JzbCAb4lCEvBs9bJ1ur2DKygMn2IiBzd",
            "325":"1LIIdKJ4tFFVrQNze6S1ljLfvQmixolmD",
            "326":"1NP3gO_7MllkPt1fyUXoTeYY3NxHnm0-W",
            "327":"1NuHLNxV92C-8GsiD1M4sTR_Jx2YmLKTO",
            "328":"1Nw6JB-P978g98uwKSqCU7ongbObuNYB5",
            "329":"1ONF9VRM22v7R80sfv7vlW4ILwHsoaZjp",
            "330":"1OSPVsy0bq6QnorNE_QhclCoOVX0vQa3B",
            "331":"1PaQLrHCcaMHmNhi4HHgcvRwISFJsg1mR",
            "332":"1PaUckvgJD6gTm7QB75dBy_XE1lF9jjL8",
            "333":"1Q3wUIuwy0bpvjzIz9ZIrkJkyOGcuXy6o",
            "334":"1QFD_4xKrnbNSzikqv0HVQ0swTDW5cYhy",
            "335":"1S-Us_d90bUA0y2rr8eF4hHLekCqPbWma",
            "336":"1SCQDBSl4Hra4d2bBiS_EHJm8NHlyTR-3",
            "337":"1SdSTv9nHvEGpGYbqdUmS3hdAg4qCnRGW",
            "338":"1SiWZBi-SGAyYwN7SylJgYXhHOa77tHSS",
            "339":"1TXRnxOnVfgjHcx4OhlSVw4a2FPJLViTm",
            "340":"1TnIj4MKAfTX5tpWF3-yZAXoodsA5VW0t",
            "341":"1U9F8F4vpoq0LgJ3h5qT-u5xXInZ-uBuH",
            "342":"1UMNld53LJZJUgIXN89WeTTuBiLxjE5l0",
            "343":"1UkpilSLjfNIJta7TsyIuqdnF0JjTcfGm",
            "344":"1V4BRNM_7uwjmXK0_RoPQOcWou0uM_c8X",
            "345":"1XtdhOXlvVBnGrZPSBsAWVIxxNrVqDw2Q",
            "346":"1Xyg21m5jMgmQK98KP6yL8rObdQ4zBkjl",
            "347":"1Y94DpAm7sAzPGwypCPuiEd0ZDhqEb7b8",
            "348":"1YTUFY76bzLEmIXtvV63HSXIqD63IAuP0",
            "349":"1ZcRekG6Jhuhk-TewNyRPCCEvQaicMKyK",
            "350":"1ZdAp4EXWXOmfvV9h1ivJ0ie00K22NamT",
            "351":"1ZdCbYPyTR7pyaucYHoNeMJMgximqX7f1",
            "352":"1ZnLnXfEfXxqZp0e9CarNXfFVl17yRXCs",
            "353":"1_aXO2f6ICIQAvJabcUYsy9J_v6pM8Nvm",
            "354":"1bNdMZABaP2fTy6fCQuETL1Ye4W8mul_1",
            "355":"1bxMyLU9E07zj7dKCLYh4VJeDjjr-WdTs",
            "356":"1ciecJpylCn9LB4OP_Pv2n0_2pYY0GSA6",
            "357":"1d-XDcEFwJXnMR8XQvSJH30aQ08G_5e3t",
            "358":"1dBq_iPrVgpYJkf7AvhAJJptFJt0KNmM-",
            "359":"1eqWJlKDRIUlL1UQJTd-mrv7zSONdHLzV",
            "360":"1esWXOo0E_4ejmH1SKtAlgWZpsaWrCot9",
            "361":"1eybdP6xsNQUEEfzqys0eiAu9fBYgoYsQ",
            "362":"1ez5s4YKxqx_gcHg2-0wuGd8MFyrMBSNe",
            "363":"1fDeq7dYqDZ510Z7RaGPzJVrtm-mwido1",
            "364":"1feIOZqhy_-ahTaofN95GUIVLYDUPieyV",
            "365":"1gfd7Ck7Mpx5kqtCRiWu3GUyNOuol16w2",
            "366":"1glfJByaRwWX_QBOx6S7jM6JqiqSJs06E",
            "367":"1hTHkSqxULv17gbN8UI-PqA4eWyGY7_di",
            "368":"1hhC161dhDF8UuFycu-UDYRtPQ6JYZ8_u",
            "369":"1j0Ol1exIfZQPT5G5v52XgxU8ufq01xlo",
            "370":"1jmTRV6nwpfLaZg1gebYLqYW121imwZEO",
            "371":"1kdipbHQ6cP0Ndg5WeuEUJxidVvGhOoqn",
            "372":"1l6ovSL1QWhj5J9vZLNvyV6ok_AEOkI2p",
            "373":"1nMXaZ7kMxZFv7VC8On9CnCZaFbJhyaI4",
            "374":"1nUKsNplTDIUmY1Eq3UlR0aP-2yIhfSia",
            "375":"1nnoO1cyXwMgZGthLxrk6_Ku37ItwLm-S",
            "376":"1o5wqOLtVi0ueGjJIogWmdplPbMKmSJi7",
            "377":"1oT6qe2EYjkgqixtYLzqFBkt0Q8Ggmdq_",
            "378":"1oXVFJjhQxwzKmxXCILJQWZumIbUlZ1Pu",
            "379":"1p8V-JpzdELudWrGqCnBObUUSxyNwz8Ra",
            "380":"1pKeFnoWpE1aC-z5ftEIa7D271Z2eGAvo",
            "381":"1pmpWmtBFoBTSu7A3hjKalvl5N4soLjkP",
            "382":"1qRh0Xswyhu3hTBvA_CTOOSC2l6fcmw2b",
            "383":"1r6hOj41nsihjMsmhlld0RvJ03Btbdtm-",
            "384":"1r7SiyVQ1yYUGQgcizFCM8UicARr9wIQV",
            "385":"1rBl66oAUjmE97qijUrA6Bm_FIrA9GkT4",
            "386":"1rvf0rQRlbFyqOZCmWF_wba8UspUW9Fkf",
            "387":"1rzDl7luB0xJf85AjVUkGJjBRaucRteaZ",
            "388":"1s3gVqhrQhyJ3Vx3wXU-2E-a0onOxspN0",
            "389":"1uGZm8lFFSs5IA87DRr2t25ytqH0jmglk",
            "390":"1vYTyyBWcH8nF5Wcj-lqMbFg0E6djXXk3",
            "391":"1w0fhWM5z9vSx66toaVc9alDqHAN8V7XZ",
            "392":"1wQSwntKq0z-HgDuz1ZGDv6ra_DsCHyxQ",
            "393":"1wXJCulMdOy__IEo4baZENodwnGFnJNpW",
            "394":"1wjFyYP-_4zNqszM8iXY_Sdgy38rncgO7",
            "395":"1x0Ym4NUGvV8hB_h6tGiEGr7ZF4RnTLZL",
            "396":"1xL5wWJ_mRCJ3jGVKDULnDLG4u4KLdXZk",
            "397":"1xjREo1VwkUdrwqjhzUHDTSDb_HIr9nlw",
            "398":"1ycv8C_0Cdk6k7SWEhtZC6B6nsC7FwFmq",
            "399":"1z1cfZko6OQoSCVGG6uagKKx29yIj7D_K",
            "400":"1zlF0r79REIfBLy_9YYz2y_f5_NCqJlJU",
            "401":"1-oe5q6ItrKY0YmR7et9V6Sbg7BL4ctG8",
            "402":"106P6ce6idA4k2czMWEqRNUtmZ5W5cnP4",
            "403":"11gDlLyjb9Mw0HGtwQMDnXxpy7Zhn4lrc",
            "404":"127oIxCWTAhMk0Y8K0ARhrqBxA9PZRKsk",
            "405":"14C-1sXKu6FtfgWRp4iOUuKz4xy1xOjj0",
            "406":"154pGgxaYEL6iQHu_s1ftAwAwpbtuCY1Q",
            "407":"16TqJCaTYa9XmS0PWuxbbWe6ABhgiuIEw",
            "408":"16VbAfXnSKrs7nn_Hf0drBVbDmiQLCdgm",
            "409":"16X0iWvV8enLsnepAJjzF_x0B_MWE1bIu",
            "410":"17T1tMFhNm_NwWVgvc2DS-BjAvu0_wXdU",
            "411":"19G-fKIiKVjPs5mwFiolX53GOiwmv8l1Z",
            "412":"19cwFbDDrygaS-TsB5ShgqKS2Loz2Imth",
            "413":"19wJ6QXdMJbBnQI63mmvGMyeeHe-UNtaH",
            "414":"1AjYeFICrv2cBoOXZhJDdxXpCHEaF-4aI",
            "415":"1C95XiHVuqjRc4goo1KG3nkXJMUpvQqaH",
            "416":"1DNNR5vcCnUonZYuPxFew8h4YQ4HiIcs6",
            "417":"1DTk3xGAB9bFvt0MuETWvF2korDqwW1a5",
            "418":"1F2o-zI1NWV51inQp_jore1iYxXD9hKBe",
            "419":"1Fgy--jPhWsf_VWmUB5yFLU66ZJbjHrM7",
            "420":"1G_dYapRDV34TtVFb50WojbLW0xB1v4Xx",
            "421":"1Gvu4DBZPChnbCskpT7ZpdarC-mXRJSbq",
            "422":"1H-eHXxEOok6VKnD9Kdu97qch99HvENGR",
            "423":"1HBz1MtzwDP92fHVTI7EoxQi_Dk-Tl0E8",
            "424":"1HYsN72-Ga3SuK3mYLRc5x6zIG9xqAh-u",
            "425":"1HmFf9-YU4paXwiybgXqSm4ag0x3n_I8i",
            "426":"1HyIHSpEdc0eLBjzsa0NSZYx3VD6AfQar",
            "427":"1IOJQ5bm0KOta-mmqP50pP6o3LjYAHzjQ",
            "428":"1J1-QfKJlDJsDWCzRyoneri59lEvPhw_m",
            "429":"1J81QErpLWjfhy6WYNfdtVpLlSMrukM94",
            "430":"1JEGvkNBTyivDcmHiUBIUAb4t-caTWWY3",
            "431":"1JftDokMul60WbjMfhPnkN_EktMTYQnny",
            "432":"1K3zJNVLXoOFz4YiYIDD2z1GSjAiITBgg",
            "433":"1Kr_6gGNBP4EDRF80Ha4B0PCTo-xhAzYV",
            "434":"1LZeMchYcX7SQ5BvtmrITG9es5Eys-7kW",
            "435":"1NDjydoZSBWG-7MGVd7LyMZmLcjPydKND",
            "436":"1NqH6-vnQTB05qnWfaoZoN313L4OdLlr8",
            "437":"1PEa5jrUqGQXQdm2Ydi9O58RE-RzJV352",
            "438":"1PSZnr68PHlw31w5qYPhSXXfEwuneV1vN",
            "439":"1Pt54CywRYttZLn_LFedmqyi0ym-aBI7h",
            "440":"1R1jtCsLMn7ghOQGR5rXAl3NGGZksZ7fb",
            "441":"1RbvGkGX9Xhz3N7N0Gzi_Z_UpXJ2lM5II",
            "442":"1SAC1TqPuskJUS0ZRjoWwC62Rzk3brcWj",
            "443":"1SGmh1LxqyNb3FBtgqJfqKtarBU3JijSn",
            "444":"1SMYbkaOeBrrDaMnupd47rosWq9JUeQKn",
            "445":"1SP97FDestOB6-YiOQnhlhix0wln0vn-F",
            "446":"1STkQVCbdmkW0nyyUntBOyf4NvyZWgy4u",
            "447":"1UrPt5Cr_Z-JtHz0c5j9a3JpPJ3C6lGcC",
            "448":"1VA1Hsllr1uIeupNeUnBs6lUi1YAJKHsM",
            "449":"1VE9YfNyYbUkbf-a7qhYR9lnvW2awo8T8",
            "450":"1Vhs5rqI7EJymW1QCennliJ8GEYxVT6Dp",
            "451":"1ViMKwQD2gj3Q-bMjLqUob2HjPZKxlbyl",
            "452":"1WQSQPmsW7yULLOxjKsoR3BNlRJMWkY-8",
            "453":"1X0594ZHkj-hmMYl7roPkrNbu8Eq6j4oC",
            "454":"1_RjXB1VIdGklTAlUt8GbGA7m2kcQXfDv",
            "455":"1a1r2llAAm474gCdxdSFuypQ6qsH6Awm-",
            "456":"1aqJu2N0f5Lw_Y699cv4PRdR5v0dbNbTM",
            "457":"1b7DGMdhujjZTcXveYUrcjJtA5p4cYRRG",
            "458":"1blrAJ8IVm1BMHMWI7BRhpcTWawgjL--p",
            "459":"1dIC-Jn-79tFctPFX2dFJm5Y_690gbyST",
            "460":"1dgrsy41xcF7KNTfdFqpBPUmKJddz6c2Y",
            "461":"1eEgWUYeFlIendunZ6njuBjwPY2p28DiZ",
            "462":"1f26McXFShgJOHvB6x5pHkFW5dK0avheY",
            "463":"1gYAe1J1aD2DwIKtl7Ahy700nhIK6h4nZ",
            "464":"1hA0k0opBsbw1Jq7o14YUXZgY0bmVwXye",
            "465":"1iTMCD1XivsWu5Wi-t-w7iQQBk0uOgklm",
            "466":"1jtexYe5KRf23StyuF3ZiCr3g_2vs5tHw",
            "467":"1mf_43tyFoWg_L1Tr8MEf0eg66KiG8X33",
            "468":"1n3azxQVaGgwuc9lVaIIJsY5Oc69WlXM8",
            "469":"1nMJiU1sAMJK5LD5kK5MtM8pJYVVuuRIG",
            "470":"1o1qwRJyds1kqeEs5Lm93mYxU82SV8aTd",
            "471":"1o3x4TbAscOrs2xJd0mtMr03eqHn-8YxY",
            "472":"1oqtQIKEIFFOTWouPNfQ4j12g7H-3jffO",
            "473":"1pZLItx-qElAm_OvpOWBAaqpKpnTRPgqi",
            "474":"1phSAQB1oGwkW0Gdc9HtH-y0kGTD23FuE",
            "475":"1phttzgn6-TUtumkyv7Gi1xc_vTdnqSVu",
            "476":"1pqtmb72gcYVUQuj2RiwtpUDfy-vfTZeN",
            "477":"1q2btgUoUJ-NneoTn_qhbtqEUXNWpzFwF",
            "478":"1r2w7IH_pn0UT3nVqmjEK2O5Y8c3xXIVt",
            "479":"1s5yhU7aq8mBoFTLzVo3ZkzdlJY4VdbB8",
            "480":"1syOhF5kraNtQ7i85J3wjrTZgjsKFbEwz",
            "481":"1tpp_Eq6x6LNy2J61Sv5JFVOXT7Cc0GOS",
            "482":"1tqexzRxNK4-Aau37179lMc3M7vpX_Ham",
            "483":"1u7orTWk7s2DappqUNf4d0VeKUVSXkpZv",
            "484":"1uM4oJlbSDPRZ_HskPOQtXUpnL6QddL4v",
            "485":"1unR-GZQ80OfeiEA1eUhbOJPjfxUcqGmj",
            "486":"1vxpc-nn2xZ_yZc_hBJGLSPY42rggz4PT",
            "487":"1ygifSJCam5QP35c4fukxFDPR6OTPegF1",
            "488":"13s3_ORbwkEokqpcwTAHPwvkYC1AL2xou",
            "489":"1GjXOoHgqBtu6RNNi047lZoWkliJpaffA",
            "490":"1Hr68Q2GfPve0Lcz116xD66EwjHpuUMhn",
            "491":"1P_US1FqMFAKaHSjSrOU3kWY9-t8nxqQ8",
            "492":"1Qb9djY3hLhLCMIlk1ENBzSM48Eq776mk",
            "493":"1U-ukyKxcgcg4V0jc7_B44rff4YElaOoD",
            "494":"1fFHSYCt7RWPhtmAKRpYowd_5lgj2Uiwl",
            "495":"1mUiLFwGPtDyP1TWW4alrTjT92hCUgd-q",
            "496":"1nLVls-FkJ1G6iOMNdBbQTHLnaIYT379P",
            "497":"1qK1AkYnYMXqHMkss7VL7jGtlswAEWgdu",
            "498":"1uJx7958YNLpCku5mfT21qpk9m95Jx7nW",
            "499":"1w3pkRq4VLfudC4yFFu2e2YCKs-BVZDKs",
            "500":"1zJMgRmkCACrbNKbMNOxnlplXpNm5d-ge",
            "501":"1-lWhaHPgEN8w3ANiWLtGRBrTlWcMUkBl",
            "502":"10HTJ5IS1opng71Q10lvFz2NWmnrPSAzl",
            "503":"10PZaOtTg0o6rgIPAWYZ894CV8WUH2guH",
            "504":"11lZG7x2hAsM7GOrZTpUo1kjzSmV1HFKL",
            "505":"11y0GryQpK9LCBKHbaP7hI4Jlx1RhUCNv",
            "506":"123awDbOeI4z6eZvZoOyR2quRFvecsXrX",
            "507":"12w1F3HoFuF4VJh01XHHXRkemySKtiIw0",
            "508":"154v40XEl5bKu8jMSAPHHgen9t6xi2h0f",
            "509":"182I0otHaTZyQpUVmlYs4xJ5VPPLhrdgV",
            "510":"18Q6BLBX4L3vpkmkxSacwXp9_-TAdwxU4",
            "511":"1A_njtRhiR5KUvLk1nC-f7axRwO5igzd6",
            "512":"1Bhxk4vFYVfhZyzzvmALV9PxB_Py3GqvH",
            "513":"1CAzMuDOZ27HtUE-fgP2xuW-Z05Wbouah",
            "514":"1CDDdiDHZP1JLJOsl7Fm6EpGk33dJSerd",
            "515":"1DgBEQ7ZmVoqH_ty_OA5g8vnJCvu-wSC4",
            "516":"1E2KUrKjxr2-yNbt8EPsK71Fifp1YpbkG",
            "517":"1G_Txvp5ykcQU1Vr5X7hEhA01BPZQE6B_",
            "518":"1GgtvzzY0-EM0JJgMfI4a3sfM54b75TM-",
            "519":"1Ipwk_6rc323YWqz-c3hha-0dnsCEDOd8",
            "520":"1KZpM9_Iq0IBuHIilX1c7EggNGBz7sorN",
            "521":"1NbeeiJoUd2ITIfzZKuPLoS1OqzYyBz8Y",
            "522":"1OXun_fjfupuEm8axK7msQ4Egd1ygFMBf",
            "523":"1OYyfqiHBg1sOPNzH20fV9lTFwr1KHA8p",
            "524":"1OyCTET31jSy46hzkoxMomHQxhs6GKp_G",
            "525":"1PJfG71CpnjoimspPhIAHljU6ZrzR9n4l",
            "526":"1RU3d30qsMCor_WPZzXbhkQOxkujTnvNO",
            "527":"1RVvlW1xGFwiGx9sWEOiE2KgVFJP9HoQB",
            "528":"1To9nQZvhpKx5ADLkLIOeQ9NEhBYOg08_",
            "529":"1VHjswwRVWFsW9Ac2uaZjdlJhFaE6Stsx",
            "530":"1Ve1hMa6AIFf8o2uS1Dr-GNsKNNSezeox",
            "531":"1WLBkwid7uTAgFAeGujnyXjdPfIdOyNzm",
            "532":"1WiNERNG8mqqRZLECyLQaDrYrsnVlXeD9",
            "533":"1bJ9W_-ImjWjEhublh14UjC4Hd6RQak2t",
            "534":"1dTnGXM4hhVFfVt-_PMfNbs0bsjYxPZ75",
            "535":"1fDSKRgxpYtXXhy4WxWTN9lfnJsSF2QYM",
            "536":"1g9rKn03YC11YJytvtG7QwyeWp9P5WDE-",
            "537":"1hT6rBCDpb4shegS9SDak4aoQ4Vxa4tFK",
            "538":"1iBKjchZWay4OwA2Y64MUa12zC1kGJCUG",
            "539":"1iOixmhvHTazjitAQdlt_TMkf-8fo0sg-",
            "540":"1j-E8W8q6Dcf8RCyrIQkxZLbDzrfZOMgF",
            "541":"1kgjtLbK3lB_OI_a5ywkFs4EnBLWkuUQL",
            "542":"1l8GKnbElVGkhzEfQL4b0ba_5ccTXr6Rs",
            "543":"1nXJqkXL_7_LNh4CL_qj59N6JjIL2PrQM",
            "544":"1nzkQqegh0g8LWTYqp_vqxkrIKGi28uHJ",
            "545":"1qjByeXJg8aHR4nuVhfcT8Xlm4KXRnzK_",
            "546":"1r7Fivb7uHJiTVU9nKbl1QuCtBRyllpc7",
            "547":"1rKMzbk1F8F7HlqnlsJ4b2TxTozOotHX1",
            "548":"1t9b3tG3MFZbnzSeVX5ckNha4CsgcbRId",
            "549":"1tdukHovfPhj_f4dJIIx-GMErfdfQNeCa",
            "550":"1vIF5F0VgNIp7L9c_d9SsRzjmrWsjclSq",
            "551":"1xmC_1bxQtN3ze7eH--SKIgD-oWFDewuA",
            "552":"1y0TBpD0nW7zeQfkmNjRGPGQmXC1IU1jU",
            "553":"13bBRVOWJ1ye4GKl3sPh5S13TwRA0ym09",
            "554":"19rK6nP0Sy6_-XKxyNUkPSe1jWsJ1tT9H",
            "555":"1Drp6bTdn51Cz41TkRJKmcOtbpIpGmwgR",
            "556":"1G91xldhV-_anOJqLWxZu2Yb_NJq0DN4a",
            "557":"1Gol-5QJera73McW027TddnJA8TCfKkqB",
            "558":"1IDqrJiUTxJPZ_WVmcVvBUge52r4q80fL",
            "559":"1Lqg-no3X5yceTZKEJ203nGjPgxTGuQbd",
            "560":"1MmsfFgMrYPtUXXBZgUXsXVnIfGifUSBj",
            "561":"1R33XSh-L784jv5NrwFzAq5XI6p25saHI",
            "562":"1VZAIG8XOz4oPjJIaeKFxMD9CgxStNh1r",
            "563":"1XW7BbcXxRYTPuTl2Xen6AWO9m1-HnXfm",
            "564":"1YF-2oSgfavHJ0KX42ozTn-7lA8c3yGyN",
            "565":"1ZPTpQCwangIdFsPvYLjIqbgSeO-YzOeG",
            "566":"1_7SaX7InFaQoZCaUtuWMu3S-MlouU4QJ",
            "567":"1g33TQ0I4DCYiST5KxyfG9q6sdWosxdQG",
            "568":"1mQEn7ciz3muAYhmnTcOc7peDpmZhadIM",
            "569":"1srV9ZrcXQVlsdz39TlINN3pK4e0Oeb6r",
            "570":"1vqe2w1L38yNJN4YyGd3MlZ7syZKFVKaD",
            "571":"1wJJmoPEkLsM-ngsrUTxw9XS_2lG58fAT",
        },
        
    };
	
	
 const photostockData = [
  { id: 'isometric', label: 'Isometric' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: '', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate("/"+selectedTablesNativeStock.value);
		}
	  
    }
  }, [selectedTablesNativeStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src="https://drive.google.com/thumbnail?id=1yo50ISSIxzkOd8MYjNyj-s76m9XFqsWF&sz=w1000" 
						alt="Home Alive Photostock" 
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		  ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nativestock_tgi;
