import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logout from './Logout';
import Select from 'react-select';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import $ from 'jquery';

const HomePage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);

const capitalizeLabel = (label) => {
  
  return label.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);


  const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);

  return (
  
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
		  <img 
			src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
			alt="Alive Logo" 
			style={{ width: '150px', marginTop: '-30px' }} 
		  />
		</a>
	  
      <img style={{ float: 'right', marginTop: '10px', marginRight: '15px' }} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
        
      <div style={{ clear: 'both' }}></div>
 
		
	  
      <div style={{
        position: 'fixed', 
        bottom: '0', 
        width: '100%', 
        backgroundColor: '#ffff', 
        padding: '5px', 
        fontSize: '12px', 
        textAlign: 'center'
      }}>
        ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
        <div style={{
          position: 'absolute', 
          bottom: '5px', 
          right: '50px'
        }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
//sebelumnya