import axios from 'axios';
import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Cngrpoc = () => {
	const [isHovered, setIsHovered] = useState(false);
  
   const [userData, setUserData] = useState(null);
   const [token, setToken] = useState(null);
   const [rimoxKey, setRimoxKey] = useState(0);
   const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);
   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	
	var ifr1 = {
	"ansib311": {
		"preview":"https://drive.google.com/file/d/1G-qpvztDn_9t5HV2y2CwNjmFdssEh7BP/preview",
		"view":"https://drive.google.com/file/d/1G-qpvztDn_9t5HV2y2CwNjmFdssEh7BP/view",
		"download":"https://drive.google.com/uc?export=download&id=1G-qpvztDn_9t5HV2y2CwNjmFdssEh7BP",
	},
	"nfpa30a": {
		"preview":"https://drive.google.com/file/d/15JhEh9JyOfxLFiiHvqwlitgHKdzLXmxj/preview",
		"view":"'https://drive.google.com/file/d/15JhEh9JyOfxLFiiHvqwlitgHKdzLXmxj/view",
		"download":"https://drive.google.com/uc?export=download&id=15JhEh9JyOfxLFiiHvqwlitgHKdzLXmxj",
	},
	"nfpa52": {
		"preview":"https://drive.google.com/file/d/1txIBGbp_035A-tht9bEFvye0KNI7flpa/preview",
		"view":"'https://drive.google.com/file/d/1txIBGbp_035A-tht9bEFvye0KNI7flpa/view",
		"download":"https://drive.google.com/uc?export=download&id=1txIBGbp_035A-tht9bEFvye0KNI7flpa",
	},
	"nfpa70": {
		"preview":"https://drive.google.com/file/d/1o6891f_jEOeA5sSBfnV2regH9NSJSQmJ/preview",
		"view":"'https://drive.google.com/file/d/1o6891f_jEOeA5sSBfnV2regH9NSJSQmJ/view",
		"download":"https://drive.google.com/uc?export=download&id=1o6891f_jEOeA5sSBfnV2regH9NSJSQmJ",
	},
        
};

var stasiunData = {

	PGNSSWJ: {
        "SKG SSWJ 2": "",
    },
    
    PTTransportasiGasIndonesia:{
        
        "Sakernan GBS":"",
        "Belilas GBS":"",
        "Jabung GBS":"",
    },
    
    PTPertaSamtanGas:{
        "Extraction Plant Prabumulih":"",
    },
    
    PTPertagas:{
        "SKG Benuang":"",
        "SKG Bitung":"",
        "SKG Bontang":"",
        "SKG Cambai":"",
        "SKG Cilamaya":"",
        "SKG Mundu":"",
        "SKG Pangkalan Brandan":"",
        "SKG Rantau Panjang":"",
        "SKG Tegal Gede":"",
    },
	
};
	
	const handleLinkClick = () => {
    setRimoxKey(prevKey => prevKey + 1); // Update key untuk refresh komponen Rimox
    tampilIfr(0,'rimox_rbi','bk_rimox_rbi','ttp_rimox_rbi');
  };
	
	const tampilIfr = (ifr,idfile,ida,ida2) => {
		
		document.getElementById('iframetbl2').src = "";
		allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
		
		if(ifr == 0){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				
				document.getElementById('pilihstasiun').style.display="block";
				document.getElementById('rimox').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				
			}else{
				document.getElementById('rimox').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				
			}
			
		}else if(ifr == 1){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 2){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}
		
	};
	
	const [openSections, setOpenSections] = useState({});

	const toggleCollapse = (section) => {
		setOpenSections((prevState) => ({
		  ...prevState,
		  [section]: !prevState[section],
		}));
	};

	const handlePofChange = async (selectedOption) => {

	

	};
	
  const [pointOfConcernOptions, setPointOfConcernOptions] = useState([
    { value: '', label: 'Point of Concern' },
    { value: 'sfpoc', label: 'Station Facilities' },
    { value: 'nipoc', label: 'Network Infrastructure' },
    { value: 'repoc', label: 'Rotating Equipment' },
    { value: 'lngrpoc', label: 'LNG Regasification' },
    { value: 'lpgppoc', label: 'LPG Plant' },
    { value: 'cngrpoc', label: 'CNG Refueling' },
    { value: 'pgpoc', label: 'Power Generation' }
  ]);
  
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		ifr2css: {
			margin: '50px',
			width: 'calc(100vw - 270px)',
			height: '700px'
		},

		placeholder_tbl2css: {
			marginTop: '0px',
			marginLeft: '-0px',
			maxHeight: '700px',
			overflow: 'hidden',
		},

		iframetbl2css:{
			marginTop: '0px',
			width: 'calc(100vw - 270px)',
			height: '700px',
			border: '0px none', 
			height: '800px', 
			marginTop: '-60px'
		},
		
		selectcompany:{
			margin: '0px 10px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		selectstasiun:{
			margin: '0px 0px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
	};

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);
  
  useEffect(() => {
    if (selectedPointOfConcern) {
      navigate("/"+selectedPointOfConcern.value);
	  
    }
  }, [selectedPointOfConcern, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
	
		<div id="prm_entitas" style={{display: 'none'}}>PGN</div>
		<div id="prm_rimox" style={{display: 'none'}}></div>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '0px', marginRight: '15px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		  
		<div style={{ clear: 'both' }}></div>
		
		<div style={{ margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
			<Select
			  styles={customStyles}
			  options={pointOfConcernOptions}
			  onChange={setSelectedPointOfConcern}
			  placeholder="Point of Concern"
			  value={selectedPointOfConcern}
			/>
		</div>
		
		
		<div style={{clear: 'both'}}></div>
	  
		<table>
			
			<tbody>
			<tr>
				<td align="left" valign="top" style={{width: '200px', fontWeight: '500'}}>
					
					<div style={{width: '100%', maxHeight: '560px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#888 #f1f1f1'}}>
					<div
						style={{
						  width: '100%',
						  maxHeight: '560px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
					{/*lightning_protection_system*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('lightning_protection_system')}
						>
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Lightning Protection System
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['lightning_protection_system'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end lightning_protection_system*/}
					
					
					{/*Load Flow Analysis*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('load_flow_analysis')}
						>
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Load Flow Analysis
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['load_flow_analysis'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('load_flow_analysis_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['load_flow_analysis_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('load_flow_analysis_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['load_flow_analysis_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('load_flow_analysis_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['load_flow_analysis_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('load_flow_analysis_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['load_flow_analysis_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end load_flow_analysis*/}
					
					{/*Short Circuit Analysis*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('short_circuit_analysis')}
						>
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Short Circuit Analysis
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['short_circuit_analysis'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('short_circuit_analysis_reference')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['short_circuit_analysis_reference']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('short_circuit_analysis_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['short_circuit_analysis_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('short_circuit_analysis_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['short_circuit_analysis_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('short_circuit_analysis_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['short_circuit_analysis_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Short Circuit Analysis*/}
					
					{/*Relay Protection and Coordination Analysis*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('relay_protection_coordination_analysis')}
						>
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Relay Protection and Coordination Analysis
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['relay_protection_coordination_analysis'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('relay_protection_coordination_analysis_analysis')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['relay_protection_coordination_analysis_analysis']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('relay_protection_coordination_analysis_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['relay_protection_coordination_analysis_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('relay_protection_coordination_analysis_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['relay_protection_coordination_analysis_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('relay_protection_coordination_analysis_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['relay_protection_coordination_analysis_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Relay Protection and Coordination Analysis*/}
				</div>
				</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 200px)'}}>
					
					<div style={{width: '100%', height: '100%', overflow: 'auto'}}>
						<img 
							id="homealive_samping_sidemenu"
							src={`${process.env.PUBLIC_URL}/gambar/alive_poc.jpeg`} 
							alt="Alive home" 
							style={{width: '98%', height: '73vh', margin: '10px'}}
						/>
						
						<div ref={(el) => (allIframesRef.current[8] = el)} id="ifr1" style={{ width: '100%', height: '75vh', margin: '10px', display: 'none'}}>
							<iframe id="iframetbl1" src="" style={{width: '100%', height: '100%'}}></iframe>
						</div>
						
						<div style={styles.ifr2css} ref={(el) => (allIframesRef.current[14] = el)} id="ifr2" style={{display: 'none', marginTop: '10px'}}>
							<div style={styles.placeholder_tbl2css} id="placeholder_tbl2">
								<iframe id="iframetbl2" style={styles.iframetbl2css}  src="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</div>
						
					</div>
					
				</td>
			</tr>
			</tbody>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		  ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Cngrpoc;
