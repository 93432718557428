import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Standardcode = () => {
	const [isHovered, setIsHovered] = useState(false);
	
   const Standardcodelist = {
    
    AICHE: {
        "AICHE G-71": "1StWeyX9v1s-MiznGH1iwjLbB03qOv5TE",
		"AICHE G-25": "1T4CjCevLHGayssi9zkZADYGmO7TnugWD",
		"AICHE G-95": "1Uxf4EHHmkm1EVWG8CdIfgpXDRNmqgiWo",
		"AICHE G-31": "1bRVN8FO7eylVd2olLYtk1CwUXMhO2CRw",
		"AICHE G-21": "1dHcAcRoyox1cYTpwnHhOozDPbULxPSJZ",
		"AICHE G-81": "1n-Cixd0J5ximEVT0rz3o1ZymjCZXeA1A",
		"AICHE G-18": "1-eYTZ9NRvCYXP0-jt3uRUyFE3P6icO8f",
		"AICHE G-20": "1GmUjyNMvWrhlmBJggQwk9QqYD_YehsrB",
		"AICHE G-15": "1MNxZV2xIFNHvqso0aY87Q89iYbjJhXYU",
		"AICHE G-12": "1cna-6UeQqFvthsJZF58Vp4cHjdhiisB_",
		"AICHE G-07": "1fEFItUpCC5gaeKuGSjxus2nOU7XCVlbq",
		"AICHE G-13": "1jWHNMQIwty7FFD1vBBzxT6zrxe0yVaEq",
		"AICHE C-19": "1tvUpNVgwk8lFjKiG0z2t5ml7pv4voZ9E",
		"AICHE B-3": "1RRVo392SgXEkLvW8ZXXs988ocT4RjftM",
		"AICHE B-4": "1kiyqxlkOadj0j88QtKt6j0YRdW_JsH53",
		"AICHE B-2": "1pqbiaXr1HLSMnbnhzr8H3JIEd7UzTgvm"
    },
	ANSI: {
       "ANSI B71.9": "10H0KF87oY4W3UtHcH_53MEuoP_2jK8Nj",
		"ANSI B73.2": "127ybO7i8F-LAM2Tyanl1Cac4GYL0qxP0",
		"ANSI B71.4": "12PXmUnpwdjAprQcLJu4MZhH0eNryHC3c",
		"ANSI B71.8": "14DMYszOQ_rHkpfCUtmQqN2oDko_5Wf-5",
		"ANSI B36.19": "14FoWPYmZ6NFeSmNZCfBq0RUWltuwiP4i",
		"ANSI B73.1": "1A_2fvt3oTMp2lPFWK1u2eNl_3NvQ6HIw",
		"ANSI B71.3": "1BhsZTbaUZcACNCFq2V9nZpLBooEg4hc5",
		"ANSI B71.7": "1EswGs97TDH6viBWKREVV9OXP3Kpz1sHF",
		"ANSI B71.1": "1Hu0iJfabbYoXSN8CoFHXgrDqrSPWw8ZY",
		"ANSI B77.1": "1OFTklB7dj6vIqfYSs_cFPYzFKJzPtRHO",
		"ANSI B36.10": "1k_NbyfyPGJMNFGOmYvfKzrsnRZ-AFN2t",
		"ANSI B77.2": "1l3vKCC_Zwmzk-5hOL_njpmGqsm88xIBC",
		"ANSI B31.8": "1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n",
		"ANSI B71.10": "1tOpGrEW5Rv9DOUSgL5rPhL0eCqI1cgFv",
		"ANSI B71.6": "1usulDbYvIieCcMBpXmQLBv0EMWEP4cP4",
		"ANSI B31.5": "1zAesDXy3c-9er1dj1jFpAhE0AB7I3cW2",
		"ANSI B16 5 Interpretations": "11ltB8rDn1Um_zsvevSiVpjJz6Hh0ySGD",
		"ANSI B31 4 Interpretations": "12aCk35L_9Hli0rg596Ck6JLn5eIaVw4l",
		"ANSI B151.29": "15EpipUHI6tyz7FUZ9h75RdOs2JoXxR03",
		"ANSI B16 34 Interpretations": "16KR4GVYbnlRpD2E8M9lKIMamEie2aPnd",
		"ANSI B31.2": "185CUURh5QdC12vMDC5aZs0wFy6zlHp8g",
		"ANSI B16 10": "1C9Z8CMjRLUaDY9excd1hiP5zYGn3GeDF",
		"ANSI B175.1": "1JtU47qnjxqP4grMUNpaVZCqJ58Bz61I0",
		"ANSI B151.5": "1LJfoMzt2MwuEjJ_V0__DXfkDlivknw92",
		"ANSI B31.11": "1MKrPuiaPvjh1ORHkqL290g9W_2U2VpnJ",
		"ANSI B165.1": "1N4kzUlNcfhh1zrVjHAh2w4mbJDZx1wTf",
		"ANSI B151.4": "1QHmD06rPHGUmmMApbSMpuiDd54LfAAkW",
		"ANSI B31.1": "1RE4k689v0XwnrXRdyA9LERW2aNB0R3Kq",
		"ANSI B16 34": "1U0uLgSDpRL-nGQvannTKoyazbu7xzxc-",
		"ANSI B175.2": "1dBKFlsQyvZ3JeO1Z3vcwdyg6QF40IqoX",
		"ANSI B16.5": "1kvlwOChh_lMshf3cOn2T4FbwAFy4g7D7",
		"ANSI B31 3": "1pQPtaS4njCu6PWmnHasW8Ly1oL7HhJbY",
		"ANSI B16 10 Interpretations": "1vbhCkqIn-gLnyaooVT786FR_bvc9ULjw",
		"ANSI B31 3 Code Cases": "1wl3ZU0uTvUlgBQFFFQvGAWIJCKVVVHUI",
		"ANSI B31 4": "1x3_bgB-snnrWAK0JIcFUNNMl0nIdBSHo",
		"ANSI B16 5": "1yCfKcD5PzJzXE6NJTO4CigFh7GpHFO_a",
		"ANSI B151.1": "141QjGnoqFTDSUAi9-X_8Wku17sHZl-zw",
		"ANSI A13.1": "1JwyvlTKsZfs5CcCnIvTFetccpk800KHV",
		"ANSI B151.21": "1Kv07J_JbNn-Rk1LE3DZKQqS18eENcTAJ",
		"ANSI B151.20": "1_IDuLWiu8gsBLmVhCrI6tPmKqn_caR6H",
		"ANSI B133.4": "1fm8Oq2a0mx9P_PTjOl1oXayN9Qspr7Xz",
		"ANSI B133.2": "1hDJnVoF2Sx7A4pbxrULMQa8cOmY3ifGw",
		"ANSI B151.15": "1jjE9P_0Cb4uJ3ApPXN4RUYDVZUIbU8IF",
		"ANSI B151.27": "1rGG9IwxnBePg8zt3kH-5vGqopzBygx0H",
		"ANSI 71.3": "1w3jR53lNdsVTVXgvelawlfXACcqRHeIT",
		"ANSI B151.2": "1yPhbYLbEBqsHTgNWgP6AXsvzYlOlnBNJ"
    },
	
	 API: {
       "API TR 978": "16z4UmD_1Q3S3zXzXnjn9C54YEc7dv5f0",
		"API-19G2": "1IPrs-N1AwlOPlbuuLv1GR9TdopjKR-TO",
		"ASTM D2624 REV A": "1LjpuUA-3XCyTiQbq_FJPiqDJmZjuRIBt",
		"ASTM D1298 REV B": "1O2E_gGNR2jiZuK22nlM-i0SExJStxh-9",
		"ASTM D4177": "1UFr25zFyQ6SUNmAugmKBG1XdwBoNvMiM",
		"API TR 997": "1VQnGrDy5fhUYveH9nAF6uqazTP_Iwc0x",
		"API TR 979": "1Xl_w25mHWKMWed6rltXmtQl1i0IrqjXf",
		"API-19G1": "1ZjERUUE8GD45T32uqPqb_WLMU6V9jFZ3",
		"ISO 10426-3": "1_SIZ7U0zQDl1jMFyQnbEH_TvoTzdaTcP",
		"ASTM D1250": "1bvcSz4FfdylsHjVjAVb80YmSfspERPQZ",
		"API-521": "1eR_Kz4MSdO0VPvL5uK0PZFOwpAJSuHfx",
		"API TR 980": "1fDbMXEIDmL_cd_MzFojuJTRPTC-5Ufgb",
		"ASTM D2276": "1hAnQcLLL56wkloDz0QNE1fOpeB6aOZ-R",
		"IEC 61511-2": "1k4OEtt3O5XQw7F_wUDL4cGA5oXUks64y",
		"ASTM D5797": "1wOFyM6etEeIw6A94VE1KQpRHZzyFDI3K",
		"API TR 941-A": "1-ZI50f091sVlm6RKaaoB3NfxDtbTAW5I",
		"API TR 939-D": "18zKJJ2RawlY7AbOG2L2bvXZgt9tJcDDO",
		"API TR 942-B": "1A6AAlE6sFFv3Cd1q662VYdNPERDsnCsF",
		"API TR 942 A": "1LnQyBWwdMTc5kh_h2ElSUG-VDL_Oa8Nm",
		"API TR 942 B": "1R49PXOKvN8BQv1seti7o18ZBsswl7quB",
		"API TR 941": "1_u4OYDU2lWHTD5Z79BG8IBXq5-gBeRrR",
		"API TR 939 D": "1QeuccIro4TqDTwrM0mWwKRe-BygB_i40",
		"API TR 938 C": "1iEcEkBukuZTLgfIUFMIogFKTypaEJXSW",
		"API TR 934-F PART 4": "1-98qewkTyEtFN-Nhfroq1Dbxbn8wLh4z",
		"API TR 934 Part 2": "10SlKpbDr0h4D6FwNyzMErzbeRF0D48Tk",
		"API TR 934 F Part 4": "1316Cq7b4PeOR9dBRyU-Sh6aYJSCGy4jj",
		"API TR 934 D": "1Ag_rSeM_PFG4zRNZJKOvfLt52S5O4F9i",
		"API TR 934-J": "1AkcRs4o2VvrCKsqra9V4Gu5hDNHrvmcQ",
		"API TR 934-H": "1FLft8XXiFHgQQkVsgmIkaXlsj518Cya_",
		"API TR 934-F PART 3": "1HAVIwsN9LN2M8yq3n_sC1x_VzkRygpGc",
		"API TR 934 F Part 3": "1NygqZAgvpObam86vCXFcxleAoqTU4Qb6",
		"API TR 756-1": "1iIhu7tME1zINJkQoilTFvPu5QkfCJk7Z",
		"API TR 934 F Part 1": "1op_0fTzwAXmRNgGjRMHtHZZ4Lj6RNTBr",
		"API TR 756 1": "1YwTo6M7l64rpsyUikBdNwPGtXBAy1afb",
		"API TR 6J1": "10V7SEKteSgT2SmrCoxxc5QfJcRVWiSwa",
		"API TR 684-1": "1FFCGo-er08SY-Mtm_AGqtVW8kgJOhyni",
		"API TR 5C3": "1QbweiEuT6W5laee_a4XY1WJJca44AqBU",
		"API TR 6AF2": "1T0VLMf14YRO_mSYyDIHNiwCiEIhEv0Xb",
		"API TR 755 1": "1UH8dEamkidMi4A2dM5wAuH1WKwCCabrM",
		"API TR 6AF1": "1kh4krULRaLQiuiF0HIEo6VvKO9lcX4Xe",
		"API TR 755-1": "1lmUOTQn4h0mOAqPDQ0ntZoz0KJvr81Sy",
		"API TR 6AM": "1m9pR9NWVvaxmV8shDogs2hTyvHZ2T1Bq",
		"API TR 6F1": "1mr8C0pD9NBakjCO36_OFIU1cEc3Xn5QK",
		"API TR 545 A": "1ndLWYbhYv_q_TSO66dGRKYWK8elb14nT",
		"API TR 6F2": "1oBvLzq1XRvpu8KkQgtzNP37Knl6-WSiY",
		"API TR 17TR4": "13imrZyejP4vQ5kSswsfUSjAJnL_uwOxX",
		"API TR 404": "15GGOrNgPUeY-5pH6BvqsD8ayn-UyL8ts",
		"API TR 405": "17A1myUXyQ2eGUEpSJH12TumJojQsEe2J",
		"API TR 2578": "18qiancb4PLaS0DwCIaCeZqOwPITCmMKG",
		"API TR 17TR2": "1CUEKnE6l9N5FphR31okxtluwzqB8mHWY",
		"API TR 17TR8": "1L40InhO3XqQsghUiF_qSmFG6DxceQWi9",
		"API TR 411": "1Ma7fFIkXs-rEKCRBQGQfTuN_0WDT-nra",
		"API TR 2571": "1Oy3P-lGN_Pf4uZsesaekKRMM3-AC050Q",
		"API TR 409": "1UMvKSpZ9KY5y_OZCAd9ysLFPaoysIsGP",
		"API TR 2573": "1XmcgS3uJXuBWVbYZmiDzWLEUXeG7fntK",
		"API TR 17TR12": "1XqC4hssn5wcGmrG2pRR3o7vLkIy3g_5r",
		"API TR 401": "1Y-RT0OrvC6W5mVokJuLSncl2sMBZ-W7_",
		"API TR 400": "1Z323TksT9Aq569NTlUWF6sJudDfecBUI",
		"API TR 21TR2-1ed": "1cZgHhjuQQtwCOrtaFGf_ae4iMESUQMmq",
		"API TR 403": "1d-0GRzocLLC5t26dHYxouwLkYItUxtcT",
		"API TR 2569": "1fy8EpfgqLR6Ljv1Xe-wtEQ6RFP2pq0Ip",
		"API TR 2572": "1miAchML2cXG6rjj9SHMaxtzmpLaUzXkJ",
		"API TR 412": "1rls3ld9aXBHCyzqvTqmzUti6VUZm0Cff",
		"API TR 410": "1wgdjCzujG3j_5vDA5TOK2WISYD_KxTt3",
		"API TR 13TR3": "11X8m9yPy7WIXjygm6rc9rmLkemKmmsm0",
		"API TR 10TR2": "18qnvm6UuY2Qjgo0KTi2Ie4eFdweC-nRl",
		"API TR 13TR1": "19SuszxdhYDWM3f6Z05S7iqQDS8jCNIDw",
		"API STD 780": "1Df5DJJX180ZWlUvUfvKzhDdWjdoQccSu",
		"API TR 10TR1": "1KO_tY970n6JNQgXqTpalHr0nB8x7RU00",
		"API STD 936": "1OEGIRTkozfkeZ1k7DMMXYNtbaBpZ2lwn",
		"API TR 10TR3": "1Q5ztSJQ0hmQlsgF3W06FUmv1x4kHwobx",
		"API TR 10TR7": "1TDI5GXLhV77Pl_AhIqzHROYaWUqns3rE",
		"API STD 6AV2": "1VBxDD7uVLLBLHzqd7WT8qfnwt1mUnObr",
		"API STD 6FB": "1bfwgReR_pYNDYskOtl0EWsXKr9ieDjN8",
		"API STD 6AV1": "1c2ZbQOGtOCX8tGWu93CS-Z8epAelqqTs",
		"API TR 16TR1": "1hCE6EfTCo7qd-wmp4EGzc6eutocV0Lq_",
		"API TR 11L6": "1i-GTk39MTn9WRtxIW5SJR0HnnjH9fjHS",
		"API TR 17TR1": "1m3ZlUDFy-TWwvdwgXz5IBQXQ2qDk_4il",
		"API STD 6ACRA": "1tfmtE25fXlPWzH6MoSFU5jYn9epCxvN4",
		"API STD 6FA": "1tkDEku-sZP8Xj4tST0gQvme2c-dIDC4q",
		"API TR 1149": "1uc3hLX8LHhYy3Tt9E3kHKg7JQ_4cdbvw",
		"API STD 6DX": "1xg-aiEjzlW4ynL0UvGkVGCSQFnRiUtgP",
		"API STD 692": "1ybyUIwP5fn9G1t8JJ5JpZvsiEAdVNWf_",
		"API STD 674": "10vOfYfnzTFaw-8dFnMg3s86OsxL3qwib",
		"API STD 677": "18noMnwUvGVfU-P18eiOK-S9MSady4SjV",
		"API STD 676": "190Pgy1z38FkzDNxHmGnii_MnzK_d2diw",
		"API STD 677-2021": "1Bx5ptNH9x7nEVIAG81vXSGOOcOGMIWN2",
		"API STD 675": "1MqQKjfmhyhB2sQs8zVOXecUV-gaxMOD4",
		"API STD 664": "1Oi9yLVJishqyU-nK8cw5KXTVt3_Culax",
		"API STD 670": "1XhYyAbkeve3ceFrsjuDgh5pvm41UwES8",
		"API STD 685": "1ZULLMXiq8EFvy7pENj5Fxa2xAD376pTW",
		"API STD 673": "1ZnOR75JVkaOrjFjbiO7dhFSexpFJ5xRM",
		"API STD 681": "1hxv5XpquHKT4rxn04jc5LTtyeDxOfQLG",
		"API STD 663": "1i7P8M2fAzXO-_IVbuQnbWs62t7dD7JZz",
		"API STD 672": "1l07KnPkNNq10ng9VQDeNlX2mQY0xOqon",
		"API STD 668": "1orarsOwuZaQ-s6h8KybqUdpqT1s9SnP",
		"API STD 682": "1q0sFf1ZFE4yfhqRF1XbYWMl0cbZtmSRY",
		"API STD 671": "1qW52sLCjwvmWCEBiCm7R7u8pQYDkg38_",
		"API STD 662": "1tbKhAL0tS6oUr7acN-Hoy7tNWUdpafKJ",
		"API STD 620": "1G80WvI_SXffB6j6YNLjABS4vlW9uiKjE",
		"API STD 622": "1-36-Vxyi_Y7p9xokUYEAg279VDPlKWk0",
		"API STD 660": "10qj9JoobezAsqFmo_RnvoSfXoZpO9NYR",
		"API STD 624": "13Bbqx9MjiEzZy1cHmdwQ4a2C7_bib1B0",
		"API STD 653 PORTUGUESE": "1Fg9QiX38LDmob_vnvI52R2gS8cuSVpVs",
		"API STD 650-20": "1GIrxLv56HBNDI7HkelG31lE-BtTHjWfu",
		"API STD 662 PART 1": "1GzPAbNCx1TZwt2aVguxdfxRnnsmA1NLH",
		"API STD 650": "1HkxLWufmqbw2D3ZBYqbEoIVWCQs75qM9",
		"API STD 64": "1Jr50zK94KF2U0INe3rnM5IPmGBmkWR9f",
		"API STD 651": "1PURQqA0oyDrqEIVDp91IKM_O9JEe1RwV",
		"API STD 661": "1Tu0ZzFo7IM5dEQZp1SIbWJopjoD6aLXb",
		"API STD 65-2": "1YSFRlec6UfEJW2xOZr7iMeK7gwS2twNx",
		"API STD 625": "1hjALkB18DcqeEKZcxgp9Tp0rCXoS5PDv",
		"API STD 653": "1iuCZz-EGxqUBzxaaVn8Xy1VJ1I8VL-P9",
		"API STD 623": "1r2MWC8Z_SEWw6R6Fz6NOiqV36OaYDrXI",
		"API STD 641": "1uRF2bw8k89dBvVnZs_y-8IKs6mW2TW4e",
		"API STD 616": "1AWB2knPiRRjjYIckpcCcW8Qx6KWy_0ME",
		"API STD 614": "1C_5EvvnLNfijvPtmNeps_KDWmS0fT7ob",
		"API STD 613": "1D59aWLDDZQy-IO-_c7vofY9K5hRSssKS",
		"API STD 616 -": "1EucVDWYohuQprMSiwY5A9BV_QkZG5SjX",
		"API STD 618": "1FbeG_96tiFU4CV_0tnRcoehWZXLOF12l",
		"API STD 611": "1Qu1QFuTUeME8JQhHm9oItcirBFQpAU81",
		"API STD 619": "1UHdA1pZ34yOchnQc4EdTERXGeTuF39yQ",
		"API STD 617": "1dFAht4rOgK6OaUQs36XJ_O2PlDdD_O9V",
		"API STD 610": "1pikuD88smxtt0kwLgBvtReypmRwYGwIl",
		"API STD 611 ERTA 2": "1tJtUfhPHQeln9pqVzXnsYb2Q7Z41h3ix",
		"API STD 612": "1vC6ZkldIkzP3-wZTE1iVFlKaiMLjawSz",
		"API STD 594": "10q6fnw8M3Ulqj4VKX5gYjN7ZwdJS89a3",
		"API STD 536": "158sSRdw66qvxBSPqwkacYzSg1VYgdXVC",
		"API STD 603": "17ad4wwPAafWw_mftn1zbJO0fJPHgMtuQ",
		"API STD 565": "18Phjvv11hCvX0frrVzzmK4ERaYWAmuZ3",
		"API STD 541": "1A_fU-JIiPvy-Jt_q28-GIn3uxQunb1o8",
		"API STD 560": "1IaTdMvT690r2TKuo1_yd4FnkjGlwJMy5",
		"API STD 537": "1L3Ebj3N2hMe1LmvQvs5-9ZP2E0P-2anm",
		"API STD 5T1": "1MnolJB9TTX0zortsFCQHJ9ZTHUIEulhp",
		"API STD 547": "1YGpNXtM901bHtZoRI4pr0_pGBTxwqtj1",
		"API STD 602": "1cctt3tO7zQz49yLe4r0goXQNCYkh0wKP",
		"API STD 599": "1dsg1hEcf8f7Z1zJEYfOrEOYAyf4ibcLO",
		"API STD 608": "1hIK_bSTvIbjCPcRfyjDhM620g8hb0aUc",
		"API STD 600": "1hTgWn1oXB7S9mXJF4XSSzQ6xX-gGgsV7",
		"API STD 607": "1huaQxwXGdxNJLnJ6OY2f2Lh36cGyzUEl",
		"API STD 530 DD1": "1k03BVsdl0-lTPG2wcrlb-5QcxeMSvtQY",
		"API STD 609": "1ltWXHK4LqJMXYN6NX2gmqjM5apzGpk9P",
		"API STD 598": "1tKshSIWmhzrUzXa0PbWCUq_6odDl-mcN",
		"API STD 530": "1uLqeHc2KfrelFOXLdAPHe0SDWrMu6Okn",
		"API STD 546": "1zxA8FzpjuFsf500o4--N10NJzkne4Ajf",
		"API STD 521": "18y9n7U80QXOHY33wUtAtcpRSO9uxq239",
		"API STD 530 ADD 1": "1ArI40LyUAwG1WuUP_VMhyTRJ01iLOfYR",
		"API STD 53": "1KimmhRP44CqTO2XxUS3zLzMkKMIlE3kW",
		"API STD 530 ADD1": "1PbzvnO1wS2AdfFMIQgAxsJZsYoQmR-fP",
		"API STD 520-1": "1Q7F3C2sjkjiFbLqIWXUxk-yCOBAEEEeE",
		"API STD 520 PART I": "1VgRdhcjct7UJuUUXUrbQqk6GA4RKsgbs",
		"API STD 520 PART II": "1W80CrqCIiA7xyhnyBMnGqZemC7h5fCAl",
		"API STD 520": "1WH9X5Cc59vxH8S_UayNIj1EG8JJDMnDT",
		"API STD 53": "1bCcVJCuZqPMn4O0ZKNlchnQtPiLTLO73",
		"API STD 520 PT I": "1flcVxZNdpIwkRnWolmPpuLCJd0dnBsYV",
		"API STD 527": "1u8xIQGmVA-TJDPCBgRo6t3WzFJ68EqVF",
		"API STD 526": "1uZ7wlik8hT9wlhFpGxVl3ioKKsLrpbVR",
		"API STD 520 PT II": "1xFLOOe8YKdqktANDP7_-ZQMjowUxLtgc",
		"API STD 19C": "10FUSVm20Rba-xqvhXvsitq6qDYxT1n3m",
		"API STD 1163": "10ISJeNqol5NvEQ3dJisZzX3sT48fF0jR",
		"API STD 2220": "17sDKnWvIoKuY91Cal9VAiQbIIgy40F4V",
		"API STD 16AR": "18fAgm8w_HnUcotzYQOKtAahLr94iBh3k",
		"API STD 2510": "19647RikpEVZ5gpJKByL1KEdL09RVf1iX",
		"API STD 2350": "1I8Lw6IFFuPyfqQEniqnJHf4QZF8gNvn9",
		"API STD 2552": "1IVOpnoFMxN7yekKstD00ZxUlebwOazjS",
		"API STD 2554": "1IVsXuLBcopXX7BKiq7YliHmHxkHwxyP6",
		"API STD 17G": "1VkXQ84u2-Pv_NslWWAEIsQk3qHptiCJE",
		"API STD 20M": "1WTbnCkh129mJlTYY6lELj33_mmf7Ah7K",
		"API STD 17G Design & Manufacture Subsea WI Equipments": "1Wq9TcPc8NxsyFWg6DM7E79tO6y6MSrHl",
		"API STD 2610": "1eEnY3Q2DWYOLBZ74uB9NBfU9xEdU5hjy",
		"API STD 20T": "1jv0xrLP5k42UIt_ijUwNVvr9-iu3E0rW",
		"API STD 20N": "1kTL4qg7FmVMCXzb8i6U0fo7Iz2aS0pmV",
		"API STD 20J": "1rvXjwxT5FBfiXkQybe7YmQdT0U0N3R9q",
		"API STD 20L": "1s5lE3XaFtuaMmo074K0wjKFk3dbaj8fY",
		"API STD 1631": "1sOf3iRer_ixoFW2ySdNWuVoONc6-5eS6",
		"API STD 20S": "1tEYWt8McEAu4ZnCfSK395MEZ1zewdwWM",
		"API STD 2555": "1vzBHoaswhE6xztnMMcwoNZPf1joXTpzF",
		"API STD 2015": "1xf84zMXJV4WZgQm9WaU7i7oLXS4gIgfy",
		"API STD 2000": "1yMdHBOhoVX7_cHz7EEcB5gVoy_7idbwV",
		"API Std  662 Part  2": "11Qhxi2dfZc-UvhXKHr4cNAVx2ol0N7X-",
		"API Std  614": "12QljWZkJ0OlZI_OkM1tQYSKqMwHPRCoU",
		"API Std  619": "17FJEqfQewxI7Hqnlcg6arcMKl_mlrOuG",
		"API Std  671": "1H3M4jCIMpz0Ey2L0mvhuHfdPcKWzdyiu",
		"API Std  610": "1JOgK3BaQA84FAWMEFd5OzGomWeD9FWFl",
		"API Std  661": "1L1VgioHe4cj0vKx6QbkTnL5CnMMfxvmZ",
		"API Std  560": "1RW3snOapGm6RlcMnG0dN_G_7hvnL5OEc",
		"API STD 1104": "1U49E1tTvxJhkOIGhQr3GUCFNLgo0v488",
		"API Std  527": "1VW1w2uQZa6Tm-UgJoe4WVHxam4vKP6S1",
		"API Std  609": "1VktJrnCOoTBWFsCvon6Eg5HNs2rNmGC8",
		"API Std  600": "1Voy7vo9hsiejxQqjsjx1cv1T1PktI5FQ",
		"API Std  620": "1XjTNX0YcoaCfIZd2XlDrnwAWBHuQwUW7",
		"API Std  602": "1eBor_7exOB8P02dvNzw0_IU5t9h-9nUb",
		"API STD 1104-21": "1jLiaTZZ6Icl1rOH3rwpupj4x-eFI6POH",
		"API Std  660": "1mIXI9RIkZdljgurq1J9CN6PJxwby10F9",
		"API Std  662 Part  1": "1yZRugk2M7BV3F4SbZxOn4PKvlQSlJszZ",
		"API Std  674": "1ykP1uuz8I_up5rCPj8IfUkF9XFlmma8n",
		"API Std  526": "17fTvSTaUYAj3_Sv7hdzx3kDb9__mk_WY",
		"API SPEC 7NRV": "1H63iU4XohmTf4IBthApaGigW75TpChhq",
		"API SPEC 6FD": "1iV8V6guMPTNzXEGd6649DltJUkz7r19J",
		"API SPEC 9A": "1t-ywJtYaYs0iJj__iO__zhMAnqRaBCQv",
		"API Spec 7-1 ":"1-P3dPMN_0K_9VVXmesfFceXHyv2_ymoh",
		"API SPEC 7HU2 -1ed":"1-X2kC7dhuo-Px4SXGb6tEx_ZFRgSPgs9",
		"API SPEC 8C":"12N8qBh-v-8Jcwqx1_N0EHiBQE4__n0V5",
		"API Std  2510":"16CLWV2kHIT-KLN-udnNPYsN-LSle7q5o",
		"API SPEC 6FB":"1FbZMWedLM2EOQkNxeN7W8Hp3v7hofiNR",
		"API SPEC 7HU2":"1QUPgXiKS3Zre0Not0bjdtpe5o3RuJIuB",
		"API SPEC 7-2":"1TmSEmF5Ae7Y-tXfwPVdUwAoDGDjSTGrL",
		"API SPEC 6A":"1TxGcBO6nnvrczLeu339wP3NHyPSP-dwY",
		"API SPEC Q1":"1Y8TubAPDg-cn-8xsYtugbLxhLEfbDZ-L",
		"API Spec 7K":"1coq2QPMINu9o40zc0ZZEDB7bgQ6PKIM1",
		"API SPEC 7-1":"1fFismLCPhY7EE9AmVZaAsu3TUlXscBkd",
		"API SPEC 6D":"1k4WQsDGb7ApipjnVjSKFvjq-DwLBfKMe",
		"API Spec 6DSS":"1nE4wtXn2jcA-48OPkF1hEye4pIG-wgln",
		"API SPEC 6DSS ADD 1":"1qCzhR0si7EF5EOqk7D_kcyteCaTzZhft",
		"API SPEC 7F":"1vQg2XWJWReaVras3kTeZYQoktcVb45VK",
		"API SPEC 5ST":"18KMMMod6wNiTkN3sw6y1qtFDW6vFau_3",
		"API SPEC 2SF":"1Bh5QaAxRoULQgV8rcRhvbnfhdhcgGx0s",
		"API SPEC 5DP":"1CI2kMXNGSfO4gyC7-HA8pmetjQagSNhB",
		"API SPEC 5CT":"1M3Fc2JMLKGxPaDeyVKfyYWtWhpX0jP7H",
		"API SPEC 5LCP":"1NsaLZWEa3ep320tJsNlrXAXyPMl_V6oQ",
		"API SPEC 2SC":"1OsX6B5vN8xQF9ETbGDPlODio_PNNdQ7_",
		"API SPEC 5 CT":"1RiuBwr6umZGt0Ogr_LZYUyuZpBXVVyB-",
		"API SPEC 5LD":"1SrwqqsU5YZrJyOM21lIrOeAUHCJVHBkA",
		"API SPEC 5L (2)":"1UIt4hFm9eBn4JNhpNxzk1-Mx-SMp6qSu",
		"API SPEC 5L":"1Y2vjaZfk5lHtK1OmYqyFsEgsDvz-DBVJ",
		"API SPEC 5LC":"1_-B5HUjDe-RanLxYju6efHGbLnDTMhHM",
		"API Spec 5CT ":"1joekobz5p9PVNXtYcUw9V1UO7YzdGY9j",
		"API SPEC 2W":"1lXHxu8ldcCNZe7Q_IUY7TyMTYugSnaFB",
		"API SPEC 2SF":"1mN_HW9MEKuktO1nTZ1TogbU44DcAHrDy",
		"API SPEC 5B":"1rYDqwg5dU0SwwVK8HccTJ4lHG5L8Fxby",
		"API Spec 5CRA":"1tOOIx4qXoABEftw-smFkuOmLNkj6tkUs",
		"API SPEC 2Y":"1v-p-KMejddhzSp-9_z_ydI_fSpfcSLtX",
		"API SPEC 4F":"1yaBTkL5gTyxRT5NYDofle1VyMy7v7sxp",
		"API SPEC 19G2":"1-PF7-y-eEjN61Ria43BDcNuLzb9GOkWL",
		"API SPEC 2MT1":"1384L7umix8lEPogADt64KOlkz07849hC",
		"API Spec 19TT":"19gXZxLQDkNQNdQZfwXW0bWR43VZ2Ln2k",
		"API SPEC 20F":"1EQDYy8UTPy2JyrcWLrgKPM08VOIQ5UZq",
		"API SPEC 20E":"1OHv0NcrtufRAzjT5cKJOjNJ3G660sehH",
		"API SPEC 16A":"1PywmoY0EN_mlyj2mUP6wy56hg40Nr9Es",
		"API Spec 19OH":"1SCJNyczEq4nr_0oflzuj7Mvqjqt-PRML",
		"API SPEC 2F":"1XjoAwg_JYlOf2PqS0e3j_9RIgGTH7_8K",
		"API SPEC 16D":"1aYuI-ZD5PAwBfSCpO7sTgea8KbGva499",
		"API SPEC 17K":"1dybJv0mORUcASlYLUBrdj8HgrfDFfwxV",
		"API SPEC 17J":"1hS2k0IAc5uZucRlCk4Fx7_BJxhgup5o2",
		"API SPEC 2MT2 -":"1lRxMV8zeX3irqhu1aNeEUq5p4ZQnP3Zh",
		"API SPEC 2MT2":"1pomVeMnoNbxKrbR1uYxe_bVsvPnOC1w8",
		"API SPEC 2B RUSSIAN":"1q8VjITcbu7Wiu7wfVX-BN4BxsZoCjJfU",
		"API SPEC 20E ADD 2":"1qtpiEZcCgLucVvjqvJvRoBwRC2HiRKB2",
		"API SPEC 17E":"1rBo5CBQJpcs94W-OejpiwY91E0V2WW4n",
		"API SPEC 2B":"1rIt5DMAVPsF6SxHxNLEJWgiBBNSvPcte",
		"API SPEC 2H":"1syP6LLxEB4tXUhBc0QX_BRZQBZkfpKVQ",
		"API SPEC 16F":"1tnSWYluRkPASfeL2u2nvFwyILh150Aan",
		"API SPEC 17D":"1vlf7c0VIXy0S6z_sW06lUTfWrHYl2oEw",
		"API SPEC 2C":"1vlvq-OlIB_vdAtXOgRNnvvkxThe68XVv",
		"API SPEC 19G1":"1wJVVpylGy3uEug3CvaM9bA8caXC95Ve2",
		"API Spec 16C":"1zit9AvufaCabAWm_JTOmMVDkTb_qXL8k",
		"API SPEC 14A":"18KEdO-oSoMTqvZ-BgYBdjktou6X295Zl",
		"API SPEC 10F":"1BsD5pRNHEblxeSAWXrqJ-gQ8c5DBG-Bd",
		"API SPEC 15S":"1GkDhxOoshe4haDNaEPgDPJsuBreCsvP7",
		"API Spec 11AX":"1KZ2ljMzHZwhDtlns2SiUc7zmiVANPp4L",
		"API Spec 14":"1ONo0bxYIK_plHKjCOgtFFTRsj0fjyNGq",
		"API SPEC 11E":"1QOZzGGIh4EarctKqcM5kYTVCTzbhaIJB",
		"API Spec 12B":"1U-3fxxxZxB0igzFWJeTPxgAq2fHKpt_T",
		"API SPEC 15LR":"1VDyBEH4bj9dI_DFFHcl5vkMK7XVqJyz4",
		"API SPEC 11B":"1Yer8mKqyVOTeTw105eBBqHMt2_6jxlQD",
		"API SPEC 15HR":"1_yqKy4LQvoYzyXqzodqKz4rz3lP31lnl",
		"API SPEC 12K":"1bH0ZYgNmxvboQvHpQB6Umwb9yRNBIfmQ",
		"API Spec 12D":"1br5CFt5Hlww4xlmFtXybs2Rm2ZhR2tWs",
		"API Spec 14 A":"1eUsicLr2oNty7xvVWUY6amity_QEIyzA",
		"API Spec 12P":"1hTaWwMXwf-Idg27jMLOPmV0jDTqgcPa4",
		"API SPEC 14L":"1i1h3vDaanFCSu1g3tdX468ShXtAey3eS",
		"API SPEC 15LE":"1l2iOm_oGXXdiIT180on5n1Ehc1r_1UEN",
		"API SPEC 12L":"1ly4W1-wVpW6b7Lq_dlEQH1S-FwBsfotA",
		"API SPEC 13A":"1r6t5FAbx-J58ckqE4ywKUenBwlKcjGej",
		"API Spec 12J":"1sMtgw2fLZw1PMeUfA8Occo35qgDlkms6",
		"API SPEC 11D1":"1uJKbIxf3x0gf3qpZ8xjcEF4NIlpPAlZv",
		"API Spec 12F":"1wQEC6Qfx_PbfFAsgVSa1e-jVpF332JKQ",
		"API RP14c 2017":"11VxnUJyB-GsQYf4wA1qj0-cSmAJs8K2y",
		"API RP 934-E":"12ZcIx9aOUrBePpOuaIWATLS3y7a_8s0W",
		"API SPEC 10A":"13TQ6FFWdvAGMDnVDahfUThbV3CUz5p4R",
		"API RP T2":"17j-DEYlFD-FQYmwwZzQWKUAdZug-FNSc",
		"API RP 945":"17xYGjeczxVmR7_iZHFuhHhHPQ01Aev6Y",
		"API RP 939 C":"1CkjdD3Y3lySzFCtAqzYGPP9OoOHYCsNY",
		"API RP2SIM":"1GBwP4KoU5re2CmbcrkQ3bEP5soS9aFvO",
		"API RP 9B":"1NtDXVzv_1Q2ST3H_Jc1CchU0hGQcM_Qj",
		"API RP 936":"1OqOZoRwkL4fKyyHFyfxBSXdXqw3sMXws",
		"API RP92":"1QKZSlXYwhhV1zV1V_xt6GJW-G23MazYk",
		"API RP T-4":"1SyDpCcCfLRqwOebZT_l2R1QXxlfOfRei",
		"API RP 96":"1_l9zKYil-rKYnOA3uclf2BKUzZP7Ks4x",
		"API RP T1":"1caKYK3do-q5yxhrfrh11xPZWU1tJenGy",
		"API RP T7":"1fh3F6z1jzsFXVRyXDzEbeB_OIlp0srol",
		"API RP 939-C":"1hV1oLYqpIcy5b8onBbhMzK7uFy1O1w5e",
		"API RP 970":"1iCpOT0TbuomaQhsKetcVjv0DEd6Ldblu",
		"API RP 941":"1igtnqVcAG19fVxj_WiuFPw0CvJdOOFs1",
		"API S1":"1rzB9RZGZ5L6fU8aTCuUsR2zJwnsgaUR2",
		"API SPEC 10D":"1xpsE5Yjp27vlebouF55Cs1-f30pxlhGn",
		"API RP T6":"1yIqhscrnkYcEiinq02ESNQUIP6sR1Ntn",
		"API RP 934 A":"100W_fwl4B9_8zpAOoVHqScn8pSI5J-8x",
		"API RP 755":"1FpQzZdjFG5L9TnjuNlNZ5Yg3Q9nWZ9sC",
		"API RP 76":"1hbNpiLgfqO4cd1sWHzaNDbQzP5tbL_Jy",
		"API RP 781":"16RYWJCLn3DoiuojsDnqQWowHwsdSPf1H",
		"API RP 754":"16frqEXgEyli21tGf1jKU-P_cDoIS2sEt",
		"API RP 80":"18SjD8XhSw_sBQqgPTB7juNcAzh7_Z0Q2",
		"API RP 932-B":"18Tj_aM8aQ9RXkwasrbPT-1Ggq6x5MHUn",
		"API RP 8B":"1FJNKKQrfnWjheBEAe7gVrRggeNexCjdR",
		"API RP 90-2":"1FR9Xqi11ygUxkFz_tUOUtl6LH9VU4vyk",
		"API RP 7G":"1G5BZ193DOuYW198yJaSYXUPvumfNR0cB",
		"API RP 932 B":"1HMSIL_0Jsa_t8fl3GoKZQyEqZaFowJfZ",
		"API RP 85":"1P_OGEpfWt0ze-dFsm3pagfZlBmaJswns",
		"API RP 7G-2":"1WdQa-_bDJB40eeNzJtYVkU2ThHLVKSv_",
		"API RP 934-C":"1XRJjWQp1joQhRdW0pnNtINkOHcaGHZkt",
		"API RP 756":"1jIpYnGjVdDBqNvgd6k0IMivhe4zB9Lvg",
		"API RP 934-A":"1lu-bixPD5-o4PvLlRZbfQMtK0kT9tLyk",
		"API RP 7L":"1moABDOV0-grCfaa7yXBvWWg4pJ3cgukW",
		"API RP 92U":"1pIuPnuPDnfv5GhYFX1l4htAWUxioBLR8",
		"API RP 90-2 ":"1x_HpXXNLhSTVF2u2GVt4wfAKLxc3OAew",
		"API RP 7C-11F":"1xhFPLvLuh4UFe17NExU-JG74pzMT-L4q",
		"API RP 87":"1xo6fqBhA3h7J_2r8eazTCHwBgx6ErOf4",
		"API RP 686":"14hhlrw7_v2kD_15YOAz9qb0E9Bfmh4ZL",
		"API RP 751":"17Ucr7vmdOwh30Qfg-LNV-jb9biR5fkdI",
		"API RP 688":"1AMI4Ofx_7qKAI2qPcAUa7MmehCdyBjff",
		"API RP 752":"1AdxGQY-RB8ddCDuwDESoJHCAjyhCGcbl",
		"API RP 74":"1C89Qio5vU1jTtd9KInnjg-0NTqI_wt91",
		"API RP 7 G ":"1CQYfTX9vaeI2l4unyXH8SyfXD7wtdaG5",
		"API RP 6HT":"1MVivrwEv_DIVtREDJardfr6pmmZLBtAS",
		"API RP 70":"1ScjP-QUJNg9fN1LH8HFN38suNX851xqi",
		"API RP 68":"1Un4pIpQc2fMxjE1vHyAyvTi0yc_Hpt_f",
		"API RP 75":"1WmizOtntOlMoKE_fVDIZMopgN0_Tw1ln",
		"API RP 753":"1aDkJubCmFxR0_bAe8-7-37y2LcGdk8Z1",
		"API RP 652":"1bDYiWbt2TEhzgrFFxDdDi1P5ayU4HkEG",
		"API RP 684":"1egjloF_mUwVXGeDr2ajM8EnSlO-bxqEN",
		"API RP 7 G-2 ":"1l5TINvwD-mWnAW-e0xurCTg44XiQDUNl",
		"API RP 67":"1lzrCIFawfP8RwWjgdv0Sy5piEeJ0aG5z",
		"API RP 687":"1sUaSqtq9dno-oT8-Jsrdfl_zFed7vSIt",
		"API RP 653":"1vOhzy1l-5q1c7CHfRrjDNZg68bQJDFaT",
		"API RP 5LW":"10EZcOllOLuPWgO0RQTlC4t0OlDYUc6Sr",
		"API RP 5C6":"12fd3jPi8gtwGiGfxYIsAjig2MOB38pfM",
		"API RP 5B1":"16rc8wRaNcnrL99NHvnQtrlaAHoq17AT_",
		"API RP 5C1":"175nqz3u_vAs2GLToTdp76oPj7O9KNs2L",
		"API RP 5B1 ADD 1":"1A-g4Sw0dggI9ryFgk73Mnm6nlZVr79NO",
		"API RP 615":"1C4-HkqoDhE8JGbomG9CXBqSiu2c6Z8bo",
		"API RP 5L1":"1E2fDpLnP6Q9jWolqMo1EO-giJGKclIdY",
		"API RP 5L9":"1Eq9NAgcgOhIKhuIQn9NjXCijU-Kluuvw",
		"API RP 5C8":"1FGRygHsrsQiMlrfkt7P2gqo0YstyLN8Q",
		"API RP 5A5":"1LjGYnHtWVftvgCl1QVyb7f2HxGwoCcoz",
		"API RP 5EX ":"1OPgNZwjAsgYSTLQOEUNkWmv8F4l15nbp",
		"API RP 621":"1PA3HdDt0U4BNJmNA-DxMnA3MP0uYgj64",
		"API RP 5LT":"1Rq56H-kCb-fBMziMVySLMsAtHVVE_mmu",
		"API RP 59":"1SIYAabl82SeRkaKwz1v8jvEwwQvpDE7V",
		"API RP 5UE":"1Ua6TzqiY6-i05sWSmADgHiNQyiFBQFOJ",
		"API RP 65-1":"1UuhqnWJ0tPzFw9WtLCi8bleaDc7f9dYj",
		"API RP 5A3":"1XneNze8CiZRMlKkoEo3FhQlRikhyFTcD",
		"API RP 651":"1ezbeJPxqniaviAi3kQUxAZclMnr7MD4y",
		"API RP 5C5":"1gWkozH6kNmFKA9KPa2Ap0XHDckhHADAp",
		"API RP 5L2":"1jIpNFBaWZ8c-jdBf0-sx-_gRD7-co_ts",
		"API RP 5L8":"1kTn8BL95Y6kIFoH148uYDEYDACCWuPUC",
		"API RP 5C7":"1ktYKluElfXMJ0l13-lRoOdd84HQpyW6W",
		"API RP 5L3":"1m9m1PyobR_faHb-ezowtnJJlMJTsa0hc",
		"API RP 591":"1oaFhtZ9masDlZaV3IuQP8Fsc9hzIH5D_",
		"API RP 5L7":"1xo08CpFT5kER6K_qX7SsGIWp36VO7wjZ",
		"API RP 583-21":"10uxaiIP0WV0S1V1VPbEmddr3mtDkDsWM",
		"API RP 577":"1DjSFiK7JEV6G5q54dQN0j8lVo1ezHNNJ",
		"API RP 582":"1G8i6U0jbrN6TZ3tmz-mKaZ3BIT6oucFP",
		"API RP 588":"1Hz-hVYCAduB2O7sENmuEdcyWxbWAe3Kg",
		"API RP 580":"1NKCbJw3JZjW9akt9daYkG-izMcfjpYdx",
		"API RP 581":"1XH9wie71cLcgSKkxLKLELGhAHA7PmZOE",
		"API RP 583":"1XpwFKJm1bbA9NbbRQIjToD-bXrI40cZu",
		"API RP 578":"1_-DYBecd9T9aja5XLz5-Z7B1q1ikqDFz",
		"API RP 579":"1_E6Isy7syY7ecNX_kUFdouIeBM-F6SO9",
		"API RP 584":"1gyqTocD2nDjEs12eOOXEluA-bKprgafP",
		"API RP 585":"1nWlaK2XjvddaOKkZz0mvZ5mnm803wqY0",
		"API RP 576":"13LtnljI2mFuYzr9_-pO7HE-YffVZocpE",
		"API RP 575":"16uHtT7RvdijCk7m8TWI6-fnGtwhunytW",
		"API RP 574":"1GMkjtBEaGYMaESlLARtcFJ8_WVvEARdj",
		"API RP 571":"1MyeAeloYdRoam9-LIf-HsHmamdHdQEya",
		"API RP 555-2021":"1lWu_q9b4C8uyodWxx0OyDQVtItF04Bw4",
		"API RP 556":"1lasoZ6pyqxb6NGjJ2_YRUpAlut1A2OXf",
		"API RP 572":"1mW9SbgCxI_5do63sMCLCXdymQJaqfPeN",
		"API RP 573":"1pgCKHpx5FLOaDBCn_TbmB873UEi6cBZF",
		"API RP 557":"1tjSm4cjxanl-zUuPFfqeawVKlS8MPXBE",
		"API RP 538":"14ZygNeQ0Z4RLYWPoTDnyK5yLkt9swgc-",
		"API RP 55":"14y7AMGJF-lMe90ne7D2K23HFs8opF6JN",
		"API RP 54":"1A44TCDW1puK3x__faMs2lE6Fhvkto9IJ",
		"API RP 554 Part 3":"1D-cOhpJi-qW_-PjoHAbJE-tmEn4vqbli",
		"API RP 554 PART 3-2021":"1F_VOIlJQjFhE21gy1dZWLP5R0E0nVFJn",
		"API RP 554 Part 2":"1H-s-2W_GY50-9zxw-VrlTWDVgpMhQhRb",
		"API RP 553":"1Ht6arvO1RinIwGWMSClc-LqsDxoNuDG1",
		"API RP 552":"1QTd6fuKkrq3Afu_KAIKd5BMsc6c8TzpZ",
		"API RP 555":"1VWgFx-bCgWYnxH6KjfZPPAUVmb7iX_3I",
		"API RP 551":"1Yh1CRHAYwpHeRfIIulPczWAsFLwTGXIS",
		"API RP 540":"1c6ZaWWoD97hIVYcfhO5uFVm7JARG53tk",
		"API RP 554":"1gnWR0enCdDcSACJtEERqEkTvK_x98_O1",
		"API RP 554 PART 1":"1qZgKJRRPttD7ply2GsGtkEa4dojmXb4N",
		"API RP 545":"1y8lUA8WzLNM1OEdliFn1teumO7DoOEbH",
		"API RP 500":"1OVFf3GOewfM32xAfSSOWF1p0FKNFpJ8G",
		"API RP 2X":"1YkH-6PQ5dz6s2ZgjQcstjba9eQDQEVhn",
		"API RP 534":"1f_42TOTjhhbX7pWrlKfHAbXFVP47RYSb",
		"API RP 505":"1kn7HVabTNZ4hGg9bOFM_6IAVog5AUyPy",
		"API RP 51":"1qyLwvZCic4EbsAeczP2OdlxP3fEU9Qqo",
		"API RP 31A":"1xX_pa4fE10bgxvpp-YKrl9m0jLNXyiOx",
		"API RP 2Z":"13JJCbv-NuINgl8k45vaQpWnXoqgwYdHq",
		"API RP 536":"15_iqH889M5qUyR586_wEnkEarlQv3cb8",
		"API RP 41":"15xVyNvr2-No9s6Vs5LnfHiDfhM1AENVU",
		"API RP 49":"1ADFN5dUrmMvz8xcI_6XO6VeXl9IdgGYK",
		"API RP 500-21":"1GVTIk7BpcGKmsnnDMC26m3AfVtn_6DGM",
		"API RP 51R":"1IDY1Hmc2v2_rrd7bThqfQFkbDourc3OO",
		"API RP 4G":"1PEf_H8LRaDirfmQi74bvAg-VHDeEQVI2",
		"API RP 50":"1RP-sABoOqYlVCzs_1YuQkwrm4OF8RQk8",
		"API RP 521":"1VfLA3oR_cBmEM_sh6_e2iqqhDV3DdwnB",
		"API RP 45":"1ah-mABuNopBgRHlwXowDswl1KSnycbMx",
		"API RP 535":"1hkUacJVg4uwBGCI0TJIg7DQObPgrAwFS",
		"API RP 52":"1lyapQZVIeN_Hx_u0VvTWdoPxDn7lGTkv",
		"API RP 2N":"10F6Yz_uJgFIQ6NDZLSu6UHT-dAzn7wR5",
		"API RP 2SK":"12HLDdFQNZrtvYwZStJVBPcBkRVhHoZ5G",
		"API RP 2T":"148F1ScTMAPWZlvjTVUw3eYmUK2ABh769",
		"API RP 2SM":"16ANRZ-nHg2OWnkppyI_BzVYQnkTlDX-q",
		"API RP 2FSIM":"17LC_8h6FU7JoO6JRXeUKRofwgOu9VSrh",
		"API RP 2FPS":"1PTd9z6LRThPKHJLgh_rkghPgD6_HCOrU",
		"API RP 2FB":"1Xg5T63j0YyGIeOXOu-0LliWm_yvhJOIQ",
		"API RP 2SIM":"1YwaA2RvLz23UTZmWuPBVu7-T6eepcoeL",
		"API RP 2MOP":"1bPxPk2Csp2xKWLDN0VzOWLBVjkjc7v08",
		"API RP 2RIM":"1d4BmfVsFTQQBDUazRuVXZNDXl2FHPNfc",
		"API RP 2EQ":"1i5lEHyXhZ55R3u0B4mLW62fwvjs2JwSN",
		"API RP 2TOP":"1iYhzx5HXJmEMseL42fuXH1Oh_D3A3QVY",
		"API RP 2MIM":"1kB5rJSAvUf5I0BljHDXGQQIDfrb3ms7j",
		"API RP 2GEO":"1n4lxG8PPmC9Il5Wd1k1SSfZpDL8oR68N",
		"API RP 2I":"1nRueRRArGfEWxd1hi_S-jhpKgBOhRuWE",
		"API RP 2l":"1q1Ddef7Jr7XLel0hH70dar6fcM7AqthU",
		"API RP 2219":"179vHKNq89pvqvUOWqyKcEYSaAUuxw13h",
		"API RP 2611":"1EXPMPmVZh6nauFlf830Ej2dR3sqTL-8w",
		"API RP 20247":"1GJdVkkMv-wn3G7Oe-QN94RmKMXt_sckE",
		"API RP 2030":"1HG4cKPGes2JTgWROlxJDbOkO4r3ednH7",
		"API RP 2200":"1IbtGS37mOK7jEP8nvQhMux2BqnRXjNsj",
		"API RP 2221":"1IgWVr5Dr3it4cinGF-peCV6z17Rv8NDw",
		"API RP 2A-LRFD":"1P0DFKwhQS_DNY4JFmSZfj0K3rfFF4uju",
		"API RP 2021":"1ReB_Tu71vsEsW-9yhon8koOpqwJPJRAy",
		"API RP 2A":"1SIv8O8JwR-Ew8C2amhYy1fp-gFoIDMbB",
		"API RP 2026":"1Sp0_E1NXiVcp0h1dm4eRHB0DBanPW7Rb",
		"API RP 2201":"1WUGqpMGML0Sm5L5bsv0KfAoGI1SYS6D9",
		"API RP 2A-WSD":"1aHQGUySP-R4BLBEcBZmBO4tWL_aBe_wE",
		"API RP 2217A":"1aViCNjFrygqkfr1gj8IRwUrsBp_3Wspt",
		"API RP 2350":"1aWEQJbdBaS-2CS6eLtK4TIgyYeAZypqF",
		"API RP 2010":"1aaMb1wDAvLHENNdLCsgvxexvvwEgl0UL",
		"API RP 2207":"1h_WpVU7oSodmKcc9KZszECAAPbmAKDjt",
		"API RP 2027":"1jO98Zg2vejzJTtgflv19GEKoQ98_5SWU",
		"API RP 2D-2":"1mNV0K_T0IPLaAx40te_ST_yr3YHWUSLc",
		"API RP 2216":"1qat4UvN050l8hdquB5x-oEeEJ5PgcIrL",
		"API RP 2218":"1vwoV-ivz5Pbi8BT5YpDN3CtfxRRT0Vhk",
		"API RP 2028":"1wBOzYpWEYtYUY0RXFM8u5i8YgTSphH2l",
		"API RP 2210":"1wLW9DRKKHyNRlucXh4wlp-KdiDNmNxP5",
		"API RP 2D":"1z5X2gb-yfoBDXlkSjalniZJdhr3NgusN",
		"API RP 16ST":"1HOnO8tSQq7hBVK4_OwLmEJIozqXrno_Q",
		"API RP 2003":"1Of1P3yd1iBcXBAcLsJ19to_D2g2Kyhuo",
		"API RP 17G":"1QlFtFERLIX8mg838B3xb2gkrkIuCKyti",
		"API RP 17A":"1WN2V_q_Ot9sqkk0_vv4z4g47bW31cr6D",
		"API RP 17S":"1ZfVl-kQJK4wMqMjcA1dZLLyzg8-EL8T2",
		"API RP 1FSC - Copy":"1kNfEjyVCP8PHfOpxXf_klYHM88WcqQwe",
		"API RP 1FSC":"1oAA9e2ixoG2ImsJws4nfaJ9B-Ih4qVYn",
		"API RP 17N":"1ptZsfupjz6rZHYzgvzBFwdUfA7Dcw9Iw",
		"API RP 2009":"1q55s8iUHxp3zAVmDzKwjEDknHi11g2J8",
		"API RP 17V":"1qSXggMxEJmGanrJ4WDtbHeu_o9z4ewOw",
		"API RP 19G9":"1wd6og-uUsbXeNGgdvldN2i6X2Mje_zjv",
		"API RP 17B":"1wqUDCpbeuQLZXjRptgJSSAjZa4OEOTOM",
		"API RP 19GLHB":"1xuIcMRYwp_boB4iIlTqcLR4P6t51EHdD",
		"API RP 2001":"1yFZtjnBwc9B4RRK2NfKimHs5BxOhfiFr",
		"API RP 17H":"1yywzKgNv4YPDsbv4HJayjsBudFYZ3Gqn",
		"API RP 1626":"11sTOzXRup4mE2GkvVARi8S1EKFsXGWK6",
		"API RP 13I":"15K-9N0sMF6FJfE72qdsf1O-zeq-UZLlp",
		"API RP 13L":"19AApir_e4K6EgobXqjSPNeV1EkmWb2xv",
		"API RP 14J":"19W0W2ETNcgU1yFN3cZjJaz7gH6_rVijo",
		"API RP 13E":"1BgmgVin5WBQPuUgOvByqrnxZn2NWDCDK",
		"API RP 13J":"1C8LXhu2qHJ3zpGFB4I_CUDh_ZgKZ_PpD",
		"API RP 16Q":"1ItPS0Y79fyoIMw6DViOeiK87XQkahkZk",
		"API RP 1640":"1QlzI65uWN7U6DigUhmBo0I4cQLnIsmv3",
		"API RP 15TL4":"1WI6hSwOy295bQzef20EJlCMEiBLWBzWu",
		"API RP 14 E":"1WRzalHxFObXpiBFRmF_yO3uezeccOYrn",
		"API RP 1543":"1XJ8CCS-MYrnpcbiFFlSjC80g0VWcQknt",
		"API RP 1637":"1a1925_9-RtFSUT55KPqcev5HSClAjA84",
		"API RP 13K":"1jwns-Dt1F3kzgLsvpNspoPii-ofwVRXr",
		"API RP 14B":"1kKjkRfdauERzGQOdsg32gcwlBZ-gSnPt",
		"API RP 14G":"1n5zsRlHB0WU8562fajtup1WBhq4xLDsT",
		"API RP 1615":"1oJ4I3L_NX2_vLEueFCW1qoD-0sIX7mCe",
		"API RP 1595":"1pYsAZJ9j5YJr5VwwiiA0De2bvV81dGWE",
		"API RP 14F":"1r3T_OG1oEw-AaSOs5yQQaBIJ4vPN5WAq",
		"API RP 14E":"1uh4olb4jPSCg_6jNw-vmn7d2WUMI7gO6",
		"API RP 14FZ":"1xhBo0EFehIK4x-x0_267tQQ77UmyBYTM",
		"API RP 14C":"1xj1Eh3OXo7aVTdZb1WfhPvuo85Gw3qmP",
		"API RP 13B-2 ADD 1":"16EI-ZbGN6d2gkk4rhIctUlLo_nBZYkHe",
		"API RP 11S3":"1By8i6yblRzoMTFy7Kazu5g9jaeej5Byr",
		"API RP 13C ":"1G37aE2eyKIqxFkMzXjVf2TZEWe9Lb1d0",
		"API RP 11S4":"1II8XCNZZM5Hs0bQq08p2eeoR3Eu_hDyL",
		"API RP 13D":"1Im-edPQKD3a_KbfjBLCbffl1I9FUMLdA",
		"API RP 11V5":"1Nuo9Nj71LeaLIs2xw20_NO0AU5pgUBKS",
		"API RP 11S6":"1T3mfHqrSfHYlRuu3F1ZvZzV-i6XEk2_l",
		"API RP 13 I":"1YKbMhhSej_TPxUn93f7ongx8dCiLpBJD",
		"API RP 11S7":"1anPie3YAf9jHyBnEX818UETeyGhLovyR",
		"API RP 13B1":"1bdsbrvuw3SgxDvqLCjJGpmt46eqplX98",
		"API RP 11S2":"1oFJeyxutflOOCcAS--SY3CU8vxot5JNa",
		"API RP 12R1":"1tV2YVihOe075Bfx0gmqV1NpGN8pnfZj8",
		"API RP 13B-1":"1tfqQyJ2l1gzPZCybPTnq9VBjHHWrIH3d",
		"API RP 13B-2":"11gZ55N5tryQwyw1e0uhupp62j0xUKg14",
		"API RP 11V7":"1Hnrf1nyMocqhMaJo8rsLAVEhLWwJu2-j",
		"API RP 11V10":"1Omv0lqvbVuaXUKxA0ELWJpyHdDEU7UL3",
		"API RP 11S5":"1ZczBsuCR6FuQpkAZjzOG68Xngs0vfhAn",
		"API RP 13C":"1g3RBKNE1ArVn6ZayPgn7Cyr1tzadG9EY",
		"API RP 11V8":"1qQGAPRdW6wWLWXaJXf-e7XDwXoRVhN0B",
		"API RP 11V2":"1r8HiuuqLlz2oVRpAikBhJ5Upd6pab7ar",
		"API RP 11S8":"1ruU5EVpj6F3Dp0_gvyajyhqZmfFYqX7M",
		"API RP 11V6":"1yimThOf3X3ZSjvkoLCnV4_7wXEYlaMfd",
		"API RP 1110":"12PW939palhXf-co0_NsXrulwBgrYdeA2",
		"API RP 1176":"13gxU_clnGXb8RRnbHTsHPNC7dTxuu2pa",
		"API RP 1113":"14549-P0G-RTGytjKFiMDy9X79lRaxlEy",
		"API RP 1175 CG BUNDLE":"146Gz3NE5BX_FReMshbPdYneeRhAcHsGZ",
		"API RP 1175":"14XFhrLyaOdxGuH7nD0Z-w--gl5Dd6fcm",
		"API RP 11S1":"1Lle56aVHMYKbKYCJr1Q4itd1PCjB7gx5",
		"API RP 11S":"1NvVStnxrpnAMIguWlR4lR-J2Hdg4eWhH",
		"API RP 1161":"1Pb5VbGIthPDLJuBn-j3BXGh0rwI5bnw3",
		"API RP 1175-2ed":"1Q5Oh-H1lmQLCBUQQIJkQrnBBkDusDaUx",
		"API RP 1104":"1SHd--kPuTjLn-IQY1PqtTAskyRFPVpmJ",
		"API RP 1130-2ed":"1TbdWNE08k7jBgi_CJS_5BquI75IKiIWE",
		"API RP 1167":"1UdW5Zd1NUhGViWEaE7_tl7hNjKWDA1dg",
		"API RP 1130":"1XNdtS3yzp7e_4VBdxxTHWtW3m3Br87Ks",
		"API RP 1115":"1_TedFnjlTCA923dEq7GG0G6s12LB02zp",
		"API RP 1111":"1bBPeG8JQcW_K0u7ytOfqqwhwd_Xcfy-i",
		"API RP 11G":"1cTLqHHnepKubhNumMjlDRBQ7m9R57K3J",
		"API RP 1188":"1cgt1msAhnQ2UWsgGwzvfU53sz6EVJUvE",
		"API RP 11AR":"1gbbZ55eP6fJLZ_uqKE31NiozJo7VNagI",
		"API RP 1169":"1jFAKtDrEimm99iWBDnnFuErUsUQ5t8Tf",
		"API RP 11ER":"1l-9FB9F9oZ8NUM7y3vBA2Pu6K33cjvpm",
		"API RP 11BR":"1ob64kpu2_1qCFpWnRg77onONa8Swvx1D",
		"API RP 1160":"1wpbD6UV3Go_lOUVhq947pkJd9xSLubvm",
		"API RP 10B-6":"10hxWUpShyyyIJ84b2LQ8aG9z7H-gffBX",
		"API RP 10 B2":"1Fni7nD2AYj36JNIwtbXreBXArbxunSJI",
		"API RP 1102":"1Q5vWLg_r22NbfO5X3S4tQHjrxduOupJ_",
		"API PUBL 939 A":"1QTOoeZBWkHN6HpjKyhdWOFOwrCKbRoJm",
		"API RP 1007":"1R1f-60pRbAbp2Am79tsRj-bxs9AZEwmV",
		"API Publ 939-A":"1XMjedQxNAKthx3m4LAiN-2qI3hB7P9lc",
		"API RP 10B-4":"1YpMtFdt8tzEP3ie2DB0bgUvqRT5lj1wc",
		"API RP 1004":"1_YWvFMT6Pe-_pt0gvLDY19r2IOypU1bN",
		"API RP 10B-5":"1mFu4aur8zOdmoSSjMpsPWk-V_0hYyp_c",
		"API RP 10D-2":"1mGbeN5l-AkA1BIIPWbcs50cG8_gL5yU-",
		"API REPORT 5TRSR22":"1n_pRbmp8ZTf7UggATPZvRnXrJttLLBXv",
		"API PUBL 939 B":"1oONH6lclZ-adlg6T9OeCo20L5z_WIGys",
		"API RP 10G":"1pKAUbwOqbxI2Gww62DX37XUGCMCxdURj",
		"API RP 10B-2":"1rUIFvUU2f5-JdDEi_SxOem3T4luwJ02d",
		"API RP 10B":"1suzLgucb6LYTi44PV3H9w9h51t-5w0ps",
		"API RP 10B-3":"1t5tFTPhsizSr9rE1CnVICM2qX5hLKwqP",
		"API PUBL 939-B":"1vp1Pg6ylA3vc-fl-Jr9DRcXrTFbxr36V",
		"API RP 10F":"1xyaDHf2A09VOrFy242q-4f4E1wjsvyxI",
		"API PUBL 959":"1z22YwjcjnZhWQxXASMz_HfM2WUD0Huqt",
		"API PUBL 932-A":"12epbswdvAwpVAI3mcmmtsepjeIX0bv1w",
		"API PUBL 7104":"1B2-rNdfCw6i7lMnYVQmmeUQWJNoww1_k",
		"API PUBL 937":"1DSQYi_z1ufQO25wzQB4qMXEDClTfrKCS",
		"API PUBL 770":"1GyFjxBlXVIr0I0Cu5ssTST83iPGDP7Bp",
		"API PUBL 938-A":"1HpBwXhOmGw5SG-WOa7Y4Fdhb6XEd9haZ",
		"API PUBL 7105":"1K8MoIjjsILLx2cUQowQW-uAQ5ZY459Il",
		"API PUBL 937 A":"1M1cj8LAKr1W9BDjioPi1gkWF5hoaFqVv",
		"API PUBL 7103":"1eE1wAOcKXHKkCdo_5XXfSC2MLt9k31AH",
		"API PUBL 938 A":"1k9UtYV-OF26WAAl-3eQm8pUNZZs0VSvv",
		"API PUBL 935":"1sNzJlOWNOojAbZYYJlF28B6bIMy3OKmg",
		"API PUBL 9100B":"1ss7HJS0a4ov4ZVv6yDg4ElimXWqaGfGl",
		"API PUBL 800":"1u8q7r-ihtW9gFz58zd7I_e9LGTsImL8D",
		"API PUBL 534":"15YpBzIzSmyT1jkAZAHmXeHta_lcgowcJ",
		"API PUBL 684":"182ofZhVOUO_pIcFx-enotOJEPiqTH_ui",
		"API PUBL 4772":"18e9gHhdR5N8xi_NTeMwXw2mayYkM3W2A",
		"API PUBL 4784":"18hcoW-skjVum1yWjR2LAUxkWYfq9aOQL",
		"API PUBL 7101":"19OSyVKCOF_ZrasXYrOnYMameH4rAYx1V",
		"API PUBL 4775":"1DVC2LPPkb57yC5AGNHSsnw9X20tHJZhN",
		"API PUBL 4758":"1M3k1OYl3y2OKEYsjNjvOu_VcEPkfVS4R",
		"API PUBL 7102":"1R_MnC_VNelwMk0ePRdU8mdM5GfdVX6KY",
		"API PUBL 581":"1nOIbA-Cf8lEwmjPcQ8Trp5Uql3-lGkiv",
		"API PUBL 4776":"1nt8upCpAWn2GxW886u65sxrbjKHyn0ye",
		"API PUBL 7100":"1vjfGp_Vas3eGXlQyZL7rVwF3oEHduF-L",
		"API PUBL 4716":"12HX4SvjchDhLrSFr4jrWRpzpjc5K-1Lt",
		"API PUBL 4702":"16Alwul2NOykOSDYQeeAlWR7Q0VB9ZQhi",
		"API PUBL 4713":"1J6nMs8kqaiSP0FfFjzzIk6oP5GjEfoqU",
		"API PUBL 4723-A":"1LcwTnfNhE0A_76TQ4IM1Ke99n7qdTecv",
		"API PUBL 4698":"1PNwslMYJnSiKlA3ziaoqA2ASp5CzzHvk",
		"API PUBL 4720":"1PUPBS_0NDQeZv9cB0adxJSahEkeYpzWy",
		"API PUBL 4734":"1XNFwwgWvMBEbRRArUlieR097-BZCDMR0",
		"API PUBL 4743":"1X_mPUVeXYGiClQ8osEITiD2M_yEm56B5",
		"API PUBL 4723":"1ZvWPnm-0WtXrhAG2n82ZwKRXwjk_UTaZ",
		"API PUBL 4722":"1_kuO-CMzXaqukgr6mJjfyeKqp7Tmd8S9",
		"API PUBL 4704":"1d4CX5ZTfw63YnE3CSGyi0t1NE27G6gKD",
		"API PUBL 4703":"1drIqAizOlitRriGMa6wCq1ji2QGXD_MV",
		"API PUBL 4717":"1fc5wwAGwHN2Vl0cQM9opYKoVE-Oz6zW7",
		"API PUBL 4730":"1iIX9fVf1Hj-7hvp0G4Jn7HudAGgjfjUz",
		"API PUBL 4700":"1neemHxNHLDsNxNeF3-amUNS8inS2XtsK",
		"API PUBL 4712":"1u9KDbJ_xNg32FttOuySoW2ZJFtQwp984",
		"API PUBL 4714":"1vljjJpHUroYonZrqXM5xbbtquoSCitc9",
		"API PUBL 4731":"1ynzVMQcBo8OpWN7ATM_Dp0-0gXsa6HTT",
		"API PUBL 4694":"1GZCQOQ-z92Pm_apFLprOydgRQpXn2QqX",
		"API PUBL 4689":"1LmngVA6t95cY4K_FgDuptEDwY8QuFEvi",
		"API PUBL 4663":"1OWjt8EeMuOYyFRL9JcEdcPThJ0TcK-sd",
		"API PUBL 4680":"1_VYpA2bVxmphh3Hemsxh2xB6mCb5xbDU",
		"API PUBL 4677":"1pPw93czrueYYFqkB1oYBAlpKtRL-jLjg",
		"API PUBL 4666":"1sr2YsNJ-L2P-IZE90wbhp2aIc16fZfPp",
		"API PUBL 4681":"1vEwrZ5Th8NHmNA2eqdakR57x70wmRir4",
		"API PUBL 4671":"1wmSsLgrfhrbNR6m5yGpEafpOLfiMcOcd",
		"API PUBL 4674":"1xx9tNfvs-edKHZ7cInx-0iFgRVl3N6K6",
		"API PUBL 4688":"17xyOspvOpxVYUaz7bqtcN_gDs4aCv84U",
		"API PUBL 4678":"19kpLwVhy89TciifTb9OS8int1NCBFx5j",
		"API PUBL 4667":"1aIneoMZMefSa1lhUVUGTg1GSNuA18ANc",
		"API PUBL 4676":"1j-6Hv6VA5CzLGjJtR9kYWDodByy5OgVu",
		"API PUBL 4683":"1sa1wpEvEg5N1coHHpzlFxkS1_Q76zp39",
		"API PUBL 4695":"1w1-mgsyWnBbz0SPf1RfFg9_gPYlAH-R-",
		"API PUBL 4658":"122pUu66-hMLKjhJw9YBQM5iqucsR1s1H",
		"API PUBL 4642":"1997Bvfmvc-tvwqSB8Xt8HMnohUoObkeZ",
		"API PUBL 4653":"1CuOGUazG13JXzfqb2HOe5CR8tIisRQ6F",
		"API PUBL 4647":"1H0DQQ9yYeoOvie2Mh8pSMiz8Kb9Xn8R3",
		"API PUBL 4657":"1HtfXSGSrAqvAIbM6ipmJlW3RPFPg8k3m",
		"API PUBL 4654":"1IJcFd9CmWZz3tUhXByCFzXYWB7-HQc3g",
		"API PUBL 4644":"1IcYygVYa9r6JIKRq1Ih0QjTA-TiXVU3H",
		"API PUBL 4650":"1OnUinea4kCHhfF7rZNwHFiPU3Wuo4EP9",
		"API PUBL 4643":"1OvQS3vjO_3CtNxtLGCQlmBkTIvqiAPV7",
		"API PUBL 4662":"1U4TrGzBdQdaT2KZtIUlv22Y-L3MELDY7",
		"API PUBL 4661":"1YnwF1HU5nrisFTaBz6xHvzZ6GaqRBrmS",
		"API PUBL 4646":"1d8vCjmBLX-w3adyzM3qBfvOQkv96ht4-",
		"API PUBL 4648":"1otBcsd5ir4c_NS5Y0xmWqubNmS3qr99Z",
		"API PUBL 4645":"1p0eas8__E62pm_enpXGdda1StOlTQ34b",
		"API PUBL 4655":"1wA3SJIvr6P5gHEXI-MpEYWWfsLFCyQ5a",
		"API PUBL 4638":"16jhWP7OKkAYMWSGOIo8MW2kaYviwUJim",
		"API PUBL 4635":"18fzTiSef99JeMKbCz_odEtKxd90WrWrm",
		"API PUBL 4639":"1AxPEvwFtYVl4CdrWjdrE4tmTzo8MpbD3",
		"API PUBL 4627":"1Cp54BZDw49UbtX2J6yfDsbjxj2E9GcSX",
		"API PUBL 4634":"1FHXqfYtvrqnUv7k3MaVk2Aw_1egO-vPq",
		"API PUBL 4631":"1U1hS3qsHzjEgMXjm00sL8_4qFQlcGQ1a",
		"API PUBL 4633":"1VWuukL62RaLaFoVjMLfTEKNbeQo_03Sv",
		"API PUBL 4628":"1d4tMUlCYTjwERIi8FU1F-ZAZnQzhhw6G",
		"API PUBL 4641":"1ncbQEqpZKHLAEvNQB9EdlTCUfsmVcvql",
		"API PUBL 4637":"1rZgH8aq1jDidcc06UOve7fNp24npl2ZU",
		"API PUBL 4629":"1xxDniYqWGyqFGNSJ5lEQyNlY9YEYzm80",
		"API PUBL 4615":"1435BEUlCBeFXnw4vYZygeixLOhVoMZ57",
		"API PUBL 4609":"14ZTAWeQP9RSB2ncvV9OKHQFI7ETDXXY5",
		"API PUBL 4610":"1BOl-3q6c5t6mHD5eW891MHxzUHl0XTx6",
		"API PUBL 4613":"1ItOAR1AvJdqlN22d6HZvro1_69zTUQhv",
		"API PUBL 4616":"1Jpc2meQ9rzIFUD7Av7ISlbsb-GNcwfWN",
		"API PUBL 4611":"1g5scTDImy64O7q77X4cRGbbcp3DbtJ2T",
		"API PUBL 4619":"1q25GveUb9moywvOBu4YWucNnRWtze6z3",
		"API PUBL 4617":"1rEKtnavC-T40My-J1yUEz76SnzhwP6gf",
		"API PUBL 4612":"1riGMAQLLPxYStqPURsb8d56jchs5Blbc",
		"API PUBL 4623":"1uOPAycZ581jVdbm42wy2htUI4xQjROAI",
		"API PUBL 4625":"1ve-oG9RGiskabHpALfT9RXdeNuqmTIdA",
		"API PUBL 4622":"1zNPdi8ITq0V3xXIMqSn-60TvEm8_mZAX",
		"API PUBL 4593":"1B-DwNkCVHTUvK8JXDzfVW90joh7_4fKj",
		"API PUBL 4605":"1B4j0ebahJ8SWKbIpDExlXBltjN-btXK1",
		"API PUBL 4599":"1GAHjUiTuEoNjLr5nnK6MECVMmVM_xWq5",
		"API PUBL 45881":"1Ow0FzZyOdBGX8ADyhp5iNMxmkZTQyrS2",
		"API PUBL 4588":"1UqdZfcRAmEcrjdFcIgJfvGBP4odXw0Ko",
		"API PUBL 4602":"1V-bhwPlWWTOeqtmMwlqFHXswtuDN60RX",
		"API PUBL 4594":"1b_0wbcg-GmElcglYS2z1adis3wp6sw9F",
		"API PUBL 4589":"1dm2JGHVm6ETsS2cGD-rzC5hGDf0bB_um",
		"API PUBL 4601":"1joZYDrgZ-2A8VcfdMoIpFYO_hw4bQJZB",
		"API PUBL 4527":"15rMezfH3mdyzS7XXlEqkapQrYoLGtO3T",
		"API PUBL 345":"170AYsOkdC3nguGkb0CKIktRa9GAcANkM",
		"API PUBL 348 V1":"1A5x_6zmXoOcTsY3j10rhYQ2uNSbpU-3A",
		"API PUBL 45592":"1JtYrEys9EcyoO__ypeqjJxVJQIjV45hE",
		"API PUBL 4262":"1RTwm05rn-5uYMdYGwmtdX_-gMW1pmjVQ",
		"API PUBL 4525":"1a--54lBra1cxb7zb9kde8abkfQzFujhZ",
		"API PUBL 4546":"1ceEQOoWyI4Kcrjp8hIaV8QftXZZDKyDY",
		"API PUBL 4531":"1dfhzGipfg-WQ3DDf5n8WNKOW7jbPTYd6",
		"API PUBL 347":"1gH6118-3OXTq68rqrZnzyi5m28mOzzeR",
		"API PUBL 4587":"1h6Tye8pQvrN0sH6zjhZzV-IcFgVhIZ92",
		"API PUBL 353":"1huKTN2yVFOGrozKCn7Ixk1Nri2BQx9ES",
		"API PUBL 344":"1kLtMtrIteX0RtF3C_FoVVQYU5687WWFK",
		"API PUBL 4261":"1ljx6dXZv8yUnQCsenPqJJlL39_KKoBTg",
		"API PUBL 422":"1x44SqPa3Jl44JZpWZowv0puiIt9qcSy-",
		"API PUBL 334":"15-b0zN7ScSazz-wTbSIPRe3n-oP9tTSs",
		"API PUBL 326":"16zYBryJ-VJru8dq6itib2oGPdABEcwWw",
		"API PUBL 333":"180yZXbeOkTR5J_mmnOoRHCva2ma2tySo",
		"API PUBL 336":"1IAek4n-9mtBlkdYM-Fj4NNfzoriZMmTk",
		"API PUBL 332":"1Kal6Erq46mfcliq7mqImtA2aferPzK5g",
		"API PUBL 331":"1SFl7bEOzouzyIXnrAFwzuHwf2m5IGh5F",
		"API PUBL 324":"1UUOFldsw2aklDQKorj1shg-Pa5LLMQar",
		"API PUBL 337":"1ZzSo_iNG7MJON-XaJ36hlJajvhm-ctp1",
		"API PUBL 328":"1_0Ux1-2oClP4-d5z-tMPL3rwmawoZG0b",
		"API PUBL 339":"1kK-jtAacL1AgXNxtlhwI9gqv_8Dxg3cn",
		"API PUBL 316":"1lk8ISlg70w1N_bC-dYneakixg4CDnJmm",
		"API PUBL 327":"1q69yqMlCMcrfW1QuyHaqoor3Bc5ndc-4",
		"API PUBL 343":"1vk1KY8WGBiDuZkghgbeXlBvoKPt2Atpw",
		"API PUBL 317":"1weO1ErXvk8z0QpKl-6FDxPMjUwh74S-m",
		"API PUBL 342":"1wwoPaJxLXdWSRRGETYsg3Ol2aJ81LuhE",
		"API PUBL 329":"1zegMAPc9ZPsovRqcLohhbMQDqi3n0gOJ",
		"API PUBL 31101":"1-0bP0DGDgUeptwMFtuvkf3bZ2y5avNX5",
		"API PUBL 307":"13MEXryxHkD-Xz2wLlBiYuOqBm037NMr-",
		"API PUBL 303":"13h6XFNbxUG6EWDv4eHFPYNsJ1WWU2ROR",
		"API PUBL 306":"13uYRQisReOjVY1gILeRrtxLaRof3BDdH",
		"API PUBL 309":"14IZ9S5oAwr4u_Yh35kpbj6xgjhffI6Ig",
		"API PUBL 305":"14Mr9CgDrjw1-npa2nJSdf-GLqCQemiFV",
		"API PUBL 312":"18iVX7o7MVbN9uNxWyW0xULl0v8pJ1FD_",
		"API PUBL 311":"195ainWFFCsohIBNlMhHM-88SLR6C3ZZk",
		"API PUBL 2510A":"1Cl_c-TzgY4S7gfvF0SvZ-L1Mj3PdKA3T",
		"API PUBL 300":"1GabXruVJnIhASZo3EjIXdBJ_fi7FG4iD",
		"API PUBL 301":"1NVQgz3mvNAikCD3lsCkEwriuOzX3VPUT",
		"API PUBL 302":"1WYcsKwJ0tXMNsqwuxxon_UYup7HqWB2q",
		"API PUBL 310":"1ntiHGugGxPBaQQROxIl8xwMfFM5xxOGA",
		"API PUBL 2566":"1toPMaxQqvYibeoUZju4bR7EmQfPt6yAh",
		"API PUBL 304":"1zKC88vip9-vVY1wHYSunVGjV8xHvaHYa",
		"API MPMS Chapter 12.2 part 2":"1VFrCvAu06jUfSRCPw3cATNiWKKxU3SuJ",
		"API PUBL 2377":"1WG6XXAeY2UyjCN28r2J1vAvDkX-3pKoQ",
		"API MPMS Chapter 5.6":"1-_UuYmyYh-NjMXLpBaUgco9qc-asJesi",
		"API PUBL 2380 - 2001":"1-gJegBbRvAh7ZS7gmvaOSrobJdWNlVvW",
		"API MPMS Chapter 17.8":"1-pfdFp5-rc0-2HA3myNX6rt-IyHJ3bt3",
		"API MPMS Chapter 12.2 part 3":"17Yq6Ynlodew9KC1WldX95x8I8TtDZCFl",
		"API MPMS CHAPTER 7":"1BeixwiYUr67GKmXERvgEF7ZfBV8s3d-B",
		"API MPMS Chapter 13.2":"1DLiJlD_JCMa6pZDPYj4o9rZJLLPFX2gO",
		"API MPMS DRAFT STANDARD":"1DUAnX4YBFIt8aZrx3kGEwkc3S8u435yN",
		"API MPMS Chapter 12.2.2pdf":"1EhufS6QXvkOEAUpO4P124qxQoHTAue8T",
		"API MPMS TR 2570":"1HlpLn2R3viVbXcgNVqaTlanH6mbyybOA",
		"API PUBL 1612":"1IMx0Z0rYpjTPLWhlwLC8L5dz86HyJwog",
		"API MPMS Chapter 12.2.1pdf":"1PuKj0M8nG5Zekh4P3BoZYP7F0f-MzLQB",
		"API PUBL 2380":"1QOafDTdV7N6Lmqb-hqeWGl9AetxW_7qu",
		"API PUBL 2376":"1_T4o5f1PUTkmd5ixy2d8p9rquM0Z1yuj",
		"API MPMS Chapter 12.3":"1aBd3wkjR_aFL0AEDXv-Tqp-67VazhLBF",
		"API PUBL 2375":"1ig2moJwBHNgaRtqVaYY1vqm7s1xQIjmK",
		"API MPMS Chapter 12.2.4":"1n3Qfn5mul26sGr4n-EmDe8qMaVKBN1db",
		"API OSRC":"1oVGbicvDfT_ISDtwmbe5pXcEUyz25Xvd",
		"API MPMS Chapter 12.2.3":"1rWxZT7HGhDLRaKOLYFdjjAmOPEom6Dlz",
		"API MPMS Chapter 12.2.5":"1rb0BgFpfuT8oxS7KKgK8ewa6Xf7WXy5n",
		"API PUBL 1638":"1sDobBbZrOEr6k9zk3TaLpt3FrDqKrRUq",
		"API PUBL 2378":"1tUfEPUCV9dSAsh0V4fGDpS9JdOZGHWnO",
		"API MPMS":"1urvGhavo7_b7eDlb74SqZn5UyLNrIKQU",
		"API MPMS Chapter 13.1":"1viMZrQO2NfE4M3-qprnm0j0nfWga2u5F",
		"API MPMS 8.5":"121_JXfa5cHE5GN5_EZLuknti2vL2kgM2",
		"API MPMS 8.2":"1A70cmkqmwmSqj7KLzPtXYhC2ELuVrlmy",
		"API MPMS 8.3":"1CPgwhxlTgu_EjFFOlFTbt156U5TFMUAB",
		"API MPMS Chapter 12.1.1":"1D8G7mBYxsxPypKoYAUHD7jIbQa-gj1K_",
		"API MPMS CH 3.2":"1H9y6tXeof19bb-1lUKLXN_xhFDzvczcG",
		"API MPMS 8.6":"1ITKVjRxLLOc_kXKQu9f694qhRibMzrux",
		"API MPMS Chapter 11":"1J5Z8tcCAUfBFlWLz7NF5IxPR0ND83OhW",
		"API MPMS CH 3.3":"1L_CrzyVASKJAFCCunIKG7U8KzDNoK988",
		"API MPMS 9.1":"1OOmmJkBaypGpsfnrYh33_wTrBCApiaAY",
		"API MPMS 9.4":"1TRvslytgfZfjYz12_4X0IGx1CiHauwzN",
		"API MPMS Chapter 12.2 Part 1":"1Y887xEbWsJ0MhT4SKyMBWK8YWOm4QvEa",
		"API MPMS 8.1":"1cNRPHJmISylzDbT3HLfheZxt1C8ke400",
		"API MPMS CH 3.6":"1hnWGX6GmdTHrcmAHwl0Cb9RKBfX-QEVz",
		"API MPMS 9.2":"1jVZGPbHu7cw6poEGNpU6vlRRR9ZFhfuL",
		"API MPMS CH 3.1A":"1qT2qvrK_0JWd8jGpcYQIBJo0Zjlaop7E",
		"API MPMS 8.4":"1ssHKyCFcmRGOLQdcGWGbhd8F5X-uPfx7",
		"API MPMS CH 3.5":"1tjOzE6JDVOJVz2xgPXbyHXoVjSvze7Rm",
		"API MPMS CH 3.1B":"1tn0v661idg0e2_xmubaFHlRokDYzGynN",
		"API MPMS Chapter 12.1.2":"1whK1yof8-iC0hNN7DbeRTO-hC-8Bbmv0",
		"API MPMS 9.3":"1xE-wv1fZXPP8rHFJhI5rTq7v0HzLiPns",
		"API MPMS CH 3.4":"1yrzL7ggMPoYY-2fBmQgWJhxGtNr4rhtq",
		"API MPMS 7.2":"11WR4yMVQf3lQXjxac0J5EWgniJHmBRKu",
		"API MPMS 6.6":"12VMc3XJnxjeltzvajVZECySd7ppnZ7d7",
		"API MPMS 4.9.3":"143DCUMa3z9ynrv4Qs1Yqr3yvf_Lsi_Vb",
		"API MPMS 7.1":"15n9FEJqqFymRupnuy8xVKDFaHCO6s3sG",
		"API MPMS 5.3":"16lC1qC99f1QsM5-tIqJe1SpyAgrC7exa",
		"API MPMS 5.5":"17I8Fls83tZfSwXwmnciy3b6P-1qCg8W2",
		"API MPMS 5.4":"17h6xhWFUh_DBxbZRfcF-jW0k0m826f-E",
		"API MPMS 6.1":"18XV3WuHzEkrzcDyC8IMaFQv8FBUgNNGd",
		"API MPMS 6.2A":"1CwOPh3A-AZKcG66ZzXpqYiV1zAIojk7U",
		"API MPMS 7.2 ERTA 1":"1G_xDkyNDX4L-PF-fgXWVnQVTA6GRJk1a",
		"API MPMS 5.1":"1Iy745OTp2vWql32XkrlGdt4MDlwtUXPx",
		"API MPMS 6.7":"1JDkKRss2Tsvt_Vg3c2T--QS_WnYyWDYb",
		"API MPMS 7.4":"1JN-BdQ7xrcWTb_ZsjfwH9KtwaC_PDNcK",
		"API MPMS 7 ADD 1":"1KHyWNgl7aVdTCrigL0Nla5lFrEUJyLur",
		"API MPMS 4.9.4":"1Mp76P2P6V7J2tV0MAW0n2E7y2vt6WNNi",
		"API MPMS 4.9.1":"1OP6-I0XdBDsdodIkqAwbYMyvwFVcs9Hp",
		"API MPMS 5.8":"1OrQDKTH0huXYPInBYIlc80EeiHBq2Ogk",
		"API MPMS 6.1A":"1UwVqKy3jR2ZuWqjZQUvhd7XCBzHyNXbx",
		"API MPMS 4.8":"1W_CBLpZKq6bSHR9hDrOBOJvxXpAW-YAD",
		"API MPMS 5.2":"1_ABN3JngXJ9_3jw1f3a3Z02Ffb7LJO4t",
		"API MPMS 7":"1euq0hUX2XzMJcktRVHavagp5IZwio9K7",
		"API MPMS 6.3A":"1hYST28JWHYLrh6TaZH9Qvxlq45SKzPep",
		"API MPMS 4.7":"1i1evmKy3qJTVsANKUU4KM05SbHvosW2g",
		"API MPMS 5.6":"1iXJbXm6A7NOlhA4CP-ARudsEk3q03STM",
		"API MPMS 4.9.2":"1r5L10yIVCQVSQsf5CX5AmTYLohFw3pRf",
		"API MPMS 6.5":"1rWdn2JGn4tzgXYSX14b9d5rZhLQkDaf_",
		"API MPMS 7.3":"1tTlL8mxvyTruGEVivp9Mvb2H0uFSNxee",
		"API MPMS 6.2":"1vfg5wXDn2dcWr3CNbiiJcjjslc4H1Kqv",
		"API MPMS 20.1":"1-zK4XEJ7YhvvC0Bm_zkK2K4TVdkhmHzg",
		"API MPMS 3.2":"10B5z74nnNsIkSJwiij9IykDq4XYFGj4b",
		"API MPMS 20.3":"10i0muqepgH101IqHKE-9HVUAOeduXYon",
		"API MPMS 20.5":"11jCf8z1Fiw-TE34FHXI9WdpMLMFkALPq",
		"API MPMS 4.1":"144JpVYkc8Mdmu8Dm47WR8pEQW7tAa4Fy",
		"API MPMS 23.1":"16AV_dOHgTng1igZOW2LsJekdYK7DiETL",
		"API MPMS 2.2G":"18NjaXoTpSqZHReyQ1oOIZQUMT7VjuTuc",
		"API MPMS 3.6":"18W4p-ozd9UpWtnghIjIJCXOPYDxpepBi",
		"API MPMS 3.1B":"1GB1LabgBgnCFhh6NqIdZX9IoM_u-hLQT",
		"API MPMS 4.2":"1JnZcZE9uxlfwg7QkgKWW9ZVwXt10qBOV",
		"API MPMS 3.4":"1LZ9GsB7yA2DMLb863vP9zpqB8a1QCgZe",
		"API MPMS 3.3":"1PBoeLhOTjp8g_qxVuyYu0PgejqvXUIJA",
		"API MPMS 4.5":"1PJdLgpBR5Q3muFZlkO2u3Q72zvl29iR0",
		"API MPMS 2.7":"1_SJb4ZRbRB6i17mqpd7yfdKexdo9Fwnf",
		"API MPMS 4.6":"1_c-7VPA4WfvyiM1SwE46XGrZzAOyxzXJ",
		"API MPMS 3.5":"1d1H3VMppfcHoGLcqksGWa7OyWnh5r8vv",
		"API MPMS 22.4":"1dxIVsjBPQUfVtqoGunWyQ0Wz_9yyFsgy",
		"API MPMS 21.2":"1eAnx6fW5Pc2uHmU8rfsSGg0zTtwqCDyS",
		"API MPMS 20.2":"1l3jgE6g-CMqrvCX_lrNbHe-Qw2FQ7Det",
		"API MPMS 22.1":"1mPUhvxzZMwVYYCgyu7kd0HOPYWajKW3n",
		"API MPMS 3.1A":"1mkYCc6fNHhTT6bqdaEmoQX_fIQf5Bbby",
		"API MPMS 2.8A":"1n78yD1j2YUGEQ6fS51pl2piFMbsdiAA4",
		"API MPMS 4.4":"1rWLyS04t7sCVxCjpwGE9N5mWI5wKd_m5",
		"API MPMS 21.2 ADD":"1v8s_miAtRG6HhhvLxxy7sdjDqHPtJHc6",
		"API MPMS 22.2":"1wZvFrQVcov0jJtJIucbqURtcNh077SjI",
		"API MPMS 2.8B":"1x9K_dIdyS9V6qZJ6XgqF1vmhffRkgj5y",
		"API MPMS 21.1":"1xj2TgRizrn9OMfA-TEb8EJwMau0BQPUN",
		"API MPMS 19.4":"1asHBIJNjYprr5Jgegjf0Gwpb7GaveRwS",
		"API MPMS 17.5 DATASHEET":"11pRHV_XSTuzTTzNoDVM9HAE7Ly_tFoha",
		"API MPMS 17.5 SPANISH":"13-HBY0prdCTFC_oMaTnbqapQIk5hcwif",
		"API MPMS 19.1D":"13g_kQ3nY86DVVAAuFiKAaGIa8NXnWN6f",
		"API MPMS 18.2":"18NamsD6cOlPNRVSxhbrsrRPF5x9CUQ0w",
		"API MPMS 2.2A":"1BRZy49Oe6rSLBNhfntwq4_Tok_9CGAgG",
		"API MPMS 17.9":"1Co0T-WYDL_HBI_0CGZqcWY5lhTnufLH6",
		"API MPMS 17.5":"1ExSyBWfrJUZfKMGGSVEA4hdRZINZKBun",
		"API MPMS 17.4":"1Fek9mCVrb80Mo3zqRjavtzZ1tZDFxHjq",
		"API MPMS 18.1":"1Hj8gCgUJmZwWe10H4zMUcN-xtjjTmL1p",
		"API MPMS 19.2":"1IKCmeIbPhqMQrrovTVpc5pDHvnHtpqzE",
		"API MPMS 19.3 PART A":"1MXxqCzzUeuBsoOC6E0MACdfi0MrY4V2F",
		"API MPMS 19.1":"1MrmATEjrOYv7ZdDrhReoO-CBQ05ej5ZZ",
		"API MPMS 19.5":"1WxD40ZGAmarNWLqcd-Nu-DF-m08o5JB6",
		"API MPMS 17.7":"1XuXxmNOy8Y8mvghywS05Acn50-eMfQFC",
		"API MPMS 19.3 PART B":"1Y7ocEhNiZI8GFdYTSlOZgLlEaPJp2L7r",
		"API MPMS 17.6":"1YjQGDYFtI9949_S-eRn5x2MVJm_PAm1K",
		"API MPMS 17.8":"1ZchtJKJChxMNjYRnKmiFRiTz4I6sC5uh",
		"API MPMS 19.3 PART C":"1awn0zxGhTvLHuG3FHa9aXDfTc1CqfgS9",
		"API MPMS 2.2F":"1e3y4cJOD3n3Nk7atvsvFas9-xdUCXW6o",
		"API MPMS 19.3 PART D":"1mO7JrznlQxG1jCECLw5f5QDzWIJ8n8xk",
		"API MPMS 2.2E":"1n6KB0tU56S3a9hOIR0UGwBOqjlSyYqW8",
		"API MPMS 2.2B":"1n7G97DxHM5o1fcCrr2hBwTVxydAxAPLF",
		"API MPMS 19.3 PART E":"1rHMgEjMh8RtwT_70B47N6MrxbsoonWKf",
		"API MPMS 2.2D":"1rVvNUdWwavw4d2zB_64-CSSJE1i_cGZr",
		"API MPMS 14.3.3":"11MQwuvog1C8OFqsC3-ytQ1A8Y_akbXt2",
		"API MPMS 14.3.4":"13IJgYa8zRN4yfz7FhQVZYon93-e-RIqm",
		"API MPMS 14.3.4 - Orif":"18GKgjoLtp66AeJyUoZ4cxKhr5tydpcIj",
		"API MPMS 14.8":"1COeg2GKHib_bdtCXqjG21z_EhJ-KMamV",
		"API MPMS 14.4":"1HlNP1Zyt-8BRG9XjT_ELLYTOuUZyuFe9",
		"API MPMS 17.1":"1I8Zi42pqZxon8Xbz-kRRxftI7eaEBBoe",
		"API MPMS 17.2":"1IqtZpI9JsahulL53TPyGcv8t4uybknRf",
		"API MPMS 14.12":"1K0MORehGNMrEAXQrW4uws_srNWdDxpFB",
		"API MPMS 17.12":"1Ryk9r9-2zhZhQ0adzbq5J3nzyvdhWdl4",
		"API MPMS 14.3.2":"1Ueb_l-TDhp0ZDjYIATGwjvI3mn5iVRjH",
		"API MPMS 17.3":"1UtnxStRB3Uww34N7bQutnhiR1SDV7DcY",
		"API MPMS 14.3.1":"1jOuy5FydroUCsiKPvBlhXDJ6xZFfbcTG",
		"API MPMS 16.2":"1mBk3wmye1ONv4HXqklbWSXCYQxNMFntn",
		"API MPMS 14.5":"1om7p5dcrqnzmiTTtM4izujfstFWIgGdt",
		"API MPMS 17.14.2":"1pD7MlsEwjrQk7l0LseYCyBYQB-tB-6qW",
		"API MPMS 17.10.2":"1rREae_Qcu7FDyzJKq238gteURkNf7ToH",
		"API MPMS 17.11":"1uto8qdBNTFoYFmZBs_BscI6ai1gm6WYp",
		"API MPMS 14.7":"1wMMuHb4wxWf5I-t9IKsnPIasWRkNcXrt",
		"API MPMS 17.14.1":"1x9EEKvwxm1xWDXBUPJTM48PoaylR-Azv",
		"API MPMS 15":"1xhZLM0L8DSSHS1KhWxqCmMgrMqlIwsL8",
		"API MPMS 12.2.1":"1-LUIJ-z_xYPwqyYcZUv6KMvT7Zm-G4n_",
		"API MPMS 12.2.4 ERTA":"1-ZPFfdiP9BlOjmb_saynlj0DHCCFMMAJ",
		"API MPMS 12.3 ADD 1":"11YxT2ZIWcUFLqtXWpsXPmq3m_NlwLpDC",
		"API MPMS 14.10":"138ksLQnKaMY73Y67jUF-moJkNOdjcpY-",
		"API MPMS 12.3":"187udeOCg5rUIerwfuTLDsHgma-968rZo",
		"API MPMS 12.1.1":"1Cur1y5Ldzx6Qew745B0jWV9TLKyCYARB",
		"API MPMS 13.2":"1K51Us6WAeY6c-LKlP7x-aFjcXsMWmWA1",
		"API MPMS 12.2.3 ADD 1":"1OCHQS0lWyPerSuW0eAyW_2MoJeYZ12fm",
		"API MPMS 12.1.2":"1OUsdU-Ad7slZDrex7WLRfXR6CI1KhVOx",
		"API MPMS 11.5":"1P0jSD7jn5zAPS9T7ZaQN7e6HZu4vKXYP",
		"API MPMS 12.3 ADD":"1ROQxHoucdLnfoyDO2qUtigHC-Lc7XCHY",
		"API MPMS 12.2.4":"1XWdZxP0yapl1cMq6rwl2MruHgZAwMuZq",
		"API MPMS 12.2":"1XjMhXZSm01T1GVnUECRNEWcRFwu363l-",
		"API MPMS 13.3":"1dwh7cFjjbLer9c_ODoJMSI0NpuOBi-bJ",
		"API MPMS 12.2.2":"1i8wO1jxNzBoPRywOVhWTvSeZlMflbHk6",
		"API MPMS 12.2.5":"1isMIpdH7HCXDwu8_g_QJgwYgX7uo_bdl",
		"API MPMS 12.2.4 ADD 1":"1k0jo0Walsb2Qy02HV-czzUA3lU1jYNU8",
		"API MPMS 12.2 -":"1nHJQMsWVDp6qGfDlQTLCMxLP9t4ItSU_",
		"API MPMS 13.1":"1ppzRFBW1yBWLkyV2QCr4CP0TlkzBs9FW",
		"API MPMS 12.3 ADD 1 -":"1qQGRlan0i8iMkINmJzOVYrpusV8PhQGS",
		"API MPMS 12.2.1 ERTA":"1sn1fgJVG4G_fmFLtE0Ifd_CL2xH_XrEU",
		"API MPMS 14.1":"1t8IBY0IDgBnAFgpFzNJqlfXx2aIBvmQb",
		"API MPMS 12.2.3":"1yazXZEi0R7LQVr1c5Snok6MMiF7jU1pH",
		"API MPMS 11.4.1":"15XgS4-RwI61cYhv66HaTWJaGA_s5ZCVw",
		"API MPMS 11.3.4":"167W_Vidi3zpw2dajP9XMbMjl0kN2S_OO",
		"API MPMS 11.2.4":"1DKMbP5Tt0vMXzlTomYxd0I-uBWVRwErz",
		"API MPMS 11.5.1":"1GH7VqUtICL0_iB26pntOpOa3qb2aXqkS",
		"API MPMS 11.3.3":"1Kej2SXEPaZswkCKkGhgpUyUGGkRCMXlh",
		"API MPMS 11.3.3.2":"1LQviYTBbGNHHk-AGc4qOVwm4TzN5bH-q",
		"API MPMS 11.2":"1LVrB3WzUgG1Ep_bBdIX3bXBpvE2QKAe8",
		"API MPMS 11.2.2":"1QI4nVuilnipn4UTCHRU-nrON4SR_NmOu",
		"API MPMS 11.2.5":"1S9XKAorWqaKQHX-ZVEcz3_McsNbGS7Ey",
		"API MPMS 11.5.3":"1SNAanDIeeWEnWcriDbVBKkVqJn0NIWha",
		"API MPMS 11.5.2":"1hLLMluu_heHb_s7ElkZW93gjrKwKqiEq",
		"API MPMS 11.2.2M":"1jrVtOCtF9cV6nuAumUCErDXkg4jS_6zG",
		"API MPMS 11.3.4 -":"1m7IfQjSlKQcUVf_YZ9_DVKRooV_UoZe4",
		"API MPMS 11.3.2.1":"1sdyTPtcSS2lU8nPMdlioKrneT8-xUGjz",
		"API MPMS 1":"1-Ug1C_gsR05ezXLwEXhiOmxm0Tdw-Xgv",
		"API MPMS 11.1 ADD 2":"11Y6JblQS0lQkXudU5jZdDM25FIhTS5iY",
		"API MPMS 10.8":"13Ce5qig8FpHdFuO5Ufz33sDbQP3_y_mK",
		"API MPMS 10.1":"1JksZYdZilwyxP9RG33d0cbwzHWWL9VKo",
		"API DR 351":"1PQsP1s6ZGX-ZqgTBo7tvJivwjZDoMsQt",
		"API MPMS 10.2":"1PgE5c_CSKU267yTo-raoKKSz0zOPeBN5",
		"API MPMS 11.1":"1RKpeUsnYrmeJoEx8xYr6N5EsPjujfxqH",
		"API HUMAN FACTORS 2006":"1UCkYHcBMIotNm0nBKCIRoCXjvahVmZz0",
		"API E5":"1UVszQm6w4gix4Piq8OcOZyNa6-YGDHWV",
		"API DR 342":"1UWnK9ZcR7axMbo1sEraUqsRHK6qkfs4s",
		"API MPMS 10.3":"1abZaA7oOTixUnYZbM6YRqAOHvfjGgWc7",
		"API MPMS 10.5":"1fYvCXbnak2cGPEqF77AvHx4V2DRP3xmh",
		"API MPMS  5.1":"1gjgTXCVdEEg3Bu_jvQINbgcYFTJpDpq8",
		"API MPMS 10.9":"1m9nz38K2-S6sS71GjFOZGAUvEwJb78jB",
		"API HUMAN FACTORS 2005":"1nGrX4GncEwMTcsc98SIcSOUv8_AnRORa",
		"API DR 76":"1sBdznLDDIOcRTBubeOjwQkYfuuRdvno0",
		"API DR 53":"1ueNJ8ssj3o1s6kIOuh-5TINebU8B4zqh",
		"API MPMS 10.6":"1v0qlgdzWqwJzLJAvAi3ljz2-n_GthO7r",
		"API MPMS 10.7":"1zQDf0FwtdF8CHcwMIq7OyN5DqvuIze2X",
		"API MPMS 10.4":"13jt9XKa2OPcDjEDacz0Aqc8GkMkNkRfI",
		"API BULL 11L3":"1-gagOcMninxXqCVdmUV8CRcHfmWYMO4l",
		"API BULL E1":"15i2MmW8z4j95y3KEe_uzYnrJNLzP3WOU",
		"API BULL 6J":"17fQ3QBRIwHElShhAVmKtJVr6v38-MsQ4",
		"API BULL 2S":"18FJ5khI4b6h-K4JIIO9hnXZzpxvcl4Tu",
		"API BULL E2":"1IMGir2n_LT6BB1FDP4rwD7JZRItSdlcj",
		"API BULL 16H-1ed":"1KIyxCWuoAflXvLBN_2dtqWix2JBa7lyP",
		"API BULL D16":"1MKcdeMWp6qiUAwUebDQSzwYV72HxXVzn",
		"API BULL 2U":"1N-kmO-rzWf4thIPZxmBnM9oJ-nL9EGjk",
		"API DR 145":"1YCpbpcXu2Yd_28nshtFmt7MHm6h4LyNx",
		"API BULL 6AF":"1b540lENrqt_fjLf6Qfo11eAsk5r6DLR6",
		"API DR 225":"1cVgmcUNA_GQ5ylpbPrOOHGH-wiswGi8e",
		"API DR 141":"1i0y0yDliEn4FlJIl0m88kh23SMlmMSKa",
		"API BULL 2V":"1mSE0Qf-RPAz8W_bEL9j4pqfNWyz2c0_e",
		"API BULL 75L":"1rC9SMB0-slnI2lt5_nVcjTFJquWoQ681",
		"API DR 148":"1uGCMkauqFUTRJxBA-ZV4PCPDX31wICsA",
		"API BULL E4":"1uZtf2fADlxxMTlRLpbDCk1aampRtcHIm",
		"API BULL E3":"1vFrqOOgqm1zXBCA6a7-oqD5arc3PfrcY",
		"API BULL 939 E":"1w8msFRmLO8Rj36j1JuNVVQMuzJY-w4Mz",
		"API 941 - 2016":"13afUOeqhGqqD4TDKZvVWiVEnUtgIGoSJ",
		"API BULL 1178":"1QjHZwp76FAheTuBY-Eds2p0w4oAcuTpI",
		"API BULL 11L2":"1kBYRafDDZFrptsi0hC48hQTk1-hW6GGQ",
		"API 939D":"1o46--NKNrF_lFr_eYlnLSf7x2YDmPvOP",
		"API 937":"13a2BC1MKsZ1G68Ro66KgxhPwqTUTE3Sz",
		"API 692":"14o8V-z_u0Ql7TVjxKprtK4c9_yXSTVXD",
		"API 755-2":"1CeBcw2WL14V9bUqnbO8wR0cOz3g4Cz8U",
		"API 936":"1JfV7jblG5aDaK_du-YusmYXW41YUJ51J",
		"API 691":"1MQ5c20Q-_askysQCC5h2KsRuyRONILq6",
		"API 686":"1XePz6-mPSOevpgvgNUDKl8guM_vc1kCW",
		"API 6D SS":"1ZenUiOre-1ohIndjxIvRGtogm-U7YPXa",
		"API 937-A":"1eGOtQW8z6U2e0uKz69WvL-BBlh4irf3r",
		"API 6D":"1mP1zlJq9KeYzbeL4HHBhY74YqD4YQw39",
		"API 687":"1qersp776cSqjmjdPKzbzJ-ih7m5g8G9f",
		"API 688":"1s5jtlGOcmAD9VCd_hczLSE_BdUQvpwOf",
		"API 6A":"1u1svO3Xcf-xh5fwsDq1nF3dyk0sJKoOu",
		"API 673":"1-z45vS3cWqXO9kz-owjsN0UgjbA3zu6J",
		"API 674":"11Et_MPeBiB8yn-LoOE9Cq7ZxlOCenm37",
		"API 653":"15Mzo0FhcUKPHZW3HfBEDRtCQnLbqeGyc",
		"API 682":"1FMfE_w6AFEJ2Jl7aSN1q7Ym2-bBVz-uV",
		"API 684":"1LTd-WhiJKMBM1AKBSaSC-2IybXIPmSRq",
		"API 681":"1MCuzEf8d-QjpyoSp-aGmP_VwIsITjO8N",
		"API 670":"1YtbUqsVr-Tu3IBmsi5BzNHHKC4y15opT",
		"API 650":"1Z79FZpxe_PQawfjpACP3Nr1_QHrsx7lh",
		"API 676":"1ZrKaG3hwjp68ALLr5tlHDaU0TvjFinYf",
		"API 671":"1_XoP88kWJATGrqHW8g4AIbvnU6RRmmF5",
		"API 685":"1aw7KYyXK26udfSN_VJQ6zaCnr8MYjtqa",
		"API 672":"1gNHRclBMfT7GnKqSsGOP5qTa9wzCnlot",
		"API 675":"1jEvrUtwsQUkd8S3a_WrgxyZrPTUlU4kF",
		"API 677":"1oSx4LT20jAM65LNeRiUYVstN4urZIzjG",
		"API 651":"1y3qgqSOAigyIsZxXI2obZuW01n7uyf5P",
		"API 610":"1-RsyGtP79lRe5XgLYXYrjXN20Qkv668H",
		"API 585":"10F-WHZX6fXAV6Zwc5hWSWPXKwxlMH4oj",
		"API 617":"12wsf_ZDzT2IxgBi3EsElJ_b8EXfeBUCF",
		"API 620":"18jm_aXdE-9QHbuo8f6brcXGj1QP0pdyg",
		"API 611":"1Qq3oAnSjdP9arBvXFyBxgumBrxrgsksN",
		"API 614":"1UXa5HK0jsbrKia0hg4UEneadTf5li1zH",
		"API 612":"1cUyhqBq-fR7dhTPsujUwS5R98lZiRxaP",
		"API 618":"1d6ub_MusOuR8Lgr0w46S4e9dukQZBkt0",
		"API 5B":"1gxBeLfPiqBhoeqX8abH7S9sGNqgOpNnD",
		"API 619":"1ll8shigrqfKufX1Cqh5UYXOeiz3K1WFr",
		"API 616":"1o7MboJjNuaAXC-zunE4Zh4Z1d0_c9vOE",
		"API 5L":"1rBQoxOL-hNFRM7mv0JYpJtd_AfkPN4Wy",
		"API 613":"1vyNIgdhsV1sBrV7Oi5iYFLmOvEn2K4vt",
		"API 579-1":"1BeIeRQseRZzwYNKXJDnzNk6wi8n6ucgM",
		"API 584":"1GOd7aAGeoe-cSdTsbomlCERcld17n89L",
		"API 583":"1giJSwq3Slp93X5EzlRCNg46qjNqhGK3Y",
		"API 579-1-2021":"1lQPW3g1PzzFnUbIn2U8ymMhCFmPgpuDy",
		"API 579-2":"1oTFE3ltVohm6BBNxpgbaLuItAi7bbpUL",
		"API 581":"1osr8BWdCnXAmmD3QreJJCQzYn47RptGn",
		"API 580":"1x_EBEBk1Ezgpw3E3C_SSswMkVKvd9Ewj",
		"API 582":"1zQLJqUs0CoBz5N618vMKiNQTBQMLVv1Z",
		"API 577":"15wrkeXuxsgsANC2NZccgru5CqJJfZgV8",
		"API 572":"1IyA_ns_VYbvbSpG4ZlsxlMM2dDwwn_Dx",
		"API 575":"1KTZXBC_6WQ-wDGqh7if2HeoScC7Obkgj",
		"API 573":"1S5_8euIB2RBLEfThMNjQYSO13XCOPQTE",
		"API 574":"1a5cn4VA__B3LoCKhDL7FmRnf1pC920u4",
		"API 576":"1h_i-PQXM4c6PTNMKQ1Bjnpd0x1dZoIKx",
		"API 571":"1jiVm0eEkVxLr2HAt1I0n3FeYLppy_UuY",
		"API 578":"1lL84Jve4vBXXPLIdJEt8Va8SeKG1G7sd",
		"API 570":"1vuchY4pu6s38wfNuKVmB5Et_YvMGbWXH",
		"API 5 CT":"10EENGtwX5qb8j08mnkYykplh7jSwyqqe",
		"API 527":"12_yJyBHEdZlUDXa-VEB0A6ehri1riUzM",
		"API 530":"1GVs19YfAMHdOvB1VPvnOXZt46qk1KDFt",
		"API 560 ed5":"1RfkW6O3PxqTQlPL4MZJw6odSqJQXmy68",
		"API 17 TR10.part":"1ZSP9KvJD-omOdL0rKTlbh4DLJsEXm7CC",
		"API 2219":"1aHqF6ju0vTGNf_ikn3S9OankE8RHH4PF",
		"API 17J":"1dX0ASxrscyW_udPawre2uMvjBLW5YvRV",
		"API 505":"1dhPOch4urOvVFOO1ahhWgWYxRAi8yeeX",
		"API 17K":"1eN44S6XmXNKwAtgduEMz42Z09hUjyY_a",
		"API 510":"1h0MvEK5bFoLQ1FtUjhtCDynbr2zF-Sq4",
		"API 2030":"1h2GynCY-cFSAmheLv9oaMMP0Ng_Gi9Qd",
		"API 560":"1oyZGPh6Z69rO2dNzk53ITs8RsDkClrJw",
		"API 570 - 2016":"1wyqp_tzfAnCrruBYRYr7fSYC1w_ePzkr",
		"API 10 B-5":"1CiUYldKHLlQvmLojrWQgJba_rwM5n3gd",
		"API 12J":"1DikTzfPXnLFV6TEgFdzQqLxvH-8qW7S2",
		"API 17 TR10":"1Ju83bttsccgFUopgnr4sVhBgEVuKK0rN",
		"API 1149":"1RXDDxXaFO3Ji7kO-GzBvPoXidylZh-yx",
		"API 11PL":"1YoLZhbrpql7oY3uvWm9B3Bqlommf3bu6",
		"API 12F":"1xehn94yaw2f3kwRTYWbS0pymh4T7XY6z",
		"API 1110":"1yprvAVOSaCj0mExHLBRLEfvK3V2RFY6C",
		"API 13 L":"13OvryOEtJwOqMoEqUDcgj3Sa5ybP730P",
		"API 12R1":"16Tt6tq-BtKyfkl2JKWs1xPj9mz_Kvm56",
		"API 1172":"1BgXh68VIybXLx9PLHuopD1A-sRe6FxoL",
		"API 1169":"1J6xzf79pMDVdn9k9gydOHp27upawP6MX",
		"API 1130":"1LPqPi16WNuZdVhZizX33X62TFVJDDsvA",
		"API 13.":"1MWCizYfIHlAP-O_2JF3VSIvku9F5Y92o",
		"API 11 B":"1Q2tEeQ44bRdPowJkI0MsLLuYj_MCG9ar",
		"API 1104":"1Qw8YYvqBlhNAE226ue9ATtMxE900Ns3X",
		"API 11A":"1VVIsuXee3jdI5NBtLdq2ZtqZ3Ii9w8yq",
		"API 13 I":"1a6hDggKOlswygv8z0WfunL9ATZiTY3u9",
		"API 10 D":"1aNMcxI-8WbEuzaH3iDE3-ItT1TRHKjJV",
		"API 10 B-6":"1cNlhFMBfXVvZdpSJpqpr_qFpJ0J7KPzz",
		"API 14.3":"1fRRzuqUbEfpMMtmxhEds3dQBzaNdcHuv",
		"API 13 D":"1xSmvsHTkwmI6Dy8SJgq2bozI--8Ispwn",
		"API  941":"1dgDVQLP2ybtGZ7uyxsIKSShd7q0FbYeN",
		"API  RP 575":"1hyWiSlX_PBV0uwuEdhiGtyZW1A21xmv2",
		"API  RP 574":"1ikLYz7asGq7w5uLE9QUCJvuN-GB33V9N",
		"API  RP 75":"1rTgpgeU8aA-7Q-cW51VMknh3mjxyfz10"

    },
	
	ASME: {
       "ASME A17.1A 2008":"1RkzKrTSG0rehJ25I28DWKJiIrImvwJxg",
		"ASME A17.1A 2004":"1WktfF5JWdD-bZsT9px0n3ym7LGJnaBuh",
		"ASME A17.1A 2005":"1hyZtcTKGH947qsPtRiO6jxWBapBwpMxO",
		"ASME A17.1A 1991":"1pFS5ZiCgmjd1xQGn0lHI-1Zwjx7cbacd",
		"ASME A17.1A 1985":"1xaSiTOM3EcPCUY80VGtKaEOzyiTI9RKF",
		"ASME A17.1A  1989":"1M2Yov2_IF1YDvpdrUX9CA5RmnOSyouc1",
		"ASME A17.1A  1987.part":"1STYD39m80IE7M4A9-_pX26Oa82iKRZSs",
		"ASME A17.1 SUPP  2005":"1MsMCwYCRa8iFrAtjZ6Ux0ImT_rDdVGtZ",
		"ASME A17.1# 1987":"1Mskfw8mD8p9K-Y4RaVJWfv8CG6p2TShk",
		"ASME A17.1":"1UwVWShKyVmS5lq7fR2cVmoc2RJ8bWIbn",
		"ASME A17.1A  1987":"1XqTpWThhit0-qD-iOkxZIeo7in333w-f",
		"ASME A17.1 Revisi":"1h46if5QInqIS5TSk_1IvP9H95BN41N4V",
		"ASME A17.1A  1982":"1xhKc9Iab3vCzq07PeVf99SfI5VI2_LEW",
		"ASME A17.1 INTERPRETATIONS":"18kEGwSZixqwbFcRNzj1xv-P5WCgCN9cY",
		"ASME A17.1 INTERPRETATIONS no 31.part":"1CJOMg6xUpnaeDPZrlJn5urTFNIekBMxd",
		"ASME A17.1 R2013":"1PcEgrGcxiM8cphZydf9NptKPpfBQbJm0",
		"ASME A17.1 INTERPRETATIONS no 32":"1VnnHHF_-MR0_A4TPcoCM_SwY5rEtLO1i",
		"ASME A17.1 R2003":"1cb0ml_kzbvjbm9RpZt2RaFTgkcGpnjyL",
		"ASME A17.1 R2010":"1rg_dSaVn50oeKEUoU6EDxB8qKsce9CUx",
		"ASME A17.1 INTERPRETATIONS no 30.part":"1EAy_NzhzCjezSPphDHZhbu2du6Ahpc8h",
		"ASME A17.1 INTERPRETATIONS no 31":"1sYFBpHTKHcw_BvGtfeBOtFqw_-RpWLJe",
		"ASME A17.1 INTERPRETATIONS no 30":"1qJJHTmElfGohKldiav9IdvOJQ0tykrVK",
		"ASME A17.1 INTERPRETATIONS no 29":"13d1cLXLFrLyjUbfTR4k-xDdYcxM9Tx3S",
		"ASME A17.1 INTERPRETATIONS  2009":"1NynNUVZccGH7dywo2JncIqm7mbrEzi3B",
		"ASME A17.1 INTERPRETATIONS - Interpretations to A17.1 Safety Code for Elevator":"1oGaWPHXECFHgjXMu9pLJx-kziogBrzqr",
		"ASME A17.1 INTERPRETATIONS no 28":"1wOyZNUUWWJQxnI_jlOy1LKx8LU6l4ZTr",
		"ASME A17.1 HDBK - Handbook for Safety Code for Elevators and Escalators":"1JWG7KvJnNCdK4INt0aiRg-eNzFkBCWrT",
		"ASME A17.1 HDBK":"1TAH1MgbeI7Jdd3sSqlpAJSyF69s8PJT5",
		"ASME A17.1 HDBK - Handbook for Safety Code for Elevators and Escalators 1981":"1ccFijlgtyx16qC1vGIuWl3RpMenEz1IE",
		"ASME A17.1 HDBK 1983":"1mryC6JuhL43Fv1Ejy-IBuu51QuIGkMb6",
		"ASME A17.1 HDBK  2000":"13nfgvGoXhZYeVnnXBp9-bcldQfFIAU6T",
		"ASME A17.1 HDBK  1996":"1DknyL8VSIefNu2IwaXvDCjzXr_bjQMV_",
		"ASME A17.1 HDBK  1993":"1NrnvFay-fmg2mh37Oo8y6x8DyI192dg0",
		"ASME A17.1 HDBK  2004":"1WicaLQFNZEI-tYVfsVDe-yffiEYvCzWD",
		"ASME A17.1 2002":"1H-kKWNqrYaH61Kb-Os_ubO0uLvPHoL1r",
		"ASME A17.1 2000.part":"1MGXtpRBgecroBFcxUzzy22YgMLmezW6K",
		"ASME A17.1 ERRATA":"1PHf7KeSt63rfB67L5--TfgWQ-I1oQBnJ",
		"ASME A17.1 2007":"1_Zk4oC5kcwmdZRntxbPdYQMOOn4lU06A",
		"ASME A17.1 2004":"1dbcGmrjeVPU0t1v_x5C1YnUP8CRdiR42",
		"ASME A17.1 A 2002":"1idsf_QME1Tvbz1XKBPWy-lm4DH4MIMT5",
		"ASME A17.1 2003":"1nscpsrplCPVBGJqqkH1X9g3RIlenaF88",
		"ASME A17.1 1993":"1-N18SjJ9281gEDyCg4oT_iiJWXPzBucA",
		"ASME A17.1 1990.part":"1ISfAA7-OKgLQh41qE8nbEtnvefAX3mab",
		"ASME A17.1 2000":"1OvGG8sZSWJZXxXoUt5l6XPf8zHQBamm8",
		"ASME A17.1 19981":"1WxWyi4KOzqJueOYTrRlEWwRFCpikabTH",
		"ASME A17.1 1984":"1qaeS-JpUNPRgADnj16GuSyytw0o9urK1",
		"ASME A17.1 1990":"1ripztFcVd65govegGPrkzoZU2WIhk3yQ",
		"ASME A17.`1 1998.part":"1Gm-_so8c0z_Nt6jSXoPfagUCvXzikPGP",
		"ASME A17.1 1982":"1UML_LLURMA9L3FhDJS-1fQdEItDGeR2F",
		"ASME A17. 1999":"1D6Ca6z9tWOoZ7YctiYzjIbKeHN2ddAga",
		"ASME A17. 1996.part":"1IaNXIDT9L2q29CKrXE8uvHvFIfR-ACOR",
		"ASME A17.`1 1998":"1SW8DySvgGoozQV7dHlzcMP4iFnf3pP2N",
		"ASME A17. 1998":"1b6Gvt5sGFLwITN390mMvTi5gR5yFKsJT",
		"ASME A17 INT NO. 13":"10MYRricbL_AF-0KS536bx7gyg2FxeRi0",
		"ASME A17 INT NO. 9":"18Ifr25v7uNG0O2GkLPL1WMGtG0NBDy1u",
		"ASME A17. 1996":"1K29wz4_UgY0M_GoN34MU7zxmY_UdBHtt",
		"ASME A17 INT NO. 10":"1QiDpHLme7xNb82jxb-7F8FIF90hctrE-",
		"ASME A17 GUIDE":"1RjuqQkXPRYsQ8LFUBSerydju4J3TzW-a",
		"ASME A17 INT NO. 11":"1YxgK8TIuHA-JznYokh9xjVqyKLpp_Yp3",
		"ASME A17. 1 A 1997":"1ZGs_XvdXl-qTlrPpi1C_dXcL-SRbnBpa",
		"ASME A17 INT NO. 12":"1cSlnACaToV949gjrRsqQ65DHpX1GJwaf",
		"ASME A17 INT NO. 8":"1gsr71H0tSCVcI1xN_wyOcCCyYFtSzkpt",
		"ASME A17 2 b 1984":"1koMWqF3TUvfgWcJ-uNoot5-eCh1CDhnX",
		"ASME A17 INT 1983":"1mvee_Sl-6WscXCqWt3slJlX_GxE1pFVA",
		"ASME A13.1":"1-_dVK9U_sbR4e-8jhTsvBOnDL15UY3Jp",
		"ASME 115":"1-yR35qzi2ZVBerPffJFdl6GqN9QVDjPr",
		"ASME A17 - SAFETY CODE FOR ELEVATORS DUMBWAITERS AND ESCALATORS Rules for varruction, Inspection, Maintenance, and Operation":"176HMpT0vGSB9dhT-IGnzNyU3_ExfFM90",
		"ASME 116":"178ow4wRPVp1Iult3guWmz71dR1osZB8B",
		"ASME 3287":"192Rqcrhs-5gZphm9-5MfTrmtC0Q7uZx2",
		"ASME 14414":"19YSVXr8ZKJH-FGDOjPyk1Fdy1mfJ1brc",
		"ASME 30.26":"1BeByvW4J09yK25_ZKgqhISM28E2MjvmD",
		"ASME A13.1 R2002":"1FHIRSA5eKgY4TolUcVRwoBPr-6c-4TGn",
		"ASME A13":"1HbZQd4opA_0RbyVigaQtQw0OqhydPZu4",
		"ASME 58.22":"1O6zLiWz6573iPneTq3yBM-xIgizidtuF",
		"ASME A13.1 1996":"1Q_CLYBtXeM2mv-eix5owmBy1CeekWB5H",
		"ASME A13.1 1982":"1RDJDlIT6gD1JxXa-LIS6Bt4JhEms5p9U",
		"ASME 3977-2":"1STOCLUG3eNsAkeHz38QyUcwtNwKbshg6",
		"ASME A13.1-2007 Revisi":"1VtPSSKQ4Y7A6FGdboEKcCDPxBwKDOmSS",
		"ASME A13.1 R1993":"1dsSZgce2ih3FnTE8qiyLS6x1Dice7DbN",
		"ASME A13.1 1956":"1dwv2KmCg-zQFlpEmJiHun94m1Bh9dFbu",
		"ASME A13.1 1975":"1e5eJVsRa3dVjxeZmW0NvzOliuc0wgofE",
		"ASME 16.42":"1lKCia3oLpoLg1CQai45qwY6WLa-eaAy-",
		"ASME A13.1-2007":"1lcqH2UfTrahySnLcoOZsQmrYM9q-qqEp",
		"ASME 120":"1pOoVbNE2JHZ_MHn8MmGTsUbZWGSSpYVg",
		"ASME 115D":"1vQmOw1ueuBiIGmuCGA4mRrAYtRX8_4wo",
		"ASME 3977-1":"1w8jvSeEqUEdSl46fiV167vHbbZ0xTIqA",
		"ASME 111":"1jpa5GGxtZ7D4dYgRvAcgkRrqVUpERTv1"
    },
	
	DNV: {
       "DNVGL-OS-A101 Safety Principles & Arrangements":"190kqxgrsAyqhFH-aLnCJFIKQEr2vleah",
		"DNV-RP-F206":"1DtzkKQSvMEvCMx_A9TI3Ys4BykmPr7yj",
		"DNVGL DNV-RP-D102":"1LnFOUcU50OD9ugXZSRTEZUJmEdiD4T3M",
		"DNVGL-RP-O501-2015 (2018) Managing Sand Production":"1RIE674WVRxxzTMPTHZ51C4fdXRuWhI9i",
		"DNV-RP-E308":"1l-jgvnLRmKjYXX88lzYaPmRsdZ0GHJHF",
		"DNV-OS-D201":"1ogUCDyCm5JjwPJzU6MN7WD0Csd8D9qLg",
		"DNV-RP-0002":"1w4YFLHT4ezZdlELloHq1BELez9AE6pvJ",
		"DNV-RP-F116":"1yxuJVPTNpmsMTk39sONUaGYqTRbAutIK",
		"DNVGL DNVGL-RU-YACHT-PART 2 CHAPTER 3":"1-xMQM8dwZVmkePmb-LUyd3ihXggqDsgK",
		"DNVGL DNV-OSS-312":"11yWFGbB81CSX53KVdjHV12athhHU4u2C",
		"DNVGL DNV-OS-H206":"13fFN23EnEDG79Ux1F9rk1snMqk0Ahvtl",
		"DNVGL DNVGL-SE-0056":"13kp96WU5S_OU4l6zj_GSVY2eaYBvQuFb",
		"DNVGL DNVGL-RU-YACHT-PART 4 CHAPTER 9":"145RQ005SJp1fleHcrLYwlpBJ-ICa_SkV",
		"DNVGL DNVGL-RU-YACHT-PART 2 CHAPTER 2":"14hZeNnGQ5uzF4iZU_6YHQoPZMz03nDae",
		"DNVGL DNVGL-SE-0436":"14smVHnJDiIOCDYC1yPB2oIFYh1c9FGZO",
		"DNVGL DNVGL-RU-YACHT-PART 4 CHAPTER 4":"14zFzkB20ZdVtTDMxx4xQRvBiPqdsADml",
		"DNVGL DNVGL-SE-0079":"17m4ZU-XfTpVJO2cUpxE-t8rdq0Jf9ndg",
		"DNVGL DNVGL-SE-0160":"18Um6xqstL-lvBGHe2rRkIt78zwgJgWPX",
		"DNVGL DNVGL-RU-YACHT-PART 4 CHAPTER 3":"1A0TiKNhnXm87pfIQ9a8QxA6ElvzFbOmL",
		"DNVGL DNVGL-RU-YACHT PART 3 CHAPTER 4":"1BEkbXvwfOgWdtKFdRl61Wp2_s45sJt0V",
		"DNVGL DNV-RP-A201":"1FxghX90qF1NzNp5ema0nckFyhRnrXhBZ",
		"DNVGL DNVGL-RU-YACHT-PART 7 CHAPTER 2":"1HJ9tIVzh4sadTRhAF9inMuzeLjbFs41a",
		"DNVGL DNVGL-SI-0551":"1KmnI_PE_H0h2iPD7SjIg3OVmJnAWDbXo",
		"DNVGL DNVGL-ST-0049":"1LVFcRi9-So1DI2ddybqeFZhY30z53qyU",
		"DNVGL DNVGL-SI-0166":"1ORJvCRf3CuvPrzoKsJfFbmRTqDG5nEwD",
		"DNVGL DNVGL-RU-YACHT-PART 4 CHAPTER 2":"1Qv1xss9HOq6hYqTLNKszKiU2NT5fjkYT",
		"DNVGL DNVGL-RU-YACHT-PART 6 CHAPTER 1":"1TiVOH3qOIbYPSHnBeE5H7Xp-0geNv9qm",
		"DNVGL DNVGL-SE-0176":"1V4xSuD7i67Gp3Hx6adhXGg8e61kkRnpF",
		"DNVGL DNVGL-ST-0342":"1XQs27ts7p-Dw_pEE4ihtTSeIUR7d8ybK",
		"DNVGL DNVGL-ST-0371":"1bWbF9XP1FEyWVxq8dgDxC3S-5lZhq9Aj",
		"DNVGL DNVGL-SI-0552":"1c2noQEDIX2w4snGvV6bTM0rHX0e9gTtx",
		"DNVGL DNVGL-RU-YACHT-PART 1 CHAPTER 1":"1dePAdLmPqjmfKPsetadwCifTzPbiz4Zy",
		"DNVGL DNVGL-SE-0077":"1eTDIFKSz-Rdi_EcYANZR5JBr9Z85BSkA",
		"DNVGL DNVGL-RU-YACHT-PART 1 CHAPTER 3":"1h0V388IGY-7CjRTQf8wu4M9pxMafdm_F",
		"DNVGL DNVGL-RU-UWT-PART 1 CHAPTER 3":"1sA5c25ocx7pQRaDX0GqCLnBz2dobaprX",
		"DNVGL DNVGL-ST-0438":"1uIRZHkBlPOP-SUQXnojSXNfFVvVSn9hs",
		"DNVGL DNVGL-SE-0284":"1v8UxSzqh_OU5-LcWA5ZpsrQkEMM2QEt8",
		"DNVGL DNVGL-RU-NAVAL PART 3 CHAPTER 1":"11NEDQYf5BoDzsgu1t6chqM2PUMs-fyNH",
		"DNVGL DNVGL-RU-UWT PART 3 CHAPTER 5":"13totjmStIHH0cpJDWvEH_50a6GivtnLQ",
		"DNVGL DNVGL-RU-SHIPS PART 4 CHAPTER 11":"17x8IAwbXpjIjApRlCno3pCnrywu5pAH5",
		"DNVGL DNVGL-RU-UWT PART 3 CHAPTER 3":"1FVF8MBUAsGvvvdhbQbBLKDli2YUiRK1l",
		"DNVGL DNVGL-RU-OU-0294":"1Ne0V6OM9ZbWzidn6PmV0u3z5TQg_03TZ",
		"DNVGL DNVGL-RU-NAVAL PART 1 CHAPTER 8":"1PgGy5SBlj7OByjF9E-BlmJyoJC1Fr575",
		"DNVGL DNVGL-RU-SHIPS PART 1 CHAPTER 3":"1QwaoTJOpjJHWD-EulnF45XA27vpwvxrR",
		"DNVGL DNVGL-RU-NAVAL PART 4 CHAPTER 2":"1QwrQnGEJ9cxbrga0bVXndRGsYBDHp0VO",
		"DNVGL DNVGL-RU-UWT PART 1 CHAPTER 1":"1R7LQSIa0ABGK2pbiw2OOIlY56QotLfUT",
		"DNVGL DNVGL-RU-UWT PART 3 CHAPTER 6":"1T3nVaH-yAGHReE5EZ_W5QLOC3VLyWgW6",
		"DNVGL DNVGL-RU-UWT PART 4 CHAPTER 2":"1TVkSumvgwQoIvxkQleVJGiregppEoym1",
		"DNVGL DNVGL-RU-UWT PART 5 CHAPTER 2":"1WhwraW65LrJh98jMZk6Gjq9haGit29DM",
		"DNVGL DNVGL-RU-OU-0300":"1kBEGAYdaRkxj3iLDVTZjLfsXvJkHMn9m",
		"DNVGL DNVGL-RU-NAVAL PART 3 CHAPTER 5":"1mMRjBRgRxBkaeqY6ssdYIX-jIaFI0mqs",
		"DNVGL DNVGL-RU-NAVAL PART 3 CHAPTER 3":"1n2PzvxxeO-na_XONHpZ1xESeqBAr_Qxy",
		"DNVGL DNVGL-RU-SHIPS PART 7 CHAPTER 1":"1nGXBQpO3tF6d0JRexsk_gyctBZCRC6ZE",
		"DNVGL DNVGL-RU-NAVAL PART 3 CHAPTER 2":"1owpkQK3NLrGDS7lV3ukuvBnYtijUSM8l",
		"DNVGL DNVGL-RU-SHIPS PART 5 CHAPTER 7":"1r-E0RfN-8F5TrkHQGrfz8AWxCerWHveJ",
		"DNVGL DNVGL-RU-OU-0101":"1tzxd9WAwAcc3wvuVD1kPUtc5OfWTCqxu",
		"DNVGL DNVGL-RU-OU-0375":"1uWMDI7AU8206xm19Dhg3L36_DCRj9pN2",
		"DNVGL DNVGL-RU-NAVAL PART 4 CHAPTER 1":"1zUFioNRxw2AOg7qKECLJn-FJrdIvqJ6l",
		"DNVGL DNVGL-CP-0507":"10sc-qZGr2zyBfB5D5fHKThdou3FmGi4X",
		"DNVGL DNVGL-CP-0346":"10uhhuKvk9Asfc2rIrVeG64SCRBkIrPnn",
		"DNVGL DNVGL-RU-0050":"144viBesFoQLiBV57XhtmaAuLxvFAhwyZ",
		"DNVGL DNVGL-RU-HSLC-PART 6 CHAPTER 7":"14f94XzSzz04qsiMvZPcUZ4UN8xBqCk32",
		"DNVGL DNVGL-CP-0553":"14zTcj6S-JaZauQHfTm0r3E3-lOVbLhQu",
		"DNVGL DNVGL-RU-HSLC PART 3 CHAPTER 2":"18Y0zq2uSR-HN_Pp2yiqLzXTZKC7GGBNq",
		"DNVGL DNVGL-CP-0261":"19uDRnhPBsa21ZYKCIASR_YbvHQ8xm2-Z",
		"DNVGL DNVGL-CP-0501":"1BtYK8yQFUE8unvkgQDlhFd22r8QMiZ3L",
		"DNVGL DNVGL-RU-HSLC-PART 6 CHAPTER 1":"1CuZtsa3mMY9syTgmy9CAkfEnNwWqReDK",
		"DNVGL DNVGL-CP-0392":"1Dyb2pa2CJkRM_OBYkQjD74TB2wzFMXgq",
		"DNVGL DNVGL-CP-0210":"1IFRqHKI6SbBMIsFchenX48-GqeDXEa6g",
		"DNVGL DNVGL-CP-0418":"1Kd4NWSNw0pGCoZA_5kAhTA36_dzrLJtl",
		"DNVGL DNVGL-RU-HSLC PART 7 CHAPTER 2":"1Loh-qbdbuc6NiEMqhSIW_prOhhEf9R_q",
		"DNVGL DNVGL-CP-0505":"1M_lB0AYaDlPwCxGB1GJfygxQ6FRxL7Dn",
		"DNVGL DNVGL-RU-INV PART 5 CHAPTER 9":"1N9Dn6tiXZNOndUjNC_XDebdldfT0I-JF",
		"DNVGL DNVGL-RU-INV PART 5 CHAPTER 7":"1Np4BIjIWIaHQtY0k9bD9ufZew8Q3LbrK",
		"DNVGL DNVGL-RU-HSLC PART 4 CHAPTER 8":"1ObkZhOPqLa82uiAYMAmu1kqrPzebbL98",
		"DNVGL DNVGL-RU-FD":"1XxKwfoBf7DMxXXAnyrIL6g59hDfFnI27",
		"DNVGL DNVGL-CP-0291":"1Y5XK4wMhK0dkkpbuALAeD-gTRLhkdmKn",
		"DNVGL DNVGL-RU-INV PART 5 CHAPTER 6":"1YvwrNruRa7O7OjkvdPnc_RAUiuiaNsCW",
		"DNVGL DNVGL-RU-NAVAL PART 1 CHAPTER 5":"1ZOrnWhTPQbRb4YFAXYoFU4CXLP__GXlW",
		"DNVGL DNVGL-CP-0352":"1dz-3vRxS0gxtYSrpxP0_0Rlri0x4PxkR",
		"DNVGL DNVGL-CP-0353":"1fbwWkd1P042tiLTJkbnye5lALeJjk18v",
		"DNVGL DNVGL-CP-0569":"1g2ru740Nu8aOYKmSu6U3ZornPySMUSVG",
		"DNVGL DNVGL-RU-INV PART 6 CHAPTER 4":"1hG_kfWIak-3oyB0pGz7wlK7oyc4DWO3e",
		"DNVGL DNVGL-RU-NAVAL PART 1 CHAPTER 7":"1q7SLp--iFrOmqim3ZENTJ4AAjZOvLdjp",
		"DNVGL DNVGL-CP-0502":"1qAjTIuvegWsbEvczUnyF7mHwOHyDleUq",
		"DNVGL DNVGL-CP-EU-RO-MR":"1tKl0XNiMfHwNmBO6tbsMyGCTsEPUYd3s",
		"DNVGL DNVGL-RU-HSLC-PART 1 CHAPTER 3":"1vI4xRCfCHTJBceoFjgG-mH1TSP8DN0da",
		"DNVGL DNVGL-CP-0483":"1wPK174MyHIEoIFBNkQemd8qTOVfFuFm-",
		"DNVGL DNVGL-RU-INV PART 6 CHAPTER 2":"1wg_wmhpTkSro0vcOOFLSRKfPY-SzX0H9",
		"DNVGL DNVGL-CP-0174":"1xMjnPJz4o3cZ2ImdJkmrVJH_HPxfOfq7",
		"DNVGL DNVGL-CG-0157":"13TrIJFieErEkRy9bYRArLtLEfKrDvvdQ",
		"DNVGL DNVGL-CG-0037":"19ZhEvEqGL7kTQEzjkaTp1eyy3_2CaFnY",
		"DNVGL DNVGL-CG-0556":"1Nal5ZDYc8bNvEdcQBFQ43pM6A-SHC1dk",
		"DNVGL DNVGL-CG-0038":"1ZHLa5Gluwxw1kZ5hzl2pMiPJKTddvdND",
		"DNVGL DNVGL-CG-0121":"1aRWu3eVZTEoOU5spWrjoJXXJp2pvPdNi",
		"DNVGL DNVGL SHIP TECHNOLOGY I-0 - I":"1c38vBKuirkUgF7bRYI1Xb37Q8zWj5so4",
		"DNVGL DNVGL-CG-0129":"1cv09UZIcaIskwCZotLY6xO8OJZY0DOax",
		"DNVGL DNVGL-CG-0264":"1poE92q2Ir-hqcDhafMOenwegHbGrFuBA",
		"DNVGL DNVGL-CG-0493":"1q66ODQCSd-M_QihmE8jFwndJvC173ChC",
		"DNVGL DNVGL-CG-0214":"1qAOwws41pJ8UUvKtWnlTKuZwV5J7AAXe",
		"DNVGL DNVGL-CG-0325":"12Rz0RZwXSBgSHG_3RKQEKW1lheo5b3XZ",
		"DNVGL DNVGL-CG-0283":"19ZtbL-2_24eZ3_JZqKyPTSl6JUTzRk50",
		"DNVGL DNVGL-CG-0128":"1AUa-BzGAXHDS5EDw-PuSvqiE6QMhLXup",
		"DNVGL DNVGL-CG-0285":"1BTBWRTbxje87KHRhjsf-7GPY8TLEoTWA",
		"DNVGL DNVGL-CG-0134":"1JwEFpsgn6kmfY8aJvm-XXZ94AHiRTjjN",
		"DNVGL DNVGL-CG-0197":"1QrYMyPbv63Xnu9-LLtI_hYtX_JiK2ARc",
		"DNVGL DNVGL-CP-0031":"1c-4OiEJbNL7ZO2vr7Vd8ASlyPT6Y3DQr",
		"DNVGL DNVGL-CG-0372":"1d3bvqcF5goxmxf_NnbOcNgQ-Zmz5RT1T",
		"DNVGL DNVGL-CP-0173":"1fNEulSdNj_sCk_gRyF-t9ZHgyTTZdWwT",
		"DNVGL DNVGL-CG-0058":"1lnHQpSP2nU43ByOtMJh7PZ-YqQN5x7z3",
		"DNVGL DNV APPROVAL OF SERVICE SUPPLIERS NO. 418":"13FwFeCrKs32LMRnAwbFgpBjaWegqEIi6",
		"DNV-CG-0447":"13wDEuv368_OHM4h1X5pkEdUVKnVFbAI8",
		"DNVGL DNV APPROVAL PROGRAMME NO. 313 NOTICE":"17dEJnViT-H1YswexQydYvCnIsmAofrab",
		"DNVGL DNV TAP NO. 4-732.10-1":"18jzfnMx-BE-ANq7lrnjNNSxPVhj-85J1",
		"DNV-CG-0580":"1DwdRLBQlUl-EySFpT2rsUA6ys4LY6ot4",
		"DNVGL DNV-DSS-402":"1K1qGYn_KyrhigEqJOpVH_HtVWAg1AZ26",
		"DNV-CG-0588":"1MjIHGFlcGW8SBQidxC6A2addAv2s1fjO",
		"DNV-CG-0565":"1NIpAEqmxv1QEpj-WO8nKOP56ab9yGjsn",
		"DNV-CG-0557":"1Ng8EOJ4f85cjnO_4jJfFkwcM9CK5x6g4",
		"DNV-CG-0493":"1NwkFnsrKxBbKVFJS0J1nDni1nSibWE_3",
		"DNVGL DNV TAP NO. 847.40":"1RJemWXnDNhxy9qZftKYsokxbp8vzkI-1",
		"DNV-CG-0608":"1blHEkjKchzNjBG9qliheDeAmDjhYrB7h",
		"DNVGL DNV APPROVAL OF SERVICE SUPPLIERS NO. 413":"1fRH2cXq78-WWnbxS_w300scICQu8PiJ4",
		"DNVGL DNV STD CERT 2.15":"1gFAN9x-lLxMkUytGHAgXOAdXFWffet9Q",
		"DNV-CG-0647":"1giicQHRsRk1n-yeoY_UdaXT_iXZ2b_Y5",
		"DNVGL DNV TAP NO. 847.92":"1j0J8OZVYaPR4Kw44yQEGHXKCtXX8c9HI",
		"DNV-CG-0456":"1k0QLxQv1tDhtE5OQ7RmIoqIesEpnZnUv",
		"DNV-CG-0550":"1l31bNG7MANaERkzWvYuBjtgSn2HD8VKG",
		"DNVGL DNV APPROVAL OF SERVICE SUPPLIERS NO. 419":"1nKnWGAWOVUzmkYv7xx_VzbY9s5_Lk7gB",
		"DNV-CG-0649":"1nnjCO-4efeDY3uPM5Jg2MCxrjKWjznVg",
		"DNV-CG-0554":"1petWy-SPYWdPWo5kuj7MsbsfmHEageOe",
		"DNV-CG-0564":"1rwkD7AJTVC_oIldBdsJvjJyAZCnQtsUt",
		"DNVGL DNV SUMMARY OF CHANGES":"1tG8Eyyvhz-rdLx6CfBYvDJO5yICd2x1l",
		"DNV-CG-0508":"1uvFt6UPjpdSBBdru5ZztADE-Co6rutcH",
		"DNVGL DNV APPROVAL PROGRAMME NO. 313":"1vA5bFHBjsxxfGOcuqSqF9S5xoYXw9r-6",
		"DNVGL DNV STD CERT 2.12":"1wz4HaUsnru8apbiA1GBtbogGINvX_A5-",
		"DNVGL DNV TAP NO. 841.10":"1ys70z5skBhE5mzDixS24CcD59Hs7eXFi",
		"DNV-CG-0556":"1zWITKGvWjFquHPA_0gwo5WCOcf1czbpt",
		"DNV-CG-0158":"1-JOhP-aomNwL-9or04pC5bR1UJrTyCkV",
		"DNV-CG-0285":"14OOzpUNGh8OHwDvK4kjOsjF-ZlZF8Cw-",
		"DNV-CG-0155":"15YaKEm7vpTE5OwayDZg_qQSLNzhgepwA",
		"DNV-CG-0170":"15ZxPotafft04YeDN5XUD6qo74zFT-kJ3",
		"DNV-CG-0214":"17Bq282sDZvPhmjaet0oZS1S3wD6568Y5",
		"DNV-CG-0287":"18Ot9Wz6urluYhd3MD9aJYKES-SfNSPKo",
		"DNV-CG-0372":"1DSAB3TAdekkiYQFPZnzypiwE8_Z0nqsm",
		"DNV-CG-0156":"1TYkaiYtsJPTFUN722yaWiR0_Ee6WRJTq",
		"DNV-CG-0313":"1U4yVly6g9yv-0ja6EezNJ2z6H-4SzLtM",
		"DNV-CG-0197":"1WXHBCL-oFUDEyC0aSSWXZwQyDXGFrvtf",
		"DNV-CG-0283":"1YWi2P34z3xFSaBGlFKNOmHd-zhUh9nq0",
		"DNV-CG-0264":"1_phBa3RFT2F8l-d8dbK4q9xYf8hjr2jL",
		"DNV-CG-0308":"1f9efUevyLAAxSX0_xY7Zj8-mbht-UXqH",
		"DNV-CG-0240":"1mVy1Nzd3hOSFjLqk89cYb4S005kXRad6",
		"DNV-CG-0325":"1monzsi1j3G7xJTp7aDWNquYb43cP0eJ3",
		"DNV-CG-0154":"1tJCp95FIQVVe5vMqhMvG0uNU_N-yGXpn",
		"DNV-CG-0157":"1vCo4ujXFFg0MKn8bHqJ6mdzKOQHws5OS",
		"DNV-CG-0182":"1wjRtOGuoAhJlob_BuFd0lUgAm5ghI4Ds",
		"DNV-CG-0288":"1x09NvwjAJGl-mwxYmog77s9nuIg-7yy2",
		"DNV-CG-0162":"1xb7UcToAJzLBGGi0scuxSWQxkbyLzPnJ",
		"DNV-CG-0339":"1xkkUlKR51kKITKLesLbdgLtuTMpdvaKR",
		"DNV-CG-0169":"1zuA57Ciua07ZeOESsCH-2AVigrGkloZU",
		"DNV-CG-0121":"1103BNHYp_FZduDjdJLjzHgckWD1aATHB",
		"DNV-CG-0063":"11ZPe3EoBJ7Tr0Sc_LXv1r2_EZSgakZ-E",
		"DNV-CG-0058":"192aW0Qxr2uByByb-vz99h5V63iCgzI8S",
		"DNV-CG-0128":"1AQyoCsA8Pl5nNygOvicgnyiOEeSUaFl8",
		"DNV-CG-0137":"1VJ7Vo1dhoE5J9LfLEd0SosjSLI1R_fV6",
		"DNV-CG-0136":"1YHGjhPLKiiAGZAe3VmVQDSgdmNrtQoiJ",
		"DNV-CG-0130":"1_zrDKxoKQmdLeik2nSl0B4-rp6dLGscB",
		"DNV-CG-0053":"1g9Rhv9s6gywA_mM4WmhIc_FvMX8ifrqH",
		"DNV-CG-0060":"1hsInw31bh_qXWMUNrr8Gfytl6ebfCgeX",
		"DNV-CG-0133":"1jr56iv2bzwAdU6fFEfUqxpfJb_1KEeyw",
		"DNV-CG-0152":"1maRflpJMhf4EDI9Apg0UVRppWWcuKhX9",
		"DNV-CG-0138":"1oAJplfSdzDiMCTt0-f7LMYl73lWu5rTB",
		"DNV-CG-0134":"1pKZrt52olTs8_vjwLEkXTLifFhNhRgLh",
		"DNV-CG-0131":"1rDQfgjPjMyyGUNgwOW-tl65Ko1kRc-KC",
		"DNV-CG-0151":"1ssU7df3yenWdPWq-EK4kLa8GdvL77wdb",
		"DNV-CG-0129":"1uD7vdVIT0aHY7W9AHCZ6j6DzrDw82vTh",
		"DNV-CG-0135":"1uE4CC-N9qnMuxYnUbh7T2oY_nYtTIOAD",
		"DNV-CG-0127":"1wLsHrBBEU-72OU80tpeio_G1eD9Q1Uqk",
		"DNV-CG-0040":"10UwJkhOkhy2i6rwhdhF7tnrbtQbjbBFG",
		"DNV DNV-CG-0182 - Allowable thickness diminution for hull structure":"192uMSIg1bW6MHRm90-JAC3R0K6-47arv",
		"DNV-CG-0041":"19H7Zq4ioSBP24-1IFbUAPL6AgbQ7o2oj",
		"DNV-CG-0042":"1BEg7I_DpnYUwe3jxXLMHP4CYnyW3mmpX",
		"DNV DNV-CG-0157 - Stability documentation for approval":"1CcY0zz9mLXqaOFVGls_kvwgQdAKM-P48",
		"DNV DNV-CG-0339 - Environmental test specification for electrical, electronic and programmable equipment and systems":"1DEsDtNS3R-_JwcR9f3bgS0er7rD4_qjp",
		"DNV DNV-CG-0508 - Smart vessel":"1ETBKQSzVzkBAvMeldOzGTVomEAMITDlm",
		"DNV DNV-CG-0649":"1HiNsD73AR_JKQQGCPfFrY-hWD2WG0VVM",
		"DNV-CG-0051":"1ITfdsIkr0Z0qELzFKZiOYsjoD7rzvZK-",
		"DNV-CG-0039":"1J8vVVPCATb29d0EFjy3DcKM2EeMslqF-",
		"DNV DNV-CG-0447 - Lashing bridge vibration":"1ME7qMaW01wCKQd1KzKmmyptA4Azg-Ax2",
		"DNV DNV-CG-0313 - Measurement procedures for noise emission":"1Xz3lAFsckG1e1bVIyWKyMy_usTi--ooT",
		"DNV DNV-CG-0372 - Foundation and mounting of machinery":"1_tfxuHAi_0WdGFNFiq3pnmf4TCpVkqXp",
		"DNV-CG-0052":"1bksBB3Oi6_FJ49LGuLpbABORXZpUUcIh",
		"DNV DNV-CG-0151":"1eXxg16wVgVdIekTOgn7uKasdBcNakwOC",
		"DNV DNV-CG-0155":"1gPy89pwhqqYxRW6J7OFxIBJgS2I7AE4D",
		"DNV-CG-0044":"1krFeW7NX6geZEOVw6EQH_tYSs6KqakR_",
		"DNV DNV-CG-0325 - Cyber secure":"1l-dT0QuqUXcuSTW1k033Xz_z-lugz61r",
		"DNV-CG-0037":"1s6b-GHs3rBpgXCsdOgynfaMCyT-W9uNS",
		"DNV-CG-0038":"1xfXRaxCAEDDMeWfOl2ciE7diW7FV5q49",
		"DNV DNV-CG-0308 - IMO Polar Code operational requirements":"1z0_Qr8xG-Tnpi4qBYU_4O-KywRdzwSeJ",
		"DNV-CG-0004 - Safe return to port":"1zV0hdpVWWqRsqEOKJqNtHWXp9Zvu70X8",
		"DNV DNV-CG-0162 - Robotic welding":"1-HTQte5T9awy0qfjTklfTTZsW5GHzpio",
		"DNV DNV-CG-0060 - Container securing":"101tXjiRRkRtRFx69Axv-hjJD8llyMN9I",
		"DNV APPROVAL OF SERVICE SUPPLIERS NO. 419 NOTICE":"13NrAJeTpYSUjpajTuamXu5W68PCKZopD",
		"DNV APPROVAL OF SERVICE SUPPLIERS NO. 413 NOTICE":"16FkLZKS8cwZ5dX_s3608CC_wbPE7KXJa",
		"DNV DNV-CG-0038 - Calculation of shafts in marine applications":"1AevkBBeYX7ouzG_igHibbVL1ojEsY0pJ",
		"DNV APPROVAL PROGRAMME NO. 313":"1D2g2tmXospC4XYWJZq1YZHRbFCkO1gUD",
		"DNV DNV-CG-0042 - Cargo vapour recovery systems":"1DL9nly9LbG8vdnFnA3-y1yY9fqWK4Uod",
		"DNV DNV-CG-0127 - Finite element analysis":"1GO3M_GFy9QzNIOE0RFzAyp5kmgFUlZju",
		"DNV APPROVAL OF SERVICE SUPPLIERS NO. 418 NOTICE":"1KFt2d8DS-q45loH6XSLt-YM9iD5HJ6JK",
		"DNV APPROVAL OF SERVICE SUPPLIERS NO. 413":"1Km6btuCgYdlsUqD30nUSyFSDzmQkl27K",
		"DNV CLASSIFICATION NOTES 30.7":"1MnQYJvRi1qsUB_uoQFQv63aKyGyZZs3Z",
		"DNV DNV APPROVAL OF SERVICE SUPPLIERS NO. 418":"1Po4Lq5f7cmUVDGNoC6g38Ho8ishZcNXo",
		"DNV DNV-CG-0136 - Liquefied gas carriers with membrane tanks":"1PplO2Akf0Vg_2jSqzJZXDPbJ9XY9hVrR",
		"DNV DNV-CG-0058 - Maintenance of safety equipment":"1TQDwCV9mMfyjITKvH6XVJaqwiE8CN-We",
		"DNV DNV-CG-0039 - Calculation of marine propellers":"1XNedG9Qb4Th3u319CfUNotTOEib4_zK9",
		"DNV CLASSIFICATION NOTES 31.3":"1_Fjh28p5rACuHtfwiredLqLU0epmW9Vd",
		"DNV DNV-CG-0053 - Approval and certification of the software of loading computer systems":"1cfYmODuZiLnvHnCg4fdUaImb6DDpEBgg",
		"DNV DNV-CG-0133 - Liquefied gas carriers with independent prismatic tanks of type A and B":"1dCmkVLNnx3-9y6N--Zn0aYLq_VW2NJOJ",
		"DNV DNV-CG-0036 - Calculation of gear rating for marine transmissions":"1n4tWm33Fc6z2iTgtgzqQbQMEXyULeyO4",
		"DNV CLASSIFICATION NOTES 30.6":"1pqXEKObBrcD-a5dSELantXkDnoMzeTl8",
		"DNV CLASSIFICATION NOTES 30.8":"1ufTWoKCv63B8jJgZUO2c9D41sc_2lyqa",
		"DNV DNV-CG-0137":"1x0t8J55WHPkl2jlCDmv8hrKDTzZ8R044"
    },
	
	IEC: {
       "IEC-IEEE 60255-118-1":"12ra05oTJvZQLBCt2qsmcqCFjMXGFPR5D",
		"IEC-60300-3-14":"1YFawtFcUxi-grslxiXHZdOS4aFWqKou0",
		"IEC-60300-3-5":"1lMzObr5NJLPIMUPhx5Cxfo7JCe2xts-D",
		"IEC-IEEE 9945":"1lWVahhVX6DtAuzwbSRod4YKH35YkK1EQ",
		"IEC-60300-3-3":"1yjjwYC8OoLE1oQRviuXR1fY2ALftijMo",
		"IEC 61120-3":"13WRjWVTv7mq7izZQmdG1X5w-XbezgGOH",
		"IEC-60300-3-11":"1AtYG8NJ4-k3nQd21pK3uLG1JA5cQQt-W",
		"IEC 61123":"1HepjgfbGGJQHYYWWxN6RWHzaWDWziMWB",
		"IEC 61120-2":"1RRwYB3zqWQo0Xa5gnx9ZNp3TFOhGgQQ7",
		"IEC 61124":"1UmXAsHAuf_sURiJpqDwiXcRJ4U9mJlH1",
		"IEC 61121":"1X_OtDdhGPPe9IqC7y3xCPJ7vnuFe0VaX",
		"IEC 61120-4":"1YK70yY_vJh43vF8l9T9cNs9BG6D1Tb65",
		"IEC 61119-3":"1a5MFm3checkEX5YVBjIg9r8Lxknhvv5i",
		"IEC 61119-2":"1azB8WdkUGJtE30bzLeOxRr1F7rYA5lwM",
		"IEC 61119-5":"1foeeZLqLOZAPZaPoSl8XSqtUqWH2lqH5",
		"IEC 61119-6":"1gvwR5w7Ywa_S-z95LyFiIlueTyNB1LXt",
		"IEC-60300-3-1":"1lfQIttsH22_0UjIdjLmZhSUVdnU-FiMn",
		"IEC 61124 CORR 1":"1nouMO-vnQb3OrL5jeXwNugXUjKjvF5WL",
		"IEC 61120-5":"1qhdN4lsDukSTDYfOu19HlVQ0TBmisBXC",
		"IEC TS 62443-1-1":"1s2ZdYNitsD1I__VD3AV9Jtd6fMcBnDUP",
		"IEC 9506-6":"1tqWEHCSHCxJ7WsvXCS0teSxKWx95o4QR",
		"IEC 9529-1":"1uduba1Oza95E0mlyHhS5EwY17g3myusN",
		"IEC 9506-5":"1xrgyZMNFQBSFA5J1yXi7lXJlp5NN6oN9",
		"IEC 61120-1":"1zEJVt8sF91Fpn7urN_a-LU1V76d6Vnom",
		"IEC 61112":"1-Qn-j9X9STQsm9uomx85lXOWhnl9gUry",
		"IEC 61094-5":"1DYy1rriaCr5NZXvdXHKn6viOgwSBvE9a",
		"IEC 61114-2":"1GEQ2H0sitRZkA2RbUko5yDYpm1T3s-ea",
		"IEC 61094-3 CORR 1":"1HybxbTYTB82gNwbaabFOXBWKpiynur9w",
		"IEC 61094-4":"1Ik_oUs0JDu3mi0bZVcF_42S3NtE0CqK2",
		"IEC 61096":"1NNIYZuv_bOi28TFlkIqgV1gYUM32aKzW",
		"IEC 61111":"1TIVet_ubQkwgowVX5cw-JZLKWHnZ50Oi",
		"IEC 61116":"1VXl70SOrKV2FPlV0PlQ8PzXtR_mxXw_P",
		"IEC 61105":"1XVugMk1viyXby6BYEEtEev0rw_zZ_B-A",
		"IEC 61094-6":"1YTgbN3t3EMKyM9VzAUGMt4nlGMJUIXrD",
		"IEC 61119-1":"1YwtkdgFMQZJ5E35R4KtgSv1AnLpEuMjz",
		"IEC 61118":"1ZIQF7fadmtE9vBokL30WK2QhvWqKp-Jw",
		"IEC 61115":"1bHXyXnOzR2Mi1IHqK5p9OL0MGIJgEjZt",
		"IEC 61114-1":"1fjEuHwQ7VqXh-yyui39PcXbHu5TaVEBI",
		"IEC 61094-3":"1gXYDpVrnbsFrJlwT2_TPBO5oCTKb35dR",
		"IEC 61106":"1hIrBu_diymeExWOgYfdtF_LUzPCuKxuW",
		"IEC 61094-2":"1v-kKG2GKZtWDN_83ybh6qBV2iUl0XbS2",
		"IEC 61104":"1vFHDBg40VvDW8_AP8O4bEPlK_MhXu2Yv",
		"IEC 61076-4-115":"126wuOgaabfI2c9ABlQCgG2hu-G3a78Qu",
		"IEC 61079-4":"1Cxz7zORj-Rr00l7GVqoppYoF3fPcqM_Q",
		"IEC 61078":"1IuF96RID9rfJ9LKCXSx-sA_JEeZbU7F1",
		"IEC 61077":"1R-RVfZ_zh_rOzSiPKNkWSeusuBK2pv0s",
		"IEC 61094-1":"1Uu1qr57qSPOyWHme95-_Su1qadyAnATb",
		"IEC 61076-7":"1Xkv91YLXPcQL02ElNuemW_sXstZ2Rf0K",
		"IEC 61079-2":"1Ygn-qlHrYpaFWWI8mMw0BHX_yiPPJi18",
		"IEC 61079-3":"1_sO4Ak27hUHN-M4r8E_3MK1OQckTaPUN",
		"IEC 61084-2-2":"1f2rqpdhCepgtPUKM5RT6MK9u0DjsdgMK",
		"IEC 61079-5":"1fL4D6MOgi5PbErwx5wYxG2Yj3ea_O5bW",
		"IEC 61080":"1fMW5HXm_00Ffc_EwIDTiAGmLPuYArJzD",
		"IEC 61084-2-1":"1sBY99kfdf94asI2E5RErYuMB8UJBeQ0_",
		"IEC 61084-1":"1u2zjat_M0rQgTDJ9QhUdP6cfTrcnnrtL",
		"IEC 61076-7-001":"1uMMhfzXxmyXXvfux303c6DLH4aDq57fy",
		"IEC 61082-1":"1v80p3_7EMyjai0ZRCF2b9xnqrvrd0noa",
		"IEC 61084-2-4":"1wmhpwoQQCXRQf9ZIGbx4dSrvrjAjtPKB",
		"IEC 61079-1":"1ye6ZO6DnIjcjAlrdlb-8DSQ2PhqhmHPf",
		"IEC 61076-4-102":"10nfSuBPWWvmSROVP-mLc-KodR-TA82ek",
		"IEC 61076-4-001":"11HIR0GcfFeK0HldRANMEjIaIN2MQpRjJ",
		"IEC 61076-4-107":"12IuaQtp4AdJS6hI0xtdvQZWjMYIAf2zK",
		"IEC 61076-4":"1BC88McZ4guUQl0ATFBTxPc3B-Hpyg08T",
		"IEC 61076-4-105":"1F2LsAooGDuLXv4FzDvpiwvxwb-063ikM",
		"IEC 61076-3-101 CORR 1":"1LqLEtr6DekIwkIXnvIqkgA9yKv4ERIEO",
		"IEC 61076-3-104":"1OzMe9Axk4UcO6rMTwFSboyg8abrJOLCz",
		"IEC 61076-4-103":"1QgCSq3ySJln0svDVJA89AiPcCFDN1Wbw",
		"IEC 61076-4-100":"1XFnPuzVIUpHC4yYs9e12KDuTi6VbvU0X",
		"IEC 61076-4-108":"1Zid_TnkgWDP-1rftWwVmkcl2LCptdwz1",
		"IEC 61076-3-001":"1aX4eH1d_Q3GUe_TMkAf-suRimG_vTIxn",
		"IEC 61076-3":"1duGBEAoBJtYvlSnOcdIgSQQeszTxjHMB",
		"IEC 61076-4-110":"1e60xW1LD7fG10ga0zx9ni0lAiaj1i4iy",
		"IEC 61076-3-103":"1iQBr3oN_CqncQROs0YQcm6qbbcKPTI8v",
		"IEC 61076-3-101":"1jAvUZx5PcmjLIUINje3sRnL5khCVV0_X",
		"IEC 61076-4-113":"1kzW97B19kWh-x7PhAHOhvnVyrlamEwqr",
		"IEC 61076-3-100":"1mumn8P_aejgTLIR8UAa7DTcEWrfQ5t5U",
		"IEC 61076-4-101":"1swLybPXGvQ2RNhP7nQrFvl8tOKG87dZZ",
		"IEC 61076-4-104":"1vDp42o1vsye19j6go4rm5x79FJ15UxXF",
		"IEC 61076-2":"12nB8g39g8FDYhyyu2MkDyuiQUSHEltFH",
		"IEC 61070":"13j8F7Fg141LpGytFXbmMRT6iyN294Zqh",
		"IEC 61076-2-101":"16LlOT025KC1dkTDCTqzV-lEbxUL6_wE0",
		"IEC 61069-6":"17Wgm0bgPr5i8VNhpxaSbYQ1rehYom0m7",
		"IEC 61069-2":"1Cc98RRkvN5hQunHO2e9UciVxKS1oLpim",
		"IEC 61069-5":"1DdwUNO37_qgrfCRk_t0F9CMr314M5T8d",
		"IEC 61069-4":"1Fb2vQgz1uzw3sPCAtj460Jjq_8Es3bEQ",
		"IEC 61076-2-103":"1LB3lNSw63qOIyXhTds5BFS3_tOu7KREI",
		"IEC 61069-1":"1NDZzK_m-ysNjZoc4AuOObafmO2KoguDz",
		"IEC 61069-3":"1OVQKC55q7fFJGWlYtbMgMoM-SBS9U7_e",
		"IEC 61069-7":"1RcnPwb5l2y1Sf_O1VWO0ET86qIggxjcf",
		"IEC 61076-2-001":"1XW0IZHfNtL-TBPHILTiA3gzc2we7YtV7",
		"IEC 61069-8":"1Y849YjOB3aQrqMKDi3Shu6jsSsGZmYT7",
		"IEC 61076-2-102":"1ZaGVvW12byjdktrXXiPqyAHrQ3PcwT8l",
		"IEC 61073-1":"1am_U9U3O9koXTO1eRvJPbrjWIKtz7YHJ",
		"IEC 61076-1":"1bWDaAUt0IehYUnYn-1-hJwqxBtFBXRTs",
		"IEC 61053-2":"1TZQWtVYHLwWYXrBgh_dxr3kkLpQ2QJZV",
		"IEC 61049 CORR":"1_-cXpDLzq0WkwGyGolwxBZm4_27_BvyL",
		"IEC 61043":"1hFN6dGRg2CUmXWX0tIsNqbfNQWEjcXzo",
		"IEC 61050":"1nt0VERM9-fCOS5JiwnZlQle0hTwWz8_W",
		"IEC 61041-4":"1qyxQ0ehl99a37b6GB6crqW3ufwfrr1ZD",
		"IEC 61041-5":"1wQH11WmPHfuOxQk2tixVfsTnqKVgTCdE",
		"IEC 61058-2-1":"14Yeo0PQi-wetOND8rUJsu_o8Eb3m2LrZ",
		"IEC 61058-2-5":"1A3gEANhojEqBCoCNBkfradtVeT0HtXew",
		"IEC 61041-2":"1APiUb-jsJJ65u3rgCbh_TljnkNd-00Yr",
		"IEC 61041-3":"1JWr8-kfYEuj_h0lRldfUKxeMu9E8IpoY",
		"IEC 61049":"1OgjLvPOi6CZr88_XvL1oPKY3bp3vMXdc",
		"IEC 61057":"1RJL3qf26C8O5yB9iIbRBd8wF5W-TMugy",
		"IEC 61048":"1WoYniNhetgpd9CAmDE_6baSeNt5PytIm",
		"IEC 61053-1":"1bkTPGtntn0Oe84D8mE8XkSK6gPIwyULE",
		"IEC 61058-2-4":"1fdBeCAooHehICeE3TqFadvH83XHt7W67",
		"IEC 61058-1":"1ne38ydOVFKDbkjNmhqFNeg5AX44e7DBl",
		"IEC 61047":"1qyfklxlAl_p2Hb-I8c2w_kAA-IaSUw3M",
		"IEC 61019-1":"16gzGMgezSvtNbECQdWcKiImOUbuv5esF",
		"IEC 61019-3":"1CIXHJyfvBLnNEYcSTwoKmJgs2DfXOHAq",
		"IEC 61014":"1EK83_jPse-QFgDOccnsqR2-UhLIPCEoc",
		"IEC 61009-1 AMD 2":"1Je7Nc9BP1TdLwtexzmc5m7bmrEppvX8i",
		"IEC 61016":"1Kg7VBjb7LF2w-IvYh2EiU0nd-WGGH5U1",
		"IEC 61034-1":"1TPEQeM5LWsWyk7XyR2OiFH_0HAADm9FA",
		"IEC 61041-1":"1VlEsIAeASxaqccuKzETraOrdis8bxtg0",
		"IEC 61019-2":"1ZH0F6ZePKs2J1FmNKBstD7do50BrTD7g",
		"IEC 61025":"1a1VpK_abdx_PZNzMo9-MKGXcQa8m71cj",
		"IEC 61012":"1ijYn1Bcu40Y6zrRl8WbjOtlRJJQQs3MK",
		"IEC 61020-1":"1jHkXF4y3Q7cbK7uBlLkaqYURTWgGdkjG",
		"IEC 61034-2":"1og_UTxpi07VLghtTFPV_15ftS_R0Ps7R",
		"IEC 61032":"1qmBZhs2q0UtVrXQNL458L3wJoJVC8M_z",
		"IEC 61009-2-1":"1vGD48lm7_Mfe-m77gwEZd1Ql-wa1XEO_",
		"IEC 61028":"1vRWXXlliD4mFv8Bughpa2BD4AOQFq8SN",
		"IEC 61009-1":"1xoSBQMtQ8eMfbSVy33aqaWGsFdcjTniM",
		"IEC 61009-2-2":"1zlIunQqIw5IPBrdz40vH5YfpPE1SGCfc",
		"IEC 61008-2-1":"1-n0g9hHEy66BhETFWblLhBxToRuu1HXb",
		"IEC 61008-2-2":"1120QIihiw8Pfk2uWquYcBVL4ClDCdR1a",
		"IEC 61000-6-1 (REDLINE + STANDARD)":"14fZda6_YMsRR81fUSsD7czHXkBE8ZqpF",
		"IEC 61000-4-9":"1FbQ2JGgJzpP2_iUvLlSUl9l1VVFR8CBZ",
		"IEC 61000-6-2":"1GhF6ItpKdoXAkiAM3M5McrWP0jQIy7Qv",
		"IEC 61008-1":"1OFQrzgvf4M3hWuXWakbmbMJs2uefrBWT",
		"IEC 61000-6-4 (REDLINE + STANDARD)":"1OHZeFbB87M-JDOw4oPnce-rbshVBedyG",
		"IEC 61003-1":"1OcxPtoKjZ67Qw24729ajzOGsvH8JkPHb",
		"IEC 61000-6-4":"1_feF4iaxc34Ii6c0w4ozE7tkHss7gyIs",
		"IEC 61000-5-2":"1aDbN04cAO0JvRZvi7PHBEvp1GOjoXF5W",
		"IEC 61000-6-1":"1dbazk18OGOHBoTj7v0vISS_uqeXSoC0Z",
		"IEC 61000-6-6":"1eL2sfJtkKD-T7c8tw_T9iIn7xKX4mdTF",
		"IEC 61009-1 AMD 2 CORR 1":"1erXW8sOnMI3R5u8sNSflQr0fDtns_s8K",
		"IEC 61008-1 AMD 1 CORR 1":"1fdJW1-6xldURvJ7hJzG-N-jfpoJnVu2k",
		"IEC 61000-5-7":"1mJWM1HI7tCV2JcfZQzt68cYaEHRD377T",
		"IEC 61000-5-5":"1vuRnJ56s5Kw1x0ir5Fg59FQ9gGhKEvwU",
		"IEC 61000-4-30 CORR 1":"15NgcEGlE3ogOQtGToNVQsoALy6RfnIsy",
		"IEC 61000-4-7":"1AFqF9OMvRr5gNsSDJDTqWMqdXAkyVGmE",
		"IEC 61000-4-27":"1Ajkwrpb9momRPjFjaRunODizrkUbmD7T",
		"IEC 61000-4-33":"1KEBDb4IQSUXgYZIf5J9O73akJ37LJ5It",
		"IEC 61000-4-5":"1KZDmDA2Ho5NEht3B3LLu_XV-kEAfFiIW",
		"IEC 61000-4-34":"1RSSRiG65ONgkU_Y_91vTdMQjxnweF1A0",
		"IEC 61000-4-25":"1aVT5A-1twXWWSb5QPPJ0NbN5nz0xlg59",
		"IEC 61000-4-29":"1crZuHYHozKFBUhdKMkgE4SJ64ecL4BoW",
		"IEC 61000-4-4":"1lvgn62Zzk65Y9Ti1GksdhvG5z3WzBIxS",
		"IEC 61000-4-3":"1n5VCkGP37geqLUPjR9aGzYejCjODUPvw",
		"IEC 61000-4-6 CORR 1":"1nEy2S8_J68KyjjBUdPMqoedxFLoEnjJ9",
		"IEC 61000-4-6":"1ql_ebmccIxJpdwBWe0LxTS-1dqUnIVxI",
		"IEC 61000-4-30":"1vIkSGbeBMoQlyqr-vve2f6ft3jiC3HqV",
		"IEC 61000-4-8":"1vMsu0uRiHo1qsP9ckldt0Wlux6d_XSZ_",
		"IEC 61000-4-28":"1zoWxRAgZVtVezL-KvVSnzjABEhbLElSa",
		"IEC 61000-4-23":"10IXJvG_M62HQuEvmPVfizVjz5awFxvQZ",
		"IEC 61000-4-24":"13BqlO4Q7w3G8wayMt-D-8oPDgYWiBnLj",
		"IEC 61000-4-15 INT 1":"1CLhO_II02liAdpps0tiBxB7RjjTMDIEC",
		"IEC 61000-4-12":"1EBBOzMt9KUeAfosycLilImNJOixAq3Ab",
		"IEC 61000-4-21":"1JC9VpLnF-PHWgt_G8ae3oejIPu5JZ_4D",
		"IEC 61000-4-11":"1S6VB9GWLKs7Fqr01bos7ykdm0vk-AqbC",
		"IEC 61000-4-17":"1bP7rub4TkRCPX23wlRs-GltvrU6uVl_B",
		"IEC 61000-4-13":"1c7pTH4wl74weB1XWvsLLiLEBhlipeQKa",
		"IEC 61000-4-16":"1iG23fYg58pL9fwJbLWukBYOGUMjKyUo7",
		"IEC 61000-4-2":"1iWAO910EM9hfhdfE7Tfom2FJMpr9Zidj",
		"IEC 61000-3-8":"1nLJPXVsKIQRh-OSQPSFrJtqKQZyXWJDA",
		"IEC 61000-4-14":"1r009J_xIxx6Fr0oRzpVuI7HSoc_WsKUX",
		"IEC 61000-4-20":"1s4bKUJfsj7YX99bq9US-4NAGWgsQXNas",
		"IEC 61000-4-15":"1s50b0UUHW1snx9qOpGsNzL5pCOgzBi_4",
		"IEC 61000-4-10":"1tcxjvsJRMEZGAnkN4c7x4Y34D9yA9oHq",
		"IEC 61000-4-13 AMD 2":"1vdvSuMlxqR_m0dq4Dk8uxCCwiseao4OG",
		"IEC 60998-2-1":"1-eEy7ELBumSSR81_irr3MEkRS-ikshRm",
		"IEC 60998-2-2":"1BmtykARuh3NI_ah0_qtBb_NtDUXfEYdi",
		"IEC 61000-1-2":"1DasuD-0ugNEvAs9VP2q2TIvVy7wo8xcB",
		"IEC 61000-3-12 INT":"1Gc4s5qiP_9RwHCxeTvYfquSiVhiGJgWc",
		"IEC 61000-3-2":"1Ic7633dTbYXDIfi4aEEvoEiGCfArIaUn",
		"IEC 61000-2-11":"1JuCpDfE1cGN2OzEQh_0_aiSnSL3e8WTx",
		"IEC 60998-2-4":"1KBdv9Fo8iaNnfbsLk1q5Gbldz91rnehf",
		"IEC 60999-1":"1MN2UGjam4V6tqr-kWyXMBOrfxIAsCRln",
		"IEC 61000-2-4":"1MYwq5Szgxr6kCJgBsC-P4ajZzQxyIX7r",
		"IEC 61000-2-10":"1MrCyTM_WGAO2_8bFaFKheUxa_bI9Bdyn",
		"IEC 61000-3-3":"1RoANsPbgw25fdZiDxnPcedvGYzTbfVEk",
		"IEC 60994":"1T86Q-jboDdsrjlXFvAGgRn9JSzt9TI0f",
		"IEC 61000-2-4 CORR 1":"1UUUVIqhy3to_CKZTiG7zTmLOHG4Ph9gR",
		"IEC 61000-2-12":"1UurAj69eBltr3mZp7E4mrUewDoUnIshG",
		"IEC 60998-2-3":"1VDAP-2YR2tJCNpjZQhL6ggJkOYORR0io",
		"IEC 60998-1":"1Zw9AwBFnFi0CITYqS_HnasZW-GkG5TN6",
		"IEC 61000-2-9":"1as2d0hL4U2I9O7OnZIb8ZCkpukvnvyo6",
		"IEC 61000-2-2":"1jxyex_s1Ugzi9onD-58FAhHcy7dUq0oZ",
		"IEC 61000-3-11":"1mZ_hi9HI2chfvF_CtFj24QQXzthos1dF",
		"IEC 61000-3-12":"1n9wm6UPugMSN-PrISIgQp81DsA7Jj9iw",
		"IEC 61000-2-13":"1qRhJp7WQMx9ME38IQjfiUaSJRjEMIiyF",
		"IEC 60998-2-3 CORR 1":"1zSLy4dhW2CQh9ZHlAfB0XlM3jafD3tWx",
		"IEC 60986":"1NSZDVY-w-NyZnOc3_hcE-ZFjcKJaYQTC",
		"IEC 60994 CORR 1":"1hnUGsLY4imAzawNMIGJBuG-gc1wnUmvO",
		"IEC 60968":"1yE25lVUKrPdmhmuq2kgH2gVuxsmqVpC8",
		"IEC 60979":"10WyVSnqjZeeJYkIMFRvXnscWq6qAlb00",
		"IEC 60969 CORR 1":"11SLhvm-GNIKNn6oQdRXjjxgWLT_83HQf",
		"IEC 60969":"124khYX6QLaigVCRgmDWWqiB3qKTvygwb",
		"IEC 60990":"176pgG2PSwnZ5E9pMaVh0c1uN4ry2Awke",
		"IEC 60968 CORR 1":"18AVNjSVBFSC_iEFV6eD_eM8ZBzXEhNSI",
		"IEC 60983":"1KgQa4VSnama9j5LHMA29Sj2ssNc9NoMJ",
		"IEC 60966-3-1":"1OV-o6KsSj6wiJwAAFUxyE7C1eb8jF7NM",
		"IEC 60984":"1PjpewMj1CKf5RzKRfhJVtDpB3eKDqMiY",
		"IEC 60981":"1WDhXeKFtixGRkJUzPsazCRMQzQzv2-I2",
		"IEC 60966-3":"1bTu172N-naSvKCni8e2AaUuORujQnAln",
		"IEC 60966-4-1":"1iV98JaBBCYuTF_82LFR-5EOUCKQYu8vh",
		"IEC 60966-2-6":"1khy0IItLvUDaDFkqKx8ZPa5DYHYJR7iv",
		"IEC 60966-4":"1mb1e4aqKEmLdfxaVPeatgcJ5mgzizq2z",
		"IEC 60966-3-2":"1nJVgWb41VjyPaec4jnzNW82bN_EcQqZw",
		"IEC 60969 (REDLINE + STANDARD)":"1uBRG_JrP4X2Bk75a1vQ_sEjvLTWfL6UY",
		"IEC 60958-1":"11yX-rm2bY4EeRbRN2WJAZhikV0x48bcH",
		"IEC 60966-2-5":"15tdFGUMgdZ27GLxtJvaurIFoK3jhzZx4",
		"IEC 60966-1":"16gBm7kELx8Bn1-8jcGnG-JEj9oOki7P7",
		"IEC 60950-23":"1HDKwNt23UR6IztahO1b9zVea_QGSh-Em",
		"IEC 60961":"1Na7B1iFI6YOzwAyZJR1dUk8LsjEVZRp3",
		"IEC 60966-2-4":"1QGTtoYC7hApO8AbYEnysfBfCz2zMwuQU",
		"IEC 60958-3":"1Qf5pP8rSjXPFPtY19Hl2BIljMB8d7QNk",
		"IEC 60966-2-3":"1Wp2rQqi49wC8p2sxiNlh1CPwuSuO7hg0",
		"IEC 60966-2-1":"1X_s0b8SsZEFSU_4DGaNWt4C8GVFMU-ge",
		"IEC 60958-4":"1aW23lM7aml1jGK3PaIOMFyOJBe3o699T",
		"IEC 60958-3 AMD 2":"1ao-HJl_9M35R1uDyAEWbeMqwBHIzbDOz",
		"IEC 60966-2-2":"1n8nKiN2e96mIDzuND95ZaugQ_BKiSl-p",
		"IEC 60950-1 CORR 2":"115Eu8DhhVBhaZaKsBdQOgO6_8qIDavUy",
		"IEC 60929":"16MYbilLQljhyp_HQXxPWp1NEJ-22n9f9",
		"IEC 60949":"16pHTZDDuV47FDcPQhXBvh6-FiRg8SL9K",
		"IEC 60933-5":"17pEFukDu4px4SslaKln8x_5L-LfA0gZm",
		"IEC 60921":"1CpPCLQ1xNq9wXzljX9wF-UXbDj5OQMI8",
		"IEC 60934":"1Fh6Uvhq98HG3TBbgQ91t9HJ1xeRMXKEY",
		"IEC 60927":"1I5U9pIDslxbGengKk5INQr8RU2l0budo",
		"IEC 60950-22":"1J0xOn9eNa0WswU_rlShncA65SciflWQG",
		"IEC 60950-1":"1PmkvNMr1TMEyFtW2K_dkREGkogZ5CQcd",
		"IEC 60917-2-2":"1WfOAyVZIWPyCcag7g2BMl6O-5-SnzH1w",
		"IEC 60923":"1YNL2G-SUjbmp8flfFkEot2jydC1qR8fr",
		"IEC 60934 (REDLINE + STANDARD)":"1Z8kGYtzLFMwTr8G96hOESxL5voyoFFWZ",
		"IEC 60949 AMD 1":"1aU8kK4Jwt5etDVRUDLqdKDZyORxDwC_r",
		"IEC 60950-21 CORR 1":"1fehfpRQ-WCABjIkLPONLxBlTRfyetJzP",
		"IEC 60946":"1gbRkX2MffTXBoEnI-1GorhXaHdF9pjMO",
		"IEC 60950-21":"1o3abK03fcJz7WfDVyrPcZX1zyd4nu9kh",
		"IEC 60942":"1r1BvJc-_iBDrNTG6Gocn_77-iNodn6ee",
		"IEC 60918":"1xNdTzKSe-WbTm8uPu-FhTcXhWFG8Wljj",
		"IEC 60947-5-4":"1yPJMZMJ9x_ENDRpi8YQDmc6nmF0ZHKv4",
		"IEC 60904-2":"11Rp1iNpVz-0eimBA8NO3uw83GVbFchEr",
		"IEC 60904-8":"12Kcbs9ylmRlXgtIj845sSnFmwGRcLcCv",
		"IEC 60904-9":"135pQlJGxEzx2M890GlRBjE0RHg53swCK",
		"IEC 60917-2-1":"150iNjzaIxRVOjvt307IgXUobocjzB7C8",
		"IEC 60908":"17Dn8pWGWo-3vG-NK1XHiAvV9UsyrSSDD",
		"IEC 60904-10":"1DGv0ZxJYQr3q34z7rkv-QDoI7zwX7QIK",
		"IEC 60904-5":"1FSOtSma0rcBWYw3IvjAC5SbgYOHkE04Q",
		"IEC 60904-7":"1FfnpBs7cTIbE9KiyA4lA7yHffqU7dlgQ",
		"IEC 60904-1":"1LX6tmtawnAgCE2OlfHpjuj_FtNjFs6Fp",
		"IEC 60917-1":"1bqAN8tsAWPdAl1dRkTuVFQk6y-IngfUi",
		"IEC 60906-2":"1eEwUyV5O48PeL0jK3roHqMAoZKdxxvik",
		"IEC 60906-1":"1hLH4PHJpbwdXg6bgvCVGLcd-gpbTn2Ck",
		"IEC 60901":"1mkf02-Dts0DoNaZDt_4yLwwIJkBAIIaR",
		"IEC 60903":"1nK50HThQaOyfx0fb4InK07IzlkJxMy7w",
		"IEC 60904-3":"1nbqvaKAjzeXsosN6d-SoUAijPXy7h-c2",
		"IEC 60906-3":"1oG5Q1-aOLoZ3DLr2jLuozuLwctwVaU8j",
		"IEC 60917-2":"1tYCLmY6mI8KiajrEQVrkoLTBFst5Mz_P",
		"IEC 60901 AMD 6":"1xAX9HFljmAFtGE_eqJvF2DcT6eJajAEh",
		"IEC 60901 AMD 4":"13X5CghuQ6rukAegDYCsAXkjNVRx1kDvP",
		"IEC 60900 (REDLINE + STANDARD)":"143XikB4bh9SJn1ls_rxRmTqcBf_4Vo7J",
		"IEC 60895 CORR 1":"173GHgO35I4EynlPlerhApOzJ6VJB9S4p",
		"IEC 60884-2-3":"1BjrpfQ9U90fcuSR2lT2fgNJqshDoOIdQ",
		"IEC 60884-2-6":"1FNVEMSelTlIEaknEdJmfTtwyxwWRDyfD",
		"IEC 60900 CORR 1":"1GL6mRHV7gp2SIeoWybyKtdkeXSsVEKOD",
		"IEC 60898-2":"1IFncC-ppycJFknUaGUbQ6y2bTJ6mzzZK",
		"IEC 60901 AMD 3":"1ROL3KfSz1sjUuS6YX2TkedrrG7qd_zCy",
		"IEC 60885-3":"1VDLVD0JAlVKCwAxE5Vlza2R2y4Y796Y_",
		"IEC 60900":"1WeIs74pHa3s7gUYkV9VzawAUSc5yKHiN",
		"IEC 60891":"1afL3VRlByumrhn_xyxbYDcs-ONxCjd0q",
		"IEC 60884-2-4":"1hH2W6aGDpqMrZcaCydSJF5DKC2-eXnDx",
		"IEC 60901 AMD 5":"1k7JywKsGsV13OgQBOa3CBsUA2YHIUlBo",
		"IEC 60884-2-5":"1nNIyyWRs2tWI_-I3WS3cjt9dJIrTSzZc",
		"IEC 60884-2-2":"1qSaE0faQ5o_Zze0EdyQGdiWzDZnQGocV",
		"IEC 60895":"1t4BJSYLonf4BmL1HtqV-_r0-yGjYzCeh",
		"IEC 60885-2":"1uRHfEV6ifHshYFwPYegvAWDVYTgBc_9E",
		"IEC 60898-1":"1wzC2TdSJ0Bc-UfosF7EKkE1I3sw2c3sb",
		"IEC 60874-14-1":"11f5NJUVg5JD4ZW35uu8WM7gRNntZwHYN",
		"IEC 60874-14-4":"19ROwcAC6D3kwdwZAsUzg8A8SgJIdA0dQ",
		"IEC 60879":"1B5wrXx8rjqZWmuWry0MpP2Zi88NeR8MB",
		"IEC 60870-6-802":"1CwI_99gpeNWi-fHQJJJKGOzrLD4pp2W-",
		"IEC 60870-6-702":"1F2e3BzbUb-nXxhb1Hjb44IZLLnXmNEQU",
		"IEC 60876-1":"1Fqh2vICbkBH0U11_sKxqF9F2iXgtKls8",
		"IEC 60874-14-10":"1IUAy4_S0CUnPmbDfCRLHJe_LyJro8bKW",
		"IEC 60874-14-5":"1KqjxwyWhu5YcIX4Zc41Xix54eLRZGJh1",
		"IEC 60874-19-3":"1LUsolknWYH40-LytoOACCq8YgPKN3t-R",
		"IEC 60873-1":"1Mft3gm4erTxH7PxKvLkGHmDJ6bDBpMV3",
		"IEC 60874-14-3":"1OKSF8mGsR03CJ-6fhRIrlpz63EwYvzUT",
		"IEC 60873-2":"1SU9bCF_igTDr__7C3lUx-7tVyPhDis2y",
		"IEC 60874-19-2":"1YDuJrH76P0vLCWepgw2iIkgxBNIB_l5B",
		"IEC 60884-1":"1a9hmAfjA2iNV55eAlYK-nhMfgiiCDWWQ",
		"IEC 60874-14-9":"1cyzm9bUiOAMKOZhpuG_4zzsFrjzTGEDP",
		"IEC 60883":"1wBkSeoLetHqjtmGbPX19u2Xhw7TofNaR",
		"IEC 60874-19-1":"1wJ7umSFyqOYtyhBufjYIJadybUa5H98o",
		"IEC 60874-14-7":"1zA7zvtGI3d6QmEicew6eLrzcSu7_9Vyt",
		"IEC 60884-1 AMD 2 CORR 1":"17Z-0v4lO9WOS1sEh1YpjnbAJ8PpDWsJH",
		"IEC 60884-2-1":"1WVPZPwomVw4WbRuInd3obO7ZbvO2V_-K",
		"IEC 60875-1":"1ZkNViGH_AZZU40clEfPIRgDLnKtdYVk5",
		"IEC 60874-1-1":"1dLzI6jsGmoHfzcPMEHwTh5B7CRRtVbYO",
		"IEC 60874-14-2":"1w4mGNFd9VhjjEvOgED60a5ad7ygXMztd",
		"IEC 60874-1":"1x1n8r_oEXwMl55fRrVi8aCUFLKq1pcdM",
		"IEC 60870-5-103":"10s7FbxALphprDn47aAXDwFH36bhAQaHK",
		"IEC 60870-5-102":"13MIpRQQbttWgKGHeakAmhSuREZLpt8BM",
		"IEC 60870-5-1":"14NjMqe23t7FJ5wWyAbfCM4qclvwAuS13",
		"IEC 60870-6-701":"1BUIrIRO7SWPkNFxlG6sohsGfnUoXYrPV",
		"IEC 60870-5-4":"1Bm8rTXKpJ8vSJP_PLh7g7QqfHEtM8X0K",
		"IEC 60870-5-2":"1C4lvq9DzT-wcA1P2ViRJ92BJq2GEyaHe",
		"IEC 60870-6-502":"1DnmMhxbwB3UBz2iPHE9YO8KMlli8hSg_",
		"IEC 60870-6-503":"1FBT2sGgl4Xh9J5rnVWJw-09GO_eeD9AM",
		"IEC 60870-5-5":"1L4Q6gXhQP7-y6Qrai0dCh-bn9hD5wwGU",
		"IEC 60870-6-501":"1SCYxs82nCDEULPHvHbHS6bXz0jlargE6",
		"IEC 60870-6-2":"1TsWLaOmfdK6HscM4lfQKwtS7iutvv7Wz",
		"IEC 60870-5-6":"1WytCDi0I22CU0_YD_EGcuhHq1P0JMbJv",
		"IEC 60870-6-601":"1YlqvlaKEUygVS2BvN5vwhGZFJoWYbOkJ",
		"IEC 60870-4":"1ZlLEmyEsQKHo1RYSn-s5ER6IlkZ3b-OU",
		"IEC 60870-5-104":"1cbnwpLvjxSx2hN0hPNyQp2M17AFWv8fY",
		"IEC 60870-5-101":"1hfa8v5U8beP1zysv1KgcZpkdrA5wPbVD",
		"IEC 60870-5-101 AMD 1":"1tHRQdAaJE2pA-SXJily2PUXDWGwKKRIL",
		"IEC 60870-5-3":"1uWSZ9g5umUBWoTJWVB1F3RXhMcSQXJIv",
		"IEC 60870-3":"1yMu8kBV-fQwvQPVFfif5GohNk7-9EXaZ",
		"IEC 60862-3":"10cidXw3OIGqPHgGWD7dgebkHgrwiDG1S",
		"IEC 60856":"11J2PTp-hlHA5-edJgmpmXOEIhzA5Fvlh",
		"IEC 60862-1":"11LnnA9SaAnh4wLS7e1S4cUV1B_XtNJO1",
		"IEC 60853-1":"12tP8pDp2AuocTmpjxaJjDpG7wejAuvp_",
		"IEC 60869-1":"15Dz6AHd-07I_BWPst3y_rAgIlh-vZ_nT",
		"IEC 60853-2 AMD 1":"19tU0jO73071h_crwaxcJXXqA-FUe8wzi",
		"IEC 60853-1 AMD 2":"1AJRUIRrtmB0rGpcLIQPklP9QZChJLmBv",
		"IEC 60864-1":"1BJOG71i8QlKYG5uQpHOpFfb5qFPNNXEN",
		"IEC 60870-2-2":"1C41ZAIzALDPgmW-IQ1K4BcuIQ-P2umsE",
		"IEC 60862-2":"1DkyQVBmgGA9PC6Mvyxs6W-KMRMM1WtN7",
		"IEC 60870-1-2":"1E6Lw2xwNXBKQ4Bh8GbybAmHQGjrs5RNb",
		"IEC 60853-2":"1FYqHpIgYDrwvx0hKJsdQV-SqSfDxIgBG",
		"IEC 60853-3":"1GYuD0Pg0GSmJ-SspIj9c_1FBxVAg-E2s",
		"IEC 60851-6":"1J-_sVe4h9dKHuAvB7UmDYdnHryD7UVhA",
		"IEC 60855-1":"1UQiBw8EQ4d6pbt0vN9pnh99TXq8iYIp3",
		"IEC 60870-2-1":"1_U16ItUweiiVU5PxyVs67bFNmuNzINwb",
		"IEC 60864-2":"1bGwQ8SGiWC4Z9u-xRfuhlHaI8eOGRaJg",
		"IEC 60853-1 AMD 1":"1kfB50-Sf8JM9abal5HqDbYN4yagQXas2",
		"IEC 60857":"1nEO0Bv7KlG76V-eHwytsMaJedqkH6m_t",
		"IEC 60851-1":"121eqnFSBnNGr78_hBPPimsWsy3OPTgDY",
		"IEC 60840 (REDLINE + STANDARD)":"17vRi39X1FZTh5Y0cRp9MdFrDsD8JfRjQ",
		"IEC 60835-3-6":"1DiDRxfJCedkpmQ4ZxczO72vR5mvW0otG",
		"IEC 60851-4":"1K8fDZTe8Tfdzf5gwcmjyNPcHZ7DzV5EJ",
		"IEC 60838-2-1":"1KEALPiAoJoUGQSjp4qPpV0cd1pfGQoSi",
		"IEC 60851-2":"1PvC2NkKGYR34uoJzezcyOlW61qiGPfGk",
		"IEC 60835-3-9":"1QyD91W36MlkJ3GJoDC9meT1928InM0kC",
		"IEC 60841":"1RonVZ5eBt7ckPFWvFcju2qebN6ecAvyE",
		"IEC 60851-3":"1VRYEg-iivnIIzssW-tYgM5pit4sr3J6D",
		"IEC 60835-3-7":"1_zR6f7Ze_Xpk66j3DDNzKRMH0_QEGjM4",
		"IEC 60835-3-5":"1asq_U7YfpKoGngjBhkK3qeItMkGog1dp",
		"IEC 60848":"1bM5wNhtfR6fo6CDafRL2i1J3WnXlybS2",
		"IEC 60843-4":"1bXdmAH8FXY5mXgxc-pXFHUH1-yhWuLF2",
		"IEC 60843-2":"1buCSElBaxfjiuVIII3t9KDTVt8y4zs9M",
		"IEC 60840":"1fIF0INDqJi58Dl0ikCQD_ILfssTo6IJE",
		"IEC 60838-1":"1g0mdk-sAKz5rVU2wyShGcuSFYGIeU6BK",
		"IEC 60843-1":"1iFCvCcbbeIW2XJpu4vX6ZWbRj7vYlWlX",
		"IEC 60843-3":"1i_4eZAL9pY7_zHP4tdtJ3MytotEqcO5J",
		"IEC 60838-2-1 AMD 2 CORR 1":"1oQgWVY_7zzWvays54rVc30Vuh3xBLyQS",
		"IEC 60851-5":"1wP9Cc0lwmsXk2cz39EC_YnmsmjsAx-bi",
		"IEC 60835-3-1":"11bACKG_pW_DEpde8PJVhmpadsplkwjut",
		"IEC 60835-2-5":"13NXuldJYZD0X7Q-7wjlMfveWgxrBeUcl",
		"IEC 60834-2":"170KiAQsw6zH5uz5-829pROB4izSl3ECu",
		"IEC 60835-2-6":"17l2IC8SXBBLgYT3RHzzjhR2pCjksBD7F",
		"IEC 60835-3-11":"1C0a04TRVd8akakEi83jbowBvf3TbyS3G",
		"IEC 60835-1-3":"1FIZvDGhg7ayGiPD5_M2wzBXRexSmcECc",
		"IEC 60835-2-8":"1FsIygeHYXzI6Ha9oJMLT7UJV9qyfpygz",
		"IEC 60835-3-4":"1HJfGV9JgfONBM6i5v_mmxe-QzG9-B7Bm",
		"IEC 60835-2-11":"1I0eP2POfu3LgfkjtG_ERNTCYk1Mcst84",
		"IEC 60835-1-2":"1KXU4f-U6GWNqa7aGwJL-sYFgf5nx4iCn",
		"IEC 60835-2-7":"1SSObYvX9ieJJzGVgv9yR2mrCdQ8ubZ3T",
		"IEC 60835-2-9":"1Z5ZL4Jiqew5PYb3BuYqUdT69-zLu6J_Z",
		"IEC 60834-1":"1_ZmPGOV5l9uW9NzJf9Qu2sL5-ds3yim0",
		"IEC 60835-3-10":"1abP9bxUx3we_RCXC0ZKgKvXLNp9WhwZX",
		"IEC 60835-2-10":"1cB42hHGgsI9y5-RUh-k4vWzsiu3A3nQO",
		"IEC 60835-2-3":"1e8MwKMbrX62vCGDvnPN73r2bjCxu8ukS",
		"IEC 60835-3-12":"1eAEKHMIaCcUOwN1ExfPMEAXHdgUZG2OS",
		"IEC 60835-2-4":"1gGSC-Uywul_zM7hfpd5i1MttG-fQIeHY",
		"IEC 60835-3-13":"1gqceXxNqJG3NMQUq_Bh852bLWpwhXtml",
		"IEC 60835-2-1":"1i1QndPN4s_3Cci3sIx--lQ4Do3Otsd2j",
		"IEC 60835-2-2":"1nHw3NZVgeXzsj0JHZiTmlUPdjPG8GsyG",
		"IEC 60835-3-2":"1p5_SuSSTQMPAfjhanj85OR1ykwIxfUOq",
		"IEC 60835-1-1":"1pPvz9n4HORzqO9Tz9rkhqt6NxWUnDC5_",
		"IEC 60835-3-14":"1t_Ade605aKHHA3Dz64WpAOAVLOdnpLQh",
		"IEC 60835-1-4":"1zQucrZDRIgzGedK0uTKbc51vstSbP4Ik",
		"IEC 60809":"10eO6Sdc0lmFhy4B-_qaHUvsPQgxGLnAk",
		"IEC 60825-2 INT 2":"1BT30U9CyFWnyBQyADcuzKAa_7gukb5ew",
		"IEC 60812":"1DAiTwRD5AOZVuOBGRW4K_wk-jfVfuIee",
		"IEC 60825-12":"1HKhDfdo2_lrep-dNhoHaK5441YtXKY-t",
		"IEC 60810":"1KIAbNQK38YwGKr7clBvyem1cTC_F7WgQ",
		"IEC 60825-2":"1XtREW13zbN75M4AZT22EgEvOHSf5xzoE",
		"IEC 60807-7":"1dKRpiU_hfdn1ax0JtcWNvNMSTIpXTfhr",
		"IEC 60832-2":"1w-UxK19KInADbKYF1lgwkwqrS4_sXojY",
		"IEC 60832-1":"1xZtAo1JKy8Fu5h1iIl7at4g-8Fc5s84h",
		"IEC 60807-3":"1xjbORWiEuFLeJ7BMPJsdAAczCz1m-KK-",
		"IEC 60825-1":"1z1Rd3hwUzyaokDZTOEAHpG55l9V7375D",
		"IEC 60807-9":"1HPA34t__ChmB29Gr0CyybaKF7D1G3RSK",
		"IEC 60825-4":"1QS2vb5jdyxy666Doj3cl__bd0wcoudPh",
		"IEC 60807-8":"1Y49j021dP3HOvdzHSQu2ZNEDELK4r7Lg",
		"IEC 60807-6":"1nus1vgDvS-QV5PA5hOBK61NPHXtaQwVR",
		"IEC 60799":"1-C17uDlsePjTGjl8x46WZrfzVJ3Q0QlH",
		"IEC 60794-3":"104dQTQ80Shwg-SEEG9I8ThC2p1mVFOO1",
		"IEC 60794-2-31":"17QWzlLMMBBIctFSq6LGvhKLK-fcouHvi",
		"IEC 60803":"17ogRHl-ZcB7cD_S5monJVijOh_ay7fD5",
		"IEC 60805":"19KYO2P7vs_1OKKFlBrTwEVQrIl_oePVF",
		"IEC 60794-3-10":"1M199C1eDZ-o-vUZMl9tLUvJFQytL_z3_",
		"IEC 60794-3-20":"1MUarHIw6sCW53DXKx-4_u4FIbnEnT34T",
		"IEC 60799 (REDLINE + STANDARD)":"1OuWJWadAKc01qB1S2y5gJ4QWYOi6AhtF",
		"IEC 60794-3-12":"1Q4ciSSV_qWIlIJs6WNaa4PCpW0JbAEsR",
		"IEC 60794-2-40":"1QJ1VrfjlJGKorPHVYd9AIwAiB3i00vfZ",
		"IEC 60800":"1TptUJVyY0aZnvGc_GoMpcLvkgXxOhR7W",
		"IEC 60794-2-31 (REDLINE + STANDARD)":"1YgR9NE08R-bRGd8RwVRzsp2_cUSerhCG",
		"IEC 60794-4":"1_RZh1JI13DYWkuSz_QH8taogmn2VcG1E",
		"IEC 60794-3-21":"1cT67IT-5tFYr_sJC_EeVTOKAjwezk-_d",
		"IEC 60794-3-30":"1d9llHh-jhCpwUJPbm2uaX3SYkh91A_Ol",
		"IEC 60807-1":"1hhQOcokmi1wn2s5iV_LJ1YpSq4ELnSnq",
		"IEC 60807-2":"1j8H4IhelWTWyEoEFBhUGZSvQfRyKzYLO",
		"IEC 60807-2 AMD 1":"1jeMFlTamI0nufelDbw58qzgZeUA8Rz9w",
		"IEC 60800 CORR 1":"1owIPQIznVOmr7N2h4s-nty7YS_FkCLQK",
		"IEC 60794-2-21 (REDLINE + STANDARD)":"1-rm-oUttsj0i1RC9OMQoBO7fAc9f0seM",
		"IEC 60793-2-50 (REDLINE + STANDARD)":"10Kqj8go8AQplFyOtK8qFakIu0QvI9cs4",
		"IEC 60793-2-40":"150nnmdVDHtzf4kac7TmfcFeIxndJe2jq",
		"IEC 60794-2-10":"19Dnm242UNroTl5KsheYdKZltAD0gNfm5",
		"IEC 60794-2-11 (REDLINE + STANDARD)":"19Z6CicjPM4T_wE1zD6Ib1qvNf09PYEv8",
		"IEC 60794-2":"1Ng2cwMedwIMr7wOw2IewQq22Hq1D7Nok",
		"IEC 60793-2-50":"1TmwoYO3-_3xXu7BM2IgtasUia3U1kBg9",
		"IEC 60794-2-21":"1TvDPIaSt7KmJ1XUsVOwo00ibmkV4ferk",
		"IEC 60794-1-1":"1U6zownCgAqOsxrPqtaM35PW4P1on66lz",
		"IEC 60793-2-10":"1Zk0F3W2qLQMXzv3K91HW4DDwCiYde1hC",
		"IEC 60793-2-30":"1_yBw7cUK33F0b3vd1fVAX_EswY1M49nc",
		"IEC 60794-1-2":"1ee5-Q376U4JjNeeCGmZ4Vtemj1Yz3LdU",
		"IEC 60794-2-30":"1hCE559I-08Pt4MdztVVr-4mouMdrkvUu",
		"IEC 60793-2-20":"1kIjZG_Ja8fdfaVW0kDEpxkBuUr7ktQ6J",
		"IEC 60794-2-30 (REDLINE + STANDARD)":"1s6ZVVQFZkjW6J5misMjplimTZ-ADZUmY",
		"IEC 60794-2-11":"1sxOzOisp7O1cwtPXE-SN8AxilNjfKCYw",
		"IEC 60794-2-20":"1u0w9et5BVdBx4D-h9Pl6IW_Kgau1kBUB",
		"IEC 60793-1-48":"112SW-WsI-AMx7rs9-x06jvX8aoNmWr_E",
		"IEC 60793-2":"13NjzzFEEL3ca-Pkg-BACvHCm2Lz_TXN5",
		"IEC 60793-1-47":"16avlcMnL_AdSYed3a1LS15PPNXB8B51B",
		"IEC 60793-1-54 (REDLINE + STANDARD)":"17vOXGvAbSwqf7fJ6PGSc3cRvUX8cznzq",
		"IEC 60793-1-47 (REDLINE + STANDARD)":"19_uS6S0g--hIhsqCWEezC_fA48fYQqVa",
		"IEC 60793-1-44":"1EMVh3L0aVtlp8-nQd8WRaA2nLRm0Nk5G",
		"IEC 60793-1-50":"1K3pBbTr4JjgfhsxNwden7oWj_eQCBtnn",
		"IEC 60793-1-52":"1Nbt1e5CgN34N_yvlJZSGRxsoSdvmr_Jl",
		"IEC 60793-1-53":"1R9hqeLIQDUGtV3i0U0AwG6G5kW-Vxj53",
		"IEC 60793-1-46":"1abKCP80MG_GEgwnJV5KcIdSRTL2WhJDX",
		"IEC 60793-1-51":"1b9o62PwjWWdrJqIdnBSp62dYVq61MgBG",
		"IEC 60793-1-45":"1fssa04Zn1vZvN0KOl53WkJDaGDAwGniR",
		"IEC 60793-1-49":"1heNy6nCpwq1Tazx7onaEsm7PBHcm0BTw",
		"IEC 60793-2-10 (REDLINE + STANDARD)":"1qcKDIXrqMhq4nWlpXbh_DMnOFyjqi3-U",
		"IEC 60793-1-54":"1rW-dRsUg9Mu-uMMOZEvs5E31l6KrRoyN",
		"IEC 60793-1-43":"1w0S9bOZOrQHykHIjBOibxVn9FJMPVGx3",
		"IEC 60793-1-32 (REDLINE + STANDARD)":"10IBtco_vMZxPg5xnOd6VDfDQ_ARPi8fd",
		"IEC 60793-1-41":"12uw8MQtU1eGPIBQvEkXcGr6Ixnl-fFxS",
		"IEC 60793-1-30":"15FQ5e9xh3wRePBBUZkcfk90CRcjAp_YI",
		"IEC 60774-5":"17WtBKAbXw20eTx_SRY1xKGOt3yxcnWV-",
		"IEC 60793-1-1":"1FngLSF-5d6TssXJDEAXwuFWJls5XFFjT",
		"IEC 60793-1-21":"1HvF6zAYgFpBFmzHyAK5soDxDstlglQSm",
		"IEC 60793-1-22":"1IybVtGYq5GVuShfUEYs1MG0Bx5ouLZhp",
		"IEC 60793-1-33":"1P67jI3txaPwrsoV53Fs-8jWYYfhiGlkE",
		"IEC 60793-1-42":"1T8cL-VcfVgsQ3fnMCFaf8sBz2qfMT24G",
		"IEC 60793-1-32":"1TJe9mQB2Q-KH40pllS4MIuPDQHtePl9m",
		"IEC 60774-3":"1TspBG-WXInDA_VXvwcHTFjK8zxaQzNQl",
		"IEC 60774-4":"1YrVVnZzWRAUr1SZhRlB947ToUzeAMRWd",
		"IEC 60793-1-34":"1eIhEOkadKtF_uJKJ8O7areeueB0x5irn",
		"IEC 60793-1-31":"1hoIDWf0H1fduGEUpzxw69kBgzG5JbHyV",
		"IEC 60793-1-40":"1t3XHASQot5SXn4AhBj3YclYZtLfhJfjh",
		"IEC 60793-1-1 (REDLINE + STANDARD)":"1v4GwRM-VcVth5JEPdB9ysZb5n6KWOYlc",
		"IEC 60793-1-20":"1xwZMJ8RSXURT6TtZHRI0kGtgHWjSUDAL",
		"IEC 60793-1-20 CORR 1":"1y6eBacHteiBFdCYPVejSgMOQ2O9s7Nmf",
		"IEC 60749-5":"1-vwLUP9jfHLfrtxEcV9rIfmwMboHcVaP",
		"IEC 60749-8 CORR 1":"1GEPqNs4zRvWwQs2NDGnZEW3jjvHwIxwA",
		"IEC 60774-2":"1JiyU4nzvhRlTXTM-ksOEYHYgs1aocuWt",
		"IEC 60749-8 CORR 2":"1LxRTicYSOw13zqxFO6Cmrm9lArRyq1nS",
		"IEC 60749-9":"1MRZwt9696NkI_FByfPgrCdUjqcwoa00X",
		"IEC 60770-2":"1PXruhw9DNdsYoJkh8kcBEJezU-yxWis5",
		"IEC 60770-1":"1RQ1Hw0-jA1UiSgaZ_iLnaPcuIPl_KRd9",
		"IEC 60749-8":"1URN5-8PYV-mFxCrKLa5PMhVo_VONdCnD",
		"IEC 60774-1":"1ZTkBpDw68w3wgHBXB-xkOi3pnAwNHJ2m",
		"IEC 60767":"1eT6H68rbW2R71IZT18faUfo9s7BuCVRD",
		"IEC 60749-6":"1fGYeP78wJ_RKLCsBJamsqv61swST1a_w",
		"IEC 60756":"1n4eWn7DNQF_qmY_0RlUF6G0EaJc19JqG",
		"IEC 60754-2":"1nxM-rqCpOya8IJ_CFxYJPFoAsRBHtzZr",
		"IEC 60773":"1q91i7hZMmupJ3YAfAM1mqxY49sykCBEn",
		"IEC 60754-1":"1q_IyEoqndSSLLeLA-JWP9a1ra7f5hlGa",
		"IEC 60757":"1sGs1FJ2-eOnh3e9CT2qOM745jZPoUx26",
		"IEC 60755":"1uLu53jG_ERV5WQMhaA2a_4P0iPyKO-Lf",
		"IEC 60751":"1vqcJerc8Oz2HZy78Ktgj8wxsYtFvKbLL",
		"IEC 60758":"1znfAJ4W-APVvwXK9hKgZbBzGanb2HIUh",
		"IEC 60749-7":"1zzVCuCK7nlHjauvxcZ9wRp0yYHMp24TD",
		"IEC 60749-22 CORR 1":"1UOf7fvJswdm6R5zxif3Pongad57rOcj6",
		"IEC 60749-20":"1gTPiWenyKi0DoPwRnY3aVNLJo0RNC_CJ",
		"IEC 60749-2 CORR 1":"1ivz75j3tPZ96uB4R7rDx9MCpehwzMWxL",
		"IEC 60749-36":"1nWuPSNcEsWuGcSbXihgniPLJTEoekJrz",
		"IEC 60749-3":"1sJmX8tWXspFaBR1RTstogw1sBLcZT5_C",
		"IEC 60749-31":"1vSxRXNS2-dIGOGn88LnqwqPDeT67T00o",
		"IEC 60749-22":"12Vqrxa_Lya4o5XnQzcrzUWAoqDBXFSS6",
		"IEC 60749-29":"1CdH-1gnwdOa9KB1KL4LMY8cTdthVP1ZQ",
		"IEC 60749-31 CORR 1":"1N9jl3o2CWDtYHb7_0SEou8Or8i1ZAAHP",
		"IEC 60749-24":"1NDyZMg-6oJambk7s2xCX5PImveOMz0qa",
		"IEC 60749-30":"1SizqC2d1hWzRlpEL-dV1DaZTg5nMCbAn",
		"IEC 60749-33":"1TXMHtOw41ZC_RG4aabEQp-GaqlXosK7r",
		"IEC 60749-18":"1Ux9IDBATEHrIIehRan_s8eQMsOkDRx9u",
		"IEC 60749-27":"1XeqbjD9EXcVLDu4NWPh3DlOLovGEI6DT",
		"IEC 60749-19":"1YVd6JwKYenfYrQ-1qDYYFfadzh3k5_Bi",
		"IEC 60749-26":"1aBo61aDhlVAYMMaw2_ruvwYKFF5nJ_Wz",
		"IEC 60749-34":"1dECyg-N4Q2Iv2paZEogYlV_R8RwyCWw3",
		"IEC 60749-4":"1hqTlsi8A0TBtnREnl6gD2yYk9rfi7OG2",
		"IEC 60749-23":"1lO3cX-7rj8Lf-e_-Je8oq7haivpOkWOS",
		"IEC 60749-25":"1lSjy7Le6BEiBsNEEIkioBIM4tLnsj9OY",
		"IEC 60749-2":"1u3UVRq92BUpRuZYe3jY8X2rTOoe4ek0S",
		"IEC 60749-32":"1xPJUaLrN7jxgGDw5aiK_Xt1VmL1VAWOE",
		"IEC 60749-21":"1xc2CNAnNfF27gWBMIUkSv_cyg15ervb4",
		"IEC 60748-3-1":"1-vJ0ZyCYq6quwAJnRw26CpJvqhBEVK-4",
		"IEC 60749-10 CORR 1":"11n_xFqKo9AR7H327hPvSdZnQ6Yh126-T",
		"IEC 60748-4-2":"13nveh2-8eB0QUplmkJ-fUjbPbq6mfmcH",
		"IEC 60748-3":"14FjjY3xvst53U0ly5YqzjZX0twJUUVsZ",
		"IEC 60749-13":"17lj0cvCQUFCyEo1T5h25YqbV1faJk0YB",
		"IEC 60749-11 CORR 1":"19ewST7U-zoUJmxLy2dxP2WYCEngTS0Jk",
		"IEC 60749-16":"1E7trolvDEXV7bwb70ciA4rjJ1Hjj5Y1G",
		"IEC 60749-1":"1J9ZbSuO0eFX14cZw0yxTaXyjXOgedSDA",
		"IEC 60749-14":"1JsD9I6qrUMm1fANF9HpkdvEmx71NHiyo",
		"IEC 60749-12":"1Mm21tnKIjxwq5gZ5Zy7fZ_FiCWNtNAi5",
		"IEC 60748-2-9":"1TSuOLV229Hw2sBBZ3YQDP39RfZh2-OCS",
		"IEC 60749-15":"1WLoQ7DuJTLMH1CUPxWXaczliSc4HyCUd",
		"IEC 60748-4":"1WsvbuhApCYS5MR2-fk4Aj7rEh9CRt4Ue",
		"IEC 60748-5":"1c7zkE6h2zEUqMSWBuNxzj-wXkLE3Op0s",
		"IEC 60748-4-1":"1dL2rf4oC-O1Pzj7paE88g9YZwF3qbL-U",
		"IEC 60749-10":"1hBB-SHbPW-pJoMOZ_vjl7r6Z6GLKZfrU",
		"IEC 60748-2-8":"1lhSDuhJ-lHQYHmWdECnVR68w0p70iwxb",
		"IEC 60749-1 CORR 1":"1oFR3ku9qTrSFdXDeKMGmbELXC743LmqB",
		"IEC 60749-11":"1uKeu-GqqApFVARs52_2-PwRDUh42d6Ha",
		"IEC 60749-11 CORR 2":"1wv5a0UVuz0Iy93x8mLzxO5TbuYOajlwL",
		"IEC 60749-17":"1xlRnH8tn0t5v7cJxld8Xq5_xsLQs5QmH",
		"IEC 60748-2-4":"10gsxi_PRWd48U9pQqAyVyEL4B3HpCB8e",
		"IEC 60748-2":"120ZngYHOJRSvEXN3gCPMxO6_tBuUd6Ac",
		"IEC 60748-2-5":"1422i6Gu0z2A5AvnhHXOs91lvpFzGNIUj",
		"IEC 60748-2-12":"14jRqImYDs24NB0ARGwnaD6TR3qfCN09q",
		"IEC 60748-2-20":"1Aeat25uaCPjF2wiND_6W-b2LhFVn8YI_",
		"IEC 60748-22-1":"1M8mkluK5tTpIwKRCTN6QMttTwHy4cQxD",
		"IEC 60748-2-6":"1NJ9Y9-B0D-AOcWanxGZ_BCborgVzdJ10",
		"IEC 60748-23-2":"1Nw59nV5WC0xHTGLZ69nJnfKmpmGnpN3-",
		"IEC 60748-23-5":"1O1OGUdbpQzFWMbhsjYUiip6_PhCqW6G_",
		"IEC 60748-20-1":"1OzOC1lS5xPcV7bpOFa9IV4OA-1WGE1qQ",
		"IEC 60748-22":"1Ss4E6dbJ1fEibwXilUx5e6SPoDO9RPux",
		"IEC 60748-23-4":"1WFiHbbzG_QGZIukDeQ9H1YdVAl7TrGmp",
		"IEC 60748-23-3":"1YM7YBDbAFrNkqTGEEzWlxjMANshl4iSi",
		"IEC 60748-21-1":"1_kNGDB3So0_wsrhgOS4MuhgWwmDmvIS1",
		"IEC 60748-20":"1cQW1hbZ7u_dIRAKVQ1vwpkWOgHflxuss",
		"IEC 60748-21":"1ekpVLJH8tH1KV1PEieltYIi8ENJ9tbvF",
		"IEC 60748-2-2":"1eqMpeSyQvQXn3UX8gkDR3wHOIb8l-W0v",
		"IEC 60748-2-3":"1iUOIIoa7zveu8PQQLQRl8CC-xlqUlq5q",
		"IEC 60748-2-11":"1nV2xbMAQ8YJVQq0WEksiT-Kjo7PN4g0q",
		"IEC 60748-23-1":"1tUCdSomYOInnY8ua4riO45usWqVp4Bvi",
		"IEC 60748-2-10":"1tiFecyC7bJpgpfPBpICjfebHZ7EjVsyV",
		"IEC 60748-2-1":"1tsGgy71LNRuy_Y9ZiRkt0KqicrPlxsBL",
		"IEC 60748-2-7":"1yJCuLGf5k8mCZNHrvwvY0BLAFaXOkYcj",
		"IEC 60747-5-3":"1-nvpXPDdK1LBI3yi2bitUv-WsCyR3jme",
		"IEC 60748-11-1":"16I5Ub2irjJ2FJJ6hMVB-yu1a1tCWqDvD",
		"IEC 60747-2":"17kWRm6uYcy7CXw6aYNjU8NQiksItAccm",
		"IEC 60747-5-2":"18Y5BkWQcJJOR7ibA8d0ps8L22DiNCydl",
		"IEC 60748-1":"19o1ljBevvQ3I3Ur47uh4sDm9riMosUvQ",
		"IEC 60747-4":"1GXdvh_akPvDuDAI8tjOGgALwowyH1K7g",
		"IEC 60747-16-2":"1P9vtHs3OvL6_KfoIKw0axx5zC0cREd2j",
		"IEC 60747-3":"1Sku2KXr9f1A4sblz_VW-D3reIxDTda_T",
		"IEC 60747-8":"1WBUSboG0h5RHAnSRMkKQlZc6FDi0Z3LF",
		"IEC 60747-16-10":"1afe6QMa2VX80O-7Rj_dYJnBDSEmG1ATf",
		"IEC 60747-9":"1ePyvH9twXFfHSqjIUc6cULN_7qO-npqp",
		"IEC 60747-16-4":"1fLSIbC06Nl7Puxrvz3UQ-S4X_nD4BuIC",
		"IEC 60747-6":"1gwU7wxqGbWJSqkiMf8aRHm3kd8lni1u7",
		"IEC 60748-11":"1wEgqWLaAjvL6sD6MPFFeoqpmt9NU3NVY",
		"IEC 60747-16-3":"1x8nX5VW7G5cggM-_-fFoNHIPh4soupMC",
		"IEC 60747-5-1":"1xKNLou1wPS-qY7M8hSVeRMH1F4VjBOWW",
		"IEC 60747-7":"1xZHIICGWYydHmASWtqBTqprIdjqg1rY8",
		"IEC 60747-16-1":"10dhzqX8NHl3CvJWY3zmJlKVgmBf2ii8a",
		"IEC 60735":"15SH_fC8FaDDiH2TqNXIOVn_6akGUE8If",
		"IEC 60747-14-1":"1A5NrWmijUFUbfrcbyPvwoJuo72OBioVb",
		"IEC 60746-1":"1B8kXYWKaMrdfTsFMmx5SqWa3Jbftz_rk",
		"IEC 60747-15":"1Bx45akMimVhbvbddUYlRtBYfpEyn8Rp8",
		"IEC 60743":"1DZor9nCBiXHrYoo89uR9nDqWnAdl0iQJ",
		"IEC 60730-2-9":"1GXynz4xSMNwRam_BXVSPDBgh5An4uem5",
		"IEC 60747-14-2":"1MVVX2o_t2Hq5ZwmB5xpuNSQSPQ-fruUW",
		"IEC 60746-3":"1NO09C8RYqIDA1YVY-WzFSOMLzlCaNnx_",
		"IEC 60746-2 CORR 2":"1OqQfY-BLQIt7YUicN58Qw2mbqqqBCDkR",
		"IEC 60730-2-9 AMD 1":"1f2YcZ3rdLd-nnv5dH9wd--CKuim_Y-XO",
		"IEC 60734":"1hfxTjt7D_9_9JeNXGyHDpzwnK79-d9jJ",
		"IEC 60746-2 CORR 1":"1igfIGWnZRibkizg-72hham5yjB1-GoaT",
		"IEC 60746-3 CORR 1":"1rsJI7_nDMijL8OZJKSFm55i2BVWAhBr7",
		"IEC 60746-5":"1t_0ZMVFfsIaaBe4UyEQkv_6cPHhcjW43",
		"IEC 60747-1":"1uGKI2M9OGmk3IZjkPeKvgIXILV9S1kOx",
		"IEC 60746-2":"1ucRpvqcXBoL630mCloBp_Zz3lsPfKYkV",
		"IEC 60747-14-3":"1wYedg7All6zjRVu8npIbrUIUiN7-ovxj",
		"IEC 60746-4":"1xRpnbStlCvlL1ubiMJ-65EtvsdGqBSk5",
		"IEC 60730-2-13":"12inBradhR9PH9FzLuG08E3OcwNFDRwxU",
		"IEC 60730-2-11":"1B654Xd_65LmzVAgGCV-JUpbXLfuTWLZ1",
		"IEC 60728-7-3":"1PLSFmga0A6SAcgokww3eJ8zz0djt7D9X",
		"IEC 60730-2-8":"12Q-Inur07NGZd2RxCxbyiwBJjA_TXusX",
		"IEC 60730-2-14 AMD 1":"12bpardYgexgLMo2IfDOaDtfpQnVDMmaf",
		"IEC 60730-2-19":"13aIPZ10j0HTKWCoZ5zE2XgmbQXKwSfj-",
		"IEC 60730-2-6":"1Dgy3LFGvmiYN_QdoAppLTCs-UT_AXdzt",
		"IEC 60728-9":"1F4Nt8GuMGlRlmxtU8cYJ1OyIGyQgNULJ",
		"IEC 60730-2-5":"1FoE00GJBkrZOXk23A7A3jUm3gh8hKS9v",
		"IEC 60730-2-12":"1Tl_RTOD8cWV_Ve6meGaGqWTVZudUPwZj",
		"IEC 60730-2-13 CORR 1":"1VJP3_MQwkH_KOnyFv3iQkqUrTv4RDODG",
		"IEC 60728-7-2":"1_Zj-jf4AIRD4FNMC6780d6phSlmJWXYu",
		"IEC 60730-2-7":"1fysz1le7BYBdGveqNFEIojO5c8AvUhsy",
		"IEC 60730-2-3":"1kmmc3HoLx_T00vVGhHLvOAwdEkno3y7r",
		"IEC 60730-2-14":"1y-hFuBptnOKLr13aS-yrAg7LfcgEo2NN",
		"IEC 60730-2-15":"1y0MkLyFFtFr2s7jbAt8Pp-LakylEVtlm",
		"IEC 60730-1":"1yZ-EpEEDNmTYoKG8XyJPxvMWqVthf9m1",
		"IEC 60730-2-10":"1zC3wGNRQEFaBInVPlfKZxE6nsOxzuN6c",
		"IEC 60721-3-5":"1-UAKWEcpgmfS6ldUnlk9rrmKrSQ22Hq2",
		"IEC 60728-6":"1-z8oL4gbmW-pD9DhoKt1OmFKHR7ghLVj",
		"IEC 60728-5":"13Qp-8XrgxPT60piQ1OqQnG4YN_rCL0W8",
		"IEC 60724":"16k5ay1kThLqZZDlnhkfxCimn1xNlb28k",
		"IEC 60721-3-9":"17be5ShujODaQtbwa0eCp1E7zkzH8y87g",
		"IEC 60728-2":"1DfiUocj-Fqw7KYPtvZOujfwyqhzvBUIc",
		"IEC 60721-3-6":"1PLyXVQ9CSiJoMsyCjHFdajbwOTuK596k",
		"IEC 60728-4":"1PlRY3irKC376na2c16M8Ntx38yC26jfS",
		"IEC 60728-11":"1QlJJXvhnQJMRyGQQaLsxVAeuuw6gofBY",
		"IEC 60728-11 CORR 1":"1U_cf9KYng-HYLZHoQGehD_CHSuRY6kZk",
		"IEC 60721-3-7":"1WuVlLdC2Tp-q8CowDI_uMGjddsWY-eiV",
		"IEC 60728-12":"1bi8sMQbQ9vi0UTmWSvqF_z90Y2kosCym",
		"IEC 60721-3-3":"1i3WNTeGKTI9llzekLd9AJ-RdDg1TSog9",
		"IEC 60721-3-9 CORR 1":"1jHN9dp-syIyxxkoInGzGOw85-crZxeFi",
		"IEC 60721-3-4":"1kEGuPArkDbDB2qt4Y6b_UQTVA47xdxAk",
		"IEC 60728-1":"1ni_q0O7H8anaN79Nzbi8a__MKv0fFoJy",
		"IEC 60728-10":"1ocfcPEz4etp27otC-Cmjz5RSABJ7M8nV",
		"IEC 60721-3-9 AMD 1":"1plupOkvN692IwmGlBMvsTz5kLV4XsUru",
		"IEC 60721-3-6 AMD 2":"1rN6st5PVORvL2ZyGMHhP1tQg4LJ5IKYM",
		"IEC 60721-3-6 AMD 1":"1sgjcm_hpFsPTi89axOkwlUVo48Ze90hu",
		"IEC 60728-3":"1tENWsQ83SLleCRv3Ns9ZJmcQ1gdY_72_",
		"IEC 60728-7-1":"1tTGXNsjDfyzuU35k2UZxA3we8MPqIOdw",
		"IEC 60721-2-6":"1-GG2LtNSKy5jb_tz7O6A0OgqTnp4nal_",
		"IEC 60721-3-2":"13LafYcRlurFYnLhijmxki4ZH7erjPzaP",
		"IEC 60721-2-3":"14xlBT5RoszM-AF5st4RV9n5-KNuufokt",
		"IEC 60721-2-2":"16y5IxEfMJzZ5e5y8GwXGrqNHZQ9IiPgw",
		"IEC 60721-3-1":"18zQ1Kh8-bdKf6D5v7Jct2DEglBbK_22j",
		"IEC 60706-5":"1Lf3VsbeKaoBKZPgc6-CP9VPTJGiTnGq5",
		"IEC 60721-3-2 CORR 1":"1MQms1-zp1fqoSsJhTH2U_9FcJAhsAZep",
		"IEC 60721-2-5":"1Mxl5ueKNMm_QxhzI2ExWCvUIVjVJKKIr",
		"IEC 60712":"1PSLh3xiwPIaVwH4Syx8FXH3-ypy7O4Nt",
		"IEC 60721-2-4":"1QHJO6va6Ra7hzKXg6NRjPfkkaiD057qJ",
		"IEC 60708 CORR 1":"1Z9eImlh0vCWAhl-hAy8h8Dyud-KaoRCO",
		"IEC 60721-1":"1ZoskfSDoxVuA5lkVaSsdvCww0B7oaCxw",
		"IEC 60719":"1_C9AkweS7QvHBD3cH8-pyH1snh8imlMU",
		"IEC 60721-3-0":"1eT0oZxUj_quViHHnc7HVv-FIaHAuXw8R",
		"IEC 60708":"1ekwI8cqVEI2ULVZt9DimPNF8iV0GXvT6",
		"IEC 60706-3":"1hngQE_jaV4pL5C6psu3Gx0-H6DvpXx92",
		"IEC 60706-2":"1lo6wFYFrNC-MgyWsWbNQ7Q16dGYU3ngw",
		"IEC 60705":"1mjdSs5uVgCyw8IpmNd83-9Og69NGCrmn",
		"IEC 60721-2-1":"1r74q78vLA6cMY3A3l-U0D_IhKz_jka5-",
		"IEC 60704-3":"1t4cAfXweYU7dc-yzbNbaVU-mhhYQkx1X",
		"IEC 60721-2-7":"1wkBivCrNprdRODii0BI_4vDKeQtV0jht",
		"IEC 60704-2-5":"14YDboVnBFx6xGmQ2uzS4GOaSxe0xHC8L",
		"IEC 60695-8-1":"14mHmOhGMb7Ge1Z0B8DxxML29qWIkpKum",
		"IEC 60704-2-3":"1CAcjpmsLTXcUwgl_tavRG2J7j8FlmR4h",
		"IEC 60704-2-11":"1Gok3FnxY2S_JEJh3XWgfL_VEftlUG7rJ",
		"IEC 60704-2-6":"1HQNj0i2RngrjsDvK45aRUypztHHbt2qN",
		"IEC 60704-2-2":"1OxeXmM_KtMtQHCgaEoQHBziGyQ3wzVyX",
		"IEC 60702-2":"1WD37ySYQWjLIYX3f7PVBqJyW0zXcRGw_",
		"IEC 60700-1":"1Yx1XEjvD05_YINMe-U-nsMK7lQ4lFJG_",
		"IEC 60704-2-1":"1ZArfmwfbEpA0etSmE26XQvvCRvj5Qi9g",
		"IEC 60702-1":"1ZoXRCAv5XXRSjCn98CBum72lS-dRhUEK",
		"IEC 60695-9-1":"1dpYC8HqVDK3gIidkv8yOdDtGy5gOvklb",
		"IEC 60704-1":"1ebHyRXH74ZlOg7R3xAjGRu-Oj3xS43D7",
		"IEC 60695-7-1":"1fa5ugsxgbj9PdJGlxOFFHKAGibXFtv-n",
		"IEC 60704-2-9":"1gYCWSOriU3bOTs426zfmDWAFBfcBh-rd",
		"IEC 60704-2-4":"1gknCnAwnDEQi3CVcXVc79S3G1uPAalR2",
		"IEC 60704-2-7":"1iERu_IA7vvtIXdAMQMzHVA10VWXPqPx2",
		"IEC 60704-2-13":"1l8equsGgtWwblk9DwRQXlMiuWe99j_9C",
		"IEC 60702-2 AMD 1":"1nC_k2t8SEWR1aThBVf9faeIrTfmObBYT",
		"IEC 60704-2-8":"1rTXjBdLcIbMo1JXz8M0dY4qR6OYMvREG",
		"IEC 60700-1 CORR 1":"1t2B1S0qzl3_4w-UDhEKdqFTneNm88aVs",
		"IEC 60704-2-10":"1xpBqh_dfnVerg2aYxruDnqXit7MtLU1v",
		"IEC 60695-11-20 CORR 1":"12K5hfmKJWUTHdRBpNAFGQL-hEn7DcoD6",
		"IEC 60695-2-11":"13qgAn0TOOf1Cmway6boqVjyD4AXztZJl",
		"IEC 60682":"19GGAVS5ntAlFUJQ6sq8MyTygS8kUfTUi",
		"IEC 60695-11-10 CORR 1":"19wzDuHF9wY1ke45EuzDaSGrNesOil1mv",
		"IEC 60695-10-3":"19yjdVbPxCcO85KA0T0PwI5GwHKh0D7iq",
		"IEC 60695-10-2":"1FP2TBFTSQljZb4h9s14aV2-EjX7aeggs",
		"IEC 60695-2-10":"1GKaOoX3LoewUcMXL_7-s5T3jkPw4Cfxx",
		"IEC 60688":"1II7Q-zCh5W5rOHIvef1Sqwb1su1M3ZDk",
		"IEC 60689":"1NOvfc4TBkwztfgzwc-dJvkz1RpIkYC5q",
		"IEC 60695-5-1":"1Xq7lkWTFle9ve3RNQAorT8qf97fc6MJc",
		"IEC 60695-11-20":"1YdDRrAhAtaau8335jsdE7mCNuA9WBfEy",
		"IEC 60695-2-12":"1YrHmCBVcbjUPx31MwLC-VoD3UoDa8ES9",
		"IEC 60679-5-1":"1aFBQnFN1WYCQKkec2DgYpbsgkzyYRxpN",
		"IEC 60695-11-10":"1awDPXcwFF9cnU3eJ91HwnE7kB7ydSgXf",
		"IEC 60679-5":"1bbGclWuYW2lvlxkqRPOJTVJR9glOxdGp",
		"IEC 60695-11-5":"1gFJkPxvSxjTWbRBZlGeplLm1IstgZ6z4",
		"IEC 60695-2-13":"1gNQ1DQuBCMMaF_3yUc09zwz8LIqO7SfK",
		"IEC 60695-6-1":"1h6STObUlC8NsS7BPT7tSiyMe2cOn6_7K",
		"IEC 60695-11-2":"1iC68N2YHzoeir6hvi9ZgNAIeIQ95onh9",
		"IEC 60695-1-20":"1l2aj4FHZlpYgkUy9DvLHA7JLe1S-oSmT",
		"IEC 60695-4":"1tS8-8MVokyEfJZVQ2faRVNPKw9rWGcfE",
		"IEC 60695-1-30":"1fHkcjqO4gBfXaUE-Q9Ev4DR1Hz2ssfWf",
		"IEC 60688 CORR 1":"1rdGW5YcZR8acnl1U7hlOLZsWaBial9Fx",
		"IEC 60679-3":"12qP0zetNeJupFU9hYVm-qUXZy8sGWgJK",
		"IEC 60679-1":"1Ahh7WELNLx6lgwtlLtPieeovShs6mQL4",
		"IEC 60679-4-1":"1BZwa0kli3XHvVyfJt5_e7AA_ls_zPJ7H",
		"IEC 60669-1":"1HSEDl0GZfjv-byJDDwZqSnLEyoz33o9Q",
		"IEC 60679-4":"1MrFDDxohK3memyX67XgNvWVzS5sY2_XJ",
		"IEC 60670-1":"1R7sp0qNEsRiZayygDsA6N3D6HjnVYJED",
		"IEC 60670-21":"1SAf8LCY2DZEGItAuOe81WAMkxEXLWvkn",
		"IEC 60670-22":"1UKeFBZJyUCBpzcULg3XDCalnOTPPhcVB",
		"IEC 60669-1 (REDLINE + STANDARD)":"1YgIc_iWkNP-_yuu8vNYxIAowlx_LjegX",
		"IEC 60675":"1boR8Fq2-W4Vk3tslPwK6cGyJIVwRZ0r_",
		"IEC 60679-2":"1c19i0cZLQLkzfuPTDbzNmM3X_mVSX-V8",
		"IEC 60669-2-1":"1delineISczEquzxmUL2Wt9CAff2h4dML",
		"IEC 60670-24":"1ecCWsy2bcsA1csoo6fUKBJijPrV68QXS",
		"IEC 60665":"1f2V8hU4tRlIAPJEwsnaS8QpgifDYpvqM",
		"IEC 60669-2-2":"1m4x5QB0MXJyJwCWZdwAm80AK9_6HuPSc",
		"IEC 60664-4":"1nwNdh8a8wQ7Pthtu22AeUsFXcH19Hl82",
		"IEC 60669-2-3":"1ota44ilYd_v62SRbNfvbAIucYaQoiyaQ",
		"IEC 60669-2-4":"1pHDz0LSY6BS8NOScywi70ouJcZkH-hpt",
		"IEC 60654-2":"1-3e6ht1tw3ifsdouA3MpcWIGOc_9JXsR",
		"IEC 60657":"10UoEv-Vx4xYfHpC-kY-J2ASPigf61y2E",
		"IEC 60654-1":"10nMxjc1sLAwOnUdpOMIeKl8tu3FeQGW6",
		"IEC 60664-1":"10o59vctFJbcadfBNU6mCwDqqI0Uq-TvK",
		"IEC 60662":"122HKybp87DlL7kktFO_GHVPgg8VpZwbm",
		"IEC 60654-4":"17Whc56AAUhvvA_hWDNM4UaKX6r4r0dtU",
		"IEC 60633":"17wk5ceEkp3bI__52QUmS0WEuqz5a71Sb",
		"IEC 60630 AMD 7":"1CKsL5hsLtXNoQEZ5U_mto3SpD-67qwF7",
		"IEC 60645-3":"1Gfd7GgRGX_iqElvdn3FhN0I6nBcddNHQ",
		"IEC 60661":"1KwmAGYpx3iZKenKVIVF0n379wY54jBHN",
		"IEC 60630 AMD 6":"1M9V71px6aqL71MPfLhqo0oG1rpmkEwar",
		"IEC 60642":"1NHdhqWg-62tW5vnmZDBqstZjAO2HXSRq",
		"IEC 60654-3":"1R8oR304aUfYKjpfvIiovClk6EZOmI49i",
		"IEC 60654-2 AMD 1":"1RG3xCW4xKnaIJpdrw4FUuZewaUyqmV9K",
		"IEC 60636":"1_iK2sX9k1wX_eud4XDByQnmxspdfTfgk",
		"IEC 60630":"1ea7AoMctqYo8ZQnCCgmACwvsgjmegmRn",
		"IEC 60664-3":"1i1y7t9m01lnF3FQB1YTzPWE60XdjfDk2",
		"IEC 60645-5":"1qP8QbagF9HaMpsXRExY8yZOoJp4vyXjO",
		"IEC 60645-1":"1tPOyzLsdJjO4i5CusI3TsQPRnOqDO2c8",
		"IEC 60642-3":"1viPa9-5s87HVJY43fLj1_Vu3MjATJ0TG",
		"IEC 60642-2":"1zjBTaAhehHL9ZxuU9Kg_fRrUEnQoJDsh",
		"IEC 60618":"12L0R5qvlhMEq4ODZ6__2SIcWZXlybya8",
		"IEC 60603-7-4":"12l3exxg9ZtlYh5rjJnpgCeUPPUv1bgBM",
		"IEC 60615":"15peGNcGTR72yA-kwreeuVmSB04W5ts_r",
		"IEC 60609-2":"19rZvgA-9iTI_8lW0iXDUOhmu0dz0xiMw",
		"IEC 60603-3":"1Dj8gRkPJYMtnNrwIvTKmrWVQIj3Q12q6",
		"IEC 60603-4":"1F44mTPTCFU9vANqc55bJ7F-zUrUYmeMW",
		"IEC 60603-2":"1NZvq8NHjMEfKYmc3gLzCwB49DJPbV9wq",
		"IEC 60603-8":"1Ny66bWiBcxFeMbBjzCv7KOXymRYovYDR",
		"IEC 60603-7-7":"1SBsPs6vGDF0A6LqG6AUiwYu0L8Kh3scR",
		"IEC 60609-1":"1WsUA3vDfiKfdUYAHW2hvij2RKEZ1LQHt",
		"IEC 60605-2":"1ZA-xGhnHlsGIeb8Qw2lYEbeGoLNSB7H9",
		"IEC 60605-4":"1cPsyO1eMIzRmqdj1BYPlEG7EBA3olEqq",
		"IEC 60603-13":"1dEVQh9Z78uIg09W-iQ6WjnknYWk3pIpk",
		"IEC 60603-14":"1dqUsm1W4naK1z1pcpGS_2bp8-KCeGGLr",
		"IEC 60603-5":"1hecA9KWfgQJx8loqB4AaZLGB2aP3dSaC",
		"IEC 60624":"1jxb-YklP5HTBJMVEHEysAbZg-sD__hjA",
		"IEC 60603-7":"1oVHFYyzBifJF185MIyMa3dj3ovHgMVHo",
		"IEC 60603-7-1":"1pQTOE10C7Z5IntpPkgas5BEY1DF9G2NM",
		"IEC 60605-6":"1qVD1WH6vZl2OoFvS5JEToYn9L3dlEPi9",
		"IEC 60619":"1r5MAsy3W5xru-Cm81h3e0AqBAsgt2cix",
		"IEC 60603-6":"1tJDXVgRKJGv4Cln1xil6AZAPu6fC3_t6",
		"IEC 60603-2 AMD 1":"1z4Ct3q5BYZgsCqSnJKvQZhU_9MyIkOPI",
		"IEC 60598-2-18 CORR 1":"10tjRmzjSx3kSQsC2zhejQCYzrCRRS5gk",
		"IEC 60598-2-2":"11IP_HuvtO4mQI1y1gF6sFDOrf-0Hu5Mo",
		"IEC 60603-1":"11W0SZJmyUDET36Npi5mkG5iyyagpOdC0",
		"IEC 60598-2-18":"16WtY4EXr3XoHR9XvGl4O8f5RbaIkgcJR",
		"IEC 60598-2-5":"1BoyF91G_6x3_mQiKoKfaAV2qv_qOEYNB",
		"IEC 60598-2-23":"1Fx68rdK_LKHhaCUfp5cABg1JD_5621Ah",
		"IEC 60598-2-3":"1KVBN9ZeH8Z1cQExowrYBry_Qp-OWI_Ab",
		"IEC 60598-2-8":"1K_2EoWwPOmbMLuAUTG-V-kbKYVBhlpcW",
		"IEC 60598-2-25 AMD 1":"1LOT6MzzfP6IR26f-GTat4wnnPf-KL8Nr",
		"IEC 60598-2-11":"1Omw8w7xcSlnDIzYmk-vMMFTWysEWAHh4",
		"IEC 60598-2-20 CORR 1":"1RJ3M9YQTMyU2Z2JCYvSOZk7G45AryIrA",
		"IEC 60598-2-20":"1RbtAGKfxy8QovTaUwuWRCWfaKiD7Tt7Y",
		"IEC 60603-11":"1SNR3zpYB3YV9xoWhuu7OIFIVFlJCtIFq",
		"IEC 60601-2-22":"1Y1pJHTkSbJR5JCG0fNA_VR9JCf3SwfLo",
		"IEC 60598-2-24":"1_UpxnPto8fCiE04eBDRyqLwBJK2Dotfg",
		"IEC 60598-2-19":"1dpTH95GLn5iw_dWvlKBCELYUfHPN3Ugh",
		"IEC 60598-2-4":"1eiK8mpx9g3PFAH5EHI5DVQKGQh1reywj",
		"IEC 60598-2-10":"1gRG1xFNRMk9ENl6dBpzgS3vGX9NiTAsr",
		"IEC 60602":"1nWOgmZh47R1H3dQBsLxM33vaYKsHkheA",
		"IEC 60603-12":"1o1s_FFkETxgTPCOiLTI2BM0rYGPzTczz",
		"IEC 60598-2-17":"1qQQTcX7MSuXP7aBesbZQQjCc32udDU-t",
		"IEC 60598-2-25":"1vPvfQyfQ6AGhMG3SDDRJWcE2t66YADZJ",
		"IEC 60598-2-22":"1vsdBMOls4NVJQlW3nLlimdXxYUB0Itoz",
		"IEC 60581-7":"19TdYoQSO_A2nOXYuFvsOBDC2cVzbhQ8T",
		"IEC 60581-5":"19XTJV0kCUwdg8WZwRoJoZ56Si-kivx1P",
		"IEC 60565":"1Fv_GGQVdsksMCD4tfmTZ0kKO7Hl730Jl",
		"IEC 60564":"1Ms17-0WqJE-UStWwR6VFbnGqIcCr2SKR",
		"IEC 60581-1":"1UqokWe5BHM41uLOrQGP-22FsdPAp2i6T",
		"IEC 60598-2-1 AMD 1":"1VRFRxeoCvc60yDAwjFIsBX4HBdv7oRzX",
		"IEC 60544-4":"1a-3y7xH-0LOVxMcsuW0nYKA2erx-s25n",
		"IEC 60584-1":"1kWxxd1eaZtktLI8X0qf41S6Xxf4KaBsL",
		"IEC 60581-10":"1pj_X2KcgP0cdFmv3oSpqRADyvf54j6Yt",
		"IEC 60558":"1pnVaysNnFhlOengW3JfbFjLwbRSBWd28",
		"IEC 60584-3":"1tFG4KNh1SFJGHDppYpZN-tWvXxTSDon9",
		"IEC 60570":"1uTSc5TOC8mnbizSMIc8ex-I4wdo1Y37r",
		"IEC 60546-1":"1xFC3KvTGkcq0q2exjTHBOADTefbgj73m",
		"IEC 60562":"1ygoiCTXNQSHqM3UQYm-ao1GPNQ3YooCy",
		"IEC 60544-5":"1-_njQV-J0h8tmYhpiUDn3ylxxEUSiisQ",
		"IEC 60587":"12cBPkJIo3zBxI86n8xP3Gwqm6fFMdmy4",
		"IEC 60598-2-1":"1Nb_InrDCeciP2N9EDVC38zCLuuCBKxe2",
		"IEC 60598-1":"1SP1HqE41IyaR9m19yJdtJ8DCb0vaFt1o",
		"IEC 60546-2":"1SzdYqzKbjHydLWnYzaaNsJbcLygIe5Sq",
		"IEC 60545":"1XIjuM1HXR4mCGD_ymJVdKS5pwLE6G1qo",
		"IEC 60560":"1eM2xiDAYNI1UaGFmv-JN_GyU63yWZYx1",
		"IEC 60544-1":"10KrSEqKRcA0mC3num4SoyIOpB-99TJ1I",
		"IEC 60534-6-1":"12zcdx3rUjguHcFLTmqa01K3xSgKgk2kH",
		"IEC 60534-2-1 CORR 1":"15X3QVbGmhEj6kRNwqWmJZaJUZA7ELx6v",
		"IEC 60533 (REDLINE + STANDARD)":"175BiEET8phgXECB_jo12w2xq_DQfxMR_",
		"IEC 60534-2-4":"18wOvlqWsDejI6Xv9ogX9vnQojXkLkm1K",
		"IEC 60534-6-2":"1BYANAy0FV4d4BJW84SWo9RfDqyy0bqfU",
		"IEC 60534-8-1":"1GDiVC8SN0cG7k6lnojsGB8J3QxUEqTTZ",
		"IEC 60534-3-2":"1GRUi9bCUwMxg7hMwqyvlFrBr5LtGdJKy",
		"IEC 60534-8-3":"1HZtjiST7Zd_SraqRTGm_6uTD-4Q2QJHu",
		"IEC 60534-3-3":"1IGhE6hiUwIck650V2GDV2eEPi6VUcZdV",
		"IEC 60534-3-1":"1JkgxEdWep1_CPW10LtopuqDA-QaVb0dz",
		"IEC 60534-8-3 3rd Edition":"1KlC-_8XBBhhL2LOPYTRcLcJHNpYaBBr6",
		"IEC 60534-1":"1Rq629kkWgbIPDH0PnXOU3BBmpa_r5UvP",
		"IEC 60534-2-1":"1_I2p8xrBD-q1MWpnJprHmtr15R2J0pSp",
		"IEC 60534-4":"1pieoXdXXzHJFhxHqsSY7ux_B5JS8W6-L",
		"IEC 60534-8-2":"1qtITsqav1vXILXrsbFe5JKNDm1X5aYai",
		"IEC 60534-5":"1rG1PnNhHuSuKyIu7HBcK084qe-gLwMdI",
		"IEC 60544-2":"1rIrM8lXvmO3kGkTQXU301AsAgvQZ53yj",
		"IEC 60534-8-4":"1tYVBS0urqWb3Prk_yUXS6XnX8AkedgJk",
		"IEC 60533":"1uCNYCjoYYi7qJc0d0mulgu-uvlVWYX7Y",
		"IEC 60531":"1ugi5pXy6SMmLe0Eyf_zx5-5vF8YFcoxJ",
		"IEC 60534-2-3":"1vFWw2_yhJ6_H7GfR06KuF6sEC0lErmzW",
		"IEC 60534-7":"1xd10aGECqzDq_yqR9Egs3pDbWcMsomKF",
		"IEC 60512-8-3":"1-QrYgS0ffod8qvvMlXTOHFGgzlwMuoO3",
		"IEC 60512-9-5":"11LJKybch96d8C_cmdBcrxZB3nEVtrB-x",
		"IEC 60523":"17CM9fHOfFN2LnXKvmL9wmCHNFezEqxcA",
		"IEC 60524":"1D3Bu3oaCc6m0JSxmmNPixbNQFegR1CBQ",
		"IEC 60512-6-1":"1G2M6YWy44BPkjEDa3emURcpbpHgkXCQm",
		"IEC 60530 AMD 2":"1GVLue1q3FxOdYgaadUsozfVQ5Lzsb8rW",
		"IEC 60512-7-1":"1Gb_el9ORn4wFDmhM0Qmc-hvlUcmCDvqE",
		"IEC 60512-6-3":"1HbBUaH2o-VKfGxga1S-eq0D45N_RXuQr",
		"IEC 60512-8-2":"1IBUm_yOGtJNr3qbb7WvSO-2qUFPHAuEt",
		"IEC 60512-5-1":"1N20DLUuR94r6dcFaZCOpzGhKW_Ms1EQL",
		"IEC 60512-9-2":"1QpK-3GclKyezxYiWgv38W1N9EEzaH1u1",
		"IEC 60530":"1WcX0CNx75gtTxVBEianefEIXjFJuSa88",
		"IEC 60523 AMD 2":"1ZpcdsiC4_XqXmr_OHozEPjyr7or68oKS",
		"IEC 60512-8-1":"1_tJWE2CTQMl1sGk5eDNEa1hK9D4Svk8b",
		"IEC 60512-5-2":"1dw73ndDiMKj1pxLBXXvCYpok4UqX3fGk",
		"IEC 60512-9-4":"1i3peOnKc_efXlDIL3BeQ7K63uv1gEfNE",
		"IEC 60512-9-3":"1jPXlVlp_7Sgm8Z4rHZvNmk8-MEWZjomD",
		"IEC 60529":"1mPqAymUGMIb3ZH2YUjFmUPEprLoUmll2",
		"IEC 60529 AMD 2 CORR 1":"1oi06fok7Vvh06opZ7Kdada2MP3U-FjuR",
		"IEC 60512-6-4":"1qdDn7-Tipg14ZlxE972PTd9Mt2KkmM3B",
		"IEC 60512-7-2":"1sLGqDZDkZTo345rWeZ6_F_K8QxNDsyta",
		"IEC 60512-6-2":"1tl2iM6xK4E3Hoc7mMErXWZo3suTRENFQ",
		"IEC 60528":"1uRN-0rt5vPRkdsR7uAxP8WwQZuFdlmXE",
		"IEC 60512-9-1":"1yfxCBVny8kyS9AooUs_AqSbzr7JMjTzr",
		"IEC 60512-6-5":"1zxb4oh9QIqpm_woN9SL6BCBj-Ol0AKd2",
		"IEC 60512-4-3":"1-Jibma_GSxW-HX3EWlvpbc9V_ILcJ1kz",
		"IEC 60512-4-1":"11e1UZ_K_vBvrWfKDG2R8PW2ntNGDp0ly",
		"IEC 60512-19-3":"12kbISDWg4nRJ2WCzCXSSRSsi5v-_sj7B",
		"IEC 60512-14-7":"137coUkFlGsiggCtfgjq6D6trKdQXmfZW",
		"IEC 60512-2-3":"13CFBQUTDAUNeJq2B2to_G1MSxYIdaEOy",
		"IEC 60512-25-2":"18KoDZBuy0NXodn1LLRW8a3wj8UQHt2ca",
		"IEC 60512-25-5":"18P3LFPegQicbDww3pbXkZA8Pkpkje1yc",
		"IEC 60512-25-6":"18mxiAAUE5jye-aDFzUciracQ7biGr4T-",
		"IEC 60512-1-4":"1B7EZ60A4jjKOc6vfm8s35bHr2f3bYcoR",
		"IEC 60512-25-4":"1ByJMxXnhBdbKAwr6abgESTFLz_ixQ4iy",
		"IEC 60512-2-6":"1E4wWRXvfeTaU4ISU2q7mf9f11ur2xN48",
		"IEC 60512-2-2":"1GKeV2Xy7iWvfuOh2Qm3JF-sj8D6eJpSf",
		"IEC 60512-23-4":"1GbETtvGK3n7JgKNuAX5OHmWRzPPX5MML",
		"IEC 60512-13-5":"1R9sBzs8Txs4SfabzYOTNMk2cB8s1ZImY",
		"IEC 60512-20-2":"1X8DeVrIPpsSFX872EWNllSB3yAmc2yrl",
		"IEC 60512-23-7":"1Y9Z2sjY8fzNnl_hQBjetcnRJlwAagIhu",
		"IEC 60512-2-5":"1_mnOMXzO0TQUwTjA6x9eppwXoh5taKv6",
		"IEC 60512-25-7":"1akDpZ1pjk5mwXajDibRuaTVmsm_hUWuU",
		"IEC 60512-16-20":"1dYydOzBSBM-T8qoDJA82rhDSzTUJrMQf",
		"IEC 60512-15-8":"1iaVBQjLGRt3tLkA0cXPFPpb77vgmVIlY",
		"IEC 60512-3-1":"1kuXZyo9b3fgURQABrjcdlsj0E_WIxJ_1",
		"IEC 60512-25-3":"1lVdCtT0MmOGcUSZy-eQ38bmQBJNIp_u5",
		"IEC 60512-4-2":"1n4KKArT4dV_WXrlLxFl-jG8xssqqJmLD",
		"IEC 60512-23-3":"1pGnOUOr8dniTmcGffnJ2HazJRYDGelop",
		"IEC 60512-2-1":"1xFDbTkNUgzqnHylwOXaCeCbOPZ9RKG0-",
		"IEC 60512-25-1":"1xwNxcVLCT6CJ76eBRhC_XGhXZtGXbHNr",
		"IEC 60512-11-5":"18tCXo6erpQ5i0lz6U-WeStb5bw-25YGu",
		"IEC 60512-11-7":"1CfwUT-S9k0B8GpSd9PeZcRlS4NxSE3af",
		"IEC 60512-11-6":"1GnX6rR7WPRXr-bWrIUQlY2LUcfUuA0ed",
		"IEC 60512-11-10":"1MYaMjC10yTdRynMfmdZy3asVM96PgiyH",
		"IEC 60512-1-1":"1MtoSboTIwPzI-RNTzH2sRXSPEJ7MYOZe",
		"IEC 60512-11-13":"1P_1QdVj3QG6i0JPKIDqogYn4qhe3Kipd",
		"IEC 60512-11-14":"1SuPsq_5978L40lVfEtK37akOuENnLEiy",
		"IEC 60512-11-8":"1WlciaR8FiPQjnq6EAUdzh1NzuobZBPTL",
		"IEC 60512-1-2":"1Y6ioWKUmDhMwsBeNsSROMcsxhrXfFvLr",
		"IEC 60512-1-3":"1Z2xin8fJWZjDCo2LbNZ7tOqyhtrfA2nQ",
		"IEC 60512-11-2":"1_5Ua2vl5LDOFrMvtDkhyLbTkE5_pWV_g",
		"IEC 60512-12-2":"1dxEMVt-xWXfCEilC5Sx9bqGObn_JsYqq",
		"IEC 60512-1-100":"1kgHopqwHKS8l3TEmGMVo8O46Vc903zW6",
		"IEC 60512-12-5":"1kqJ1ukrIZSwnhKBZTE-PFx5ToMRWyoTd",
		"IEC 60512-11-9":"1mXssGbT06pc5QXci-JImlYJC4g-0IiIC",
		"IEC 60512-11-11":"1rulFRsVx4w3HZjThsZjabT603wEEExW5",
		"IEC 60512-11-4":"1vRYzWcsjmJGixEzxFjRawrPW79azgM6v",
		"IEC 60512-13-1":"1yZorochAeExRaQ0sby-riUD8W-ED788m",
		"IEC 60512-12-4":"1zegKIy9TR6sB7y-JVQ2WoKqtN-v2rwIy",
		"IEC 60512-12-6":"114Rhzw4jKLGocp2MfD07TsM5M8rQH6X_",
		"IEC 60512-11-12":"12ODDfzGyL6V2dEHaeneq8UfOvzMz-ua3",
		"IEC 60512-12-3":"1fHv1iDRNFbaYCo0SdzVyq1lLhspo3bZk",
		"IEC 60512-11-3":"1hRr5Hjg58iK43qMR5yg6zNGPCusNhcXy",
		"IEC 60512-11-1":"1vADZn3QCUFSJwPSZ5rlCaPXMpEHLLl0g",
		"IEC 60512-13-2":"1xtNM0FVcX36OVbKdwOk6b7Wnz9vgOJw4",
		"IEC 60510-3-2":"12ATfQcwmosBahMn4NqniSQvCyiJ9arV2",
		"IEC 60510-3-3":"19sJsi7dv5GhCrRTw8i-DjXk0lTlrFPT6",
		"IEC 60510-2-6":"1Am23ZAg56Z7mwEXhnfFXQfaFaKUgLoKp",
		"IEC 60510-2-4":"1H6NmVMJrzziwprqHPtSWshDo8cGRv5tf",
		"IEC 60502-2":"1NkzTuQf702MiONFVmSw91mpuMN0u9uTU",
		"IEC 60512-1":"1PHNqhcQ0R2juIHVI37zf7waG8h-Gq1fR",
		"IEC 60510-1":"1RJwKLeREkRbVVdfnsoOKj4lPsXAYuZKt",
		"IEC 60510-1-3":"1RlgyLmMGtqLqxgD_Eh2ieZANMUWCKBcj",
		"IEC 60505 CORR 1":"1bDwfhjDVuDcaTQtldMKs5q3ivTfByzLp",
		"IEC 60510-1-2":"1bHOhVxgXritkQ7pFy0SXwLVPpNt9vnsd",
		"IEC 60510-2-5":"1cpMmjVQixi1wAZdQd6-ejGYgAIrn-3lj",
		"IEC 60503":"1gMcKMnyULrHVVdMIRh2u-OEkjsbghTWR",
		"IEC 60512-10-4":"1hqV7xsVMUS_mbU0fqQWsPvr8Uho91HC-",
		"IEC 60510-2-1":"1k17s75ZE2KtGkC67M6E6JVb3w_OaMMPU",
		"IEC 60502-4":"1nVWPFYZGLdrRkGLch3atItKhVwvmaScI",
		"IEC 60510-1-5":"1nYnS18Az9AKBivtyNkIcNb9GxVO5raeP",
		"IEC 60510-1-4":"1pn5LUO0KaDmFD8lJGN9UmbHqNiQMBnNa",
		"IEC 60510-2-7":"1r9SBaIbo4gFJdxP_8vMQ3yZ18plm2YHG",
		"IEC 60510-3-4":"1wBNdgx9kTe5g-6wMIwsd2PViRWz0VcPp",
		"IEC 60510-2-3":"1xOdoNP26T952zUFUKo_ZrT7keHOpA_Eo",
		"IEC 60505":"1xim4CcOI-44JepA6naELA8kTku3tL24v",
		"IEC 60510-3-1":"1ziwcNAbwwxm0Qw4bPhyfB1UjKiPdq1i3",
		"IEC 60493-1":"10CZE4OPsYadI1FcS8qjUhDlwxIxczfjQ",
		"IEC 60487-3":"10c8cb9rWcGqyMCmxN9W81mIzheBiI4YJ",
		"IEC 60502-1":"11xEsl9E9GTpqauKr411JmHswEh_CyimP",
		"IEC 60487-3-2":"16LxsK9zK7Jd2gY_ibpTUq0cXLbeS-3x5",
		"IEC 60488-1":"18m_w9MwnCd26-ZmFTc1RP7TvjdyVeD5x",
		"IEC 60487-3-4":"1Gg3BT5ucwkaS3GH6Nr9bumKNG81l4A6X",
		"IEC 60487-3-6":"1KyzNPFCkoS1kcxlp8TlwCEh3b-ASblBy",
		"IEC 60487-2-8":"1L8CYeI3LFRKO9qS8Wudjd_t6VkR_jm3R",
		"IEC 60489-3":"1LyvaPIkF1FHPypssiQRh_V3faAQ7CJl7",
		"IEC 60489-6":"1P0sNhNry2j810G3gjK46BW9FSH4YZALG",
		"IEC 60488-2":"1V-QuoTSczXPgrZG9f1XBfxjucLUWf9ES",
		"IEC 60496":"1W5rhAIU4ttyL21EjzOUl2csmtXcF2WP5",
		"IEC 60495":"1aWVY3RFQrmuTYR6CkI4dyVL0Un1FztW4",
		"IEC 60487-2-7":"1dntsD9V1XnLLJzxOsX56KH800RMk5HHC",
		"IEC 60502-2 Redline":"1g7TFSlXB6I2eaaULly6BmksAw9EvvvUZ",
		"IEC 60500":"1jbxvgINFGFgv8f3DVp6fMbdUm-8AQeZR",
		"IEC 60487-3-5":"1pcwHMq9E6phuHDkWNDOqZHYLjTeLVER6",
		"IEC 60487-3-3":"1yOP3UZ1-GGkvK4D_3i81v5HMQnW6QWZP",
		"IEC 60450":"1-jsrhlqgC9RrS3HFdAu0PYLzbNXOkb8A",
		"IEC 60461":"1-tUmFLCjz9qiLJE-6Ss4RrjFuoG92HfX",
		"IEC 60457-5":"13GANrpzFC1pljay9kWytbSh9yxGiEaut",
		"IEC 60481":"14ONMIkeqG7_6-PxsxEy6bqKL5i_5w0wc",
		"IEC 60479-2":"18vyhhJeth0xX76QqUaeod80qZgLxxNgM",
		"IEC 60447":"19mCNfOZ4Tjquk8-YEzVyGbtdETHYk5zf",
		"IEC 60477":"19mKcZldUIVP8AbAH26tJqOMaCw3aUfT6",
		"IEC 60487-2-3":"1CJ-H_gaK5QuYT5WMOEQqGfSYm9iUWSmf",
		"IEC 60487-2-6":"1Fh5NKdT9OsM0iH1aqw2HwsPKLZnnBmuE",
		"IEC 60487-2-1":"1NxOaq03PrCJNyqxVVxkpVoVrJzVgOHqi",
		"IEC 60483":"1OJi_DYt1tlRJAL-7Lg15RMHLVepBdPFL",
		"IEC 60487-1":"1ObzVS7KCGuNa9MBmMNAehdNxGjvRhbvV",
		"IEC 60487-2-2":"1Rm4KGtgyv9qEQfuqbAwjLIgN0CD4iqcJ",
		"IEC 60477-2 - Copy":"1WXiThwFdjEULDHkC_YbQa3liRTne_NpJ",
		"IEC 60457-1":"1YNMDdxz_G0zKoFFv2Tbt1KTq2qyWdWXf",
		"IEC 60456":"1b3e4fM9L-oZQzKpWZthL4YOwJffpk238",
		"IEC 60487-2-5":"1cufZIuQpFuKz2Uf82jUZ6ykaG4j0o7eX",
		"IEC 60457-3":"1dXcnMLPesQC8gg2x2BRpAi427KClYZ2T",
		"IEC 60477-2":"1fVRP50dzu-MKVnqb6zt4F7jH9uaz31u4",
		"IEC 60487-2-4":"1mMAhZQGMCaX4xn9V4Aqt1g22pi_zHZ9j",
		"IEC 60457-2":"1tVlNlPH5X9gkHlIwansvjlCkeo_uaCI5",
		"IEC 60457-4":"1wzARgqT97NVE1SR0zvAPzqOdWi0AHt_x",
		"IEC 60445":"1z0jH5JeugPDVGSbZ4m9KSI2aGMU4LMQ4",
		"IEC 60442":"1-pSmsG9NJx3nKiv3oTqbTr0hflg_CAhe",
		"IEC 60423":"11VePH0mmlQ5FEPXwZ-2Yf9jawhhAC46D",
		"IEC 60444-7":"12ZFM0XM6M46_liO30XEXX--hKywS2JJH",
		"IEC 60432-1":"137BkTpqgNhweQJZyaXQyqoY_LL1jDs8r",
		"IEC 60428":"1AGPkqzAW79u7S1KUGecI80gnu966j8ub",
		"IEC 60444-8":"1Du3eQnLJwYs-GcMQxNJBe2fzEmFU4cFa",
		"IEC 60444-6":"1HllJMo5qFPEmq4_Ay36Qk-7hFVQWZKgt",
		"IEC 60436":"1KoWma0NJUswKwqfCEjg8E443Lo10CbDH",
		"IEC 60444-1 AMD 1":"1LW2hdFSHVEcmopK3so1XsFxPLvl_6-3m",
		"IEC 60432-2":"1SrIt_lXh5BbCT2nl3ouGeCBT5uKLJU3n",
		"IEC 60434":"1Y1cw-_X4bb6tL9frVIUygKtfVCPNg8lv",
		"IEC 60445 COR 1":"1YJXO1fXcyjMGnqnGL9R_lSv0BMT_5mgB",
		"IEC 60426":"1ZPClKciCbXY41mU707RrgZrVEv9mFqWu",
		"IEC 60444-2":"1bCso0D2rI0z4YTpH-4L3mDF7vB8SEk3s",
		"IEC 60441":"1cplG_o6iJVafZU9XerFG5I7EoWx92c_6",
		"IEC 60444-5":"1l-FHBjT8HRnJtkSZLtdaC5A63yCF36h5",
		"IEC 60444-1":"1mp8lSRJK4QyEo6D8T4rxQG8_F-QP_S4p",
		"IEC 60432-3":"1rErtW85QymrkMhSMu82zlDAqtUHd9Bq2",
		"IEC 60381-1":"14CZnYUS8XqSYtY_4cW4EukJFMcIMD7Om",
		"IEC 60377-2":"183Ezn-4Yu1clR2ZUrKCdnkz9LojhVHkN",
		"IEC 60400":"1AUF536RNBklGEoGPObqmiHtFWEQKQ0cN",
		"IEC 60417":"1BWN7S-OStb_hMmTl-x1bc5_l709kNg5h",
		"IEC 60364-7-753":"1DunOhyp_ElvbP9aFj8rYPxG6G1fPZT5P",
		"IEC 60364-7-711":"1EiIb4EU6-p2Qe8GakSgYebdSoCcG9jNt",
		"IEC 60364-7-715":"1MSx_vgfDAE-mLzWM_O486p_0AkcMZZ_0",
		"IEC 60364-7-714":"1N2e4NFXY-6Efz1tVDNOE_KEMHYTSJ4mS",
		"IEC 60364-7-712":"1ONxDjR0tpqk8qRs_-twnYHj_Iy-dxOuo",
		"IEC 60364-7-710":"1QeqPdS_WsdrYgh7JBBsrs3osHL7CWizh",
		"IEC 60368-1":"1XUPUZxtG1KgqtgEz9IG3_hpqDPyg232a",
		"IEC 60413":"1epeK2KYnGkaav81d_mdID8b1oXPjQxtn",
		"IEC 60364-7-717":"1hsmq_kdCWdXJTwrRny64pmIXZbMDZS5o",
		"IEC 60377-1":"17BTOVclQmTqrAmYNKq2eW_lWYmmLjnIB",
		"IEC 60386":"1Gjt3JIc0vDqehuNNR3uZTcNxKKHkxOGb",
		"IEC 60364-7-713":"1I24UoOTzKvbZ2wT44-u0TwxCaXwyLb0E",
		"IEC 60374":"1K-Z3mSmDeUGEVcz5N3hBk-TwsniVbBVt",
		"IEC 60382":"1PQQaubjSb18x5qFy8if4n6qQATTuEt1b",
		"IEC 60399":"1dN52S-tn_8bBnxE7aQTLv4pHc_zH0Ope",
		"IEC 60379":"1gL_V8IHIsNruwDxeBtmnowL1RUJPFSyS",
		"IEC 60364-7-740":"1setHYnVkwue_-42eg1z6h52aNfKy9Bcg",
		"IEC 60381-2":"1zbwQumPZA-VCiBf3r5wJ_l3jkHRSnpVR",
		"IEC 60364-6 2016":"115EfPnWQlw47Fcq3248LBotzAw7nPx4S",
		"IEC 60364-7-703":"17WTloK4wM7BPAPD97feYL63qX-UxbW-z",
		"IEC 60364-4-44":"1Cp6vImeFYBsLvi07dJN8bS0aYFe_CUiO",
		"IEC 60364-7-709":"1DaoiJaDg1dKjoDb3pZIMK8A11dtEvfan",
		"IEC 60364-5-51":"1DmozyfYRvEVfi5-eMUFdOveTMFq0E_7P",
		"IEC 60364-5-52 CORR 1":"1Oj23mATroqreHDdfk-mw-NEBeic0VPdw",
		"IEC 60364-7-702":"1QpLVPBQnUPP67IIKteHFjN86pcMYtU52",
		"IEC 60364-7-705":"1VY5iWbqFU4WRV-xgmluExh4IsSq_Is3e",
		"IEC 60364-5-54":"1covun5ftEvn-uS8PL17cYzLW6lbi0iHY",
		"IEC 60364-7-701":"1dPM_T1Y7SGNmYbwudXfsVptyHEmxBLy5",
		"IEC 60364-5-55 AMD 2":"1g33yAHoVOCyrmAyy_IKZMhWaiLji5iDx",
		"IEC 60364-4-43":"1jYVuoYQ0LM5A0_0kxYovq3KnNlk1wwn7",
		"IEC 60364-5-52":"1o-sbLAL4P_VkFJADIlF0FM5z2RAEGLDK",
		"IEC 60364-5-55":"1pMAOb7E29XMvP7pjLCW54ZDjTJc_APOO",
		"IEC 60364-7-708":"1q4RZ399T3ovdXO5rLwLiK_8M4-ZfCgG8",
		"IEC 60364-7-706":"1tiM-yYDwrU8GJ5EwM0cKUlCso8bhdblF",
		"IEC 60364-7-704":"1vABYMjRMcJbpUVtYFy8-LMtQjwjCsG1a",
		"IEC 60364-5-53":"1xPhaR6AR1cs80z1pDIRvMVmZr4AcQc04",
		"IEC 60360":"1-2BuGT9tEKDW9eE80OZ_cKjN_5Htln99",
		"IEC 60352-7":"10Tb4BhClCo2eYvLrph4ar3Y9MuQiNJ_7",
		"IEC 60352-3 CORR 1":"11sqjt-00F1OwXlbTvnaRyOjUXa_KweUn",
		"IEC 60364-4-41":"12oViNd4Oy6wBoFKRrLzlrAn0GLRIC9Lj",
		"IEC 60357":"14uOYMZKEYFLlsQ4X625ZbFCzftHcQ132",
		"IEC 60352-4":"159YN-TWYRxNEdLJ69MjGEQWkUD-78B8k",
		"IEC 60352-5 CORR 1":"198_HgUeiqUX9VZI_n0vR8fxzFHrJ7vX6",
		"IEC 60357 AMD 2":"1AIi9YBza9OeP-mu1ozNkjMLcHybAZTwQ",
		"IEC 60364-2016":"1E2tGgmf8VZKFArf6sOLXUEBsnhnioxwG",
		"IEC 60359":"1FKqLrqWANiY0XtQzVFQp6J5sSFLRsT_B",
		"IEC 60357 AMD 3":"1HYB3D-YhYMWleWHooUJdoBW1SuIDAmW_",
		"IEC 60352-5":"1Iwzs-HcC-FlOWol7cmJ9Lm_fm89jF5Aa",
		"IEC 60364-4-41 CORR 1":"1LHS4km2-24mLJ5T_gF8i9VFU1SNWVQVz",
		"IEC 60357 AMD 1":"1LVFlCIwCZiigPs9v0w4ZGPK1wS5IgeU3",
		"IEC 60364-4-43 CORR 1":"1O5MsBi3gOjWe4efZUMjo2pv1d5xylQyP",
		"IEC 60353 AMD 1":"1QYT8WmNU5uGgzFrjZIdWE9Sz_7H2Vhd0",
		"IEC 60352-3":"1ZeWdOhMy6sZOmoz2I0t7nKUDHvHWEdR7",
		"IEC 60356":"1_NXUN06HzOtZSWkBkuwjs6ZVAE0gAPeq",
		"IEC 60352-2":"1goFxERlA7-zWQIuNibwUW-4KCTfA5X7B",
		"IEC 60364-4-42":"1hsbSqWIHcIWXVGic-KurLfw75EweEMc2",
		"IEC 60364-1 CORR 1":"1mWjarUQi3LB0P1BKZCSxzdolCP0Uk8Cb",
		"IEC 60364-1":"1n8W7vx_jaIoohokjlpUNpYQkV-yBa6Hd",
		"IEC 60352-6":"1q5y4YebsSw8S0qrfx8c7lqnQPGR_09aR",
		"IEC 60353":"1w8G6EGWXIaTUQGnLXOPn1ns-ZUi_SYhX",
		"IEC 60352-1":"1zC-cI4P4r3Zz9xGgKyDi5nD2PPxn3tf9",
		"IEC 60339-2":"138BwVG3GJYMR_O1CpJr_qWP31u8-Pp_J",
		"IEC 60332-1-2":"18w6vjj_Vc2K6eRh7p_uw1OUvq4plyRrS",
		"IEC 60344 CORR 1":"1Aent7H3vBSPrMi-YF4uRnh8rOrbWfZ82",
		"IEC 60332-3-25":"1GfhJW864XRWC1w69wJXl90UFt19FmQY_",
		"IEC 60332-3-24":"1HkzE1Xv16DcNU8aWRIV42TkFApcIa1Fd",
		"IEC 60332-1-1":"1Hu1NyUBXZEoEKlW4Kut6iI1cF9lo6lR_",
		"IEC 60350-2":"1NNu97OpppyUnBgtsZ5JDmV7NlSmzFY9h",
		"IEC 60332-2-2":"1SrhDDdftewcGtP7R0kQcH67sh9_RaGht",
		"IEC 60339-1":"1UirsccP151i0XHqkekcsMwKuARa-ICvT",
		"IEC 60332-3-23":"1VkJ4AAb4kndzFA0WJdkJOzjXSUK4M2XY",
		"IEC 60343":"1Y-s4FxIG6bsv4ipHByG0oju6RneECnNK",
		"IEC 60332-1":"1Zk_igJVNwfKOWcwD3LRT2G2r7H_lsAE7",
		"IEC 60332-3-22":"1b7SEcaITuv_NvfX-EdgA-dOvgZIhKZGD",
		"IEC 60332-2":"1exgzUP-7ASDmqE6j0JWrNDif0jv-Wh4C",
		"IEC 60332-3-21":"1h9rvVpCYK2Wk7xMNN3pEJ5oSEoK0NnMI",
		"IEC 60350-1":"1jxacmmGYOn7z91-3exFKwqKCgwwfM3v6",
		"IEC 60332-3-10":"1n13ic0_RzBYhCFlGVr12DFijdBHtLHCB",
		"IEC 60332-2-1":"1rdrNIM6DIsvpm0UCBK4TgpEWaK6ECYff",
		"IEC 60347":"1xy-qCnb8H9H8Ehl9HC-J_VxJeypqYWpm",
		"IEC 60332-1-3":"1yXE8mZKzSUDHmyK5aAkUK1ay-V8wlyVF",
		"IEC 60318-3":"12DeOnj8Hnsp3Gqp_bGQFlIlVIsh8adbn",
		"IEC 60331-2":"1372tD6rbO66esu8oxaKHqEN_RnTgzHrc",
		"IEC 60317-49":"13omAuK8qdK0hwdqbTiqoq2a53jokndaH",
		"IEC 60317-53":"15k_KpWEMnr9CDaKeYyHz4gFEBczmJxQ_",
		"IEC 60317-47":"17SnfxXsas6EKWTQsp9f68iuhD990oJLy",
		"IEC 60320-2-1":"194Q32-nM2kqUNQGXXG8kUBFYvm3osPPf",
		"IEC 60317-51":"1977RcKnCGNuIE1FrOUFvvzvlmjcbiYda",
		"IEC 60331-23":"19L2KBgfg9_8MmDEn7qYdNeLGbF9zSNG1",
		"IEC 60318-1":"1JQBaRK08yFXhRlegd2nziU1Z2tI5UYP5",
		"IEC 60320-1":"1KWhcjvdMz2AT1JOoOoPNvqeC4n2e0mmI",
		"IEC 60317-8":"1T8_DqW3L7u_QDFa_I7k038zcQwnm1Ym5",
		"IEC 60331-1":"1c3FpVGotAhL7D3J_mGJudceOMG80CXdS",
		"IEC 60317-52":"1i0YhFqAarFVfVvPjjFRupN36CZxsSbhY",
		"IEC 60320-2-3":"1iddd3DCgnrQidP9UVZqQn8x9cLlq4Vak",
		"IEC 60331-25":"1jjIVZIyEM2GLAtPKh7iZqOSXAeE1mntQ",
		"IEC 60320-2-4":"1kM013c_t5Mwf2WEqhYp2JvmWOGhTvY3X",
		"IEC 60331-11":"1n4jA-cE6cSsnDEexa8uFe8jlXkY3sdhn",
		"IEC 60317-50":"1oAjzrgkTFpbV9w_h1XK5didutg7c4UVP",
		"IEC 60317-46":"1oGAHndiyzkXJGMVEE2Vx7lwlL9vlMNmT",
		"IEC 60317-48":"1u4FRPz4z7eIUFCu0bPYpJSxt-6ZL2mRd",
		"IEC 60331-21":"1uqtzbEZsRv0w5-nWhaAnFeSf2W0yPKYK",
		"IEC 60317-35":"14ceSTA6v7lPpCqvrt773jyA5xZ0jkMb_",
		"IEC 60317-26":"1Ao_OXQTu78xVQPijTbCEXdNS2bPSc2q8",
		"IEC 60317-3":"1FfJlr7xoVg7LyxLtBUxJPT8LVwJBhyNv",
		"IEC 60317-38":"1SZPQdnrH4cJyzr6MMHEp2BaOcVL2N_Kj",
		"IEC 60317-32":"1gw-WtQIMWzPguWF5-dsPUblMvBh9Rrh4",
		"IEC 60317-42":"1vKQS4ako29y6DKagSMVDAyHkpQD6EKN2",
		"IEC 60317-23":"1vnHZuWJ3sZtPZHSb2KdSFnMtxqxMGbqN",
		"IEC 60317-44":"1-4zgolQyvmVQpQNt6at9oVpuulFyPH-t",
		"IEC 60317-27":"1-AIcQpdiSxJHzgqPB88Gpz6QCdP9YvlB",
		"IEC 60317-29":"17vbl49drRMPSFrSOQtQSKypQfI4RLkk7",
		"IEC 60317-22":"18gUTNyVwAzkFLuOfomUo1bP4nrSyugx0",
		"IEC 60317-43":"1EbiKQkOjcpeJHVRzReVJV6N_V2iXgmfT",
		"IEC 60317-4":"1GsIRQzbnN-7KndBsamojuaNEZZLKtx1m",
		"IEC 60317-37":"1I3Pr-AWABgSx8fPAY49xBtZI93Z79bn6",
		"IEC 60317-33":"1T8B_7Jw6ngPpFsqgzS3ROVXTKx4TWuNz",
		"IEC 60317-20":"1U-tyiT2RQ6PRI5TyrSHrBfvMfqdDnW88",
		"IEC 60317-25":"1VREEdeQece5MwJYX9Frfc2N9TcxDnzwV",
		"IEC 60317-28":"1iEUpCPWvqBPZLsKIvktAmIjuT0pLLHw2",
		"IEC 60317-31":"1kJpZY-7qXhV__DegQ44ovT0upU29V0Cp",
		"IEC 60317-21":"1rYDNCHb75N0JsY2096ySZ_bGn1t_HaY7",
		"IEC 60317-36":"1yX-Hvqz0cvabBiDaOcrRbKczPe6Vbyh9",
		"IEC 60306-4":"1-kPNmpsPcLhkgChVuSBXJG9vx2QKEBg-",
		"IEC 60317-15":"11aFK_WTqSUYDD9SJgRD6R-uh1x_xoVur",
		"IEC 60317-17":"18vHlCTp4CpR7H2uAsh5SpidyrmAoU3vz",
		"IEC 60317-0-4":"19I4G3HF_V3aaQE4hSZ46bnqDdD2HZt9y",
		"IEC 60306-1":"1JKIvfRyH402u6imPGVTLF0smlIZuFFL9",
		"IEC 60306-3":"1OKWcIwfJkGjYee9Mz5-vd_9APQqEiJAc",
		"IEC 60304":"1PGmyCsSGp164qv4ZkHNpCzmH6dcIiIUT",
		"IEC 60317-0-6":"1RrI2vOFHrViqRSdIhD_7rYxYO_4_31vM",
		"IEC 60317-12":"1SEnPe4A7HJG1csQIQzhD0lxU3eQqsFFB",
		"IEC 60317-0-2":"1Wt5eB-dQhK9KJLm5DP-m-kM3MGj1xmet",
		"IEC 60317-2":"1Z6mR89_gHuA9GjrWn9c0dMJ_cxutZDxz",
		"IEC 60315-4":"1ZgQVl7l33N0JJB2s8KBEkQIPNFUIwDNx",
		"IEC 60317-13":"1aYTHPdPQp3ywf0DGxTNt5nFnVZcQbnxF",
		"IEC 60317-1":"1cbDp3HJyzf9TOFQvhLnXt2-iMw17s_Mz",
		"IEC 60315-3":"1dRLssFqR8s36xY5ypMSQvbsi4KuW9EMu",
		"IEC 60315-6":"1eJLXroM4iBaodSH-t1TmA08BPQvcvl7R",
		"IEC 60317-0-3":"1h_IxM1GWA2VSUpe5CeuH-udmbYXHq9EE",
		"IEC 60315-1":"1j3I0zrpIOi1WA6bolyENgg6kpGQ2n2bN",
		"IEC 60317-0-1":"1m7TyRNFkczPA9bUQ4tIrxQ_r-3O8ip3C",
		"IEC 60317-18":"1pltri35RbXL0t1Hjen3AipS4sGeFJtjw",
		"IEC 60306-2":"1s-SV84keOI2-P6AQUX9ayfLWiYcpO8zK",
		"IEC 60311":"1uwNZ1Ezo7I-VVZ2LLLoZc2ryaWgR7v9X",
		"IEC 60300-3-3":"1-UGzYUDoOjpKMF4CvQUvKSXomwnuiewO",
		"IEC 60300-3-11":"14Vl_exyiJ3YMRFtzVt7OkEkFy3axxAe6",
		"IEC 60300-3-12":"154gE4ba0JKxXqfqL5OQMut8WzVkjRNsz",
		"IEC 60300-3-10":"1BUQuDYknzcsNvNrnkngJfOfpfTKFQXGn",
		"IEC 60297-3-102":"1LGQR93zyKt4opu312_ept1Y8GJaFvCNJ",
		"IEC 60287-2-3":"1VPaNP7F10jZgQs_LmpWbGwRzHygbwHYN",
		"IEC 60297-3-103":"1WyVmjY2s6FgVsY0XYd9YUThN-Zi8P1-g",
		"IEC 60287-2-2":"1YpFkCoTeZCX9vHkRdDgetXhcZI9UB17K",
		"IEC 60287-3-2":"1cRQu5sMOJhWv-pUTF8TvZspMf_E4CndR",
		"IEC 60300-3-14":"1eRQDbSVRQewtFPrTyGaJk-E8XFMZHlku",
		"IEC 60299":"1g-CFTK52M6ls9Vy61KWwAzrVSov7Oegg",
		"IEC 60300-3-4":"1gjx3bE1Am1muhFIb8CItjN96Bg1Ta_P4",
		"IEC 60297-3-101":"1iesC1Dkb4fBNedcwxxqiLoiFi3vZpowT",
		"IEC 60287-3-1":"1lEJW5MCxt5_5E-dgnv9O1os026VeBwsg",
		"IEC 60300-3-5":"1mwsFnwvIbYb0oOS7kku7Ln3q9Va_pbTB",
		"IEC 60287-3-3":"1n0UjZQmzj7VKveqEkYTp_hq-9XOAW4Vk",
		"IEC 60300-3-1":"1sssJzN60yAvmz9OR5DaKZn5OOPr44Vl6",
		"IEC 60296":"1t0rBjxoOa-r7nhKLfnxJdUQ2OtGcHxMA",
		"IEC 60300-3-2":"1x9vOo7HjjBKh132_vaWaEEgdZ1avucxP",
		"IEC 60300-1":"1znkBMi0JnOyFkAMsu1XhP6B1yjeob09h",
		"IEC 60287-2-1":"11xhtsDxhSNBGeGGLxXZGNQ-rLGU0eCVI",
		"IEC 60268-5":"17_9YmyHkw1kYncD4SMReQ_n2k9WeshAd",
		"IEC 60268-4":"1813G-XhMs2vHdgAA02ipos9l2SJIdGIt",
		"IEC 60287-1-3":"1FHAbNIzefmcWhCI7JvxqxO367cgr3gKX",
		"IEC 60268-8":"1GnCjMQJg5_36Crj1zYhAQrVSgAbchljB",
		"IEC 60268-6":"1HZto6QwDVm8tlBXQZdEh_S7djR935FKf",
		"IEC 60287-1-2":"1KHvlkh8rHoaarDkDms0N1MUMFRmSiNd7",
		"IEC 60268-7":"1N_AbE7hyBoWQlCMIYYyf5YbtVR6vM-hE",
		"IEC 60268-2":"1QZ85Ay32L2FkjPYAj_5A8jraVa4vbID-",
		"IEC 60287-1-1":"1YsaV2-6NuYV_AAZRH0HG_FhsPT1GQ2db",
		"IEC 60268-3":"1orCIvLdCgNhpcz8_ACmiWtLmERtnlik9",
		"IEC 60276":"1q-w6f6xDWG8M5fZcZMUAQzcCpd6I75jr",
		"IEC 60268-9":"1w6g53EHH-1TSeBnawPt7Th2a_LKDq1C9",
		"IEC 60268-17":"1zTCh4iw4T8EUBko6OJs-PcmITIUL33R8",
		"IEC 60268-16":"123b06YlsBtG6invZyRC4JFOGBH5V0l_0",
		"IEC 60264-5-2":"13sjcDZeu5RNgqcg1_HWH1EV2b14KG7v9",
		"IEC 60264-3-4":"17tuzRF6RnarsC3JwlBKAEgYyDjQFdkg1",
		"IEC 60264-3-1":"1C6R1m7CqclIzQZ0AWNgX4zXqKThpRoZY",
		"IEC 60264-3-2":"1GC5dwshgCRY95ABS7hIdIz9CDsvoHIti",
		"IEC 60264-2-1":"1M0yoGfE_b-Uu0x3nXaYbsn9_Ohz5ChiO",
		"IEC 60264-2-2":"1PdnWx-NfvzEf85LOO6YENtqKRasoWtta",
		"IEC 60268-10":"1Qa-CWDWignSDrmOI94xIF0X9VF4fCkaM",
		"IEC 60264-4-1":"1XUF8_tNIz9YZ-74LlyMQBo2-lbb8FF-e",
		"IEC 60264-3-3":"1azOXs6M5HKMlDbXXQW3D-LFubwsjvEN8",
		"IEC 60268-1":"1bDsOKKXqKO3c3xF2VVjD-Z5R15Bm4f1p",
		"IEC 60264-3-5":"1g_l00ayMuPewGSFPuRRbAxYbRnwtj_e2",
		"IEC 60264-4-2":"1h8h5_Aw0GGYC6U14iGuoX6Hqr5hBUYtU",
		"IEC 60268-12":"1hVMLPBR_-erg-ejM0SFY82Q0SpEb2Nda",
		"IEC 60264-2-3":"1itpyNmhx4FoyxopIlWkbZd_XQbs7RTht",
		"IEC 60264-5-1":"1wEzeRnkSzL6QOddeFtxF2fyjCo5bbuMb",
		"IEC 60268-11":"1xlmSe2n7CwgJrEWyPnoo68kzjyYzdFSj",
		"IEC 60258":"11Wf165cfOHTU2ZW_j656yMp5IaWF6t5G",
		"IEC 60255-1":"14nbcg7iOAhtCpfTsNJ0fcCdUgCJxRzzO",
		"IEC 60245-2":"1CVPitJdh2_djXDXAaSxk1OrQuDpTJAzT",
		"IEC 60255-21-2":"1I91sR9IPuJEQmovaujoUCuqdHwOiuPAZ",
		"IEC 60263":"1KWFQwmkmwdKqHOkikObtQJiRzuFoS4mR",
		"IEC 60245-4":"1LL-VSkEgGGw__om6c2UG2BdAGZeFthkJ",
		"IEC 60264-1":"1LsSNEdit_EOR69oFxmkkXht_gQ6pAe8T",
		"IEC 60244-8":"1MwSz5fdyJ7mQnyC_AfknYiGmfI2xZbiB",
		"IEC 60255-24":"1NhRiObU83f7NapT48PnXBgvRFVKbV-Zg",
		"IEC 60245-3":"1_4U4of572cdFLrEKWmZWljhoIrFPx2E1",
		"IEC 60244-9":"1_MVB3mIroynRuAD7mzwupLZ1oBj27El8",
		"IEC 60245-1":"1b83y-lT-58z1I2tdmmoEUSnZB5f0o87J",
		"IEC 60245-8":"1cXD3pO91RJPH3AJ9pJ0OOR-jBGGr8Qsi",
		"IEC 60246":"19Zx9xOTXaZNjJL61HAQooZ9ChGi8XTal",
		"IEC 60245-6":"1OxBg15inh1A2RQXnpasLBv4K_OUkxDJM",
		"IEC 60255-21-1":"1R0e9uuZjnq-5MmS0aed92a2mgI8vsr_m",
		"IEC 60255-13":"1SGle1daya27I23w5rZMRfUP_e0C1Zsew",
		"IEC 60261":"1SjXCnJGh3SouwMVdCWdLsue6ZZ7giPDC",
		"IEC 60255-25":"1VcykRDn35kVs1tA5SvR0NTooYCyxsEJv",
		"IEC 60255-27":"1WGEcixgdzUbMHbdpb9LuLvuHj49J2Slz",
		"IEC 60255-12":"1_D8bMP1lzk8K2CHFhJuoJlXJA5WjbP9p",
		"IEC 60245-5":"1bwbc3-v5xHOU3uoDYU9YXw-SMUvPQ8mh",
		"IEC 60255-21-3":"1uImTqkRrv09sPDfUcz0D7GcQJgYV9q-T",
		"IEC 60245-7":"1zCDXbS3qfDsuFAYpD2CBvXN5jRUV2G78",
		"IEC 60244-10":"10D5kNL7WG3muzln8AehQAQmE2_05bWPa",
		"IEC 60244-5":"15wVT_BbKn5Ni4UT8oWxNMkPusS0cnh28",
		"IEC 60244-3":"19nEMKRDSCHu78cxuqnY7iD7ldFMWJjZt",
		"IEC 60244-14":"1EEf22bZisTwk5-dhq_C3c6zCQ_5QNfE1",
		"IEC 60244-2":"1OI2ovIH2L2llMDJk7W0Hdq6fG1RGwtpv",
		"IEC 60244-4":"1UEnb31xoixzFYTk401YZZDiSmJO1tTlf",
		"IEC 60244-11":"1VsOzz1MBfc93kCT4q0PGRY9iFVsL9Mv6",
		"IEC 60244-13":"1Wwl96v5Z0nLSLAF2QrumhHZeVtkMOk3m",
		"IEC 60244-2 AMD 1":"1dFTWAnSvpFFLtdsoThaXjjrQ1_ni-PNr",
		"IEC 60244-15":"1mCMnYTiAjWfJ4Qf8p-gGtjPGN0cdcxR1",
		"IEC 60244-7":"1plP5x7kPqvMn2lAHBXIt8ZFnAbDwTizV",
		"IEC 60244-6":"1uueO0kdRlfexZP07ww7P58IjDzo7glIV",
		"IEC 60235-8":"1-0EfMgwVAGGQbKTe8MuzcKkpS-I4c_BI",
		"IEC 60235-6":"1-xLWX_V1hOFdlkjEoen0TsamosWZ_2yb",
		"IEC 60238":"16dYehVUIIJwyI7WbRhvjBOdw8d_fDOwV",
		"IEC 60235-4":"17Ei2g1dIcZSNKZ-I49OeRAaTJQoHx4UN",
		"IEC 60236":"1GkNseGMQpBPEJTcIfDhdEv_GcCSJHoib",
		"IEC 60235-5":"1GpO8_H9_uBoLN05oPfmxU3Zg4V9SBSJ8",
		"IEC 60240-1":"1Jyjgu8mpiCQBXp7-GlNntOFpXE4PxQEU",
		"IEC 60235-2":"1LfV23xGseO-JfJFCs-GDrm4eJQVNYu5j",
		"IEC 60243-2":"1OWjoOXsSGXNz5LqbED3tHLChsut8xY9p",
		"IEC 60243-1":"1PUos4Mk_2kPhCOryz6hoPE8IwxuNsIhP",
		"IEC 60228":"1R6gfI7PXCKzSiHn7BoCa_8cZEdCzVRtF",
		"IEC 60235-1":"1TkiArALnB1QyPEnHFZVrJtctZsrJc_LD",
		"IEC 60244-1":"1U5KNMHd91bKHKJBNBratmaQnaCkv9uxD",
		"IEC 60235-9":"1ULSy3cCWw5BWNkJ0TZTCtgg94Tgqkrv5",
		"IEC 60235-1A":"1VjAgp9CR0mJ-vBSQWIt1rDTp6VJrNRwr",
		"IEC 60238 CORR 1":"1ep4lILsexqmvsgcRKB3bJZO82LdUoJCZ",
		"IEC 60235-3":"1erlTjp-bnL4ory9i1ancViqIw7mEWUvA",
		"IEC 60230":"1ez0uM1abhrcHSBAbc_2UBGCuqOztkxHb",
		"IEC 60243-3":"1hze3lPEFY773HOvRyk-h-IEFpM8S_aCh",
		"IEC 60229":"1loyeX8UXmv6rJRHUp5J5Z-UwXMqfjuij",
		"IEC 60235-7":"1ut1PwSPS8zd_fG3iijB5Fwd3SCM7Ckr0",
		"IEC 60204-31":"1-lahiyUkG_0pDybmlTWUJCoFdrtzfSZ_",
		"IEC 60216-1":"11E3znXzKT0TDST3rXnWy2c0QudS9NfK0",
		"IEC 60204-32":"12RIvN1tNERIebs6pJTAWeUWpiHvWsv6t",
		"IEC 60216-3 CORR 1":"1302H1f_cBytEfPo5fMu-8q4_sdCoptMH",
		"IEC 60216-3":"151hxqsIpKnq60mi2RWIgrsu626jkpMwx",
		"IEC 60227-5":"1AaMWwMMPusR1y6BaqdjKQrNsulOlWib9",
		"IEC 60227-6":"1BpS7Mi8LVPVKm4GosJE-Ua6t8krTcbwR",
		"IEC 60227-4":"1HTgFpY-TA66dFe2y3ZZM-F9E1NE_KiAq",
		"IEC 60227-2":"1IBRlqDj4imTXwkKeeUlpC6nhRJbvDIvw",
		"IEC 60212":"1Jljvw6lfeRjADzSTUSbuh04N0LcMuH4q",
		"IEC 60227-7":"1Kgs1fRFqGjysCH6a8bjm3jKkulD7EiWc",
		"IEC 60216-6":"1LFzF-nW-PSPtk2Ne-dB5Cu9BCMFRQOgU",
		"IEC 60216-2":"1VmzG4Y5EACWONxGxX7V1Xk0ugVChGsBz",
		"IEC 60214-1":"1ZZAt-yR8s97ozB4Va_u2_k2nOx4BpcfM",
		"IEC 60227-1":"1amUU6MHRK82e-XTF_yr9GJQR2F1nMFn1",
		"IEC 60216-4-3":"1dls4qlyWR8IXjl2Fjs0U_ipbVnJhMNp8",
		"IEC 60216-5":"1kDsRuq9EtgHKsNViWhdgqld45XAzGB96",
		"IEC 60216-4-2":"1mCSR-7AI5yMh9vGeKf7CHRXPkvlgED5E",
		"IEC 60215":"1mQPiwvuKECOYAPYjj7QU15uwXfJV9Rdm",
		"IEC 60227-3":"1oi4HsviIUadMZ3C4PvRa4PstpgJF1S7J",
		"IEC 60204-11":"1qWMrXcjEkDWra1pHhHMk1ovwP3qZC8mK",
		"IEC 60216-4-1":"1sJ5vvbOSx24slc_s7cj24eYVR1rvItjF",
		"IEC 60214-2":"1xhLXAK1QIgDKgpeILS_L_IrH3g4SyYky",
		"IEC 60191-6-5":"1A81yENtsOra9TMwtlJlRDq5O0RFhfo8J",
		"IEC 60191-6-4":"1G1twM1dfC2axLJqDrcjwIfCgurgcDgCJ",
		"IEC 60196":"1O3KetSJTJE7SgFtZt_TymlL2c0NikqEZ",
		"IEC 60191-6-10":"1Os11Xt0WpzWCAbyzPgYhcuuxc3rbRHUu",
		"IEC 60197":"1Tcz1kwmAWTIj3uE_q-LN6Gp6zMWr0Sve",
		"IEC 60191-6-6":"1UpurGHhxs7bJaxq9xups8jZSLfTNC15g",
		"IEC 60204-1":"1WgDCXKYydPSgCD5jnqeN5vLQ6zvrc6qh",
		"IEC 60193":"1ZWmPobB9oEiAIR_ZW4sMhauor5lwQw2N",
		"IEC 60191-6-3":"1bi2aKbmRld2FYMhCzdenoquUU1Z_vBBO",
		"IEC 60191-6-8":"1fny0vta9z6wZfUQXOK14USdTpg445xlG",
		"IEC 60192":"1kQngBDfQao16jy7D5WJzTkHdKDjIO7s-",
		"IEC 60191-6-2":"1rFvvdljsE7jKA-JYZShuDCkBh8vuoUel",
		"IEC 60204-1(REDLINE + STANDARD)":"1uDzb1y9UV7FaBxuPe_Ean6gCOvwUTVqi",
		"IEC 60191-6-12":"1xm_oDdpd5LJp1AbLhqukRBsvmdIycQD0",
		"IEC 60169-6":"13CTtfwkpLeQp7bHy1UR3kCAZDgP91NaF",
		"IEC 60169-9":"14d2iWi_4-2l6FN5w8ScoQ6kFvCbgyGgB",
		"IEC 60172":"17frVfzVlSpFbcdzN_tY9bUMN3OgcCtxh",
		"IEC 60191-4":"1DUeT3zkaujXrsVQbJ4CknZsn63UfU9SJ",
		"IEC 60191-5":"1EdqB9rjWakJmSRcYLgFgOyCFt1AWQpcq",
		"IEC 60169-27":"1Ew-aeBt8VgKyffZpI4KrtW0R5IYYtc8c",
		"IEC 60169-3":"1L4ydZs7NudzV7npn3rxrPdSD9kyxlJzM",
		"IEC 60169-23":"1MxpFsuaThvqD-eCBCp5CUBZfSlcIcHJL",
		"IEC 60169-7":"1QH5Hxr2jNXzvONmq7pU-6NvNKNah4m0Z",
		"IEC 60169-22":"1TK-PdGNEXfPT-B9fTs2EyojyRPaMmcpf",
		"IEC 60183":"1YFQF5InPnYjhmSCR1A38elyOcFC4aZlT",
		"IEC 60169-5":"1_sBAEafXKR1AV5X_ciam-OdXYD4wgIm7",
		"IEC 60191-6-1":"1k225KA-u-dIYWmikXA1DjmIrNBfmpmye",
		"IEC 60191-2":"1qEjT39EVeiPZqu1hHbxpbfkwAhEkDUTZ",
		"IEC 60169-28":"1qUqgp2HvS6GaE1zj78tCvp5fgY7fmwJ-",
		"IEC 60169-25":"1rkZrH-4XQ3Fn4Q2bE5zFxtNgPnSjrC6x",
		"IEC 60189-2":"1rpROuHNFQTvJ_ccVjZG1CSHnLi1Sh-rk",
		"IEC 60189-3":"1wNBKSfhekO0AJbciEvjZ0ClLk2fUsMyf",
		"IEC 60191-3":"1z2NWtcpx-WI0UZT9wbo1SZj71rXfg0tJ",
		"IEC 60191-6":"16GSLLSqBLCYHgrWRX7zU5PkFX1wUaI6A",
		"IEC 60191-1":"1N-vSSREO8vBRMakvbNqbxp15iUTUVa2H",
		"IEC 60169-21":"1i5qRLnFmcjOWSmR9p5jxRPHjVWJYATj1",
		"IEC 60188":"1j_ftRC3liXrxLSSa1K8oRyoyn3LD_Y7D",
		"IEC 60169-10":"1DB78rjR5sAqUewdVfqrRy0MrM7nGbb0b",
		"IEC 60154-4":"1EgmovKaWo_I6Wx4eZLSTOuE85Duva1tO",
		"IEC 60169-15":"1JYqC2HGyHbmHHw5GaTrBKWYNb5YGNnS5",
		"IEC 60154-1":"1NnMLgEdSnwqUKCR4vTCPcNhEiXZ5je4X",
		"IEC 60154-6":"1ORm2CFU3RXoCus6vGawWlX1FOmoHN4WV",
		"IEC 60169-1-3":"1PRWIoQySgM5m3EKmtrr9XwxgySA7PxU2",
		"IEC 60153-7":"1SlJfqzArORuYY2eL2319flHGltNxKlOw",
		"IEC 60153-6":"1U1Hijh9nNi7JkybLNidDMVfq5YS5yPyN",
		"IEC 60169-1":"1XC7MDlPVB9H36LE2F-5G121rVzUQbdLp",
		"IEC 60155 AMD 2":"1XuQinyUSKgMegx8PDLizIe_d-VjjmkUC",
		"IEC 60154-3":"1YJaLl1Xr6cChD7Wbn6kwTC8uwqVYBR1t",
		"IEC 60155":"1aWG5rPryovRFsgQUAti0claEubLOhXaO",
		"IEC 60169-15 AMD 1":"1fC-AeTxOuGkrY1Rm-kWdxCyxbA0pHGQU",
		"IEC 60154-2":"1fj5WzG6TwkJN-P56A5tt8zsKeuZcRJ1b",
		"IEC 60169-17":"1gu-4ELGAa9OLipyFaYfrM7bSogyWpOzM",
		"IEC 60169-13":"1j9W0IIi-6w2WBgN-1Kd_TZMnRKe4Yyjm",
		"IEC 60169-11":"1pA0vvIDeTkj16pkVDk-TthoQsbxiFald",
		"IEC 60169-1 AMD 1":"1rCv-DL_Xk0pC-di5z2al_CkCUC8llwiJ",
		"IEC 60169-1-1":"1sYTlCEiSffmkZmauBtwEF9IoMDO5rYAA",
		"IEC 60169-12":"1u-mzdfExTyZrIWwGoIf3VQj89wehbC75",
		"IEC 60154-7":"1uZWgTc6_Sd8cHC0cPzSsaHZl5B73RmrP",
		"IEC 60169-20":"1yW2Xqv8RHjKaeHb183WnlxcS6Tt26-5j",
		"IEC 60151-26":"10-aiSuMsWjEKYUWF2I9MRpihR213l9mt",
		"IEC 60151-5":"10X7MbRaC8qSCEaC-c_EbVRCQ5_E1DTzx",
		"IEC 60152":"13vz_KxlbPiYonuxz04HcPBSPiU0xyF2n",
		"IEC 60153-2":"15V_ctkULR2xO7vmeLNl8PYI4yeAbtglB",
		"IEC 60151-18":"1AnEzd8vcbudFtCx0VkHzFpxkfBxVLZ6I",
		"IEC 60151-23":"1Bmfas8h9D__gDNmhOoSVW4GhOaHF5Gaa",
		"IEC 60151-7":"1CxOQVVVIQNJK7yBLLRTr9RfW2RmOncYu",
		"IEC 60151-21":"1F-LF-77cKZ8dXq5UnxZeqaaxPue4W3Tl",
		"IEC 60153-3":"1JN7VbbHZRXYZ3pfYhwPNJsrE4e3AsjCZ",
		"IEC 60151-25":"1KF_iowIxkR-AM89_x0QSn2oQIf4DZdT5",
		"IEC 60151-28":"1OqISsoL4DLXCLLIzyk6eiiH8fTXk9E74",
		"IEC 60151-20":"1PeIDIW7_wpQ9S70Zoaa6CTiqdbgS4joZ",
		"IEC 60151-24":"1RKWuiiCmvQf1fT-lNC9hOQ1bZQrUJ8nm",
		"IEC 60151-6":"1V6mVlA_3S81y2Wv1LjP-jSYKuRawxTou",
		"IEC 60151-3":"1ZVknegW5w4RsGKDdrt7tj4TJff6h1gMs",
		"IEC 60153-1":"1_j4UkW1Hzhsx058ashKmQ9Fol1BUMX69",
		"IEC 60151-4":"1co2NeH4rSZbfWffeMl5UWR-_COiwZbja",
		"IEC 60151-27":"1eijJWbUOErlPYULWfhFYvOlU8FbyVgoR",
		"IEC 60151-19":"1hYn3JY4SfjMwrSka24QnhCiOItTcfnvh",
		"IEC 60151-8":"1oy69_TrJt9AAqEYhlv0mi6LF56tMci1n",
		"IEC 60153-4":"1p-MYCwR5dwjej3OhiDTqlp_7nzLyrjg8",
		"IEC 60151-9":"1pqa8rkv5I1RsFab7U5OlnN3lzLTE55KW",
		"IEC 60151-16":"1rqozMgN7cPixhHLKnfooj10D3D1VXgEs",
		"IEC 60151-15":"1tEfzI49BDrLvzsVoCVWV4KMMJPIIE_-W",
		"IEC 60151-17":"1vnLaUqkA0GD1m2nH7scef241q2cJGnXo",
		"IEC 60151-22":"1wRG9GbLwTgEBowe_M3X_Y9CHp4JE6NOw",
		"IEC 60130-5":"1Dz-2mvIbenrl4ACGgF2X_JgZCGtQpXkw",
		"IEC 60130-17":"1F7SL4_nL0kzjRUp2KuKTZ6uFEp1DFb30",
		"IEC 60141-4":"1G7haMmxyP2A_4I0_PsJSu45rzoJzeRin",
		"IEC 60122-1":"1HTYSed3O_RTfBRS35LOEi4JPRnvQa0cK",
		"IEC 60122-2-1":"1Ig4ICQpe9-AsSQ5MeCcAcnuDKxamYIvJ",
		"IEC 60151-10":"1KOAVu4fYq8d_tTKFyh_cIFsXQ9cG82Dq",
		"IEC 60130-10":"1NKnZIEFmFdCJ5NDpyJIQHFM0CPwdSABs",
		"IEC 60136":"1PEM5TizKPr7JifsbmqpXtmY3Z0tPUwTi",
		"IEC 60130-9":"1S75tkFHgupUJNLFWAB_sgrI-L8MQlj9T",
		"IEC 60151-11":"1U-s5JmFwJCorRItnaBKPi7v_RHK2iVY_",
		"IEC 60146-2":"1YH5S3CGUw9ey8F5deMJ6P4ZYThA_pLje",
		"IEC 60141-1":"1ZAfZB-JR-y1gubfIl9UVaAdH6RdmTGCC",
		"IEC 60141-3":"1Z__ITskkniokxwLKn3aGXtRytrFlv9wm",
		"IEC 60146-1-3":"1ZjTTms6LBuyXoO1OcqR0jkNihWEErL4l",
		"IEC 60130-12":"1_Sv8GDrNEZcAt9NVli4v-jC12Uzg346m",
		"IEC 60151-0":"1_e4a80yp3fMkpLlXlypx8rf6aP674Vzo",
		"IEC 60139":"1d7ox3xS3_R0JMGgw9dQSAP6GRN53InQ2",
		"IEC 60151-14":"1eYLKn8kaUF65vxSuSZt9-prU0SEVPgeZ",
		"IEC 60122-2":"1gTYumnJ0pKLuLZh7AqzEl-RloE4Fg1L2",
		"IEC 60135":"1ntND16iZOKsinU_ayjK6xaFicQbKYP5m",
		"IEC 60122-3":"1pnA-H8WJrC1G_uNBFTJHRePwzdRbuo95",
		"IEC 60146-1-1":"1v7u1D3Q1uyzFacVQKWJwV9DCKf06QCLf",
		"IEC 60151-12":"1vt6eOk1s1QRORRDZg4O9zIo2eB-byPaR",
		"IEC 60134":"1wIxZsU981pwcnOHog8ZeEzjxU3TLsJXY",
		"IEC 60151-1":"1xJyA3mLm59BrwnBnIlYRQ-dcMGyOTnJL",
		"IEC 60151-13":"1xat4yeN_MPOmefqRCdiO835qQjlo1sye",
		"IEC 60118-14":"18-8fmNRBHEqfk1iE5Rt28JHUxRVCgF3A",
		"IEC 60118-0":"1BioAjNib8NGscmpC2KLV3BLQaLbAjQz8",
		"IEC 60107-7":"1EO3F8iHfFr-1oEdn3Km6luYMhaNr-tH4",
		"IEC 60112":"1GgG6y2gcRi8AtZ1Vur_37UE91CrP2-YQ",
		"IEC 60107-5":"1H7ZPwSDn-PAEUIajcp_hqAdZn9duFQug",
		"IEC 60118-7":"1I8Y53BYn70n5ocRVmhsn9HqERx3WNU70",
		"IEC 60107-1":"1P7QP6CtEwD3TXa_VsQVYgRPAfO7QlGZJ",
		"IEC 60099-8 (REDLINE + STANDARD)":"1Q2aQw6N9LU69bOcWrdTH-33glVv04OIY",
		"IEC 60107-3":"1QUw0qZCihg-x_bjCPgGiytj2F7alJdq7",
		"IEC 60107-2":"1RxqoVf7hxuZjI4TK7Xd7b-PgCYGmTYiV",
		"IEC 60118-9":"1UQeUbEmB-tlRbo1Kid29QCpvYVEBlhZV",
		"IEC 60118-5":"1Zk7BE7HLi7q_nEvf3H-RtxdkzCbDla9T",
		"IEC 60118-8":"1_gBhwv9lCo8dC6Mr_IzqZ8gTa5umBpNx",
		"IEC 60118-13":"1cFSynAXBahc7o0ocQ1fgqa9jAY64NBL2",
		"IEC 60107-6":"1e29FgfUDxJI-ESnhlpKzxbncfWBcCoH8",
		"IEC 60107-4":"1eBtjDVMAV2hQBBDyN51fxpqvDY-SR2jK",
		"IEC 60118-4":"1ijVSpA_lD7IDRPSwQgQAE6G1jbn0rytW",
		"IEC 60099-6":"1qPzvwpEXuT2GDBvDF6cogw9CTjL3rC1p",
		"IEC 60100":"1v9XizVNLx_WIJCk1GvMp8h_SHoZKdz1g",
		"IEC 60118-12":"1ysxN6gcYSQGloIb2gEDahwEs2QmIa_uf",
		"IEC 60098":"1-XpMGRnsLdrO260fOVuruGNV8L14GnF8",
		"IEC 60094-10":"1m8HcEq-ECY1v5aEW3zualapA0HxvZL2b",
		"IEC 60092-501":"10oFFwlgzTds_rq5q3WLrgGTAiXuVpM58",
		"IEC 60094-7":"11iA83SPPmfOiHgX2VuMwsMUbwhIaadP0",
		"IEC 60092-507":"16TFO52DDogE88DWMMK4iYfzLepaa7JaF",
		"IEC 60092-506":"16x6p1cI-Ac3SLpWXY-sQST38NnEW4wWR",
		"IEC 60094-2":"1CVYP_oEGtlntrCTJJKaPHQGciC7oPNiX",
		"IEC 60094-1":"1EumT9Bjcg2Cpbi05rHoEtUGENdLDVd24",
		"IEC 60094-6":"1I5KXNhYwD8bfZ2Ql3jdtokEziVnIG4Yc",
		"IEC 60094-11":"1UDmmVoCfRzxNJ2Opym9VkBIHANPMTUBS",
		"IEC 60099-4":"1VfrluW10PgLWSuruWwOjZleK1HQLxZok",
		"IEC 60094-5":"1YZy3LOKKyHNOpJ5GhvCxEYBkaMBoc5UK",
		"IEC 60094-3":"1Zn3hVEqF8-vkOg4cJCb6_iEpG2ZhrAy1",
		"IEC 60099-5":"1a-aiYAkNjJFoz8EBwpSxr77JYjfn2kjA",
		"IEC 60092-503":"1bpfmI7IbuPH6L4L4Um5R0tKjW4ZnnAfW",
		"IEC 60092-401":"1dfcfwA91o2q0HPt850_mgQWUajiMhnog",
		"IEC 60094-4":"1hEEId45XY1LxKIaadGLPK6c7A2Sf67rG",
		"IEC 60092-502":"1ldCNM2Zf33G1DbYefchI42dW-wGUFBeQ",
		"IEC 60099-5 (REDLINE + STANDARD)":"1xJnhPrjAX3sD7QI_G5hNfdU6HsQCBmnr",
		"IEC 60086-3":"13mzftJOqUL9Qe1yJtMAGNPOzbx4-x5IT",
		"IEC 60092-350":"14stI0i-bW1xlqQy60tRP-gwwPMCuCNYn",
		"IEC 60092-353":"1CVsIZKoiUV4DFpFENpjaGMqyM4SIAORy",
		"IEC 60092-352":"1D7Vk5Et7RkC1ntNSrJJy7FGJCwfQ3VDk",
		"IEC 60092-202":"1KoQTasHOhQPVS49vW8pzIJMyZsWZa57K",
		"IEC 60092-354":"1LLMk9fYKsWpSse171n7YJsjkiOYOamCv",
		"IEC 60085":"1Sr0wGQs666-U7sKzUV-PTh9FfF-MrNue",
		"IEC 60086-4":"1U3vWzrqw8aLXmfDISzH0e2CSx4sFu4V3",
		"IEC 60092-303":"1U8lpyoWFw92JpKA7BSVfz_vSItVZkmYw",
		"IEC 60086-2":"1UMS094-Dxv0fCZxp9xsR_gL-4KawN8hc",
		"IEC 60092-307":"1_pINQz-7vibsgGZvPfF_Uf2jrcqdxZ1Q",
		"IEC 60086-5":"1c5U1ZQbg3o3ELORVxBhSBw26QsipFB64",
		"IEC 60092-302":"1gLZ0362jFhn-x2s2jHrajCZ95fg0UR_Z",
		"IEC 60079-7":"1jo2ctKy5iPmT-2cBYAzGknbgz4eyB2PE",
		"IEC 60086-1":"1nCmGsUE2YtY1HD3RU6jjJwWv4sMJs5vp",
		"IEC 60092-201":"1nFP4Bu1He3xSaEnC63nsElJUZwXdbZVd",
		"IEC 60081":"1nFxGxGGgCQhjhZE2LkKUKyecjj77oxEr",
		"IEC 60092-376":"1qGilWNLg3oSkSqaBHcfi_G52Wgf4UOne",
		"IEC 60092-305":"1qraUb97Qcsj_9prFVBIvlwdoOkYHoGPD",
		"IEC 60092-304":"1v1nYD27lAYqxR5TzwrHZlZ__dHK3gM4_",
		"IEC 60092-101":"1wUGtVKWVDq9lFX5t0t9yy2mufQca-nhz",
		"IEC 60092-306":"1zY9Kq1hWyy3w3beQI8T3WglgfLHQ2-2_",
		"IEC 60079-2":"17ykXbffqeRXej_ubR__vy3dQcCui3pAO",
		"IEC 60079-25":"18PY5tviaxrrVHifcD-C0V0ADKoIUZORj",
		"IEC 60079-29-4 2010":"1B4B8Php95j_yYj2YRK82gtFZng9xD2dN",
		"IEC 60079-33":"1BlrDOwe3VJ4s6zk-E4jPEwa7udXoqMSW",
		"IEC 60079-28 INT 1":"1DemDjCTPy1oLPXySI_AOHTctKOo1r-vG",
		"IEC 60079-29-4 2009":"1Dt7yCIONuXiODtMV--Ih_2x5JT6DaERq",
		"IEC 60079-29-2":"1Wbux9cEZQQR7B4UVABu4czdcrrxSbxKP",
		"IEC 60079-5":"1_t6SCEVPyxo3Y4U6bM-X_HiijQleQHWm",
		"IEC 60079-29-3":"1aw88KPp9cVGqLlVy5Sjd79h0Y3GN3uIf",
		"IEC 60079-6":"1mAtrjNCf5OmjpLk5rh7pOgpIP9rgw9k1",
		"IEC 60079-26":"1n8Jx4tNQ19GYTDnXbloEctK9318fFXrC",
		"IEC 60079-28":"1opmXRZruOgevbyr7XWPmrPg1zyABB2HV",
		"IEC 60079-29-1":"1ri4qlsM0pWCbkYtpTJWNSvf8h1wgs6dl",
		"IEC 60079-30-2":"1syI0sCv2yTe5vFGqaEinKSMx1nP8hiVL",
		"IEC 60079-20-1 CORR 1":"1vAy0k9z73WYqUqRNAO5EVpwuyyBCvvZO",
		"IEC 60079-30-1":"1wfR1vmaRP3kSN2DN4IncAxvqzsSh2toB",
		"IEC 60079-10-2":"13ysPMlWG9WQxnBhS6lyZacg1EV5AAdSJ",
		"IEC 60079-2 CORR 1":"18XBj7hem0UB5FTtq1DGzmsQhnsDeoRUZ",
		"IEC 60079-13":"19IPZYKG9BdChMOHcZ_ff4OOC6X8qUe7k",
		"IEC 60079-10-1 CORR 1":"1ATlXpE4GKpXNGrPNY2OfrAKczjoLVjGe",
		"IEC 60079-14 INT 2":"1EGPFl0hOrUCf3ASP8vhjYwuUiwKDwBkb",
		"IEC 60079-17":"1G8Mmh5h1ulIE4Ylco0Q6LpNZYFuitEXK",
		"IEC 60079-18":"1Hp0G49vZgeDn15NL52QSgC635fOxXq9i",
		"IEC 60079-18 CORR 1":"1JfkbMLxf30UG7RbSz0kbH4-jhbI9k4O_",
		"IEC 60079-1 INT 1":"1NPzUqwo6udB1rgqi5j1uRkwLhICMIFca",
		"IEC 60079-10-1":"1TJJFUm4jRTpDRwlsxtBcPEBaz4lWwWEe",
		"IEC 60079-15":"1XX6dmoY2fKORIuZNdOpQSf5BFPMLPqnQ",
		"IEC 60079-19":"1_WecOo7Zi8Xqv_8KkUPrWrc59xxnenUv",
		"IEC 60079-14 CORR 1":"1cGk_8FKKC-AtzwxUF7lLAxB6V5mZeUjI",
		"IEC 60079-14 INT 1":"1cax8tqI-wa7dtZyyzj8TvnXEa51_ECuz",
		"IEC 60079-1":"1lW8K3RZ-xNqsB-kDdkXUKcVCkExeJ_Fx",
		"IEC 60079-11":"1nrGqTT5lhzrCSeNON-1lHJej0UWR4fJY",
		"IEC 60079-14":"1o0tKPIOMLtVczICwZyrIYKlRO6p-Nb-K",
		"IEC 60079-1 CORR 1":"1oOoJgCsHk94rmi5Aifrmw-wsZpj37sAK",
		"IEC 60079-10":"1tKAKCPdLY-PXZrmo6PvIaZ_AOJinb7YK",
		"IEC 60076-5":"10kSDENJE3cIJWa_oJmfXu0Fd-J-4pbkY",
		"IEC 60076-10":"17iZ4q_23rFqkCuvmCCyVLm0l4CdR1dnN",
		"IEC 60079-0 CORR 1":"1AybEVilUHXNmTBhCAb-1S891Gia09X58",
		"IEC 60079-0 INT 2":"1DFXV_OrE28ClplzXNY5WSTLl90YGa6iJ",
		"IEC 60079 SET":"1EXT4Aqd7P8Hk3OlgKf4nD6HNAnTN5HBW",
		"IEC 60076-7":"1FpOO9qAdoiSq9U2lsg3fXb0Ayfda7dQW",
		"IEC 60076-10-1":"1KFQHrTS7YutK9FvZcLjFOPBqZ1GARAlI",
		"IEC 60079-0":"1O95aKsreybtLlXMY6xbce3zl9cWzb_tu",
		"IEC 60076-8":"1_lwRKeBz3I7HVv252LpLVYRgJOAugRgt",
		"IEC 60076-4":"1ncllje6miC02Pt4WuAdfqmurORchqGdV",
		"IEC 60079-0 INT 1":"1ngIqMO7quNu0Q3ap1YRFdPra3Y8aSWfE",
		"IEC 60076-2":"1r2-UiKdFSD2H15NvJOIwHziUGH_pS8Bd",
		"IEC 60076-11":"1sk66Z9XQzigbBc5yQAMMrvSGX2q8Iasb",
		"IEC 60079":"1sqih8qsZnknyWTY_oEaITh_yTGswGy6m",
		"IEC 60076-11 CORR 1":"1v2NCjyvlk9mMAQbYhdAxcDPX9xL-BjLN",
		"IEC 60076-3":"1yUKmJ4FB1p5Ei3e4KFYJ9gENVROFM_19",
		"IEC 60068-2-61":"11uS5bihDPHZ_2FlwL98eeWRSYgIHUczu",
		"IEC 60068-2-7":"1Bok0VtOQNe3ZdirJr6-0aTksmCRWGofp",
		"IEC 60068-2-57":"1MbbYcrAJR8ZUh_4bQ3xXYBNn9FCGQUr6",
		"IEC 60068-2-5 CORR1":"1O6Yk1vGJXdNsf6cYxXBOSn5-MYrx3uKB",
		"IEC 60068-2-49":"1RIRD7q_E6IHPBEW4vUrH3Ie5DCiU9nk0",
		"IEC 60068-2-47":"1aYwDQDxHphuCXhiWkkp1XKJ4YEbOE-CZ",
		"IEC 60068-2-65":"1hB5arBy15thDRxkkDUuxeI6jgeCn_RJO",
		"IEC 60068-2-68":"1igPg9wPEwZAG3GMOcLm5H_DAd1X3wjR9",
		"IEC 60068-2-60":"1jdDPiJk5PB8di4wYbgD38k-DgdHnYQnA",
		"IEC 60076-1":"1w-WfS7Tx69gDJOTEZoOEaCriswhyOrZ8",
		"IEC 60068-2-7 AMD 1":"13p8DS7A9sD-93K8nfO1iumNxaErOjeqC",
		"IEC 60068-2-66":"16dnTyvrUUJQAnkkoDtL_LSXeLwr-itKQ",
		"IEC 60068-2-52":"1O3hxNT0n6hXAg5KqYU5e6KdgPpteMGn2",
		"IEC 60068-2-6":"1V1djayueqsbCwO-PXmraq9gSPy_NtXP5",
		"IEC 60068-2-7 AMD1":"1WwJb5BQGFwd__Eg3AVrFsV9StmvMZkTD",
		"IEC 60068-2-67":"1ZOFp0dnMKWWWIwnbom_zrXxru9IZcAWF",
		"IEC 60072-3":"1fSqUwb7juvE71BHHrZNSxnqs23f9g1kZ",
		"IEC 60068-2-64":"1iDUtCPm2WrLPUzEgWviOn0KNOvnsvgpK",
		"IEC 60068-2-5":"1jcnuNoSJv0M141Cr9YknPQ0BZRC3FQL_",
		"IEC 60068-2-55":"1pCPIJ2i0DYDXqrdpnG8bLMi0XsukUtEJ",
		"IEC 60073":"1pCnlcTrz2m_pI48l2hSYpd4X8JbNzEPA",
		"IEC 60068-2-53":"1x2LBSGbv1GKiycsk3qccvruoX5a2NK4X",
		"IEC 60068-1":"1-FAlxVEkBHK4gac6WANrMJRYq3AD_BML",
		"IEC 60068-2-18":"15lrX2S1ZjvOWPD0Km-Swtdejo_2e3kOK",
		"IEC 60068-2-30":"1BwBuVoMAppK3XZH_XmJpfITbjbz4Teo8",
		"IEC 60068-2-13":"1GYoCm9xiUjgTC8aZV02l15h2DE6HLywr",
		"IEC 60068-2-11 CORR 1":"1SM4MVW7-rKbiDOYKNiigryLo3-kzkgy0",
		"IEC 60068-2-38":"1WVOHCKRzqNt6J9qiYUzgUOD4A0Z5flDM",
		"IEC 60068-2-40":"1X7j70UkqdJ01sGKTSvExB5XFgN8AuC9_",
		"IEC 60068-2-41 AMD 1":"1YKkjLcQ9R6TayP-rFIjl2MYqV3MskqyU",
		"IEC 60068-2-10":"1YdBHrDtTeguGzT0n0iu7IYyIYyG5cNXs",
		"IEC 60068-2-2":"1ZYHVIadUUtc6jqS3R4u4-XAaoEOsf2cA",
		"IEC 60068-2-31":"1bR2JvFGLKSqRNWUiLTriqS3xpYH0jCRH",
		"IEC 60068-2-45":"1gViHyTAkWn27BaPn0IUMnyYYLVuq7sPP",
		"IEC 60068-2-40 AMD 1":"1iADwMOQL9uYSY58GV-xkgpoh_qJ2tg7y",
		"IEC 60068-2-46":"1jfrOfdOG1kx5TnoUbFXDzTa_syXcevEN",
		"IEC 60068-2-41":"1migSI8eCTcO9965yiIVRYMqov1zUgjwy",
		"IEC 60067":"1smqcmOzct4wYepYxALdVQeQYuSAwi06j",
		"IEC 60068-2-14":"1u6ljku29byNg6Zjgz7si3VYY9unuwB4L",
		"IEC 60068-2-11":"1u_Q6wp57UgnxdJ4GpFcz52rOBxz4mkUt",
		"IEC 60068-2-27":"1ulvRYawUXmNasT6seszULWarHe9xhtee",
		"IEC 60068-2-39":"1wM2jr-p5zxyxjWFJ-Q39fk6RABRr8Hi1",
		"IEC 60068-2-1":"1yTTakomY7kg3QXksCelvw2K9LnnuZel-",
		"IEC 60068-2-43":"1yWah9nbs0Hn7nuAWIokXNWAz9XrLGnYf",
		"IEC 60068-2-17":"1ymP5Dn5PmE3HkPb62wDRedJAh6kPzxiU",
		"IEC 60055-2":"1-WJsPJsbNZOd8L-8gdTcQQWLxBkNb3cR",
		"IEC 60061-4":"1F3Udu54z6iLm5W5wynEdXYBARUZl-9Nn",
		"IEC 60055-1":"1ISa0ogaPqRUVLUNn3BQWe-3Lrag2-6se",
		"IEC 60059":"1RyDBCA3dfBOewei1k8qW8TY1y0qbE6LR",
		"IEC 60065":"1VH10WJq1EDmRvtDhy9FhxbjUYg4wZb-N",
		"IEC 60061-2":"1Xo458WGc51hewm9OBH8KIfs5zqXIANgv",
		"IEC 60061-3":"1YWFfgCE7D4eEnTvbH54jHeymfg0GbkL2",
		"IEC 60061-2 AMD 54":"1ZDkmS_IAbSswzwAKl4EEA-CDHreZLJqp",
		"IEC 60061-1":"1a_mPgF_5WwjYET52iQnJwzQWqvFixcSO",
		"IEC 60061-3 AMD 56":"1cqXeM1KPUtVfQ9zZMYaadS8scUUpFpMf",
		"IEC 60051-9":"1lV1UiQIBamluZe9DTU51Eqd4Q7yU1xZK",
		"IEC 60051-9 AMD 2":"1vOfucmsYwh3Zzf6YywCF3pzxIjXq1M9H",
		"IEC 60064":"1yWgj_63Fd_rEPDXNIP6FRH4wQ_BtF6N7",
		"IEC 60051-4":"1-knNMgw94eJBruCvbbdXrjS2o8eJbQPA",
		"IEC 60051-1":"16ZDHxgrkg9jWG91S9whwlOFJa3ddAeLp",
		"IEC 60051-6":"17sOnXAvLoEMHe5RPU_RGe-9A5ANCKNGI",
		"IEC 60051-5":"1F4_3kIefwkBGGc8ScDdnQetecKjrWQt8",
		"IEC 60034-6":"1GbO9A7Jlm3BEkK8iBWxxK39ofbUv4r-T",
		"IEC 60051-3":"1Kj_ndyJo-EVxSPtY7BQw_cqUH99SFD_v",
		"IEC 60051-8":"1MuaUYFv0FLylFFXx49kh8GA9eIoh84ff",
		"IEC 60038":"1RtTL6ZanKIiX3OzEVDGQNoIiwxxkvBAy",
		"IEC 60034-8":"1U1xHdu3nyI0CRDF5qavfgoSBhMpdzmuw",
		"IEC 60044-7":"1WjVu_rhr2376VmYpQO0eQy8w5zZ1KS1h",
		"IEC 60051-9 AMD 1":"1YN-zhY4Y93UbuazvnUe8sQZxsJ9E_l5z",
		"IEC 60041":"1aJKaBC7nVT05NbzPq33z6ntpQOnKoVC3",
		"IEC 60051-7":"1kZC8jPBJ_MPOJcxfpJPPjfq6hrJ7uiS6",
		"IEC 60034-7":"1omt2cGztplgufTwy7sBh2buVddMabo6H",
		"IEC 60051-2":"1qNfbzQkEDqII24M7RRgIa25tcAID_OI3",
		"IEC 60044-5":"1qkIrEFIpwQTa0Lav9XHoaa2LElc577uq",
		"IEC 60044-1":"1vJbkia7SpEBrHU3ItSSp908gTGQK6puE",
		"IEC 60034-9":"1xH5r6sFmxSrK_dFU1q62zv7vh_9Z5ait",
		"IEC 60034-18-31":"14maKPdtdP7Tw3DDInFPjuqbBkh2nV9rs",
		"IEC 60034-2-1":"169vngtpt70zXrY0wOD7Bc2QCuDI27d6K",
		"IEC 60034-2-3":"1MXOYEBmS_5nkyqNfSNqkbRD9gYpVZmHE",
		"IEC 60034-19":"1NfBR6Jtv5cr7dhwWHdddda7A_hDQZC9a",
		"IEC 60034-5":"1NfQueSICTJWVJ6Dv1PssOqLMsoRRMC4m",
		"IEC 60034-27-1":"1Nhc66bDKMSQqpAglPjjocoP0QN4KyDT0",
		"IEC 60034-4":"1OzwV3WLy_8Ij0G7hhjFrdhEgizMePou4",
		"IEC 60034-18-1":"1QFdGqPfctK3NtmdiRG2ziA1ITHXeLcMb",
		"IEC 60034-18-21":"1VedtfFcxyYbGJqUZclTsZkhezSyAzI_H",
		"IEC 60034-3":"1Vvl7Y_4OQrkedex0ajb2O3qVEG2MKLvG",
		"IEC 60034-2-2":"1YbUzMcBsry8GNmXVAECaFAySLjFDnhCP",
		"IEC 60034-22":"1buLIpWEH_EM_JNPJli_IZMEWRC3ip-VG",
		"IEC 60034-27-4":"1jNJ3XFejlB8AKk2VhMpAyEHG42k8I23w",
		"IEC 60034-2":"1m00jYvRwlchJChfoc2mCSE2qMyc3MTQ5",
		"IEC 60034-27-3":"1s6TZiIVcuYwjXJOHlg7vifJ4i7_9Yt67",
		"IEC 60034-1-2022":"12bNzjRgydMiQy6IkO0NzeDfZMhAr2kMQ",
		"IEC 60034-11":"15CrRFr19CSzQ_wV-Dh7YNdtHWtikK6kg",
		"IEC 19249-2017":"16LX8fkHafoIRn92rvPCyZmY2Qvfh87Oj",
		"IEC 60034-12":"17nyzan46XIXYG6yphuwOim_83pDcTvNF",
		"IEC 60034-14":"17tV1YyKvsNoH65vaTCJHQfEee1al5BpO",
		"IEC 60034-1":"1ARKr68TbgJp-ooEGqVA5yfXISZRdz_Hk",
		"IEC 17025":"1Ekh1WPJGvMKQU1ehG-Dx3byqV_x0pq4Q",
		"IEC 14365":"1TU2kvveMq07sQzrjhdOdWao7frYN3IiF",
		"IEC 60034-15":"1UFRGfzJzU1ZH_7KZ__WxjEpNKBYAkKgj",
		"IEC 31010":"1bnsw9yzKSEkJ9Z70DnFi4VgNd3h9kBux",
		"IEC 60034-16-1":"1h8U7K1AkF25qhdbaDkaLnb_92NonZRQv",
		"API RP 14E":"1Dq6EqRfm4vUBeGxvIwnltpxL5aHJTFWI"
    },
	
	NACE: {
       "SP0116-2016-SG Multiphase Flow Internal Corrosion Direct Assessment":"1-uraG5ZtXTxepG4KE6t9Z3CTB3oik231",
		"NACE TM0197":"11PeuPhm5c_zLBHxe6d8T1AOzPhN-Bw2c",
		"NACE TM0104-2004":"11Zo3v2JZqxdCvPhgxoYtMtmGhaUJxpUo",
		"NACE WJ-3":"16ubPPi6DWh3CyRgNZCSru0TEsugk4I5K",
		"NACE Standard  MR 0175":"195F_vQj75ujGNRXKEySxP4eCapJDtEDk",
		"NACE TM 0177":"1CPS9N0ICEFl0-tUOCQAYmGVtktDAbCus",
		"NACE WJ-2":"1G7W1yFghGfK4GnWj95pVdgbZ8dwjC41a",
		"NACE WJ-1":"1OU42yAqf5-hEM4k5y_gsa2LtierLOZj2",
		"NACE WJ-4":"1Qx-IfSKLSny0wCJ7kuCdx8aK-ofWyJ6b",
		"SP0110-2018-SG Wet Gas Internal Corrosion Direct Assessment":"1UVAGHteSJTCwvkZiKGwfnGg2_BMzmKuC",
		"Thumbs.db":"1dv5S2LvBj4hwzgT1Zi9LlXQYgdF5n6ps",
		"SP0407-HD2013-SG Format Content and Guidelines for Developing a Material Selection Diagram":"1wOgCU3X-0kCeS9qlrFM9TZiyyW3D16N6",
		"NACE SP 198":"12xyBRjW5xIYHCnwcIWIXifBXPIXDMKt0",
		"NACE SP1015 WAB 4":"16Lw8bb416JkKIuRcAc5r0FvN7gOIZOY-",
		"NACE SP0169":"18EU02EPPXUD2lOTqnecan543d8NZfr7x",
		"NACE SP0715 WAB 1":"1CJVMiPpPwYWnxV3lGx8ik6ys7Y4tPXEE",
		"NACE SP0176":"1CYIQEylSQP4zWNXiVzUQdRM_NYrCBnj4",
		"NACE SP0391":"1FJyAYD4bnC7Ady0KYjMkFznOfK8kidqp",
		"NACE SP0472":"1FQfiWTF3kH3lY82gX9QUiXmV57IrSNUj",
		"NACE SP0198":"1HgQlY0XHYOfV0kDKRliKgn0z22LwrdSX",
		"NACE SP0193":"1IgISKQLvJiniMtkBj_cKAOu1mKpDkdgI",
		"NACE SP 0169":"1MaMSyo3w-OFSDTWdcwiXxI5tw4Y0HPUJ",
		"NACE SP0170":"1O-imvNcC4MXVgRkTY-Gucv4rDC9nBpCA",
		"NACE SP0104":"1RQAbevI-GekxrQ3Epzwp-xw_KlYoW3_5",
		"NACE SP 0102":"1WW2Ty9BD77Le6avW4BOXz47heUQEdYDZ",
		"NACE SP 0198":"1XVUzQSEqfQXlGt-iIkYIBCaqVxqu-ovI",
		"NACE SP0775":"1Z7qy7ez-YDRKzN5m2jNnjH1JIeBJYnCs",
		"NACE SP0572":"1ayHaHbPHgOuUdKG9meCJPoHxCieMnJTu",
		"NACE SP0575":"1cD_0WVjrlxtH0pcL0ULTiqoLArYpbji8",
		"NACE SP0395":"1eLOThYvYai5JxF-0lQlbH0848HPjI9Fs",
		"NACE SP0102":"1eoJlon9uyVfRnJ9-lHIDIx2YCABLPvVI",
		"NACE SP0487":"1hryr3qAiyM9TcH5dQjpzB_Nm8J7qbueU",
		"NACE SP0388":"1lT6s5l9pSjuHJYTPbcb6zfJ-y3TSKSgS",
		"NACE SP0915 WAB 8":"1lwuRjD2ccuoN1oXGWGqsSXJ0dCbXKVcB",
		"NACE SP0815 WAB 3":"1pAmfqmUV94bcroWbkr57ZWiZGyKU3x_C",
		"NACE SP0196":"1rBHHVdhJjzpqB5q2yOOAd3e1MwTva1rx",
		"NACE SP0108":"1vTt4otiA-jBcZiJNFkCfwPhbP0xHPvDw",
		"NACE SP0615 WAB2":"1wv9ihKrV4JYOaRAXu8jDKfD2YmC_dtk2",
		"NACE RP0296":"14dN2yyTYjNw4TRq69SK501C9GhyMAMw2",
		"NACE RP-0169 atau SP0169":"15QMgQwO2asoj6cjYeaOly5cjhOk2hyjT",
		"NACE RP&SP0176":"195GWiataDckMFpPBmRuLVcFB1NEzLdtW",
		"NACE MR0175 CIR 1 TO PT 1":"1AkJ__3SPJ2ypRqcEPrzggaaihzeBMsvQ",
		"NACE NO. 1":"1DQdQ9TgYsZwQyrw4OevYm6c_Jh3PsSAy",
		"NACE MR0175":"1E-0px9dTq6iqvm6R7yOtcaiiP0r0d97w",
		"NACE MR0175 CIR 1 TO PT 2":"1GS_hymmeKqgd530A86mPetG8Hg2evf-H",
		"NACE RP&SP0177":"1GU8Ow95bkZi8ZObuyNPuJt2AAHrPEAwp",
		"NACE RP&SP0274":"1JVZj7g7GKLZBHe0fo4Z8VtjIAcV_fBcd",
		"NACE NO. 2":"1LwVlDav-gcN5N5p7VWtIcPelfWZKkNGI",
		"NACE RP 0169 atau SP0169":"1SrmYzevYuvIFC1vQ1qug4dOxl9rwXjhk",
		"NACE RP&SP0394":"1UbjGEsfJvhGfMUJnXne213ZpEOHkp1Mh",
		"NACE MR0175 CIR 1 TO PT 3":"1Xtkq0ZAVT67OflA5brDAgnOyzIBV106f",
		"NACE RP&SP0192":"1_bggCh4Db7BzumGRZfGSx-GUOKfcKsWA",
		"NACE RP 0472":"1cGXUgsAb1opEyusGqUNB352RZ6Jdhgz1",
		"NACE MR0175 CIR 2 TO PT 3":"1dHZNHGmgu6BBxleW2MJOGrp3QdIIP1Y0",
		"NACE NO. 6":"1gFag5IFrCC8HPJnF8hyIsgHMHAuXN8b3",
		"NACE RP0472":"1hPD86Yscn0u74ka5u_M4QGO8qe7Uc8si",
		"NACE RP 0294":"1leWI2RdInWKhpsbntNtzzmWUKIuL_x3V",
		"NACE RP0387":"1nMIf6pVEwT3rh78TTCz-ZQqzcE-Evlk9",
		"NACE No.12":"1oF2rjjIJXyWQAedN_cSnAG3nlAYZ0xIB",
		"NACE NO. 3":"1pFPWu10RbC85ffMabGgvkcrH99PefcHQ",
		"NACE No.11":"1pb0w4Lbth3TBToyHC6waM0eiA-xv0uL3",
		"NACE NO.13":"1vx2Lv5raJSl4XbcnH5G2hQ-T3miRJ3Az",
		"NACE MR0175 CIR 2 TO PT 2":"1w5Yyh4bbElLK6hqMl609IIzcWTGIBQP_",
		"NACE RP 0170 atau SP0171":"1xM-UV96L3QBdM6tnXqHyuncuUPaHCHWs",
		"NACE 0502":"14BrtH2IT9tBIdDXO15EwJfDNs3dP5Imf",
		"NACE 1D177":"14SW7U5_VlvhYP9OEZ89Ouo-QQ6Qtz7Sz",
		"NACE 7L198":"16YMDTvLR2Z5uF1dbiUyJdpPj33hUqxA-",
		"NACE 5A171":"1A5dhpGdWkxlOCk8v0ajgQwfpzB8HP2I9",
		"NACE 34109":"1JRz8YovR8GZeFTQexP_PbHs7d0dHXpNF",
		"NACE MR 0175":"1O45n_esmZ3M5FyXMp92GLo1Fv0iOvfPw",
		"NACE 8X194":"1XzeQH1AQ_IzLIMAc9Edlk0QDYS77qEwX",
		"NACE 30105":"1t0Hm-vZgA0Yf8pLSryI17bH-xczfQn14",
		"NACE MR0103":"1v9uC_1gxO46vNh0L-UXf2DsBnOZSlLLZ",
		"NACE MR 0103":"1x3UqWjaY7MCTzidy1EukYTFrrM80kO3x",
		"NACE 21429":"1ztywikQ1FlXua3Q1Z4lYjGIRonqkdUjW"
    },
	
	NFPA: {
       "NFPA FPH":"1B0lzU4s8IUeVnPYHU8EjJWlNnGHrARQr",
		"NFPA HAZ10":"1KOOortlh287iFDRt9UP7pgvGhrlJ2OWI",
		"NFPA 99C":"104tgafZNKTPY5rdU8nJIYycv_bZv6zzX",
		"NFPA 99B":"1AcHhCHAQ93ySwXFEIhgAzgQgKugTeZBl",
		"NFPA 99":"1KBW5iGEmnkX3JZxNDxW2uXHEZFfHDaOe",
		"NFPA 96 AMD 1":"1QShq4-RbYZHrIel7IcsyF1eMucuxKCJ4",
		"NFPA 921":"1T_NG3qPLwbFNKC2UtReZdA15-5rQMtQh",
		"NFPA 99 AMD 1":"1UZ4MVG7J87UmHa0Jnx-1yPl2mQd4wRXl",
		"NFPA 99 ERTA 2":"1Vhp6s6Rc-33ZE2f_8LsWftv9QnmXh70R",
		"NFPA 99 AMD 2":"1XTO3kBvcETRiHAd-Nzn8eWLNOUa_bdyX",
		"NFPA 99 ERTA 1":"1ZbAd-JJKiDl8pO0nDYIfGpJqhsI_Lf73",
		"NFPA 96":"1amSSodtTxvdh3_pcPUGqPBydv_nDJhTH",
		"NFPA 950":"1iasBFQtC64bqqY_V2y38rrI5ZiT4Rh2g",
		"NFPA AMD 1":"1rDAdfGaJ5FKv5MffKm5nfZyIcN2XZR8J",
		"NFPA 951":"1zB0bqBB3z6NaUsm8bzI15p3C0p-PtKFm",
		"NFPA 91":"11kCbuZ0KL0LqDk27EJcT5WbWSJwO291t",
		"NFPA 90A":"14dZ6vcuEltdBz3zIAO8amlS5AqQNTg1Y",
		"NFPA 909":"1BirWBnWAj9_heyNLXLk_Qdzwv_sRq47F",
		"NFPA 92":"1FfESqkE1kbw-A7e2zvFl3ylbKk6k0MMP",
		"NFPA 90B":"1RX_rJWOZ_zvahi2C7bdXeaD7_S3NmBxo",
		"NFPA 914":"1cdoEnLfqkrLcBwMnk-_1uABpJZ9B6RWK",
		"NFPA 921 AMD 1":"1yOctooFMtZpJb2slUNKbbik3nrk_4qi6",
		"NFPA 88A":"17rO5n4JXKX0M5svmsDISjTNOdoMX4BKs",
		"NFPA 851":"1A4r-YEYHzmOv_t4T-gggTbutYjNuQX0_",
		"NFPA 820":"1DBaVuEGhfn04xsXRdPrR6UhfFfWz6lfV",
		"NFPA 901":"1DpBIJmKmeeDRoH8BBz4GIv7V9tq6C9Xc",
		"NFPA 855":"1G-8XqzhcOU2Ys1Ee6PVOx4xNLyAMKZPQ",
		"NFPA 806":"1G7sx7NYliop2skRfUEOboleAtcVsd1sM",
		"NFPA 900":"1KMaVknBOadSND3M2EFuupUTPXwh-Wen9",
		"NFPA 853":"1NU0wRqPY8mM-jWpExtiU_EZiI29g8nbA",
		"NFPA 87":"1QRH1ZlmQAj2q6yI3QEoJgjBPYpmFog3g",
		"NFPA 82":"1UfIOXWqliuEVFEB-iD93S4HtzehQLb9I",
		"NFPA 805":"1XRbCgpF_WeY8y9VQGgK0Fu8VWX7CyGNk",
		"NFPA 86":"1Zpu_TEleEl0uw3Tcy-J9aA0BJkyXhtmo",
		"NFPA 85":"1dHtX7UBlW9pwhj8OSVqpThQenEqazYT3",
		"NFPA 850":"1iDZLo1zKZNJAzGxKe-clPGUJ6-Woc7nH",
		"NFPA 909 ERTA 1":"1lrTyIir4wMvC8ALGhholxSJcYSEU1buo",
		"NFPA 80A   ":"1lwHlX-T4W0KaLn_7SfgeIqsJrMbd0a1c",
		"NFPA 80A":"1r6r6QhbWtni670k3m3xgXxS6Wc9uDeHc",
		"NFPA 720":"1-hJOUBw6vyBFBsGTtgT9AwGXDUtUziY5",
		"NFPA 80":"1-oRIkgLX5L4WuPR2Jlbi6ldUzWdcAMdD",
		"NFPA 791":"10TXMnuiCLBPZ3Uu7uwHsCFbTgj9e3_3F",
		"NFPA 76":"11nmgf8fkcYDZl8DsmHeSEyY-k3FlAP5H",
		"NFPA 72":"12GQYmfW9VbHkmTpAEbAIr2B5MQ8VzZ7Q",
		"NFPA 731":"14XRUWoe-LNSbX_wN64ni2eA-me67dokG",
		"NFPA 730":"183lIv5hOjO2_encaAX8e2h1t2rSR64Ed",
		"NFPA 78":"1Ce6_cM705065XPFpdYWZW07m_Q5kaD1I",
		"NFPA 801":"1FeNIMwND9L4NgOJvQ8cgWjnTbuurUsDT",
		"NFPA 73":"1HL2RccUmOjKz3Mty6kV8aHmE-sQY-7yD",
		"NFPA 790":"1NLUg76OiSHOCelNtG-4QMrx0GYQoweug",
		"NFPA 780":"1RClIPzPAd6_hz2dDhkvW_vmZCN9WAHrB",
		"NFPA 750":"1SzDhUwLYgTlKbJuxJSYd9uJWI12tumMi",
		"NFPA 75":"1ipF-czVlAh2O8cAmEfKCCAbfHK5Ni1_p",
		"NFPA 79":"1isrfrof_Kb3CtlgHQTvjiPhpftRbYeDE",
		"NFPA 77":"1jnHYr-zl6SaDHXM8WOHv4eozY22WwPcW",
		"NFPA 804":"1wwSkfSDoHOnzeV5YQq1OpuRXe-P6hFCy",
		"NFPA 70E ERTA 1":"13KJXBpx_rP2Ra_VZgcuWSPp5JyKLKHbj",
		"NFPA 701":"16eIRm-cdSMlttspMj2s39YqqrmWUWkH7",
		"NFPA 72 AMD 1":"19jytC-q0sBTf3SvOLzX691lhwDns4L0J",
		"NFPA 70E AMD 1":"1ESs_x3HIBsEHeTtTxDtcGAmiB59pozUY",
		"NFPA 705":"1G9S1hh3xdHsCQGT5jtiwkWq1BP14WnDk",
		"NFPA 70B   ":"1I0S3-fLWVaL5xb1jBXDvOxVZgHznBNrm",
		"NFPA 70B":"1KAGyERsbyuCe-rew3oyLQy5GiU-4wnlo",
		"NFPA 70A":"1Rru7AM4grQ4-U8L7F_oSIDXytAGRN-fU",
		"NFPA 70":"1UAZUy4xoT2Gt7kbRmQqawD0J-gOnoH1R",
		"NFPA 703":"1VDG-XWdwOH0r-ucKQdBXtSwBOP_290xf",
		"NFPA 704":"1WQqmnwX3IwnTlpwUPOQnqtUvObIQ-qeY",
		"NFPA 70E   ":"1bPGApVbWwt9BN-OkxU1sOjCul18ubpEt",
		"NFPA 70E":"1cR7EkztlRPodrDY1RkQISPOuK2QTDaAy",
		"NFPA 72 ERTA 1":"1huYuvAuMDMpLNCwW8gf-klAga_t5JdRD",
		"NFPA 70E AMD 2":"1yKNiuk448x9Eb_kLBDiTiZaf53ivTU-m",
		"NFPA 70E - Standard for Electrical Safety in the Workplace - Effective":"1z5QD7nPckWHC0Vzm5j-zitIyt6_WWXxl",
		"NFPA 69":"14Cq61lLtg_npJy7Gd3ZHZZRm9wHtnIxE",
		"NFPA 68 ERTA 1":"17y4fnLEZH6zC9celzmBLeMb26b9q0qlK",
		"NFPA 68 ERTA 2":"18py9e3JQ1lC8MMTOvZUU3qul2IeTHT3p",
		"NFPA 70 (2)":"1GyFw0EbvVaz5kvZe3R-VDHtlJlkoE7Ev",
		"NFPA 68 ERTA 4":"1JekskykPCaaleyCqyewybrNVfBAkL3RN",
		"NFPA 70 ERTA 6":"1NHek_BfExTT9OAZXXfqI9psj3iX5tor9",
		"NFPA 655":"1OIwaFfmgjsexQ6rNv62YI0h-LuY5WktO",
		"NFPA 70 AMD 17":"1U8BsFJJjQ0l-vWw7nlcgjDj4WU_pvDNo",
		"NFPA 67":"1XfSgZtZKcEGPj-VoMKLAbSFPdPsnp75u",
		"NFPA 68":"1YQFKlzNW81wFcurbhw4IiEcFKygwegeh",
		"NFPA 654":"1bgtyI7t9HyBCRHnMi4x_0TWe3BON9jkC",
		"NFPA 664":"1c342Ppc2AT8FB7SSFU_PcBL73g_N2e5E",
		"NFPA 68 ERTA 3":"1dKgVgy365t3rDrUYZkkV_rkEsDSUD8Am",
		"NFPA 652":"1dk8Sd-0zEfRFSR_NWr6nJntB0v0wYNkL",
		"NFPA 664 ERTA 1":"1fUVayRkZjtxJ6cSXEhWKaVlYDf75DUkP",
		"NFPA 68 AMD 1":"1hqda2HhGUBS9-wqVtBSqict0-o05UWKE",
		"NFPA 70 E":"1ieb4xjhWWAOIFFlhPDlTOTnLJhAySjVv",
		"NFPA 70 2017":"1nPoI3hhB8u2kv1crikz6YzMFoBOnHsGv",
		"NFPA 59A   ":"12z3qkWpZ4POl5LezsMTYf2znh0zuqbAY",
		"NFPA 560 AMD 1":"142UM64DbgMcbJBPuP6zVL21c5Yp9DZDD",
		"NFPA 601":"1BZT0zU2IaPTZGLyR1scWVzmj32JUuoaZ",
		"NFPA 61":"1CLbzYV25Qm5ILOXz1tGlSqQBALHBJl2W",
		"NFPA 56A":"1DMUBRUhO8491-TUMlzip-mNCWOPyBoga",
		"NFPA 59":"1GUqjxPiNSrbso5RraDxSEhBIl0CrgQeG",
		"NFPA 58":"1J5brXHyYnSxYgya5An-gWGR71JQxmQx4",
		"NFPA 56":"1XfuFtqtzxpmEoUQsTOKPG0zb0aqKJKZz",
		"NFPA 560":"1fJRmzGgjCala_2tVJSbyzFuLlTar4zj9",
		"NFPA 610":"1xKEe_EXVBP8njaRoOjE3zHe2q_IWgA1h",
		"NFPA 58 2017 editiom":"1VCQ2raS3xbMS7WqKf25Pbrbys4-f8ehx",
		"NFPA 59A":"1cTMj-REBZqp6VRXi8xVYM6ixEnh-P6i4",
		"NFPA 58 2017 edition":"1cmQ-FS8kuhzewdp42jPYI8K_ftmBRHqt",
		"NFPA 557":"1jyblZhUW6gOm8h_xh4PHVl0Vaaa760T2",
		"NFPA 556":"1pjDtjM2qn6iHMLeTg6zb9L3SeI8MF_uP",
		"NFPA 600":"1s3FfhW2vF6I7GjRgIjQV887iR4NyyVBa",
		"NFPA 555":"10epTaCyAPo5XJXUWOqsXqvmspEPk9XCO",
		"NFPA 55":"13TOwKmjVF-c-V3vFW5e-knBOyWjCtZOc",
		"NFPA 550":"14ljP0j3gWzTeO3j3oTTjLnSrjXyBiPkk",
		"NFPA 551":"19LhZ4FdmKxYjVsdJM8_yfKPECCFNyBCt",
		"NFPA 53":"1Irp4sUjvzKfnzKLFGd8O8-xxU5vU3tUN",
		"NFPA 52":"1S5WAWMztzYV0GpF-koFU9CErY3MX-H2_",
		"NFPA 54":"1TVqwGh9rzKNdbGgKH5A9YhkTVtUnG0uD",
		"NFPA 51A AMD 1":"1UqPMPyK5s4hb8V7DsePuy0eR7sPoaPpu",
		"NFPA 51B   ":"1VuhYCLU11jSwKmoEkE386SthCZPbIFtB",
		"NFPA 501A":"1_7BbQuSw80NMIev9-Et4gxTHrZxieKqW",
		"NFPA 51A   ":"1aMRwCl1rEq7FxhzY349LBcyLXs-DcHvx",
		"NFPA 5000":"1cdOP2r6wvVubdDHbyJU2HGAl-GU0w55f",
		"NFPA 51B":"1czXhlCwbg6YyK51QWOtGmwvuzFqZKd20",
		"NFPA 501":"1eLlLB85a9BeqwowmwbSVvX60RHdGhUIB",
		"NFPA 51":"1hfnyCs5tIoVMmf6G2glxlbEt2vaj_6IM",
		"NFPA 505":"1oT4U6mfPohkSsqpENizsKuYM7mSE_4yO",
		"NFPA 51A":"1pIKhRAOOpk5fpdQNYVuMZx1tx8gTVW7h",
		"NFPA 501 ERTA 1":"1uNWz4gvsrp0MKWSpIQ3jI6tXGJfuS-lu",
		"NFPA 520":"1uYfh1qqzDKJfMYUAwK8iV3raKKb8kIvE",
		"NFPA 502":"1x8txyn3Ik84te95147XCBVMJMnSpBBxj",
		"NFPA 473":"13CSIZ0cYMsxD2HGtIKtK7Sq97xXC3DdF",
		"NFPA 472":"17AtgwJx5UHeeyx8PKTUC96Jlc9T7NSb1",
		"NFPA 495":"17xkQdpIovVz71juk5JsCGIbZwGCkJtMn",
		"NFPA 423":"1EZ5lyEaI1OAXTPuAmC-1l-_2O9DbQLy2",
		"NFPA 424":"1GQMAaA6iJu8NHuIh2M8zzaRyZTqjhRXa",
		"NFPA 499":"1HSB65zJhvsukD1yCClVzPO7C4cUsOcxb",
		"NFPA 45 AMD 1":"1Hz_syvdmdTFpYr7t2D9ds3KY7Xp8L3Sh",
		"NFPA 471":"1NjbAF2lyL9ztjlZ8GrZg6SY1UJ1n5iSi",
		"NFPA 475":"1OEVvKw_6fvuacLNGqjVIcUfGuCLw3O9e",
		"NFPA 422":"1PYYT5Gp1ENdkFP5VSRsd0ElD8YnoIjYw",
		"NFPA 418":"1VXwga1eIXFUK_evWQ59_j6OzbIgOJaBF",
		"NFPA 45":"1Xr2_3dTggwcnJL7ZgPf4PGZMGX6CGPKq",
		"NFPA 434":"1iH8dUnN8wbTRNb7jFGRNMUev-ojmwRXT",
		"NFPA 484":"1lbSAN2brN5XX1FrKMwy2DL1tuLnNKeHx",
		"NFPA 497":"1poVymoW_t8niF-b-NaP45IBuETMBLQZ3",
		"NFPA 451":"1qDTP_GnThpC6L-QFuO1FS38xRZ19k26V",
		"NFPA 450":"1qpHoPQT_nCVT8LPXYRu-Nu8GI-NNKx4y",
		"NFPA 498":"1wrTNAKts-Z4qbjVk5BNodWfK1CXC-Sme",
		"NFPA 496":"1wxTOgL1rPCHdzjUbiaB1Rntdc5rODFnB",
		"NFPA 408":"11N4YUr00izNSZdG61X_dJlZaTF0leUvq",
		"NFPA 36 AMD 1":"1BeHl1r4u450ZfL2nG4hGZrVSG6EuqLgS",
		"NFPA 403":"1D2B4B_u2ooFaAJdJlN5Lk2n-kGVOQH8b",
		"NFPA 407":"1D3otAOPOo84N8d4tEJXRnzGOnzp0f-av",
		"NFPA 409 ERTA 1":"1DEFuJbm9iR5gyzfYGpR2-72TEy864RpX",
		"NFPA 410":"1DEmA_eBB_wZNtG_-DtWaalQcvvyWohn3",
		"NFPA 400":"1Dfp_dYWDubgUyaK3BccoMkcLCCpJDGOz",
		"NFPA 402":"1Gd1RFhSUhb-egp9ebiBfC0OraaxRaKE7",
		"NFPA 415":"1GdO2m8O9EpVcMDYWmwjz47XfSGdbjYQv",
		"NFPA 350":"1KTWmzrb7GX1OMpx3EmPobwMyUwiHvpDz",
		"NFPA 412":"1Ovcgox_eZ5eM4LbRql-xE4xLCnExqFIT",
		"NFPA 385":"1Ti1Xfn8QEJ0XLayB-S6HP545s21A_7AB",
		"NFPA 4":"1VRHGQrOhNMwHRzRhruE9qlUI7FPhz-Dr",
		"NFPA 35":"1Xf2HXwwotDjQi432d0dUVz0Bjf0fiXwd",
		"NFPA 37":"1a4a_t8DA1PY94nCiqyh7ANqOY8Iy95it",
		"NFPA 409":"1dOQXe4jpOoKldANmuyuEdtvnKwRoWU5R",
		"NFPA 405":"1ezdQ4LNGAicPm10nUO7VKeTOR_xFHUru",
		"NFPA 407 AMD 1":"1t_7vWpJ74oUqf6BDOGAznaN7-3NlEKoM",
		"NFPA 36":"1tuAnAbQxqaF29bjBUjwyXsxuDeHF2AHZ",
		"NFPA 414":"1u_74gsP0ej_X7WMvIZ48YFpJfB_v8X8p",
		"NFPA 40":"1waOtr6DbJwWpHXGLOkgR_5ZPKYaHhfVv",
		"NFPA 409 AMD 1":"1yPjyxNhow0yhKNerCNuxB0TsJhfkf28L",
		"NFPA 32":"10sVQX9vFK-T87gUBrVHQfLwKPMASLG0-",
		"NFPA 30B":"12nJ62yBopx5Omo-O-oQ0mvvJ0I4pcMGc",
		"NFPA 302":"1AoBXNZBoBH51lDVXF-mxkx1_M-X0uJbD",
		"NFPA 34":"1Au0UF77phMaICUmxwjv0nW4xJ8Fw0pDK",
		"NFPA 301":"1E5KrTFc10PoxvQXYvg50PB3PS1ka5hR6",
		"NFPA 31":"1Eym_0ZBWGqMGty9SNA0cX7eI1H6VfSGt",
		"NFPA 30A":"1LODjVTcQqqs9eLd8LKc-SaqQlbL1WTkm",
		"NFPA 30A   ":"1MCr50ZYzSNfO1pGSduxHLPDz3LTZg6NN",
		"NFPA 30B AMD 1":"1NZ20sKAWPdJPUOKB9jeRdZRIJ7Nde1aV",
		"NFPA 303":"1Uoll_zq9NrcJbR-VJOS-4rcXrgk7gjBE",
		"NFPA 33":"1YJPPv1iSa9rZQRiUlTSjqgkkm7tS-jqc",
		"NFPA 329":"1fRMxqbUx9VFCBVEwjZVt4FXNn88YyGKj",
		"NFPA 307":"1fVI9NbBgXXw0W1qgZQMyGz8nvjCns6ET",
		"NFPA 30B AMD 2":"1fncOO5ml5VX-2E0o1SpcUGr989gpl1Iz",
		"NFPA 30B   ":"1kt3fPAxbB26wmVXEexY2ry5CRZLfG87A",
		"NFPA 30A AMD 1":"1oUm823RPaRTtIATBDS3jyWdX0gwNXpsQ",
		"NFPA 318":"1pTYMZL0pO5nzTPXLMS-yvzn9clLNCJ4B",
		"NFPA 306":"1pfXjGjunFWnYGjRZF2tWhoZZtaSanE7a",
		"NFPA 312":"1twd4t7Gv1WQvTmRGV8r2AMpm6aYSgN4R",
		"NFPA 326":"1vsz3pUUXbeXyia7GvoQDdTwtNKZeUuWn",
		"NFPA 30 ERTA 1":"10wJUkbij_i8PMUKNG6z_ESKp7vHuCcLL",
		"NFPA 291":"14CUz8ok0gt-ew2-t0lrmLoVGe9EtybcK",
		"NFPA 269":"19fyAxG2i88YHWSPBWK1LJKfXzG8to4mt",
		"NFPA 286":"1HFTogv_C2U5QTN_GnqQGQMLJvUGMpIV4",
		"NFPA 257":"1MnyETxVLo8ZflfzEUSQp9RflVesGCT-8",
		"NFPA 255":"1R3oxdky9xE2jCwuy2GwWul5r4I5iMH4M",
		"NFPA 274":"1TcocdIuxMJT2yx_jwQUglvbd5oHcDqYz",
		"NFPA 275":"1VaDVQzNW1S5Iiet-m76nx7sGUPDoooTV",
		"NFPA 262":"1YgRAKeITSchtpTUxaXwJeatpyo1RfHtq",
		"NFPA 276":"1c0HJcaecx423IFp2fTKsVQg8C8hABdO_",
		"NFPA 287":"1n_plfIncDWUOMD2qaTA--nz2zwQqMsoF",
		"NFPA 268":"1rf_LCswgwc93-rjQc3QuDnxQGX1xniEl",
		"NFPA 288":"1uLozWYGtfpsFe2NmqpQeakadGEkBesjM",
		"NFPA 30":"1Bl3GHUDZk_hwjoLt35QeKOBF6F8GJeoG",
		"NFPA 259":"1E9ztCzy4AbZpBSnZCjB3gVtDjIwuoajM",
		"NFPA 270":"1FSqZkOp7zCVMFhUPUzNlvOWxBpiDQ_Rw",
		"NFPA 290":"1RNSQBLnuUmqyhguokLx40bmdNkIBI0cj",
		"NFPA 271":"1WilGMssPTqDFIoces3T0aAGMcDfuuJpz",
		"NFPA 265":"1Z72iXg5Fr3h2bgrz_zMfR_HO_LS2SltE",
		"NFPA 261":"1a1wAbUNFVNBECUU_MWuQBPX7o4zGDebS",
		"NFPA 289":"1rXyjAsLhz2I-ZKyE117CrtDXDg3d6V51",
		"NFPA 3":"1sn6HRMl46_-dNMQ1pYFWaT6wGN8fwGsn",
		"NFPA 257 ERTA 1":"1uZVW9ou93bSEHxxtfwnFqV89hK_6C6CY",
		"NFPA 285":"1xUdXBp6SAzQMV6T0i2Cqq5gDacr4lyoF",
		"NFPA 260":"1yKonh5vbWzhDGa8ZdVShpYwD4lT2-inh",
		"NFPA 253":"102RGCXL4GGdrvUSYY2E3KX8etCes14gE",
		"NFPA 225":"11g1w_KrRiOCAzeL-Qlh7e5CYoWpgoDpu",
		"NFPA 252":"12EMVO1Pxq9yoNW3poCnQ2wH_l-pA1ERP",
		"NFPA 232":"12_N0DcjS10pTUysmqf5oX9qv91HLYQrP",
		"NFPA 214":"12cJ89LPlgehptDQUQKplUb8IHj0eWIHg",
		"NFPA 204":"17STssyAm-SFqMB-PmNbJLPwFP4ntUkii",
		"NFPA 25":"18dhBpp0B1pBAe_ul8qanrhf6vJibs5_j",
		"NFPA 251":"19obdF0IugA1wjUtXLKtSA4uTVYTC9jfg",
		"NFPA 220":"1Ar-6ObDzJrqaBRKmCEadNyCcVBc0fJkz",
		"NFPA 22":"1ERY8s4uOI51YZ9HD8hhUiA-UwXy9jw9h",
		"NFPA 255 ERTA":"1I-Kak4UkYrHAlvOGUd4w0rWdJF0SJQC7",
		"NFPA 2113":"1Ih3r-DSJkSxlOs2DCGCuuivyY0v6M7Ja",
		"NFPA 25 ERTA 1":"1NW4uSm7FBWGDvVWY-c_f0_NztN3ur2RE",
		"NFPA 241":"1a2A8pxfG4NCjx-RZTM_CCE09ncxEFPo-",
		"NFPA 211":"1boSdKNxWK7xB0kGFHh3I50B7YNVOtQ7t",
		"NFPA 2010":"1eYMA8N4hxe_h7svZgx2HyL0SGIaOraeh",
		"NFPA 221":"1kkNG9daJq7KtUyo5bamttHdyCjw_zyu-",
		"NFPA 24":"1mhqh_pUptnTatz3P8C3ZX0GIGhvhl18f",
		"NFPA 22 AMD 1":"1rhZ587i6QBqmi-39_ekM2FJd6d_zVhNk",
		"NFPA 2001":"1roG3-O6GcDdhiNvCOQEy77XHinyG3T3f",
		"NFPA 2112":"1uw3Tx1fNay0KibnLJjyzjmy5KXkHz_ne",
		"NFPA 2011 ERRATA 2":"1wJ_R-7RIVcXk86-6PQsAhRlIaGP0j7jL",
		"NFPA 2100 ERRATA 1":"1x1YIn38HiwRNAgfFggjp1zUEczdEJeYE",
		"NFPA 1983":"15RNoatqkoQfNmsHbIkb1xrEndfHAoiRV",
		"NFPA 1984":"1ERCkbHyu1A_n7SdQrjtFMHWEHu7sqDXP",
		"NFPA 1999":"1HxBAN5OkAMTOysWkcdJYtc4CNpSRtXi_",
		"NFPA 1975":"1LQLJvYIYqV9tpDGHeIo5X-vpuVxHHZ78",
		"NFPA 20":"1M9BRs0wOiy-sBCNZOvdVjtpoTZqR0fg6",
		"NFPA 20 ERRATA":"1PyQu-bcjNHDRefGL5aChDknTJFrCgm6r",
		"NFPA 1989":"1SeAqjyVZidq90llIyoIipfH4ZXLdF83g",
		"NFPA 1991":"1Vii4AQyvEoebgDDW_Tlxi3YkUk1IyVs5",
		"NFPA 1971":"1_4jKCghoqXotUrqq5E2BFDS9N2TCNQWW",
		"NFPA 2":"1evTwKsTZAkZ8zs8ucWFaiqCMS3XWtEnx",
		"NFPA 1994":"1iaER2iRCMV5othDV6Hjm03yRapiGqL0G",
		"NFPA 1982":"1k1os3wwxD6YYuu_s-R0bqw5fdMGNJm-h",
		"NFPA 1965":"1lgtIuGqanelQreQ82xMOM86_d4sJbriL",
		"NFPA 1981":"1mEjlKmB61sEzY9YMdeR6sMkvIXOt6eCs",
		"NFPA 1977":"1nCsYnS2X84R_JjKCtL1TbzHOR1pKmBXU",
		"NFPA 1964":"1pocslKYzMia2m6Lr0-pBEvBE_g4PvxGq",
		"NFPA 1992":"1reDjEwIIqJgP2gQEXAyGk6fUGzqCpooc",
		"NFPA 20 Tahun 2016":"1vFjBAePWjh183YWln0g1set_9G9hl2E9",
		"NFPA 1963":"1xPbSKuRaOYpq3CqniFnsrsevhYgfPlz4",
		"NFPA 18A":"147NiXrD1vEj-zwe0LghPP-er7LKvZghD",
		"NFPA 1912":"19kBM9P_3BXMAr7SXCQ1HqRN73y-WhVhn",
		"NFPA 1961":"1AVVDNWr458q82YxrYEgMEvIYP9GWYoNi",
		"NFPA 1925":"1CYGaXJf0Dc6zdWPaB8TeinyifVCF6sqK",
		"NFPA 1901":"1IdISR-9He3qGJ0gub_xiGLSbOa5QhO-o",
		"NFPA 1952":"1IqCzLexP5i9tz6T8BJpvhR0p6oMb2crN",
		"NFPA 1962":"1J2B5OnRu-EmMyoNihkUKELYFkB7wsv1K",
		"NFPA 1931":"1JO0uJsaW7aR3hJmX303ljVrK94S_l6qJ",
		"NFPA 1852":"1UdRW580Lc7aJyyHUoFZRrzd-uIufPJAc",
		"NFPA 1932":"1V3OAww6LC8JMp3i3fP3X0Vj_7DUtVVhz",
		"NFPA 1851":"1YpmsrrTQj7K7_BrLRiiPvaMUl9c4yMaA",
		"NFPA 1936":"1aDMugqCJUfDftcrlBNmp-1UTPFfF5Tjo",
		"NFPA 1801":"1fpWoOw4PDAyNCp9ltVg2k3mQ-NfxEd6H",
		"NFPA 1906":"1iyu2COQc0QzQcPnvTqci5DLKuotnhQiM",
		"NFPA 1911":"1lzW_tmlaGVd-s7nAp5g5hnCLgPePEJXy",
		"NFPA 1855":"1m1nM2x8HhWy65_Nk8EbCFgBKFUsS0VgR",
		"NFPA 1951":"1pcnMKf7qL72s3WOTtIMY-8eN5eEh32u-",
		"NFPA 1858":"1vICyjCDRKmyy1_JeKvczZjxdsYdaY1dF",
		"NFPA 1917":"1w3DMZ2GBrTAG460AEvKbpDogLsodE3Wd",
		"NFPA 1521":"12IBzEsf_E7bjIS_RX2ZOxI9sXMpwWlqB",
		"NFPA 1730":"12VsnQW4LZsG_hQdjHNrJetwKmre79zqk",
		"NFPA 1500":"1Gz2EPI2Z912krtiV1Nfoo4HpkV6c5JkL",
		"NFPA 1670":"1HC8ZyNvr4KKSLyh1DKXTKxHLXe9okUSc",
		"NFPA 17":"1IHp2XLwAT8yYJWEOyLny6KK6LKRm6G5c",
		"NFPA 16":"1OJsCu1XLTcFnlLgQOxI-kfvT9GwF_RIq",
		"NFPA 160":"1Q_nFwM4H4PckkpQoqGhdN8DOPq-rYbTe",
		"NFPA 170":"1Uf3yRrB5TZgTVZ9MsmXbjz0hIMvdfjNw",
		"NFPA 1584":"1Waim0rGcOe1ybkn0cjdO8MACvpVuuwuM",
		"NFPA 1561":"1WcEGruQt9pPiate_Vy6YAatMeKBYolFB",
		"NFPA 1710":"1XaQLwdPDVUlXQ7AB99sVef9QBJVJcwMF",
		"NFPA 18":"1bMU5-_xmZAETA_dRs7RdpmufQ1QndoCv",
		"NFPA 1600":"1dfORg_bnBEGGk57XxpVDIPLKNWjW8DGw",
		"NFPA 1616":"1f-D-SYw_I9l4oS6gRiXM086C8ZEy-GIl",
		"NFPA 17A":"1fe2zoq_QygLLgyM9LhK_LMO_JK0HaQWi",
		"NFPA 1583":"1feJHE7LNIUoTGMTxWP5YNvgzAaduyrS3",
		"NFPA 1581":"1k1k4nxrCMC4z9zITJsEdoEtvR3tic1GR",
		"NFPA 1720":"1oj5cxOz-8koIheE_yy9jW93Aff-_TW_q",
		"NFPA 1620":"1scWbd0m6hN4_TOo5Lb4VwFHZz6OtHIMz",
		"NFPA 1582":"1vqeg7Hycz92UYUrg6Yv8HRdOvWBb1ViD",
		"NFPA 1405 - 2020":"10PdNtskxKa_M5bTm-eaJb-hSDVQa2piV",
		"NFPA 1401":"19qwWG7V4mufntD0g0BGf_1jz6GVBJ4o6",
		"NFPA 1407 - 2020":"1Xf9yHk16IHNbfVGBU2QtYRanHZJLsNvz",
		"NFPA 1410 - 2015":"1q34__NDQmYLXvmaLhEKgE9cGcbwBOYNP",
		"NFPA 1451":"1siX5_7MlttnbufUYyBJpvGUwyAHudUQM",
		"NFPA 1407 - 2015":"1w43vremQ5LofPzEZVdT5mH2nOiMV-3l7",
		"NFPA 1403":"1zcOi0ET7MG6dWyEJgK6Cv28mbpN88YEw",
		"NFPA 1405 - 2016":"126HVsT9naSEOky4AksbO76BInVfD3YWp",
		"NFPA 1408 - 2015":"14eqj1iAhRUAe0GGF0IZzxvquMegeQS2u",
		"NFPA 1402":"16KUvdtOANdgHOkR-4LLzSPDJThbHD7i1",
		"NFPA 140":"17ALpyhWpLtQ-n3527lRltK46nyVFzXc1",
		"NFPA 1452":"1Spzdk-3RI6Mnr_TGzDzUWSbVg9jpNsuz",
		"NFPA 1404":"1V0hrqCMLDmw_gqTkHAR7fZYBcBYGXo6X",
		"NFPA 14":"1bK482q37rMrBJ5NnoNMoNAcQ2tUfp8PV",
		"NFPA 1408 - 2020":"1eJ686Kgcg9vW5e5svl_0V1abJfKIWaPN",
		"NFPA 1410 - 2020":"1jD7ZGIvnCc-vqz_oHYsiIMY0cKmeNaA9",
		"NFPA 15":"1o5orsu5x8wuzr_CjJ5Il9Fj_uSXOyc6Z",
		"NFPA 15 ERTA 1":"1vBWTSDYlENwbkOdEO5MdG6Q5xRohkFxK",
		"NFPA 150":"1y-UGOWjfloAjd7hTHufsQXqSGxz6J4tV",
		"NFPA 13":"1-6mB0NhzCHpo5D1lD4z2Wt4GM116YAXq",
		"NFPA 13 ERTA 4":"16DfyUBzBjgyYi5mU4RWFi0_7h0byXE7G",
		"NFPA 13R   ":"17nkc1BQIEFuPS3AzHK1eg18UhbAQpwpk",
		"NFPA 1300":"1C45FlVDXkFpLWPJ36dioFYR6TQk3oWUt",
		"NFPA 13R":"1FDTc-oFmu7vjVvtp6x73gTm0v81VRuNE",
		"NFPA 13D":"1IaxzZ--fyqQvqYDO7foVlH59p3iNh6ow",
		"NFPA 13 ERTA 3":"1MrXXTNVNi6fJdPypTeXnv7rOJuWubhvC",
		"NFPA 13E   ":"1UrnSZYCP81gmFsZC2KH8Yx2nYxHIHiIb",
		"NFPA 130":"1fm7wgMMpzWN3m0qNmVA36AZ7tSVeS0sZ",
		"NFPA 13E":"1k1VMifXC2k_OzPnDm_-v6mvNyum9Faju",
		"NFPA 13D   ":"1l5YFCBx3ATKjQOR8GIt1Yjbiq7htaM6D",
		"NFPA 13 AMD 2":"1-yNy1LiA3eaO5Jiw72bAanaG2M9vHWXs",
		"NFPA 1144":"10xrpd_JkBTieLU8c7NiLkAifz-ayGqC9",
		"NFPA 13 AMD 4":"11kTXeIWD9RFek9SQe10_xXSYjHHknR4E",
		"NFPA 1201":"17DyQGmkp-cD1lv7UGrHXA0GL1jB4F9H8",
		"NFPA 122":"19TlW6sj4EuPnjkf6sRwWcxqdRo1t7FZy",
		"NFPA 1201 – 2020":"1AWu26ZuqKf3oV9tiUddNgYtrlpaRqMoF",
		"NFPA 12A":"1EOw3Y3-OeaRPglDgYsjDCnxPdGRDPaKO",
		"NFPA 13 AMD 5":"1GhPcNiFxJoaSlZ1qER-VLrG4Fidpn7Js",
		"NFPA 1192":"1JIR6pBgbCSHKw2R8GeE1ULVdLG5dPKYh",
		"NFPA 1221":"1K9KJD68Q1ZYO_7CVTSryJtfs1PPuW63X",
		"NFPA 1194":"1KRE7gpY_V8uNk6EKQdClLAiIsNZ5WWi5",
		"NFPA 1143":"1QN-zuRfa3DOzfFveqeiEInz2yGNvnTYs",
		"NFPA 13 AMD 3":"1R7zgw7Eb4OfqbazYrz-3sEQrsXBu2r3w",
		"NFPA 1142":"1UX1sk2M_wjtqUVyjVklCB2cUfczgOYfU",
		"NFPA 13 ERTA 1":"1UiM7Pb1WHRuIFtuc2BSDMMQAw00ZJ_g1",
		"NFPA 13 ERTA 2":"1baPOSh43aN6zc9-hOkyLHw4eMpuf6sUG",
		"NFPA 13 AMD 6":"1bta_6Vzd9e8IWzebF-jHzTaGJ7yukPvf",
		"NFPA 1150":"1dZF0-TTKYrFK6kLL4xltX8y_VHBlklBY",
		"NFPA 1250":"1ipYS7yL2TXTVerZ1m3sn9-R0q30cEiQc",
		"NFPA 120":"1jKgsi9EcOUiTrxlN459fdyUfJutVFH09",
		"NFPA 13 AMD 1":"1mBA0cCdWlV_kIkitgcuSXopa5G96IEPz",
		"NFPA 12":"1nXW0BhojXdLn6v9elDGwNMuIPVTsnLGb",
		"NFPA 1201 - 2015":"1pB6MQegk4lkHa4tmdQTuRLbfHq9VdECc",
		"NFPA 115":"1qZWgMpnoExdlpQkHUuOasAHLuNox6XlQ",
		"NFPA 1145":"1ux2II_fwPzVNsLOtq1Z1uAicOxod40ZW",
		"NFPA 1091":"1122zM1KkgKE8sLZ3lSz7eyjPWKPuEJjt",
		"NFPA 11 ERTA 1":"12r1gRxsrJt3r31IFSzf6gxAqTiNThHuL",
		"NFPA 1078":"131R-Du0NI3mhes09DTSD2ea4293r_cAx",
		"NFPA 1071":"17gNNWV-qmZNIAvBZSoik6-gNZdlECCH7",
		"NFPA 1122":"1DFS_asRqVvQsQ8th4EllsT1Pueja-MM-",
		"NFPA 111":"1DrF9HFhQMcL7qsGSZqhihx44-l_nV5qP",
		"NFPA 1123":"1Ezk7bK21sE5kAL57rxqlyxek457zg0vZ",
		"NFPA 1125":"1GZMpKOtcO9Prsyy4GaRs5iK4V7NExzlD",
		"NFPA 1081":"1GgpFmyblu-QLNy6rLuSZeHV4j904H9Mo",
		"NFPA 1037":"1HtuYFEN4ROI8Xt_v5J0Luz_Jk03xer8l",
		"NFPA 11":"1PDrS9zEb9JpKEOFj9qoFkeQTqO2Pj744",
		"NFPA 1041":"1PXCw9vDzlcns5Sv9bmpJx5XyRlxMJ0dp",
		"NFPA 1061":"1YgbjTCW0TvotGWFvTYOOzfSZbvq3Ozli",
		"NFPA 1124":"1_0QXUpCr2iAe_2IXsnAO_qbyR5qh6gAU",
		"NFPA 11 ERRATA":"1ekmwJr9Nlej8I97tNl1w3A1cgjP5H1aH",
		"NFPA 1126":"1grIUcS9_aOnPo57CHuwFaJPjGSr9ntAb",
		"NFPA 1072":"1iciyZetZykECfiXMc2DGMw-3-r3Zs4g7",
		"NFPA 1127":"1jp83f0JA3ctcdgPEhAcNNlXdzDRNgXrA",
		"NFPA 105":"1lvb8lcELU5auPTbA1IINdWYOVqu6ISBL",
		"NFPA 1127 AMD 1":"1qmL_TB-HmRBQmc5FuwYyqUEc-SI3D-K1",
		"NFPA 110":"1rO0d3pVo2zUk1i5Gaho42uUa6zQ7a2P8",
		"NFPA 1035":"1roIz4grlCadOMu9YDjz_vmrnP_dgtHiG",
		"NFPA 1051":"1wWMKOvKzd5d4USncK6rkiJqJ75pFW-PC",
		"NFPA 1141":"1xKN8fLtZaA43IUe4fPzZDN9OIRpRhCe0",
		"NFPA 1082":"1z-UO6qC3R_qcDWqnDWAX2K5llHm0QRm0",
		"NFPA 1021":"1-XIcXC0gTUFbn50cd-o-TGVFHawBFn-b",
		"NFPA 101 AMD 3":"10zJehRY4-OIUnj_22kOFEsRDtZFi48uA",
		"NFPA 10":"12GV3ynqt73iWPMUKN5BUltljrk9saR7L",
		"NFPA 1002":"13ZnRv1LmHp17wUZb_x7Uvq6fKN9vZ14D",
		"NFPA 101B":"13kOM2kmPS2siIuGXLqgIpR5HQqObtrEY",
		"NFPA 101 AMD 2":"14xsZrmLH9vIBlWGmdRpMFzP3H5t6Y9Lw",
		"NFPA 1006 AMD 5":"15FzIOVdnaaXvAbsYJecvAq9ipuICUrCt",
		"NFPA 1006 AMD 1":"15UBHBXwgtLS4MH4_t55YBRFTtcFpUscR",
		"NFPA 101":"16laeXVPNKleD0Pr0BW67blq-o-hb69-3",
		"NFPA 1003":"196QokE0XoITFE0x6tXdCadrHx-UQ90jj",
		"NFPA 1006":"19KiQLLrKkXgIvmaYsbSb1SoYdjxr_2Os",
		"NFPA 1001":"19nCXVcS_ndE0FWbyLGj0IZphzbo8i4ad",
		"NFPA 1006 AMD 4":"1FA2ReP7M1cBYZA6CEHaveXRDg5VIUaXG",
		"NFPA 101 AMD 4":"1M0BDR1ccvbKAF89FiiKaxLlgmZddqyQW",
		"NFPA 1026":"1QIIqNIkuyeRXqcQomTiXmWi2AJGJGOQR",
		"NFPA 102":"1TDm_MMQpturAlracMKAxWSjvwksp4kDt",
		"NFPA 101 AMD 1":"1Z-tOI77KMKwxN5z7mamyPl1yz4YIxYSj",
		"NFPA 1033":"1_ECThHnqPkBDC4NBdbZQP7kHRmBamYXW",
		"NFPA 1000":"1c6DA94ANj2tiynCW_7Hheedjuz_G8OCs",
		"NFPA 1006 AMD 3":"1eZqbqAVFclgrgBZ7n39lFOZU2BEKPy_f",
		"NFPA 1031":"1fTsvd0uMbAq-PTQwrQ8Xkyx3cSz08vLJ",
		"NFPA 101A":"1j1RARQHu1EpVdd_ErBQ1CqJ1duW9ea8L",
		"NFPA 1005":"1jVPO2Cp6bWBblEGJMyQn1VrplrlfWf7_",
		"NFPA 102 ERTA 1":"1rKvVkoqG0fUtP8GuJoxb0MU3n4mqgMdD",
		"NFPA 101 ERTA 2":"1rrlsMT8ByorurK6c7nbtz3gSqu8oongF",
		"NFPA 10 ERTA 1":"1t5mOAP9e_slN8exuXoTNC9LVvhRxo7sS",
		"NFPA 1006 AMD 6":"1zzfhHsxwBWFjfEy2q2PqUGN1FMZZvCPB",
		"NFPA 10 2017":"1-nw-7BNSpO8EIZ781Wk0Fkv5ceKYjCL_",
		"NFPA  422":"15UQMk7GxBiGYFHx02cjsF7phv5U4qHRD",
		"NFPA 1 AMD 1":"19tx5G5hZGz1648p8E-LWNmcmAs3pwTha",
		"NFPA 1 2018":"1DY2yWfQ1KaBD-jilXqwtsj_c4hax5MYJ",
		"NFPA 01":"1NIXen8m2b41wSHm6xQ3OdlZBYwOkBIIi",
		"NFPA 10 AMD 1":"1VdsH2rkozYBbSIKHBH-TvC-xZFcHrs-J",
		"NFPA 1":"1n3AnraPKV53HKRlUua4MkLRUFuFQHDfq",
		"NFPA  520":"1obubBZnw6V6v1QVH_Mq-9TX8X2-H1cvc",
		"NFPA 1 - Fire Code - Effective 2017":"1qVbCEuB6ru8yVnrBSV91oR1BVlIi4nTQ"
    },
	
};

   const [userData, setUserData] = useState(null);
   const [token, setToken] = useState(null);
   const [rimoxKey, setRimoxKey] = useState(0);
   const [selectedStandardCode, setSelectedStandardCode] = useState(null);
   const [codeList, setCodeList] = useState([]);
   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};

  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
  useEffect(() => {
    console.log('Selected Standard Code:', selectedStandardCode);

    if (selectedStandardCode && selectedStandardCode.value) {
      const list = Standardcodelist[selectedStandardCode.value];

      if (list) {
        const newList = Object.entries(list).map(([key, value], index) => ({
          id: index + 1,
          key,
          value,
        }));
        console.log('Mapped Code List:', newList);
        setCodeList(newList);
      } else {
        setCodeList([]);
      }
    } else {
      setCodeList([]);
    }
  }, [selectedStandardCode]);
  
  const [standardCodeOptions, setStandardCodeOptions] = useState([
    { value: 'back', label: 'Standard Code' },
    { value: 'AICHE', label: 'AICHE' },
    { value: 'ANSI', label: 'ANSI' },
    { value: 'API', label: 'API' },
    { value: 'ASME', label: 'ASME' },
    { value: 'DNV', label: 'DNV' },
    { value: 'IEC', label: 'IEC' },
    { value: 'NACE', label: 'NACE' },
    { value: 'NFPA', label: 'NFPA' }
  ]);
  
  const handleItemClick = (id, key) => {
	  
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
	  
	document.getElementById('iframetbl1').src = "https://drive.google.com/file/d/"+Standardcodelist[selectedStandardCode.value][key]+"/preview";
    document.getElementById('url_print').href="https://drive.google.com/file/d/"+Standardcodelist[selectedStandardCode.value][key]+"/view";
    document.getElementById('url_download').href="https://drive.google.com/uc?export=download&id="+Standardcodelist[selectedStandardCode.value][key];
    document.getElementById('tmp_url_print').style.display="block";
    document.getElementById('tmp_url_download').style.display="block";
    document.getElementById('ifr1').style.display="block";
	document.getElementById('homealive_standard_code').style.display="none";
	document.getElementById(`${id}open`).style.display="none";
	document.getElementById(`${id}close`).style.display="block";
	  
  };

  const handleItemCloseClick = (id) => {
    
	document.getElementById('tmp_url_print').style.display="none";
    document.getElementById('tmp_url_download').style.display="none";
    document.getElementById('ifr1').style.display="none";
	document.getElementById('homealive_standard_code').style.display="block";
	document.getElementById(`${id}open`).style.display="block";
	document.getElementById(`${id}close`).style.display="none";
	
  };

 const location = useLocation();

  // Mendapatkan code dari location state
  const { code } = location.state || {};

  // Mengupdate selectedStandardCode berdasarkan code yang didapat dari location
  useEffect(() => {
    if (code) {
      const selectedOption = standardCodeOptions.find(option => option.value === code);
      if (selectedOption) {
        setSelectedStandardCode(selectedOption);
      }
    }
  }, [code]);

  // Mengupdate codeList ketika selectedStandardCode berubah
  useEffect(() => {
	  
	  
    if (selectedStandardCode && selectedStandardCode.value) {
	  if(selectedStandardCode.value == "back"){
		  window.location.href = "./";
	  }
      const list = Standardcodelist[selectedStandardCode.value];

      if (list) {
		
        const newList = Object.entries(list).map(([key, value], index) => ({
          id: index + 1,
          key,
          value,
        }));
        console.log('Mapped Code List:', newList);
        setCodeList(newList);
      } else {
        setCodeList([]);
		
      }
    } else {
      setCodeList([]);
	  
    }
	
	
  }, [selectedStandardCode]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
	
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '10px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="sc" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={standardCodeOptions}
			  onChange={setSelectedStandardCode}
			  placeholder="Standard Code"
			  value={selectedStandardCode}
			/>
        </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '250px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '250px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						{selectedStandardCode && (
						<ul>
						  {codeList.length > 0 ? (
							codeList.map(({ id, key }) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{color: 'black'}}
										  id={`${id}open`}
										  href="#"
										  ref={(el) => (allIframesRefbk.current[id] = el)}
										  onClick={() => handleItemClick(`${id}`, key)}
										>
										  {key}
										</a>
										<a
										  style={{color: 'black'}}
										  id={`${id}close`}
										  className="all_ifr"
										  style={{ display: 'none' }}
										  href="#"
										  ref={(el) => (allIframesRef.current[id] = el)}
										  onClick={() => handleItemCloseClick(`${id}`)}
										>
										  <b style={{color: 'black'}}>{key}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))
						  ) : (
							<li>No data available</li>
						  )}
						</ul>
					  )}
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="tmp_url_print" style={{display: 'none', float: 'right'}}>
						<a id="url_print" href="" target="_blank">
							<img src="https://drive.google.com/thumbnail?id=1B3NWf05IkIaifnTa9g64mVGEJEDse3Tc&sz=w1000" style={{margin: '0px 0px 10px 0px'}} />
						</a>
					</div>
					
					<div id="tmp_url_download" style={{display: 'none', float: 'right'}}>
						<a id="url_download" href="">
							<img src="https://drive.google.com/thumbnail?id=1wHk7gUjP0XZwoY78aSvm_37P7VTj4aei&sz=w1000" style={{margin: '0px 0px 10px 0px'}} />
						</a>
					</div>
					
					<div id="ifr1" style={{display: 'none', width: 'calc(100vw - 270px)'}}>
						<iframe id="iframetbl1" style={{width: 'calc(100vw - 270px)', height: '600px', overflowX: 'auto', overflowY: 'hidden'}} src=""></iframe>
					</div>
					
					<img id="homealive_standard_code" style={{width:'calc(100vw - 270px)', height: '600px', marginTop: '10px'}} src="https://drive.google.com/thumbnail?id=1EQFzV56C-K1aJwL2uTGhXFecv4SI9K2z&sz=w1000" />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		  ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Standardcode;
