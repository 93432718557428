import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logout from './Logout';
import Select from 'react-select';
import { collection, setDoc, getDoc, getDocs, deleteDoc, doc } from "firebase/firestore";
import { fetchUserData } from '../api';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import $ from 'jquery';

const HomePage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const [idusers, setIdusers] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [entitas, setEntitas] = useState('');
  const [message, setMessage] = useState("");
  const [subsidiary, setSubsidiary] = useState(null);
  const [hakakses, setHakakses] = useState(null);
  const [Password, setPassword] = useState("");
  const [Prmtambahedit, setPrmtambahedit] = useState('tambah');
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(""); 
  
  const subsidiaryOptions = [
    { value: 'PGN', label: '1. Perusahaan Gas Negara' },
    { value: 'PTPertaminaGas', label: '2. Pertamina Gas' },
    { value: 'PTNusantaraRegas', label: '3. Nusantara Regas' },
    { value: 'PLI', label: '4. PLI' },
    { value: 'PTTransportasiGasIndonesia', label: '5. Transportasi Gas Indonesia' },
    { value: 'PTKalimantanJawaGas', label: '6. Kalimantan Jawa Gas' },
    { value: 'PTPertaSamtanGas', label: '7. Perta Samtan Gas' },
    { value: 'PTPGNLNGIndonesia', label: '8. PGN LNG Indonesia' },
    { value: 'PTWidarMandripaNusantara', label: '9. Widar Mandripa Nusantara' },
    { value: 'PTSakaEnergiIndonesia', label: '10. Saka Energi Indonesia' },
    { value: 'GAGAS', label: '11. Gagas Energi Indonesia' },
    { value: 'PTPertagasNiaga', label: '12. Pertagas Niaga' },
    { value: 'PTPertaArunGas', label: '13. Perta Arun Gas' },
    { value: 'PTPertaDayaGas', label: '14. Perta Daya Gas' },
    { value: 'PGNSSWJ', label: '15. PGN-SSWJ' },
    { value: 'PGNSOR1', label: '16. PGN-SOR1' },
    { value: 'PGNSOR2', label: '17. PGN-SOR2' },
    { value: 'PGNSOR3', label: '18. PGN-SOR3' },
  ];
  
  const hak_akses_field = [
    { value: 'Super Admin', label: 'Super Admin' },
    { value: 'Admin', label: 'Admin' },
    { value: 'User', label: 'User' },
  ];
  
  const aliases = {
  tableClass: "table table-bordered",
  buttonPrimary: "btn btn-primary",
  buttonDanger: "btn btn-danger",
  editButtonWidth: { width: '60px', textAlign: 'center', padding:'0', margin: '5px'},
  subsidiaryOptions: [
    { value: 'PGN', label: 'Perusahaan Gas Negara' },
    { value: 'PTPertaminaGas', label: 'Pertamina Gas' },
    { value: 'PTNusantaraRegas', label: 'Nusantara Regas' },
    { value: 'PLI', label: 'PLI' },
    { value: 'PTTransportasiGasIndonesia', label: 'Transportasi Gas Indonesia' },
    { value: 'PTKalimantanJawaGas', label: 'Kalimantan Jawa Gas' },
    { value: 'PTPertaSamtanGas', label: 'Perta Samtan Gas' },
    { value: 'PTPGNLNGIndonesia', label: 'PGN LNG Indonesia' },
    { value: 'PTWidarMandripaNusantara', label: 'Widar Mandripa Nusantara' },
    { value: 'PTSakaEnergiIndonesia', label: 'Saka Energi Indonesia' },
    { value: 'GAGAS', label: 'Gagas Energi Indonesia' },
    { value: 'PTPertagasNiaga', label: 'Pertagas Niaga' },
    { value: 'PTPertaArunGas', label: 'Perta Arun Gas' },
    { value: 'PTPertaDayaGas', label: 'Perta Daya Gas' },
    { value: 'PGNSSWJ', label: 'PGN-SSWJ' },
    { value: 'PGNSOR1', label: 'PGN-SOR1' },
    { value: 'PGNSOR2', label: 'PGN-SOR2' },
    { value: 'PGNSOR3', label: 'PGN-SOR3' },
  ],
};


  useEffect(() => {
    window.$ = $;
    window.jQuery = $;
  
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
            setUserData(response.data);
			
			
          } else {
            // Jika token tidak valid, arahkan ke login
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();

    fetchData2();
	
  }, []);
  
  useEffect(() => {
  if (userData && userData.hak_akses === "User") {
    // Mengatur state dengan penundaan 1 detik (1000 ms)
    const timer = setTimeout(() => {
      setName(userData.name || '');
      setEmail(userData.email || '');
	  setIdusers(userData.id);
    }, 1000);

    // Bersihkan timer ketika komponen unmount atau userData berubah
    return () => clearTimeout(timer);
  }
}, [userData]);
  
const fetchData2 = async () => {
    try {
      const response = await axios.get('https://alive.my.id/restapialive3/api/users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

const addUser = async () => {
  // Pastikan variabel bukan null dan trim untuk menghindari kesalahan
  const trimmedName = name?.trim() || "";
  const trimmedEmail = email?.trim() || "";
  const trimmedPassword = Password?.trim() || "";

  // Validasi untuk memastikan semua field terisi
  if (trimmedName === "" || trimmedEmail === "" || subsidiary === null || hakakses === null || trimmedPassword === "") {
    setMessage("Tolong Lengkapi Form");
    return;
  }

  // Regex untuk validasi format email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Cek apakah email sesuai dengan format
  if (!emailRegex.test(trimmedEmail)) {
    setMessage("Masukkan format email yang benar.");
    return;
  }

  try {
    const response = await fetch('https://alive.my.id/restapialive3/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        name: trimmedName,
        email: trimmedEmail,
        password: trimmedPassword,
        entitas: subsidiary,  // Pastikan ini adalah nilai yang benar
        hak_akses: hakakses,  // Pastikan ini adalah nilai yang benar
      }),
    });

    // Mengecek status respon
    if (!response.ok) {
      const errorData = await response.json(); // Mengambil data JSON dari respon
      setMessage(errorData.message || "Terjadi kesalahan saat menambah data.");
      return;
    }

    const successData = await response.json(); // Mengambil data JSON dari respon
    setMessage(successData.message || "Terjadi kesalahan saat menambah data.");

    if (successData.message === "User registered successfully") {
      // Reset form fields
      setIdusers("");
      setPrmtambahedit('tambah');
      setPassword("");
      setName("");
      setEmail("");
      alert('Berhasil tambah data');
      
      // Refresh data pengguna
      fetchData2();
    }

  } catch (error) {
    console.error('Error fetching user data:', error);
    setMessage("Terjadi kesalahan saat menambah data.");
  }

  setMessage("");
};

const editUserdb = async (userId) => {
   // Pastikan variabel bukan null dan trim untuk menghindari kesalahan
  const trimmedName = name?.trim() || "";
  const trimmedEmail = email?.trim() || "";
  const trimmedPassword = Password?.trim() || "";

  // Validasi untuk memastikan semua field terisi
  if (trimmedName === "" || trimmedEmail === "" || subsidiary === null || hakakses === null) {
    setMessage("Tolong Lengkapi Form");
    return;
  }

  // Regex untuk validasi format email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Cek apakah email sesuai dengan format
  if (!emailRegex.test(trimmedEmail)) {
    setMessage("Masukkan format email yang benar.");
    return;
  }

  try {
    const response = await fetch('https://alive.my.id/restapialive3/api/editusers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
		id: userId,
        name: trimmedName,
        email: trimmedEmail,
        password: trimmedPassword,
        entitas: subsidiary,  // Pastikan ini adalah nilai yang benar
        hak_akses: hakakses,  // Pastikan ini adalah nilai yang benar
      }),
    });

    // Mengecek status respon
    if (!response.ok) {
      const errorData = await response.json(); // Mengambil data JSON dari respon
      setMessage(errorData.message || "Terjadi kesalahan saat menambah data.");
      return;
    }

    const successData = await response.json(); // Mengambil data JSON dari respon
    setMessage(successData.message || "Terjadi kesalahan saat menambah data.");

    if (successData.message === "User updated successfully") {
      // Reset form fields
      setIdusers("");
      setPrmtambahedit('tambah');
      setPassword("");
      setName("");
      setEmail("");
      alert('Berhasil tambah data');
      
      // Refresh data pengguna
      fetchData2();
    }

  } catch (error) {
    console.error('Error fetching user data:', error);
    setMessage("Terjadi kesalahan saat menambah data.");
  }

  setMessage("");
};


const editUserdb2 = async (userId) => {
	alert(userId);
   // Pastikan variabel bukan null dan trim untuk menghindari kesalahan
  const trimmedName = name?.trim() || "";
  const trimmedEmail = email?.trim() || "";
  const trimmedPassword = Password?.trim() || "";
  
  // Validasi untuk memastikan semua field terisi
  if (trimmedEmail === "" || trimmedName === "") {
    setMessage("Tolong Lengkapi Form12");
    return;
  }

  // Regex untuk validasi format email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Cek apakah email sesuai dengan format
  if (!emailRegex.test(trimmedEmail)) {
    setMessage("Masukkan format email yang benar.");
    return;
  }

  try {
    const response = await fetch('https://alive.my.id/restapialive3/api/editusershakakses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
		id: userId,
        name: trimmedName,
        email: trimmedEmail,
        password: trimmedPassword,
        entitas: subsidiary,  // Pastikan ini adalah nilai yang benar
        hak_akses: hakakses,  // Pastikan ini adalah nilai yang benar
      }),
    });

    // Mengecek status respon
    if (!response.ok) {
      const errorData = await response.json(); // Mengambil data JSON dari respon
      setMessage(errorData.message || "Terjadi kesalahan saat menambah data.");
      return;
    }

    const successData = await response.json(); // Mengambil data JSON dari respon
    setMessage(successData.message || "Terjadi kesalahan saat menambah data.");

    if (successData.message === "User updated successfully") {
      // Reset form fields
      alert('Berhasil tambah data');
      
      // Refresh data pengguna
      fetchData2();
    }

  } catch (error) {
    console.error('Error fetching user data:', error);
    setMessage("Terjadi kesalahan saat menambah data.");
  }

  setMessage("");
};

const customStyles = {
    control: (provided) => ({
      ...provided,
      height: '25px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'linear-gradient(to bottom, #f2f2f2, black)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
      border: '1px solid #b3b3b3',
      borderRadius: '4px',
      marginBottom: '5px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '25px',
      padding: '0px 8px',
      display: 'flex',
      alignItems: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      backgroundColor: '#f5f5f5',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      marginBottom: '20px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333333',
    }),
  };
  
  const styles = {
  navigation: {
    width: '190px',
	height: 'calc(100vh - 200px)',
    float: 'left',
	boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25)',
	marginRight: '10px',
	display : 'none'
  },
  
  formtambah: {
    width: '38%',
	height: 'calc(100vh - 200px)',
	padding: '10px',
    float: 'left',
	boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25)',
  },
  
  formedit: {
    width: '38%',
	height: 'calc(100vh - 200px)',
	padding: '10px',
	margin: '0 auto',
	boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25)',
  },
  
  list: {
    width: '60%',
    height: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: '10px',
    padding: '10px',
    float: 'left',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25), 0 6px 12px rgba(0, 0, 0, 0.25)',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f5f5f5',
  },
  
  judullayout: {
	  margin: '-20px 0px 20px 0px',
  },
};

const editUser = async (documentId) => {
  try {
    const response = await fetch('https://alive.my.id/restapialive3/api/getdataUsers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        id_users: documentId,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    const userData = await response.json();
	
	if(userData){
	setIdusers(userData.id);
    setPrmtambahedit('edit');
    setName(userData.name);
    setEmail(userData.email);
    setSubsidiary(userData.entitas);
    setHakakses(userData.hak_akses);
	}
    // Lakukan sesuatu dengan userData yang diterima, misalnya mengisi form edit
    //console.log(userData);

  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};


  const handleSubsidiaryChange = (selectedOption) => {
    const selectedSubsidiary = selectedOption ? selectedOption.value : null;
    setSubsidiary(selectedSubsidiary);
  };
  
  const handleHakaksesChange = (selectedOption) => {
    const selectedHakakses = selectedOption ? selectedOption.value : null;
    setHakakses(selectedHakakses);
  };

const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Apakah Anda yakin ingin menghapus data ini?");
    
    if (confirmDelete) {
      
	  try {
		const response = await fetch('https://alive.my.id/restapialive3/api/getdeleteUsers', {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		  },
		  body: JSON.stringify({
			id_users: id,
		  }),
		});

		if (!response.ok) {
		  alert('gagal hapus data');
		}

		const userData = await response.json();
		alert("berhasil hapus data");
		fetchData2();

	  } catch (error) {
		console.error('Error fetching user data:', error);
	  }
	  
    }
  };
  
   // Apply search filter first
  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic applied to filtered data
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredUsers.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
  
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
		  <img 
			src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
			alt="Alive Logo" 
			style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
		  />
		</a>
		  
      <img 
        style={{ float: 'right', marginTop: '10px', marginRight: '15px' }} 
        src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} 
        width="200" 
      />
      <div style={{ clear: 'both' }}></div>
	  
	  <h3 style={styles.judullayout} align="center">User Management</h3>
		
		<div style={styles.navigation}>
			<div
				style={{margin: '10px 0px 0px -35px' ,cursor: 'pointer', fontSize: '13px'}}
				
			>
				<ul>
					<li>
						<table>
							<tbody>
								<tr>
									<td valign="top">
										<span className="lbn" style={{ fontSize: '12px'}}></span>
									</td>
									<td>
										User Setting
									</td>
								</tr>
							</tbody>
						</table>
					</li>
				</ul>
			
			</div>
			
		</div>
		
		
		
{userData && (userData.hak_akses === "Super Admin" || userData.hak_akses === "Admin") && (
  <>
		<div style={styles.list}>
		
		<input
          type="text"
          placeholder="Search by name or email"
          className="form-control"
          style={{ marginBottom: '10px' }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
		
        <table className={aliases.tableClass}>
  <thead>
    <tr>
      <th style={{textAlign: 'center', verticalAlign: 'top'}}>Email</th>
      <th style={{textAlign: 'center', verticalAlign: 'top'}}>Name</th>
      <th style={{textAlign: 'center', verticalAlign: 'top'}}>Grand Access</th>
      <th style={{textAlign: 'center', verticalAlign: 'top'}}>Entitas</th>
      <th style={{textAlign: 'center', verticalAlign: 'top'}}>Action</th>
    </tr>
  </thead>
  <tbody>
    {currentUsers.length > 0 ? (
      currentUsers.map((user) => {
        const entity = aliases.subsidiaryOptions.find(option => option.value === user.entitas);
        return (
          <tr key={user.id}>
            <td>{user.email}</td>
            <td>{user.name}</td>
            <td>{user.hak_akses}</td>
            <td>{entity ? entity.label : user.entitas}</td>
            <td style={{width: '170px'}}>
              <button className={aliases.buttonPrimary} style={aliases.editButtonWidth} onClick={() => editUser(user.id)}>Edit</button>
              <button className={aliases.buttonDanger} style={aliases.editButtonWidth} onClick={() => handleDelete(user.id)}>Delete</button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5">No data available</td>
      </tr>
    )}
  </tbody>
</table>
        
        {/* Pagination */}
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <a onClick={() => paginate(number)} href="#" className="page-link">
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

		
		<div style={styles.formtambah}>
			<div>
				<h5 align="center">User Setup</h5>
				<h5 style={{color: 'red'}} align="center">{message && <p>{message}</p>}</h5>
				<label>Nama :</label>
				<input
				  className="form-control"
				  type="text" 
				  value={name} 
				  onChange={(e) => setName(e.target.value)} 
				  placeholder="Name"
				/>
				<label>Email :</label>
				<input
				  className="form-control"
				  type="email" 
				  value={email} 
				  onChange={(e) => setEmail(e.target.value)} 
				  placeholder="Email"
				/>
				<label>Entitas :</label>
				
				<Select
				  styles={customStyles}
				  options={subsidiaryOptions}
				  onChange={handleSubsidiaryChange}
				  value={subsidiaryOptions.find(option => option.value === subsidiary)}
				  placeholder="Entitas"
				/>
				
				<label>Grand Access :</label>
				<Select
				  styles={customStyles}
				  options={hak_akses_field}
				  onChange={handleHakaksesChange}
				  value={hak_akses_field.find(option => option.value === hakakses)}
				  placeholder="Grand Access"
				/>
				
				<label>Password :</label>
				
				<input
				  style={{marginBottom: '20px'}}
				  className="form-control"
				  type="text" 
				  value={Password} 
				  onChange={(e) => setPassword(e.target.value)} 
				  placeholder="Password"
				/>
				
				<button className="form-control btn-primary" style={{width: '60px'}} 
				onClick={() => {
					if (Prmtambahedit === "tambah") {
					  addUser();
					} else if (Prmtambahedit === "edit") {
					  editUserdb(idusers);
					}
				  }}
				>
				
					{Prmtambahedit === "tambah" ? "Save" : "Save"}
				
				</button>
				
			</div>
		</div>
	  </>
)}
      
{userData && userData.hak_akses === "User" && (
  <>
    <div style={styles.formedit}>
      <div>
        {/* Menampilkan pesan jika ada */}
        {message && (
          <h5 style={{ color: 'red', textAlign: 'center' }}>
            <p>{message}</p>
          </h5>
        )}

        <label>Nama :</label>
        <input
          className="form-control"
          type="text" 
		  value={name}
          onChange={(e) => setName(e.target.value)} 
          placeholder="Name"
        />

        <label>Email :</label>
        <input
          className="form-control"
		  value={email}
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Email"
        />

        <label>Entitas :</label>
        <input
          type="text" 
          value={aliases.subsidiaryOptions.find(option => option.value === userData.entitas)?.label || "Entitas tidak ditemukan"} 
          className="form-control" 
          disabled 
        />

        <label>Password :</label>
        <input
          style={{ marginBottom: '20px' }}
          className="form-control"
          type="text"  
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password"
        />

        <button 
          className="form-control btn-primary" 
          style={{ width: '120px', marginTop: '10px' }} 
          onClick={() => { editUserdb2(idusers) }}
        >
          Edit User
        </button>
      </div>
    </div>
  </>
)}

	  
      <div style={{
        position: 'fixed', 
        bottom: '0', 
        width: '100%', 
        backgroundColor: '#ffff', 
        padding: '5px', 
        fontSize: '12px', 
        textAlign: 'center'
      }}>
        ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
        <div style={{
          position: 'absolute', 
          bottom: '5px', 
          right: '50px'
        }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
